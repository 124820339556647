import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Text,
} from '@knockrentals/knock-shared-web';
import { ColorPicker } from '../../../../../../Components/ColorPicker';
import { ColorSwatch } from '../../../../../../Components/Icons';

export const BRAND_COLOR_TITLE = 'Brand Color';

const useStyles = makeStyles({
  brandColorAccordion: {
    borderRadius: '8px',
    '&:before': {
      content: 'none',
    },
    marginBottom: '16px',
  },
  colorSwatch: {
    height: '58px',
    width: '58px',
  },
  details: {
    alignItems: 'center',
  },
});

const BrandColor = ({ brandColor, isBrandColorMixed, updateBrandColor }) => {
  const classes = useStyles();

  return (
    <Accordion
      className={classes.brandColorAccordion}
      defaultExpanded
      variant="outlined"
    >
      <AccordionSummary>
        <Text variant="subtitle1">{BRAND_COLOR_TITLE}</Text>
      </AccordionSummary>

      <AccordionDetails className={classes.details}>
        <ColorSwatch
          className={classes.colorSwatch}
          color={brandColor}
          isIndeterminate={isBrandColorMixed}
        />

        <ColorPicker
          color={brandColor}
          isIndeterminate={isBrandColorMixed}
          onConfirmColor={updateBrandColor}
        />
      </AccordionDetails>
    </Accordion>
  );
};

BrandColor.propTypes = {
  brandColor: PropTypes.string,
  isBrandColorMixed: PropTypes.bool,
  updateBrandColor: PropTypes.func,
};

export default BrandColor;
