import React, { Component } from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter } from '@knockrentals/knock-react';
import { Formik } from 'formik';
import { trimValue } from '../../../Utils';

import './_addIntegrationForm.scss';

class AddMriIntegration extends Component {
  state = {
    url: '',
    mri_community_id: '',
    partner_key: '',
    client_id: '',
    database_name: '',
    username: '',
    password: '',
  };

  render() {
    return (
      <Modal className="add-integration-form">
        <ModalHeader>
          <h3>Add MRI Integration</h3>
        </ModalHeader>
        <Formik
          initialValues={{
            username: '',
            password: '',
            mri_community_id: '',
            partner_key: '',
            client_id: '',
            database_name: '',
            url: '',
          }}
          onSubmit={this.submit.bind(this)}
          validate={this.validate}
        >
          {props => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <ModalContent>
                <div className="knock-react-flex">
                  <div>
                    <strong>MRI Property ID: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      type="text"
                      value={props.values.mri_community_id}
                      name="mri_community_id"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.mri_community_id && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.mri_community_id}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Partner Key: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.partner_key}
                      name="partner_key"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.partner_key && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.partner_key}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Client ID: </strong>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="knock-react-input"
                      value={props.values.client_id}
                      name="client_id"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.client_id && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.client_id}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Database Name: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.database_name}
                      name="database_name"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.database_name && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.database_name}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>URL: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.url}
                      name="url"
                      placeholder="https://mri-domain.com/path/api.asp"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Username: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.username}
                      name="username"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                      type="text"
                    />
                    {!!props.errors.username && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.username}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Password: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.password}
                      name="password"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.password && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.password}
                      </span>
                    )}
                  </div>
                </div>
              </ModalContent>
              <ModalFooter>
                <button className="knock-react-button" type="submit">
                  Save
                </button>
                <button className="knock-react-button" onClick={this.cancel.bind(this)}>
                  Cancel
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  submit(values) {
    const {
      username,
      password,
      mri_community_id,
      partner_key,
      client_id,
      database_name,
      url,
    } = values;

    const data = {
      username,
      password,
      mri_community_id,
      partner_key,
      client_id,
      database_name,
      url,
      vendor: 'mri',
    };

    this.props.onAddIntegration({ ...data });
  }

  cancel() {
    this.props.onCancelAddIntegration();
  }

  validate(values) {
    const errors = {};

    if (!values.username) {
      errors.username = 'Required';
    }

    if (!values.password) {
      errors.password = 'Required';
    }

    if (!values.mri_community_id) {
      errors.mri_community_id = 'Required';
    }

    if (!values.partner_key) {
      errors.partner_key = 'Required';
    }

    if (!values.client_id) {
      errors.client_id = 'Required';
    }

    if (!values.database_name) {
      errors.database_name = 'Required';
    }

    if (!values.url) {
      errors.url= 'Required';
    }

    return errors;
  }
}

export default AddMriIntegration;
