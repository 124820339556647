import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, makeStyles, NamedColors } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
    ipAddressChip: {
        backgroundColor: NamedColors.slate[100],
        margin: '0px 3px 0px 3px',
        '& .MuiChip-deleteIcon': {
            color: NamedColors.denim[500],
        },
    },
});

const INITIAL_DISPLAY_COUNT = 8;

const IpAddressChips = ({ ipAddresses }) => {
    const classes = useStyles();
    const [shouldShowHiddenChips, setShouldShowHiddenChips] = useState(false);

    const visibleChips = shouldShowHiddenChips
        ? ipAddresses
        : ipAddresses?.slice(0, INITIAL_DISPLAY_COUNT);
    const hiddenChipsCount = ipAddresses?.length - INITIAL_DISPLAY_COUNT;

    const toggleShowHiddenChips = () => {
        setShouldShowHiddenChips((prevState) => !prevState);
    };
    const chipToggleText = shouldShowHiddenChips
        ? 'Collapse'
        : `+${hiddenChipsCount}`;

    return (
        <span>
            {visibleChips.map((ipAddress, index) => (
                <Chip
                    key={index}
                    label={ipAddress}
                    size="small"
                    onClick={() => { }}
                    onDelete={() => { }}
                    className={classes.ipAddressChip}
                />
            ))}

            {hiddenChipsCount > 0 && (
                <Chip
                    label={chipToggleText}
                    clickable
                    onClick={toggleShowHiddenChips}
                    size="small"
                    className={classes.ipAddressChip}
                />
            )}
        </span>

    );
};

IpAddressChips.propTypes = {
    ipAddresses: PropTypes.arrayOf(PropTypes.string),
};

export default IpAddressChips;
