import { apiRemote } from '../../Services/RemoteService';
import queryString from 'query-string';
import moment from 'moment';

export const transformPropertySourceCosts = (fees) =>
  fees.map((fee) => {
    const pricingModel = fee.type === 'None' ? null : fee.type;
    const cost = pricingModel ? { cost: fee.cost || 0 } : {};

    return {
      ...cost,
      month_to_update: `${fee.year}-${String(fee.month).padStart(2, '0')}`,
      pricing_model: pricingModel,
    };
  });

class SourcesAPI {
  static FEE_TYPES = [
    { value: null, label: 'None' },
    { value: 'per-lease', label: 'Per lease' },
    { value: 'per-lead', label: 'Per lead' },
    { value: 'per-month', label: 'Per month' },
  ];

  static async batchUpdatePropertySourceCosts(propertyId, sourceId, fees) {
    const payload = transformPropertySourceCosts(fees);

    try {
      const response = await apiRemote.put(
        `admin/sources/properties/${propertyId}/costs/${sourceId}`,
        payload
      );

      if (!response.ok || !response.status === 204) {
        throw new Error(response.statusText);
      }
    } catch (e) {
      throw new Error(e.message);
    }
  }

  static getAccountSources() {
    return apiRemote
      .get('admin/sources/account')
      .then((response) => response.json())
      .catch((e) => e);
  }

  static getSourceById(sourceId) {
    return apiRemote
      .get(`admin/sources/account/${sourceId}`)
      .then((response) => response.json())
      .catch((e) => e);
  }

  static getCompanyMarketingSources = () =>
    apiRemote
      .get('admin/sources/company-marketing-sources')
      .then((response) => response.json())
      .catch((e) => {
        throw e;
      });

  static getCompanyMarketingSourceProperties = (marketingSourceId) =>
    apiRemote
      .get(`admin/sources/${marketingSourceId}/properties`)
      .then((response) => response.json())
      .catch((e) => {
        throw e;
      });

  static getPropertiesSources(propertyIds = null, includeDisabled = null) {
    const queryParams = propertyIds
      ? queryString.stringify({
          pid: propertyIds,
          include_disabled: includeDisabled,
        })
      : '';

    return apiRemote
      .get(`admin/sources/properties?${queryParams}`)
      .then((response) => response.json())
      .catch((e) => e);
  }

  static getPropertySourcesCosts(
    propertyId,
    sourceId,
    start = moment().startOf('year').subtract(1, 'year').format('YYYY-MM')
  ) {
    return apiRemote
      .get(
        `admin/sources/properties/${propertyId}/costs/${sourceId}?start=${start}`
      )
      .then((response) => response.json())
      .catch((e) => e);
  }

  static getAllMarketingSources() {
    return apiRemote
      .get('admin/sources')
      .then((response) => response.json())
      .catch((e) => e);
  }

  static addCompanyMarketingSource(sourceId) {
    const payload = {
      source_id: sourceId,
    };

    return apiRemote
      .post('admin/sources/company-marketing-source', payload)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .catch((e) => {
        throw Error(e);
      });
  }

  static updatePropertySource(
    propertyId,
    sourceId,
    displayEmail,
    updatedSourceMappings
  ) {
    const payload = {
      display_email: displayEmail,
      source_mappings: updatedSourceMappings,
    };

    return apiRemote
      .put(
        `admin/sources/properties/${propertyId}/sources/${sourceId}`,
        payload
      )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .catch((e) => {
        console.error(`Error updating property source: ${e}`);
        throw Error(e);
      });
  }

  static updatePropertySourceMapping(property_id, updatedSourceMappings) {
    const payload = {
      source_mappings: updatedSourceMappings,
    };
    return apiRemote
      .put(`admin/sources/properties/${property_id}/source-mapping`, payload)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .catch((e) => {
        console.error(`Error updating property source: ${e}`);
        throw Error(e);
      });
  }

  static transformUpdateCompanySourcePropertyMappings(propertyMappings) {
    const apiMappings = { isEnabled: 'is_enabled' };
    return Object.entries(propertyMappings).reduce(
      (propertyMap, [clientKey, clientValue]) => {
        const apiKey = apiMappings[clientKey];
        propertyMap[apiKey] = clientValue;
        return propertyMap;
      },
      {}
    );
  }

  static updateCompanySourceProperty(
    marketingSourceId,
    leasingTeamId,
    updatedPropertyMappings
  ) {
    const payload = {
      properties: [
        {
          leasing_team_id: leasingTeamId,
          ...this.transformUpdateCompanySourcePropertyMappings(
            updatedPropertyMappings
          ),
        },
      ],
    };

    return apiRemote
      .put(`admin/sources/${marketingSourceId}/properties`, payload)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .catch((e) => {
        throw e;
      });
  }

  static batchUpdateCompanySourceProperties(marketingSourceId, updates) {
    const payload = this.transformUpdateCompanySourcePropertyMappings(updates);

    return apiRemote
      .put(`admin/sources/${marketingSourceId}/properties/batch`, payload)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .catch((e) => {
        throw e;
      });
  }

  static updateDefaultPropertySource(
    propertyId,
    integrationId,
    integrationSourceId
  ) {
    const payload = {
      integration_id: integrationId,
      integration_source_id: integrationSourceId,
    };

    return apiRemote
      .put(`admin/sources/properties/${propertyId}/default-source`, payload)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .catch((e) => {
        console.error(`Error updating default property source: ${e}`);
        throw Error(e);
      });
  }

  static updateAccountSource(accountId, sourceId, isEnabled, parentSourceId) {
    return apiRemote
      .put(`admin/sources/account/${accountId}/sources/${sourceId}`, {
        is_enabled: isEnabled,
        parent_source_id: parentSourceId,
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .catch((e) => {
        console.error(`Error updating account source: ${e}`);
        throw Error(e);
      });
  }

  static createPropertySourceRelay(
    propertyId,
    {
      voiceNumberRaw,
      voiceNumberForwardsToRaw,
      voiceNumberResidentForwardsToRaw,
      shouldRecord,
      greetingRepeatCount,
      residentGreetingRepeatCount,
      greetingDelaySeconds,
      residentGreetingDelaySeconds,
      countryCode,
      sourceId,
    }
  ) {
    const payload = {
      source_id: sourceId,
      country_code: countryCode,
      relay_phone_number: voiceNumberRaw,
      forwarding_phone_number: voiceNumberForwardsToRaw,
      resident_forwarding_phone_number: voiceNumberResidentForwardsToRaw,
      should_record: shouldRecord,
      greeting_repeat_count: greetingRepeatCount,
      resident_greeting_repeat_count: residentGreetingRepeatCount,
      greeting_delay_seconds: greetingDelaySeconds,
      resident_greeting_delay_seconds: residentGreetingDelaySeconds,
    };

    return apiRemote
      .post(`admin/sources/properties/${propertyId}/voice-relays`, payload)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .catch((e) => {
        console.error(`Error creating property source relay: ${e}`);
        throw Error(e);
      });
  }

  static updatePropertySourcePhoneRelay(
    propertyId,
    relayPhoneId,
    {
      voiceNumberForwardsToRaw,
      voiceNumberResidentForwardsToRaw,
      shouldRecord,
      greetingRepeatCount,
      residentGreetingRepeatCount,
      greetingDelaySeconds,
      residentGreetingDelaySeconds,
      isSpamDetectionEnabled,
      sourceId,
    }
  ) {
    const payload = {
      forwarding_number: voiceNumberForwardsToRaw,
      resident_forwarding_number: voiceNumberResidentForwardsToRaw,
      should_record: shouldRecord,
      greeting_repeat_count: greetingRepeatCount,
      resident_greeting_repeat_count: residentGreetingRepeatCount,
      greeting_delay_seconds: greetingDelaySeconds,
      resident_greeting_delay_seconds: residentGreetingDelaySeconds,
      is_spam_detection_enabled: isSpamDetectionEnabled,
      source_id: sourceId,
    };

    return apiRemote
      .put(
        `admin/sources/properties/${propertyId}/voice-relays/${relayPhoneId}`,
        payload
      )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .catch((e) => {
        console.error(`Error updating property source phone relay: ${e}`);
        throw Error(e);
      });
  }

  static removePropertySourcePhoneRelay(propertyId, relayPhoneId, isReassign) {
    return apiRemote
      .delete(
        `admin/sources/properties/${propertyId}/voice-relays/${relayPhoneId}?isReassigning=${
          isReassign ? 'true' : 'false'
        }`
      )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
      })
      .catch((e) => {
        console.error(`Error removing property source phone relay: ${e}`);
        throw Error(e);
      });
  }

  static updatePropertySourceCosts(
    propertyId,
    sourceId,
    monthYearString,
    { cost, pricingModel }
  ) {
    const payload = {
      month_to_update: monthYearString,
      cost: cost,
      pricing_model: pricingModel,
    };

    return apiRemote
      .post(`admin/sources/properties/${propertyId}/costs/${sourceId}`, payload)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .catch((e) => {
        console.error(`Error updating property source costs: ${e}`);
        throw Error(e);
      });
  }

  static getAvailableNumber(countryCode, areaCode) {
    return apiRemote
      .get(`relay/voice/phones?areaCode=${areaCode}&countryCode=${countryCode}`)
      .then((response) => response.json())
      .catch((e) => e);
  }

  static getPortedNumber(countryCode, areaCode, portedNumber) {
    return apiRemote
      .get(
        `relay/voice/phones/ported?areaCode=${areaCode}&countryCode=${countryCode}&portedNumber=${portedNumber}`
      )
      .then((response) => response.json())
      .catch((e) => e);
  }

  static getRelaySettings(propertyId, relayPhoneId) {
    return apiRemote
      .get(
        `admin/sources/properties/${propertyId}/voice-relays/${relayPhoneId}`
      )
      .then((response) => response.json())
      .catch((e) => e);
  }

  static applySettingsToAll(sourceLeasingTeamId) {
    return apiRemote
      .put(`admin/sources/account`, {
        source_leasing_team_id: sourceLeasingTeamId,
      })
      .then((response) => response.json())
      .catch((e) => e);
  }

  static getSpreadsheetUrl() {
    return apiRemote
      .get('admin/sources/spreadsheet-url')
      .then((response) => response.json())
      .catch((e) => e);
  }

  static getPhoneRecordingTemplates(propertyId) {
    return apiRemote
      .get(`admin/sources/properties/${propertyId}/phone-recording-config`)
      .then((response) => response.json())
      .catch((e) => e);
  }

  static putPhoneRecordOutboundConfig(propertyId, shouldRecordOutbound) {
    return apiRemote
      .put(`admin/sources/properties/${propertyId}/phone-recording-config`, {
        should_record_outbound: shouldRecordOutbound,
      })
      .then((response) => response.json())
      .catch((e) => e);
  }

  static putPhoneRecordingTemplates(propertyId, greetingUrl) {
    return apiRemote
      .put(`admin/sources/properties/${propertyId}/phone-recording-config`, {
        greeting_url: greetingUrl,
      })
      .then((response) => response.json())
      .catch((e) => e);
  }

  static putPhoneResidentRecordingTemplates(propertyId, greetingUrl) {
    return apiRemote
      .put(`admin/sources/properties/${propertyId}/phone-recording-config`, {
        greeting_url_resident: greetingUrl,
      })
      .then((response) => response.json())
      .catch((e) => e);
  }

  static uploadPhoneRecordingFile(recordingFile) {
    const data = new FormData();
    data.append('recording', recordingFile);

    const headers = apiRemote.getHeaders();
    delete headers['Content-Type'];

    const fetchResult = fetch(
      apiRemote.baseUri + 'admin/sources/phone-recording-template',
      {
        headers: headers,
        method: 'POST',
        body: data,
      }
    );

    return fetchResult.then((response) => response.json()).catch((e) => e);
  }

  static placeTestCallForPhoneRecordingUrl(phoneNumber, recordingUrl) {
    return apiRemote
      .post('admin/sources/test-recording-template', {
        phone_number: phoneNumber,
        recording_url: recordingUrl,
      })
      .then((response) => response.json())
      .catch((e) => e);
  }
}

export default SourcesAPI;
