import React from 'react';
import PropTypes from 'prop-types';
import { Report } from '@material-ui/icons';
import {
  FAILED_STATUS,
  NO_RELAY_DEFAULTS,
  REGISTRATION_ATTEMPTS,
  TRACKING_NUMBER_STATUS,
} from '../constants';

export const ARIA_LABEL = 'Failure status icon';

export const PhoneRegistrationStatusIcon = ({
  propertyPhoneRegistrationRelayMap,
  relayPhoneId,
}) => {
  const status =
    propertyPhoneRegistrationRelayMap[relayPhoneId]?.[TRACKING_NUMBER_STATUS] ||
    '';

  if (status.toLowerCase() !== FAILED_STATUS) {
    return null;
  }

  return <Report aria-label={ARIA_LABEL} color="error" />;
};

PhoneRegistrationStatusIcon.propTypes = {
  propertyPhoneRegistrationRelayMap: PropTypes.objectOf(
    PropTypes.shape({
      [REGISTRATION_ATTEMPTS]: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      [TRACKING_NUMBER_STATUS]: PropTypes.string,
      [NO_RELAY_DEFAULTS]: PropTypes.shape({
        [REGISTRATION_ATTEMPTS]: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
        [TRACKING_NUMBER_STATUS]: PropTypes.string,
      }),
    })
  ),
  relayPhoneId: PropTypes.number,
};

export default PhoneRegistrationStatusIcon;
