import React from 'react';
import { Switch } from '@knockrentals/knock-shared-web';

import { DataTableCheckbox } from '../../common/DataTableCheckbox';

export const getInputSummaries = (propertiesMap) => {
  const initialCounts = {
    selected: 0,
    unselected: 0,
  };

  const properties = Object.values(propertiesMap);
  const omniCounts = properties.reduce((countMap, property) => {
    property.isSelected ? countMap.selected++ : countMap.unselected++;

    return countMap;
  }, initialCounts);

  const propertyCount = properties.length;

  return {
    areAllSelected:
      propertyCount !== 0 && omniCounts.selected === propertyCount,
    areAllUnselected: omniCounts.unselected === propertyCount,
  };
};

export const getColumns = ({
  handleOnChangeAll,
  handleSwitchValueOnChange,
  handleRowDataOnChange,
  selectedProperties,
}) => {
  const { areAllSelected, areAllUnselected } =
    getInputSummaries(selectedProperties);

  return [
    {
      align: 'left',
      field: 'isSelected',
      sorting: false,
      title: (
        <DataTableCheckbox
          arialLabel="select all properties checkbox"
          isChecked={areAllSelected}
          isIndeterminate={!areAllSelected && !areAllUnselected}
          onChangeHandler={handleOnChangeAll}
        />
      ),
      render: (rowData) => {
        return (
          <DataTableCheckbox
            arialLabel="select property checkbox"
            isChecked={!!selectedProperties[rowData.propertyId]?.isSelected}
            onChangeHandler={() => handleRowDataOnChange(rowData.propertyId)}
          />
        );
      },
      minWidth: '40px',
      maxWidth: '40px',
    },
    {
      align: 'left',
      field: 'propertyName',
      title: `Properties`,
      width: '250px',
      sorting: false,
    },
    {
      align: 'left',
      field: 'is_transcribed',
      title: 'Transcribe Calls',
      sorting: false,
      render: (rowData) => (
        <Switch
          className='call-intel-feature-switch'
          name='is_transcribed'
          color="primary"
          checked={rowData.is_transcribed}
          onChange={(event) => handleSwitchValueOnChange(event, rowData)}
        />
      )
    },
    {
      align: 'left',
      field: 'is_scored',
      title: 'Annotate Calls',
      sorting: false,
      render: (rowData) => (
        <Switch
          className='call-intel-feature-switch'
          name='is_scored'
          color="primary"
          checked={rowData.is_scored}
          onChange={(event) => handleSwitchValueOnChange(event, rowData)}
        />
      )
    },
    {
      align: 'left',
      field: 'is_standalone',
      title: 'Standalone',
      sorting: false,
      render: (rowData) => (
        <Switch
          className='call-intel-feature-switch'
          name='is_standalone'
          color="primary"
          checked={rowData.is_standalone}
          onChange={(event) => handleSwitchValueOnChange(event, rowData)}
        />
      )
    }
  ];
};
