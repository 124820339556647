import React, { Component } from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter } from '@knockrentals/knock-react';
import { Formik } from 'formik';
import { trimValue } from '../../../Utils';

import './_addIntegrationForm.scss';

class AddEntrataIntegration extends Component {
  render() {
    return (
      <Modal className="add-integration-form">
        <ModalHeader>
          <h3>Add Entrata Integration</h3>
        </ModalHeader>
        <Formik
          initialValues={{
            username: '',
            password: '',
            subdomain: '',
            entrata_property_id: '',
          }}
          validate={this.validate}
          onSubmit={this.submit.bind(this)}
        >
          {props => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <ModalContent>
                <div className="knock-react-flex">
                  <div>
                    <strong>Username: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      type="text"
                      value={props.values.username}
                      name="username"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.username && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.username}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Password: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.password}
                      name="password"
                      type="password"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.password && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.password}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Subdomain: </strong>
                  </div>
                  <div>
                    <input
                      type="text"
                      className="knock-react-input"
                      value={props.values.subdomain}
                      name="subdomain"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.subdomain && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.subdomain}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Entrata property ID: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.entrata_property_id}
                      name="entrata_property_id"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.entrata_property_id && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.entrata_property_id}
                      </span>
                    )}
                  </div>
                </div>
              </ModalContent>
              <ModalFooter>
                <button className="knock-react-button" type="submit">
                  Save
                </button>
                <button className="knock-react-button" onClick={this.cancel.bind(this)}>
                  Cancel
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  submit(values) {
    this.props.onAddIntegration({ ...values, vendor: 'entrata' });
  }

  cancel() {
    this.props.onCancelAddIntegration();
  }

  validate(values) {
    const errors = {};

    if (!values.username) {
      errors.username = 'Required';
    }

    if (!values.password) {
      errors.password = 'Required';
    }

    if (!values.subdomain) {
      errors.password = 'Required';
    }

    if (!values.entrata_property_id) {
      errors.password = 'Required';
    }

    return errors;
  }
}

export default AddEntrataIntegration;
