import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  addCircleIcon: {
    '&.MuiSvgIcon-root path': {
      stroke: 'currentColor',
    },
  },
});

export const AddCircleSvgIcon = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      className={classnames(classes.addCircleIcon, className)}
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 7.5V16.5"
          stroke="#353950"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.5 12H16.5"
          stroke="#353950"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
          stroke="#353950"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

AddCircleSvgIcon.propTypes = {
  className: PropTypes.string,
};
