import React, { useState } from 'react';
import { TableRow } from '../Table';
import { ActionButtons } from './ActionButtons';


export const EngrainIntegrationsTable = props => {
  const [properties, setProperties] = useState(props.propertyMapping);
  const { reloadData } = props;

  const handleChangeEvent = (property_id, e) => {
    // base check, make sure input value is number. we chould change the input type but we are saving a str
    // on the backend and since this is a external integration we cannot control what they may change their
    // values down the road. so we can keep the backend open and remove the below check if needed
    const re = /^[0-9\b]+$/;
    if (!re.test(e.target.value)) return;

    const newProperties = properties.map(property => {
      if (property.property_id === property_id) {
        property.engrain_unitmap_id = e.target.value;
      }
      return property;
    });
    setProperties(newProperties);
  };

  const rows = properties.map(({ property_id, community_name, credential_id, engrain_unitmap_id }, index) => {
    return (
      <TableRow
        key={'properties-row-' + index}
        cells={[
          community_name,
          <div className="field-input">
            <input
              name="attributeTypeValue"
              type="text"
              value={engrain_unitmap_id}
              onChange={e => handleChangeEvent(property_id, e)}
              className="knock-react-input"
            />
          </div>,
          <div>
            <ActionButtons
              property_id={property_id}
              engrain_unitmap_id={engrain_unitmap_id}
              reloadData={reloadData}
              credential_id={credential_id}
            />
          </div>
        ]}
      />
    );
  });

  return (
    <div>
        <table className="wire-frame-table">
        <thead>
          <tr>
            <th>Community Name</th>
            <th>Engrain Unitmap ID</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};
