import React from 'react';
import PropTypes from 'prop-types';

const OnsiteIntegrationInfo = ({ integration }) => {
  const { osm_property_id: osmPropertyId, vendor } = integration;
  return (
    <div className="integration-info">
      <div>
        <span className="integration-info-title">Integration:</span> {vendor}
      </div>
      <div>
        <span className="integration-info-title">Onsite Property Id:</span>{' '}
        {osmPropertyId}
      </div>
    </div>
  );
};

OnsiteIntegrationInfo.propTypes = {
  integration: PropTypes.shape({
    osm_property_id: PropTypes.string,
    vendor: PropTypes.string,
  }),
};

export default OnsiteIntegrationInfo;
