import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_grid.scss';

// SASS classes are prefixed to make them unique
const CSS_CLASS_PREFIX = 'admin-web__syndication__components__dPHxbW__Grid';
const prefix = className => `${CSS_CLASS_PREFIX}-${className}`;

export const Grid = ({ children, className, rowWindowClassName }) => (
  <div className={classNames(prefix('grid'), className)}>
    <div className={classNames(prefix('row-window'), rowWindowClassName)}>{children}</div>
  </div>
);

Grid.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  rowWindowClassName: PropTypes.string,
};

// Rows need to have Columns as children.
// To make a row a header row, just set header=true (it will stick to the top of the grid)
// To giver a header row a drop shadow bottom border set border=true
// To give a header row with rotated column headings extra height, set rotated=true
// When using more than one header row, each row after the first will need an offset: eg. top='92px'
export const Row = ({ children, header, border, rotated, title, top, className, style }) => {
  const classes = {
    [prefix('row')]: true,
    [prefix('header')]: header,
    [prefix('header-border')]: header && border,
    [prefix('header-rotated')]: header && rotated,
  };
  const styles = {
    top,
    ...style,
  };
  return (
    <div className={classNames(classes, className)} style={styles} title={title}>
      {children}
    </div>
  );
};

Row.propTypes = {
  children: PropTypes.node,
  header: PropTypes.bool,
  border: PropTypes.bool,
  rotated: PropTypes.bool,
  title: PropTypes.string,
  top: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.any,
};

// Columns act like the cells in the grid.
// To make a column styled like a table heading, set heading=true
// To rotate a heading column, set rotated=true
export const Column = ({ children, heading, rotated, title, className, style }) => {
  const classes = {
    [prefix('col')]: true,
    [prefix('heading')]: heading,
    [prefix('heading-rotated')]: heading && rotated,
  };
  return (
    <div className={classNames(classes, className)} title={title} style={style}>
      {children}
    </div>
  );
};

Column.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.bool,
  rotated: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.any,
};
