import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Text,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  makeStyles,
} from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  textWordBreak: {
    wordBreak: 'break-word',
  },
});

const ConfirmationDialog = ({
  closeDialog,
  handleConfirmation,
  isOpen,
  message,
  primaryButtonText,
  secondaryButtonText = 'Cancel',
  title,
}) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={closeDialog}>
      <DialogTitle disableTypography className={classes.textWordBreak}>
        <Text variant="subtitle1">{title}</Text>
      </DialogTitle>

      <DialogContent>
        <DialogContentText className={classes.textWordBreak}>
          {message}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={closeDialog} variant="outlined">
          {secondaryButtonText}
        </Button>

        <Button color="primary" onClick={handleConfirmation}>
          {primaryButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  closeDialog: PropTypes.func,
  handleConfirmation: PropTypes.func,
  isOpen: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  title: PropTypes.string,
};

export default ConfirmationDialog;
