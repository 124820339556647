import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Button, Menu, MenuItem } from '@knockrentals/knock-shared-web';

const MenuButton = ({ buttonText, onSelect, options, ...rest }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => (e) => {
    setAnchorEl(null);
    onSelect(e, option.value);
  };

  return (
    <div>
      <Button
        aria-controls={isMenuOpen ? 'dropdown-menu' : undefined}
        aria-expanded={isMenuOpen ? 'true' : undefined}
        aria-haspopup="true"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        id="button-dropdown"
        onClick={handleClick}
        variant="contained"
        {...rest}
      >
        {buttonText}
      </Button>

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        aria-labelledby="positioned-button"
        id="positioned-menu"
        onClose={handleClose}
        open={isMenuOpen}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
      >
        {options.map((option) => (
          <MenuItem
            disableRipple
            key={option.value}
            onClick={handleOptionClick(option)}
            name={option.label}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

MenuButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  buttonText: PropTypes.string,
  onSelect: PropTypes.func,
};

export default MenuButton;
