import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  makeStyles,
  Tab,
  Tabs,
  TabPanel,
} from '@knockrentals/knock-shared-web';

import AutoResponderTemplates from '../AutoResponderTemplates';
import TemplatesPage from '../../../Templates/TemplatesPage';

export const VIEWS = {
  AUTO_RESPONDERS: 'auto-responders',
  TEMPLATES: 'templates',
};

export const TITLES = {
  [VIEWS.AUTO_RESPONDERS]: 'Auto Responders',
  [VIEWS.TEMPLATES]: 'Templates',
};

const useStyles = makeStyles({
  templateTabs: {
    borderBottom: '1px solid #E0E0E0',
    marginBottom: '20px',
  },
});

const TemplateTabs = () => {
  const [view, setView] = useState(VIEWS.TEMPLATES);

  const classes = useStyles();

  return (
    <section>
      <Tabs
        className={classes.templateTabs}
        indicatorColor="primary"
        onChange={(_, newView) => setView(newView)}
        textColor="primary"
        value={view}
      >
        <Tab
          ariaLabel={TITLES[VIEWS.TEMPLATES]}
          label={TITLES[VIEWS.TEMPLATES]}
          value={VIEWS.TEMPLATES}
        />

        <Tab
          ariaLabel={TITLES[VIEWS.AUTO_RESPONDERS]}
          label={TITLES[VIEWS.AUTO_RESPONDERS]}
          value={VIEWS.AUTO_RESPONDERS}
        />
      </Tabs>

      <TabPanel value={view} index={VIEWS.TEMPLATES}>
        <TemplatesPage />
      </TabPanel>

      <TabPanel value={view} index={VIEWS.AUTO_RESPONDERS}>
        <AutoResponderTemplates />
      </TabPanel>
    </section>
  );
};

TemplateTabs.propTypes = {
  children: PropTypes.node,
};

export default TemplateTabs;
