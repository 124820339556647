import { Button, makeStyles } from '@knockrentals/knock-shared-web';
import PropTypes from 'prop-types';
import React from 'react';
import { EditIcon } from '../../../../Components/Icons/EditIcon';

const useStyles = makeStyles((theme) => ({
  editConfigurationButton: {
    textAlign: 'right',
    padding: '10px 20px',
  },
  editButton: {
    textTransform: 'none',
    '& .MuiSvgIcon-root': {
      height: '18px',
      width: '18px',
    },
    '&:disabled .MuiSvgIcon-root path': {
      stroke: theme.palette.action.disabled,
    },
  },
}));

const EditButton = ({ selected = [], onClick}) => {
  const classes = useStyles();
  const isDisabled = selected.length === 0;

  return (
    <div className={classes.editConfigurationButton}>
      <Button
        className={classes.editButton}
        disabled={isDisabled}
        color="primary"
        startIcon={<EditIcon />}
        variant="text"
        onClick={onClick}
      >
        {`Edit (${selected.length} selected)`}
      </Button>
    </div>
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.array,
  tooltip: PropTypes.string,
};

export default EditButton;
