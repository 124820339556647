import React, { Component } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { validateUrl } from '../../Utils';

class PropertyInfoEditSettings extends Component {
  state = {
    preferences: {},
  };

  componentDidMount() {
    this.setState({ preferences: this.props.property.preferences });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ preferences: nextProps.property.preferences });
  }

  submit = (values) => {
    const {
      selfGuidedTourUrl: self_guided_tour_url,
      selfGuidedTourButtonLabel: self_guided_tour_button_label,
      toursExportOnlyFavoriteUnit: tours_export_only_favorite_unit,
    } = values;

    const {
      property: existingProperty,
      onChange,
    } = this.props;

    const existingPreferences = existingProperty.preferences;
    const existingJsonPrefs = (existingPreferences && existingPreferences.preferences) || {};

    const updatedProperty = {
      ...existingProperty,
      preferences: {
        ...existingPreferences,
        preferences: {
          ...existingJsonPrefs,
          self_guided_tour_url: self_guided_tour_url ? self_guided_tour_url : null,
          self_guided_tour_button_label: self_guided_tour_button_label ? self_guided_tour_button_label : null,
          tours_export_only_favorite_unit: tours_export_only_favorite_unit ? tours_export_only_favorite_unit : false,
        }
      },
    };

    this.setState({
      preferences: {
        ...this.state.preferences,
        ...updatedProperty.preferences,
      },
    });

    // This will update the props data so the new data will persist when we switch properties:
    existingProperty.preferences = updatedProperty.preferences;

    // Save updated property:
    onChange(updatedProperty);
  }

  validate = (values) => {
    const errors = {};

    if (values.selfGuidedTourUrl && !validateUrl(values.selfGuidedTourUrl)) {
      errors.selfGuidedTourUrl = 'Specified website is invalid';
    }

    return errors;
  }

  // get preferences from the property_preferences.preferences JSON column
  getJsonPreferences = () => {
    const preferences = this.state.preferences || {};
    return (preferences && preferences.preferences) || {};
  }

  // TODO: refactor checkboxes to use Formik
  render() {
    const {
      self_guided_tour_url: selfGuidedTourUrl,
      self_guided_tour_button_label: selfGuidedTourButtonLabel,
      tours_export_only_favorite_unit: toursExportOnlyFavoriteUnit,
    } = this.getJsonPreferences();

    return (
      <div className="property-info-edit-container">
        <Formik
          enableReinitialize
          initialValues={{
            propertyId: this.props.property.id, // will re-render when the property select option changes
            selfGuidedTourUrl: selfGuidedTourUrl ? selfGuidedTourUrl : '',
            selfGuidedTourButtonLabel: selfGuidedTourButtonLabel ? selfGuidedTourButtonLabel : '',
            toursExportOnlyFavoriteUnit: toursExportOnlyFavoriteUnit ? toursExportOnlyFavoriteUnit : false,
          }}
          validate={this.validate}
          onSubmit={this.submit}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="property-info-edit-formfield-container">
                <label htmlFor="requireProspectFloorplan" className="property-info-edit-formfield-container-label">Require prospect floorplan</label>
                <div>
                  <input
                    type="checkbox"
                    id="requireProspectFloorplan"
                    checked={this.state.preferences.require_prospect_floorplan}
                    onChange={this.settingUpdated('require_prospect_floorplan')}
                  />
                </div>
              </div>

              <div className="property-info-edit-formfield-container">
                <label htmlFor="requireProspectMoveInDate" className="property-info-edit-formfield-container-label">Require prospect move-in date</label>
                <div>
                  <input
                    type="checkbox"
                    id="requireProspectMoveInDate"
                    checked={this.state.preferences.require_prospect_move_in_date}
                    onChange={this.settingUpdated('require_prospect_move_in_date')}
                  />
                </div>
              </div>

              <div className="property-info-edit-formfield-container">
                <label htmlFor="toursExportOnlyFavoriteUnit" className="property-info-edit-formfield-container-label">Tours Export Only Favorite Unit</label>
                <div>
                  <input
                    type="checkbox"
                    id="toursExportOnlyFavoriteUnit"
                    checked={values.toursExportOnlyFavoriteUnit}
                    onChange={this.preferencesPreferencesUpdated('tours_export_only_favorite_unit')}
                  />
                </div>
              </div>

              <div className="property-info-edit-formfield-container">
                <div className="property-info-edit-formfield-container-label">Self-Guided Tour URL</div>
                <div>
                  <input
                    value={values.selfGuidedTourUrl}
                    id="selfGuidedTourUrl"
                    name="selfGuidedTourUrl"
                    onChange={handleChange}
                    onBlur={handleSubmit}
                    className="knock-react-input"
                    placeholder="http://www.yourtourwebsite.com"
                  />
                </div>
                {errors.selfGuidedTourUrl && (
                  <span className="knock-react-form-hint knock-react-form-hint-error">
                    {errors.selfGuidedTourUrl}
                  </span>
                )}
              </div>

              <div className="property-info-edit-formfield-container">
                <div className="property-info-edit-formfield-container-label">Self-Guided Tour Button Label</div>
                <div>
                  <input
                    value={values.selfGuidedTourButtonLabel}
                    id="selfGuidedTourButtonLabel"
                    name="selfGuidedTourButtonLabel"
                    onChange={handleChange}
                    onBlur={handleSubmit}
                    className="knock-react-input"
                    placeholder="Custom Label Text"
                  />
                </div>
              </div>

            </form>
          )}
        </Formik>
      </div>
    );
  }

  preferencesPreferencesUpdated = (key) => (event) => {
    const updatedProperty = {
      ...this.props.property,
      preferences: {
        ...this.props.property.preferences,
        preferences: {
          ...this.props.property.preferences.preferences,
          [key]: event.target.checked
        }
      },
    };

    this.setState({
      preferences: {
        ...this.state.preferences,
        preferences: {
          ...this.state.preferences.preferences,
          [key]: event.target.checked
        }
      },
    });
    this.props.property.preferences = updatedProperty.preferences;

    this.props.onChange(updatedProperty);
  }

  // TODO: move this logic into the submit method
  settingUpdated = (key) => (event) => {
    const updatedProperty = {
      ...this.props.property,
      preferences: {
        ...this.props.property.preferences,
        [key]: event.target.checked,
      },
    };

    this.setState({
      preferences: {
        ...this.state.preferences,
        [key]: event.target.checked,
      },
    });

    // This will update the props data so the new data will persist when we switch properties:
    this.props.property.preferences = updatedProperty.preferences;

    this.props.onChange(updatedProperty);
  }
}

PropertyInfoEditSettings.propTypes = {
  property: PropTypes.shape({
    id: PropTypes.number,
    preferences: PropTypes.shape({
      require_unit_scheduling: PropTypes.bool,
      require_prospect_floorplan: PropTypes.bool,
      require_prospect_move_in_date: PropTypes.bool,
      preferences: PropTypes.shape({
        self_guided_tour_url: PropTypes.string,
        self_guided_tour_button_label: PropTypes.string
      }),
    })
  }),
  onChange: PropTypes.func,
};

export default PropertyInfoEditSettings;
