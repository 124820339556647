import React, { useEffect, useState } from 'react';
import SourcesAPI from './SourcesAPI';

export const SPREAD_SHEET_LINK_TEXT = {
  GENERATE: 'Generate Google Docs Spreadsheet',
  VIEW: 'View Google Docs Spreadsheet',
};

const spreadSheetLinkInitialState = {
  url: '',
  linkText: '',
};

const getLinkData = (spreadsheetUrl, companyId) => {
  if (!spreadsheetUrl) {
    return {
      url: `/refresh-spreadsheet/${companyId}`,
      linkText: SPREAD_SHEET_LINK_TEXT.GENERATE,
    };
  }

  return {
    url: spreadsheetUrl,
    linkText: SPREAD_SHEET_LINK_TEXT.VIEW,
  };
};

const SpreadsheetLink = () => {
  const [spreadsheetLink, setSpreadSheetLink] = useState(
    spreadSheetLinkInitialState
  );

  const fetchSpreadsheetUrl = () => {
    SourcesAPI.getSpreadsheetUrl().then(({ spreadsheet_url, company_id }) => {
      setSpreadSheetLink(getLinkData(spreadsheet_url, company_id));
    });
  };

  useEffect(() => {
    if (!spreadsheetLink.url) {
      fetchSpreadsheetUrl();
    }
  }, [spreadsheetLink.url]);

  return (
    <div>
      <a
        className="spreadsheet-link"
        href={spreadsheetLink.url}
        target="_blank"
      >
        {spreadsheetLink.linkText}
      </a>
    </div>
  );
};

export default SpreadsheetLink;
