import React, { useState } from 'react';
import { LoadingOverlay, ThemeProvider } from '@knockrentals/knock-shared-web';

import FeatureDrawer from '../common/FeatureDrawer';
import { BrandContentDataTable } from './BrandContentDataTable';
import { BrandContentDrawer } from './BrandContentDrawer';
import { ManageButton } from './ManageButton';
import PageDescription from './PageDescription';
import { ErrorDialog } from '../common/ErrorDialog';
import { useBrandContent } from './use-brand-content';
import { useMergeTags } from '../common/MergeTags';

import './_BrandContentPage.scss';

const BrandContentPage = () => {
  const [propertyList, setPropertyList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const { mergeTags } = useMergeTags();

  const {
    getCurrentPagePropertyCount,
    getSelectedProperties,
    getTableData,
    isLoadingOverlayVisible,
    propertySelectionMap,
    reloadBrandContent,
    rowsPerPage,
    rowsPerPageOptions,
    selectAllOnPage,
    selectedPropertyCount,
    setPageIndex,
    tableRef,
    totalPropertyCount,
    updateAllPropertiesSelections,
    updatePropertySelection,
  } = useBrandContent({ setErrorMessage });

  const handleEditActionOnClick = (propertyId) => {
    const property = propertySelectionMap[propertyId];
    setPropertyList([property]);
  };

  const handleManageOnClick = () => {
    setPropertyList(getSelectedProperties());
  };

  const closeDrawer = () => {
    setPropertyList([]);
  };

  const closeErrorDialog = () => {
    setErrorMessage('');
    if (propertyList.length > 0) {
      closeDrawer();
    }
  };

  return (
    <ThemeProvider>
      <section className="brand-content-page">
        <PageDescription />

        <LoadingOverlay open={isLoadingOverlayVisible} />

        <ErrorDialog
          closeDialog={closeErrorDialog}
          errorMessage={errorMessage}
          isOpen={Boolean(errorMessage)}
        />

        <FeatureDrawer disableEnforceFocus isOpen={propertyList.length > 0}>
          <BrandContentDrawer
            closeDrawer={closeDrawer}
            mergeTags={mergeTags}
            propertyList={propertyList}
            reloadBrandContent={reloadBrandContent}
            setErrorMessage={setErrorMessage}
          />
        </FeatureDrawer>

        <ManageButton
          onClick={handleManageOnClick}
          selectedCount={selectedPropertyCount}
        />

        <BrandContentDataTable
          getCurrentPagePropertyCount={getCurrentPagePropertyCount}
          getTableData={getTableData}
          handleEditActionOnClick={handleEditActionOnClick}
          propertyCount={totalPropertyCount}
          propertySelectionMap={propertySelectionMap}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          selectAllOnPage={selectAllOnPage}
          setPageIndex={setPageIndex}
          tableRef={tableRef}
          updateAllPropertiesSelections={updateAllPropertiesSelections}
          updatePropertySelection={updatePropertySelection}
        />
      </section>
    </ThemeProvider>
  );
};

export default BrandContentPage;
