import { aiRemote, responseHandler } from '../../Services/RemoteService';

export const getVirtualAgents = (companyId, page, pageSize) => {
  return aiRemote
    .get(`/v1/pmcs/${companyId}/virtual-agents?page=${page}&size=${pageSize}`)
    .then((response) => responseHandler(response));
};

export const getVirtualAgentsByPropertyIds = (propertyIds, page, pageSize) => {
  return aiRemote
    .get(
      `/v1/properties/${propertyIds}/virtual-agents?page=${page}&size=${pageSize}`
    )
    .then((response) => responseHandler(response));
};

export const createVirtualAgent = (virtualAgent) => {
  return aiRemote
    .post('/v1/virtual-agents/', {
      pmc_id: virtualAgent.companyId,
      is_chat_enabled: virtualAgent.isChatEnabled,
      property_id: virtualAgent.propertyId,
      call_routing: virtualAgent.callRouting,
      offer_time: virtualAgent.offerTime,
      voice_id: virtualAgent.voiceId,
      voice_rate: virtualAgent.voiceRate,
      is_answer_verification: virtualAgent.answerVerification,
      is_sms_enabled: virtualAgent.isSmsEnabled,
      rpcc_agent_rollover: virtualAgent.rpccAgentRollover,
      is_gen_ai_voice_enabled: virtualAgent.isGenAiVoiceEnabled,
      is_gen_ai_chat_enabled: virtualAgent.isGenAiChatEnabled,
      is_gen_ai_sms_enabled: virtualAgent.isGenAiSmsEnabled,
      ...(virtualAgent.isTranscribed !== undefined
        ? { is_transcribed: virtualAgent.isTranscribed }
        : {}),
      ...(virtualAgent.isScored !== undefined
        ? { is_scored: virtualAgent.isScored }
        : {}),
      ...(typeof virtualAgent.residentVirtualAgentVoice === 'boolean' && {
        resident_virtual_agent_voice: virtualAgent.residentVirtualAgentVoice,
      }),
      ...(typeof virtualAgent.residentVirtualAgentSms === 'boolean' && {
        resident_virtual_agent_sms: virtualAgent.residentVirtualAgentSms,
      }),
    })
    .then((response) => responseHandler(response));
};

export const updateVirtualAgent = (virtualAgent) => {
  const virtualAgentPropertyMap = {
    isChatEnabled: 'is_chat_enabled',
    isSmsEnabled: 'is_sms_enabled',
    rpccAgentRollover: 'rpcc_agent_rollover',
    isTranscribed: 'is_transcribed',
    isScored: 'is_scored',
    callRouting: 'call_routing',
    offerTime: 'offer_time',
    voiceId: 'voice_id',
    voiceRate: 'voice_rate',
    chatRollover: 'chat_rollover',
    answerVerification: 'is_answer_verification',
    isGenAiVoiceEnabled: 'is_gen_ai_voice_enabled',
    isGenAiChatEnabled: 'is_gen_ai_chat_enabled',
    isGenAiSmsEnabled: 'is_gen_ai_sms_enabled',
    residentVirtualAgentVoice: 'resident_virtual_agent_voice',
    residentVirtualAgentSms: 'resident_virtual_agent_sms',
    scheduleTourVaEnabled: 'schedule_tour_va_enabled',
    pnaVaEnabled: 'pna_va_enabled',
  };

  const fieldName =
    virtualAgentPropertyMap[virtualAgent.field] || virtualAgent.field;

  const payload = {
    property_id: virtualAgent.propertyId,
    [fieldName]: virtualAgent.value,
  };

  return aiRemote
    .put(`/v1/virtual-agents/${virtualAgent.virtualAgentId}`, payload)
    .then((response) => responseHandler(response));
};

export const getIntents = (page, pageSize) => {
  return aiRemote
    .get(`/v1/intents/?page=${page}&size=${pageSize}`)
    .then((response) => responseHandler(response));
};

export const getLeasingIntentsV2 = () => {
  return aiRemote
    .get('/v2/intents/')
    .then((response) => responseHandler(response));
};

export const getResidentIntentsV2 = () => {
  return aiRemote
    .get('/v2/maintenance-intents/')
    .then((response) => responseHandler(response));
};

export const getLeasingResponsesV2 = (propertyId, intentId) => {
  return aiRemote
    .get(`/v2/responses/?property_id=${propertyId}&intent_id=${intentId}`)
    .then((response) => responseHandler(response));
};

export const getResidentResponsesV2 = (propertyId, intentId) => {
  return aiRemote
    .get(
      `/v2/maintenance-responses/?intent_id=${intentId}&source_id=${propertyId}&source=KNCK`
    )
    .then((response) => responseHandler(response));
};

export const saveLeasingResponsesV2 = (propertyId, intentId, responses) => {
  const { voiceResponse, chatResponse, smsResponse } = responses;
  const payload = {
    intent_id: intentId,
    property_id: propertyId,
    voice_response: voiceResponse,
    chat_response: chatResponse,
    sms_response: smsResponse,
  };

  return aiRemote
    .put(`/v2/responses/`, payload)
    .then((response) => responseHandler(response));
};

export const saveResidentResponsesV2 = (propertyId, intentId, responses) => {
  const { voiceResponse, chatResponse, smsResponse } = responses;
  const payload = {
    intent_id: intentId,
    source_id: propertyId,
    voice_response: voiceResponse,
    chat_response: chatResponse,
    sms_response: smsResponse,
    source_type: 'PROPERTY',
  };

  return aiRemote
    .post(`/v2/maintenance-responses/`, payload)
    .then((response) => responseHandler(response));
};

export const copyVoiceResponsesToChat = (companyId) => {
  return aiRemote
    .post(`/v1/pmcs/responses/copy-voice-to-chat`, { pmc_id: companyId })
    .then((response) => responseHandler(response));
};

export const copyVoiceResponsesToChatV2 = (propertyIds, copyDest) => {
  let endpoint = `/v2/responses/copy-voice-to-chat`;
  endpoint = copyDest
    ? endpoint.concat(`?destination_override=${copyDest}`)
    : endpoint;
  return aiRemote
    .post(endpoint, { property_ids: propertyIds })
    .then((response) => responseHandler(response));
};

export const downloadLeasingPropertyResponses = (properties) => {
  const payload = {
    properties: properties.map((property) => ({
      property_id: property.propertyId,
      property_name: property.propertyName,
    })),
  };
  return aiRemote
    .post(`/v3/responses/export-to-csv`, payload)
    .then((response) => response.text());
};

export const downloadResidentPropertyResponses = (properties) => {
  const sources = properties.map((property) => ({
    source_id: `${property.propertyId}`,
    source: 'KNCK',
    property_name: property.propertyName,
  }));
  return aiRemote
    .post(`/v3/maintenance-responses/export-to-csv`, { sources })
    .then((response) => response.text());
};

export const getGenAiResponses = (propertyId, genAiChannels) => {
  return aiRemote
    .post(`/v2/properties/${propertyId}/generate-ai-responses`, genAiChannels)
    .then((response) => responseHandler(response));
};

export const getProductsEnabled = (companyId, propertyId) => {
  return aiRemote
    .get(`/v2/pmcs/${companyId}/property/${propertyId}/products_enabled`)
    .then((response) => responseHandler(response));
};

export const saveProductsEnabled = (payload) => {
  return aiRemote
    .post('/v2/pmcs/products_enabled', payload)
    .then((response) => responseHandler(response));
};

export const downloadProductsEnabledConfig = (companyId, properties) => {
  let payload = { pmc_id: companyId, properties: properties };

  return aiRemote
    .post(`/v2/pmcs/products_enabled/export-to-csv`, payload)
    .then((response) => response.text());
};
export const downloadVirtualAgentConfig = (companyId, properties) => {
  let payload = { pmc_id: companyId, properties: properties };

  return aiRemote
    .post(`/v2/pmcs/virtual-agent-config/export-to-csv`, payload)
    .then((response) => response.text());
};
export const downloadInternalConfig = (companyId, properties) => {
  let payload = { pmc_id: companyId, properties: properties };

  return aiRemote
    .post(`/v2/pmcs/internal-config/export-to-csv`, payload)
    .then((response) => response.text());
};

export const getVoicesList = () => {
  return aiRemote
    .get(`/v1/voices/?page=1`)
    .then((response) => responseHandler(response));
};
