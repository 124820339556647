import React from 'react';
import PropTypes from 'prop-types';

import DataTable from 'material-table';
import { Paper } from '@knockrentals/knock-shared-web';

import { Pagination } from './Pagination';
import { getColumns } from './table-columns';
import { getTableActions } from './table-actions';
import { getTableOptions } from './table-options';
import './_QuickReplyGrid.scss';

//TODO get tooltip text
export const EDIT_ACTION_TOOLTIP = 'Edit quick reply';

const CompanyQuickRepliesGrid = ({
  getTableData,
  handleCreateQuickReply,
  handleEditQuickReply,
  rowsPerPage,
  rowsPerPageOptions,
  setPageIndex,
  tableRef,
}) => {
  const handleOnPageChange = (index) => {
    setPageIndex(index);
  };

  const handleEditActionOnClick = (quickReplyId) => {
    handleEditQuickReply({ quickReplyId });
  };

  const tableColumns = getColumns();

  const tableActions = getTableActions(
    handleEditActionOnClick,
    EDIT_ACTION_TOOLTIP
  );

  return (
    <div className="quick-reply-data-table">
      <DataTable
        actions={tableActions}
        columns={tableColumns}
        components={{
          Container: (props) => <Paper {...props} elevation={1} />,
          Pagination: (props) => (
            <Pagination
              {...props}
              handleCreateQuickReply={handleCreateQuickReply}
            />
          ),
        }}
        data={getTableData}
        onChangePage={handleOnPageChange}
        options={getTableOptions(rowsPerPage, rowsPerPageOptions)}
        style={{ minWidth: '768px', overflow: 'hidden' }}
        tableRef={tableRef}
      />
    </div>
  );
};

CompanyQuickRepliesGrid.propTypes = {
  getTableData: PropTypes.func,
  handleCreateQuickReply: PropTypes.func,
  handleEditQuickReply: PropTypes.func,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  setPageIndex: PropTypes.func,
  tableRef: PropTypes.shape({ current: PropTypes.object }),
};

export default CompanyQuickRepliesGrid;
