import React from 'react';
import PropTypes from 'prop-types';
import MenuButton from '../../../Components/MenuButton';
import {
  DRAWER_MODE_OPTIONS,
  TEMPLATE_TABLE_CONTENT_TYPES,
  VIEWS,
} from './constants';
import './_DripCampaignPage.scss';

const MENU_BUTTON_OPTIONS = [
  { label: 'Campaign', value: DRAWER_MODE_OPTIONS.CAMPAIGN },
  { label: 'Email Template', value: DRAWER_MODE_OPTIONS.EMAIL },
  { label: 'SMS Template', value: DRAWER_MODE_OPTIONS.SMS },
];

const CreateNewMenuButton = ({ setDrawerData, setMenuView }) => {
  const getSelectionData = (value) => {
    if (value === DRAWER_MODE_OPTIONS.CAMPAIGN) {
      return [VIEWS.CAMPAIGN, {}];
    }

    const drawerData = {
      contentType:
        value === DRAWER_MODE_OPTIONS.SMS
          ? TEMPLATE_TABLE_CONTENT_TYPES.SMS
          : TEMPLATE_TABLE_CONTENT_TYPES.EMAIL,
    };
    return [VIEWS.TEMPLATE, drawerData];
  };

  const handleMenuButtonChange = (_e, value) => {
    const [view, drawerData] = getSelectionData(value);
    setMenuView(view);
    setDrawerData(drawerData);
  };

  return (
    <MenuButton
      buttonText="Create New"
      options={MENU_BUTTON_OPTIONS}
      onSelect={handleMenuButtonChange}
    />
  );
};

CreateNewMenuButton.propTypes = {
  setDrawerData: PropTypes.func,
  setMenuView: PropTypes.func,
};
export default CreateNewMenuButton;
