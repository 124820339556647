import React, { useEffect, useState } from 'react';
import { SourcePropertiesDataTable } from './SourcePropertiesDataTable';
import { ErrorAlert } from '../ErrorAlert';
import { getSortedProperties, normalizePropertiesMap } from '../utils';
import SourcesAPI from '../../SourcesAPI';
import { ERROR_MESSAGES } from '../constants';

const SourceProperties = ({
  propertiesMap = {},
  sourceId,
  updatePropertiesMap,
  updateSourceProperties,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorSeverity, setErrorSeverity] = useState('');

  const fetchSourceProperties = async () => {
    try {
      const { properties = {} } = await SourcesAPI.getCompanyMarketingSourceProperties(sourceId);

      if (properties === null) {
        setErrorSeverity('info');
        setErrorMessage(ERROR_MESSAGES.COMPANY_SOURCE_PROPERTIES_NOT_FOUND);
        return {};
      }

      return properties;
    } catch (_e) {
      setErrorSeverity('error');
      setErrorMessage(ERROR_MESSAGES.COMPANY_SOURCE_PROPERTIES);

      return {};
    }
  };

  const handleFetchProperties = async () => {
    setIsFetching(true);
    const leasing_teams = await fetchSourceProperties();
    updatePropertiesMap(normalizePropertiesMap(leasing_teams));
    setIsFetching(false);
  };

  useEffect(() => {
    const shouldFetchSourceProperties =
      Object.keys(propertiesMap).length === 0 && !isFetching && !errorMessage;

    if (shouldFetchSourceProperties) {
      handleFetchProperties();
    }
  }, [handleFetchProperties, propertiesMap, isFetching, errorMessage]);

  const handleUpdateSourceProperties = (updatedMappedProperties) => {
    updateSourceProperties(updatedMappedProperties);
  };

  const sortedProperties = getSortedProperties(propertiesMap);

  return (
    <div>
      {/* TODO - consult with UX for loading state */}
      {isFetching && <div data-testid="loading-properties-indicator" />}

      <ErrorAlert isVisible={!!errorMessage} alertMessage={errorMessage} severity={errorSeverity} />

      {sortedProperties && sortedProperties.length > 0 && (
        <SourcePropertiesDataTable
          properties={sortedProperties}
          updateSourceProperties={handleUpdateSourceProperties}
        />
      )}
    </div>
  );
};

export default SourceProperties;
