import React, { useEffect, useState } from 'react';
import { Pagination } from '../Features/common/Pagination';
import DataTable from 'material-table';
import {
    Button,
    Chip,
    IconButton,
    makeStyles,
    NamedColors,
    Paper,
} from '@knockrentals/knock-shared-web';
import { DeleteSvgIcon } from '../../Components/Icons';
import { deleteUser, getUsers } from './SSDAGraphQLAPI';
import { NotificationService } from '../../Components/Notifications';
import { getDefaultTableOptions } from '../../Services/SharedService';
import ConfirmationDialog from '../Features/common/ConfirmationDialog';

const CONFIRMATION = {
    MESSAGE:
        'Are you sure you want to delete selected users? They will lose access to the Knock Data Cloud Features.',
    PRIMARY_BUTTON_TEXT: 'Yes, Delete',
    SECONDARY_BUTTON_TEXT: 'Do not Delete',
    TITLE: 'Delete User',
};

const ERROR = 'error';
const SUCCESS = 'success';

const useStyles = makeStyles((theme) => ({
    deleteBtn: {
        color: theme.palette.error.dark,
        marginBottom: '10px'
    },
    deleteSelectedButtonContainer: {
        textAlign: 'end'
    },
    [ERROR]: {
        backgroundColor: NamedColors.apple[100],
        textTransform: 'capitalize',
    },
    [SUCCESS]: {
        backgroundColor: NamedColors.grass[100],
        textTransform: 'capitalize',
    },
    selectionCheckbox: {
        '& svg': {
            zIndex: 'unset',
        }
    },
}));

const SSDATable = () => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [usersToDelete, setUsersToDelete] = useState([]);

    useEffect(() => {
        const getUserData = async () => {
            try {
                const { userInfo = [] } = await getUsers({
                    pageSize: rowsPerPage,
                    pageIndex: page * rowsPerPage,
                });
                setUserInfo(userInfo);
            } catch (error) {
                NotificationService.notify('Error fetching SSDA user data');
            }
        };
        getUserData();
    }, [rowsPerPage, page]);

    const deleteUsers = async (usersToDelete) => {
        try {
            await deleteUser(usersToDelete);
        } catch (error) {
            NotificationService.notify('Error deleting SSDA user data');
        }
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteUsers({ usernames: usersToDelete });
            NotificationService.notify('User deleted successfully');
            usersToDelete.length === 1
                ? selectedUsers.filter((username) => username !== usersToDelete)
                : setUsersToDelete([]);
        } catch (error) {
            NotificationService.notify('Error deleting SSDA user data');
        } finally {
            setUsersToDelete([]);
        }
    };

    const renderUserStatus  = (status) => {
        const userStatus = status === 'fail' ? ERROR : SUCCESS;
        return <Chip label={userStatus} className={classes[userStatus]} />;
    };

    const columns = [
        {
            emptyValue: '-',
            field: 'status',
            title: 'Status',
            align: 'left',
            render: (user) => renderUserStatus(user?.status)
        },
        {
            emptyValue: '-',
            field: 'username',
            title: 'User Name',
            align: 'left',
        },
        {
            emptyValue: '-',
            field: 'fullName',
            title: 'Full Name',
            align: 'left',
        },
        {
            emptyValue: '-',
            field: 'email',
            title: 'Contact Email',
            align: 'left',
        },
        {
            title: 'Actions',
            align: 'left',
            render: (user) => (
                <IconButton
                    className={classes.deleteBtn}
                    onClick={() => {
                        setUsersToDelete([user.username]);
                    }}
                    aria-label="Delete User"
                >
                    <DeleteSvgIcon fontSize="large" />
                </IconButton>
            ),
        },
    ];

    const defaultTableOptions = getDefaultTableOptions();

    const options = {
        ...defaultTableOptions,
        paging: true,
        pageSize: rowsPerPage,
        pageSizeOptions: [10, 20, 30],
        search: false,
        selection: true,
        selectionProps: () => ({
            color: 'primary',
            size: 'medium',
            className: classes.selectionCheckbox,
        }),
        showFirstLastPageButtons: false,
        sorting: false,
        toolbar: false,
    };

    return (
        <div className="knock-data-cloud-user-table">
            <ConfirmationDialog
                closeDialog={() => {
                    setUsersToDelete([]);
                }}
                handleConfirmation={handleDeleteConfirm}
                isOpen={usersToDelete.length > 0}
                message={CONFIRMATION.MESSAGE}
                primaryButtonText={CONFIRMATION.PRIMARY_BUTTON_TEXT}
                secondaryButtonText={CONFIRMATION.SECONDARY_BUTTON_TEXT}
                title={CONFIRMATION.TITLE}
            />

            <div className={classes.deleteSelectedButtonContainer}>
                <Button
                    className={classes.deleteBtn}
                    disabled={selectedUsers.length === 0}
                    onClick={() => setUsersToDelete(selectedUsers)}
                    startIcon={<DeleteSvgIcon />}
                    variant="text"
                >
                    Delete Selected
                </Button>
            </div>

            <DataTable
                columns={columns}
                components={{
                    Container: (props) => (
                        <Paper {...props} elevation={4} className="data-table-paper" />
                    ),
                    Pagination: Pagination,
                }}
                data={userInfo}
                onChangePage={(index) => {
                    setPage(index);
                }}
                options={options}
                onChangeRowsPerPage={(value) => {
                    setRowsPerPage(value);
                }}
                onSelectionChange={(rows) => {
                    const selectedUsernames = rows.map((row) => row.username);
                    setSelectedUsers(selectedUsernames);
                }}
            />
        </div>
    );
};

export default SSDATable;
