import React, { Component } from 'react';
import SourcesAPI from './SourcesAPI';
import SourceListTable from './SourceListTable';
import {
  PageContainer,
  Toaster,
  Alert,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '@knockrentals/knock-react';
import UsersAPI from '../Users/UsersAPI';

class SourceListPage extends Component {
  state = {
    isLoading: false,
    selectedAccountIndex: 'all',
    accountsMap: {},
    accounts: [],
    applyToAllTeamsId: null,
  };

  componentDidMount() {
    this.loadData();
  }

  async loadData() {
    try {
      this.setState({ isLoading: true });

      const { leasing_teams } = await UsersAPI.getLeasingTeamsList();
      const sortedLeasingTeams = leasing_teams.sort((groupA, groupB) => {
        return groupA.name > groupB.name ? 1 : -1;
      });

      this.setState({ accounts: sortedLeasingTeams, isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      Toaster.showToast(
        'Error loading sources in source-list',
        2000,
        Toaster.ToastClasses.error
      );
    }
  }

  render() {
    const { isLoading, applyToAllTeamsId, selectedAccountIndex } = this.state;
    return (
      <PageContainer isLoading={isLoading}>
        <h1>Source list</h1>

        {applyToAllTeamsId && this.renderApplyToAllModal()}

        <div className="knock-react-flex knock-react-flex-row">
          <div>
            <div>Leasing team</div>
            <select
              value={selectedAccountIndex}
              onChange={this.onAccountChanged.bind(this)}
            >
              <option value={'all'}>All</option>
              {this.renderAccountOptions()}
            </select>
          </div>
        </div>
        {this.renderAccounts()}
      </PageContainer>
    );
  }

  renderApplyToAllModal() {
    return (
      <Modal>
        <ModalHeader>
          <h3>Apply to all</h3>
        </ModalHeader>

        <ModalContent>
          <p>
            Are you sure you want to apply these sources and groups to all
            teams? Once completed, this cannot be undone.
          </p>
        </ModalContent>

        <ModalFooter>
          <button
            className="knock-react-button"
            onClick={this.confirmApplyToAll.bind(this)}
          >
            Yes, I'm sure
          </button>
          <button
            className="knock-react-button"
            onClick={this.cancelApplyToAll.bind(this)}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  updateAccountSources(accountId, sources) {
    this.setState((prevState) => {
      return {
        accountsMap: {
          ...prevState.accountsMap,
          [accountId]: sources,
        },
      };
    });
  }

  onAccountChanged(event) {
    this.setState({ selectedAccountIndex: event.target.value });
  }

  renderAccountOptions() {
    const { accounts } = this.state;

    return accounts.map((account, index) => (
      <option key={'account-' + account.id} value={index}>
        {account.name}
      </option>
    ));
  }

  renderAccounts() {
    const { accountsMap, accounts, selectedAccountIndex } = this.state;

    if (!accounts.length) {
      return <Alert>No properties to display.</Alert>;
    }

    if (selectedAccountIndex === 'all') {
      return accounts.map((account) => {
        const sources = accountsMap[account.id] || [];

        return (
          <SourceListTable
            updateAccountSources={this.updateAccountSources.bind(this)}
            key={account.id}
            account={account}
            sources={sources || []}
            onSourceChanged={this.onSourceChanged.bind(this)}
            onStartApplyToAllTeams={this.onStartApplyToAllTeams.bind(this)}
          />
        );
      });
    }

    const selectedAccount = accounts[this.state.selectedAccountIndex];

    return (
      <SourceListTable
        updateAccountSources={this.updateAccountSources.bind(this)}
        key={selectedAccount.id}
        account={selectedAccount}
        sources={accountsMap[selectedAccount.id] || []}
        onSourceChanged={this.onSourceChanged.bind(this)}
        onStartApplyToAllTeams={this.onStartApplyToAllTeams.bind(this)}
      />
    );
  }

  async onSourceChanged(accountId, sourceId, isEnabled, parentSourceId) {
    if (parentSourceId === 'None') {
      parentSourceId = null;
    }

    try {
      await SourcesAPI.updateAccountSource(
        accountId,
        sourceId,
        isEnabled,
        parentSourceId
      );

      this.setState((prevState) => {
        const updatedAccountsMap = {
          ...prevState.accountsMap,
          [accountId]: this.updateSources(
            prevState.accountsMap[accountId],
            sourceId,
            isEnabled,
            parentSourceId
          ),
        };
  
        return {
          accountsMap: updatedAccountsMap,
        };
      });

      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);

    } catch (error) {
      Toaster.showToast(
        'Error saving a source in source-list',
        2000,
        Toaster.ToastClasses.error
      );
    }
  }

  updateSources(sources, sourceId, isEnabled, parentSourceId) {
    return sources.map((source) => {
      if (source.source_id === sourceId) {
        return {
          ...source,
          is_enabled: isEnabled,
          parent_source_id: parentSourceId,
        };
      }
      return { ...source };
    });
  }

  async saveSource(updatedAccountId, sourceId, isEnabled, parentSourceId) {
    if (parentSourceId === 'None') {
      parentSourceId = null;
    }

    try {
      await SourcesAPI.updateAccountSource(
        updatedAccountId,
        sourceId,
        isEnabled,
        parentSourceId
      );

      this.setState((prevState) => {
        const updatedAccountsMap = {
          ...prevState.accountsMap,
          [updatedAccountId]: this.updateSources(
            prevState.accountsMap[updatedAccountId],
            sourceId,
            isEnabled,
            parentSourceId
          ),
        };
  
        return {
          accountsMap: updatedAccountsMap,
        };
      });

      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);

    } catch (error) {
      Toaster.showToast(
        'Error saving a source in source-list',
        2000,
        Toaster.ToastClasses.error
      );
    }
  }

  onStartApplyToAllTeams(leasingTeamId) {
    this.setState({ applyToAllTeamsId: leasingTeamId });
  }

  confirmApplyToAll() {
    return SourcesAPI.applySettingsToAll(this.state.applyToAllTeamsId).then(
      () => {
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        this.cancelApplyToAll();
        this.setState({ accountsMap: {} });
        this.loadData();
      }
    );
  }

  cancelApplyToAll() {
    this.setState({ applyToAllTeamsId: null });
  }
}

export default SourceListPage;
