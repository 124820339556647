import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FacebookProviderLogin from '../FacebookProviderLogin';
import ErrorMessage from '../../ErrorMessage';


class LoginForm extends Component {
  static propTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    error: PropTypes.string,
  }

  handleSubmit = (event) => {
    event.preventDefault();
  }

  render() {
    const {
      onSuccess,
      onError,
      error,
    } = this.props;

    const styles = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      textAlign: 'center',
    };

    return (
      <form
        style={styles}
        onSubmit={this.handleSubmit}
        autoComplete='off'
      >
        <FacebookProviderLogin
            onResponse={onSuccess}
            onError={onError}
        />

        { error && <ErrorMessage message={error} /> }
      </form>
    );
  }
}

export default LoginForm;
