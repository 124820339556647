import React, { Component } from 'react';
import AdminUsers from './AdminUsers';
import './_adminsPage.scss';
import { PageContainer } from '@knockrentals/knock-react';

class AdminsPage extends Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <PageContainer className="admin-page-container" isLoading={this.state.isLoading}>
        <AdminUsers />
      </PageContainer>
    );
  }
}

export default AdminsPage;
