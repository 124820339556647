import { STATUS } from '../constants';

export const TEMPLATE = {
  MESSAGE: 'message',
  SUBJECT: 'subject',
  TEMPLATE_NAME: 'templateName',
};

export const PREVIEW_SMS = 'SMS MESSAGE';

export const TEMPLATE_LABELS = {
  EMAIL_CONTENT: 'Email Content',
  MESSAGE: 'Message',
  SUBJECT: 'Subject',
  TEMPLATE_NAME: 'Template Title',
};

export const PLACE_HOLDER_TEXT = {
  MESSAGE: 'Add your message here',
  SUBJECT: 'Subject',
  TEMPLATE_NAME: 'Add a unique name for this template',
};

export const TEMPLATE_COMPOSE_HEADER = {
  EMAIL: 'Email',
  EMAIL_COMPOSE_MESSAGE: 'Compose an email template',
  SMS: 'SMS Message',
  SMS_COMPOSE_MESSAGE: 'Compose an SMS text message template',
};

export const TEMPLATE_FPO_MESSAGE =
  'Template name is for internal tracking only and will not appear in the actual message';

export const CONFIRMATION_MODES = {
  ACTIVATE: 'activate',
  ARCHIVE: 'archive',
  BACK: 'back',
  CANCEL: 'cancel',
  DELETE_ARCHIVE: 'delete-archive',
  DELETE_DRAFT: 'delete-draft',
  DUPLICATE: 'duplicate',
  LIVE: 'live',
  SAVE: 'save',
  SAVE_DRAFT: 'save-draft',
  SAVE_DRAFT_EDIT: 'save-draft-edit',
  SAVE_PROPERTY_CHANGE: 'save-property-change',
};

export const TEMPLATE_CONFIRMATION_MESSAGES = {
  [CONFIRMATION_MODES.ACTIVATE]:
    'This Template will be ready for use. Once active it will not be editable.',
  [CONFIRMATION_MODES.ARCHIVE]:
    'This will remove the Template from active use. This may impact active Campaigns. All stats will be retained. The Template can be duplicated and made active again in the future.',
  [CONFIRMATION_MODES.BACK]:
    'Going back now will close this tray and this work will be lost. Are you sure you want to go back?',
  [CONFIRMATION_MODES.CANCEL]:
    'Canceling now will close all open trays and all work will be lost. Are you sure you want to cancel?',
  [CONFIRMATION_MODES.DELETE_ARCHIVE]:
    'This will permanently delete the Template and all associated data. If you want to use this as a starting point for another template, you can duplicate and edit.',
  [CONFIRMATION_MODES.DELETE_DRAFT]:
    'This will delete the Template and all associated data. If you want to change some details, consider continuing to edit.',
  [CONFIRMATION_MODES.DUPLICATE]:
    'This will create a new Template that can be edited and will have its own tracking data. The original Template and its tracking data will not be changed.',
  [CONFIRMATION_MODES.SAVE_DRAFT]:
    'This will create a new Template that will be inactive but available to edit until it is made live.',
  [CONFIRMATION_MODES.SAVE]:
    'This Template will be ready for use once saved. It will not be editable.',
  [CONFIRMATION_MODES.SAVE_DRAFT_EDIT]:
    'Save this template as a draft version that will be inactive but available to edit until it is made live.',
};

export const CAMPAIGN_CONFIRMATION_MESSAGES = {
  [CONFIRMATION_MODES.ARCHIVE]:
    'This will remove the Campaign from active use. All stats will be retained. The campaign can be duplicated and made active again in the future.',
  [CONFIRMATION_MODES.BACK]:
    'Going back now will close this tray and this work will be lost. Are you sure you want to go back?',
  [CONFIRMATION_MODES.CANCEL]:
    'Canceling now will close all trays and all work will be lost. Are you sure you want to cancel?',
  [CONFIRMATION_MODES.DELETE_ARCHIVE]:
    'This will permanently delete the Campaign and all associated data. If you want to use this as a starting point for another campaign, you can duplicate and edit.',
  [CONFIRMATION_MODES.DELETE_DRAFT]:
    'This will delete the Campaign and all associated data. If you want to change some details, consider continuing to edit.',
  [CONFIRMATION_MODES.DUPLICATE]:
    'This will create a new Campaign that can be edited and will have its own tracking data. The original Campaign and its tracking data will not be changed.',
  [CONFIRMATION_MODES.LIVE]:
    'This Campaign will be ready for distribution once it is activated by the triggering event. Once live it will not be editable except for which properties are included.',
  [CONFIRMATION_MODES.SAVE_DRAFT]:
    'This will create a new Campaign that will be inactive but available to edit until it is made live.',
  [CONFIRMATION_MODES.SAVE_DRAFT_EDIT]:
    'Save this campaign as a draft version that will be inactive but available to edit until it is made live.',
  [CONFIRMATION_MODES.SAVE_PROPERTY_CHANGE]:
    'This will save the change to the selected properties for this campaign',
};

export const CONFIRMATION_BUTTON_TEXT = {
  [CONFIRMATION_MODES.ACTIVATE]: 'Make Active',
  [CONFIRMATION_MODES.ARCHIVE]: 'OK, Archive it',
  [CONFIRMATION_MODES.BACK]: 'Yes, Go Back',
  [CONFIRMATION_MODES.CANCEL]: 'Yes, Close Tray',
  [CONFIRMATION_MODES.DELETE_ARCHIVE]: 'Delete it',
  [CONFIRMATION_MODES.DELETE_DRAFT]: 'Delete it',
  [CONFIRMATION_MODES.DUPLICATE]: 'OK, Duplicate it',
  [CONFIRMATION_MODES.SAVE_DRAFT_EDIT]: 'Yes, Save As Draft',
  [CONFIRMATION_MODES.LIVE]: 'Yes, Make Live',
  [CONFIRMATION_MODES.SAVE_DRAFT]: 'Yes, Save As Draft',
  [CONFIRMATION_MODES.SAVE]: 'Yes, Save',
  [CONFIRMATION_MODES.SAVE_PROPERTY_CHANGE]: 'Save Change',
};

export const CONFIRMATION_SECONDARY_BUTTON_TEXT = {
  [CONFIRMATION_MODES.BACK]: 'No, Stay Here',
  [CONFIRMATION_MODES.CANCEL]: 'No, Keep Open',
};

export const CONFIRMATION_TITLES = {
  [CONFIRMATION_MODES.ACTIVATE]: 'Make Active',
  [CONFIRMATION_MODES.ARCHIVE]: 'Archive Template',
  [CONFIRMATION_MODES.BACK]: 'Go Back',
  [CONFIRMATION_MODES.CANCEL]: 'Cancel',
  [CONFIRMATION_MODES.DELETE_ARCHIVE]: 'Delete Archive',
  [CONFIRMATION_MODES.DELETE_DRAFT]: 'Delete Draft',
  [CONFIRMATION_MODES.DUPLICATE]: 'Duplicate & Edit',
  [CONFIRMATION_MODES.LIVE]: 'Make Live',
  [CONFIRMATION_MODES.SAVE_DRAFT]: 'Save As Draft',
  [CONFIRMATION_MODES.SAVE_DRAFT_EDIT]: 'Save As Draft',
  [CONFIRMATION_MODES.SAVE]: 'Save',
  [CONFIRMATION_MODES.SAVE_PROPERTY_CHANGE]: 'Save Property Change',
};

export const CONFIRMATION_MODE_FOR_STATUS = {
  [STATUS.ARCHIVED]: CONFIRMATION_MODES.DELETE_ARCHIVE,
  [STATUS.DRAFT]: CONFIRMATION_MODES.DELETE_DRAFT,
  [STATUS.LIVE]: CONFIRMATION_MODES.ARCHIVE,
};

export const TEMPLATE_SUBHEADER = {
  EDIT_DRAFT: 'Edit Draft Template',
  NEW_EMAIL: 'Create New Email Template',
  NEW_SMS: 'Create New SMS Template',
  PREVIEW: 'Preview New Template',
  USE_ARCHIVE: 'Use Archived Template',
  USE_DRAFT: 'Use Draft Template',
  USE_LIVE: 'Use Active Template',
  VIEW_ARCHIVE: 'View Archived Template',
  VIEW_DRAFT: 'View Draft Template',
  VIEW_LIVE: 'View Active Template',
};

export const CAMPAIGN_SUBHEADER = {
  EDIT_DRAFT: 'Edit Draft Campaign',
  NEW: 'Create New Campaign',
  PREVIEW: 'Preview New Campaign',
  VIEW_LIVE: 'View Active Campaign',
  VIEW_DRAFT: 'View Draft Campaign',
  VIEW_ARCHIVE: 'View Archived Campaign',
  USE_LIVE: 'Use Active Campaign',
  USE_DRAFT: 'Use Draft Campaign',
  USE_ARCHIVE: 'Use Archived Campaign',
};

export const TEMPLATE_VALIDATION_MESSAGES = {
  TEMPLATE_NAME: 'A template name is required',
  SUBJECT: 'A subject is required',
  MESSAGE: 'A message is required',
};

export const SMS_CHARACTER_LIMIT = 160;
