import { apiRemote } from '../../Services/RemoteService';

export const postProspects = async (importFormData) => {
  const response = await apiRemote.postFile(
    `admin/imports/prospects`,
    importFormData
  );

  return await response.json();
};
