import { validateUrl } from '../../../../Utils';
import {
  COMPANY_TEMPLATE,
  MESSAGE_TYPES,
  VALIDATION_ERROR_MESSAGES,
} from '../constants';

export const getChangedAssignableProperties = (
  assignablePropertiesMap,
  originalAssignableProperties
) => {
  if (!originalAssignableProperties.length) {
    const deselectedProperties = Object.values(assignablePropertiesMap).reduce(
      (deselected, property) => {
        const { propertyId, isSelected } = property;
        if (!isSelected) {
          deselected.push({ propertyId, isSelected });
        }
        return deselected;
      },
      []
    );

    return deselectedProperties;
  }

  const changedAssignableProperties = originalAssignableProperties.reduce(
    (changedAssignableProperties, originalProperty) => {
      const { propertyId } = originalProperty;
      const currentProperty = assignablePropertiesMap[propertyId];

      if (currentProperty.isSelected !== originalProperty.isSelected) {
        changedAssignableProperties.push({
          propertyId,
          isSelected: currentProperty.isSelected,
        });
      }
      return changedAssignableProperties;
    },
    []
  );

  return changedAssignableProperties;
};

export const getIsEveryPropertyAssignable = (
  assignableProperties = [],
  assignablePropertiesMap = {}
) =>
  assignableProperties.every(
    (property) => assignablePropertiesMap?.[property.propertyId]?.isSelected
  );

export const getSelectedProperties = (propertiesMap) =>
  Object.values(propertiesMap).reduce((properties, property) => {
    const { isSelected, propertyId, propertyName } = property;
    if (isSelected) {
      properties.push({ propertyId, propertyName });
    }
    return properties;
  }, []);

export const getSelectedPropertyIds = (propertiesMap) =>
  Object.values(propertiesMap).reduce((propertyIds, property) => {
    if (property.isSelected) {
      propertyIds.push(property.propertyId);
    }
    return propertyIds;
  }, []);

export const getTablePropertiesMap = (properties = []) =>
  properties.reduce((propertiesMap, property) => {
    propertiesMap[property.propertyId] = { ...property };
    return propertiesMap;
  }, {});

export const getModifiedTemplateData = (template, originalTemplate) => {
  const templateKeys = [
    COMPANY_TEMPLATE.MESSAGE,
    COMPANY_TEMPLATE.SUBJECT,
    COMPANY_TEMPLATE.TEMPLATE_NAME,
  ];

  return templateKeys.reduce((updatedTemplate, templateKey) => {
    const templateValue = template[templateKey];
    if (templateValue !== originalTemplate[templateKey]) {
      updatedTemplate[templateKey] = templateValue;
    }
    return updatedTemplate;
  }, {});
};

export const getModifiedProperties = (propertiesMap, originalProperties) => {
  const properties = Object.values(propertiesMap);

  const originalPropertiesMap = originalProperties.reduce(
    (propertyMap, property) => {
      propertyMap[property.propertyId] = property;
      return propertyMap;
    },
    {}
  );

  return properties.reduce((modifiedProperties, property) => {
    const { isEnabled, propertyId } = property;
    const originalProperty = originalPropertiesMap[propertyId];

    if (!originalProperty || isEnabled !== originalProperty.isEnabled) {
      modifiedProperties.push({ propertyId, isEnabled });
    }
    return modifiedProperties;
  }, []);
};

export const getModifiedAssignableProperties = ({
  assignableProperties,
  assignablePropertiesMap,
  originalTemplate,
}) => {
  const updates = {};

  const isEveryPropertyAssignable = getIsEveryPropertyAssignable(
    assignableProperties,
    assignablePropertiesMap
  );

  if (
    isEveryPropertyAssignable !== originalTemplate.isEveryPropertyAssignable ||
    (!isEveryPropertyAssignable && !originalTemplate.isEveryPropertyAssignable)
  ) {
    updates.isEveryPropertyAssignable = isEveryPropertyAssignable;
    updates.assignableProperties = isEveryPropertyAssignable
      ? []
      : getChangedAssignableProperties(
          assignablePropertiesMap,
          originalTemplate.assignableProperties
        );
  }

  return updates;
};

export const getUpdatedMap = (propertiesMap, isSelected) =>
  Object.entries(propertiesMap).reduce((updatedMap, [propertyId, property]) => {
    updatedMap[propertyId] = {
      ...property,
      isSelected,
    };
    return updatedMap;
  }, {});

export const hasAssignablePropertiesChanged = ({
  assignablePropertiesMap,
  isEditing,
  isEveryPropertyAssignable,
  originalTemplate,
}) => {
  if (!isEditing) {
    return !isEveryPropertyAssignable;
  }

  return (
    isEveryPropertyAssignable !== originalTemplate.isEveryPropertyAssignable ||
    (!isEveryPropertyAssignable &&
      !originalTemplate.isEveryPropertyAssignable &&
      originalTemplate.assignableProperties.some(
        (property) =>
          property.isSelected !==
          assignablePropertiesMap[property.propertyId].isSelected
      ))
  );
};

export const validateUrlString = (urlString) => {
  if (!urlString) {
    return VALIDATION_ERROR_MESSAGES.URL_REQUIRED;
  }

  const isHttps = /^https:\/\//.test(urlString);
  if (!isHttps) {
    return VALIDATION_ERROR_MESSAGES.URL_INVALID_PROTOCOL;
  }

  const isValidURL = validateUrl(urlString);
  if (!isValidURL) {
    return VALIDATION_ERROR_MESSAGES.URL_INVALID_FORMAT;
  }
  try {
    new URL(urlString);
  } catch (_e) {
    return VALIDATION_ERROR_MESSAGES.URL_INVALID_FORMAT;
  }
};

export const validateMessage = (messageType, message) => {
  switch (messageType) {
    case MESSAGE_TYPES.URL:
      return validateUrlString(message);
    default:
      if (message === '') {
        return VALIDATION_ERROR_MESSAGES[messageType];
      }
  }
};
