import React from 'react';
import { PropTypes } from 'prop-types';

import FeatureCard from './FeatureCard';
import { DRAWER_TITLE } from '../constants';

const DripCampaignCard = ({
  children,
  closeDrawer,
  handleBackButtonOnClick,
  subheader,
}) => (
  <FeatureCard
    closeDrawer={closeDrawer}
    handleBackButtonOnClick={handleBackButtonOnClick}
    subheader={subheader}
    title={DRAWER_TITLE}
  >
    {children}
  </FeatureCard>
);

DripCampaignCard.propTypes = {
  children: PropTypes.node,
  closeDrawer: PropTypes.func,
  handleBackButtonOnClick: PropTypes.func,
  subheader: PropTypes.string,
};

export default DripCampaignCard;
