import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useCampaigns from '../../DripCampaignViews/useCampaigns';
import { ErrorDialog } from '../../../common/ErrorDialog';
import { CampaignDataTable } from '../../DripCampaignViews/CampaignView/CampaignDataTable';

export const SelectExistingCampaign = ({
  isOriginCreateCampaign,
  setCampaign,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const { getTableData } = useCampaigns({
    setErrorMessage,
  });

  return (
    <>
      <ErrorDialog
        closeDialog={() => {
          setErrorMessage('');
        }}
        errorMessage={errorMessage}
        isOpen={Boolean(errorMessage)}
      />
      <CampaignDataTable
        getTableData={getTableData}
        setCampaign={setCampaign}
        isOriginCreateCampaign={isOriginCreateCampaign}
      />
    </>
  );
};

SelectExistingCampaign.propTypes = {
  isOriginCreateCampaign: PropTypes.bool,
  setCampaign: PropTypes.func,
};
