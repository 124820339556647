import { apiRemote } from '../../../../Services/RemoteService';
import {
  getPropertyListQueryString,
  getQueryString,
  normalizeCampaign,
  normalizeCampaigns,
  normalizePropertyList,
  normalizeTemplate,
  normalizeTemplates,
  transformCampaignSavePayload,
  transformCampaignUpdatePayload,
  transformTemplateUpdatePayload,
} from './utils';

export const ERROR_MESSAGES = {
  FETCH_CAMPAIGNS:
    'We are unable to manage drip campaigns at this time.  Please try again later.',
  FETCH_TEMPLATE:
    'We are unable to manage templates at this time.  Please try again later.',
  GET_PROPERTIES:
    'We are unable to manage properties at this time.  Please try again later.',
  SAVE: 'We are unable to save the drip campaign at this time.',
  UPDATE_CAMPAIGN: 'We are unable to update the drip campaign at this time.',
  UPDATE_TEMPLATE: 'We are unable to update the template at this time.',
  DELETE_CAMPAIGN:
    'We are unable to delete campaigns at this time.  Please try again later.',
  DELETE_TEMPLATE:
    'We are unable to delete drip campaign templates at this time.  Please try again later.',
  DUPLICATE_TITLE: 'That campaign name is taken. Please create a unique name.',
};

export const ARCHIVE_CAMPAIGN_ERROR_MESSAGES = {
  '404': 'Unable to find the selected Campaign',
  '403': 'Insufficient permissions to archive the selected campaign',
  '400': 'The selected campaign has already been archived',
};

const getKnownErrorMessage = (errorMessage = '') => {
  if (errorMessage.includes('Drip campaign title should be unique')) {
    return ERROR_MESSAGES.DUPLICATE_TITLE;
  }
};

export const archiveCampaign = async (campaignId) => {
  try {
    const { ok, status } = await apiRemote.patch(
      `admin/campaign/archive/${campaignId}`
    );

    if (!ok || status !== 204) {
      throw new Error(status);
    }
    return;
  } catch (error) {
    const errorMessage =
      ARCHIVE_CAMPAIGN_ERROR_MESSAGES[error.message] ||
      ERROR_MESSAGES.FETCH_CAMPAIGNS;
    throw new Error(errorMessage);
  }
};

export const createCampaign = async (campaign) => {
  const payload = transformCampaignSavePayload(campaign);

  try {
    const response = await apiRemote.post('admin/campaign', payload);
    const responseData = await response.json();

    if (!response.ok || response.status !== 201) {
      throw new Error(responseData.error);
    }

    return responseData.drip_campaign_id;
  } catch (error) {
    const knownErrorMessage = getKnownErrorMessage(error.message);
    throw new Error(knownErrorMessage || ERROR_MESSAGES.SAVE);
  }
};

export const getCampaigns = async (queryParams) => {
  const queryString = getQueryString(queryParams);

  try {
    const response = await apiRemote.get(
      `admin/campaign/company${queryString}`
    );

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const { drip_campaigns, count } = await response.json();

    return {
      campaigns: normalizeCampaigns(drip_campaigns),
      totalCampaignCount: count,
    };
  } catch (_e) {
    throw new Error(ERROR_MESSAGES.FETCH_CAMPAIGNS);
  }
};

export const getCampaignById = async (campaignId) => {
  try {
    const response = await apiRemote.get(`admin/campaign/${campaignId}`);
    const { drip_campaign } = await response.json();
    return normalizeCampaign(drip_campaign);
  } catch (_error) {
    throw new Error(ERROR_MESSAGES.FETCH_CAMPAIGNS);
  }
};

export const deleteCampaign = async (campaignId) => {
  try {
    const response = await apiRemote.delete(`admin/campaign/${campaignId}`);

    if (!response.ok || response.status !== 204) {
      throw new Error();
    }
  } catch (e) {
    throw new Error(ERROR_MESSAGES.DELETE_CAMPAIGN);
  }
};

export const getPropertyList = async () => {
  try {
    const response = await apiRemote.get('admin/property-list');

    if (!response.ok && response.status !== 200) {
      throw new Error(response.statusText);
    }

    const { properties = [] } = await response.json();
    return normalizePropertyList(properties);
  } catch (_e) {
    throw new Error(ERROR_MESSAGES.GET_PROPERTIES);
  }
};

export const saveTemplate = async (template) => {
  const payload = {
    content_type: template.contentType || 'email',
    status: template.status,
    subject: template.subject,
    text: template.message,
    title: template.templateName,
  };

  try {
    const response = await apiRemote.post('admin/campaign/template', payload);

    if (!response.ok || response.status !== 201) {
      throw new Error();
    }
    const responseData = await response.json();
    return responseData.template_id;
  } catch (error) {
    throw new Error(ERROR_MESSAGES.SAVE);
  }
};

export const updateTemplate = async (templateId, template) => {
  const payload = transformTemplateUpdatePayload(template);

  try {
    const response = await apiRemote.patch(
      `admin/campaign/template/${templateId}`,
      payload
    );

    if (!response.ok || response.status !== 204) {
      throw new Error();
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.UPDATE_TEMPLATE);
  }
};

export const updateDripCampaign = async (campaignId, campaign) => {
  const payload = transformCampaignUpdatePayload(campaign);

  try {
    const response = await apiRemote.patch(
      `admin/campaign/${campaignId}`,
      payload
    );

    if (!response.ok || response.status !== 204) {
      const responseData = await response.json();
      throw new Error(responseData.error);
    }

  } catch (error) {
    const knownErrorMessage = getKnownErrorMessage(error.message);
    throw new Error(knownErrorMessage || ERROR_MESSAGES.UPDATE_CAMPAIGN);
  }
};

export const deleteTemplate = async (templateId) => {
  try {
    const response = await apiRemote.delete(
      `admin/campaign/templates/${templateId}`
    );

    if (!response.ok || response.status !== 204) {
      throw new Error();
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.DELETE_TEMPLATE);
  }
};

export const archiveTemplate = async (templateId) => {
  let errorMessage = ERROR_MESSAGES.FETCH_TEMPLATE;
  try {
    const response = await apiRemote.patch(
      `admin/campaign/templates/archive/${templateId}`
    );

    if (!response.ok || response.status !== 204) {
      if (response.status === 400) {
        const { message } = await response.json();
        errorMessage = message;
      }
      throw new Error();
    }
  } catch (_e) {
    throw new Error(errorMessage);
  }
};

export const getTemplateById = async (templateId) => {
  try {
    const response = await apiRemote.get(
      `admin/campaign/templates/${templateId}`
    );

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const { template } = await response.json();

    return normalizeTemplate(template);
  } catch (_e) {
    throw new Error(ERROR_MESSAGES.FETCH_TEMPLATE);
  }
};

export const getTemplates = async (queryParams) => {
  const queryString = getQueryString(queryParams);

  try {
    const response = await apiRemote.get(
      `admin/campaign/templates${queryString}`
    );

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const responseData = await response.json();

    return {
      templates: normalizeTemplates(responseData.templates),
      totalTemplateCount: responseData.total_num_records,
    };
  } catch (_error) {
    throw new Error(ERROR_MESSAGES.FETCH_TEMPLATE);
  }
};

export const getAiEmailGatedPropertyList = async (campaignId) => {
  try {
    const queryParam = getPropertyListQueryString({ campaignId });
    const response = await apiRemote.get(
      `admin/campaign/ai-email-gated-property-list${queryParam}`
    );

    if (!response.ok && response.status !== 200) {
      throw new Error(response.statusText);
    }

    const { properties = [] } = await response.json();
    return normalizePropertyList(properties);
  } catch (_e) {
    throw new Error(ERROR_MESSAGES.GET_PROPERTIES);
  }
};

export const getDripCampaignPropertyList = async (campaignId, triggerType) => {
  try {
    const queryParam = getPropertyListQueryString({ campaignId, triggerType });
    const response = await apiRemote.get(
      `admin/campaign/drip-campaign-properties-availability${queryParam}`
    );

    if (!response.ok && response.status !== 200) {
      throw new Error(response.statusText);
    }

    const { properties = [] } = await response.json();
    return normalizePropertyList(properties);
  } catch (_e) {
    throw new Error(ERROR_MESSAGES.GET_PROPERTIES);
  }
};
