import React, { Component } from 'react';
import { PageContainer, Toaster } from '@knockrentals/knock-react';
import classNames from 'classnames';
import SyndicationAPI from './SyndicationAPI';
import IlsSubscriptionTable from './components/IlsSubscriptionTable';
import isEmpty from 'lodash/isEmpty';
import './_syndication.scss';

class SyndicationPage extends Component {
  static TOAST_DURATION_MS = 2000;

  state = {
    isLoading: true,
    communities: null,
    communityIds: null,
    developers: null,
    subscriptions: null,
  };

  async componentDidMount() {
    await this.initialize();
  }

  handleError = (message) => {
    console.error(message);
    Toaster.showToast(
      message,
      SyndicationPage.TOAST_DURATION_MS,
      Toaster.ToastClasses.error
    );
    this.setState({ isLoading: false });
  };

  handleSuccess = (message) => {
    Toaster.showToast(
      message,
      SyndicationPage.TOAST_DURATION_MS,
      Toaster.ToastClasses.success
    );
  };

  hasSubscriptionData = () => {
    const { communities, communityIds, developers, subscriptions } = this.state;

    return communities && communityIds && developers && subscriptions;
  };

  initialize = async () => {
    let myCommunities;
    let developerSubscriptions;

    // Fetch user's communities:
    try {
      myCommunities = await SyndicationAPI.getMyCommunities();
    } catch (error) {
      console.error(error);
      this.handleError(`Couldn't fetch communities`);
      return;
    }

    // Parse communities and communityIds:
    const { communities: fetchedCommunities } = myCommunities || {};
    if (!fetchedCommunities || !fetchedCommunities.length) {
      this.handleError(`No communities available`);
      return;
    }

    const communities = fetchedCommunities.map(
      ({ id, location: { name } }) => ({ id, name })
    );
    if (!communities || !communities.length) {
      this.handleError(`No communities available`);
      return;
    }
    this.setState({ communities });

    const communityIds = communities.map(({ id }) => id);
    this.setState({ communityIds });

    // Fetch developer subscriptions:
    try {
      developerSubscriptions =
        await SyndicationAPI.getIlsDeveloperSubscriptions();
    } catch (error) {
      console.error(error);
      this.handleError(`Couldn't fetch developer subscriptions`);
      return;
    }

    // Parse developers, and their subscriptions to communities:
    const { subscriptions } = developerSubscriptions || {};
    if (isEmpty(subscriptions)) {
      this.handleError(`No developer subscriptions available`);
      return;
    }
    this.setState({ subscriptions });

    const developers = Object.entries(subscriptions).reduce(
      (devs, [id, { name }]) => {
        devs.push({ id, name });
        return devs;
      },
      []
    );

    if (!developers || !developers.length) {
      this.handleError(`No developers available`);
      return;
    }

    this.setState({
      developers,
      isLoading: false,
    });
  };

  updateIlsSubscriptions = async (subscribeActions) => {
    let updatedSubscriptions;
    try {
      updatedSubscriptions =
        await SyndicationAPI.updateIlsDeveloperSubscriptions(subscribeActions);
    } catch (error) {
      console.error(error);
      this.handleError(`Couldn't update ILS developer subscriptions`);
      return null;
    }

    if (!isEmpty(updatedSubscriptions)) {
      const newSubscriptions = { ...this.state.subscriptions };
      const developerIds = Object.keys(updatedSubscriptions);

      developerIds.forEach((id) => {
        newSubscriptions[id].communityIds = [
          ...updatedSubscriptions[id].communityIds,
        ];
      });

      this.setState({ subscriptions: newSubscriptions });
    }
    return updatedSubscriptions;
  };

  createSubscriptionAction = (communityId, subscribe) => {
    return {
      communityId,
      subscribe,
    };
  };

  subscriptionChangePhrase = (isChecked) =>
    isChecked ? 'subscribed to' : 'unsubscribed from';

  handleSubscriptionChange = (community, developer) => async (event) => {
    event.persist();
    const isChecked = event.target.checked;
    const changePhrase = this.subscriptionChangePhrase(isChecked);
    const payload = {
      [developer.id]: [this.createSubscriptionAction(community.id, isChecked)],
    };

    const updatedSubscriptions = await this.updateIlsSubscriptions(payload);

    if (updatedSubscriptions) {
      this.handleSuccess(
        `${developer.name} ${changePhrase} ${community.name}!`
      );
    }
  };

  handleSubscribeAllChange = (developer) => async (event) => {
    event.persist();
    const isChecked = event.target.checked;
    const changePhrase = this.subscriptionChangePhrase(isChecked);
    const payload = {
      [developer.id]: [],
    };

    this.state.communities.forEach(({ id }) => {
      payload[developer.id].push(this.createSubscriptionAction(id, isChecked));
    });

    const updatedSubscriptions = await this.updateIlsSubscriptions(payload);

    if (updatedSubscriptions) {
      this.handleSuccess(`${developer.name} ${changePhrase} all communities!`);
    }
  };

  render() {
    const { isLoading, communities, developers, subscriptions } = this.state;

    return (
      <PageContainer
        className={classNames('syndication-info-page-container')}
        isLoading={isLoading}
      >
        <h1>Syndication</h1>

        {this.hasSubscriptionData() && (
          <div
            className={classNames('syndication-developer-subscription-content')}
          >
            <h3>Publish Properties to ILS Developers</h3>
            <IlsSubscriptionTable
              communities={communities}
              developers={developers}
              subscriptions={subscriptions}
              onSubscriptionChange={this.handleSubscriptionChange}
              onSubscribeAllChange={this.handleSubscribeAllChange}
            />
          </div>
        )}
      </PageContainer>
    );
  }
}

export default SyndicationPage;
