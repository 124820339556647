import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TabPanel } from '@knockrentals/knock-shared-web';
import { CampaignView } from './CampaignView';
import { TemplateView } from './TemplateView';
import useCampaigns from './useCampaigns';
import useTemplates from './useTemplates';
import { VIEWS } from '../constants';

const DripCampaignViews = ({ setDrawerData, reloadViewRef, view }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    getTableData: getCampaignTableData,
    reloadCampaigns,
    rowsPerPage: campaignRowsPerPage,
    rowsPerPageOptions: campaignRowsPerPageOptions,
    setPageIndex: campaignSetPageIndex,
    setShouldLoadPageIndex: campaignSetShouldLoadPageIndex,
    tableRef: campaignTableRef,
    totalCampaignCount,
  } = useCampaigns({
    setErrorMessage,
  });

  const {
    getTableData,
    rowsPerPage,
    rowsPerPageOptions,
    setPageIndex,
    setShouldLoadPageIndex,
    tableRef,
    totalTemplateCount,
    updateTemplateGridData,
  } = useTemplates({ setErrorMessage, setIsFetching });

  reloadViewRef.current[VIEWS.CAMPAIGN] = reloadCampaigns;
  reloadViewRef.current[VIEWS.TEMPLATE] = updateTemplateGridData;

  return (
    <React.Fragment>
      <TabPanel value={view} index={VIEWS.CAMPAIGN}>
        <CampaignView
          errorMessage={errorMessage}
          getTableData={getCampaignTableData}
          rowsPerPage={campaignRowsPerPage}
          rowsPerPageOptions={campaignRowsPerPageOptions}
          setPageIndex={campaignSetPageIndex}
          setShouldLoadPageIndex={campaignSetShouldLoadPageIndex}
          setCampaign={setDrawerData}
          tableRef={campaignTableRef}
          totalCampaignCount={totalCampaignCount}
        />
      </TabPanel>

      <TabPanel value={view} index={VIEWS.TEMPLATE}>
        <TemplateView
          errorMessage={errorMessage}
          getTableData={getTableData}
          isFetching={isFetching}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          setPageIndex={setPageIndex}
          setShouldLoadPageIndex={setShouldLoadPageIndex}
          setTemplate={setDrawerData}
          tableRef={tableRef}
          totalTemplateCount={totalTemplateCount}
        />
      </TabPanel>
    </React.Fragment>
  );
};

DripCampaignViews.propTypes = {
  reloadViewRef: PropTypes.shape({
    current: PropTypes.shape({
      [VIEWS.CAMPAIGN]: PropTypes.func,
      [VIEWS.TEMPLATE]: PropTypes.func,
    }),
  }),
  setDrawerData: PropTypes.func,
  view: PropTypes.string,
};

export default DripCampaignViews;
