import React from 'react';
import PropTypes from 'prop-types';

const EntrataIntegrationInfo = ({ integration: { vendor, username, password, domain, entrata_property_id } }) => (
  <div className="knock-admin-integration-info">
    <div>
      <strong>Integration:</strong> {vendor}
    </div>
    <div>
      <strong>Username:</strong> {username}
    </div>
    <div>
      <strong>Password:</strong> {password}
    </div>
    <div>
      <strong>Entrata domain:</strong> {domain}
    </div>
    <div>
      <strong>Entrata property ID:</strong> {entrata_property_id}
    </div>
  </div>
);

EntrataIntegrationInfo.propTypes = {
  integration: PropTypes.object
};

export default EntrataIntegrationInfo;
