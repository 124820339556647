import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  LoadingOverlay,
  makeStyles,
} from '@knockrentals/knock-shared-web';

import AddCompanySourceView from './AddCompanySourceView';
import { NotificationService } from '../../../../Components/Notifications';
import SourcesAPI from '../../SourcesAPI';
import { ERROR_MESSAGES } from '../constants';
import { SAVED_NOTIFICATION } from '../../../../constants';

const useStyles = makeStyles({
  dialogContainer: {
    maxWidth: '550px',
    position: 'relative',
  },
});

export const buttonText = 'Add Source';

const CompanySourceModal = ({ onSubmit, sourceMap = {} }) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sources, setSources] = useState(null);

  useEffect(() => {
    if (sources === null && isOpen) {
      setIsLoading(true);

      if (errorMessage) {
        setErrorMessage();
      }

      SourcesAPI.getAllMarketingSources()
        .then((response) => {
          const data = response.marketing_sources.map((source) => {
            return {
              id: source.id,
              label: source.name,
            };
          });

          setSources(data);
        })
        .catch(() => {
          setErrorMessage(ERROR_MESSAGES.ALL_SOURCES);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [sources, isOpen, setIsLoading, setSources]);

  const handleSubmit = (source) => {
    if (source) {
      SourcesAPI.addCompanyMarketingSource(source.id)
        .then(() => {
          onSubmit();
          setIsOpen(false);
          NotificationService.notify(SAVED_NOTIFICATION);
        })
        .catch(() => {
          setErrorMessage(ERROR_MESSAGES.ADD_SOURCE);
        });
    }
  };

  return (
    <React.Fragment>
      <Button onClick={() => setIsOpen(true)}>{buttonText}</Button>

      <Dialog
        open={isOpen}
        scroll="paper"
        disableEscapeKeyDown={true}
        fullWidth={true}
        classes={{ paperFullWidth: classes.dialogContainer }}
        onClose={() => setIsOpen(false)}
      >
        <LoadingOverlay
          open={isLoading}
          style={{ opacity: '0.5', position: 'absolute' }}
        />
        <AddCompanySourceView
          buttonText={buttonText}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          sources={sources}
          sourceMap={sourceMap}
          onSubmit={handleSubmit}
          onClose={() => setIsOpen(false)}
        />
      </Dialog>
    </React.Fragment>
  );
};

export default CompanySourceModal;
