import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@knockrentals/knock-shared-web';
import DrawerActions from './DrawerActions';
import { CONFIRMATION_MODES } from '../DrawerView/constants';

const PreviewActionButtons = ({ isEditing, setConfirmationMode }) => (
  <DrawerActions>
    {isEditing ? (
      <React.Fragment>
        <Button
          className="action-button"
          color="primary"
          onClick={() => {
            setConfirmationMode(CONFIRMATION_MODES.SAVE_DRAFT_EDIT);
          }}
        >
          Save Draft
        </Button>

        <Button
          className="action-button"
          color="primary"
          onClick={() => {
            setConfirmationMode(CONFIRMATION_MODES.ACTIVATE);
          }}
        >
          Make Active
        </Button>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Button
          className="action-button"
          color="primary"
          onClick={() => {
            setConfirmationMode(CONFIRMATION_MODES.SAVE_DRAFT);
          }}
        >
          Save as Draft
        </Button>

        <Button
          className="action-button"
          color="primary"
          onClick={() => {
            setConfirmationMode(CONFIRMATION_MODES.SAVE);
          }}
        >
          Save
        </Button>
      </React.Fragment>
    )}
  </DrawerActions>
);

PreviewActionButtons.propTypes = {
  isEditing: PropTypes.bool,
  setConfirmationMode: PropTypes.func,
};

export default PreviewActionButtons;
