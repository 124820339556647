import { apiRemote } from '../../Services/RemoteService';

class SpreadsheetRefreshAPI {
  static getRefreshedSpreadsheetUrl(companyId) {
    return apiRemote
      .post(`public/refresh-spreadsheet/${companyId}`)
      .then(response => response.json());
  }
}

export default SpreadsheetRefreshAPI;
