import {
  AVAILABILITY_BUFFER,
  CUSTOM_REQUIREMENTS,
  DAYS_OF_THE_WEEK,
  END_TIME,
  INCOME_MULTIPLIER,
  IS_BUDGET_REQUIRED,
  IS_EVICTION_HISTORY_REQUIRED,
  IS_MOVE_DATE_REQUIRED,
  IS_PET_POLICY_REQUIRED,
  IS_SELF_SCHEDULE_ENABLED,
  IS_SELF_GUIDED_TOURS_ENABLED,
  IS_IN_PERSON_TOURS_ENABLED,
  IS_LIVE_VIDEO_TOURS_ENABLED,
  MAX_VISITORS,
  NUMBER_OF_SCHEDULING_DAYS,
  PRE_APPOINTMENT_BUFFER,
  POST_APPOINTMENT_BUFFER,
  SHOULD_HIDE_SCHEDULING_CALL_TO_ACTION,
  SHOULD_SELF_GUIDED_TOURS_BLOCK,
  SHOULD_CLUSTER_TOURS,
  START_TIME,
  TOUR_DURATION,
} from '../../constants';

export const TIMEZONE = 'timezone';

export const PREFERENCES_TITLES = {
  [AVAILABILITY_BUFFER]: 'Minimum booking notice',
  [CUSTOM_REQUIREMENTS]: 'Custom requirements',
  [INCOME_MULTIPLIER]: 'Monthly income',
  [IS_BUDGET_REQUIRED]: 'Budget',
  [IS_EVICTION_HISTORY_REQUIRED]: 'Eviction history',
  [IS_IN_PERSON_TOURS_ENABLED]: 'Enable In-Person Tours',
  [IS_LIVE_VIDEO_TOURS_ENABLED]: 'Enable Live Video Tours',
  [IS_MOVE_DATE_REQUIRED]: 'Move date',
  [IS_PET_POLICY_REQUIRED]: 'Pet ownership',
  [IS_SELF_GUIDED_TOURS_ENABLED]: 'Enable Knock Self-guided Tours',
  [IS_SELF_SCHEDULE_ENABLED]: 'Self-schedule',
  [NUMBER_OF_SCHEDULING_DAYS]: 'Number of days out',
  [PRE_APPOINTMENT_BUFFER]: 'Buffer before appointments',
  [POST_APPOINTMENT_BUFFER]: 'Buffer after appointments',
  [SHOULD_CLUSTER_TOURS]: 'Cluster tours on the same day',
  [SHOULD_HIDE_SCHEDULING_CALL_TO_ACTION]: 'Hide scheduling call to actions',
  [SHOULD_SELF_GUIDED_TOURS_BLOCK]: 'Self-Guided Tours Occupy Availability',
  [TIMEZONE]: 'Timezone',
  [TOUR_DURATION]: 'Default length of tour times',
};

export const NO = 'no';
export const YES = 'yes';

export const PREFERENCE_TYPES = {
  IN_PERSON_TOUR_HOURS: 'inPersonTourHours',
  FEATURES: 'features',
  PRESCREENING_REQUIREMENTS: 'prescreeningRequirements',
  SELF_GUIDED_TOUR_HOURS: 'selfGuidedTourHours',
  TIMEZONE: 'timezone',
};

export {
  AVAILABILITY_BUFFER,
  CUSTOM_REQUIREMENTS,
  DAYS_OF_THE_WEEK,
  END_TIME,
  INCOME_MULTIPLIER,
  IS_BUDGET_REQUIRED,
  IS_EVICTION_HISTORY_REQUIRED,
  IS_MOVE_DATE_REQUIRED,
  IS_PET_POLICY_REQUIRED,
  IS_SELF_SCHEDULE_ENABLED,
  IS_SELF_GUIDED_TOURS_ENABLED,
  IS_IN_PERSON_TOURS_ENABLED,
  IS_LIVE_VIDEO_TOURS_ENABLED,
  MAX_VISITORS,
  NUMBER_OF_SCHEDULING_DAYS,
  PRE_APPOINTMENT_BUFFER,
  POST_APPOINTMENT_BUFFER,
  SHOULD_HIDE_SCHEDULING_CALL_TO_ACTION,
  SHOULD_SELF_GUIDED_TOURS_BLOCK,
  SHOULD_CLUSTER_TOURS,
  START_TIME,
  TOUR_DURATION,
};
