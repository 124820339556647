import React from 'react';
import PropTypes from 'prop-types';
import { Text, makeStyles } from '@knockrentals/knock-shared-web';

export const HEADER_DESCRIPTIONS = {
  LOGO_AND_BRAND_COLOR:
    'Email header will display a banner of your brand color with the logo overlaid. You can select from 3 layouts to display your logo.',
  BRAND_COLOR_ONLY:
    'Because there is no logo image available, your header will display a Brand Color band with the property name overlaid. You can select from 3 layouts.',
  LOGO_ONLY:
    'Because there is no Brand Color available, the Header will display the property logo on a white background. You can select from 3 layouts to display your logo.',
  NEITHER_LOGO_OR_BRAND_COLOR:
    'Because there is no logo image or brand color available, your header will display the Property Name in black on a white background. You can select from 3 layouts.',
};

const getDescriptionText = (hasLogo, hasBrandColor) => {
  if (hasLogo) {
    return hasBrandColor
      ? HEADER_DESCRIPTIONS.LOGO_AND_BRAND_COLOR
      : HEADER_DESCRIPTIONS.LOGO_ONLY;
  }
  return hasBrandColor
    ? HEADER_DESCRIPTIONS.BRAND_COLOR_ONLY
    : HEADER_DESCRIPTIONS.NEITHER_LOGO_OR_BRAND_COLOR;
};

const useStyles = makeStyles({
  descriptionText: {
    margin: '24px 0',
  },
});

const BrandHeaderDescription = ({
  hasBrandColor = false,
  hasLogo = false,
  isBatchUpdate,
}) => {
  const classes = useStyles();
  return (
    <Text className={classes.descriptionText} variant="body2">
      {isBatchUpdate
        ? HEADER_DESCRIPTIONS.LOGO_AND_BRAND_COLOR
        : getDescriptionText(hasLogo, hasBrandColor)}
    </Text>
  );
};

BrandHeaderDescription.propTypes = {
  hasBrandColor: PropTypes.bool,
  hasLogo: PropTypes.bool,
  isBatchUpdate: PropTypes.bool,
};

export default BrandHeaderDescription;
