import React from 'react';
import PropTypes from 'prop-types';

import { PreferencesSelect } from '../PreferencesSelect';
import { getOptions } from '../utils';
import { END_TIME, MAX_VISITORS, START_TIME } from '../constants';
import './_TourHoursDaySettings.scss';

export const ariaLabels = {
  [END_TIME]: 'tour end time',
  [MAX_VISITORS]: 'simultaneous max visitors',
  [START_TIME]: 'tour start time',
};

const getStartTimeOptions = (endTimeString = '20:00:00') =>
  getOptions(START_TIME).filter(
    (startTimeOption) => startTimeOption.value < endTimeString
  );

const getEndTimeOptions = (startTimeString = '') =>
  getOptions(END_TIME).filter(
    (endTimeOption) => endTimeOption.value > startTimeString
  );

const TourHoursDaySettings = ({ tourHoursToday, updatePreference }) => {
  const renderSelect = (name, options) => {
    return (
      <PreferencesSelect
        ariaLabel={ariaLabels[name]}
        name={name}
        options={options}
        updatePreference={updatePreference}
        value={tourHoursToday[name]}
      />
    );
  };

  const { endTime, startTime } = tourHoursToday;

  return (
    <div className="tours-hours-day-settings">
      <div className="hours">
        {renderSelect(START_TIME, getStartTimeOptions(endTime))}

        <span className="separator-text">to</span>

        {renderSelect(END_TIME, getEndTimeOptions(startTime))}
      </div>

      <div className="max-visitors">
        {renderSelect(MAX_VISITORS, getOptions(MAX_VISITORS))}
      </div>
    </div>
  );
};

TourHoursDaySettings.propTypes = {
  tourHoursToday: PropTypes.shape({
    endTime: PropTypes.string,
    isActive: PropTypes.bool,
    maxVisitors: PropTypes.number,
    startTime: PropTypes.string,
    weekday: PropTypes.number,
  }),
  updatePreference: PropTypes.func,
};

export default TourHoursDaySettings;
