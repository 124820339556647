import React from 'react';
import {
  Card,
  CardContent,
  makeStyles,
  Text,
} from '@knockrentals/knock-shared-web';
import { useCompanyPhoneRegistration } from './use-company-phone-registration';
import {
  REGISTRATION_LABELS,
  TOTAL_REGISTERED,
  TOTAL_UNREGISTERED,
  TOTAL_FAILED,
} from '../constants';
import './_CompanyPhoneRegistration.scss';

const useStyles = makeStyles({
  statsCard: {
    backgroundColor: '#eeeeee',
    width: '120px',
  },
  statsCardContent: {
    '&.MuiCardContent-root, .MuiCardContent-root:last-child': {
      padding: '4px',
    },
  },
});

const registrationStatsOrder = [
  TOTAL_REGISTERED,
  TOTAL_UNREGISTERED,
  TOTAL_FAILED,
];

const CompanyPhoneRegistration = () => {
  const classes = useStyles();
  const { errorMessage, registrationStats } = useCompanyPhoneRegistration();

  return (
    <div className="company-phone-registration-container">
      <div className="company-phone-registration">
        {registrationStatsOrder.map((statsKey) => (
          <Card className={classes.statsCard} elevation={0} key={statsKey}>
            <CardContent className={classes.statsCardContent}>
              <Text align="center" variant="subtitle2">
                {REGISTRATION_LABELS[statsKey]}
              </Text>

              <Text align="center" variant="h5">
                {registrationStats[statsKey] || 0}
              </Text>
            </CardContent>
          </Card>
        ))}
      </div>

      {errorMessage && (
        <Text color="error" variant="caption">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default CompanyPhoneRegistration;
