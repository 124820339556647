import React from 'react';

export const CloudSetupImage = () => {
  return (
    <svg
      width="354"
      height="354"
      viewBox="0 0 354 354"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_6756_44386)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M 226.8 13.8 C 174.6 -15.5 109.3 3.4 80.8 56 C 70.4 75.2 66.3 96.2 67.9 116.6 C 45.8 123.4 26.3 138.6 14.3 160.7 C -10.2 205.9 6.3 263 51.2 288.3 L 132.7 334.1 C 177.6 359.3 233.8 343.1 258.3 297.9 C 262.1 291 264.8 283.8 266.7 276.5 C 295.4 283 325.9 270 340.7 242.7 C 358.7 209.5 346.5 167.5 313.6 149 L 285.7 133.3 C 294.3 88.1 273.7 40.2 231.6 16.5 L 226.8 13.8 Z"
          fill="white"
        />
      </g>
      <path
        d="M 133.6 136.2 H 188.3 M 133.6 159.6 H 164.8 M 133.6 183.1 H 153.1 M 153.1 206.5 H 125.7 C 123.7 206.5 121.7 205.7 120.2 204.2 C 118.7 202.8 117.9 200.8 117.9 198.7 V 112.7 C 117.9 110.7 118.7 108.7 120.2 107.2 C 121.7 105.7 123.7 104.9 125.7 104.9 H 181.1 C 183.2 104.9 185.2 105.7 186.6 107.2 L 201.6 122.2 C 203.1 123.6 203.9 125.6 203.9 127.7 V 140.1 M 218.3 206.9 C 216.5 204.9 214.3 203.3 211.8 202.2 C 209.3 201.1 206.6 200.5 203.9 200.5 C 201.2 200.5 198.5 201.1 196 202.2 C 193.5 203.3 191.3 204.9 189.5 206.9 M 235.1 190.9 C 235.1 208.1 221.1 222.1 203.9 222.1 C 186.6 222.1 172.6 208.1 172.6 190.9 C 172.6 173.6 186.6 159.6 203.9 159.6 C 221.1 159.6 235.1 173.6 235.1 190.9 Z M 214.7 184.9 C 214.7 190.8 209.9 195.7 203.9 195.7 C 197.9 195.7 193.1 190.8 193.1 184.9 C 193.1 178.9 197.9 174 203.9 174 C 209.9 174 214.7 178.9 214.7 184.9 Z"
        stroke="#5c5c5c"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_6756_44386"
          x="0"
          y="0"
          width="354.002"
          height="354"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6756_44386"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6756_44386"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
