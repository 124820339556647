import React, { Component } from 'react';
import SyncAPI from './SyncAPI';
import PricingAPI from '../Pricing/PricingAPI';
import { PageContainer, Toaster } from '@knockrentals/knock-react';
import moment from 'moment';

class SyncPage extends Component {
  state = {
    isLoading: true,
    ledgers: {
      prospects: [],
      units: [],
      residents: [],
    },
    retryingCredId: null,
  };

  componentDidMount() {
    SyncAPI.getPropertySyncLedgers()
      .then(response => response.json())
      .then(({ sync_ledgers }) => {
        this.setState({ ledgers: sync_ledgers, isLoading: false });
      });
  }

  render() {
    return (
      <PageContainer className="hours-page-container" isLoading={this.state.isLoading}>
        <h1>Sync Tasks</h1>

        {this.renderSyncType('Prospects', this.state.ledgers.prospects)}
        {this.renderSyncType('Units', this.state.ledgers.units)}
        {this.renderSyncType('Residents', this.state.ledgers.residents)}
      </PageContainer>
    );
  }

  renderProperties() {
    return this.state.ledgers.map(ledger => {
      return (
        <div>
          <h3>{ledger.property_data.location.name}</h3>

          <div>
            <div>
              <strong>Credential info:</strong>
              <div>ID: {ledger.credential.id}</div>

              <div>Vendor: {ledger.credential.vendor}</div>

              <div>Property ID: {ledger.credential.property_id}</div>

              <div>Vendor Property ID: {ledger.credential.vendor_property_id}</div>

              <div>Role: {ledger.credential.role}</div>
            </div>
          </div>

          <br />

          {this.renderProperty(ledger)}
        </div>
      );
    });
  }

  renderSyncType(title, ledgers) {
    return (
      <div>
        <h1>{title}</h1>

        <table className="wire-frame-table">
          <thead>
            <tr>
              <th>Property</th>

              <th>Community ID</th>

              <th>Credential ID</th>

              <th>Vendor</th>

              <th>Started</th>

              <th>Completed</th>

              <th>Error</th>

              <th>Updated Count</th>

              <th>Created Count</th>

              <th>Notes</th>

              <th>Actions</th>
            </tr>
          </thead>

          <tbody>{this.renderPropertyRows(ledgers)}</tbody>
        </table>
      </div>
    );
  }

  static badRowStyle = { backgroundColor: 'red', color: 'white' };
  static warnRowStyle = { backgroundColor: 'yellow', color: 'black' };
  static goodRowStyle = { backgroundColor: '#00FF00', color: 'black' };

  renderPropertyRows(ledgers) {
    return ledgers.map(ledger => {
      if (!ledger.ledger) {
        return (
          <tr style={SyncPage.badRowStyle}>
            <td>{ledger.property_data.name}</td>
            <td colSpan={10}>This job has never run!</td>
          </tr>
        );
      }

      let rowStyle = SyncPage.goodRowStyle;
      let rowNotes = [];

      if (
        moment()
          .subtract(24, 'hours')
          .isAfter(moment(ledger.ledger.created_time))
      ) {
        rowStyle = SyncPage.warnRowStyle;
        rowNotes.push('Job has not run in over 24 hours');
      }

      if (ledger.ledger.exception) {
        rowStyle = SyncPage.warnRowStyle;
        rowNotes.push('Job had an error.');
      }

      if (!ledger.ledger.completed_time) {
        rowStyle = SyncPage.badRowStyle;
        rowNotes.push('Job did not finish successfully.');
      }

      const rowNotesElements = rowNotes.map((note, index) => {
        return <div key={index}>{note}</div>;
      });

      return (
        <tr key={ledger.credential.id} style={rowStyle}>
          <td>{ledger.property_data.name}</td>

          <td>{ledger.property_data.community_id}</td>

          <td>{ledger.credential.id}</td>

          <td>{ledger.credential.vendor}</td>

          <td>{moment(ledger.ledger.created_time).format('MM/DD/YYYY hh:mm a')}</td>

          <td>
            {ledger.ledger.completed_time
              ? moment(ledger.ledger.completed_time).format('MM/DD/YYYY hh:mm a')
              : 'Failed'}
          </td>

          <td style={{ maxWidth: 'initial' }}>{ledger.ledger.exception || 'None'}</td>

          <td>{ledger.ledger.updated_count !== null ? ledger.ledger.updated_count : 'Unknown'}</td>

          <td>{ledger.ledger.created_count !== null ? ledger.ledger.created_count : 'Unknown'}</td>

          <td>{rowNotesElements}</td>

          <td>
            {ledger.ledger.sync_type === 'prospects' ? (
              <button
                disabled={this.state.retryingCredId === ledger.credential.id}
                onClick={() => {
                  this.retryTask(ledger.ledger, ledger.credential);
                }}
                className="knock-react-button"
              >
                Retry
              </button>
            ) : null}
          </td>
        </tr>
      );
    });
  }

  renderProperty(property) {
    return (
      <table className="wire-frame-table" style={{ fontSize: '12px' }}>
        <thead>
          <tr>
            <th>Sync Type</th>

            <th>Started</th>

            <th>Completed</th>

            <th>Error</th>

            <th>Notes</th>

            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {this.renderSyncRow(property.prospects, 'Prospects', property.credential)}
          {this.renderSyncRow(property.units, 'Units', property.credential.vendor)}
          {this.renderSyncRow(property.residents, 'Residents', property.credential)}
        </tbody>
      </table>
    );
  }

  renderSyncRow(row, title, credential) {
    if (!row) {
      return (
        <tr style={SyncPage.badRowStyle}>
          <td>{title}</td>
          <td colSpan={5}>This job has never run!</td>
        </tr>
      );
    }

    let rowStyle = SyncPage.goodRowStyle;
    let rowNotes = [];

    if (
      moment()
        .subtract(24, 'hours')
        .isAfter(moment(row.created_time))
    ) {
      rowStyle = SyncPage.warnRowStyle;
      rowNotes.push('Job has not run in over 24 hours');
    }

    if (row.exception) {
      rowStyle = SyncPage.warnRowStyle;
      rowNotes.push('Job had an error.');
    }

    if (!row.completed_time) {
      rowStyle = SyncPage.badRowStyle;
      rowNotes.push('Job did not finish successfully.');
    }

    const rowNotesElements = rowNotes.map((note, index) => {
      return <div key={index}>{note}</div>;
    });

    return (
      <tr style={rowStyle}>
        <td>{title}</td>
        <td>{moment(row.created_time).format('MM/DD/YYYY hh:mm a')}</td>
        <td>
          {row.completed_time ? moment(row.completed_time).format('MM/DD/YYYY hh:mm a') : 'Failed'}
        </td>
        <td style={{ maxWidth: 'initial' }}>{row.exception || 'None'}</td>
        <td>{rowNotesElements}</td>
        <td>
          {title === 'Prospects' ? (
            <button
              disabled={this.state.retryingCredId === credential.id}
              onClick={() => {
                this.retryTask(row, credential);
              }}
              className="knock-react-button"
            >
              Retry
            </button>
          ) : null}
        </td>
      </tr>
    );
  }

  retryTask(row, credential) {
    this.setState({ retryingCredId: credential.id });

    PricingAPI.syncPmsProspects(credential.vendor, credential.property_id, 2).then(response => {
      this.setState({ retryingCredId: null });

      if (response.status_code === 'ok') {
        Toaster.showToast(response.message, 2000, Toaster.ToastClasses.success);
      } else {
        Toaster.showToast('Error retying sync', 2000, Toaster.ToastClasses.error);
      }
    });
  }
}

export default SyncPage;
