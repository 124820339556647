import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@knockrentals/knock-shared-web';
import { StatusChip } from '../../StatusChip';
import { TRIGGER_LABELS } from '../../../DrawerView/Campaign/constants';

export const getColumns = ({ setCampaign, isOriginCreateCampaign }) =>
  [
    {
      align: 'left',
      defaultSort: 'asc',
      title: 'Campaign Name',
      render: (rowData) => {
        const { campaignId, campaignName, status } = rowData;
        return (
          <Button
            component="span"
            onClick={() => setCampaign({ campaignId, status })}
            variant="text"
          >
            {campaignName}
          </Button>
        );
      },
      width: '349px',
    },
    !isOriginCreateCampaign && {
      align: 'left',
      cellStyle: { textTransform: 'capitalize' },
      title: 'Recipient',
      field: 'recipient',
      width: '102px',
    },
    !isOriginCreateCampaign && {
      align: 'left',
      title: 'Trigger',
      render: (rowData) => TRIGGER_LABELS[rowData.campaignTrigger],
      width: '374px',
    },
    !isOriginCreateCampaign && {
      align: 'center',
      field: 'associatedPropertiesCount',
      title: 'Associated Properties',
      width: '100px',
    },
    {
      align: isOriginCreateCampaign ? 'center' : 'left',
      field: 'status',
      render: (rowData) => <StatusChip status={rowData.status} />,
      title: 'Status',
      width: '102px',
    },
  ].filter((column) => column);

getColumns.propTypes = {
  setCampaign: PropTypes.func,
  isOriginCreateCampaign: PropTypes.bool,
};
