import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Chip } from '@material-ui/core';
import { makeStyles } from '@knockrentals/knock-shared-web';

export const DISABLED_STATUS = '-';
export const ENABLED_STATUS = 'ON';

const useStyles = makeStyles(() => ({
  statusChip: {
    backgroundColor: '#DCF6EE',
    '&.transparent': {
      backgroundColor: 'transparent',
    },
  },
}));

const StatusChip = ({ isEnabled }) => {
  const classes = useStyles();

  const label = isEnabled ? ENABLED_STATUS : DISABLED_STATUS;

  return (
    <Chip
      className={classnames(classes.statusChip, !isEnabled && 'transparent')}
      label={label}
    />
  );
};

StatusChip.propTypes = {
  isEnabled: PropTypes.bool,
};

export default StatusChip;
