import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  makeStyles,
  CardActions,
} from '@knockrentals/knock-shared-web';
import { DeleteSvgIcon } from '../../../../Components/Icons';

const useStyles = makeStyles((theme) => ({
  featureActions: {
    justifyContent: 'space-between',
    padding: '18px 22px',
    boxShadow: '0px -1px 0px rgba(24, 39, 76, 0.25)',
  },
  deleteBtn: {
    color: theme.palette.error.dark,
  },
  actionButton: {
    marginRight: '10px',
  },
  CardActions: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    display: 'flex',
  },
}));

const FeatureActions = ({
  handleSaveOnClick,
  handleDeleteOnClick,
  handleCancelOnClick,
  isCancelButtonHidden,
  isDeleteButtonVisible,
  isSaveButtonDisabled = false,
  primaryText = 'Save',
  primaryType = 'button',
}) => {
  const classes = useStyles();

  return (
    <CardActions className={classes.featureActions}>
      {isDeleteButtonVisible && (
        <Button
          className={classes.deleteBtn}
          color="primary"
          startIcon={<DeleteSvgIcon fontSize="small" />}
          variant="text"
          onClick={handleDeleteOnClick}
        >
          Delete
        </Button>
      )}

      <div className={classes.CardActions}>
        {!isCancelButtonHidden && (
          <Button
            className={classes.actionButton}
            color="primary"
            onClick={handleCancelOnClick}
            type="button"
            variant="outlined"
          >
            Cancel
          </Button>
        )}

        <Button
          className={classes.actionButton}
          color="primary"
          disabled={isSaveButtonDisabled}
          onClick={handleSaveOnClick}
          type={primaryType}
        >
          {primaryText}
        </Button>
      </div>
    </CardActions>
  );
};

FeatureActions.propTypes = {
  handleSaveOnClick: PropTypes.func,
  handleDeleteOnClick: PropTypes.func,
  isCancelButtonHidden: PropTypes.bool,
  isDeleteButtonVisible: PropTypes.bool,
  isSaveButtonDisabled: PropTypes.bool,
  handleCancelOnClick: PropTypes.func,
  primaryText: PropTypes.string,
  primaryType: PropTypes.string,
};

export default FeatureActions;
