import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import './PropertyInfoEditCustom.scss';

const InputURL = (props) => {
  return (
    <div className="input-container">
      <div className="input-url-label-container">
        <div className="input-url-label-container-text">
          {props.inputURLTitle}
        </div>
        <Tooltip title="This URL will be appended to the end of the Custom disclaimer text. Not required.">
          <i className="fa-regular fa-question-circle"></i>
        </Tooltip>
      </div>
      <div>
        <input
          className="knock-react-input"
          disabled={!props.textAreaValue}
          name={props.inputURLField}
          onBlur={props.URLChanged(true)}
          onChange={props.URLChanged()}
          placeholder="http(s)://www..."
          value={props.inputURLValue}
        />
        {props.errors[props.inputURLField] && (
          <span className="knock-react-form-hint knock-react-form-hint-error">
            {props.errors[props.inputURLField]}
          </span>
        )}
      </div>
    </div>
  );
};

InputURL.propTypes = {
  errors: PropTypes.objectOf(PropTypes.string),
  inputURLTitle: PropTypes.string,
  inputURLField: PropTypes.string,
  inputURLValue: PropTypes.string,
  textAreaValue: PropTypes.string,
  URLChanged: PropTypes.func,
};

export default InputURL;
