import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '@knockrentals/knock-react';

export const TRANSFER_TEAM_ERROR_MESSAGES = {
  INVALID: 'Invalid master/company to transfer to',
  SAME_MASTER_USER: 'Same master user',
  LEASING_TEAM_MEMBERS_NOT_EXCLUSIVE: 'Multi-team users cannot be transferred',
  COMPANY_MIGRATION_DIRECTION_NOT_ALLOWED:
    'Invalid transfer direction for Unified Platform company',
};

export const getTransferTeamErrorMessage = (errorMessage = '') => {
  const containsKnownError = Object.values(TRANSFER_TEAM_ERROR_MESSAGES).some(
    (transferTeamErrorMessage) =>
      transferTeamErrorMessage.toUpperCase() === errorMessage.toUpperCase()
  );

  return containsKnownError ? errorMessage : 'Error transferring team';
};

class TransferTeam extends Component {
  state = {
    transferTeamMasterUsername: null,
    transferringTeamInProgress: false,
  };

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  setTransferTeamMasterUsername(event) {
    this.setState({ transferTeamMasterUsername: event.target.value });
  }

  submit(event) {
    event.preventDefault();

    this.setState({ transferringTeamInProgress: true });

    this.props.onSubmit(this.state.transferTeamMasterUsername).then(() => {
      this.setState({ transferringTeamInProgress: false });
    });
  }

  cancel(event) {
    event.preventDefault();
    this.props.onCancel();
  }

  renderTransferErrors() {
    if (!this.props.transferErrors) {
      return null;
    }

    return (
      <div>
        <div>
          <strong>Invalid</strong>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal className="user-editor-container">
        <form onSubmit={this.submit}>
          <ModalHeader>
            <h2>Transfer {this.props.team.leasing_team_name}</h2>
          </ModalHeader>
          <ModalContent>
            <div className="knock-react-flex">
              <div>
                <strong>Master username at new company: </strong>
              </div>
              <div>
                <input
                  type="text"
                  className="knock-react-input"
                  name="teamName"
                  value={this.state.transferTeamMasterUsername}
                  onChange={this.setTransferTeamMasterUsername.bind(this)}
                />
              </div>
            </div>
            {this.renderTransferErrors()}
          </ModalContent>

          {this.state.transferringPropertyInProgress ? (
            <ModalFooter>
              <button className="knock-react-button" disabled>
                Transfering...
              </button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <button className="knock-react-button">Transfer</button>
              <button
                className="knock-react-button"
                onClick={this.cancel.bind(this)}
              >
                Cancel
              </button>
            </ModalFooter>
          )}
        </form>
      </Modal>
    );
  }
}

export default TransferTeam;
