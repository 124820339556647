export const removeSpaces = (value) => value.replace(/\s+/g, '');

export const whiteSpaceRestrictValue = (setFieldValue) => (e) => {
  const cleanValue = removeSpaces(e.target.value);
  setFieldValue(e.target.name, removeSpaces(cleanValue));
};

export const trimValue = (setFieldValue) => (e) => {
  setFieldValue(e.target.name, e.target.value.trim());
};

export const validateUrl = (url) => {
  return /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/.test(
    url
  );
};

export const sortCaseInsensitive = (field) => {
  const collator = new Intl.Collator('en', {
    caseFirst: 'upper',
  });
  return (a, b) => collator.compare(a[field], b[field]);
};
