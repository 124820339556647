import React from 'react';

import PropertyResponses from './Components/PropertyResponses';
import { Alert } from '@knockrentals/knock-react';
import * as VirtualAgentAPI from '../VirtualAgentAPI';

const VirtualAgentLeasingResponses = ({
  properties,
  intents,
  onConfirmation,
  currentTab
}) => {

  return (
    <React.Fragment>
      {properties.length ? (
        properties.map((property) => (
          <PropertyResponses
            key={property.id}
            property={property}
            intents={intents}
            onConfirmation={onConfirmation}
            getResponses={VirtualAgentAPI.getLeasingResponsesV2}
            saveResponses={VirtualAgentAPI.saveLeasingResponsesV2}
            currentTab={currentTab}
          />
        ))
      ) : (
        <Alert>No properties to display.</Alert>
      )}
    </React.Fragment>
  );
};

export default VirtualAgentLeasingResponses;
