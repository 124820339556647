import React, { Component } from 'react';
import { FeatureFlagContext } from '../../Context/FeatureFlagContext';
import DatePicker from 'react-datepicker';
import PropertyIntegration from '../Integrations/PropertyIntegration';
import PricingAPI from './PricingAPI';
import UnitsAPI from '../Properties/UnitsApi';
import {
  Toaster,
  AuthenticationService,
  Collapse,
  Alert,
} from '@knockrentals/knock-react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import URLInput from './URLInput';
import { IntegrationVendorNamesLowerCase } from '../Integrations/constants';

class PropertyPricing extends Component {
  state = {
    isEditingPreferredFloorplan: false,
    floorplans: {},
    syncedProspects: false,
    isLoaded: false,
    syncJobStartDate: moment().startOf('days').subtract(29, 'days'),
    syncJobEndDate: moment().endOf('days').add(1, 'days'),
    layouts: [],
  };

  captureSyncJobDateChange(date) {
    if (date.diff(this.state.syncJobEndDate, 'days') === 0) {
      return;
    }

    this.setState({
      syncJobStartDate: date,
    });
  }

  fetchFeatureFlags = () => {
    // Destructure feature flags and update flag fn from context provider and state
    // If the feature flag has not already been fetched for this leasing team, initialize and set in context
    // If no leasing team, or the flag can't be found, it will evaluate to false
    const { newUnitsFlags, updateNewUnitsFlags } = this.context;

    if (
      this.props.leasingTeamId &&
      newUnitsFlags[this.props.leasingTeamId] === undefined
    ) {
      updateNewUnitsFlags(this.props.leasingTeamId);
    }
  };

  render() {
    return (
      <Collapse
        title={this.props.property.property_name}
        onToggled={this.onToggled.bind(this)}
      >
        {this.state.isLoaded ? this.renderLoaded() : this.renderLoading()}
      </Collapse>
    );
  }

  renderLoaded() {
    if (this.props.property.missingIntegration) {
      return (
        <div>
          <Alert>Property has no integrations.</Alert>
        </div>
      );
    }
    return (
      <div>
        <div>
          <div>
            <strong>Owner:</strong>{' '}
            {this.props.property.owning_manager_username}
          </div>

          <div>
            <strong>Knock ID:</strong> {this.props.property.community_id}
          </div>

          {this.renderIntegrations()}
        </div>

        {this.renderPricingOptions()}
      </div>
    );
  }

  renderIntegrations() {
    return this.props.property.integrations.map((integration) => (
      <PropertyIntegration key={integration.vendor} integration={integration} />
    ));
  }

  renderLoading() {
    return (
      <div>
        <span>Loading...</span>
      </div>
    );
  }

  onToggled(isOpen) {
    if (isOpen && !this.state.isLoaded) {
      this.fetchFeatureFlags();
      PricingAPI.getPropertyPricing([this.props.property.property_id])
        .then(({ pricing_data }) => {
          const pricingData = pricing_data || [];
          const propertyData = pricingData.find(
            (property) => property.id === this.props.property.id
          );

          this.props.onDataRetrieved(
            this.props.property.property_id,
            propertyData,
            () => this.setState({ isLoaded: true })
          );
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  mapOptions(optionFields, joinSeparator) {
    return optionFields
      .filter((id) => id)
      .map((id) => {
        const title = id
          .split('_')
          .map((part) => part[0].toUpperCase() + part.substring(1))
          .join(joinSeparator);

        return (
          <option key={id} value={id}>
            {title}
          </option>
        );
      });
  }

  mapObjectOptions(optionFields) {
    return optionFields
      .filter((id) => id)
      .map((id, idx) => {
        let description = id.description;

        if (id.description === 'Yieldstar') {
          description = 'Pricing Matrix- 12 Month';
        }

        if (id.description === 'Effective rent') {
          description = 'Pricing Matrix- Effective rent (min)';
        }

        return (
          <option key={idx} value={id.title}>
            {description}
          </option>
        );
      });
  }

  renderPricingOptions() {
    return this.props.property.integrations.map(
      this.renderIntegrationPricingOptions.bind(this)
    );
  }

  renderIntegrationPricingOptions(integration) {
    // Destructure feature flags from context provider
    const { newUnitsFlags } = this.context;
    const newUnitsEnabled = this.props.leasingTeamId
      ? newUnitsFlags[this.props.leasingTeamId]
      : false;

    const integrationVendor = integration.vendor.toLowerCase();

    if (integrationVendor === IntegrationVendorNamesLowerCase.ONSITE) {
      return null;
    }

    let mappedLossReasons = undefined;
    const { preferences } = integration;

    if ('loss_reasons' in preferences) {
      mappedLossReasons = this.mapOptions(preferences.loss_reasons, ' ');
    }

    const vendorPricingOptions = this.props.pricingOptions[integrationVendor];

    let mappedPricingOptions = undefined;

    if (vendorPricingOptions) {
      mappedPricingOptions = this.mapObjectOptions(vendorPricingOptions);
    }

    let mappedResidentTypesOptions = undefined;

    if (integrationVendor in this.props.residentImportTypes) {
      const vendorResidentImportTypes =
        this.props.residentImportTypes[integrationVendor];
      mappedResidentTypesOptions = this.mapOptions(
        vendorResidentImportTypes,
        ' + '
      );
    }

    let mappedEventResults = undefined;
    if (preferences.event_results) {
      mappedEventResults = this.mapOptions(preferences.event_results, ' ');
    }

    return (
      <div key={integration.vendor_property_id}>
        <h3>{integration.vendor_property_id}</h3>

        {preferences.rent_option !== undefined &&
        mappedPricingOptions !== undefined ? (
          <div>
            <strong>Pricing method: </strong>
            <select
              value={preferences.rent_option}
              onChange={this.selectedUpdated(integration, 'rent_option').bind(
                this
              )}
            >
              {mappedPricingOptions}
            </select>
          </div>
        ) : null}

        {/* TODO-NU: Remove this check when new units is enabled for all users */}
        {newUnitsEnabled ||
        integrationVendor === 'yardi' ||
        integrationVendor === 'realpage' ? (
          <div>
            <strong>Override pricing manually: </strong>
            <input
              type="checkbox"
              checked={preferences.use_manual_pricing}
              onChange={this.checkboxUpdated(
                integration,
                'use_manual_pricing'
              ).bind(this)}
            />
          </div>
        ) : null}

        {newUnitsEnabled && integrationVendor === 'resman' && (
          <div>
            <strong>Import All Units (marketable and non-marketable)</strong>
            <input
              type="checkbox"
              checked={preferences.import_all_units}
              onChange={this.checkboxUpdated(
                integration,
                'import_all_units'
              ).bind(this)}
            />
          </div>
        )}

        {/* TODO-NU: Remove this section once new units flag is on for all users */}
        {!newUnitsEnabled && preferences.new_listing_hidden !== undefined ? (
          <div>
            <strong>Default new listings to hidden: </strong>
            <input
              type="checkbox"
              checked={preferences.new_listing_hidden}
              onChange={this.checkboxUpdated(
                integration,
                'new_listing_hidden'
              ).bind(this)}
            />
          </div>
        ) : null}

        {preferences.suppress_exports !== undefined ? (
          <div>
            <strong>Suppress exports: </strong>
            <input
              type="checkbox"
              checked={preferences.suppress_exports}
              onChange={this.checkboxUpdated(
                integration,
                'suppress_exports'
              ).bind(this)}
            />
          </div>
        ) : null}

        {/* TODO-NU: Remove this section once new units flag is on for all users */}
        {!newUnitsEnabled && preferences.new_listing_as_hidden !== undefined ? (
          <div>
            <strong>Default new listings to hidden: </strong>
            <input
              type="checkbox"
              checked={preferences.new_listing_as_hidden}
              onChange={this.checkboxUpdated(
                integration,
                'new_listing_as_hidden'
              ).bind(this)}
            />
          </div>
        ) : null}

        {/* TODO-NU: Remove this section once new units flag is on for all users */}
        {!newUnitsEnabled && preferences.use_made_ready_date !== undefined ? (
          <div>
            <strong>MadeReadyDate = First Availability date: </strong>
            <input
              type="checkbox"
              checked={preferences.use_made_ready_date}
              onChange={this.checkboxUpdated(
                integration,
                'use_made_ready_date'
              ).bind(this)}
            />
          </div>
        ) : null}

        {/* TODO-NU: Remove this section once new units flag is on for all users */}
        {!newUnitsEnabled && preferences.use_ready_date !== undefined ? (
          <div>
            <strong>Use ready date as first available date: </strong>
            <input
              type="checkbox"
              checked={preferences.use_ready_date}
              onChange={this.checkboxUpdated(
                integration,
                'use_ready_date'
              ).bind(this)}
            />
          </div>
        ) : null}

        {/* TODO-NU: Remove this section once new units flag is on for all users */}
        {!newUnitsEnabled &&
        preferences.use_concessions_pricing !== undefined ? (
          <div>
            <strong>Subtract concessions: </strong>
            <input
              type="checkbox"
              checked={preferences.use_concessions_pricing}
              onChange={this.checkboxUpdated(
                integration,
                'use_concessions_pricing'
              ).bind(this)}
            />
          </div>
        ) : null}

        {/* TODO-NU: Remove this section once new units flag is on for all users */}
        {!newUnitsEnabled && preferences.floorplan_creation !== undefined ? (
          <div>
            <strong>Floorplan creation: </strong>
            <select
              value={preferences.floorplan_creation}
              onChange={this.selectedUpdated(
                integration,
                'floorplan_creation'
              ).bind(this)}
            >
              <option value={'from_unit'}>
                Create floorplan from Yardi units
              </option>
              <option value={'from_floorplan'}>
                Create floorplan from Yardi floorplans
              </option>
            </select>
          </div>
        ) : null}

        {preferences.unit_status !== undefined ? (
          <div>
            <strong>
              Which status(es) do you want to display in Knock under your Active
              Ads:{' '}
            </strong>
            <select
              value={preferences.unit_status}
              onChange={this.selectedUpdated(integration, 'unit_status').bind(
                this
              )}
            >
              <option value={'available_and_on_notice'}>
                Available & On Notice
              </option>
              <option value={'available'}>Available</option>
            </select>
          </div>
        ) : null}

        {preferences.sync_prospects !== undefined &&
        AuthenticationService._internalMode ? (
          <div>
            <strong>Import guest cards: </strong>
            <input
              type="checkbox"
              checked={preferences.sync_prospects}
              onChange={this.checkboxUpdated(
                integration,
                'sync_prospects'
              ).bind(this)}
            />
          </div>
        ) : null}

        {preferences.resident_import_type !== undefined ? (
          <div>
            <strong>
              Which residents do you want to pull into Knock from Yardi?{' '}
            </strong>
            <select
              value={preferences.resident_import_type}
              onChange={this.selectedUpdated(
                integration,
                'resident_import_type'
              ).bind(this)}
            >
              {mappedResidentTypesOptions}
            </select>
          </div>
        ) : null}

        {preferences.default_event_result !== undefined ? (
          <div>
            <strong>First Contact Result </strong>
            <select
              value={preferences.default_event_result}
              onChange={this.selectedUpdated(
                integration,
                'default_event_result'
              ).bind(this)}
            >
              <option value="null">None</option>
              {mappedEventResults}
            </select>
          </div>
        ) : null}

        {preferences.default_loss_reason !== undefined ? (
          <div>
            <strong>Default loss reason: </strong>
            <select
              value={preferences.default_loss_reason}
              onChange={this.selectedUpdated(
                integration,
                'default_loss_reason'
              ).bind(this)}
            >
              {mappedLossReasons}
            </select>
          </div>
        ) : null}

        {/* TODO-NU: Remove legacy floorplan logic */}
        {!newUnitsEnabled &&
        preferences.default_preferred_property_floorplan_id !== undefined ? (
          <div>
            <strong>Pre-assign Preferred Floorplan: </strong>
            {!this.state.isEditingPreferredFloorplan ? (
              <span>
                <span>
                  {this.renderFloorplanName(
                    preferences.default_preferred_property_floorplan
                  )}{' '}
                </span>
                <span>
                  <a
                    href={'#'}
                    onClick={this.startEditingPreferredFloorplan(integration)}
                  >
                    Edit
                  </a>
                </span>
              </span>
            ) : (
              this.renderFloorplanSelect(integration)
            )}
          </div>
        ) : null}

        {/* New Units preferred layout select */}
        {newUnitsEnabled &&
        preferences.default_preferred_layout_id !== undefined ? (
          <div>
            <strong>
              Default preferred floorplan to pre-assign prospects:{' '}
            </strong>
            {!this.state.isEditingPreferredFloorplan ? (
              <span>
                <span>
                  {this.renderLayoutName(
                    preferences.default_preferred_layout_id,
                    false
                  )}{' '}
                </span>
                <span>
                  <a
                    href={'#'}
                    onClick={this.startEditingPreferredLayout(integration)}
                  >
                    Edit
                  </a>
                </span>
              </span>
            ) : (
              this.renderLayoutSelect(integration)
            )}
          </div>
        ) : null}

        {preferences.chat_contact_type_mapping !== undefined ? (
          <div>
            <strong>Chat contact type mapping? </strong>
            <select
              value={preferences.chat_contact_type_mapping}
              onChange={this.selectedUpdated(
                integration,
                'chat_contact_type_mapping'
              ).bind(this)}
            >
              <option key="chat" value="Chat">
                Chat
              </option>
              <option key="email" value="Email">
                Email
              </option>
            </select>
          </div>
        ) : null}

        {preferences.text_contact_type_mapping !== undefined ? (
          <div>
            <strong>Text contact type mapping? </strong>
            <select
              value={preferences.text_contact_type_mapping}
              onChange={this.selectedUpdated(
                integration,
                'text_contact_type_mapping'
              ).bind(this)}
            >
              <option key="text" value="Text">
                Text
              </option>
              <option key="email" value="Email">
                Email
              </option>
            </select>
          </div>
        ) : null}

        {preferences.capitalize_profile !== undefined ? (
          <div>
            <strong>Capitalize profile fields? </strong>
            <input
              type="checkbox"
              checked={preferences.capitalize_profile}
              onChange={this.checkboxUpdated(
                integration,
                'capitalize_profile'
              ).bind(this)}
            />
          </div>
        ) : null}

        {preferences.leasing_application_url !== undefined ? (
          <div>
            <strong>Online Leasing Application URL: </strong>
            <URLInput
              updateValue={(value) =>
                this.valueUpdated(integration, 'leasing_application_url', value)
              }
              url={preferences.leasing_application_url}
            />
          </div>
        ) : null}

        {integrationVendor === 'realpage' && (
          <div>
            <strong>RP unified quoting widget: </strong>
            <input
              type="checkbox"
              checked={preferences.unified_quotes}
              onChange={this.checkboxUpdated(
                integration,
                'unified_quotes'
              ).bind(this)}
            />
          </div>
        )}

        {AuthenticationService._internalMode ? (
          <div>
            <DatePicker
              selected={this.state.syncJobStartDate}
              selectsStart
              syncJobStartDate={this.state.syncJobStartDate}
              syncJobEndDate={this.state.syncJobEndDate}
              onChange={this.captureSyncJobDateChange.bind(this)}
              minDate={moment().subtract(89, 'days')}
              maxDate={moment().add(1, 'days')}
              placeholderText="Select a date between today and 90 days in the past"
            />

            <button
              className="knock-react-button"
              onClick={() => this.syncIntegrationProspects(integration)}
            >
              Run{' '}
              {this.state.syncJobEndDate.diff(
                this.state.syncJobStartDate,
                'days'
              ) + 1}
              -day sync
            </button>

            {this.state.syncedProspects ? (
              <div>Sync job is scheduled</div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }

  checkboxUpdated(integration, key) {
    return (event) => {
      this.valueUpdated(integration, key, event.target.checked);
    };
  }

  selectedUpdated = (integration, key) => {
    return (event) => {
      this.valueUpdated(integration, key, event.target.value);
    };
  };

  valueUpdated(integration, key, value) {
    integration.preferences[key] = value;
    this.props.onPreferencesChanged(
      this.props.property.property_id,
      integration.vendor_property_id,
      integration.preferences
    );
  }

  renderLayoutSelect = (integration) => {
    return (
      <span>
        {this.state.layouts ? (
          <select
            value={integration.preferences.default_preferred_layout_id}
            onChange={this.selectedUpdated(
              integration,
              'default_preferred_layout_id'
            )}
          >
            <option value={null}>None</option>
            {this.state.layouts.map((layout) =>
              this.renderLayoutName(layout.id, true)
            )}
          </select>
        ) : (
          <i className="fa fa-cog fa-spin" />
        )}
      </span>
    );
  };

  renderFloorplanSelect = (integration) => {
    // TODO-NU: Remove legacy floorplan logic
    return (
      <span>
        {this.state.floorplans[integration.vendor_property_id] ? (
          <select
            value={
              integration.preferences.default_preferred_property_floorplan_id
            }
            onChange={this.selectedUpdated(
              integration,
              'default_preferred_property_floorplan_id'
            )}
          >
            <option value={null}>None</option>
            {this.renderFloorplans(integration)}
          </select>
        ) : (
          <i className="fa fa-cog fa-spin" />
        )}
      </span>
    );
  };

  renderFloorplans(integration) {
    // TODO-NU: Remove legacy floorplan logic
    return this.state.floorplans[integration.vendor_property_id].map(
      (floorplan) => {
        return (
          <option key={floorplan.id} value={floorplan.property_floorplan_id}>
            {this.renderFloorplanName(floorplan)}
          </option>
        );
      }
    );
  }

  syncIntegrationProspects(integration) {
    const diffDays =
      this.state.syncJobEndDate.diff(this.state.syncJobStartDate, 'days') + 1;

    return PricingAPI.syncPmsProspects(
      integration.vendor,
      this.props.property.property_id,
      diffDays
    )
      .then(() => {
        Toaster.showToast(
          'Scheduled sync job!',
          2000,
          Toaster.ToastClasses.success
        );
        this.setState({ syncedProspects: true });
      })
      .catch(() => {
        Toaster.showToast(
          'Unable to schedule sync job',
          2000,
          Toaster.ToastClasses.error
        );
        this.setState({ syncedProspects: false });
      });
  }

  startEditingPreferredLayout(integration) {
    // Fetch new units layouts and store in state
    this.setState({
      isEditingPreferredFloorplan: integration.vendor_property_id,
    });

    UnitsAPI.getLayouts(this.props.property.property_id)
      .then((response) => {
        this.setState({ layouts: response.layouts });
      })
      .catch((error) => console.error('Error fetching layouts: ', error));
  }

  startEditingPreferredFloorplan(integration) {
    // TODO-NU: Remove legacy floorplan logic
    this.setState({
      isEditingPreferredFloorplan: integration.vendor_property_id,
    });

    PricingAPI.getPropertyFloorplans(
      this.props.property.property_id,
      integration.vendor_property_id
    ).then((response) => {
      const { floorplans } = this.state;
      floorplans[integration.vendor_property_id] = response.floorplans;

      this.setState({ floorplans });
    });
  }

  renderLayoutName = (layoutId, isOption) => {
    // Render the layout name for a given layout object.
    // If isOption is True, render as an option element. Else render as a string.
    const layout = this.state.layouts.find((layout) => layout.id === layoutId);
    if (
      !layout ||
      (!layout.name && !layout.bedrooms && !layout.bathrooms && !layout.area)
    ) {
      console.warn(`No available layout information for layout ${layoutId}`);
      return;
    }

    const name = layout.name ? `${layout.name} - ` : '';
    const bedrooms = layout.bedrooms ? `${layout.bedrooms}bd, ` : '0bd, ';
    const bathrooms = layout.bathrooms ? `${layout.bathrooms}ba` : '0ba';
    const area = layout.area ? ` - ${layout.area.toLocaleString()} sqft` : '';

    const layoutName = `${name}${bedrooms}${bathrooms}${area}`;
    return isOption ? (
      <option id={layout.id} value={layout.id}>
        {layoutName}
      </option>
    ) : (
      layoutName
    );
  };

  renderFloorplanName(floorplan) {
    // TODO-NU: Remove legacy floorplan logic
    if (!floorplan) {
      return 'None';
    }
    return `(ID: ${floorplan.property_floorplan_id}) - ${floorplan.name} - ${floorplan.bedrooms}bd, ${floorplan.bathrooms}ba`;
  }
}

export default PropertyPricing;

PropertyPricing.contextType = FeatureFlagContext;
