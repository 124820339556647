import React from 'react';
import classnames from 'classnames';

import {
  PageContainer,
  AuthenticationService,
} from '@knockrentals/knock-react';
import PropertySourcesTab from './PropertySourcesTab';
import SpreadsheetLink from './SpreadSheetLink';
import { SourcesPageTabs } from './SourcesPageTabs';
import { useFeatureFlagContext } from '../../Context/FeatureFlagContext';

import './_SourcesPage.scss';

const SourcesPage = () => {
  const { isCompanySourceListEnabled } = useFeatureFlagContext();

  const isCompanySourcesEnabled =
    AuthenticationService._internalMode && isCompanySourceListEnabled;

  return (
    <PageContainer
      className={classnames(
        'sources-page',
        isCompanySourcesEnabled && 'tabbed-sources'
      )}
    >
      <h1 className="page-heading">Sources</h1>
      <SpreadsheetLink />
      {isCompanySourcesEnabled ? <SourcesPageTabs /> : <PropertySourcesTab />}
    </PageContainer>
  );
};

export default SourcesPage;
