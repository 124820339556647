import React, { useState } from 'react';
import { PageContainer, Toaster } from '@knockrentals/knock-react';
import * as ProspectsAPI from './ProspectsAPI';
import ProspectsImport from './ProspectsImport';
import './_ProspectsPage.scss';

const handleError = (message) =>
  Toaster.showToast(message, 2000, Toaster.ToastClasses.error);

const handleSuccess = (message) =>
  Toaster.showToast(message, 2000, Toaster.ToastClasses.success);

const ProspectsPage = () => {
  const [errorMessages, setErrorMessages] = useState([]);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const handleCancel = () => {
    setIsImportModalOpen(false);
    if (errorMessages.length) {
      setErrorMessages([]);
    }
  };

  const onImportProspects = async (prospectsFile) => {
    setErrorMessages([]);
    const data = new FormData();
    data.append('prospects-import', prospectsFile, 'prospects-import.csv');

    try {
      const response = await ProspectsAPI.postProspects(data);

      if (response.validation_errors) {
        setErrorMessages(response.validation_errors);
        handleError('Error importing prospects');
        return;
      }

      handleSuccess('Uploaded prospects successfully!');
    } catch (e) {
      handleError('Error importing prospects');
    }
  };

  return (
    <PageContainer className="scheduling-preferences-container">
      <h1>Prospect Upload</h1>

      <button
        className="knock-react-button btn-sm btn-success"
        onClick={() => {
          setIsImportModalOpen(true);
        }}
      >
        <i className="fa fa-lg fa-plus-circle" /> Upload CSV File
      </button>

      {isImportModalOpen && (
        <ProspectsImport
          errorMessages={errorMessages}
          onCancel={handleCancel}
          onImportProspects={onImportProspects}
        />
      )}
    </PageContainer>
  );
};

export default ProspectsPage;
