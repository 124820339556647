import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { DraggableList, DraggableListRowContainer } from './index';
import { v4 as uuidv4 } from 'uuid';

export const DraggableListContext = React.createContext({
  isDragging: false,
  isEditOccuring: false,
  handleCancel: undefined,
  handleDelete: undefined,
  handleEdit: undefined,
  handleSave: undefined,
  raiseErrorMessage: undefined,
  validator: undefined,
});

/**
 * A dynamic width, draggable list of items that can be individually edited, deleted, and reordered. Calls props.onDataChange with the updated state
 * of the data after any edit action occurs. Expects parent to reinitialize component with updated props after data update has been accepted.
 * Expects all data to include unique `id` field. Performance will degrade as number of items exceeds 50.
 *
 * @param {Array<Object>} props.data An array of objects representing rows of data. Each object must contain an `id` field.
 * @param {Array<string>} props.fields An ordered array representing the data fields to be parsed for data object.
 * @param {Array<string>} props.headers The headers to be displayed at the top of each column. Ordering should match `fields` array.
 * @param {number} props.maxSize An optional value representing the maxiumum number of items that can be contained by the list.
 * @param {Yup.object} props.validator An optional `Yup.object` validator to be run on each row of data on change submission. Customized error messages will be displayed.
 * @param {function} props.onDataChange The function to be called when the list has been updated. Will be called with the full updated state of the `data` array as argument.
 */
export const DraggableListContainer = ({
  data,
  fields,
  title,
  headers,
  maxSize,
  validator,
  onDataChange,
}) => {
  // Data validation and setup
  if (fields.length !== headers.length) {
    throw new Error('Number of fields does not match number of headers.');
  }

  if (data.length > maxSize) {
    throw new Error('Data exceeds max size');
  }

  data.forEach((item) => {
    if (!item.id) {
      throw new Error('Data must include "id" field');
    }
  });

  const [tableState, setTableState] = useState({
    isDragging: false,
    isEditOccuring: false,
    addedRow: null,
    errorMessage: null,
  });

  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  const { isDragging, isEditOccuring, addedRow, errorMessage } = tableState;

  useEffect(() => {
    const dataLength = data.length + (addedRow ? 1 : 0);
    const isMaxSizeReached = dataLength >= maxSize;
    setIsAddButtonDisabled(isMaxSizeReached || isEditOccuring);
  }, [data, isEditOccuring]);

  // Actions
  const onBeforeDragStart = () => {
    setTableState({
      ...tableState,
      isDragging: true,
    });
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;

    setTableState({
      ...tableState,
      isDragging: false,
    });

    if (!destination) {
      return;
    }

    if (
      destination.droppableID === source.droppableID &&
      destination.index === source.index
    ) {
      return;
    }

    // Updating the data to return to parent via prop callback
    const updatedRows = [...data];
    const rowToMove = updatedRows.splice(source.index, 1)[0];
    updatedRows.splice(destination.index, 0, rowToMove);

    onDataChange(updatedRows);
  };

  const handleAdd = () => {
    const currentLength = data.length;

    if (currentLength > maxSize - 1) {
      return;
    }

    // Creating an new data row with each field inited to blank string
    const newData = fields.reduce(
      (object, field) => {
        object[field] = '';
        return object;
      },
      {
        id: uuidv4(),
      }
    );

    setTableState({
      ...tableState,
      addedRow: newData,
      isEditOccuring: true,
    });
  };

  const handleCancel = (rowIndex) => {
    const initialDataLength = data.length;
    const isNewItem = rowIndex >= initialDataLength;

    const updatedState = {
      isEditOccuring: false,
      errorMessage: null,
    };

    // If item is new, 'Cancel' button deletes incomplete entry
    if (isNewItem) {
      updatedState.addedRow = undefined;
    }

    setTableState({
      ...tableState,
      ...updatedState,
    });
  };

  const handleDelete = (rowIndex) => {
    const rowsData = [...data];

    rowsData.splice(rowIndex, 1);

    onDataChange(rowsData);
  };

  const handleEdit = () => {
    setTableState({
      ...tableState,
      isEditOccuring: true,
    });
  };

  const handleSave = (rowIndex, content) => {
    const rowsData = [...data];

    const initialDataLength = rowsData.length;
    const isNewItem = rowIndex >= initialDataLength;

    const updatedState = {
      isEditOccuring: false,
      errorMessage: null,
    };

    if (isNewItem) {
      updatedState.addedRow = null;

      rowsData.push({ ...content });
    } else {
      rowsData[rowIndex] = { ...content };
    }

    setTableState({
      ...tableState,
      ...updatedState,
    });

    onDataChange(rowsData);
  };

  // Helpers
  const raiseErrorMessage = (errorMessage) => {
    setTableState({
      ...tableState,
      errorMessage,
    });
  };

  const isRowInitInEditMode = (index) => index >= data.length;

  // Data
  const isDraggingContextValue = {
    isDragging,
    isEditOccuring,
    handleCancel,
    handleDelete,
    handleEdit,
    handleSave,
    raiseErrorMessage,
    validator,
  };

  const dataToDisplay = !addedRow ? data : [...data, addedRow];

  return (
    <DraggableListContext.Provider value={isDraggingContextValue}>
      <DragDropContext
        onBeforeDragStart={onBeforeDragStart}
        onDragEnd={onDragEnd}
      >
        <Droppable droppableId="0">
          {(provided) => (
            <DraggableList
              title={title}
              headers={headers}
              errorMessage={errorMessage}
              handleAdd={handleAdd}
              isAddButtonDisabled={isAddButtonDisabled}
              provided={provided}
            >
              {dataToDisplay.map((row, index) => (
                <DraggableListRowContainer
                  fields={fields}
                  key={row.id}
                  row={row}
                  index={index}
                  initInEditMode={isRowInitInEditMode(index)}
                />
              ))}
            </DraggableList>
          )}
        </Droppable>
      </DragDropContext>
    </DraggableListContext.Provider>
  );
};
