import React, { Component } from 'react';
import AttributesAPI from '../AttributesAPI';

import ChangeAttributeModal from './Modals/ChangeAttributeModal';
import AddAttributeModal from './Modals/AddAttributeModal';

import '../_attributeInfo.scss';
import * as _ from 'lodash';

class SourceAttributes extends Component {
  state = {
    sourceAttributes: [],
    marketingSources: [],

    isChangingAttribute: false,
    isAddingAttribute: false,
  };

  ATTRIBUTE_SUGGESTIONS = {};

  UNSAFE_componentWillMount() {
    this.loadAttributes();
  }

  loadAttributes() {
    AttributesAPI.getSourceAttributes()
      .then(response => {
        const marketingSources = response.marketing_sources;
        marketingSources.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });

        const sourceAttributes = response.company_source_attributes;

        _.forEach(marketingSources, (marketingSource) => {
          if (marketingSource.default_attribute) {
            this.ATTRIBUTE_SUGGESTIONS[marketingSource.default_attribute] = true;
          }
        });

        _.forEach(sourceAttributes, (sourceAttribute) => {
          if (sourceAttribute.attribute) {
            this.ATTRIBUTE_SUGGESTIONS[sourceAttribute.attribute] = true;
          }
        });

        this.setState({ sourceAttributes, marketingSources });
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    return (
      <div>
        {this.state.isChangingAttribute ? (
          <ChangeAttributeModal
            marketingSource={this.state.isChangingAttribute.marketingSource}
            sourceAttribute={this.state.isChangingAttribute.sourceAttribute}
            suggestions={this.ATTRIBUTE_SUGGESTIONS}
            updateAttribute={this.updateAttribute.bind(this)}
            deleteAttribute={this.deleteAttribute.bind(this)}
            toggleModal={this.toggleChangeAttributeModal.bind(this)}
          />
        ) : null}
        {this.state.isAddingAttribute ? (
          <AddAttributeModal
            marketingSource={this.state.isAddingAttribute.marketingSource}
            suggestions={this.ATTRIBUTE_SUGGESTIONS}
            createAttribute={this.createAttribute.bind(this)}
            toggleModal={this.toggleAddAttributeModal.bind(this)}
          />
        ) : null}
        <table className="wire-frame-table">
          <thead>
            <tr>
              <th />
              <th>Global Default</th>
              <th>Effective Attribute</th>
              <th />
            </tr>
          </thead>
          <tbody>{this.renderSourceRows()}</tbody>
        </table>
      </div>
    );
  }

  renderSourceRows() {
    return this.state.marketingSources.map((marketingSource, i) => {
      let effectiveAttributeValue = marketingSource.default_attribute;
      let effectiveSourceAttribute = null;

      _.forEach(this.state.sourceAttributes, (sourceAttribute) => {
        if (marketingSource.id === sourceAttribute.marketing_source_id) {
          effectiveAttributeValue = sourceAttribute.attribute;
          effectiveSourceAttribute = sourceAttribute;
        }
      });

      return (
        <tr key={`source-${marketingSource.id}`}>
          <td>{marketingSource.name}</td>

          <td className="shaded">
            {marketingSource.default_attribute ? (
              <span>{marketingSource.default_attribute} </span>
            ) : (
              <small className="muted">None</small>
            )}
          </td>

          <td>
            {effectiveAttributeValue ? (
              <span>{effectiveAttributeValue} </span>
            ) : (
              <small className="muted">None</small>
            )}
          </td>

          <td>
            {effectiveSourceAttribute ? (
              <button
                className="btn-sm btn-primary"
                onClick={this.toggleChangeAttributeModal.bind(this, {
                  marketingSource,
                  sourceAttribute: effectiveSourceAttribute,
                })}
              >
                <i className="fa fa-edit" /> Modify custom
              </button>
            ) : (
              <button
                className="btn-sm btn-success"
                onClick={this.toggleAddAttributeModal.bind(this, {
                  marketingSource,
                })}
              >
                <i className="fa fa-edit" /> Override default
              </button>
            )}
          </td>
        </tr>
      );
    });
  }

  createAttribute(marketingSourceId, attributeValue) {
    AttributesAPI.postSourceAttribute(attributeValue, marketingSourceId).then(() => {
      this.loadAttributes();
      this.toggleAddAttributeModal(false);
      this.toggleChangeAttributeModal(false);
    });
  }

  updateAttribute(sourceAttributeId, marketingSourceId, attributeValue) {
    AttributesAPI.updateSourceAttribute(sourceAttributeId, marketingSourceId, attributeValue).then(
      () => {
        this.loadAttributes();
        this.toggleAddAttributeModal(false);
        this.toggleChangeAttributeModal(false);
      }
    );
  }

  deleteAttribute(sourceAttributeId) {
    AttributesAPI.deleteSourceAttribute(sourceAttributeId).then(() => {
      this.loadAttributes();
      this.toggleAddAttributeModal(false);
      this.toggleChangeAttributeModal(false);
    });
  }

  toggleAddAttributeModal(obj) {
    this.setState({
      isAddingAttribute: obj,
    });
  }

  toggleChangeAttributeModal(obj) {
    this.setState({
      isChangingAttribute: obj,
    });
  }
}

export default SourceAttributes;
