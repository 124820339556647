import React, { Component, Fragment } from 'react';
import { FeatureFlagContext } from '../../Context/FeatureFlagContext';
import IntegrationsAPI from './IntegrationsAPI';
import PropertyInfo from './PropertyInfo';
import { PageContainer, Alert, Toaster } from '@knockrentals/knock-react';
import {
  Button,
  FacebookIntegrationModal,
  EngrainIntegrationModal,
} from './components';
import { IntegrationVendorNames } from './constants';
import classnames from 'classnames';

import './_IntegrationsPage.scss';
import { getCallIntelData, getCompanyId } from '../Features/CallIntelPage/CallIntelApi/callIntelApi';

class IntegrationsPage extends Component {
  state = {
    isLoading: true,
    properties: [],
    selectedPropertyId: null,
    integrationError: '',
    integrationErrorPropertyId: null,
    facebookIntegrationError: '',
    facebookIntegrationWarningPropertyIds: [],
    showFacebookIntegrationModal: false,
    showEngrainIntegrationModal: false,
  };

  static contextType = FeatureFlagContext;
  
  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    this.setState({ isLoading: true });

    const { company_properties } = await IntegrationsAPI.getProperties();

    const sortedProperties = company_properties.sort((propertyA, propertyB) => {
      return propertyA.location.name > propertyB.location.name ? 1 : -1;
    });

    const selectedPropertyId =
      this.state.selectedPropertyId ||
      (sortedProperties && sortedProperties.length
        ? sortedProperties[0].community_id
        : 'all');

    const callIntelProperties = await getCallIntelData(getCompanyId());
    const callIntelStandAloneMap = callIntelProperties.reduce(
      (standAloneMap, property) => {
        standAloneMap[property.property_id] = property.is_standalone;
        return standAloneMap;
      },
      {}
    );
    
    this.setState({
      isLoading: false,
      properties: sortedProperties.map((property) => ({
        ...property,
        isCallIntelStandAlone: callIntelStandAloneMap[property.property_id],
      })),
      facebookPropertyMapping:
        this.getFacebookPropertyMapping(sortedProperties),
      facebookIntegrationError:
        this.getFacebookIntegrationError(company_properties),
      facebookIntegrationWarningPropertyIds:
        this.getFacebookIntegrationWarningPropertyIds(company_properties),
      engrainPropertyMapping: this.getEngrainPropertyMapping(sortedProperties),
      selectedPropertyId,
    });
  };

  render() {
    const {
      facebookIntegrationError,
      showFacebookIntegrationModal,
      facebookPropertyMapping,
      showEngrainIntegrationModal,
      engrainPropertyMapping,
      selectedPropertyId,
      properties,
    } = this.state;

    return (
      <PageContainer isLoading={this.state.isLoading}>
        <h1>Properties</h1>

        <div className="knock-react-flex knock-react-flex-row">
          <div>
            {facebookIntegrationError && this.renderFacebookIntegrationError()}
            <div>Property</div>
            <select
              value={selectedPropertyId}
              onChange={this.onPropertyChanged.bind(this)}
            >
              <option value={'all'}>All</option>
              {this.renderPropertyOptions()}
            </select>
            <div>
              <Button
                className="edit-facebook-button"
                onClick={this.toggleFacebookIntegrationModal}
                iconType="pencil"
              >
                Edit Facebook integration
              </Button>

              {this.context.isToursSiteMapEnabled && (
                <Button
                  className="edit-engrain-button"
                  onClick={this.toggleEngrainIntegrationModal}
                  iconType="pencil"
                >
                  Edit Engrain Integration
                </Button>
              )}
            </div>
          </div>
        </div>

        <FacebookIntegrationModal
          show={showFacebookIntegrationModal}
          onHide={this.toggleFacebookIntegrationModal}
          reloadData={this.loadData}
          propertyMapping={facebookPropertyMapping}
          properties={properties}
        />

        <EngrainIntegrationModal
          show={showEngrainIntegrationModal}
          onHide={this.toggleEngrainIntegrationModal}
          reloadData={this.loadData}
          propertyMapping={engrainPropertyMapping}
          properties={properties}
        />

        {this.renderProperties()}
      </PageContainer>
    );
  }

  // If any property's Facebook integration is found to have has_error set, return an error message
  getFacebookIntegrationError = (company_properties) => {
    for (const property of company_properties) {
      const { secondary_integrations } = property;

      if (secondary_integrations && secondary_integrations.length) {
        for (const integration of secondary_integrations) {
          const { has_error, vendor } = integration;
          if (has_error && vendor === 'Facebook') {
            return `There appears to be an issue with your Facebook integrations.
            This is typically due to a Facebook user changing her/his password.
            Please click the Edit Facebook Integration button to fix the integration.`;
          }
        }
      }
    }
    return this.state.facebookIntegrationError;
  };

  // Note that not all properties will exist in the mapping from fetching Facebook pages.
  // we need a list of all properties to use in Edit Facebook alongside the Facebook page response
  // so this is what this accomplishes
  getFacebookPropertyMapping = (company_properties) => {
    return company_properties
      .map((property) => {
        const {
          community_id,
          location: { name: community_name },
          secondary_integrations,
          property_id,
        } = property;

        const mappedProperty = {
          community_id,
          community_name,
          property_id,
        };

        if (secondary_integrations && secondary_integrations.length) {
          const facebookIntegration = secondary_integrations.find(
            ({ vendor }) => vendor === IntegrationVendorNames.FACEBOOK
          );

          if (facebookIntegration) {
            const { credential_id, facebook_page_id } = facebookIntegration;
            mappedProperty.credential_id = credential_id;
            mappedProperty.facebook_page_id = facebook_page_id;
          }
        }

        return mappedProperty;
      })
      .filter((property) => !!property.facebook_page_id);
  };

  renderFacebookIntegrationError = () => {
    const classNames = classnames([
      'knock-react-alert',
      'knock-react-alert-error',
      'property-integration-error',
    ]);
    const error = this.state.facebookIntegrationError;
    const errorMessage =
      error &&
      error.split('\n').reduce((message, line) => {
        message.push(
          <Fragment>
            {line && line.trim()}
            <br />
          </Fragment>
        );
        return message;
      }, []);

    return (
      <Fragment>
        <div className={classNames}>{errorMessage}</div>
        <br />
      </Fragment>
    );
  };

  // Get all propertyIds for properties that have Facebook Integration warnings
  getFacebookIntegrationWarningPropertyIds = (company_properties) => {
    const propertyIdsWithNoFacebookIntegration = company_properties.reduce(
      (propertyIdList, property) => {
        const { property_id, secondary_integrations } = property;

        if (!secondary_integrations || !secondary_integrations.length) {
          propertyIdList.push(property_id);
          return propertyIdList;
        }

        const facebookIntegration = secondary_integrations.find(
          (integration) =>
            integration.vendor === IntegrationVendorNames.FACEBOOK
        );
        if (!facebookIntegration) {
          propertyIdList.push(property_id);
          return propertyIdList;
        }

        return propertyIdList;
      },
      []
    );

    const totalProperties = company_properties.length;
    const propertiesWithNoIntegrationsCount =
      propertyIdsWithNoFacebookIntegration.length;

    // If some, but not all, properties are integrated with Facebook:
    if (
      propertiesWithNoIntegrationsCount > 0 &&
      propertiesWithNoIntegrationsCount < totalProperties
    ) {
      return propertyIdsWithNoFacebookIntegration;
    }

    return [];
  };

  // Return a warning message if a property has a misconfigured Facebook integration
  getFacebookIntegrationWarning = (property_id) => {
    const { facebookIntegrationWarningPropertyIds } = this.state;
    let warningMessage = '';

    if (
      facebookIntegrationWarningPropertyIds &&
      facebookIntegrationWarningPropertyIds.includes(property_id)
    ) {
      warningMessage =
        `We noticed that you are using the Facebook integration, but it appears this community is not configured to use this integration.
      Please click the Edit Facebook Integration button and re-add this community to the integration`.trim();
    }
    return warningMessage;
  };

  // Structure the property data a little nicer for the EngrainModal usage
  getEngrainPropertyMapping = (company_properties) => {
    return company_properties.map((property) => {
      const {
        location: { name: community_name },
        secondary_integrations,
        property_id,
      } = property;

      const mappedProperty = {
        community_name,
        property_id,
      };

      if (secondary_integrations && secondary_integrations.length) {
        const engrainIntegration = secondary_integrations.find(
          ({ vendor }) => vendor === IntegrationVendorNames.ENGRAIN
        );

        if (engrainIntegration) {
          const { credential_id, engrain_unitmap_id } = engrainIntegration;
          mappedProperty.credential_id = credential_id;
          mappedProperty.engrain_unitmap_id = engrain_unitmap_id;
        }
      }
      return mappedProperty;
    });
  };

  onPropertyChanged(event) {
    this.setState({ selectedPropertyId: event.target.value });
  }

  renderPropertyOptions() {
    return this.state.properties.map((property) => {
      return (
        <option
          key={'property-' + property.community_id}
          value={property.community_id}
        >
          {property.location.name}
        </option>
      );
    });
  }

  toggleFacebookIntegrationModal = () => {
    this.setState({
      showFacebookIntegrationModal: !this.state.showFacebookIntegrationModal,
    });
  };

  toggleEngrainIntegrationModal = () => {
    this.setState({
      showEngrainIntegrationModal: !this.state.showEngrainIntegrationModal,
    });
  };

  renderProperties() {
    const { properties, selectedPropertyId } = this.state;

    if (properties.length === 0) {
      return <Alert>No properties to display.</Alert>;
    }

    if (selectedPropertyId === 'all') {
      return properties.map((property) => {
        return this.renderProperty(property);
      });
    }

    return this.renderProperty(
      properties.find(
        (property) => property.community_id === this.state.selectedPropertyId
      )
    );
  }

  renderProperty(property) {
    const { integrationError, integrationErrorPropertyId } = this.state;
    const { property_id } = property;
    const errorMessage =
      property_id === integrationErrorPropertyId ? integrationError : '';
    const facebookIntegrationWarningMessage =
      this.getFacebookIntegrationWarning(property_id);

    return (
      <PropertyInfo
        key={property.community_id}
        property={property}
        integrationErrorMessage={errorMessage}
        secondaryIntegrationWarningMessage={facebookIntegrationWarningMessage}
        onPropertyChanged={this.onPropertyUpdated.bind(this)}
        onIntegrationUpdated={this.onIntegrationUpdated.bind(this)}
        onStartAddIntegration={this.onStartAddIntegration.bind(this)}
        onEndAddIntegration={this.onEndAddIntegration.bind(this)}
      />
    );
  }

  onPropertyUpdated(updatedProperty) {
    IntegrationsAPI.updateProperty(updatedProperty.property_id, updatedProperty)
      .then(() => {
        const updatedProperties = this.state.properties.slice();
        const updatedPropertyIndex = updatedProperties.findIndex(
          (property) => property.community_id === updatedProperty.community_id
        );

        updatedProperties[updatedPropertyIndex] = updatedProperty;

        this.setState({
          properties: updatedProperties,
        });

        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      })
      .catch(() => {
        Toaster.showToast(
          'Error saving property!',
          2000,
          Toaster.ToastClasses.error
        );
      });
  }

  onIntegrationUpdated() {
    this.loadData();
  }

  onStartAddIntegration() {
    this.setState({ isLoading: true });
  }

  onEndAddIntegration(propertyId, errorMessage) {
    this.setState({
      isLoading: false,
      integrationError: errorMessage,
      integrationErrorPropertyId: propertyId,
    });
  }
}

export default IntegrationsPage;
