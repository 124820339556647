import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import App from './App';
import ErrorBoundary from './ErrorBoundary';
import UnifiedLoginAuthProvider from './Context/UnifiedLoginProvider';
import { AppContextProvider } from './Context/AppContext';
import { FeatureFlagProvider } from './Context/FeatureFlagContext';

export const history = createBrowserHistory({});

ReactDOM.render(
  <Router history={history}>
    <UnifiedLoginAuthProvider>
      <ErrorBoundary>
        <AppContextProvider>
          <FeatureFlagProvider>
            <App />
          </FeatureFlagProvider>
        </AppContextProvider>
      </ErrorBoundary>
    </UnifiedLoginAuthProvider>
  </Router>,
  document.getElementById('root')
);
