import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Box,
  CardMedia,
  makeStyles,
  Text,
} from '@knockrentals/knock-shared-web';
import { BOX_SHADOW, LINEAR_GRADIENT, MEDIUM_GREY } from '../../utils';

export const FPO = 'FPO';
export const HEADER_PREVIEW_ARIA_LABEL = 'brand content header preview';

const useStyles = makeStyles({
  logoImage: {
    height: '58px',
    width: 'auto',
    objectFit: 'contain',
  },
  logoFpo: {
    backgroundColor: MEDIUM_GREY,
    borderRadius: '4px',
    border: `1px solid ${MEDIUM_GREY}`,
    height: '58px',
    width: '102px',
  },
  linearGradient: {
    backgroundImage: LINEAR_GRADIENT.IMAGE,
    backgroundSize: LINEAR_GRADIENT.SIZE,
  },
  preview: {
    boxShadow: BOX_SHADOW,
  },
  propertyNameText: ({ contrastingTextColor }) => ({
    fontSize: '24px',
    lineHeight: '58px',
    color: contrastingTextColor,
  }),
});

const BrandHeaderPreview = ({
  alignment,
  brandColor,
  contrastingTextColor,
  isBatchUpdate,
  isBrandColorMixed,
  logoUrl,
  propertyName,
}) => {
  const classes = useStyles({ contrastingTextColor });
  const logoAltText = `${propertyName} logo`;

  const renderLogo = () => (
    <CardMedia
      alt={logoAltText}
      className={classes.logoImage}
      component="img"
      src={logoUrl}
      title={logoAltText}
    />
  );

  const renderLogoAlternative = () => {
    if (alignment) {
      return isBatchUpdate ? (
        <Box
          aria-label={FPO}
          className={classnames(
            classes.logoFpo,
            !isBrandColorMixed && classes.linearGradient
          )}
        />
      ) : (
        <Text variant="h6" className={classes.propertyNameText}>
          {propertyName}
        </Text>
      );
    }
  };

  const getBrandColor = () => {
    if (!isBrandColorMixed) {
      return brandColor;
    }
  };

  return (
    <Box
      aria-label={HEADER_PREVIEW_ARIA_LABEL}
      align={alignment}
      bgcolor={getBrandColor()}
      className={isBrandColorMixed ? classes.linearGradient : classes.preview}
      height={58}
      py={1.25}
      px={2}
    >
      {logoUrl && alignment ? renderLogo() : renderLogoAlternative()}
    </Box>
  );
};

BrandHeaderPreview.propTypes = {
  alignment: PropTypes.string,
  brandColor: PropTypes.string,
  contrastingTextColor: PropTypes.string,
  isBatchUpdate: PropTypes.bool,
  isBrandColorMixed: PropTypes.bool,
  logoUrl: PropTypes.string,
  propertyName: PropTypes.string,
};

export default BrandHeaderPreview;
