import React, { useState, useEffect } from "react";

import CommunityService from "../../Services/CommunityService";
import PropertiesAPI from '../Properties/PropertiesAPI';
import { PageContainer, Toaster, Alert } from '@knockrentals/knock-react';
import PropertyToursSettings from "./PropertyToursSettings";

import './Tours.scss';

const ALL = 'all';

const ToursPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState(null);
  const [selectedPropertyId, setSelectedPropertyId] = useState(ALL);

  const loadProperties = async () => {
    try {
      const propertiesResponse = await PropertiesAPI.getCommunityInfo();
      const { properties: responseProperties = [] } = propertiesResponse || {};
      const properties = responseProperties.map(property => ({
        ...property,
        data: CommunityService.enforceCommunityIntegrity(property.data),
      }));
      setProperties(properties || []);

    } catch (e) {
      setProperties([]);
      setIsLoading(false);
      Toaster.showToast('Error retrieving properties', 2000, Toaster.ToastClasses.error);
    }
  };

  useEffect(() => {
    properties ? setIsLoading(false) : loadProperties();
  }, [properties]);

  const renderPropertyOptions = () => {
    if (!properties) {
      return null;
    }
    return properties.map(property => {
      return (
        <option key={'doorwaySettings-' + property.id} value={property.id}>
          {property.data.location.name}
        </option>
      );
    });
  };

  const renderProperties = () => {
    if (!properties || properties.length === 0) {
      return (<Alert>No properties to display.</Alert>);
    }

    if (selectedPropertyId === ALL) {
      return properties.map((property) => <PropertyToursSettings
        key={'property-tours-settings-' + property.id}
        property={property}
      />
      );
    }

    const selectedProperty = properties.find(
      (property) => property.data.property_id === selectedPropertyId
    );
    return <PropertyToursSettings
      property={selectedProperty}
    />;
  };

  const onSelectedPropertyChanged = (e) => {
    const selectedValue = e.target.value === ALL ? e.target.value : Number(e.target.value);
    setSelectedPropertyId(selectedValue);
  };

  return (
    <PageContainer className="doorway-page-container" isLoading={isLoading}>
      <h1>Knock Tours</h1>

      <div className="knock-react-flex knock-react-flex-row">
        <div>
          <div>Property</div>
          <select
            value={selectedPropertyId}
            onChange={onSelectedPropertyChanged}
          >
            <option value={ALL}>All</option>
            {renderPropertyOptions()}
          </select>
        </div>
      </div>
      {renderProperties()}
    </PageContainer>
  );
};

export default ToursPage;
