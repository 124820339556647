import { AuthenticationService } from '@knockrentals/knock-react';
import { BASE_API_URI, DEPLOYMENT_VERSION, ENVIRONMENT } from '../config';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const clientToken = 'pub7f2ffac947e781629abc8e3740087915';
const dataDogSite = 'datadoghq.com';
const service = 'knock-admin-web';

export function dataDogInit() {
  datadogLogs.init({
    clientToken: clientToken,
    env: `${ENVIRONMENT || 'development'}`,
    forwardErrorsToLogs: true,
    site: dataDogSite,
    service: service,
    sessionSampleRate: 20,
    version: `${DEPLOYMENT_VERSION || '1.0.0'}`,
  });

  datadogRum.init({
    // allowedTracingUrls: [(url) => url.startsWith(BASE_API_URI)],
    applicationId: '36ed6286-5317-4cda-94c3-e3d9757a35cd',
    clientToken: clientToken,
    site: dataDogSite,
    service: service,
    env: `${ENVIRONMENT || 'development'}`,
    version: `${DEPLOYMENT_VERSION || '1.0.0'}`,
    replaySampleRate: 100, // deprecated in future version of @datadog/browser-rum
    sampleRate: 20, // deprecated in future version of @datadog/browser-rum
    sessionSampleRate: 20,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

export function setDataDogUser(companyName, companyId, userId) {
  const userData = {
    companyId: companyId,
    companyName: companyName,
    internalLogin: AuthenticationService._internalMode || false,
    userId: userId,
    userLevel: AuthenticationService.getRole(),
  };

  datadogRum.setUser(userData);
}
