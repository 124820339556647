import React from 'react';
import { AuthProvider } from 'react-oidc-context';
import {
  REALPAGE_LOGIN_URL,
  AUTH_CLIENT_ID,
  AUTH_REDIRECT_URL,
  UNIFIED_SCOPE,
} from '../config';

const UnifiedLoginAuthProvider = ({ children }) => {
  const onSigninCallback = () => {
    window.history.replaceState({}, '', window.location.pathname);
  };

  const oidcConfig = {
    authority: REALPAGE_LOGIN_URL,
    client_id: AUTH_CLIENT_ID,
    onSigninCallback,
    redirect_uri: AUTH_REDIRECT_URL,
    response_mode: 'query',
    response_type: 'code',
    scope: UNIFIED_SCOPE,
  };

  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
};

export default UnifiedLoginAuthProvider;
