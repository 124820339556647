import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text, TextField, makeStyles } from '@knockrentals/knock-shared-web';
import TimingLabel from './TimingLabel';

export const DAYS_TO_DELAY_LABEL_TEXT = 'Days after';

const useStyles = makeStyles({
  input: {
    verticalAlign: 'baseline',
    width: '80px',
    '& label.MuiInputLabel-formControl': {
      left: '5px',
      position: 'absolute',
    },
  },
  daysToDelayText: { paddingRight: '5px' },
});

const DaysToDelay = ({
  actionTrigger,
  clearErrorMessage,
  daysToDelay,
  handleDaysToDelayOnChange,
  hasError,
  isFormMode,
  isDaysToDelayDisabled,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classnames(isDaysToDelayDisabled && 'disable-days-to-delay')}
    >
      {isFormMode ? (
        <TextField
          className={classes.input}
          component="span"
          error={hasError}
          inputProps={{ style: { textAlign: 'center' } }}
          label="Number"
          margin="none"
          onChange={handleDaysToDelayOnChange}
          onFocus={clearErrorMessage}
          size="small"
          placeholder="#"
          value={daysToDelay || ''}
          variant="filled"
        />
      ) : (
        <Text
          className={classes.daysToDelayText}
          component="span"
          variant="subtitle1"
        >
          {daysToDelay}
        </Text>
      )}

      <TimingLabel
        actionTrigger={actionTrigger}
        hasError={hasError}
        labelText={DAYS_TO_DELAY_LABEL_TEXT}
      />
    </div>
  );
};

DaysToDelay.propTypes = {
  actionTrigger: PropTypes.string,
  clearErrorMessage: PropTypes.func,
  daysToDelay: PropTypes.number,
  handleDaysToDelayOnChange: PropTypes.func,
  hasError: PropTypes.bool,
  isFormMode: PropTypes.bool,
  isDaysToDelayDisabled: PropTypes.bool,
};

export default DaysToDelay;
