import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@knockrentals/knock-shared-web';
import DrawerActions from './DrawerActions';
import { MODES, STATUS } from '../constants';

export const ICON_BUTTON_TEXT = {
  [STATUS.ARCHIVED]: 'Delete Archive',
  [STATUS.DRAFT]: 'Delete Draft',
  [STATUS.LIVE]: 'Archive Template',
};

const TemplateActionButtons = ({
  handleDuplicateOnClick,
  handleEditOnClick,
  handleIconButtonOnClick,
  handlePreviewOnClick,
  handleSaveOnClick,
  isOriginCreateTemplate,
  isPreviewButtonDisabled,
  mode,
  status,
}) => {
  const renderFormActionButtons = () => (
    <Button
      className="action-button"
      color="primary"
      disabled={isPreviewButtonDisabled}
      onClick={handlePreviewOnClick}
    >
      Preview
    </Button>
  );

  const renderDisplayActionButtons = () =>
    status === STATUS.DRAFT && !isOriginCreateTemplate ? (
      <React.Fragment>
        <Button
          className="action-button"
          color="primary"
          onClick={handleEditOnClick}
        >
          Edit
        </Button>

        <Button
          className="action-button"
          color="primary"
          onClick={handleSaveOnClick}
        >
          Make Active
        </Button>
      </React.Fragment>
    ) : (
      <Button
        className="action-button"
        color="primary"
        onClick={handleDuplicateOnClick}
      >
        Duplicate and Edit
      </Button>
    );

  return (
    <DrawerActions
      handleIconButtonOnClick={handleIconButtonOnClick}
      iconButtonText={ICON_BUTTON_TEXT[status]}
      isIconButtonVisible={mode === MODES.DISPLAY && !isOriginCreateTemplate}
      status={status}
    >
      {mode === MODES.DISPLAY
        ? renderDisplayActionButtons()
        : renderFormActionButtons()}
    </DrawerActions>
  );
};

TemplateActionButtons.propTypes = {
  handleDuplicateOnClick: PropTypes.func,
  handleEditOnClick: PropTypes.func,
  handleIconButtonOnClick: PropTypes.func,
  handlePreviewOnClick: PropTypes.func,
  handleSaveOnClick: PropTypes.func,
  isOriginCreateTemplate: PropTypes.bool,
  isPreviewButtonDisabled: PropTypes.bool,
  mode: PropTypes.string,
  status: PropTypes.string,
};

export default TemplateActionButtons;
