import React, { useEffect, useState } from 'react';

import { Toaster } from '@knockrentals/knock-react';
import {
  LoadingOverlay,
  makeStyles,
  Box,
  Alert,
} from '@knockrentals/knock-shared-web';
import * as VirtualAgentAPI from '../VirtualAgentAPI';
import PropertyResponses from './Components/PropertyResponses';

const useStyles = makeStyles({
  bannerContainer: {
    width: '700px',
  },
});

const VirtualAgentResidentResponses = ({
  properties,
  onConfirmation,
  currentTab,
}) => {
  const [intents, setIntents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasAnyChannelEnabled, setHasAnyChannelEnabled] = useState(false);
  const classes = useStyles();
  const getIntents = async () => {
    try {
      const intentsResponse = await VirtualAgentAPI.getResidentIntentsV2();
      const sortedIntents =
        intentsResponse.intents?.sort((a, b) =>
          a.display_name.localeCompare(b.display_name),
        ) || [];

      setIntents(sortedIntents);
    } catch (e) {
      Toaster.showToast(
        'Error retrieving intents',
        2000,
        Toaster.ToastClasses.error,
      );
    }
  };

  const checkForEnabledChannel = () => {
    const hasEnabled = properties.some(
      (property) =>
        property.agent.resident_virtual_agent_sms ||
        property.agent.resident_virtual_agent_voice,
    );
    setHasAnyChannelEnabled(hasEnabled);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getIntents();

      setIsLoading(false);
    };

    checkForEnabledChannel();
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <LoadingOverlay
        open={isLoading}
        style={{ position: 'absolute', opacity: '0.5' }}
      />
      {properties.length && !hasAnyChannelEnabled && (
        <Box className={classes.bannerContainer}>
          <Alert severity="warning" className={classes.alert}>
            You do not have Resident AI enabled. <br />
            Contact your Knock Support Representative
          </Alert>
        </Box>
      )}
      {properties.length ? (
        properties.map((property) => (
          <PropertyResponses
            key={property.id}
            property={property}
            intents={intents}
            onConfirmation={onConfirmation}
            getResponses={VirtualAgentAPI.getResidentResponsesV2}
            saveResponses={VirtualAgentAPI.saveResidentResponsesV2}
            currentTab={currentTab}
          />
        ))
      ) : (
        <Alert>No properties to display.</Alert>
      )}
    </React.Fragment>
  );
};

export default VirtualAgentResidentResponses;
