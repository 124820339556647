import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Text,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import { AccordionActions } from '@material-ui/core';
import { DeleteSvgIcon } from '../../../../../Components/Icons';

const useStyles = makeStyles((theme) => ({
  actionDeleteBtn: {
    color: theme.palette.error.dark,
  },
}));

const CampaignActionAccordion = ({
  accordionSummaryDescription,
  accordionSummaryTitle,
  children,
  handleDeleteAction,
  hasValidationError,
  isDefaultExpanded,
  isDeleteActionVisible,
}) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(isDefaultExpanded);

  useEffect(() => {
    if (hasValidationError) {
      setIsExpanded(true);
    }
  }, [hasValidationError]);

  const handleOnChange = () => {
    if (!hasValidationError || !isExpanded) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <Accordion
      defaultExpanded={isDefaultExpanded}
      expanded={isExpanded}
      onChange={handleOnChange}
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
    >
      <AccordionSummary>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Text variant="subtitle1">{accordionSummaryTitle}</Text>

          {accordionSummaryDescription && (
            <Text variant="body2" color="secondary">
              {accordionSummaryDescription}
            </Text>
          )}
        </Box>
      </AccordionSummary>

      <AccordionDetails aria-hidden={!hasValidationError && !isExpanded}>
        {children}
      </AccordionDetails>

      <Divider variant="middle" />

      {isDeleteActionVisible && (
        <AccordionActions>
          <Button
            className={classes.actionDeleteBtn}
            color="primary"
            startIcon={<DeleteSvgIcon fontSize="small" />}
            variant="text"
            onClick={handleDeleteAction}
          >
            Delete Action
          </Button>
        </AccordionActions>
      )}
    </Accordion>
  );
};

CampaignActionAccordion.propTypes = {
  accordionSummaryDescription: PropTypes.string,
  accordionSummaryTitle: PropTypes.string,
  children: PropTypes.node,
  handleDeleteAction: PropTypes.func,
  hasValidationError: PropTypes.bool,
  isDefaultExpanded: PropTypes.bool,
  isDeleteActionVisible: PropTypes.bool,
};

export default CampaignActionAccordion;
