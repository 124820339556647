import { useEffect, useState, useRef } from 'react';
import { dripCampaignsApi } from '../../DripCampaignsApi';
import { MODES, STATUS } from '../../constants';
import { NotificationService } from '../../../../../Components/Notifications';

const useCampaign = ({
  closeAllDrawers,
  reloadCampaignsViewData,
  selectedCampaign,
  setErrorMessage,
}) => {
  const initialCampaignState = {
    ...selectedCampaign,
    actions: [{}],
  };
  const [campaign, setCampaign] = useState(initialCampaignState);
  const [isEditing, setIsEditing] = useState(false);

  const campaignRef = useRef(initialCampaignState);
  const duplicatedCampaignRef = useRef({});

  const {
    actions,
    associatedProperties = [],
    recipient,
    campaignName,
    campaignTrigger,
    status,
  } = campaign;

  const { campaignId } = campaign;
  const mode =
    !campaignId || (isEditing && status !== STATUS.LIVE)
      ? MODES.FORM
      : MODES.DISPLAY;

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const campaign = await dripCampaignsApi.getCampaignById(campaignId);

        campaignRef.current = campaign;

        if (!campaign.actions || !campaign.actions.length) {
          campaign.actions = [];
        }
        setCampaign(campaign);
      } catch (error) {
        setErrorMessage(error.message);
      }
    };

    if (campaignId) {
      fetchCampaign();
    }
  }, [campaignId, setErrorMessage]);

  const archiveCampaign = async () => {
    try {
      await dripCampaignsApi.archiveCampaign(campaignId);

      reloadCampaignsViewData();
      closeAllDrawers();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const hasActionsChanged = () => {
    const originalActions = campaignRef.current.actions;
    const actions = campaign.actions;
    if (actions.length !== originalActions.length) {
      return true;
    }

    return actions.some((action, index) => {
      const originalAction = originalActions[index];
      return (
        action.daysToDelay !== originalAction.daysToDelay ||
        action.templateId !== originalAction.templateId
      );
    });
  };

  const getUpdatedProperties = () => {
    const updatedPropertiesMap = campaign.associatedProperties.reduce(
      (propertyMap, property) => {
        const { propertyId } = property;
        propertyMap[propertyId] = { propertyId };
        return propertyMap;
      },
      {}
    );

    campaignRef.current.associatedProperties.forEach((property) => {
      const { propertyId } = property;
      if (!updatedPropertiesMap[propertyId]) {
        updatedPropertiesMap[propertyId] = { propertyId, isDeleted: true };
        return;
      }
      delete updatedPropertiesMap[propertyId];
    });

    return Object.values(updatedPropertiesMap);
  };

  const getCampaignChanges = (status) => {
    const fields = ['campaignName', 'campaignTrigger'];

    const campaignUpdates = fields.reduce((updates, field) => {
      if (campaign[field] !== campaignRef.current[field]) {
        updates[field] = campaign[field];
      }
      return updates;
    }, {});

    if (status !== campaignRef.current.status) {
      campaignUpdates.status = status;
    }

    const updatedAssociatedProperties = getUpdatedProperties();

    if (updatedAssociatedProperties.length) {
      campaignUpdates.associatedProperties = updatedAssociatedProperties;
    }

    if (hasActionsChanged()) {
      const currentSavedActions = campaign.actions.reduce(
        (savedActions, action) => {
          const { actionId } = action;
          if (actionId) {
            savedActions[actionId] = action;
          }
          return savedActions;
        },
        {}
      );

      const updatedActions = [...campaign.actions];

      campaignRef.current.actions.forEach((action) => {
        const { actionId, isDeleted = true } = action;
        if (!currentSavedActions[actionId]) {
          updatedActions.push({ actionId, isDeleted });
        }
      });

      campaignUpdates.actions = updatedActions;
    }

    return campaignUpdates;
  };

  const createNewCampaign = (status) => {
    return dripCampaignsApi.createCampaign({
      ...campaign,
      status,
    });
  };

  const updateEditedDraftCampaign = (status) => {
    const editedCampaignData = getCampaignChanges(status);

    const payload = {
      ...editedCampaignData,
    };

    return dripCampaignsApi.updateDripCampaign(campaign.campaignId, payload);
  };

  const saveCampaign = async (status) => {
    try {
      campaignId
        ? await updateEditedDraftCampaign(status)
        : await createNewCampaign(status);

      reloadCampaignsViewData();
      closeAllDrawers();
      NotificationService.notify('Saved!');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const setDraftCampaignStatusLive = async () => {
    try {
      await dripCampaignsApi.updateDripCampaign(campaign.campaignId, {
        status: STATUS.LIVE,
      });

      reloadCampaignsViewData();
      closeAllDrawers();
      NotificationService.notify('Campaign is Live!');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const updateLiveEditedProperties = async () => {
    try {
      const associatedProperties = getUpdatedProperties();
      await dripCampaignsApi.updateDripCampaign(campaign.campaignId, {
        associatedProperties,
      });

      reloadCampaignsViewData();
      NotificationService.notify('Properties updated!');
    } catch (error) {
      setErrorMessage(error.message);
    }
    setIsEditing(false);
  };

  const handleDuplicate = () => {
    duplicatedCampaignRef.current = campaignRef.current;

    setCampaign((prevState) => {
      const {
        actions = [],
        campaignTrigger,
        recipient = '',
      } = prevState;

      const duplicateCampaign = {
        actions: actions.map((action) => {
          const duplicateAction = { ...action };
          delete duplicateAction.actionId;
          return duplicateAction;
        }),
        campaignTrigger,
        recipient,
      };

      campaignRef.current = duplicateCampaign;

      return duplicateCampaign;
    });
  };

  const updateCampaign = (campaignUpdate) => {
    setCampaign((prevCampaign) => ({
      ...prevCampaign,
      ...campaignUpdate,
    }));
  };

  const removeAssociatedProperty = (removedPropertyId) => {
    setCampaign((prevState) => {
      const currentProperties = [...prevState.associatedProperties];

      const updatedAssociatedProperties = currentProperties.filter(
        (property) => property.propertyId !== removedPropertyId
      );

      return {
        ...prevState,
        associatedProperties: updatedAssociatedProperties,
      };
    });
  };

  const hasAssociatedPropertiesChanged = () => {
    const { associatedProperties = [] } = campaign;
    const originalAssociatedProperties =
      campaignRef.current.associatedProperties || [];

    if (associatedProperties.length !== originalAssociatedProperties.length) {
      return true;
    }

    return associatedProperties.some((property, index) => {
      const originalProperty = originalAssociatedProperties[index];
      return (
        !originalProperty || property.propertyId !== originalProperty.propertyId
      );
    });
  };

  const addAction = () => {
    setCampaign((prevCampaign) => {
      const { actions } = prevCampaign;
      return {
        ...prevCampaign,
        actions: [...actions, {}],
      };
    });
  };

  const deleteAction = (index) => {
    setCampaign((prevCampaign) => {
      const updatedActions = prevCampaign.actions.filter(
        (_action, idx) => idx !== index
      );

      return {
        ...prevCampaign,
        actions: updatedActions,
      };
    });
  };

  const updateAction = (actionIndex, actionUpdate) => {
    setCampaign((prevCampaign) => {
      const updatedActions = [...prevCampaign.actions];
      updatedActions[actionIndex] = {
        ...updatedActions[actionIndex],
        ...actionUpdate,
      };

      return {
        ...prevCampaign,
        actions: updatedActions,
      };
    });
  };

  const getHasCampaignChanged = () => {
    if (mode === MODES.DISPLAY) {
      return false;
    }

    const fields = ['campaignName', 'campaignTrigger', 'status'];

    return (
      fields.some(
        (field) =>
          (campaign[field] || '') !== (campaignRef.current[field] || '')
      ) ||
      hasAssociatedPropertiesChanged() ||
      hasActionsChanged()
    );
  };
  const hasCampaignChanged = getHasCampaignChanged();

  const onFormGoBack = (isOriginCreateCampaign, closeCampaignDrawer) => {
    if (isEditing) {
      setIsEditing(false);
      if (hasCampaignChanged) {
        setCampaign(campaignRef.current);
      }
      return;
    }

    const isDuplicatedCampaign = Boolean(
      duplicatedCampaignRef.current.campaignId
    );

    if (isDuplicatedCampaign && !isOriginCreateCampaign) {
      const duplicatedCampaign = duplicatedCampaignRef.current;
      duplicatedCampaignRef.current = {};
      setCampaign(duplicatedCampaign);
      return;
    }

    closeCampaignDrawer && closeCampaignDrawer();
  };

  const deleteCampaign = async () => {
    try {
      await dripCampaignsApi.deleteCampaign(campaignId);
      reloadCampaignsViewData();
      closeAllDrawers();
      NotificationService.notify('Campaign Deleted');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return {
    actions,
    addAction,
    archiveCampaign,
    associatedProperties,
    campaignId,
    campaignName,
    campaignTrigger,
    deleteAction,
    deleteCampaign,
    isEditing,
    handleDuplicate,
    hasCampaignChanged,
    mode,
    onFormGoBack,
    recipient,
    removeAssociatedProperty,
    saveCampaign,
    setDraftCampaignStatusLive,
    setIsEditing,
    status,
    updateAction,
    updateCampaign,
    updateLiveEditedProperties,
  };
};

export default useCampaign;
