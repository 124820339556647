import React from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Text,
} from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  preferencesSummary: {
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '46px',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      marginBottom: 0,
    },
  },
});

const PreferencesAccordion = ({ children, title }) => {
  const classes = useStyles();
  return (
    <Accordion>
      <AccordionSummary className={classes.preferencesSummary}>
        <Text variant="body1">{title}</Text>
      </AccordionSummary>
      <AccordionDetails>
        <div className="accordion-div">{children}</div>
      </AccordionDetails>
    </Accordion>
  );
};

PreferencesAccordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default PreferencesAccordion;
