import { useEffect, useState } from 'react';
import RegistrationAPI from '../../../Registration/RegistrationAPI';
import {
  REGISTRATION_LOADING_STATE,
  TOTAL_FAILED,
  TOTAL_REGISTERED,
  TOTAL_UNREGISTERED,
} from '../constants';

export const ERROR_MESSAGE =
  'An unexpected error has occurred. Phone registration currently not available';

export const useCompanyPhoneRegistration = () => {
  const [registrationStats, setRegistrationStats] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchCompanyPhoneRegistration = async () => {
      try {
        const registration =
          await RegistrationAPI.getCompanyPhoneRegistration();

        setRegistrationStats({
          [TOTAL_FAILED]: registration.total_failed,
          [TOTAL_REGISTERED]: registration.total_registered,
          [TOTAL_UNREGISTERED]: registration.total_unregistered,
        });
      } catch {
        setErrorMessage(ERROR_MESSAGE);
      }
    };

    if (!registrationStats) {
      fetchCompanyPhoneRegistration();
    }
  }, [registrationStats]);

  return {
    errorMessage,
    registrationStats: registrationStats || REGISTRATION_LOADING_STATE,
  };
};
