import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles, Popover } from '@knockrentals/knock-shared-web';
import ColorPickerCard from './ColorPickerCard';

const useStyles = makeStyles({
  popperButton: {
    marginLeft: '8px',
  },
});

const ColorPicker = ({ color, isIndeterminate, onConfirmColor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleOpenColorPicker = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmColor = (color) => {
    handleClose();
    onConfirmColor(color);
  };

  return (
    <React.Fragment>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <ColorPickerCard
          color={color || ''}
          isIndeterminate={isIndeterminate}
          onCancel={handleClose}
          onConfirmColor={handleConfirmColor}
        />
      </Popover>

      <Button
        className={classes.popperButton}
        onClick={handleOpenColorPicker}
        variant="text"
      >
        Manage color
      </Button>
    </React.Fragment>
  );
};

ColorPicker.propTypes = {
  color: PropTypes.string,
  isIndeterminate: PropTypes.bool,
  onConfirmColor: PropTypes.func,
};

export default ColorPicker;
