import React, { Component } from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter } from '@knockrentals/knock-react';
import { Formik } from 'formik';
import { trimValue } from '../../../Utils';

import './_addIntegrationForm.scss';

class AddResmanIntegration extends Component {
  render() {
    return (
      <Modal className="add-integration-form">
        <ModalHeader>
          <h3>Add ResMan Integration</h3>
        </ModalHeader>
        <Formik
          initialValues={{
            integration_partner_id: '',
            api_key: '',
            account_id: '',
            resman_property_id: '',
            is_import_credential: false,
          }}
          onSubmit={this.submit.bind(this)}
          validate={this.validate}
        >
          {props => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <ModalContent>
                <div className="knock-react-flex">
                  <div>
                    <strong>Property ID: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.resman_property_id}
                      name="resman_property_id"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                      type="text"
                    />
                    {!!props.errors.resman_property_id && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.database_name}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Partner Id: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.integration_partner_id}
                      name="integration_partner_id"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                      type="text"
                    />
                    {!!props.errors.integration_partner_id && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.integration_partner_id}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Account Id: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.account_id}
                      name="account_id"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                      type="text"
                    />
                    {!!props.errors.account_id && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.account_id}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Import only: </strong>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      className="knock-react-input"
                      value={props.values.is_import_credential}
                      name="is_import_credential"
                      onChange={e => props.setFieldValue("is_import_credential", e.target.checked)}
                    />
                  </div>
                </div>
              </ModalContent>
              <ModalFooter>
                <button className="knock-react-button" type="submit">
                  Save
                </button>
                <button className="knock-react-button" onClick={this.cancel.bind(this)}>
                  Cancel
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  submit(values) {
    this.props.onAddIntegration({ ...values, vendor: 'resman' });
  }

  cancel() {
    this.props.onCancelAddIntegration();
  }
}

export default AddResmanIntegration;
