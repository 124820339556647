import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ModalView from '../../ModalView';
import ChooseAction from './ChooseAction';
import IntegrationsTable from './IntegrationsTable';
import RemoveIntegrations from './RemoveIntegrations';
import IntegrationsAPI from '../../../IntegrationsAPI';
import classnames from 'classnames';

// We can use this data for tests and mocking in the future
// import * as testData from './testData';


// Enum of all view states the modal can have:
export const FacebookIntegrationModalViews = {
  DEFAULT : 'DEFAULT',
  EDIT: 'EDIT',
  REMOVE: 'REMOVE',
};

class FacebookIntegrationModal extends Component {
  static propTypes = {
    onHide: PropTypes.func,
    children: PropTypes.node,
    reloadData: PropTypes.func,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        community_id: PropTypes.string,
        location: PropTypes.shape({
          name: PropTypes.string,
        }),
        property_id: PropTypes.number,
      })
    ),
    propertyMapping: PropTypes.arrayOf(
      PropTypes.shape({
        community_id: PropTypes.string,
        community_name: PropTypes.string,
        property_id: PropTypes.number,
        credential_id: PropTypes.number,
        facebook_page_id: PropTypes.string,
      })
    ),
  };

  state = {
    error: null,
    currentView: FacebookIntegrationModalViews.DEFAULT,
    reloadOnHide: false,
    fbAccessToken: null,
    fbUserId: null,
    fbPages: null,
    fbPageMapping: null
    // fbPages: [...testData.fbPages],
    // fbPageMapping: [...testData.fbPageMapping],
  };

  handleFacebookLoginSuccess = async payload => {
    const {
      tokenDetail: { accessToken: fbAccessToken, userID: fbUserId }
    } = payload;

    const {
      facebook_payload: { data: fbPages },
      mapping: fbPageMapping
    } = await IntegrationsAPI.getFacebookPages(fbAccessToken);

    this.setState({
      currentView: FacebookIntegrationModalViews.EDIT, // change to edit view after login
      error: null,
      fbAccessToken,
      fbUserId,
      fbPages,
      fbPageMapping
    });
  };

  handleFacebookLoginError = payload => {
    this.setState({ error: payload.toString() });
  };

  handleModalHide = async () => {
    const {
      reloadData,
      onHide,
    } = this.props;

    this.setState({
      currentView: FacebookIntegrationModalViews.DEFAULT,
      error: null,
    });

    onHide();

    if (this.state.reloadOnHide) {
      await reloadData();
    }
  }

  setView = newView => {
    const viewList = Object.values(FacebookIntegrationModalViews);

    if(viewList.includes(newView)) {
      this.setState({ currentView: newView });
    } else {
      console.warn(`${newView} is not a valid FacebookIntegrationModalView`);
    }
  }

  showRemoveView = () => {
    this.setView(FacebookIntegrationModalViews.REMOVE);
  }

  setReloadOnHide = (value) => {
    this.setState({ reloadOnHide: value });
  }

  render() {
    const { propertyMapping, show } = this.props;
    const { currentView, fbPages, fbPageMapping, error, fbAccessToken } = this.state;
    let view;

    const DefaultView = (
      <ChooseAction
        onSuccess={this.handleFacebookLoginSuccess}
        onError={this.handleFacebookLoginError}
        error={error}
        showRemoveView={this.showRemoveView}
      />
    );

    // Select view to show:
    switch (currentView) {
      case FacebookIntegrationModalViews.DEFAULT:
        view = DefaultView;
        break;

      case FacebookIntegrationModalViews.EDIT:
        view = (
          <IntegrationsTable
            fbPages={fbPages}
            fbPageMapping={fbPageMapping}
            properties={this.props.properties}
            fbAccessToken={fbAccessToken}
          />
        );
        break;

      case FacebookIntegrationModalViews.REMOVE:
        view = (
          <RemoveIntegrations
            propertyMapping={propertyMapping}
            setReloadOnHide={this.setReloadOnHide}
          />
        );
        break;

      default:
        view = DefaultView;
        break;
    }

    const classNames = classnames({
      'facebook-integration-content': true,
      'max-width-500': currentView === FacebookIntegrationModalViews.DEFAULT,
    });

    return (
      <ModalView
        header='Edit Facebook Integration'
        onHide={this.handleModalHide}
        show={show}
      >
        <div className={classNames}>
          { view }
        </div>
      </ModalView>
    );
  }
}

export default FacebookIntegrationModal;
