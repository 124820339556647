import React, { Component } from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter } from '@knockrentals/knock-react';
import { Formik } from 'formik';

class TeamEditor extends Component {
  render() {
    return (
      <Modal className="user-editor-container">
        <Formik
          initialValues={{
            teamName: this.props.team ? this.props.team.leasing_team_name : '',
          }}
          validate={this.validate}
          onSubmit={this.submit.bind(this)}
        >
          {props => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <ModalHeader>
                <h2>{this.props.team ? 'Edit team' : 'Add team'}</h2>
              </ModalHeader>
              <ModalContent>
                <div className="knock-react-flex">
                  <div>
                    <strong>Team name: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.teamName}
                      name="teamName"
                      onChange={props.handleChange}
                    />
                    {!!props.errors.teamName && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.teamName}
                      </span>
                    )}
                  </div>
                </div>
              </ModalContent>
              <ModalFooter>
                <button className="knock-react-button" type="submit">
                  Save
                </button>
                <button className="knock-react-button" onClick={this.cancel.bind(this)}>
                  Cancel
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  nameChanged(event) {
    this.setState({ name: event.target.value });
  }

  submit(values) {
    const { teamName } = values;
    if (this.props.team) {
      this.props.onUpdateTeam(teamName);
    } else {
      this.props.onAddTeam(teamName);
    }
  }

  cancel(event) {
    event.preventDefault();
    this.props.onCancel();
  }

  validate(values) {
    const errors = {};

    if (!values.teamName) {
      errors.teamName = 'Required';
    }

    return errors;
  }
}

export default TeamEditor;
