import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Toaster } from '@knockrentals/knock-react';
import PropertiesAPI from '../Properties/PropertiesAPI';

export const ARIA_LABELS = {
  INVALID: 'invalid name',
  VALID: 'valid name',
  VALIDATING: 'validating name',
};

const RelayUsernameField = ({
  isValid,
  onChange,
  relayUsername,
  setIsValid,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const validateRelayUsername = useCallback(
    async (username) => {
      try {
        const { relay } = await PropertiesAPI.getRelayEmail(username);
        setIsValid(!relay);
      } catch {
        Toaster.showToast(
          'Error checking email username availability',
          2000,
          Toaster.ToastClasses.error
        );
      }
      setIsProcessing(false);
    },
    [setIsValid]
  );

  const validateUserName = useMemo(
    () => debounce(validateRelayUsername, 500),
    [validateRelayUsername]
  );

  useEffect(() => {
    return validateUserName.cancel();
  }, [validateUserName]);

  const relayUsernameChanged = (event) => {
    const relayUsername = event.target.value.toLowerCase();
    onChange(relayUsername);
    if (
      !relayUsername ||
      relayUsername.length < 3 ||
      (event.target.validity && !event.target.validity.valid)
    ) {
      setIsValid(false);
      return;
    }
    setIsProcessing(true);
    validateUserName(relayUsername);
  };
      

  return (
    <div className="knock-react-flex">
      <div className="property-info-edit-field-label">Knock Email</div>

      <div className="property-info-edit-field-input">
        <input
          value={relayUsername}
          name="knockEmail"
          pattern="[a-zA-Z0-9\-_]+"
          onChange={relayUsernameChanged}
          className="knock-react-input"
          placeholder="email"
        />

        <span className="input-append-text">
          <span className="side-margin-md">@m.knck.io</span>

          {isProcessing ? (
            <i
              aria-label={ARIA_LABELS.VALIDATING}
              className="fa fa-spin fa-cog"
            />
          ) : isValid ? (
            <i
              aria-label={ARIA_LABELS.VALID}
              className="fa fa-check-circle txt-success"
            />
          ) : (
            <i
              aria-label={ARIA_LABELS.INVALID}
              className="fa fa-exclamation-triangle txt-danger"
            />
          )}
        </span>
      </div>
    </div>
  );
};

RelayUsernameField.propTypes = {
  isValid: PropTypes.bool,
  onChange: PropTypes.func,
  relayUsername: PropTypes.string,
  setIsValid: PropTypes.func
};

export default RelayUsernameField;