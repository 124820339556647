import React from 'react';
import PropTypes from 'prop-types';

const RealPageIntegrationInfo = ({ integration: { vendor, role, pmc_id, site_id } }) => (
  <div className="knock-admin-integration-info">
    <div>
      <strong>Integration:</strong> {vendor}
      {role === 'import' ? ' (unit availability, resident import)' : ''}
    </div>
    <div>
      <strong>PMC ID:</strong> {pmc_id}
    </div>
    <div>
      <strong>Site ID:</strong> {site_id}
    </div>
  </div>
);

RealPageIntegrationInfo.propTypes = {
  integration: PropTypes.object
};

export default RealPageIntegrationInfo;
