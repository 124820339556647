import { useCallback, useEffect, useState } from 'react';

import { dripCampaignsApi } from '../../DripCampaignsApi';
import { STATUS } from '../../constants';

export const useTemplateList = ({ contentType, isActionTemplates }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState([]);

  const fetchTemplates = useCallback(async () => {
    try {
      // TODO - resolve - api is paginated but template list is not
      const { templates } = await dripCampaignsApi.getTemplates({
        page: 0,
        pageSize: 500,
        sortColumn: 'templateName',
        sortDirection: 'asc',
        contentType,
      });

      const templateList = isActionTemplates
        ? templates.filter((template) => template.status === STATUS.LIVE)
        : templates;

      setTemplates(templateList);
    } catch (e) {
      setErrorMessage(e.message);
    }

    setIsLoading(false);
  }, [isActionTemplates]);

  useEffect(() => {
    fetchTemplates();
  }, [contentType, fetchTemplates]);

  return {
    errorMessage,
    isLoading,
    templates: templates.map((template) => ({ ...template })),
    setErrorMessage,
  };
};
