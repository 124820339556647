import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormControl, FormHelperText } from '@material-ui/core';

import {
  Divider,
  Select,
  Text,
  TextField,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import { AddIconButton } from '../../../common/AddIconButton';
import { CampaignActionButtons } from '../../DrawerActionButtons';
import { DripCampaignCard } from '../../DripCampaignCard';
import { DisplayChip } from './DisplayChip';
import { ErrorDialog } from '../../../common/ErrorDialog';
import FeatureCardContent from '../../../common/FeatureCardContent';
import FeatureDrawer from '../../../common/FeatureDrawer';
import { SelectProperties } from './SelectProperties';
import { PropertyChips } from './PropertyChips';
import { Confirmation } from '../Confirmation';
import useCampaign from './useCampaign';
import { useFeatureFlaggedOptions } from './useFeatureFlaggedOptions';
import {
  getCardConfig,
  getSelectOptions,
  setPlaceHolder,
  validateCampaign,
} from './utils';
import { MODES, STATUS } from '../../constants';
import { CONFIRMATION_MODES, CONFIRMATION_MODE_FOR_STATUS } from '../constants';
import {
  CAMPAIGN_LABELS,
  CAMPAIGN_NAME,
  CAMPAIGN_TRIGGER,
  RECIPIENT,
  RECIPIENT_TYPES,
  SELECT_PROPERTY_MODES,
  TRIGGER_LABELS
} from './constants';
import './_Campaign.scss';
import { CampaignActions } from '../CampaignActions';
import ActionTemplate from './ActionTemplate/ActionTemplate';

const CAMPAIGN_DISCLAIMERS = {
  [RECIPIENT_TYPES.PROSPECT]:
    '*Once a prospect has an inbound communication, the Campaign will stop.',
};

const useStyles = makeStyles(() => ({
  campaignCardContent: {
    '& .MuiInputBase-root': {
      border: 'none',
      paddingLeft: 0,
    },
  },
  campaignNameInput: {
    width: '400px',
  },
  campaignFilledInput: {
    '& .MuiFilledInput-root:not(.Mui-disabled)': {
      backgroundColor: '#ffffff',
    },
    '& label.MuiInputLabel-formControl': {
      fontWeight: '400',
      transform: 'translate(0, 24px) scale(.85)',
      top: '5px',
      left: '12px',
    },
  },
  campaignDisclaimer: {
    margin: '2px 0',
  },
  propertiesError: {
    marginTop: '24px',
  },
  recipientSelect: {
    width: '270px',
  },
  triggerSelect: {
    width: '370px',
  },
  addPropertyDivider: {
    margin: '14px 0',
  },
}));

const Campaign = ({
  closeDrawer,
  closeParentDrawer,
  isOriginCreateCampaign,
  reloadCampaignsViewData,
  selectedCampaign,
}) => {
  const classes = useStyles();
  const [actionTemplate, setActionTemplate] = useState(null);
  const [confirmationMode, setConfirmationMode] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectPropertiesMode, setSelectPropertiesMode] = useState(null);
  const [validationErrorMessages, setValidationErrorMessages] = useState({});

  const closeAllDrawers = isOriginCreateCampaign
    ? closeParentDrawer
    : closeDrawer;

  const {
    actions,
    addAction,
    archiveCampaign,
    associatedProperties,
    campaignId,
    campaignName = '',
    campaignTrigger = '',
    deleteAction,
    handleDuplicate,
    hasCampaignChanged,
    isEditing,
    mode,
    onFormGoBack,
    recipient = '',
    removeAssociatedProperty,
    saveCampaign,
    setDraftCampaignStatusLive,
    setIsEditing,
    status,
    updateAction,
    updateCampaign,
    updateLiveEditedProperties,
    deleteCampaign,
  } = useCampaign({
    closeAllDrawers,
    reloadCampaignsViewData,
    selectedCampaign,
    setErrorMessage,
  });

  const { triggerOptions } = useFeatureFlaggedOptions({ recipient });

  const clearErrorValidationMessage = (field) => {
    setValidationErrorMessages((prevState) => ({
      ...prevState,
      [field]: '',
    }));
  };

  const handleOnFocus = (field) => {
    if (validationErrorMessages[field]) {
      clearErrorValidationMessage(field);
    }
  };

  const handleAddPropertyOnClick = () => {
    if (validationErrorMessages.properties) {
      clearErrorValidationMessage('properties');
    }
    setSelectPropertiesMode(SELECT_PROPERTY_MODES.ADD);
  };

  const isPropertyDrawerOpen = Boolean(selectPropertiesMode);
  const isActionTemplateDrawerOpen = Boolean(actionTemplate);

  const handleCancel = closeParentDrawer || closeDrawer;

  const handleFormGoBack = () => {
    isPropertyDrawerOpen
      ? setSelectPropertiesMode(null)
      : onFormGoBack(isOriginCreateCampaign, closeDrawer);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    updateCampaign({ [name]: value });
  };

  const onSelectionChange = (targetName) => (value) => {
    const clearSelectedProperties =
      targetName === RECIPIENT || targetName === CAMPAIGN_TRIGGER;
    const updatedPropertyList = clearSelectedProperties
      ? { associatedProperties: [] }
      : {};
    updateCampaign({ [targetName]: value, ...updatedPropertyList });
  };

  const handleIconButtonOnClick = () => {
    setConfirmationMode(CONFIRMATION_MODE_FOR_STATUS[status]);
  };

  const validateCampaignOnClick = (confirmationMode) => () => {
    const messages = validateCampaign({
      actions,
      associatedProperties,
      campaignName,
      campaignTrigger,
      recipient,
    });

    messages
      ? setValidationErrorMessages(messages)
      : setConfirmationMode(confirmationMode);
  };

  const handleSaveCampaign = (handledConfirmationMode) => {
    const updatedStatus =
      handledConfirmationMode === CONFIRMATION_MODES.LIVE
        ? STATUS.LIVE
        : STATUS.DRAFT;

    saveCampaign(updatedStatus);
  };

  const handleConfirmation = () => {
    const shouldSetDraftLive =
      status === STATUS.DRAFT &&
      confirmationMode === CONFIRMATION_MODES.LIVE &&
      !isEditing;

    const handledConfirmationMode = confirmationMode;
    setConfirmationMode(null);

    switch (handledConfirmationMode) {
      case CONFIRMATION_MODES.ARCHIVE:
        archiveCampaign();
        break;

      case CONFIRMATION_MODES.DELETE_ARCHIVE:
      case CONFIRMATION_MODES.DELETE_DRAFT:
        deleteCampaign();
        break;

      case CONFIRMATION_MODES.BACK:
        handleFormGoBack();
        break;

      case CONFIRMATION_MODES.CANCEL:
        handleCancel();
        break;

      case CONFIRMATION_MODES.DUPLICATE:
        handleDuplicate();
        break;

      case CONFIRMATION_MODES.LIVE:
      case CONFIRMATION_MODES.SAVE_DRAFT:
        shouldSetDraftLive
          ? setDraftCampaignStatusLive()
          : handleSaveCampaign(handledConfirmationMode);
        break;

      case CONFIRMATION_MODES.SAVE_PROPERTY_CHANGE:
        updateLiveEditedProperties();
        break;

      default:
        break;
    }
  };

  const cardConfig = getCardConfig({
    closeDrawer,
    handleCancel,
    handleFormGoBack,
    hasCampaignChanged,
    isOriginCreateCampaign,
    mode,
    setConfirmationMode,
    status,
  });

  return (
    <DripCampaignCard
      closeDrawer={cardConfig.closeDrawer}
      handleBackButtonOnClick={cardConfig.backButtonHandler}
      subheader={cardConfig.subheader}
    >
      <FeatureDrawer
        isOpen={isPropertyDrawerOpen || isActionTemplateDrawerOpen}
      >
        {isPropertyDrawerOpen && (
          <SelectProperties
            associatedProperties={associatedProperties}
            campaignId={campaignId}
            closeDrawer={() => setSelectPropertiesMode(null)}
            closeParentDrawer={handleCancel}
            selectPropertiesMode={selectPropertiesMode}
            setConfirmationMode={setConfirmationMode}
            setErrorMessage={setErrorMessage}
            setIsEditing={setIsEditing}
            triggerType={campaignTrigger}
            updateCampaign={updateCampaign}
          />
        )}

        {isActionTemplateDrawerOpen && (
          <ActionTemplate
            closeParentDrawer={cardConfig.closeDrawer}
            mode={mode}
            setActionTemplate={setActionTemplate}
            template={actionTemplate}
            updateActions={updateAction}
          />
        )}
      </FeatureDrawer>

      <Confirmation
        confirmationMode={confirmationMode}
        handleConfirmation={handleConfirmation}
        isCampaign
        setConfirmationMode={setConfirmationMode}
      />

      <ErrorDialog
        closeDialog={() => {
          setErrorMessage('');
        }}
        errorMessage={errorMessage}
        isOpen={Boolean(errorMessage)}
      />

      <FeatureCardContent className={classes.campaignCardContent}>
        {mode === MODES.FORM ? (
          <TextField
            aria-label={CAMPAIGN_LABELS.CAMPAIGN_NAME}
            className={classnames(
              classes.campaignNameInput,
              classes.campaignFilledInput
            )}
            error={Boolean(validationErrorMessages.campaignName)}
            helperText={validationErrorMessages.campaignName}
            inputProps={{ maxLength: 100 }}
            label={CAMPAIGN_LABELS.CAMPAIGN_NAME}
            name={CAMPAIGN_NAME}
            onChange={handleOnChange}
            onFocus={() => {
              handleOnFocus(CAMPAIGN_NAME);
            }}
            placeholder="Enter a name"
            value={campaignName}
            variant="filled"
          />
        ) : (
          <Text variant="subtitle1">{campaignName}</Text>
        )}

        {mode === MODES.FORM ? (
          <div className="select-container">
            <FormControl
              className={classes.campaignFilledInput}
              error={Boolean(validationErrorMessages.recipient)}
              variant="filled"
            >
              <Select
                aria-label="Select Recipient Type"
                className={classes.recipientSelect}
                displayEmpty
                label={CAMPAIGN_LABELS.RECIPIENT}
                name={RECIPIENT}
                noForm
                onFocus={() => {
                  handleOnFocus(RECIPIENT);
                }}
                onSelectChange={onSelectionChange(RECIPIENT)}
                options={getSelectOptions(RECIPIENT)}
                renderValue={setPlaceHolder(recipient, RECIPIENT)}
                value={recipient}
              />
              {validationErrorMessages.recipient && (
                <FormHelperText>
                  {validationErrorMessages.recipient}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              className={classes.campaignFilledInput}
              error={Boolean(validationErrorMessages.campaignTrigger)}
              variant="filled"
            >
              <Select
                aria-label="Select Trigger Type"
                className={classes.triggerSelect}
                disabled={!recipient}
                displayEmpty
                label={CAMPAIGN_LABELS.CAMPAIGN_TRIGGER}
                name={CAMPAIGN_TRIGGER}
                noForm
                onFocus={() => {
                  handleOnFocus(CAMPAIGN_TRIGGER);
                }}
                onSelectChange={onSelectionChange(CAMPAIGN_TRIGGER)}
                options={triggerOptions}
                renderValue={setPlaceHolder(campaignTrigger, CAMPAIGN_TRIGGER)}
                value={campaignTrigger}
              />

              {validationErrorMessages.campaignTrigger && (
                <FormHelperText>
                  {validationErrorMessages.campaignTrigger}
                </FormHelperText>
              )}
            </FormControl>
          </div>
        ) : (
          <div className="campaign-trigger-display">
            <DisplayChip
              label={CAMPAIGN_LABELS.RECIPIENT}
              value={recipient || 'Prospect'}
            />
            <DisplayChip
              label={CAMPAIGN_LABELS.CAMPAIGN_TRIGGER}
              value={TRIGGER_LABELS[campaignTrigger]}
            />
          </div>
        )}

        <Text className={classes.campaignDisclaimer} variant="body2">
          {CAMPAIGN_DISCLAIMERS[recipient]}
        </Text>

        <Divider className={classes.addPropertyDivider} />

        {associatedProperties.length > 0 && (
          <div
            className={
              mode === MODES.FORM ? 'campaign-associated-properties' : ''
            }
          >
            <PropertyChips
              properties={associatedProperties}
              mode={mode}
              removeProperty={removeAssociatedProperty}
            />
          </div>
        )}

        {mode === MODES.FORM && (
          <React.Fragment>
            {validationErrorMessages.properties && (
              <Text className={classes.propertiesError} color="error">
                Properties:{' '}
                <Text variant="caption">
                  {validationErrorMessages.properties}
                </Text>
              </Text>
            )}
            <AddIconButton
              handleOnClick={handleAddPropertyOnClick}
              primaryText={'Add Property'}
              disabled={!campaignTrigger}
            />
          </React.Fragment>
        )}

        <Divider className={classes.addPropertyDivider} />

        <CampaignActions
          actions={actions}
          addAction={addAction}
          deleteAction={deleteAction}
          mode={mode}
          setActionTemplate={setActionTemplate}
          setValidationErrorMessages={setValidationErrorMessages}
          triggerType={campaignTrigger}
          updateAction={updateAction}
          validationErrorMessages={validationErrorMessages.actions}
        />
      </FeatureCardContent>

      <CampaignActionButtons
        handleSaveChangeOnClick={() => {
          setConfirmationMode(CONFIRMATION_MODES.SAVE_PROPERTY_CHANGE);
        }}
        handleDuplicateOnClick={() =>
          setConfirmationMode(CONFIRMATION_MODES.DUPLICATE)
        }
        handleEditOnClick={() => setIsEditing(true)}
        handleEditPropertyOnClick={() => {
          setSelectPropertiesMode(SELECT_PROPERTY_MODES.EDIT);
        }}
        handleIconButtonOnClick={handleIconButtonOnClick}
        handleSaveOnClick={validateCampaignOnClick(CONFIRMATION_MODES.LIVE)}
        handleSaveDraftOnClick={validateCampaignOnClick(
          CONFIRMATION_MODES.SAVE_DRAFT
        )}
        hasCampaignChanged={hasCampaignChanged}
        isEditing={isEditing}
        isOriginCreateCampaign={isOriginCreateCampaign}
        mode={mode}
        status={status}
      />
    </DripCampaignCard>
  );
};

Campaign.propTypes = {
  closeDrawer: PropTypes.func,
  closeParentDrawer: PropTypes.func,
  isOriginCreateCampaign: PropTypes.bool,
  reloadCampaignsViewData: PropTypes.func,
  selectedCampaign: PropTypes.shape({
    campaignId: PropTypes.number,
    status: PropTypes.string,
  }),
};

export default Campaign;
