import React from 'react';
import PropTypes from 'prop-types';
import {
  LOADING_STATE_VALUE,
  NO_RELAY_DEFAULTS,
  PHONE_REGISTRATION_COLUMNS,
  REGISTRATION_ATTEMPTS,
  TRACKING_NUMBER_STATUS,
} from '../constants';

const renderColumns = (registrationRelayPhone) =>
  PHONE_REGISTRATION_COLUMNS.map((fieldName) => (
    <td className="phone-registration table-data" key={fieldName}>
      {registrationRelayPhone[fieldName] || LOADING_STATE_VALUE}
    </td>
  ));

const PhoneRegistrationColumnCells = ({
  propertyPhoneRegistrationRelayMap,
  relayPhones,
}) => {
  if (relayPhones) {
    return relayPhones.map((relayPhone) => {
      const registrationRelayPhone =
        propertyPhoneRegistrationRelayMap[relayPhone.relayPhoneId] || {};
      return renderColumns(registrationRelayPhone);
    });
  }

  const noRelayDefaults = propertyPhoneRegistrationRelayMap[NO_RELAY_DEFAULTS];
  return renderColumns(noRelayDefaults || {});
};

PhoneRegistrationColumnCells.propTypes = {
  propertyPhoneRegistrationRelayMap: PropTypes.objectOf(
    PropTypes.shape({
      [REGISTRATION_ATTEMPTS]: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      [TRACKING_NUMBER_STATUS]: PropTypes.string,
      [NO_RELAY_DEFAULTS]: PropTypes.shape({
        [REGISTRATION_ATTEMPTS]: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
        [TRACKING_NUMBER_STATUS]: PropTypes.string,
      }),
    })
  ),
  relayPhones: PropTypes.arrayOf(
    PropTypes.shape({ relayPhoneId: PropTypes.number })
  ),
};

export default PhoneRegistrationColumnCells;
