import React from 'react';
import PropTypes from 'prop-types';
import { ListItemText, Text } from '@knockrentals/knock-shared-web';

export const propertyCountText = (count) =>
  `${count} ${count === 1 ? 'Property' : 'Properties'}`;

const TemplateDetails = ({ assignedPropertiesCount, templateName }) => (
  <ListItemText disableTypography>
    <div className="template-row">
      <Text variant="subtitle1">{templateName}</Text>

      <div className="template-row-data">
        <div className="enabled-property-ratio">
          <Text align="center" color="secondary" variant="body2">
            {propertyCountText(assignedPropertiesCount)}
          </Text>
        </div>
      </div>
    </div>
  </ListItemText>
);

TemplateDetails.propTypes = {
  assignedPropertiesCount: PropTypes.number,
  templateName: PropTypes.string,
};

export default TemplateDetails;
