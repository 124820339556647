import React from 'react';

export const ReferralSourceSelect = ({
  referralSource,
  availableReferralSources,
  onReferralSourceChange,
}) => (
  <select
    value={referralSource || ''}
    data-testid="referral-source-select"
    onChange={(event) => onReferralSourceChange(event.target.value)}
  >
    <option key={0} data-testid="referral-source-option" value="">
      Original Source of Prospect
    </option>
    {availableReferralSources.map((referralSource) => (
      <option
        key={referralSource.source_id}
        data-testid="referral-source-option"
      >
        {referralSource.name}
      </option>
    ))}
  </select>
);

export default ReferralSourceSelect;
