import React from 'react';
import { DataTableCheckbox } from '../../../common/DataTableCheckbox';

export const getInputSummaries = (propertiesMap) => {
  const initialCounts = {
    selected: 0,
    unselected: 0,
  };

  const properties = Object.values(propertiesMap);
  const omniCounts = properties.reduce((countMap, property) => {
    property.isSelected ? countMap.selected++ : countMap.unselected++;

    return countMap;
  }, initialCounts);

  const propertyCount = properties.length;

  return {
    areAllSelected:
      propertyCount !== 0 && omniCounts.selected === propertyCount,
    areAllUnselected: omniCounts.unselected === propertyCount,
  };
};

export const getColumns = ({
  handleOmniOnChange,
  handleRowDataOnChange,
  propertyCountsText,
  isEditing,
  tablePropertiesMap,
}) => {
  const { areAllSelected, areAllUnselected } =
    getInputSummaries(tablePropertiesMap);

  const templateNameColumn = {
    align: 'right',
    field: 'assignedTemplateName',
    title: 'Template Name',
    width: '200px',
    cellStyle: { wordBreak: 'break-word' },
    sorting: false,
  };
  const checkboxColumn = {
    align: 'left',
    field: 'isSelected',
    sorting: false,
    title: (
      <DataTableCheckbox
        arialLabel="select all properties checkbox"
        isChecked={areAllSelected}
        isIndeterminate={!areAllSelected && !areAllUnselected}
        onChangeHandler={handleOmniOnChange}
      />
    ),
    render: (rowData) => {
      const { isSelected } = tablePropertiesMap[rowData.propertyId] || {};
      return (
        <DataTableCheckbox
          arialLabel="select property checkbox"
          isChecked={isSelected}
          onChangeHandler={handleRowDataOnChange(rowData.propertyId)}
        />
      );
    },
    minWidth: '20px',
    maxWidth: '20px',
  };

  return [
    ...(isEditing ? [] : [checkboxColumn]),
    {
      align: 'left',
      field: 'propertyName',
      title: `Property Name${
        propertyCountsText ? ` ${propertyCountsText}` : ''
      }`,
      width: isEditing ? '100%' : '200px',
      cellStyle: isEditing ? { height: '52px' } : {},
      sorting: true,
    },
    ...(isEditing ? [] : [templateNameColumn]),
  ];
};
