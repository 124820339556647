import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { Chip, makeStyles, slate, Text } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles((theme) => ({
  propertiesChip: {
    margin: '6px 3px',
    '&.MuiChip-root': {
      backgroundColor: slate[100],
    },
    '& .MuiChip-deleteIcon': {
      color: theme.palette.primary.main,
    },
  },
  propertiesTitle: {
    marginRight: '8px',
  },
}));

export const INITIAL_DISPLAY_COUNT = 3;

export const PropertyChips = ({ handleOnClick, properties }) => {
  const classes = useStyles();
  const [isListTruncated, setIsListTruncated] = useState(true);

  const toggleIsListTruncated = () => {
    setIsListTruncated((prevState) => !prevState);
  };

  const visiblePropertyChips = isListTruncated
    ? properties.slice(0, INITIAL_DISPLAY_COUNT)
    : properties;

  const hiddenPropertiesCount = properties.length - INITIAL_DISPLAY_COUNT;
  const hasHiddenPropertyChips = hiddenPropertiesCount > 0;

  const clickableChipText = isListTruncated
    ? `+${hiddenPropertiesCount}`
    : 'close';

  const ClickableChipIcon = isListTruncated ? ExpandMore : ExpandLess;

  return (
    <React.Fragment>
      <Text
        component="span"
        className={classes.propertiesTitle}
        variant="body2"
      >
        Properties:
      </Text>

      <span className="property-chips">
        {visiblePropertyChips.map((property) => (
          <Chip
            className={classes.propertiesChip}
            clickable={Boolean(handleOnClick)}
            key={property.propertyId}
            label={<Text variant="caption">{property.propertyName}</Text>}
            onDelete={handleOnClick}
          />
        ))}

        {hasHiddenPropertyChips && (
          <Chip
            className={classes.propertiesChip}
            clickable
            icon={<ClickableChipIcon fontSize="small" />}
            label={<Text variant="caption">{clickableChipText}</Text>}
            onClick={toggleIsListTruncated}
          />
        )}
      </span>
    </React.Fragment>
  );
};

PropertyChips.propTypes = {
  handleOnClick: PropTypes.func,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      propertyId: PropTypes.number,
      propertyName: PropTypes.string,
    })
  ),
};

export default PropertyChips;
