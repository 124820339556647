import React, { useState } from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Text,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import PropTypes from 'prop-types';
import VirtualAgentGenAIChannelList, { CHANNEL_LIST } from './VirtualAgentGenAIChannelList';

const useStyles = makeStyles(() => ({
  dialogActions: {
    padding: '16px',
  },
  mBottom20: {
    marginBottom: '20px',
  },
}));

const VirtualAgentGenAIResponsesModal = ({
  isOpen,
  closeDialog,
  handleGeneration,
  isGenerating,
  propertyId
}) => {
  const classes = useStyles();
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [showModalSecondPage, setShowModalSecondPage] = useState(false)

  const handleGoToNextPage = () => {
    setShowModalSecondPage(true);
  }

  const handleCloseDialog = () => {
    setSelectedChannels([]);
    setShowModalSecondPage(false);
    closeDialog();
  }

  const handleGenerateAIResponsesConfirmation = () => {
    const channelMap = CHANNEL_LIST.reduce((acc, channel) => {
      acc[channel.toLowerCase()] = selectedChannels.includes(channel);
      return acc;
    }, {}); 
    const propertyGenAiChannels = {channels: channelMap}
    setSelectedChannels([]);
    setShowModalSecondPage(false);
    handleGeneration(propertyId, propertyGenAiChannels);
  }

  const firstPageText = "Select the Channels where you want to generate GenAl chat responses. \
    Doing this will overwrite any existing responses."

  const secondPageText = (
    <>
      Generating responses pulls content from the leasing binder and will overwrite all existing 
      responses in all selected properties, intents, and channels. This may take a few moments.
      <br /><br />
      We recommend reviewing all generated content for accuracy. Content may be manually updated as needed.
    </>
  );

  return (
    <Dialog open={isOpen} onClose={handleCloseDialog}>
      <DialogTitle disableTypography={true}>
        <Text variant="h6" className={classes.mBottom20}>
          {showModalSecondPage ? "Create GenAI Responses" : "Select Channels"}
        </Text>
        <Text variant="body1">
          {showModalSecondPage ? secondPageText : firstPageText}
        </Text>
      </DialogTitle>

      {(!showModalSecondPage) && (
        <DialogContent dividers={false}>
          <VirtualAgentGenAIChannelList
            selectedChannels={selectedChannels}
            setSelectedChannels={setSelectedChannels}
          />
        </DialogContent>
      )}

      <Dialog open={isGenerating}>
        <DialogContent dividers={false}>
          <DialogTitle disableTypography={true}>
            <Text variant="h6" className={classes.mBottom20}>
            Generating AI responses...
            </Text>
            <CircularProgress size="20px" />
          </DialogTitle>
        </DialogContent>
      </Dialog>

      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            showModalSecondPage ? handleGenerateAIResponsesConfirmation() : handleGoToNextPage(); 
          }}
          disabled={selectedChannels <= 0}
        >
          {showModalSecondPage ? "Generate Responses" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

VirtualAgentGenAIResponsesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleGeneration: PropTypes.func.isRequired,
  isGenerating: PropTypes.bool.isRequired,
  propertyId: PropTypes.number.isRequired,
};

export default VirtualAgentGenAIResponsesModal;
