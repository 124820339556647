import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@knockrentals/knock-shared-web';

import { DripCampaignCard } from '../../DripCampaignCard';
import FeatureCardContent from '../../../common/FeatureCardContent';
import TemplatePreview from './TemplatePreview';
import { PreviewActionButtons } from '../../DrawerActionButtons';
import { CONFIRMATION_MODES, TEMPLATE_SUBHEADER } from '../constants';

const useStyles = makeStyles(() => ({
  templateCardContent: {
    background: '#FFFFFF',
  },
}));

const Preview = ({
  closeDrawer,
  contentType,
  isEditing,
  message,
  setConfirmationMode,
  subject,
  templateName,
}) => {
  const classes = useStyles();

  return (
    <DripCampaignCard
      closeDrawer={() => setConfirmationMode(CONFIRMATION_MODES.CANCEL)}
      handleBackButtonOnClick={closeDrawer}
      subheader={TEMPLATE_SUBHEADER.PREVIEW}
    >
      <FeatureCardContent className={classes.templateCardContent}>
        <TemplatePreview
          contentType={contentType}
          message={message}
          subject={subject}
          templateName={templateName}
        />
      </FeatureCardContent>

      <PreviewActionButtons
        isEditing={isEditing}
        setConfirmationMode={setConfirmationMode}
      />
    </DripCampaignCard>
  );
};

Preview.propTypes = {
  closeDrawer: PropTypes.func,
  closeParentDrawer: PropTypes.func,
  contentType: PropTypes.string,
  isEditing: PropTypes.bool,
  message: PropTypes.string,
  setConfirmationMode: PropTypes.func,
  subject: PropTypes.string,
  templateName: PropTypes.string,
};

export default Preview;
