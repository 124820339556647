export const NO_RELAY_DEFAULTS = 'noRelayDefaults';
export const REGISTRATION_ATTEMPTS = 'registrationAttempts';
export const TOTAL_FAILED = 'totalFailed';
export const TOTAL_REGISTERED = 'totalRegistered';
export const TOTAL_UNREGISTERED = 'totalUnregistered';
export const TRACKING_NUMBER_STATUS = 'registrationStatus';

export const REGISTRATION_LABELS = {
  [TOTAL_FAILED]: 'Total Failed',
  [TOTAL_REGISTERED]: 'Total Registered',
  [TOTAL_UNREGISTERED]: 'Total Unregistered',
};

export const LOADING_STATE_VALUE = '-';

export const REGISTRATION_LOADING_STATE = {
  [TOTAL_FAILED]: LOADING_STATE_VALUE,
  [TOTAL_REGISTERED]: LOADING_STATE_VALUE,
  [TOTAL_UNREGISTERED]: LOADING_STATE_VALUE,
};

export const PHONE_REGISTRATION_COLUMN_NAMES = {
  [REGISTRATION_ATTEMPTS]: 'Registration Attempts',
  [TRACKING_NUMBER_STATUS]: 'Tracking Number Status',
};

export const PHONE_REGISTRATION_COLUMNS = [
  TRACKING_NUMBER_STATUS,
  REGISTRATION_ATTEMPTS,
];

export const NO_RELAY_PHONE = {
  [REGISTRATION_ATTEMPTS]: 'N/A',
  [TRACKING_NUMBER_STATUS]: 'None entered',
};

export const FAILED_STATUS = 'failed';
