import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import {
  IconButton,
  Tooltip,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import TooltipCard from './TooltipCard/TooltipCard';
import FeatureDrawer from '../../common/FeatureDrawer';
import './_TooltipDrawer.scss';

export const TOOLTIP_DRAWER_ARIA_LABEL = 'Tooltip drawer button';

const useStyles = makeStyles({
  tooltipButton: {
    color: '#5C5C5C',
    verticalAlign: 'middle',
  },
  tooltipWrapper: {
    '& .MuiTooltip-tooltip': {
      fontSize: '10px',
      lineHeight: '14px',
      padding: '4px 8px',

      '& .MuiTooltip-arrow': {
        bottom: 0,
        top: 0,
        marginTop: '-0.71em',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '0 100%',
        },
      },
    },
  },
});

const TooltipDrawer = ({ children, subheader, title }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleIsDrawerOpen = (isOpen) => () => {
    setIsDrawerOpen(isOpen);
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <Tooltip
        arrow
        PopperProps={{
          className: classes.tooltipWrapper,
        }}
        title={title}
      >
        <span>
          <IconButton
            aria-label={TOOLTIP_DRAWER_ARIA_LABEL}
            className={classes.tooltipButton}
            disableRipple
            onClick={handleIsDrawerOpen(true)}
            color="secondary"
          >
            <HelpOutlineOutlinedIcon />
          </IconButton>
        </span>
      </Tooltip>

      <FeatureDrawer isOpen={isDrawerOpen}>
        <TooltipCard
          closeDrawer={handleIsDrawerOpen(false)}
          title={title}
          subheader={subheader}
        >
          {children}
        </TooltipCard>
      </FeatureDrawer>
    </React.Fragment>
  );
};

TooltipDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  subheader: PropTypes.string,
  title: PropTypes.string,
};

export default TooltipDrawer;
