import React from 'react';
import { ColorPicker } from '@knockrentals/knock-react';

const DoorwayColorPickerInput = ({ label, color, onChange, testId }) => (
    <div className="doorway-property-setting-item">
        <div>
            <span data-testid={testId}>{label}</span>
        </div>

        <ColorPicker
            color={color}
            onChange={onChange}
        />
    </div>
);

export default DoorwayColorPickerInput;
