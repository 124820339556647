import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import { 
  IconButton,
  SearchIcon,
  Divider
 } from '@knockrentals/knock-shared-web';

const SearchBar = ({ searchTerm, setSearchTerm }) => {
  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClear = () => {
    setSearchTerm('');
  };

  return (
    <div>
      <TextField
        variant="outlined"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <>
              <IconButton onClick={handleClear}>
                <ClearIcon />
              </IconButton>
              {searchTerm && (
                <Divider orientation="vertical" flexItem />
              )}
              <IconButton>
                <SearchIcon />
              </IconButton>
            </>
          ),
          style: { borderRadius: 25 },
        }}
        fullWidth
      />
    </div>
  );
};

SearchBar.propTypes = {
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
};

export default SearchBar;
