import React from 'react';
import {
  makeStyles,
  Box,
  Text,
  Alert,
} from '@knockrentals/knock-shared-web';


const useStyles = makeStyles({
  bannerContainer: {
    margin: '24px 0',
    maxWidth: '1110px',
    minWidth: '768px'
  },
  messageTitle: {
    marginBottom: '4px',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  messageText: {
    paddingBottom: '6px'
  }
});

const AiSetupBanner = () => {
  const classes = useStyles();
  return (
    <Box className={classes.bannerContainer}>
      <Alert severity="info" className={classes.alert}>
        <Box className={classes.contentContainer}>
          <Text className={classes.messageTitle}>AI Email Not Enabled</Text>
          <Text className={classes.messageText} variant="body2">
            You can configure your AI Template Responses here, they will not become active until you turn on AI Email.
          </Text>
        </Box>
      </Alert>
    </Box>
  );
};

export default AiSetupBanner;
