import React from 'react';
import { Paper, List, ListItem, ListItemText,} from '@knockrentals/knock-shared-web';
import { IndeterminateSwitch } from '../../../Features/common/IndeterminateSwitch';

const AiBulkEditFeatures = ({ featureStates, handleEditFeatures }) => {

  return (
      <Paper elevation={1}>
        <List className='toggles-list'>
          {featureStates.map((feature) => (
            <React.Fragment key={feature.field}>
              <ListItem >
                <ListItemText primary={feature.field}></ListItemText>
                <IndeterminateSwitch
                  isChecked={feature.value}
                  isIndeterminate={!feature.equal}
                  name={feature.field}
                  onChangeHandler={(field) => handleEditFeatures(field)}
                />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Paper>
    );
}

export default AiBulkEditFeatures;