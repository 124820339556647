import React from 'react';
import PropTypes from 'prop-types';

import { Text, makeStyles } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  characterMessage: {
    marginLeft: '5px',
  },
});

export const charactersLeftMessage = (limit, textLength) => {
  const numCharactersLeft = limit - textLength;
  return `${numCharactersLeft} ${
    numCharactersLeft === 1 ? 'character' : 'characters'
  } left`;
};

const CharacterLimitDisplay = ({ limit, textLength }) => {
  const classes = useStyles();
  return (
    <Text
      className={classes.characterMessage}
      variant="caption"
      variantMapping={{ caption: 'div' }}
    >
      {charactersLeftMessage(limit, textLength)}
    </Text>
  );
};

CharacterLimitDisplay.propTypes = {
  limit: PropTypes.number,
  textLength: PropTypes.number,
};

export default CharacterLimitDisplay;
