import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Formik } from 'formik';

import {
  AuthenticationService,
  Collapse,
  Toaster,
} from '@knockrentals/knock-react';
import { FeatureFlagContext } from '../../Context/FeatureFlagContext';
import IntegrationsAPI from '../Integrations/IntegrationsAPI';
import LoginAPI from '../Login/LoginAPI';
import { AddIntegration } from './AddIntegrationForms';
import { AddIntegrationButtons } from './AddIntegrationButtons';
import PropertyIntegration from './PropertyIntegration';
import {
  IntegrationVendorNames,
  IntegrationVendorNamesLowerCase,
} from './constants';

import './_propertyinfo.scss';

class PropertyInfo extends Component {
  state = {
    addingIntegrationVendor: null,
    isConfirmingRemoveIntegration: null,
    addingIntegrationVendorError: null,
    newPropertyEmail: this.props.property.contact_email,
    requireSMSConsent: this.props.property.require_sms_consent,
  };

  static contextType = FeatureFlagContext;

  static propTypes = {
    property: PropTypes.object.isRequired,
  };

  render() {
    const allowLoginLink = AuthenticationService._internalMode || !this.props.property.isCallIntelStandAlone;
    return (
      <Collapse
        title={
          this.props.property.location.name +
          ' - ' +
          this.props.property.location.address.city +
          ', ' +
          this.props.property.location.address.state
        }
      >
        <div key={this.props.property.community_id}>
          <div>
            <strong>Owner:</strong> {this.props.property.owner}{' '}
            {allowLoginLink && (
              <a
                href={'#'}
                onClick={this.loginToUser.bind(
                  this,
                  this.props.property.owning_manager_id
                )}
              >
                Login
              </a>
            )}
          </div>
          <div>
            <strong>Contact Email: </strong>
            {!this.state.isEditingContactEmail ? (
              <span>
                {this.props.property.contact_email}{' '}
                {AuthenticationService._internalMode ? (
                  <a href={'#'} onClick={this.editContactEmail.bind(this)}>
                    Edit
                  </a>
                ) : null}
              </span>
            ) : (
              <span>
                <Formik
                  onSubmit={this.saveContactEmail.bind(this)}
                  initialValues={{
                    newPropertyEmail: this.props.property.contact_email,
                  }}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <input
                        className="knock-react-input"
                        name="newPropertyEmail"
                        value={props.values.newPropertyEmail}
                        size={
                          props.values.newPropertyEmail
                            ? props.values.newPropertyEmail.length + 10
                            : 20
                        }
                        onChange={props.handleChange}
                      />
                      {!!props.errors.newPropertyEmail && (
                        <span className="knock-react-form-hint knock-react-form-hint-error">
                          {props.errors.newPropertyEmail}
                        </span>
                      )}
                      <button type="submit">Save</button>
                      <button
                        className="knock-react-button"
                        onClick={this.cancelEditContactEmail.bind(this)}
                      >
                        Cancel
                      </button>
                    </form>
                  )}
                </Formik>
              </span>
            )}
          </div>

          <div>
            <strong>Knock ID:</strong> {this.props.property.community_id}
          </div>

          {this.renderIntegratedProperties()}
          {this.renderSecondaryIntegrations()}
        </div>
      </Collapse>
    );
  }

  renderIntegratedProperties() {
    const integrations = this.props.property.integrations.map(
      (propertyIntegration, index) => {
        return AuthenticationService._internalMode ? (
          <div key={index}>
            <PropertyIntegration integration={propertyIntegration} />
            {this.state.isConfirmingRemoveIntegration !==
              propertyIntegration.credential_id ? (
              <button
                className="knock-react-button danger"
                onClick={this.startRemoveIntegration.bind(
                  this,
                  propertyIntegration.credential_id
                )}
              >
                <i className="fa fa-trash" /> Remove integration
              </button>
            ) : (
              <div>
                <strong>
                  Are you sure you wish to remove this integration?
                </strong>
                <button
                  className="knock-react-button danger"
                  onClick={this.removeIntegration.bind(
                    this,
                    propertyIntegration
                  )}
                >
                  Yes, remove integration
                </button>
                <button
                  className="knock-react-button"
                  onClick={this.cancelRemoveIntegration.bind(
                    this,
                    propertyIntegration.credential_id
                  )}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        ) : null;
      }
    );

    return (
      <div>
        <div>{integrations}</div>
        {this.renderIntegrationOptions()}
      </div>
    );
  }

  renderSecondaryIntegrationWarning = () => {
    const { secondaryIntegrationWarningMessage } = this.props;
    const classNames = classnames(
      'knock-react-alert',
      'knock-react-alert-error',
      'property-integration-error',
      'warning'
    );
    return (
      secondaryIntegrationWarningMessage && (
        <div className={classNames}>{secondaryIntegrationWarningMessage}</div>
      )
    );
  };

  renderSecondaryIntegrations() {
    const {
      property: { secondary_integrations },
    } = this.props;
    const secondaryIntegratedOptions = [];

    const secondaryIntegrationComponents = secondary_integrations.map(
      (secondaryIntegration, index) => {
        const { vendor } = secondaryIntegration;

        if (vendor === IntegrationVendorNames.FACEBOOK) {
          secondaryIntegratedOptions.push(secondaryIntegration.vendor);
          return (
            <div key={`secondaryIntegrationComponents-${index}`}>
              <PropertyIntegration integration={secondaryIntegration} />
            </div>
          );
        }

        if (vendor === IntegrationVendorNames.ENGRAIN) {
          secondaryIntegratedOptions.push(secondaryIntegration.vendor);
          return (
            <div key={`secondaryIntegrationComponents-${index}`}>
              <PropertyIntegration integration={secondaryIntegration} />
            </div>
          );
        }

        return null;
      }
    );

    return (
      <div>
        {secondaryIntegrationComponents}
        {this.renderSecondaryIntegrationOptions(secondaryIntegratedOptions)}
        {this.renderSecondaryIntegrationWarning()}
      </div>
    );
  }

  renderIntegrationOptions() {
    const { addingIntegrationVendor } = this.state;
    const { integrationErrorMessage } = this.props;

    return (
      <React.Fragment>
        {addingIntegrationVendor && (
          <AddIntegration
            onAddIntegration={this.onAddIntegration.bind(this)}
            onCancelAddIntegration={this.onCancelAddIntegration.bind(this)}
            vendor={addingIntegrationVendor}
          />
        )}

        {AuthenticationService._internalMode && (
          <div>
            <AddIntegrationButtons
              selectIntegrationOption={this.selectIntegrationOption.bind(this)}
            />

            {integrationErrorMessage && (
              <div className="knock-react-alert knock-react-alert-error property-integration-error">
                {integrationErrorMessage}
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }

  renderSecondaryIntegrationOptions() {
    const { addingIntegrationVendor } = this.state;
    if (addingIntegrationVendor) {
      if (
        addingIntegrationVendor === IntegrationVendorNamesLowerCase.FACEBOOK
      ) {
        return (
          <AddIntegration
            onAddIntegration={this.onAddFacebookIntegration.bind(this)}
            onCancelAddIntegration={this.onCancelAddIntegration.bind(this)}
            vendor={IntegrationVendorNamesLowerCase.FACEBOOK}
          />
        );
      }
    }

    return (
      <div>
        {/* NOTE: removing because as of 1/23/2020 all facebook integrations are added through the }}
        {/* {!secondaryIntegratedOptions.includes(
          IntegrationVendorNames.FACEBOOK
        ) ? (
          <button
            className="knock-react-button"
            onClick={this.selectIntegrationOption.bind(
              this,
              IntegrationVendorNamesLowerCase.FACEBOOK
            )}
          >
            <i className="fa fa-plus" /> Add Facebook integration
          </button>
        ) : null} */}
      </div>
    );
  }

  loginToUser(userId, event) {
    event.preventDefault();
    if (this.context.isUseCookieForTokenEnabled) {
      LoginAPI.openWebappAsUserFromCookie(userId);
    } else {
      LoginAPI.openWebappAsUser(userId);
    }
  }

  editContactEmail(event) {
    event.preventDefault();
    this.setState({ isEditingContactEmail: true });
  }

  cancelEditContactEmail(event) {
    event.preventDefault();
    this.setState({ isEditingContactEmail: false });
  }

  saveContactEmail(values) {
    const editedProperty = {
      ...this.props.property,
      contact_email: values.newPropertyEmail,
    };

    this.props.onPropertyChanged(editedProperty);
    this.setState({ isEditingContactEmail: false });
  }

  selectIntegrationOption(addingIntegrationVendor) {
    this.setState({ addingIntegrationVendor });
  }

  onAddIntegration(integration) {
    this.setState({ addingIntegrationVendor: null });

    const propertyId = this.props.property.property_id;

    this.props.onStartAddIntegration();

    IntegrationsAPI.addIntegration(
      this.props.property.property_id,
      this.state.addingIntegrationVendor,
      integration
    ).then((response) => {
      if (response.status !== 201) {
        Toaster.showToast(
          'Error adding integration. See error message.',
          2000,
          Toaster.ToastClasses.error
        );

        response.json().then((json) => {
          this.props.onEndAddIntegration(propertyId, json.message);
        });

        return;
      }

      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);

      this.props.onIntegrationUpdated();
      this.props.onEndAddIntegration();
    });
  }

  onAddFacebookIntegration(integration) {
    this.setState({ addingIntegrationVendor: null });

    const propertyId = this.props.property.property_id;

    this.props.onStartAddIntegration();

    IntegrationsAPI.addFacebookIntegration(
      this.props.property.property_id,
      integration
    ).then((response) => {
      if (response.status !== 201) {
        Toaster.showToast(
          'Error adding Facebook integration. See error message.',
          2000,
          Toaster.ToastClasses.error
        );

        response.json().then((json) => {
          this.props.onEndAddIntegration(propertyId, json.message);
        });

        return;
      }

      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);

      this.props.onIntegrationUpdated();
      this.props.onEndAddIntegration();
    });
  }

  onCancelAddIntegration() {
    this.setState({ addingIntegrationVendor: null });
  }

  startRemoveIntegration(credential_id) {
    this.setState({ isConfirmingRemoveIntegration: credential_id });
  }

  cancelRemoveIntegration() {
    this.setState({ isConfirmingRemoveIntegration: null });
  }

  removeIntegration(integration) {
    this.setState({ isConfirmingRemoveIntegration: null });

    IntegrationsAPI.removeIntegration(
      this.props.property.property_id,
      integration.credential_id,
      integration.vendor.toLowerCase()
    )
      .then(() => {
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        this.props.onIntegrationUpdated();
      })
      .catch(() => {
        Toaster.showToast(
          'Error removing integration.',
          2000,
          Toaster.ToastClasses.error
        );
      });
  }

  removeFacebookIntegration(integration) {
    this.setState({ isConfirmingRemoveIntegration: null });

    IntegrationsAPI.removeFacebookIntegration(
      this.props.property.property_id,
      integration.credential_id
    )
      .then(() => {
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        this.props.onIntegrationUpdated();
      })
      .catch(() => {
        Toaster.showToast(
          'Error removing facebook integration.',
          2000,
          Toaster.ToastClasses.error
        );
      });
  }

  validate({ newPropertyEmail }) {
    const errors = {};

    if (!newPropertyEmail) {
      errors.newPropertyEmail = 'Required';
    }

    if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(newPropertyEmail)) {
      errors.newPropertyEmail = 'Invalid email';
    }

    const mailerRegex1 = /^.*?mailer.knockrentals.com\s*$/;
    const mailerRegex2 = /^.*?m.knck.io\s*$/;

    if (
      !mailerRegex1.test(newPropertyEmail) &&
      !mailerRegex2.test(newPropertyEmail)
    ) {
      errors.newPropertyEmail = 'Cannot use Knock mailer';
    }

    return errors;
  }
}

export default PropertyInfo;
