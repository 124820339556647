import React, { Component } from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter } from '@knockrentals/knock-react';

class PhotosDialogModal extends Component {
  render() {
    return (
      <Modal className="photos-dialog-modal">
        <ModalHeader>
          <h2>{this.props.title}</h2>
        </ModalHeader>
        <ModalContent>
          <div className="knock-react-flex">
            <div>{this.props.body}</div>
          </div>
        </ModalContent>
        <ModalFooter>
          {this.props.onCancel ? (
            <button className="btn-primary" onClick={this.onCancel.bind(this)}>
              <i className="fa fa-lg fa-times-circle" /> Cancel
            </button>
          ) : null}
          {this.props.onConfirm ? (
            <button className="btn-success" onClick={this.onConfirm.bind(this)}>
              <i className="fa fa-lg fa-check-circle" /> OK
            </button>
          ) : null}
        </ModalFooter>
      </Modal>
    );
  }

  onConfirm() {
    this.props.clearDialog();
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  onCancel() {
    this.props.clearDialog();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }
}

export default PhotosDialogModal;
