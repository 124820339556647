import React from 'react';
import PropTypes from 'prop-types';
import { Text, makeStyles } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  actionTriggerText: { paddingLeft: '5px' },
});

const TimingLabel = ({ actionTrigger, hasError, labelText }) => {
  const classes = useStyles();
  return (
    <Text color={hasError ? 'error' : 'textPrimary'} component="span">
      {labelText}
      <Text
        className={classes.actionTriggerText}
        component="span"
        variant="subtitle1"
      >
        {actionTrigger}
      </Text>
    </Text>
  );
};

TimingLabel.propTypes = {
  actionTrigger: PropTypes.string,
  hasError: PropTypes.bool,
  labelText: PropTypes.string,
};

export default TimingLabel;
