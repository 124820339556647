import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { CardContent, makeStyles } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  featureCardContent: {
    flexGrow: 1,
    background: '#F7F7F8',
    overflowY: 'auto',
    padding: '26px 40px 40px',
  },
});

const FeatureCardContent = ({ children, className }) => {
  const classes = useStyles();
  return (
    <CardContent className={classnames(classes.featureCardContent, className)}>
      {children}
    </CardContent>
  );
};

FeatureCardContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default FeatureCardContent;
