import React, { Component } from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter } from '@knockrentals/knock-react';

class UserImport extends Component {
  state = {
    importInProgress: false,
  };

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.importFileAdded = this.importFileAdded.bind(this);
  }

  importFileAdded(fileInput) {
    this.fileInput = fileInput;
  }

  submit(event) {
    event.preventDefault();

    this.setState({ importInProgress: true });

    this.props.onImportUsers(this.fileInput.files[0]).then(() => {
      this.setState({ importInProgress: false });
    });
  }

  cancel(event) {
    event.preventDefault();
    this.props.onCancel();
  }

  renderImportErrors() {
    if (!this.props.importErrors) {
      return null;
    }
    if (this.props.importErrors.failedUserRecords) {
      return this.renderFailedUserImportRecords();
    } else {
      return this.renderUserImportValidationErrors();
    }
  }

  renderUserImportValidationErrors() {
    const invalidPublicIds = this.props.importErrors.invalidPublicIds.map(pid => {
      return <div>{pid}</div>;
    });

    const existingManagersUsernames = this.props.importErrors.existingManagersUsernames.map(
      username => {
        return <div>{username}</div>;
      }
    );

    const duplicateManagersUsernames = this.props.importErrors.duplicateManagersUsernames.map(
      username => {
        return <div>{username}</div>;
      }
    );

    const invalidTimezones = this.props.importErrors.invalidTimezones.map(timezone => {
      return <div>{timezone}</div>;
    });

    return (
      <div>
        <div>
          <strong>Invalid public IDs</strong>
          {invalidPublicIds}
        </div>
        <div>
          <strong>Existing managers usernames</strong>
          {existingManagersUsernames}
        </div>
        <div>
          <strong>Duplicate managers usernames</strong>
          {duplicateManagersUsernames}
        </div>
        <div>
          <strong>Invalid timezones</strong>
          {invalidTimezones}
        </div>
      </div>
    );
  }

  renderFailedUserImportRecords() {
    const invalidUserRecords = this.props.importErrors.failedUserRecords.map(user_record => {
      return <li className="mb-2">
        <div>
          <div><strong>username:</strong> {user_record.user_data.manager_username}</div>
          <div><strong>first_name:</strong> {user_record.user_data.manager_first_name}</div>
          <div><strong>last_name:</strong> {user_record.user_data.manager_last_name}</div>
          <div><strong>email:</strong> {user_record.user_data.manager_email}</div>
          <div><strong>phone:</strong> {user_record.user_data.manager_phone}</div>
        </div>
        <div className="text-danger">Error: {user_record.exception.message}</div>
      </li>;
    });
    return (
      <div>
        <div><strong>Imported {this.props.importErrors.importedUserRecords.length} users</strong></div>
        <div>
          <strong>An error occurred while importing following users: </strong>
          <ul>{invalidUserRecords}</ul>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal className="user-editor-container">
        <form onSubmit={this.submit}>
          <ModalHeader>
            <h2>Import users</h2>
          </ModalHeader>
          <ModalContent>
            <div className="knock-react-flex">
              <div>
                <strong>User import CSV: </strong>
              </div>
              <div>
                <input
                  type="file"
                  className="knock-react-input"
                  name="teamName"
                  ref={this.importFileAdded}
                />
              </div>
            </div>
            {this.renderImportErrors()}
          </ModalContent>
          {this.state.importInProgress ? (
            <ModalFooter>
              <button className="knock-react-button" disabled>
                Saving...
              </button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <button className="knock-react-button">Save</button>
              <button className="knock-react-button" onClick={this.cancel.bind(this)}>
                Cancel
              </button>
            </ModalFooter>
          )}
        </form>
      </Modal>
    );
  }
}

export default UserImport;
