const ALLOWED_STYLES =
  'azimuth,background*,border*,bottom,box-shadow,box-sizing,break*,caption-side,clear,color,column*,columns,cursor,direction,display,empty-cells,float,font,font-family,font-size,font-style,font-variant,font-weight,height,hsl,hsla,left,letter-spacing,line-height,list-style-position,list-style-type,margin*,max-width,min-width,opacity,outline,overflow*,padding,padding-bottom,position*,right,table-layout,text-align,text-decoration,text-indent,text-overflow,text-transform,top,vertical-align,visibility,voice-family,white-space,width,word-spacing,word-wrap,z-index';
const ADDITIONAL_ALLOWED_TAGS =
  'address area b big blockquote button cite code del dir fieldset hr i ins kbd label map q samp select small style sub sup; tt var; iframe[align,frameborder,height,id,longdesc,name,sandbox,scrolling,src,tabindex,title,width]; input[checked,name,maxlength,required,size,type,value]; option[value,selected]; table[border-collapse,align,border,cellpadding,cellspacing,summary,id,dir,width]; td[align,bgcolor,colspan,rowspan,scope,valign]; textarea[cols,name,required,rows]; th[scope]; tr[scope];';

export class EditorConfig {
  constructor(props = {}) {
    this.MARKER = '#';
    this.MAX_FILE_SIZE = 5; // In MB
    this.MAX_RESIZE_HEIGHT = props.maxResizeHeight;
    this.MAX_RESIZE_WIDTH = props.maxResizeWidth;
    this.MIN_RESIZE_HEIGHT = props.minResizeHeight;
    this.MIN_RESIZE_WIDTH = props.minResizeWidth;
    this.MENTIONS_CASE_SENSITIVE = false;

    this.authToken = props.authToken;
    this.filesUploaded = [];
    this.imageUploadUrl = props.imageUploadUrl;
    this.mentionsList = props.mentionsList || [];
    this.marker = props.marker || this.MARKER;
    this.isMentionsCaseSensitive =
      props.isMentionsCaseSensitive || this.MENTIONS_CASE_SENSITIVE;
    this.isSourceModeEnabled = props.isSourceModeEnabled;
    this.onBlur = props.onBlur;
    this.placeHolderText = props.placeHolderText;
    this.getBaseConfig = this.getBaseConfig.bind(this);
    this.getConfig = this.getConfig.bind(this);
    this.getDoesBeginWith = this.getDoesBeginWith.bind(this);
    this.getFeed = this.getFeed.bind(this);
    this.getMentions = this.getMentions.bind(this);
    this.isInvalidFileType = this.isInvalidFileType.bind(this);
    this.isInvalidFileSize = this.isInvalidFileSize.bind(this);
    this.validateFile = this.validateFile.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.deleteRemovedFileFromServer =
      this.deleteRemovedFileFromServer.bind(this);
    this.deleteFilesRemovedFromContent =
      this.deleteFilesRemovedFromContent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.fileUploadResponse = this.fileUploadResponse.bind(this);
    this.notificationShow = this.notificationShow.bind(this);
    this.dialogShow = this.dialogShow.bind(this);
  }

  getBaseConfig() {
    return {
      extraPlugins: 'colorbutton,mentions,emoji,image2',
      removePlugins: 'autogrow,uploadfile,uploadimage',
      removeDialogTabs: 'image:advanced;link:advanced;table:advanced',
      linkShowTargetTab: false,
      embed_provider:
        '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',

      removeButtons: 'Subscript,Superscript',

      extraAllowedContent: `*(*){${ALLOWED_STYLES}}; ${ADDITIONAL_ALLOWED_TAGS}`,

      disallowedContent: 'a[onclick]',

      format_tags: 'p;h1;h2;h3;pre',

      resize_dir: 'both',

      notification_duration: 3000,

      clipboard_handleImages: false,

      disableNativeSpellChecker: false,

      fileBrowserUploadMethod: 'xhr',

      fileTools_defaultFileName: 'files',

      startupShowBorders: false,

      toolbar: [
        { name: 'type', items: ['Font', 'FontSize'] },
        {
          name: 'texttools',
          items: ['Bold', 'Italic', 'Underline', 'Strike'],
        },
        { name: 'colors', items: ['TextColor', 'BGColor'] },
        {
          name: 'alignment',
          items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
        },
        { name: 'lists', items: ['NumberedList', 'BulletedList'] },
        { name: 'indentation', items: ['Indent', 'Outdent'] },
        {
          name: 'links',
          items: ['Link', 'Unlink'],
        },
        {
          name: 'insert',
          items: ['Image', 'EmojiPanel'],
        },
      ],
      on: {
        change: this.handleChange,
        blur: this.handleBlur,
        paste: this.handlePaste,
        fileUploadResponse: this.fileUploadResponse,
        notificationShow: this.notificationShow,
        dialogShow: this.dialogShow,
      },
    };
  }

  getConfig() {
    const config = this.getBaseConfig();
    if (this.authToken) {
      config.fileTools_requestHeaders = {
        Authorization: `Bearer ${this.authToken}`,
      };
    }

    if (this.imageUploadUrl) {
      config.imageUploadUrl = this.imageUploadUrl;
      config.uploadUrl = this.imageUploadUrl;
      config.extraPlugins = `${config.extraPlugins},uploadimage`;
      config.removePlugins = 'autogrow,uploadfile';
    }

    if (this.placeHolderText) {
      config.editorplaceholder = this.placeHolderText;
    }

    if (this.isSourceModeEnabled) {
      config.extraPlugins = `${config.extraPlugins},sourcedialog`;
      config.toolbar.push({
        name: 'mode',
        items: ['Sourcedialog'],
      });

      config.dialog_noConfirmCancel = true;
    }

    if (this.MAX_RESIZE_HEIGHT) {
      config.resize_maxHeight = this.MAX_RESIZE_HEIGHT;
    }

    if (this.MAX_RESIZE_WIDTH) {
      config.resize_maxWidth = this.MAX_RESIZE_WIDTH;
    }

    if (this.MIN_RESIZE_HEIGHT) {
      config.resize_minHeight = this.MIN_RESIZE_HEIGHT;
    }

    if (this.MIN_RESIZE_WIDTH) {
      config.resize_minWidth = this.MIN_RESIZE_WIDTH;
    }

    if (this.mentionsList.length) {
      config.mentions = this.getMentions();
    }

    return config;
  }

  getDoesBeginWith(name, matcher) {
    const index = this.isMentionsCaseSensitive
      ? name.indexOf(matcher)
      : name.toLowerCase().indexOf(matcher.toLowerCase());
    return index === 0;
  }

  getFeed(options, callback) {
    const mentionedName = options.query;
    const feed = this.mentionsList.reduce((feedData, mention) => {
      if (this.getDoesBeginWith(mention.name, mentionedName)) {
        feedData.push({
          ...mention,
          id: feedData.length,
        });
      }
      return feedData;
    }, []);

    feed.sort((a, b) => (a.name > b.name ? 1 : -1));
    return callback(feed);
  }

  getMentions() {
    return [
      {
        feed: this.getFeed,
        minChars: 0,
        marker: this.marker,
        caseSensitive: this.isMentionsCaseSensitive,
        itemTemplate:
          '<li data-id="{id}"><strong>{name}</strong> <br/> <i>{description}</i></li>',
      },
    ];
  }

  isInvalidFileType(fileType) {
    return !RegExp(/^.*\/(jpg|jpeg|png|gif)$/).test(fileType);
  }

  isInvalidFileSize(fileSize) {
    const megabits = fileSize / 1024 / 1024;
    return megabits > this.MAX_FILE_SIZE;
  }

  validateFile(file) {
    if (!this.imageUploadUrl) {
      return {
        errorMessage:
          'Upload stopped. Inline attachments are not currently supported',
        notificationMessage: 'Inline attachments are not currently supported',
      };
    }

    if (this.isInvalidFileType(file.type)) {
      return {
        errorMessage: `Upload stopped. Unsupported file type: ${file.type}`,
        notificationMessage:
          'This file type is currently unsupported for inline attachments.',
      };
    }

    if (this.isInvalidFileSize(file.size)) {
      return {
        errorMessage: `Upload stopped. Large file: ${file.size}MB`,
        notificationMessage: `This file exceeds the ${this.MAX_FILE_SIZE}MB size limit`,
      };
    }
    return {};
  }

  handlePaste(event) {
    const fileCount = event.data.dataTransfer.getFilesCount();
    for (let i = 0; i < fileCount; i++) {
      const file = event.data.dataTransfer.getFile(i);
      const { notificationMessage, errorMessage } = this.validateFile(file);

      if (notificationMessage && errorMessage) {
        event.editor.showNotification(notificationMessage, 'info', 3000);
        event.stop();
        event.cancel();
        console.error(errorMessage);
      }
    }
  }

  async deleteRemovedFileFromServer(deleteToken) {
    const url = `${this.imageUploadUrl}/${deleteToken}`;
    const method = 'delete';
    const headers = new Headers({ Authorization: `Bearer ${this.authToken}` });
    try {
      await fetch(url, { method, headers });
    } catch (e) {
      console.error(e);
    }
  }

  deleteFilesRemovedFromContent(content = '') {
    const uploadedFilesInContent = this.filesUploaded.reduce(
      (existingFiles, uploadedFile) => {
        const { url, deleteToken } = uploadedFile;
        !content.includes(url)
          ? this.deleteRemovedFileFromServer(deleteToken)
          : existingFiles.push(uploadedFile);
        return existingFiles;
      },
      []
    );

    if (uploadedFilesInContent.length !== this.filesUploaded.length) {
      this.filesUploaded = uploadedFilesInContent;
    }
  }

  handleChange({ editor }) {
    const editorContent = editor.getData();

    if (this.imageUploadUrl && this.filesUploaded.length > 0) {
      this.deleteFilesRemovedFromContent(editorContent);
    }
  }

  handleBlur({ editor }) {
    if (this.onBlur) {
      const editorContent = editor.getData();
      this.onBlur(editorContent);
    }
  }

  fileUploadResponse(event) {
    event.stop();
    const {
      data: {
        fileLoader: { xhr },
      },
    } = event;
    const { attachments } = JSON.parse(xhr.response);
    const { url, delete_token } = attachments[0];
    event.data.url = url;
    this.filesUploaded.push({ url, deleteToken: delete_token });
  }

  notificationShow(event) {
    if (event.data.notification.type === 'warning') {
      event.data.notification.duration = 3000;
    }
  }

  dialogShow(event) {
    if (event.data.definition.title === 'Image Properties') {
      event.stop();
      event.cancel();
      event.data.hide();
    }
  }
}
