import React from 'react';

import { InputCheckbox } from '@knockrentals/knock-shared-web';
import { NotificationService } from '../../../../../../Components/Notifications';
import SourcesAPI from '../../../../SourcesAPI';
import { SAVED_NOTIFICATION } from '../../../../../../constants';

export const getErrorMessage = (isEnabled) =>
  `Unable to ${isEnabled ? 'enable' : 'disable'} property`;

const CompanySourceEnable = ({ property, updateSourceProperty }) => {
  const handleOnChange = async (event) => {
    const isEnabled = event.target.checked;
    updateSourceProperty({ isEnabled });
    try {
      await SourcesAPI.updateCompanySourceProperty(
        property.marketingSourceId,
        property.leasingTeamId,
        { isEnabled }
      );
      NotificationService.notify(SAVED_NOTIFICATION);
    } catch (_e) {
      NotificationService.notify(getErrorMessage(isEnabled));
      updateSourceProperty({ isEnabled: !isEnabled });
    }
  };

  return (
    <InputCheckbox
      color="primary"
      checked={property.isEnabled}
      onChange={handleOnChange}
    />
  );
};

export default CompanySourceEnable;
