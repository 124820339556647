import { NamedColors, makeStyles } from '@knockrentals/knock-shared-web';

export const useStyles = makeStyles(() => ({
  aiTemplateCardContent: {
    flexGrow: 1,
    background: '#F7F7F8',
    overflowY: 'auto',
    padding: '26px 40px 40px',
    '& .cke_chrome': {
      border: `2px solid ${NamedColors.slate[300]}`,
    },
    '& .error': {
      '& .cke_chrome': {
        borderColor: '#F599A8',
      },
    },
  },
  textLabel: {
    marginLeft: '5px',
    marginBottom: '3px',
    wordBreak: 'break-word',
  },
  emailErrorText: {
    marginTop: '3px',
    marginLeft: '5px',
  },
}));
