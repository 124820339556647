import { apiRemote } from '../../Services/RemoteService';
import queryString from 'query-string';

class PricingAPI {
  static getPropertiesPricing() {
    return apiRemote
      .get(`admin/pricing`)
      .then(response => response.json())
      .catch(e => e);
  }

  static getPropertyPricing(propertyIds) {
    const queryParams = propertyIds ? queryString.stringify({ pid: propertyIds }) : '';

    return apiRemote
      .get(`admin/pricing/properties?${queryParams}`)
      .then(response => response.json())
      .catch(e => e);
  }

  static updatePropertyPricing(propertyId, vendorPropertyId, updatedPreferences) {
    return apiRemote
      .put(`admin/pricing/${propertyId}/integrations/${vendorPropertyId}`, updatedPreferences)
      .then(response => response.json());
  }

  static getPropertyFloorplans(propertyId, vendorPropertyId) {
    return apiRemote
      .get(`admin/pricing/${propertyId}/floorplans?vendorPropertyId=${vendorPropertyId}`)
      .then(response => response.json())
      .catch(e => e);
  }

  static syncPmsProspects(vendor, propertyId, startingDays) {
    return apiRemote
      .post(`admin/pricing/${propertyId}/${vendor}/sync-prospects`, {
        starting_days: startingDays,
      })
      .then(response => response.json())
      .catch(e => e);
  }
}

export default PricingAPI;
