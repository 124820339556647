import React, { useState, useRef } from 'react';

import { Text, ThemeProvider } from '@knockrentals/knock-shared-web';
import { useFeatureFlagContext } from '../../../Context/FeatureFlagContext';
import { DrawerView } from './DrawerView';
import DripCampaignTabs from './DripCampaignTabs';
import { DripCampaignViews } from './DripCampaignViews';
import FeatureDrawer from '../common/FeatureDrawer';
import CreateMenuButton from './CreateMenuButton';
import { VIEWS } from './constants';
import './_DripCampaignPage.scss';

export const DRIP_CAMPAIGN_TEXT = 'Drip Campaign';
export const DESCRIPTION =
  'Set up Campaigns and templates to efficiently communicate with your prospects automatically based on a customizable trigger event. This automated service can free-up the Agent To-Do list and add efficiency to the Prospect to Lease process. Once a prospect has an inbound communication (email/call) they will be added back to the To-Do list and the Drip Campaign will stop';

const DripCampaignPage = () => {
  const { isDripSmsEnabled } = useFeatureFlagContext();
  const [drawerData, setDrawerData] = useState(null);
  const [tabView, setTabView] = useState(VIEWS.CAMPAIGN);
  const [menuView, setMenuView] = useState(null);

  const reloadViewRef = useRef({});

  const reloadViewData = (view) => {
    reloadViewRef.current[view]();
  };

  const closeDrawer = (shouldReloadView) => {
    setMenuView(null);
    setDrawerData(null);
  };

  return (
    <ThemeProvider>
      <section className="drip-campaign-page">
        <div className="page-header">
          <Text variant="h6">{DRIP_CAMPAIGN_TEXT}</Text>
        </div>

        {isDripSmsEnabled && (
          <div className="menu-button">
            <CreateMenuButton
              setDrawerData={setDrawerData}
              setMenuView={setMenuView}
            />
          </div>
        )}

        <Text className="drip-campaign-page-description" color="textSecondary">
          {DESCRIPTION}
        </Text>
        <DripCampaignTabs setView={setTabView} view={tabView}>
          <DripCampaignViews
            setDrawerData={setDrawerData}
            reloadViewRef={reloadViewRef}
            view={tabView}
          />
        </DripCampaignTabs>
        <FeatureDrawer disableEnforceFocus isOpen={Boolean(drawerData)}>
          <DrawerView
            closeDrawer={closeDrawer}
            drawerData={drawerData}
            reloadViewData={reloadViewData}
            view={menuView || tabView}
          />
        </FeatureDrawer>
      </section>
    </ThemeProvider>
  );
};

export default DripCampaignPage;
