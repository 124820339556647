/**
 * safely retrieve values from deeply nested objects without having to
 * check for undefined values.
 * @param {Object} fn a function that wraps the unsafe behavior
 * @param {Object} defaulVal The default value returned
 */
export const getSafe = (fn, defaultVal = null) => {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
};
