import React, { Component } from 'react';

import { PageContainer, Toaster } from '@knockrentals/knock-react';
import AccessSettingsInput from './AccessSettingsInput';
import AccessAPI from './AccessAPI';
import './_access.scss';

class AccessPage extends Component {
  state = {
    isLoading: true,
    settings: {},
  };

  async componentDidMount() {
    AccessAPI.getCompanyAccessSettings().then(response => {
      this.setState({
        settings: response.access,
        isLoading: false,
      });
    });
  }

  onSettingChanged(service, field, role, isSelected) {
    const settings = this.state.settings;
    const accessSettingRoles = settings[service][field];
    const roleIsIncluded = accessSettingRoles.includes(role);

    if (isSelected && !roleIsIncluded) {
      accessSettingRoles.push(role);
    } else if (!isSelected && roleIsIncluded) {
      accessSettingRoles.splice(accessSettingRoles.indexOf(role), 1);
    }

    this.setState({ settings }, () => {
      AccessAPI.setCompanyAccessSettings(settings).then(() => {
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      });
    });
  }

  render() {
    return (
      <PageContainer className="access-settings-page-container" isLoading={this.state.isLoading}>
        <h1>Access</h1>

        <div className="knock-react-flex knock-react-flex-row">
          <table className="knock-react-table access-settings-table wire-frame-table">
            <thead>
              <tr>
                <td></td>
                <td className="access-settings-header">Ad Spend</td>
                <td className="access-settings-header">Attributes</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="access-settings-role">Admin</td>
                <td className="access-settings-select">
                  <AccessSettingsInput
                    service="analytics"
                    field="ad_spend_page"
                    accountRole="admin"
                    settings={this.state.settings}
                    onSelect={this.onSettingChanged.bind(this)}
                  />
                </td>
                  <td className="access-settings-select">
                    <AccessSettingsInput
                      service="settings"
                      field="attribute_page"
                      accountRole="admin"
                      settings={this.state.settings}
                      onSelect={this.onSettingChanged.bind(this)}
                    />
                  </td>
              </tr>

              <tr>
                <td className="access-settings-role">Agent</td>
                <td className="access-settings-select">
                  <AccessSettingsInput
                    service="analytics"
                    field="ad_spend_page"
                    accountRole="agent"
                    settings={this.state.settings}
                    onSelect={this.onSettingChanged.bind(this)}
                  />
                </td>
                <td className="access-settings-select"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </PageContainer>
    );
  }
}

export default AccessPage;
