import React, { Component } from 'react';
import { Modal, ModalContent, ModalHeader, ModalFooter } from '@knockrentals/knock-react';
import { Formik } from 'formik';
import { trimValue } from '../../../Utils';

import './_addIntegrationForm.scss';

class AddYardiIntegration extends Component {
  render() {
    return (
      <Modal className="add-integration-form">
        <ModalHeader>
          <h3>Add Yardi Integration</h3>
        </ModalHeader>
        <Formik
          initialValues={{
            username: '',
            password: '',
            service_url: '',
            database: '',
            platform: 'SQL Server',
            servername: '',
            yardi_property_id: '',
            managed_by_kip: false,
          }}
          validate={this.validate}
          onSubmit={this.submit.bind(this)}
        >
          {props => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <ModalContent>
                <div className="knock-react-flex">
                  <div>
                    <strong>Username: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.username}
                      name="username"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                      type="text"
                    />
                    {!!props.errors.username && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.username}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Password: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.password}
                      name="password"
                      type="password"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                  </div>
                  {!!props.errors.password && (
                    <span className="knock-react-form-hint knock-react-form-hint-error">
                      {props.errors.password}
                    </span>
                  )}
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Service URL: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.service_url}
                      name="service_url"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                      type="text"
                    />
                    {!!props.errors.service_url && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.service_url}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Server name: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.servername}
                      name="servername"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                      type="text"
                    />
                    {!!props.errors.servername && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.servername}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Database: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.database}
                      name="database"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                      type="text"
                    />
                    {!!props.errors.database && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.database}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Platform: </strong>
                  </div>
                  <div>
                    <select
                      value={props.values.platform}
                      onChange={props.handleChange}
                      name="platform"
                    >
                      <option value={'SQL Server'}>SQL Server</option>
                      <option value={'Oracle'}>Oracle</option>
                    </select>
                    {!!props.errors.platform && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.platform}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Yardi property ID: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.yardi_property_id}
                      name="yardi_property_id"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                      type="text"
                    />
                    {!!props.errors.yardi_property_id && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.yardi_property_id}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Manage with KIP: </strong>
                  </div>
                  <div>
                    <input
                      checked={props.values.managed_by_kip}
                      name="managed_by_kip"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                      type="checkbox"
                    />
                  </div>
                </div>
              </ModalContent>
              <ModalFooter>
                <button className="knock-react-button" type="submit">
                  Save
                </button>
                <button className="knock-react-button" onClick={this.cancel.bind(this)}>
                  Cancel
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  submit(values) {
    this.props.onAddIntegration({ ...values, vendor: 'yardi' });
  }

  cancel() {
    this.props.onCancelAddIntegration();
  }

  validate(values) {
    const errors = {};

    if (!values.username) {
      errors.username = 'Required';
    }

    if (!values.password) {
      errors.password = 'Required';
    }

    if (!values.service_url) {
      errors.service_url = 'Required';
    }

    if (!values.database) {
      errors.database = 'Required';
    }

    if (!values.platform) {
      errors.platform = 'Required';
    }

    if (!values.servername) {
      errors.servername = 'Required';
    }

    if (!values.yardi_property_id) {
      errors.yardi_property_id = 'Required';
    }

    return errors;
  }
}

export default AddYardiIntegration;
