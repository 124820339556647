import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@knockrentals/knock-shared-web';

import { IsModifiedCheckbox } from '../../IsModifiedCheckbox';
import CustomRequirement from './CustomRequirement';
import AddCustomRequirement from './AddCustomRequirement';
import { CUSTOM_REQUIREMENTS, PREFERENCES_TITLES } from '../../constants';
import './_CustomRequirements.scss';

const useStyles = makeStyles({
  title: {
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
});

export const getIsSameValue = (
  currentRequirements = [],
  initialRequirements = []
) => {
  const customRequirementsMap = [
    currentRequirements,
    initialRequirements,
  ].reduce((promptMap, requirements) => {
    requirements.forEach((requirement) => {
      const { prompt, desiredAnswer } = requirement;
      if (!promptMap[prompt]) {
        promptMap[prompt] = [];
      }
      promptMap[prompt].push(desiredAnswer);
    });
    return promptMap;
  }, {});

  const isSameValue = Object.values(customRequirementsMap).every(
    (requiredAnswers) =>
      requiredAnswers.length === 2 && requiredAnswers[0] === requiredAnswers[1]
  );

  return isSameValue;
};

const CustomRequirements = ({
  customRequirements = [],
  isDisabled,
  modifiedStatusMap,
  setRequirements,
  updateModifiedStatus,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const classes = useStyles();

  const deleteCustomRequirement = (prompt, desiredAnswer) => () => {
    setRequirements((prevState) => {
      const updatedCustomRequirements = prevState.customRequirements.filter(
        (requirement) => {
          const isDeletedRequirement =
            requirement.prompt === prompt &&
            requirement.desiredAnswer === desiredAnswer;
          return !isDeletedRequirement;
        }
      );

      return {
        ...prevState,
        customRequirements: updatedCustomRequirements,
      };
    });
  };

  const resetCustomRequirements = (customRequirements) => {
    setRequirements((prevState) => ({
      ...prevState,
      ...customRequirements,
    }));
  };

  const addCustomRequirement = (customRequirement) => {
    setRequirements((prevState) => {
      const previousCustomRequirements = prevState.customRequirements || [];
      return {
        ...prevState,
        customRequirements: [...previousCustomRequirements, customRequirement],
      };
    });
  };

  const saveCustomRequirement = (customRequirement) => {
    setIsAdding(false);
    addCustomRequirement(customRequirement);
  };

  const shouldShowNoCustom = !customRequirements.length && !isAdding;
  const disabledClass = isDisabled ? 'batch-disabled' : '';

  return (
    <List>
      <ListItem className={disabledClass}>
        <ListItemText
          className={classes.title}
          inset
          secondary={shouldShowNoCustom && 'No custom requirements'}
          secondaryTypographyProps={{ align: 'right' }}
        >
          <IsModifiedCheckbox
            customValueComparison={getIsSameValue}
            isModified={modifiedStatusMap[CUSTOM_REQUIREMENTS]}
            updateModifiedStatus={updateModifiedStatus}
            name={CUSTOM_REQUIREMENTS}
            value={customRequirements}
            resetPreference={resetCustomRequirements}
          />
          {PREFERENCES_TITLES[CUSTOM_REQUIREMENTS]}
        </ListItemText>

        {!isAdding && (
          <ListItemText
            className={disabledClass}
            primaryTypographyProps={{ align: 'right' }}
          >
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setIsAdding(true)}
            >
              Add
            </Button>
          </ListItemText>
        )}
      </ListItem>

      {isAdding && (
        <AddCustomRequirement
          cancel={() => setIsAdding(false)}
          customRequirements={customRequirements}
          saveCustomRequirement={saveCustomRequirement}
        />
      )}

      {customRequirements &&
        customRequirements.length > 0 &&
        customRequirements.map(({ prompt, desiredAnswer }) => (
          <CustomRequirement
            deleteCustomRequirement={deleteCustomRequirement(
              prompt,
              desiredAnswer
            )}
            desiredAnswer={desiredAnswer}
            key={`${prompt}${desiredAnswer}`}
            prompt={prompt}
          />
        ))}
    </List>
  );
};

CustomRequirements.propTypes = {
  customRequirements: PropTypes.arrayOf(
    PropTypes.shape({ prompt: PropTypes.string, desiredAnswer: PropTypes.bool })
  ),
  isDisabled: PropTypes.bool,
  modifiedStatusMap: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.bool)])
  ),
  setRequirements: PropTypes.func,
  updateModifiedStatus: PropTypes.func,
};

export default CustomRequirements;
