import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '@knockrentals/knock-react';
import { Formik } from 'formik';

import '../../_attributeInfo.scss';

class EditAttributeModal extends Component {
  render() {
    return (
      <Modal className="attribute-modal">
        <Formik
          initialValues={{
            attributeTypeValue: this.props.attribute.attribute_type_value || '',
          }}
          validate={this.validate}
          onSubmit={this.onConfirm.bind(this)}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <ModalHeader>
                <h2>Edit Attribute</h2>
              </ModalHeader>
              <ModalContent>
                <div className="knock-react-flex">
                  <div className="field-label">Property</div>
                  <div className="field-input">
                    <i className="fa fa-building" /> {this.props.property.name}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div className="field-label">Attribute Type</div>
                  <div className="field-input field-wrap">
                    <i className="fa fa-tags" />{' '}
                    {this.props.attributeType.attribute_type}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div className="field-label">Attribute Value</div>
                  <div className="field-input">
                    <input
                      name="attributeTypeValue"
                      type="text"
                      onChange={props.handleChange}
                      value={props.values.attributeTypeValue}
                      className="knock-react-input"
                    />
                    {props.errors.attributeTypeValue ? (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.attributeTypeValue}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div className="field-label">Suggestions</div>
                  <div className="field-input field-wrap">
                    {this.props.suggestions.length ? (
                      this.props.suggestions.map((suggestion, i) => {
                        return (
                          <a
                            key={`suggestion-${i}`}
                            className="mRight10"
                            onClick={this.loadSuggestion.bind(
                              this,
                              suggestion,
                              props
                            )}
                            href="#"
                          >
                            {suggestion}
                          </a>
                        );
                      })
                    ) : (
                      <em>None</em>
                    )}
                  </div>
                </div>
              </ModalContent>
              <ModalFooter>
                <button
                  className="btn-md btn-primary"
                  onClick={this.onCancel.bind(this)}
                  type="button"
                >
                  <i className="fa fa-lg fa-times-circle" /> Cancel
                </button>
                <button
                  className="btn-md btn-danger"
                  onClick={this.onDelete.bind(this)}
                  type="button"
                >
                  <i className="fa fa-lg fa-trash" /> Remove
                </button>
                <button className="btn-md btn-success" type="submit">
                  <i className="fa fa-lg fa-check-circle" /> Update Attribute
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  loadSuggestion(suggestion, formProps) {
    formProps.setFieldValue('attributeTypeValue', suggestion);
  }

  onConfirm(values) {
    this.props.updateAttribute(
      this.props.attribute.id,
      values.attributeTypeValue.trim()
    );
  }

  onDelete(event) {
    event.preventDefault();
    this.props.deleteAttribute(this.props.attribute.id);
  }

  onCancel() {
    this.props.toggleModal(false);
  }

  validate(values) {
    const errors = {};

    if (!values.attributeTypeValue.trim()) {
      errors.attributeTypeValue = 'Required';
    }

    return errors;
  }
}

export default EditAttributeModal;
