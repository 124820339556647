import { apiRemote } from '../../Services/RemoteService';

export const normalizeSettings = (settings) =>
  settings.reduce((normalizedSettings, settingsGroup) => {
    const { enable_property_referrals, ...partialSettings } = settingsGroup;

    const enable_cross_sell = enable_property_referrals;
    const normalizedSettingsGroup = {
      ...partialSettings,
      enable_cross_sell,
    };
    normalizedSettings.push(normalizedSettingsGroup);

    return normalizedSettings;
  }, []);

export const transformSettings = (settings) => {
  /***  express_sms_consent_override is not editable on the settings page ***/
  /***  but its presence in the update payload triggers a long running ***/
  /***  database process when in the payload ***/
  const {
    enable_cross_sell,
    // eslint-disable-next-line no-unused-vars
    express_sms_consent_override,
    ...partialSettings
  } = settings;
  return {
    ...partialSettings,
    enable_property_referrals: enable_cross_sell,
  };
};

class SettingsAPI {
  static getSettings() {
    return apiRemote
      .get(`admin/settings`)
      .then((response) => response.json())
      .then((responseBody) => ({
        ...responseBody,
        leasing_team_settings: normalizeSettings(
          responseBody['leasing_team_settings']
        ),
      }))
      .catch((e) => e);
  }

  static updateGroupSettings(groupId, updatedSettings) {
    const transformedSettings = transformSettings(updatedSettings);
    const payload = {
      ...transformedSettings,
      craigslist_template_id:
        transformedSettings.craigslist_settings.company_template_id,
      craigslist_reminder_frequency:
        transformedSettings.craigslist_settings.reminder_frequency,
    };
    return apiRemote
      .put(`admin/settings/${groupId}`, payload)
      .then((response) => response.json());
  }
}

export default SettingsAPI;
