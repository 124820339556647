import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Text,
} from '@knockrentals/knock-shared-web';

export const DIALOG_TITLES = {
  SAVE: 'Save Company QR',
  DELETE: 'Delete Company QR',
};

export const MESSAGES = {
  CREATE: 'Saving this QR will enable it for all properties.',
  EDIT: 'Saving this QR will apply the QR and enabled property changes.',
  DELETE: 'Deleting this QR will remove it from all properties.',
};

const ConfirmationDialog = ({
  isEditing,
  isOpen,
  isSaving,
  closeDialog,
  handleConfirmation,
}) => {
  const getMessage = () => {
    if (!isSaving) {
      return MESSAGES.DELETE;
    }
    return isEditing ? MESSAGES.EDIT : MESSAGES.CREATE;
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={closeDialog}>
      <DialogTitle disableTypography>
        <Text variant="subtitle1">
          {isSaving ? DIALOG_TITLES.SAVE : DIALOG_TITLES.DELETE}
        </Text>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>{getMessage()}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={closeDialog} variant="outlined">
          Cancel
        </Button>

        <Button color="primary" onClick={handleConfirmation}>
          {isSaving ? 'Save' : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
