import React, { Component } from 'react';
import SourcesAPI from '../SourcesAPI';
import { ModalHeader, ModalContent, ModalFooter, Modal, Alert } from '@knockrentals/knock-react';
import { Formik } from 'formik';

class SearchRelayNumbers extends Component {
  state = {
    availableNumbers: [],
    hasSearched: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.availableNumbers !== nextProps.sourcesToAddTracking) {
      this.setState({ availableNumbers: [] });
    }
  }

  render() {
    return (
      <Modal className="phone-relay-editor">
        <Formik
          onSubmit={this.onSearch.bind(this)}
          initialValues={{
            countryCode: 'US',
            areaCode: '',
            portedNumber: '',
          }}
          validate={this.validate}
        >
          {props => (
            <form
              onSubmit={props.handleSubmit}
              onKeyPress={this.pressEnterSearch.bind(this, props)}
              autoComplete="off"
            >
              <ModalHeader>
                <h3>Search relay numbers</h3>
              </ModalHeader>
              <ModalContent>
                <div className="knock-react-flex">
                  <div>
                    <strong>Country code:</strong>
                  </div>
                  <div>
                    <select
                      name="countryCode"
                      value={props.values.countryCode}
                      onChange={props.handleChange}
                    >
                      <option value={'US'}>U.S.</option>
                      <option value={'CA'}>Canada</option>
                    </select>
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Area code:</strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.areaCode}
                      maxLength="3"
                      name="areaCode"
                      placeholder="Area code"
                      onChange={props.handleChange}
                    />
                    {!!props.errors.areaCode && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.areaCode}
                      </span>
                    )}
                  </div>
                </div>
                {this.props.internal && (
                  <div className="knock-react-flex">
                    <div>
                      <strong>Ported Number:</strong>
                    </div>
                    <div>
                      <input
                        type="text"
                        className="knock-react-input"
                        value={props.values.portedNumber}
                        maxLength="7"
                        pattern="[0-9]*"
                        name="portedNumber"
                        placeholder="Ported Number"
                        onChange={props.handleChange}
                      />
                      {!!props.errors.portedNumber && (
                        <span className="knock-react-form-hint knock-react-form-hint-error">
                          {props.errors.portedNumber}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {this._getWarningMessage(props)}
              </ModalContent>
              <ModalFooter>
                <button className="knock-react-button" onClick={this.searchCancel.bind(this)}>
                  Cancel
                </button>
                <button className="knock-react-button" type="submit">
                  Search
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  onSearch(values) {
    const { portedNumber, countryCode, areaCode } = values;

    if (portedNumber.length === 0) {
      SourcesAPI.getAvailableNumber(countryCode, areaCode).then(response => {
        const availableNumbers = response.phone_numbers;
        this.setState({
          hasSearched: true,
          availableNumbers,
        });

        const sufficientAvailableNumbers =
          availableNumbers.length !== 0 &&
          availableNumbers.length >= this.props.sourcesToAddTracking.length;

        if (sufficientAvailableNumbers) {
          this.props.callbackFromParent(availableNumbers, countryCode);
        }
      });
    } else {
      SourcesAPI.getPortedNumber(countryCode, areaCode, portedNumber)
        .then(response => {
          const availableNumbers = response.phone_numbers || [];
          this.setState({
            availableNumbers,
            hasSearched: true,
          });

          if (availableNumbers.length === this.props.sourcesToAddTracking.length) {
            this.props.callbackFromParent(availableNumbers, countryCode);
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  //Prevent the enter key from bubbling up and closing the form
  pressEnterSearch(event, formProps) {
    if (event.charCode === 13) {
      event.stopPropagation();
      event.preventDefault();

      this.onSearch(formProps.values);
    }
  }

  searchCancel() {
    this.setState({ hasSearched: false });
    this.props.onCancel();
  }

  _getWarningMessage = formProps => {
    const searchForPortedNumber = this.props.internal && formProps.values.portedNumber.length === 7;
    const tooManySources =
      this.props.sourcesToAddTracking.length > this.state.availableNumbers.length;
    const noNumbersAvailable = this.state.availableNumbers.length === 0;

    if (!this.state.hasSearched) {
      return null;
    }

    if (searchForPortedNumber && noNumbersAvailable) {
      return (
        <Alert>
          <h4>Ported number not found.</h4>
        </Alert>
      );
    }

    if (searchForPortedNumber && tooManySources) {
      return (
        <Alert>
          <h4>Ported number can only be saved to one source.</h4>
          <h4>Please cancel and select a single source.</h4>
        </Alert>
      );
    }

    if (!searchForPortedNumber && noNumbersAvailable) {
      return (
        <Alert>
          <h4>No numbers with area code {this.state.areaCode}.</h4>
          <h4>Please search a different area code.</h4>
        </Alert>
      );
    }

    if (!searchForPortedNumber && tooManySources) {
      return (
        <Alert>
          <h4>Not enough matching numbers with area code {this.state.areaCode}. </h4>
          {this.state.availableNumbers.length === 1 ? (
            <h4>Please search a different area code or cancel and only select one.</h4>
          ) : (
            <h4>
              Please search a different area code or cancel and only select{' '}
              {this.state.availableNumbers.length} sources.
            </h4>
          )}
        </Alert>
      );
    }

    return null;
  };

  validate = (values) => {
    const errors = {};

    if (!values.areaCode) {
      errors.areaCode = 'Required';
    }

    if (values.areaCode.length !== 3 || !/[0-9]{3}/.test(values.areaCode)) {
      errors.areaCode = 'Invalid area code';
    }

    return errors;
  }
}

export default SearchRelayNumbers;
