import React  from 'react';
import { LoadingOverlay, Text, ThemeProvider } from '@knockrentals/knock-shared-web';

import { ErrorDialog } from '../common/ErrorDialog';
import { EditButton } from './EditButton';
import { PropertiesCallIntelTable } from './PropertiesCallIntelTable/PropertiesCallIntelTable';
import { useCallIntel } from './useCallIntel';
import FeatureDrawer from '../common/FeatureDrawer';
import { BulkPropertiesUpdateDrawer } from './BulkPropertiesUpdateDrawer/BulkPropertiesUpdateDrawer';

import './_CallIntelPage.scss';

export const DESCRIPTION = `Manage settings for transcribing and annotating prospect calls and agent responses. Transcriptions are direct text version of the spoken conversation. Annotations are feedback to help identify weak points in the model\u2019s performance. Standalone is only used for customers who are not using Knock as their CRM.`;

export const getSelectedPropertiesCount = (selectedProperties) => {
  return Object.keys(selectedProperties).reduce((acc, propertyId) => {
    if (selectedProperties[propertyId].isSelected) {
      acc++;
    }
    return acc;
  }, 0);
};

export const areFeatureObjectsEqual = (features1, features2) => {
  for (let key of Object.keys(features1)) {
    if (features1[key] !== features2[key]) return false;
  }
  return true;
};

export const doSelectedPropertiesHaveSameFeatures = (selectedPropertiesMap, propertiesCallIntelData) => {
  if (!Object.keys(selectedPropertiesMap).length) return true;

  let featuresMap = {};
  return propertiesCallIntelData.every(propertyData => {
    const { propertyName, propertyId, ...features } = propertyData;
    if (selectedPropertiesMap[propertyData.propertyId].isSelected) {
      if (!Object.keys(featuresMap).length) featuresMap = { ...features };

      return areFeatureObjectsEqual(featuresMap, features);
    }

    return true;
  });
};

const CallIntelPage = () => {
  const { 
    handleUpdateProperties,
    editDrawerOpened,
    errorMessage,
    isLoading,
    isUpdatingFeatureSwitch,
    onUpdateTableValue,
    propertiesCallIntelData,
    selectedProperties,
    setEditDrawerOpened,
    setErrorMessage,
    setSelectedProperties,
  } = useCallIntel();

  const handleCloseErrorDialog = () => {
    setErrorMessage('');
  };


  return (
    <ThemeProvider>
      <section className="call-intel-page">
        <Text variant="h6">Call Intelligence</Text>

        <div className="call-intel-page-description">
          <Text variant="body1">{DESCRIPTION}</Text>
        </div>

        <LoadingOverlay open={isLoading} />

        <ErrorDialog
          closeDialog={handleCloseErrorDialog}
          errorMessage={errorMessage}
          isOpen={Boolean(errorMessage)}
        />

        <EditButton onClick={() => setEditDrawerOpened(true)} count={getSelectedPropertiesCount(selectedProperties)} />

        <PropertiesCallIntelTable 
          isUpdatingFeatureSwitch={isUpdatingFeatureSwitch}
          onUpdateTableValue={onUpdateTableValue}
          propertiesCallIntelData={propertiesCallIntelData}
          selectedProperties={selectedProperties}
          setSelectedProperties={setSelectedProperties}
        />
      </section>

      <FeatureDrawer disableEnforceFocus isOpen={editDrawerOpened} onClose={() => setEditDrawerOpened(false)}>
        <BulkPropertiesUpdateDrawer
          closeDrawer={() => setEditDrawerOpened(false)}
          handleUpdateProperties={handleUpdateProperties}
          isLoading={isLoading}
          selectedProperties={selectedProperties}
          selectedPropertiesHaveSameFeatures={doSelectedPropertiesHaveSameFeatures(selectedProperties, propertiesCallIntelData)}
        />
      </FeatureDrawer>
    </ThemeProvider>
  );
};

export default CallIntelPage;