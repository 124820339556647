import React, { Component } from 'react';
import moment from 'moment';
import { Modal, ModalContent, ModalHeader, ModalFooter } from '@knockrentals/knock-react';

import SourcesAPI from './SourcesAPI';

class PropertySourceCosts extends Component {
  state = {
    isLoading: true,
    fees: [],
  };

  _currentMoment = moment().startOf('month');

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.source.propertySourceId !== prevProps.source.propertySourceId) {
      this.loadData();
    }
  }

  loadData() {
    this.setState({ isLoading: true });

    SourcesAPI.getPropertySourcesCosts(this.props.property.id, this.props.source.id).then(data => {
      const fees = data.monthly_property_source_costs.map(monthlyData => {
        return {
          cost: monthlyData.cost,
          type: monthlyData.pricing_model || 'None',
          year: monthlyData.year,
          month: monthlyData.month,
          isPerpetual: monthlyData.is_perpetual
        };
      });

      this.setState({ 
        fees, 
        originalFees: JSON.parse(JSON.stringify(fees)),
        isLoading: false
      });

    });
  }

  render() {
    return (
      <Modal className="property-source-costs" onBackdropClicked={this.props.onCancel}>
        <ModalHeader>
          <h3>{this.props.source.title}'s Fees</h3>
        </ModalHeader>

        <ModalContent>
          <table className="wire-frame-table">
            <thead>
              <tr>
                <th>Month</th>
                <th>Cost type</th>
                <th>Cost amount</th>
                <th />
              </tr>
            </thead>

            <tbody>{this.renderCosts()}</tbody>
          </table>
          <div>
            <small>
              <i className="fa fa-fast-forward fa-fw" /> Applies perpetually to all future months rolling forward
            </small>
          </div>
        </ModalContent>

        <ModalFooter>
          <button className="knock-react-button" onClick={this.props.onCancel}>
            Cancel
          </button>
          <button className="knock-react-button" 
                  disabled={this.state.isLoading}
                  onClick={this.saveCosts.bind(this)}>
            Save
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  renderCosts() {
    const feeTypeOptions = this.props.feeTypes.map((feeType, index) => {
      return (
        <option key={index} value={feeType.value}>
          {feeType.label}
        </option>
      );
    });

    const getFeeTypeChangeHandler = feeIndex => {
      return event => {
        const updatedFees = this.state.fees.slice();
        updatedFees[feeIndex].type = event.target.value;
        updatedFees[feeIndex].cost = updatedFees[feeIndex].cost || 0;

        this.setState({ fees: updatedFees });
      };
    };

    const getFeeCostChangeHandler = feeIndex => {
      return event => {
        const updatedFees = this.state.fees.slice();
        updatedFees[feeIndex].cost = parseInt(event.target.value, 10) || 0;

        this.setState({ fees: updatedFees });
      };
    };

    return this.state.fees.map((fee, index) => {
      const adjustedNow = moment()
        .month(fee.month - 1)
        .year(fee.year)
        .startOf('month');

      return (
        <tr key={index}>
          <td>
            <span>{adjustedNow.format('MMM YYYY')} </span>
            { fee.isPerpetual ? (
              <span> 
                <i className="fa fa-fast-forward fa-fw" 
                   title="This ad-spend pricing will be applied perpetually to all future months rolling forward" />
               </span>
            ) : null }
            { (fee.isPerpetual && this._currentMoment.isAfter(adjustedNow)) ? (
              <div>
                <small>Up to {this._currentMoment.format('MMM YYYY')} and beyond</small>
              </div>
            ) : null }
          </td>
          <td>
            <select value={fee.type} onChange={getFeeTypeChangeHandler(index)}>
              {feeTypeOptions}
            </select>
          </td>
          <td>
            {fee.type !== 'None' ? (
              <input
                className="knock-react-input"
                type="number"
                value={fee.cost}
                onChange={getFeeCostChangeHandler(index)}
              />
            ) : null}
          </td>
          <td>
            {index !== this.state.fees.length - 1 ? (
              <button
                className="knock-react-button"
                disabled={fee.type === 'None'}
                onClick={this.applyToFuture.bind(this, fee, index)}
              >
                Apply to future
              </button>
            ) : (
              <div>
                <button className="knock-react-button btn-success btn-sm" onClick={this.addFutureMonth.bind(this)}>Next month</button>
                { (this._currentMoment.isAfter(adjustedNow)) ? (
                  <div style={{ paddingTop: '2px' }}>
                    <button className="knock-react-button btn-success btn-sm" onClick={this.addFutureMonthToCurrent.bind(this)}>To current month</button>
                  </div>
                ) : null }
              </div>
            )}
          </td>
        </tr>
      );
    });
  }

  saveCosts() {
    const changedCosts = [];

    this.state.fees.forEach((fee, index) => {
      const feeHasChanged =
        fee.cost !== this.state.originalFees[index].cost ||
        fee.type !== this.state.originalFees[index].type;

      if (feeHasChanged) {
        changedCosts.push(fee);
      }
    });

    this.setState({ isLoading: true });
    this.props.onCostsChanged(changedCosts, this.loadData.bind(this));
  }

  applyToFuture(fee, index) {
    const feesCopy = this.state.fees.slice();

    for (let i = index; i < feesCopy.length; i++) {
      feesCopy[i].cost = fee.cost;
      feesCopy[i].type = fee.type;
    }

    this.setState({ fees: feesCopy });
  }

  addFutureMonthToCurrent() {
    let lastFee = this.state.fees[this.state.fees.length - 1];
    let lastFeeMoment =  moment().month(lastFee.month - 1).year(lastFee.year);

    while (this._currentMoment.isAfter(lastFeeMoment)) {
      this.addFutureMonth();

      lastFee = this.state.fees[this.state.fees.length - 1];
      lastFeeMoment =  moment().month(lastFee.month - 1).year(lastFee.year);
    }
  }

  addFutureMonth() {
    const lastFee = this.state.fees[this.state.fees.length - 1];
    
    const nextMonth = moment()
      .month(lastFee.month - 1)
      .year(lastFee.year)
      .add(1, 'month');

    const nextFee = JSON.parse(JSON.stringify(lastFee));
    nextFee.month = parseInt(nextMonth.format('MM'), 10);
    nextFee.year = parseInt(nextMonth.format('YYYY'), 10);
    nextFee.isPerpetual = false;

    const originalNextFee = JSON.parse(JSON.stringify(nextFee));
    originalNextFee.cost = null;
    originalNextFee.type = null;

    const fees = this.state.fees;
    fees.push(nextFee);

    const originalFees = this.state.originalFees;
    originalFees.push(originalNextFee);

    this.setState({ fees, originalFees });
  }
}

export default PropertySourceCosts;
