import { getDefaultTableOptions } from '../../../../Services/SharedService';

const defaultTableOptions = getDefaultTableOptions();

export const getTableOptions = (pageSize, pageSizeOptions) => {
  return {
    ...defaultTableOptions,
    actionsColumnIndex: -1,
    emptyRowsWhenPaging: false,
    headerStyle: {
      ...defaultTableOptions.headerStyle,
      fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
      lineHeight: '24px',
      padding: '16px 24px 16px 16px',
      whiteSpace: 'nowrap',
    },
    maxBodyHeight: '65vh',
    minBodyHeight: '65vh',
    paging: true,
    pageSize,
    pageSizeOptions,
    rowStyle: {
      minWidth: '1048px',
    },
    search: false,
    showFirstLastPageButtons: false,
    sorting: true,
    toolbar: false,
  };
};
