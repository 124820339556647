import React, { useState } from 'react';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import {
  IconButton,
  Tooltip,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import FeatureDrawer from '../../common/FeatureDrawer';
import CompanyTemplatesInfo from './CompanyTemplatesInfo';
import './_CompanyTemplatesTooltip.scss';

export const ARIA_LABEL = 'company templates tooltip button';
export const TOOLTIP_TITLE = 'Company Templates Information';

const useStyles = makeStyles({
  tooltipButton: {
    color: '#5C5C5C',
    verticalAlign: 'middle',
  },
  companyTemplatesTooltip: {
    '& .MuiTooltip-tooltip': {
      fontSize: '10px',
      lineHeight: '14px',
      padding: '4px 8px',

      '& .MuiTooltip-arrow': {
        bottom: 0,
        top: 0,
        marginTop: '-0.71em',
        marginLeft: 4,
        marginRight: 4,
        '&::before': {
          transformOrigin: '0 100%',
        },
      },
    },
  },
});

const CompanyTemplatesTooltip = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const classes = useStyles();

  const handleIsDrawerOpen = (isOpen) => () => {
    setIsDrawerOpen(isOpen);
  };

  return (
    <React.Fragment>
      <Tooltip
        arrow
        PopperProps={{
          className: classes.companyTemplatesTooltip,
        }}
        title={TOOLTIP_TITLE}
      >
        <span>
          <IconButton
            aria-label={ARIA_LABEL}
            className={classes.tooltipButton}
            disableRipple
            onClick={handleIsDrawerOpen(true)}
            color="secondary"
          >
            <HelpOutlineOutlinedIcon />
          </IconButton>
        </span>
      </Tooltip>

      <FeatureDrawer isOpen={isDrawerOpen}>
        <CompanyTemplatesInfo closeDrawer={handleIsDrawerOpen(false)} />
      </FeatureDrawer>
    </React.Fragment>
  );
};

export default CompanyTemplatesTooltip;
