import React from 'react';
import PropTypes from 'prop-types';

import {
  AI_COMPANY_TEMPLATE,
  AI_PERSONALIZED_RESPONSE_CUSTOM_TAG,
  AI_TEMPLATE_TYPES,
  CUSTOM_MERGE_TAG_FIELD_ID,
  MERGE_TAG_LIST_OF_URLS,
} from '../AiConstants';
import { EmailTemplateEditor } from '../../../../../EmailTemplateEditor';

export const AiEmailTemplateEditor = ({
  handleOnChangeMessage,
  mergeTags,
  message,
  setEditorInited,
  templateType,
}) => {
  const handleMergeTagChange = function () {
    const dialog = this.getDialog();
    const urlField = dialog.getContentElement('info', 'url');
    const protocolField = dialog.getContentElement('info', 'protocol');
    const urlInput = document
      .getElementById(urlField.domId)
      .querySelector('input');
    const protocolSelect = document
      .getElementById(protocolField.domId)
      .querySelector('select');

    if (!protocolSelect || !urlInput) {
      console.error(
        `Protocol field or url field were not found by ids: ${urlField.domId} and ${protocolField.domId}`
      );
      return;
    }

    const newMergeTagValue = this.getValue();
    if (newMergeTagValue) {
      urlField.setValue(newMergeTagValue);
      protocolField.setValue('');

      urlInput.disabled = true;
      protocolSelect.disabled = true;
    } else {
      urlField.setValue('');

      urlInput.disabled = false;
      protocolSelect.disabled = false;
    }
  };

  const handleLinkTypeChange = function (event) {
    const linkTypeValue = event.data.value;
    const dialog = this.getDialog();
    const mergeTagField = dialog
      .getContentElement('info', CUSTOM_MERGE_TAG_FIELD_ID)
      .getElement()
      .getParent()
      .getParent();

    linkTypeValue === 'url' ? mergeTagField.show() : mergeTagField.hide();
  };

  const updateViewofCkeEditorLinkModal = (ev) => {
    const dialogName = ev.data.name;
    const dialogDefinition = ev.data.definition;

    if (dialogName === 'link') {
      const selectItems = [
        ['', ''],
        ...MERGE_TAG_LIST_OF_URLS.map((mergeTag) => [`#${mergeTag}`, mergeTag]),
      ];
      const mergeTagField = {
        onChange: handleMergeTagChange,
        type: 'select',
        style: 'margin-bottom: 10px',
        width: '100%',
        items: selectItems,
        id: CUSTOM_MERGE_TAG_FIELD_ID,
        label: 'Merge tag',
        setup: function (data) {
          const dialog = this.getDialog();
          const linkField = dialog.getContentElement('info', 'linkType');

          linkField.on('change', handleLinkTypeChange);

          const urlValueIsMergeTag = MERGE_TAG_LIST_OF_URLS.includes(
            data?.url?.url
          );
          if (urlValueIsMergeTag) {
            this.setValue(data?.url?.url);
          }
        },
      };
      const infoTab = dialogDefinition.getContents('info');
      if (!infoTab.get(CUSTOM_MERGE_TAG_FIELD_ID)) infoTab.add(mergeTagField);
    }
  };

  const handleEditorInstanceReady = () => {
    setEditorInited(true);
  };

  return (
    <EmailTemplateEditor
      content={message}
      mergeTags={
        templateType === AI_TEMPLATE_TYPES.AI_POWERED_WEBFORM
          ? [...mergeTags, AI_PERSONALIZED_RESPONSE_CUSTOM_TAG]
          : mergeTags
      }
      minResizeWidth={'100%'}
      name={AI_COMPANY_TEMPLATE.MESSAGE}
      onChange={handleOnChangeMessage}
      onDialogDefinition={updateViewofCkeEditorLinkModal}
      onInstanceReady={handleEditorInstanceReady}
    />
  );
};

AiEmailTemplateEditor.propTypes = {
  handleOnChangeMessage: PropTypes.func,
  message: PropTypes.string,
  mergeTags: PropTypes.array,
  setEditorInited: PropTypes.func,
  templateType: PropTypes.string,
};
