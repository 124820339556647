import { AuthenticationService } from '@knockrentals/knock-react';
import { mlRemote } from "../../../../Services/RemoteService";

export const getCompanyId = () => {
  const tokenPayload = AuthenticationService.getTokenPayload() || {};
  const { company_id: companyId } = tokenPayload.sub || {};

  return companyId;
};

export const getCallIntelData = async (company_id) => {
  try {
    const response = await mlRemote.get(`call-intel/companies/${company_id}/properties`);

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const callIntelData = await response.json();

    return callIntelData;
  } catch (error) {
    throw new Error('We are unable to manage call intel at this time.');
  }
};

export const updatePropertyCallIntelData = async (payload, property_id) => {
  try {
    const response = await mlRemote.patch(`call-intel/properties/${property_id}`, payload);

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    return response;
  } catch (error) {
    throw new Error('We are unable to update call intel at this time.');
  }
};

export const updatePropertiesCallIntelData = async (payload) => {
  try {
    const response = await mlRemote.patch(`call-intel/properties`, payload);

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    return response;
  } catch (error) {
    throw new Error('We are unable to update properties call intel at this time.');
  }
};
