import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from '../AppContext';
import { featureFlags } from '../../Services/FeatureFlagService';

import {
  getFeatureFlag,
  getInitialFeatureFlags,
  initializeFeatureFlagService,
} from './utils';

const { NEW_UNITS, QUOTE_UPCHARGES } = featureFlags;

const initialFeatureFlags = {
  isAdminPasswordResetEnabled: false,
  isAiEmailGenAiAdminEnabled: false,
  isBatchSourceCostEnabled: false,
  isBrandContentEnabled: null,
  isCallIntelStandaloneEnabled: false,
  isCompanySourceListEnabled: null,
  isCompanyTemplatesEnabled: null,
  isCompanyTemplatesPreviewEnabled: null,
  isCompanyTemplatesSelfGuidedToursEnabled: null,
  isCompanyTemplatesWinbackEnabled: null,
  isConfigurableStaleTriggerEnabled: null,
  isDoorwayDniEnabled: false,
  isDripSmsEnabled: null,
  isEnhancedAccessEnabled: null,
  isEnhancedBrandContentEnabled: null,
  isOSMEnabled: false,
  isSSDAPageEnabled: false,
  isShowNewVAConfigurationUiEnabled: false,
  isSourcesMarketingSourceCostEnabled: false,
  isToursSiteMapEnabled: null,
  isUseCookieForTokenEnabled: false,
  isUserDeleteDisabled: null,
  newUnitsFlags: {},
  quoteUpchargesFlags: {},
};

export const defaultFeatureFlagContext = {
  ...initialFeatureFlags,
  updateNewUnitsFlags: () => {},
  updateQuoteUpchargesFlags: () => {},
};

export const FeatureFlagContext = createContext(defaultFeatureFlagContext);

export const FeatureFlagProvider = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState(initialFeatureFlags);
  const { companyId, setIsReady } = useAppContext();

  const setInitialFeatureFlags = useCallback(async () => {
    await initializeFeatureFlagService(companyId, companyId);

    const flagValues = await getInitialFeatureFlags();

    setFeatureFlags((prevState) => ({
      ...prevState,
      ...flagValues,
    }));

    setIsReady(true);
  }, [companyId, setFeatureFlags, setIsReady]);

  useEffect(() => {
    if (companyId) {
      setInitialFeatureFlags();
    }
  }, [companyId, setInitialFeatureFlags]);

  const updateScopedFeatureFlag = async (scope, featureFlagName) => {
    await initializeFeatureFlagService(scope, companyId);

    const [flagKey, flagValue] = await getFeatureFlag(featureFlagName);

    setFeatureFlags((prevState) => ({
      ...prevState,
      [flagKey]: {
        ...prevState[flagKey],
        [scope]: flagValue,
      },
    }));
  };

  const updateNewUnitsFlags = (leasingTeamId) => {
    updateScopedFeatureFlag(leasingTeamId, NEW_UNITS);
  };

  const updateQuoteUpchargesFlags = (leasingTeamId) => {
    updateScopedFeatureFlag(leasingTeamId, QUOTE_UPCHARGES);
  };

  const featureFlagContextValue = {
    ...featureFlags,
    updateNewUnitsFlags,
    updateQuoteUpchargesFlags,
  };

  return (
    <FeatureFlagContext.Provider value={featureFlagContextValue}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

FeatureFlagProvider.propTypes = {
  children: PropTypes.node,
};

export const useFeatureFlagContext = () => useContext(FeatureFlagContext);
