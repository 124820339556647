export const ACTION_TYPES = {
  DELETE: 'delete',
  SAVE: 'save',
};

export const CHAR_LIMITS = {
  long: 10000,
  medium: 1500,
  quickReplySms: 320,
  short: 140,
};

export const QUICK_REPLY = {
  EMAIL: 'email',
  SMS: 'sms',
  TITLE: 'title',
  PROPERTIES: 'properties',
};
