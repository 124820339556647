import React from 'react';
import { InputCheckbox } from '@knockrentals/knock-shared-web';
import { ACCESS_ROLE_LABELS, ACCESS_ROLES } from '../../../constants';

export const getTableColumns = (isFullAccessView, updateSetting) =>
  [
    {
      align: 'center',
      cellStyle: { backgroundColor: '#f1f1f1' },
      headerStyle: { backgroundColor: 'unset' },
      title: 'Setting',
      render: (rowData) => rowData.label,
      width: '100px',
    },
    {
      align: 'center',
      title: ACCESS_ROLE_LABELS[ACCESS_ROLES.ADMIN],
      render: (rowData) =>
        rowData.configurableRoles[ACCESS_ROLES.ADMIN] ? (
          <InputCheckbox
            aria-label={`${rowData.label} ${
              ACCESS_ROLE_LABELS[ACCESS_ROLES.ADMIN]
            } checkbox`}
            checked={rowData.configurableRoles[ACCESS_ROLES.ADMIN].isChecked}
            color="primary"
            onChange={updateSetting(rowData, ACCESS_ROLES.ADMIN)}
          />
        ) : null,
      width: '100px',
    },
    {
      align: 'center',
      title: ACCESS_ROLE_LABELS[ACCESS_ROLES.AGENT],
      render: (rowData) =>
        rowData.configurableRoles.agent ? (
          <InputCheckbox
            aria-label={`${rowData.label} ${
              ACCESS_ROLE_LABELS[ACCESS_ROLES.AGENT]
            } checkbox`}
            checked={rowData.configurableRoles[ACCESS_ROLES.AGENT].isChecked}
            color="primary"
            onChange={updateSetting(rowData, ACCESS_ROLES.AGENT)}
          />
        ) : null,
      width: '100px',
    },
    isFullAccessView && {
      align: 'center',
      title: ACCESS_ROLE_LABELS[ACCESS_ROLES.ANALYTICS],
      render: (rowData) =>
        rowData.configurableRoles[ACCESS_ROLES.ANALYTICS] ? (
          <InputCheckbox
            aria-label={`${rowData.label} ${
              ACCESS_ROLE_LABELS[ACCESS_ROLES.ANALYTICS]
            } checkbox`}
            checked={
              rowData.configurableRoles[ACCESS_ROLES.ANALYTICS].isChecked
            }
            color="primary"
            onChange={updateSetting(rowData, ACCESS_ROLES.ANALYTICS)}
          />
        ) : null,
      width: '100px',
    },
  ].filter((column) => column);
