export const getHousingTypes = () => {
  const housingTypes = [{
    label: '-- Select --',
    value: '0',
    disabled: true
  }];

  housingTypes.push({label: 'Conventional', value: 'Conventional', disabled: false});
  housingTypes.push({label: 'Conventional with Affordable', value: 'Conventional with Affordable', disabled: false});
  housingTypes.push({label: 'Affordable', value: 'Affordable', disabled: false});
  housingTypes.push({label: 'Student', value: 'Student', disabled: false});
  housingTypes.push({label: 'Senior', value: 'Senior', disabled: false});
  housingTypes.push({label: 'Military', value: 'Military', disabled: false});

  return housingTypes;
};

export const getPropertyClasses = () => {
  const propertyClasses = [{
    label: '-- Select --',
    value: '0',
    disabled: true
  }];

  propertyClasses.push({label: 'Class A', value: 'Class A', disabled: false});
  propertyClasses.push({label: 'Class B', value: 'Class B', disabled: false});
  propertyClasses.push({label: 'Class C', value: 'Class C', disabled: false});
  propertyClasses.push({label: 'Class D', value: 'Class D', disabled: false});

  return propertyClasses;
};

export const getValidYears = () => {
  const validYears = [];

  for (let i = 1900; i <= 2030; i++) {
    validYears.push({
      label: i.toString(),
      value: i
    });
  }

  return validYears;
};