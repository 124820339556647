import { apiRemote } from '../../Services/RemoteService';

class UtilitiesAPI {

  static forgetGuestcard(streamId) {
    return apiRemote
      .post(`admin/utilities/${streamId}/forget`);
  }

  static inheritResidentSmsConsentFromProspect(streamId) {
    return apiRemote
      .post(`admin/utilities/${streamId}/sms-consent-inherit`);
  }

}

export default UtilitiesAPI;
