import React, { useState } from 'react';
import { Menu, MenuItem, ThemeProvider } from '@material-ui/core';
import { VerticalNavbarLink } from '@knockrentals/knock-react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, DialogContent } from '@knockrentals/knock-shared-web';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  filterBox: {
    display: 'flex',
    position: 'center',
  },
  customMenuItem: {
    paddingRight: '100px !important',
  },
  filterHeader: {
    backgroundColor: '#f0f0f0',
    borderRadius: '20px',
    padding: '8px 10px 8px 15px',
    textDecoration: 'none',
    cursor: 'pointer',
    border: '1px solid white',
  },
  filterHeaderClicked: {
    border: '1px solid blue',
    borderRadius: '20px',
    backgroundColor: '#B0C4DE',
  },
  arrowIconUp: {
    transform: 'rotate(180deg)',
  },
}));

const FilterMulti = ({ onFilterChange, filterName, filterOptions }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [clicked, setClicked] = useState(false);
  const classes = useStyles();

  const getSelectedDisplay = () => {
    if (selectedFilters && selectedFilters.length) {
      const first = filterOptions.find((filter) => filter.value === selectedFilters[0]);
      return selectedFilters.length > 1
        ? `${filterName}: ${first.label} +${selectedFilters.length - 1}`
        : `${filterName}: ${first.label}`;
    }

    return `${filterName}: All`;
  };

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
    setClicked(true);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setClicked(false);
  };

  const handleFilterChange = (filterValue) => {
    setSelectedFilters((prevFilters) => {
      if (Array.isArray(prevFilters)) {
        if (prevFilters.includes(filterValue)) {
          const updatedFilters = prevFilters.length === 1 ? null : prevFilters.filter((f) => f !== filterValue);
          onFilterChange(updatedFilters);
          return updatedFilters;
        } else {
          const updatedFilters = [...prevFilters, filterValue];
          onFilterChange(updatedFilters);
          return updatedFilters;
        }
      } else {
        onFilterChange([filterValue]);
        return [filterValue];
      }
    });
  };

  return (
    <ThemeProvider>
      <div className={`${classes.filterBox}`}>
        <div
          className={`${classes.filterHeader} ${clicked ? classes.filterHeaderClicked : ''}`}
          onClick={handleOpenDropdown}
          style={{ color: 'black' }}
        >
          <VerticalNavbarLink>
            {getSelectedDisplay()}
            <i className={`${'fa fa-angle-down fa-fw support-dropdown-icon '}${clicked ? classes.arrowIconUp : ''}`}
            />
          </VerticalNavbarLink>
        </div>

        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleCloseDropdown}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{ marginTop: '40px' }}
        >
          <DialogContent dividers={false} style={{padding: '0'}}>
            <div style={{ maxHeight: '300px', overflowY: 'auto', padding: '0' }}>
              {filterOptions.map((filterOption, idx) => {
                return (
                  <MenuItem
                    key={idx}
                    onClick={() => handleFilterChange(filterOption.value)}
                    className={classes.customMenuItem}
                  >
                    <Checkbox color="primary" checked={selectedFilters ? selectedFilters.includes(filterOption.value) : false} /> {filterOption.label}
                  </MenuItem>
                );
              })}
            </div>
          </DialogContent>
        </Menu>
      </div>
    </ThemeProvider>
  );
};

FilterMulti.propTypes = {
  onFilterChange: PropTypes.func,
  filterName: PropTypes.string,
  filterOptions: PropTypes.arrayOf(PropTypes.object)
};

export default FilterMulti;