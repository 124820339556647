import React, { Component } from 'react';
import {
  ModalHeader,
  ModalContent,
  ModalFooter,
  PageContainer,
  Modal,
} from '@knockrentals/knock-react';

class DeleteGroupUserConfirm extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  render() {
    const { loading } = this.state.loading;
    const userToDelete = this.props.users.filter(
      user => user.user_id === this.props.deletingUserId
    )[0];
    const userToTransferTo = this.props.users.filter(
      user => user.user_id === this.props.transferToUserId
    )[0];

    return (
      <PageContainer isLoading={loading}>
        <Modal>
          <ModalHeader>
            <h2>
              {' '}
              Delete {userToDelete.cognito_username || userToDelete.username} and <br />
              transfer data to {userToTransferTo.cognito_username || userToTransferTo.username}{' '}
            </h2>
          </ModalHeader>

          <ModalContent>
            Are you sure you want to delete {userToDelete.cognito_username || userToDelete.username}
            ? <br />
            Deletion is permanent and cannot be undone.
          </ModalContent>

          <ModalFooter>
            <button
              className="knock-react-button"
              id="cancelDeleteGroupUser"
              onClick={this.props.onCancel}
            >
              Cancel
            </button>

            <button
              className="knock-react-button"
              id="backToTransferSelect"
              onClick={this.props.onBack}
            >
              Back
            </button>

            <button
              className="knock-react-button & danger"
              id="confirmDeleteGroupUser"
              onClick={this.props.onDelete}
              disabled={loading}
            >
              {loading && <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>}
              {!loading && <span>Transfer and Delete</span>}
            </button>
          </ModalFooter>
        </Modal>
      </PageContainer>
    );
  }
}

export default DeleteGroupUserConfirm;
