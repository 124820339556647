import React from 'react';
import './DraggableListRow.scss';

/**
 * The presentational element of the `DraggableListRow` component. Renders as `<tr>` element. Expects React component children to be `<td>` elements.
 *
 * @param {string} props.children An array of React components as `<td>` elements.
 * @param {boolean} props.areButtonsDeactivated Whether the user should be able to interact with the row's buttons
 * @param {boolean} props.isEditing Whether the user is currently editing this row of data
 * @param {string} props.rowClassName A CSS class name to be applied to the contained `<td>`. Used to manage hover states.
 * @param {string} props.errorMessage The error message to be displayed
 * @param {function} props.onClickCancelButton A handler to be called when the 'Cancel' button is clicked
 * @param {function} props.onClickDeleteButton A handler to be called when the 'Delete' button is clicked
 * @param {function} props.onClickEditButton A handler to be called when the 'Edit' button is clicked
 * @param {function} props.onClickSaveButton A handler to be called when the 'Save' button is clicked
 * @param {object} props.provided An object required by `react-beautiful-dnd` to be unpacked to make a given element a `Draggable`
 */
export const DraggableListRow = ({
  children,
  areButtonsDeactivated,
  isEditing,
  rowClassName,
  onClickCancelButton,
  onClickDeleteButton,
  onClickEditButton,
  onClickSaveButton,
  provided,
}) => (
  <tr
    className={rowClassName}
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
  >
    <td className="draggable-list-icon-cell">
      <i className="fa fa-reorder fw" />
    </td>
    {children}
    <td className="draggable-list-buttons-cell">
      {isEditing ? (
        <div>
          <button
            disabled={areButtonsDeactivated}
            onClick={onClickSaveButton}
            className="draggable-save-button knock-react-button"
          >
            Save
          </button>
          <button
            disabled={areButtonsDeactivated}
            onClick={onClickCancelButton}
            className="draggable-cancel-button knock-react-button danger"
          >
            Cancel
          </button>
        </div>
      ) : (
        <div>
          <button
            disabled={areButtonsDeactivated}
            onClick={onClickEditButton}
            className="draggable-edit-button knock-react-button"
          >
            Edit
          </button>
          <button
            disabled={areButtonsDeactivated}
            onClick={onClickDeleteButton}
            className="draggable-delete-button knock-react-button danger"
          >
            Delete
          </button>
        </div>
      )}
    </td>
  </tr>
);
