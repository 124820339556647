import { useCallback, useEffect, useMemo, useState } from 'react';
import AccessAPI from './AccessAPI';
import { NotificationService } from '../../Components/Notifications';
import { useAppContext } from '../../Context/AppContext';
import { useFeatureFlagContext } from '../../Context/FeatureFlagContext';
import { getEnabledRoleNames, normalizeAccessSettings } from './utils';
import { ACCESS_SETTINGS } from '../../constants';

export const FETCH_FAILURE =
  'We are unable to manage Access settings at this time. Please try again.';
export const UPDATE_FAILURE_MESSAGE = 'Failed to updated to Access setting';
export const UPDATE_SUCCESS_MESSAGE = 'Access setting updated';

export const useAccess = () => {
  const [accessSettings, setAccessSettings] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { isInternalMode: isFullAccessView } = useAppContext();
  const { isBrandContentEnabled, isSSDAPageEnabled } = useFeatureFlagContext();

  const featureFlagHiddenSettings = useMemo(
    () => ({
      [ACCESS_SETTINGS.BRAND_CONTENT_PAGE]: !isBrandContentEnabled,
      [ACCESS_SETTINGS.SSDA_PAGE]: !isSSDAPageEnabled,
    }),
    [isBrandContentEnabled, isSSDAPageEnabled]
  );

  const fetchAccessSettings = useCallback(async () => {
    setIsLoading(true);
    try {
      const { access: { settings = {} } = {} } =
        await AccessAPI.getCompanyAccessSettings();
      const accessSettings = normalizeAccessSettings({
        featureFlagHiddenSettings,
        isFullAccessView,
        settings,
      });

      setAccessSettings(accessSettings);
    } catch (_e) {
      setAccessSettings([]);
      setErrorMessage(FETCH_FAILURE);
    }
    setIsLoading(false);
  }, [featureFlagHiddenSettings, isFullAccessView]);

  useEffect(() => {
    if (!accessSettings & !isLoading) {
      fetchAccessSettings();
    }
  }, [accessSettings, fetchAccessSettings, isLoading]);

  const updateAccessSetting = (rowData, role, isChecked) => {
    const updatedConfigurableRoles = {
      ...rowData.configurableRoles,
      [role]: {
        ...rowData.configurableRoles[role],
        isChecked,
      },
    };

    const updateSettings = async (payload) => {
      try {
        await AccessAPI.updateAccessSettings(payload);
        NotificationService.notify(UPDATE_SUCCESS_MESSAGE);
      } catch {
        NotificationService.notify(UPDATE_FAILURE_MESSAGE);
        setAccessSettings(null);
      }
    };

    setAccessSettings((prevState) =>
      prevState.map((setting) => {
        if (setting.settingName === rowData.settingName) {
          setting.configurableRoles = updatedConfigurableRoles;
        }
        return { ...setting };
      })
    );

    const enabledRoleNames = getEnabledRoleNames(updatedConfigurableRoles);

    const payload = {
      [rowData.settingName]: enabledRoleNames,
    };

    updateSettings(payload);
  };

  return {
    accessSettings: accessSettings || [],
    errorMessage,
    isFullAccessView,
    isLoading,
    setErrorMessage,
    updateAccessSetting,
  };
};
