import React from 'react';
import PropTypes from 'prop-types';
import { formatAmount, padAmount, parseAmount } from './utils';

export const CUSTOM_FEE_ARIA_LABEL = 'custom fee amount';

const CustomFeeInput = ({ amount, className, updateInputField }) => {
  const handleOnBlur = (e) => {
    const paddedValue = padAmount(e.target.value);
    updateInputField(paddedValue);
  };

  const handleOnChange = (e) => {
    const fee = parseAmount(e.target.value);
    updateInputField(fee);
  };

  return (
    <input
      aria-label={CUSTOM_FEE_ARIA_LABEL}
      className={className}
      onBlur={handleOnBlur}
      onChange={handleOnChange}
      placeholder="Fee in USD, ex: 100"
      type="text"
      value={formatAmount(amount)}
    />
  );
};

CustomFeeInput.propTypes = {
  amount: PropTypes.string,
  className: PropTypes.string,
  updateInputField: PropTypes.func,
};

export default CustomFeeInput;
