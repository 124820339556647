import React, { Component } from 'react';
import './_knockReactCollapse.scss';

class Collapse extends Component {
  state = {
    isOpen: false,
  };

  render() {
    return (
      <div className="knock-react-collapse">
        <div className="knock-react-collapse-title" onClick={this.toggleOpen.bind(this)}>
          <h2>
            {this.state.isOpen ? (
              <i className="fa fa-caret-down fa-fw" />
            ) : (
              <i className="fa fa-caret-right fa-fw" />
            )}{' '}
            {this.props.title}
          </h2>
        </div>

        <div className="knock-react-collapse-content">
          {this.state.isOpen ? this.props.children : null}
        </div>
      </div>
    );
  }

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen }, () => {
      if (this.props.onToggled) {
        this.props.onToggled(this.state.isOpen);
      }
    });
  }
}

export default Collapse;
