import React, { forwardRef } from 'react';
import DataTable from 'material-table';
import PropTypes from 'prop-types';
import { ArrowDownward } from '@material-ui/icons';
import { ThemeProvider } from '@knockrentals/knock-shared-web';

import { getColumns } from './table-columns';
import { getTableOptions } from './table-options';

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

const getPropertySelectionText = (propertiesMap, propertiesCount) => {
  const selectedPropertiesCount = Object.values(propertiesMap).filter(
    (property) => property.isSelected
  ).length;
  return `(${selectedPropertiesCount}/${propertiesCount} selected)`;
};

const PropertiesDataTable = ({
  isEditing,
  properties,
  tablePropertiesMap,
  setTablePropertiesMap,
}) => {
  const handleOmniOnChange = (updatedSetting) => {
    setTablePropertiesMap((prevState) => {
      const updatedPropertyMap = Object.entries(prevState).reduce(
        (updatedMap, [id, settings]) => {
          updatedMap[id] = { ...settings, ...updatedSetting };
          return updatedMap;
        },
        {}
      );
      return updatedPropertyMap;
    });
  };

  const handleRowDataOnChange = (propertyId) => (updatedSetting) => {
    setTablePropertiesMap((prevState) => {
      const propertySettings = prevState[propertyId];
      return {
        ...prevState,
        [propertyId]: { ...propertySettings, ...updatedSetting },
      };
    });
  };

  const propertyCountsText =
    !isEditing &&
    getPropertySelectionText(tablePropertiesMap, properties.length);

  const tableColumns = getColumns({
    handleOmniOnChange,
    handleRowDataOnChange,
    propertyCountsText,
    isEditing,
    tablePropertiesMap,
  });

  return (
    <div>
      <ThemeProvider>
        <DataTable
          columns={tableColumns}
          data={properties.map((property) => ({ ...property }))}
          options={getTableOptions()}
          style={{ overflow: 'hidden' }}
          icons={tableIcons}
        />
      </ThemeProvider>
    </div>
  );
};

PropertiesDataTable.propTypes = {
  isEditing: PropTypes.bool,
  properties: PropTypes.array,
  tablePropertiesMap: PropTypes.object,
  setTablePropertiesMap: PropTypes.func,
};

export default PropertiesDataTable;
