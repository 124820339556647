import React from 'react';

import { InputCheckbox } from '@knockrentals/knock-shared-web';
import { NotificationService } from '../../../../../../Components/Notifications';
import SourcesAPI from '../../../../SourcesAPI';
import { SAVED_NOTIFICATION } from '../../../../../../constants';

export const getErrorMessage = (isEnabled) =>
  `Unable to ${isEnabled ? 'enable' : 'disable'} all properties`;

export const getPropertyMaps = (properties, isEnabled) => {
  const initialPropertyMaps = {
    updated: {},
    original: {},
  };
  return properties.reduce((propertyMaps, property) => {
    propertyMaps.updated[property.leasingTeamId] = {
      isEnabled,
    };
    propertyMaps.original[property.leasingTeamId] = { isEnabled: property.isEnabled };
    return propertyMaps;
  }, initialPropertyMaps);
};

const CompanySourceBatchEnable = ({ properties, updateSourceProperties }) => {
  const isChecked = properties.every((property) => property.isEnabled);

  const handleOnChange = async (event) => {
    const isEnabled = event.target.checked;
    const propertyMaps = getPropertyMaps(properties, isEnabled);
    updateSourceProperties(propertyMaps.updated);

    try {
      const [{ marketingSourceId }] = properties;
      await SourcesAPI.batchUpdateCompanySourceProperties(marketingSourceId, {
        isEnabled,
      });
      NotificationService.notify(SAVED_NOTIFICATION);
    } catch (_e) {
      NotificationService.notify(getErrorMessage(isEnabled));
      updateSourceProperties(propertyMaps.original);
    }
  };

  return (
    <InputCheckbox
      color="primary"
      checked={isChecked}
      onChange={handleOnChange}
    />
  );
};

export default CompanySourceBatchEnable;
