import React from 'react';
import PropTypes from 'prop-types';
import { ArrowDownward } from '@material-ui/icons';
import DataTable from 'material-table';
import { Paper } from '@knockrentals/knock-shared-web';

import { Pagination } from '../../common/Pagination';
import { getTableActions } from './table-actions';
import { getTableColumns } from './table-columns';
import { getTableOptions } from './table-options';

const tableIcons = {
  SortArrow: React.forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
};

const BrandContentDataTable = ({
  getCurrentPagePropertyCount,
  getTableData,
  handleEditActionOnClick,
  propertyCount,
  propertySelectionMap,
  rowsPerPage,
  rowsPerPageOptions,
  selectAllOnPage,
  setPageIndex,
  tableRef,
  updateAllPropertiesSelections,
  updatePropertySelection,
}) => {
  const tableActions = getTableActions(handleEditActionOnClick);

  const tableColumns = getTableColumns({
    getCurrentPagePropertyCount,
    propertyCount,
    propertySelectionMap,
    selectAllOnPage,
    updateAllPropertiesSelections,
    updatePropertySelection,
  });

  const tableOptions = getTableOptions(rowsPerPage, rowsPerPageOptions);

  return (
    <div className="brand-content-data-table">
      <DataTable
        actions={tableActions}
        columns={tableColumns}
        components={{
          Container: (props) => (
            <Paper {...props} elevation={4} className="data-table-paper" />
          ),
          Pagination: Pagination,
        }}
        data={getTableData}
        icons={tableIcons}
        onChangePage={(index) => setPageIndex(index)}
        options={tableOptions}
        tableRef={tableRef}
      />
    </div>
  );
};

BrandContentDataTable.propTypes = {
  getCurrentPagePropertyCount: PropTypes.func,
  getTableData: PropTypes.func,
  handleEditActionOnClick: PropTypes.func,
  propertyCount: PropTypes.number,
  propertySelectionMap: PropTypes.objectOf(
    PropTypes.shape({
      propertyId: PropTypes.number,
      propertyName: PropTypes.string,
    })
  ),
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  selectAllOnPage: PropTypes.func,
  setPageIndex: PropTypes.func,
  tableRef: PropTypes.object,
  updateAllPropertiesSelections: PropTypes.func,
  updatePropertySelection: PropTypes.func,
};

export default BrandContentDataTable;
