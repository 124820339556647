import React from 'react';
import PropTypes from 'prop-types';

const EngrainIntegrationInfo = ({ integration: { vendor, property_id, engrain_unitmap_id } }) => (
  <div className="knock-admin-integration-info">
    <div>
      <strong>Integration:</strong> {vendor}
    </div>
    <div>
      <strong>Engrain Property Id:</strong> {property_id}
    </div>
    <div>
      <strong>Engrain Unitmap Id:</strong> {engrain_unitmap_id}
    </div>
  </div>
);

EngrainIntegrationInfo.propTypes = {
  integration: PropTypes.object
};

export default EngrainIntegrationInfo;
