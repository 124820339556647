import React, { useState } from 'react';
import { UpdateIntegrationButton } from './UpdateIntegrationButton';
import { RemoveIntegrationButton } from './RemoveIntegrationButton';


export const ActionButtons = props => {
  const {
    property_id,
    engrain_unitmap_id,
    credential_id,
    reloadData,
  } = props;

  const [updateLoading, setUpdateLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      {!removeLoading ?
      <UpdateIntegrationButton
        property_id={property_id}
        engrain_unitmap_id={engrain_unitmap_id}
        reloadData={reloadData}
        setUpdateLoading={setUpdateLoading}
      /> : '' }

      {credential_id && !updateLoading ?
        <RemoveIntegrationButton
          property_id={property_id}
          engrain_unitmap_id={engrain_unitmap_id}
          reloadData={reloadData}
          setRemoveLoading={setRemoveLoading}
        /> : ''}
    </div>
  );
};