import React from 'react';
import PropTypes from 'prop-types';
import { removeSpaces, validateUrl } from '../../Utils';

export const INVALID_URL_MESSAGE = 'Specified URL is invalid';

class URLInput extends React.Component {
  state = {
    url: this.props.url,
    errorMessage: '',
  };

  handleOnBlur = (e) => {
    const { value } = e.target;
    if (value && !validateUrl(value)) {
      this.setState({ errorMessage: INVALID_URL_MESSAGE });
      return;
    }

    if (value !== this.props.url) {
      this.props.updateValue(value);
    }
  };

  handleOnFocus = () => {
    this.setState({ errorMessage: '' });
  };

  render() {
    return (
      <React.Fragment>
        <input
          onBlur={this.handleOnBlur}
          onChange={(event) => {
            this.setState({ url: removeSpaces(event.target.value) });
          }}
          onFocus={this.handleOnFocus}
          type="text"
          value={this.state.url}
        />

        {this.state.errorMessage && (
          <span className="knock-react-form-hint knock-react-form-hint-error">
            {this.state.errorMessage}
          </span>
        )}
      </React.Fragment>
    );
  }
}

URLInput.propTypes = {
  updateValue: PropTypes.func,
  url: PropTypes.string,
};

export default URLInput;
