import { apiRemote } from '../../../../Services/RemoteService';
import {
  getQueryString,
  normalizePropertyList,
  normalizeSchedulingPreferences,
  normalizeSchedulingProperties,
  transformPreferencesPayload,
} from './utils';

export const getPropertyList = () =>
  apiRemote
    .get('admin/property-list')
    .then((response) => {
      if (response.ok && response.status === 200) {
        return response.json();
      }
      throw new Error(response.statusText);
    })
    .then((data) => {
      return normalizePropertyList(data.properties);
    });

export const getSchedulingProperties = (queryParams) => {
  const queryString = getQueryString(queryParams);
  return apiRemote
    .get(`admin/scheduling/properties${queryString}`)
    .then((response) => {
      if (response.ok && response.status === 200) {
        return response.json();
      }
      throw new Error(response.statusText);
    })
    .then((data) => {
      return {
        properties: normalizeSchedulingProperties(data.property_data),
        totalPropertyCount: data.total_num_records,
      };
    });
};

export const getSchedulingPreferences = (propertyId) => {
  return apiRemote
    .get(`admin/scheduling/preferences/${propertyId}`)
    .then((response) => {
      if (response.ok && response.status === 200) {
        return response.json();
      }
      throw new Error(response.statusText);
    })
    .then((data) => normalizeSchedulingPreferences(data.property_hours_data));
};

export const updateSchedulingPreferences = (payload) => {
  const transformedPayload = transformPreferencesPayload(payload);
  return apiRemote
    .put('admin/scheduling/preferences', transformedPayload)
    .then((response) => {
      if (response.ok && response.status === 201) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
};
