import React from 'react';
import { EditIcon } from '../../../../Components/Icons';

export const getTableActions = (handleEditActionOnClick, editActionTooltip) => [
  {
    icon: () => <EditIcon />,
    tooltip: editActionTooltip,
    onClick: (_e, rowData) => {
      handleEditActionOnClick(rowData.quickReplyId);
    },
  },
];
