import React from 'react';
import PropTypes from 'prop-types';

import { Chip, makeStyles, Text } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  displayChipLabel: {
    marginRight: '24px',
    '& .displayChip': {
      '&.MuiChip-root': {
        backgroundColor: 'rgba(141, 144, 165, 0.08)',
      },
      '& .MuiTypography-root': {
        textTransform: 'capitalize',
      },
    },
  },
});

const DisplayChip = ({ label, value }) => {
  const classes = useStyles();

  return (
    <Text className={classes.displayChipLabel} component="span">
      {`${label}: `}
      <Chip
        className="displayChip"
        component="span"
        label={<Text component="span"> {value} </Text>}
      />
    </Text>
  );
};

DisplayChip.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export default DisplayChip;
