export const AVAILABILITY_BUFFER = 'availabilityBuffer';
export const CUSTOM_REQUIREMENTS = 'customRequirements';
export const END_TIME = 'endTime';
export const INCOME_MULTIPLIER = 'incomeMultiplier';
export const IS_BUDGET_REQUIRED = 'isBudgetRequired';
export const IS_EVICTION_HISTORY_REQUIRED = 'isEvictionHistoryRequired';
export const IS_MOVE_DATE_REQUIRED = 'isMoveDateRequired';
export const IS_PET_POLICY_REQUIRED = 'isPetPolicyRequired';
export const IS_SELF_SCHEDULE_ENABLED = 'isSelfScheduleEnabled';
export const IS_SELF_GUIDED_TOURS_ENABLED = 'isSelfGuidedToursEnabled';
export const IS_IN_PERSON_TOURS_ENABLED = 'isInPersonToursEnabled';
export const IS_LIVE_VIDEO_TOURS_ENABLED = 'isLiveVideoToursEnabled';
export const MAX_VISITORS = 'maxVisitors';
export const NUMBER_OF_SCHEDULING_DAYS = 'numberOfSchedulingDays';
export const PRE_APPOINTMENT_BUFFER = 'preAppointmentBuffer';
export const POST_APPOINTMENT_BUFFER = 'postAppointmentBuffer';
export const SHOULD_HIDE_SCHEDULING_CALL_TO_ACTION =
  'shouldHideSchedulingCallToAction';
export const SHOULD_SELF_GUIDED_TOURS_BLOCK = 'shouldSelfGuidedToursBlock';
export const SHOULD_CLUSTER_TOURS = 'shouldClusterTours';
export const START_TIME = 'startTime';
export const TOUR_DURATION = 'tourDuration';

export const DAYS_OF_THE_WEEK = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const ERROR_MESSAGES = {
  FETCH:
    'An unexpected error has occurred.  Scheduling preferences are unable to be managed at this time',
  UPDATE:
    'An unexpected error has occurred.  Scheduling preferences are unable to be updated at this time',
};
