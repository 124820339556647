import React from 'react';
import PropTypes from 'prop-types';
import CKEditor from 'ckeditor4-react';
import { getCustomStyleText, DEFAULT_EDITOR_HEIGHT } from './utils';
import { CKEDITOR_URL } from '../../../../../../constants';

CKEditor.editorUrl = CKEDITOR_URL;

export const FOOTER_TEMPLATE_ARIA_LABEL = 'brand-footer-template-editor';
const EDITOR_NAME = 'brand-footer-template-editor';
const EDITOR_BODY_CLASS = `${EDITOR_NAME}-body`;

const MENTIONS_CASE_SENSITIVE = false;
const MARKER = '#';

const setHtmlTagFormatRules = (editor) => {
  const htmlTagNames = ['br', 'li', 'ol', 'p', 'ul'];
  const rules = {
    breakAfterClose: false,
    breakAfterOpen: false,
    breakBeforeClose: false,
    breakBeforeOpen: false,
    indent: false,
  };

  htmlTagNames.forEach((tagName) => {
    editor.dataProcessor.writer.setRules(tagName, rules);
  });
};

const getDoesBeginWith = (name, matcher) => {
  const index = MENTIONS_CASE_SENSITIVE
    ? name.indexOf(matcher)
    : name.toLowerCase().indexOf(matcher.toLowerCase());
  return index === 0;
};

const getFeed = (mentionsList) => (options, callback) => {
  const mentionedName = options.query;
  const feed = mentionsList.reduce((feedData, mention) => {
    if (getDoesBeginWith(mention.name, mentionedName)) {
      feedData.push({
        ...mention,
        id: feedData.length,
      });
    }
    return feedData;
  }, []);

  feed.sort((a, b) => (a.name > b.name ? 1 : -1));
  return callback(feed);
};

const getMentions = (mentionsList) => {
  return [
    {
      feed: getFeed(mentionsList),
      minChars: 0,
      marker: MARKER,
      caseSensitive: MENTIONS_CASE_SENSITIVE,
      itemTemplate:
        '<li data-id="{id}"><strong>{name}</strong> <br/> <i>{description}</i></li>',
    },
  ];
};

const PLACE_HOLDER_TEXT =
  '> Enter text here if you want to populate the brand field below';

const MIXED_CONTENT_PLACE_HOLDER_TEXT =
  '> ALL selected properties will display the text entered here. If left blank, the various properties will retain their current copy.';

const handleChange =
  (onChange) =>
  ({ editor }) => {
    const editorContent = editor.getData();
    onChange(editorContent);
  };

const getEditorConfig = ({ isFooterContentMixed, mentionsList, onChange }) => {
  const config = {
    bodyClass: EDITOR_BODY_CLASS,
    editorplaceholder: isFooterContentMixed
      ? MIXED_CONTENT_PLACE_HOLDER_TEXT
      : PLACE_HOLDER_TEXT,
    extraPlugins: 'mentions',
    disallowedContent: 'a[onclick]',
    height: DEFAULT_EDITOR_HEIGHT,
    on: {
      change: handleChange(onChange),
    },
    removeDialogTabs: 'image:advanced;link:advanced;table:advanced',
    removePlugins:
      'autogrow,elementspath,image2,pastefromgdocs,pastefromlibreoffice,pastefromword,pastetext,pastetools,resize,uploadfile,uploadimage,uploadwidget',
    resize_enabled: false,
    toolbar: [
      { name: 'type', items: ['Font', 'FontSize'] },
      {
        name: 'texttools',
        items: ['Bold', 'Italic', 'Underline', 'Strike'],
      },
      {
        name: 'alignment',
        items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
      },
      { name: 'lists', items: ['NumberedList', 'BulletedList'] },
    ],
  };

  if (mentionsList.length) {
    config.mentions = getMentions(mentionsList);
  }

  return config;
};

const customStyleText = getCustomStyleText({
  bodyClassName: EDITOR_BODY_CLASS,
});

const FooterTemplateEditor = ({
  footerContent,
  isFooterContentMixed,
  mentionsList = [],
  onChange,
}) => {
  return (
    <div className={EDITOR_NAME} aria-label={FOOTER_TEMPLATE_ARIA_LABEL}>
      <CKEditor
        config={getEditorConfig({
          isFooterContentMixed,
          mentionsList,
          onChange,
        })}
        style={{ width: '100%', marginBottom: '16px' }}
        data={footerContent}
        name={EDITOR_NAME}
        onInstanceReady={({ editor }) => {
          setHtmlTagFormatRules(editor);
          editor.document.appendStyleText(customStyleText);
        }}
      />
    </div>
  );
};

FooterTemplateEditor.propTypes = {
  footerContent: PropTypes.string,
  isFooterContentMixed: PropTypes.bool,
  mentionsList: PropTypes.array,
  onChange: PropTypes.func,
};

export default FooterTemplateEditor;
