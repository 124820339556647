import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputCheckbox } from '@knockrentals/knock-shared-web';

import ConfirmationDialog from '../../common/ConfirmationDialog';

export const SaveSettingsDialog = ({ closeDialog, handleConfirmation, isOpen, selectedPropertiesAmount }) => {
  const [doNotShowDialogSelected, setDoNotShowDialogSelected] = useState(false);

  const onSave = () => {
    handleConfirmation(doNotShowDialogSelected);
  };

  return (
    <ConfirmationDialog
      closeDialog={closeDialog}
      handleConfirmation={onSave}
      isOpen={isOpen}
      message={
        <>
          <span className="call-intel-dialog-text">
            Saving will update the preferences for {selectedPropertiesAmount > 1 ? 'all' : ''} {selectedPropertiesAmount} selected {selectedPropertiesAmount === 1 ? 'property' : 'properties'}. 
            Do you want to continue?
          </span>
          <InputCheckbox
            label="Do not show again"
            checked={doNotShowDialogSelected}
            color="primary"
            onChange={() => setDoNotShowDialogSelected(!doNotShowDialogSelected)}
          />
        </>
      }
      primaryButtonText="Yes, Save"
      secondaryButtonText="No, Do not Save"
      title="Save Settings"
    />
  );
};

SaveSettingsDialog.propTypes = {
  closeDialog: PropTypes.func,
  handleConfirmation: PropTypes.func,
  isOpen: PropTypes.bool,
  selectedPropertiesAmount: PropTypes.number,
};