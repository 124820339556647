import React from 'react';
import PropTypes from 'prop-types';

const YardiIntegrationInfo = ({ integration: { vendor, role, username, password, yardi_property_id, servername, service_url, database, platform, managed_by_kip } }) => (
  <div className="knock-admin-integration-info">
    <div>
      <strong>Integration:</strong> {vendor}
      {role === 'import' ? ' (unit availability, resident import)' : ''}
    </div>
    <div>
      <strong>Username:</strong> {username}
    </div>
    <div>
      <strong>Password:</strong> {password}
    </div>
    <div>
      <strong>Yardi property ID:</strong> {yardi_property_id}
    </div>
    <div>
      <strong>Yardi server name:</strong> {servername}
    </div>
    <div>
      <strong>Yardi server URL:</strong> {service_url}
    </div>
    <div>
      <strong>Yardi database:</strong> {database}
    </div>
    <div>
      <strong>Yardi platform:</strong> {platform}
    </div>
    <div>
      <strong>Manage with KIP:</strong> {managed_by_kip}
    </div>
  </div>
);

YardiIntegrationInfo.propTypes = {
  integration: PropTypes.object
};

export default YardiIntegrationInfo;
