import React from 'react';
import PropTypes from 'prop-types';

import { Button, makeStyles } from '@knockrentals/knock-shared-web';

import { EditIcon } from '../../../../Components/Icons';

const useStyles = makeStyles((theme) => ({
  manageButton: {
    textTransform: 'none',

    '& .MuiSvgIcon-root': {
      height: '18px',
      width: '18px',
    },

    '&:disabled .MuiSvgIcon-root path': {
      stroke: theme.palette.action.disabled,
    },
  },
}));

const ManageButton = ({ onClick, selectedCount = 0 }) => {
  const classes = useStyles();
  const isDisabled = selectedCount === 0;

  return (
    <div className="manage-brand-count-button">
      <Button
        className={classes.manageButton}
        disabled={isDisabled}
        color="primary"
        onClick={onClick}
        startIcon={<EditIcon />}
        variant="text"
      >
        {`Manage (${selectedCount})`}
      </Button>
    </div>
  );
};

ManageButton.propTypes = {
  onClick: PropTypes.func,
  selectedCount: PropTypes.number,
};

export default ManageButton;
