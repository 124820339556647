import { QUICK_REPLY } from '../constants';

const TRANSFORMED_FIELDS = {
  [QUICK_REPLY.EMAIL]: 'message',
  [QUICK_REPLY.SMS]: 'message_sms',
  [QUICK_REPLY.TITLE]: 'title',
};

const normalizeProperties = (properties) =>
  properties.map((property) => ({
    propertyId: property.property_id,
    propertyName: property.property_name,
    leasingTeamName: property.leasing_team_name,
    isEnabled: property.is_enabled,
  }));

export const normalizeQuickReplyData = (quickReplyData) => {
  const { company_quick_reply: quickReply, properties } = quickReplyData;
  return {
    [QUICK_REPLY.EMAIL]: quickReply.message || "",
    [QUICK_REPLY.PROPERTIES]: normalizeProperties(properties),
    [QUICK_REPLY.SMS]: quickReply.message_sms || "",
    [QUICK_REPLY.TITLE]: quickReply.title,
  };
};

const transformProperties = (properties) =>
  properties.map((property) => ({
    property_id: property.propertyId,
    is_enabled: property.isEnabled,
  }));

export const getTransformedPayload = (payload) => {
  const transformedPayload = {};
  const { quickReply, properties } = payload;

  if (Object.keys(quickReply).length) {
    transformedPayload.quickReply = Object.entries(quickReply).reduce(
      (reply, [field, value]) => {
        reply[TRANSFORMED_FIELDS[field]] = value;
        return reply;
      },
      {}
    );
  }
  if (properties.length) {
    transformedPayload.properties = transformProperties(properties);
  }
  return transformedPayload;
};

const normalizeQuickReplies = (quickReplies) =>
  quickReplies.map((quickReply) => ({
    email: quickReply.message,
    enabledPropertiesCount: quickReply.properties,
    quickReplyId: quickReply.id,
    sms: quickReply.message_sms,
    title: quickReply.title,
  }));

export const getNormalizeAllQuickRepliesData = (responseData) => {
  const { company_quick_replies, total_records } = responseData;
  return {
    quickReplies: normalizeQuickReplies(company_quick_replies),
    quickRepliesCount: total_records,
  };
};
