import React from 'react';
import PropTypes from 'prop-types';

import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import { PreferencesSelect } from '../PreferencesSelect';
import { PreferencesSwitch } from '../PreferencesSwitch';
import ItemTitle from './ItemTitle';
import { IsModifiedCheckbox } from '../IsModifiedCheckbox';
import { getOptions } from '../utils';

import {
  AVAILABILITY_BUFFER,
  IS_SELF_SCHEDULE_ENABLED,
  IS_SELF_GUIDED_TOURS_ENABLED,
  IS_IN_PERSON_TOURS_ENABLED,
  IS_LIVE_VIDEO_TOURS_ENABLED,
  NUMBER_OF_SCHEDULING_DAYS,
  PRE_APPOINTMENT_BUFFER,
  PREFERENCES_TITLES,
  POST_APPOINTMENT_BUFFER,
  SHOULD_HIDE_SCHEDULING_CALL_TO_ACTION,
  SHOULD_SELF_GUIDED_TOURS_BLOCK,
  SHOULD_CLUSTER_TOURS,
  TOUR_DURATION,
} from '../constants';

export const featureSwitches = [
  IS_SELF_SCHEDULE_ENABLED,
  SHOULD_HIDE_SCHEDULING_CALL_TO_ACTION,
  IS_SELF_GUIDED_TOURS_ENABLED,
  IS_IN_PERSON_TOURS_ENABLED,
  IS_LIVE_VIDEO_TOURS_ENABLED,
  SHOULD_SELF_GUIDED_TOURS_BLOCK,
  SHOULD_CLUSTER_TOURS,
];

export const featureSelections = [
  TOUR_DURATION,
  AVAILABILITY_BUFFER,
  PRE_APPOINTMENT_BUFFER,
  POST_APPOINTMENT_BUFFER,
  NUMBER_OF_SCHEDULING_DAYS,
];

const batchEditingDisabledMap = {
  [IS_IN_PERSON_TOURS_ENABLED]: true,
  [IS_LIVE_VIDEO_TOURS_ENABLED]: true,
};

export const CLUSTER_TOURS_TOOL_TIP =
  'Allow instant booking for times tours are scheduled (Recommended)';

const getToolTips = (features) => ({
  [SHOULD_CLUSTER_TOURS]: features[IS_SELF_SCHEDULE_ENABLED]
    ? CLUSTER_TOURS_TOOL_TIP
    : '',
});

const useStyles = makeStyles({
  schedulingFeatures: {
    '& .MuiInputBase-root': {
      minWidth: '220px',
    },
  },
});

export const getBatchDisabledClass = (isBatchEdit, name) =>
  isBatchEdit && batchEditingDisabledMap[name] ? 'batch-disabled' : '';

const Features = ({
  features,
  isBatchEdit,
  modifiedStatusMap = {},
  setFeatures,
  updateModifiedStatus,
}) => {
  const classes = useStyles();

  const toolTips = getToolTips(features);

  const updatePreference = (preference) => {
    if (preference[IS_IN_PERSON_TOURS_ENABLED] === false) {
      preference[IS_LIVE_VIDEO_TOURS_ENABLED] = true;
    }
    if (preference[IS_LIVE_VIDEO_TOURS_ENABLED] === false) {
      preference[IS_IN_PERSON_TOURS_ENABLED] = true;
    }
    setFeatures((prevState) => ({
      ...prevState,
      ...preference,
    }));
  };

  const getIsSelectIndeterminate = (value) => isBatchEdit && !value;
  const getIsSwitchIndeterminate = (value) =>
    isBatchEdit && typeof value !== 'boolean';

  const renderListItemText = (name) => (
    <ListItemText>
      <IsModifiedCheckbox
        isModified={modifiedStatusMap[name]}
        updateModifiedStatus={updateModifiedStatus}
        name={name}
        value={features[name]}
        resetPreference={updatePreference}
      />
      <ItemTitle title={PREFERENCES_TITLES[name]} tooltip={toolTips[name]} />
    </ListItemText>
  );

  return (
    <List className={classes.schedulingFeatures}>
      {featureSwitches.map((name) => (
        <ListItem
          key={name}
          className={getBatchDisabledClass(isBatchEdit, name)}
        >
          {renderListItemText(name)}
          <PreferencesSwitch
            ariaLabel={PREFERENCES_TITLES[name]}
            isChecked={features[name]}
            isIndeterminate={getIsSwitchIndeterminate(features[name])}
            name={name}
            updatePreference={updatePreference}
          />
        </ListItem>
      ))}

      {featureSelections.map((name) => (
        <ListItem key={name}>
          {renderListItemText(name)}
          <PreferencesSelect
            ariaLabel={PREFERENCES_TITLES[name]}
            isIndeterminate={getIsSelectIndeterminate(features[name])}
            name={name}
            options={getOptions(name)}
            value={features[name]}
            updatePreference={updatePreference}
          />
        </ListItem>
      ))}
    </List>
  );
};

Features.propTypes = {
  features: PropTypes.shape({}),
  isBatchEdit: PropTypes.bool,
  modifiedStatusMap: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.bool)])
  ),
  setFeatures: PropTypes.func,
  updateModifiedStatus: PropTypes.func,
};

export default Features;
