import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  makeStyles,
  Text,
  ThemeProvider,
} from '@knockrentals/knock-shared-web';
import { adminIconImageSrc } from '../../Components/Icons';

const useStyles = makeStyles({
  adminLogoCardContent: {
    alignContent: 'center',
    height: '70vh',
    textAlign: 'center',
  },
  adminLogo: {
    borderRadius: '4px',
    boxShadow: '0px 4px 16px -2px #18274B05, 0px 2px 12px -4px #18274B0A',
    display: 'inline',
    height: '100px',
    objectFit: 'contain',
    width: 'auto',
  },
  adminTitle: {
    fontWeight: 'normal',
    marginLeft: '12px',
  },
  subtitle: {
    marginTop: '20px',
  },
});

const AccessInvalidPage = () => {
  const classes = useStyles();

  return (
    <ThemeProvider>
      <Card elevation={0}>
        <CardContent className={classes.adminLogoCardContent}>
          <CardMedia
            alt={'Knock Admin Logo'}
            className={classes.adminLogo}
            component="img"
            src={adminIconImageSrc}
            title={'Knock Admin'}
          />

          <Text className={classes.adminTitle} component="span" variant="h1">
            Admin
          </Text>

          <Text
            className={classes.subtitle}
            color="secondary"
            variant="subtitle2"
          >
            Thank you for your inquiry, but your access settings seem to be
            invalid.
            <br />
            We suggest contacting your access administrator for assistance.
          </Text>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};

export default AccessInvalidPage;
