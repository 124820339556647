import React, { Component } from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter } from '@knockrentals/knock-react';
import '../../_attributeInfo.scss';
import * as _ from 'lodash';
import { Formik } from 'formik';

class ChangeAttributeModal extends Component {
  state = {
    suggestions: _.keys(this.props.suggestions).sort(),
  };

  render() {
    return (
      <Modal className="attribute-modal">
        <Formik
          initialValues={{
            attributeValue: this.props.sourceAttribute ? this.props.sourceAttribute.attribute : '',
            isUseNullAttribute: this.props.sourceAttribute.attribute === null,
          }}
          onSubmit={this.onConfirm.bind(this)}
        >
          {props => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <ModalHeader>
                <h2>Modify Source Attribute</h2>
              </ModalHeader>
              <ModalContent>
                <div className="knock-react-flex">
                  <div className="field-label">Source</div>
                  <div className="field-input">
                    <i className="fa fa-building" /> {this.props.marketingSource.name}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div className="field-label">Default Attribute</div>
                  <div className="field-input">
                    {this.props.marketingSource.default_attribute ? (
                      <span>
                        <i className="fa fa-tags" /> {this.props.marketingSource.default_attribute}
                      </span>
                    ) : (
                      <span>
                        <i className="fa fa-tag" /> <em>None</em>
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div className="field-label">Effective Attribute</div>
                  <div className="field-input">
                    <input
                      value={props.values.isUseNullAttribute ? '' : props.values.attributeValue}
                      type="text"
                      name="attributeValue"
                      onChange={props.handleChange}
                      placeholder="Custom source attribute"
                      disabled={props.values.isUseNullAttribute}
                      className="knock-react-input"
                    />
                    <input
                      name="isUseNullAttribute"
                      onChange={() => this.handleNullChange(props)}
                      type="checkbox"
                      value={props.values.isUseNullAttribute}
                    />
                    No source attribute
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div className="field-label">Suggestions</div>
                  <div className="field-input field-wrap">
                    {this.state.suggestions.length ? (
                      this.state.suggestions.map((suggestion, i) => {
                        return (
                          <a
                            key={`suggestion-${i}`}
                            className="mRight10"
                            onClick={this.loadSuggestion.bind(this, suggestion, props)}
                            href="#"
                          >
                            {suggestion}
                          </a>
                        );
                      })
                    ) : (
                      <em>None</em>
                    )}
                  </div>
                </div>
              </ModalContent>
              <ModalFooter>
                <button
                  className="btn-md btn-primary"
                  onClick={this.onCancel.bind(this)}
                  type="button"
                >
                  <i className="fa fa-lg fa-times-circle" /> Cancel
                </button>
                <button
                  className="btn-md btn-danger"
                  onClick={this.onRevertToDefault.bind(this)}
                  type="button"
                >
                  <i className="fa fa-lg fa-undo" /> Revert to Default
                </button>
                <button className="btn-md btn-success" type="submit">
                  <i className="fa fa-lg fa-check-circle" /> Update Custom Attribute
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  toggleIsUseNullAttribute(value = null, formProps) {
    const toggleToValue = typeof value === 'boolean' ? value : !!this.state.isUseNullAttribute;

    this.setState({ isUseNullAttribute: toggleToValue });
    formProps.setFieldValue('attributeValue', toggleToValue ? '_None_' : '');
  }

  loadSuggestion(suggestion, formProps) {
    if (!formProps.values.isUseNullAttribute) {
      formProps.setFieldValue('attributeValue', suggestion);
    }
  }

  onConfirm(values) {
    const attributeValue = this.state.isUseNullAttribute ? null : values.attributeValue;

    if (attributeValue !== '') {
      this.props.updateAttribute(
        this.props.sourceAttribute.id,
        this.props.marketingSource.id,
        attributeValue
      );
    }
  }

  onRevertToDefault() {
    this.props.deleteAttribute(this.props.sourceAttribute.id);
  }

  onCancel() {
    this.props.toggleModal(false);
  }

  validate(values) {
    const errors = {};

    if (!values.attributeValue) {
      errors.attributeValue = 'Required';
    }

    return errors;
  }

  handleNullChange(formProps) {
    let {
      values: { isUseNullAttribute },
    } = formProps;
    isUseNullAttribute = !isUseNullAttribute;
    formProps.setFieldValue('isUseNullAttribute', isUseNullAttribute);

    if (isUseNullAttribute) {
      formProps.setFieldValue('attributeValue', '');
    }
  }
}

export default ChangeAttributeModal;
