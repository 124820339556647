import React from 'react';
import PropTypes from 'prop-types';
import { formatUSDollar } from './utils';

export const CustomFeeDisplay = ({ amount }) => (
  <span>{formatUSDollar(amount)}</span>
);

CustomFeeDisplay.propTypes = { amount: PropTypes.number };

export default CustomFeeDisplay;
