import React, { Component } from 'react';
import { AuthenticationService, ColorPicker } from '@knockrentals/knock-react';
import RelayUsernameField from '../RelayUsernameField/RelayUsernameField';
import { Formik } from 'formik';
import KeySellingFeatures from './KeySellingFeatures';
import { validateUrl } from '../../Utils';

class PropertyInfoEditBasics extends Component {
  state = {
    isMailerHandleValid: true,
  };

  render() {
    const { property } = this.props;
    const { website = {} } = property.data;

    const mailerParts = property.data.social.knock_email.split('@');
    const keySellingFeatures = property.data.key_selling_points || {};
    return (
      <div className="property-info-edit-container">
        <Formik
          enableReinitialize
          initialValues={{
            propertyName: property.data.location.name ? property.data.location.name : '',
            propertyAddress: property.data.location.address
              ? property.data.location.address.street
              : '',
            propertyCity: property.data.location.address ? property.data.location.address.city : '',
            propertyState: property.data.location.address
              ? property.data.location.address.state
              : '',
            propertyZip: property.data.location.address ? property.data.location.address.zip : '',
            neighborhood: property.data.location.address.neighborhood
              ? property.data.location.address.neighborhood
              : '',
            numberOfUnits: property.data.location.numberOfUnits
              ? property.data.location.numberOfUnits
              : '',
            yearBuilt: property.data.location.yearBuilt ? property.data.location.yearBuilt : '',
            propertyOwner: property.owning_manager_id ? property.owning_manager_id : '',
            knockEmail: property.data.social.knock_email ? property.data.social.knock_email : '',
            urlWebsite: property.data.social.website ? property.data.social.website : '',
            urlFacebook: property.data.social.facebook ? property.data.social.facebook : '',
            urlVideo: property.data.videos.length > 0 ? property.data.videos[0].url : '',
            description: property.data.description.short ? property.data.description.short : '',
            mailerHandle: mailerParts[0],
            mailerDomain: mailerParts[1],
            primaryColor: website.primaryColor || '',
            notes: property.data.notes || '',
            keySellingFeatures: {
              community: keySellingFeatures.community || [],
              units: keySellingFeatures.units || [],
              location: keySellingFeatures.location || [],
            },
          }}
          validate={this.validate}
          onSubmit={this.submit.bind(this)}
        >
          {props => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <div className="full-width">
                <h2 className="side-margin-lg">Location</h2>
                <div className="half-width inline-block">
                  <div className="knock-react-flex">
                    <div className="property-info-edit-field-label">Property Name</div>
                    <div className="property-info-edit-field-input">
                      <input
                        value={props.values.propertyName}
                        name="propertyName"
                        onChange={props.handleChange}
                        className="knock-react-input"
                        placeholder="ABC Apartment Homes"
                      />
                      {!!props.errors.propertyName && (
                        <span className="knock-react-form-hint knock-react-form-hint-error">
                          {props.errors.propertyName}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="knock-react-flex">
                    <div className="property-info-edit-field-label">Address</div>
                    <div className="property-info-edit-field-input">
                      <input
                        value={props.values.propertyAddress}
                        name="propertyAddress"
                        onChange={props.handleChange}
                        className="knock-react-input"
                        placeholder="Street address"
                      />
                      {!!props.errors.propertyAddress && (
                        <span className="knock-react-form-hint knock-react-form-hint-error">
                          {props.errors.propertyAddress}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="knock-react-flex">
                    <div className="property-info-edit-field-label">City</div>
                    <div className="property-info-edit-field-input">
                      <input
                        value={props.values.propertyCity}
                        name="propertyCity"
                        onChange={props.handleChange}
                        className="knock-react-input"
                        placeholder="City"
                      />
                      {!!props.errors.propertyCity && (
                        <span className="knock-react-form-hint knock-react-form-hint-error">
                          {props.errors.propertyCity}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="knock-react-flex">
                    <div className="property-info-edit-field-label">State</div>
                    <div className="property-info-edit-field-input">
                      <select
                        value={props.values.propertyState}
                        name="propertyState"
                        onChange={props.handleChange}
                      >
                        <option value="">--- Select State ---</option>
                        <option value="AL">Alabama - AL</option>
                        <option value="AK">Alaska - AK</option>
                        <option value="AZ">Arizona - AZ</option>
                        <option value="AR">Arkansas - AR</option>
                        <option value="CA">California - CA</option>
                        <option value="CO">Colorado - CO</option>
                        <option value="CT">Connecticut - CT</option>
                        <option value="DE">Delaware - DE</option>
                        <option value="FL">Florida - FL</option>
                        <option value="GA">Georgia - GA</option>
                        <option value="HI">Hawaii - HI</option>
                        <option value="ID">Idaho - ID</option>
                        <option value="IL">Illinois - IL</option>
                        <option value="IN">Indiana - IN</option>
                        <option value="IA">Iowa - IA</option>
                        <option value="KS">Kansas - KS</option>
                        <option value="KY">Kentucky - KY</option>
                        <option value="LA">Louisiana - LA</option>
                        <option value="ME">Maine - ME</option>
                        <option value="MD">Maryland - MD</option>
                        <option value="MA">Massachusetts - MA</option>
                        <option value="MI">Michigan - MI</option>
                        <option value="MN">Minnesota - MN</option>
                        <option value="MS">Mississippi - MS</option>
                        <option value="MO">Missouri - MO</option>
                        <option value="MT">Montana - MT</option>
                        <option value="NE">Nebraska - NE</option>
                        <option value="NV">Nevada - NV</option>
                        <option value="NH">New Hampshire - NH</option>
                        <option value="NJ">New Jersey - NJ</option>
                        <option value="NM">New Mexico - NM</option>
                        <option value="NY">New York - NY</option>
                        <option value="NC">North Carolina - NC</option>
                        <option value="ND">North Dakota - ND</option>
                        <option value="OH">Ohio - OH</option>
                        <option value="OK">Oklahoma - OK</option>
                        <option value="OR">Oregon - OR</option>
                        <option value="PA">Pennsylvania - PA</option>
                        <option value="RI">Rhode Island - RI</option>
                        <option value="SC">South Carolina - SC</option>
                        <option value="SD">South Dakota - SD</option>
                        <option value="TN">Tennessee - TN</option>
                        <option value="TX">Texas - TX</option>
                        <option value="UT">Utah - UT</option>
                        <option value="VT">Vermont - VT</option>
                        <option value="VA">Virginia - VA</option>
                        <option value="WA">Washington - WA</option>
                        <option value="WV">West Virginia - WV</option>
                        <option value="WI">Wisconsin - WI</option>
                        <option value="WY">Wyoming - WY</option>
                      </select>
                      {!!props.errors.propertyState && (
                        <span className="knock-react-form-hint knock-react-form-hint-error">
                          {props.errors.propertyState}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="knock-react-flex">
                    <div className="property-info-edit-field-label">Zipcode</div>
                    <div className="property-info-edit-field-input">
                      <input
                        value={props.values.propertyZip}
                        name="propertyZip"
                        pattern="[0-9\-]*"
                        onChange={props.handleChange}
                        className="knock-react-input"
                        placeholder="Zip"
                      />
                      {!!props.errors.propertZip && (
                        <span className="knock-react-form-hint knock-react-form-hint-error">
                          {props.errors.propertyZip}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="full-width">
                <h2 className="side-margin-lg">Contact</h2>
                <div className="knock-react-flex">
                  <div className="property-info-edit-field-label">Owner</div>
                  <div className="property-info-edit-field-input">
                    <select
                      value={props.values.propertyOwner}
                      name="propertyOwner"
                      onChange={props.handleChange}
                      disabled={!this.state.propertyOwner}
                    >
                      <option value="">--- Select User ---</option>
                      {this.props.teams.map(team => {
                        return (
                          <optgroup key={team.leasing_team_id} label={team.leasing_team_name}>
                            {team.users.map(user => {
                              return (
                                <option key={user.user_id} value={user.user_id}>
                                  {user.first_name} {user.last_name} ({user.email})
                                </option>
                              );
                            })}
                          </optgroup>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div className="property-info-edit-field-label">Knock Email</div>
                  <div className="property-info-edit-field-input">
                    <input
                      value={props.values.knockEmail}
                      name="knockEmail"
                      disabled
                      className="knock-react-input"
                      placeholder="email"
                    />
                    <span className="input-append-text">
                      <i className="fa fa-lock" /> Locked
                    </span>
                  </div>
                </div>
                {AuthenticationService._internalMode && (
                  <RelayUsernameField
                    relayUsername={props.values.mailerHandle}
                    isValid={this.state.isMailerHandleValid}
                    onChange={(value) => { props.setFieldValue('mailerHandle', value); }}
                    setIsValid={this.setIsEmailValid}
                  />
                )}
              </div>
              <div className="full-width">
                <h2 className="side-margin-lg">Social</h2>
                <div className="knock-react-flex">
                  <div className="property-info-edit-field-label">Website</div>
                  <div className="property-info-edit-field-input">
                    <input
                      value={props.values.urlWebsite}
                      name="urlWebsite"
                      onChange={props.handleChange}
                      className="knock-react-input"
                      placeholder="http://yourcommunity.com"
                    />
                  </div>
                  {props.errors.urlWebsite && (
                    <span className="knock-react-form-hint knock-react-form-hint-error">
                      {props.errors.urlWebsite}
                    </span>
                  )}
                </div>
                <div className="knock-react-flex">
                  <div className="property-info-edit-field-label">Facebook</div>
                  <div className="property-info-edit-field-input">
                    <input
                      value={props.values.urlFacebook}
                      name="urlFacebook"
                      onChange={props.handleChange}
                      className="knock-react-input"
                      placeholder="http://facebook.com/user/yourcommunity"
                    />
                    {props.errors.urlFacebook && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.urlFacebook}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div className="property-info-edit-field-label">Video</div>
                  <div className="property-info-edit-field-input">
                    <input
                      value={props.values.urlVideo}
                      name="urlVideo"
                      onChange={props.handleChange}
                      className="knock-react-input"
                      placeholder="Link to tour video"
                    />
                  </div>
                  {props.errors.urlVideo && (
                    <span className="knock-react-form-hint knock-react-form-hint-error">
                      {props.errors.urlVideo}
                    </span>
                  )}
                </div>
              </div>
              <div className="full-width">
                <h2 className="side-margin-lg">Description</h2>
                <div className="">
                  <div className="knock-react-flex">
                    <div className="property-info-edit-field-label">Neighborhood</div>
                    <div className="property-info-edit-field-input">
                      <input
                        value={props.values.neighborhood}
                        name="neighborhood"
                        onChange={props.handleChange}
                        className="knock-react-input"
                        placeholder="e.g. Jackson District County"
                      />
                    </div>
                  </div>
                  <div className="knock-react-flex">
                    <div className="property-info-edit-field-label">Year Built</div>
                    <div className="property-info-edit-field-input">
                      <input
                        value={props.values.yearBuilt}
                        name="yearBuilt"
                        pattern="[0-9]*"
                        onChange={props.handleChange}
                        className="knock-react-input"
                        placeholder="e.g. 2009"
                      />
                    </div>
                  </div>
                  <div className="knock-react-flex">
                    <div className="property-info-edit-field-label"># of Units</div>
                    <div className="property-info-edit-field-input">
                      <input
                        value={props.values.numberOfUnits}
                        name="numberOfUnits"
                        pattern="[0-9]*"
                        onChange={props.handleChange}
                        className="knock-react-input"
                        placeholder="e.g. 48"
                      />
                    </div>
                  </div>
                  <div className="knock-react-flex">
                    <div className="property-info-edit-field-label">Property Description</div>
                    <div className="property-info-edit-field-input">
                      <textarea
                        name="description"
                        value={props.values.description}
                        onChange={props.handleChange}
                        onBlur={props.handleChange}
                        placeholder="This is where you can talk about your community and how amazing it is!"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="full-width">
                <h2 className="side-margin-lg">Property Overview</h2>
                <div className="">
                  <div className="knock-react-flex">
                    <div className="property-info-edit-field-label">Notes</div>
                    <div className="property-info-edit-field-input">
                      <textarea
                        name="notes"
                        value={props.values.notes}
                        onChange={props.handleChange}
                        onBlur={props.handleChange}
                        placeholder="Add something that would be important for leasing agents to know!"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <KeySellingFeatures
                community={props.values.keySellingFeatures.community}
                location={props.values.keySellingFeatures.location}
                units={props.values.keySellingFeatures.units}
                handleChange={props.handleChange}
              />
              <div className="full-width">
                <h2 className="side-margin-lg">Brand Colors</h2>
                <div className="property-info-edit-field-container">
                  <div className="knock-react-flex input-auto">
                    <div className="property-info-edit-field-label">
                      <h3>Primary color: </h3>
                    </div>
                    <ColorPicker
                      key={props.values.primaryColor}
                      color={props.values.primaryColor}
                      onChange={color => props.setFieldValue('primaryColor', color, false)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <button className="knock-react-button btn-success" type="submit">
                  <i className="fa fa-lg fa-check-circle" /> Save Basic Information
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }

  setIsEmailValid = (isMailerHandleValid) => {
    this.setState({ isMailerHandleValid });
  }

  submit = (values) => {
    const updatedCommunity = this.props.property.data;
    updatedCommunity.location.name = values.propertyName;

    updatedCommunity.location.address.street = values.propertyAddress;
    updatedCommunity.location.address.city = values.propertyCity;
    updatedCommunity.location.address.state = values.propertyState;
    updatedCommunity.location.address.zip = values.propertyZip;
    updatedCommunity.location.address.neighborhood = values.neighborhood;
    updatedCommunity.location.numberOfUnits = parseInt(values.numberOfUnits, 10);
    updatedCommunity.location.yearBuilt = parseInt(values.yearBuilt, 10);

    updatedCommunity.social.knock_email = `${values.mailerHandle}@${values.mailerDomain}`;
    updatedCommunity.social.email_subject = values.defaultSubjectLine;
    updatedCommunity.social.website = values.urlWebsite;
    updatedCommunity.social.facebook = values.urlFacebook;
    updatedCommunity.key_selling_points = {
      location: values.keySellingFeatures.location,
      units: values.keySellingFeatures.units,
      community: values.keySellingFeatures.community,
    };
    const website = updatedCommunity.website || {};
    updatedCommunity.website = {
      ...website,
      primaryColor: values.primaryColor
    };

    if (updatedCommunity.videos.length > 0) {
      updatedCommunity.videos[0].url = values.urlVideo;
    } else {
      updatedCommunity.videos.push({
        url: values.urlVideo,
        thumb_url: '',
      });
    }

    updatedCommunity.description.short = values.description;
    updatedCommunity.notes = values.notes;

    const updatedProperty = this.props.property;
    updatedProperty.data = updatedCommunity;
    this.props.onChange(updatedProperty);
  }

  validate = (values) => {
    const errors = {};

    if (!values.propertyName) {
      errors.propertyName = 'Required';
    }

    if (!values.propertyOwner) {
      errors.propertyOwner = 'Required';
    }

    if (values.urlWebsite && !validateUrl(values.urlWebsite)) {
      errors.urlWebsite = 'Specified website is invalid';
    }

    if (values.urlFacebook && !validateUrl(values.urlFacebook)) {
      errors.urlFacebook = 'Specified facebook page is invalid';
    }

    if (values.urlVideo && !validateUrl(values.urlVideo)) {
      errors.urlVideo = 'Specified video link is invalid';
    }

    if (AuthenticationService._internalMode && !this.state.isMailerHandleValid) {
      errors.mailerHandle = 'Mailer handle is invalid';
    }

    return errors;
  }
}

export default PropertyInfoEditBasics;
