import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@knockrentals/knock-shared-web';
import { MoreHoriz } from '@material-ui/icons';

const PreferencesSelect = ({
  ariaLabel,
  isIndeterminate,
  name,
  options = [],
  updatePreference,
  value,
}) => {
  const handleChange = (selectedValue) => {
    updatePreference({ [name]: selectedValue });
  };

  const getRenderIndeterminate = () => {
    if (isIndeterminate) {
      return () => <MoreHoriz fontSize="small" data-testid="more-horiz-icon" />;
    }
  };

  const getValue = () => (value === undefined || value === null ? '' : value);

  return (
    <Select
      displayEmpty
      inputProps={{ 'aria-label': ariaLabel }}
      noForm
      onSelectChange={handleChange}
      options={options}
      renderValue={getRenderIndeterminate()}
      value={getValue()}
    />
  );
};

PreferencesSelect.propTypes = {
  ariaLabel: PropTypes.string,
  isIndeterminate: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  updatePreference: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PreferencesSelect;
