export const MEDIUM_GREY = '#bbbbbb';

export const LINEAR_GRADIENT = {
  IMAGE: `linear-gradient(45deg, ${MEDIUM_GREY} 8.33%, #dddddd 8.33%, #dddddd 50%, ${MEDIUM_GREY} 50%, ${MEDIUM_GREY} 58.33%, #dddddd 58.33%, #dddddd 100%)`,
  SIZE: '8.49px 8.49px',
};

export const BOX_SHADOW =
  '0px 4px 16px -2px #18274B05, 0px 2px 12px -4px #18274B0A';

export const getTooltipMessage = (propertyCount) =>
  `Settings are mixed across the ${propertyCount} selected properties`;
