import React from 'react';
import PropTypes from 'prop-types';

import { Box, makeStyles, Text } from '@knockrentals/knock-shared-web';
import { EmailTemplatePreview } from '../../EmailTemplatePreview';
import { TEMPLATE_LABELS } from '../constants';

const useStyles = makeStyles(() => ({
  messageContainer: {
    marginLeft: '-20px',
  },
  messageLabel: {
    marginTop: '40px',
  },
  subjectLabel: {
    marginTop: '5px',
  },
  templateInfoContainer: {
    backgroundColor: '#FFFFFF',
    marginTop: '25px',
    padding: '20px',
  },
}));

const PreviewEmailTemplate = ({ templateName, subject, message }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Text variant="subtitle1">{templateName}</Text>

      <Box className={classes.templateInfoContainer}>
        <Text
          className={classes.subjectLabel}
          color="secondary"
          variant="subtitle2"
        >
          {TEMPLATE_LABELS.SUBJECT}
        </Text>
        <Text>{subject}</Text>

        <Text
          className={classes.messageLabel}
          color="secondary"
          variant="subtitle2"
        >
          {TEMPLATE_LABELS.EMAIL_CONTENT}
        </Text>

        <Box className={classes.messageContainer}>
          <EmailTemplatePreview
            content={message}
            name="template-preview"
            width="100%"
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

PreviewEmailTemplate.propTypes = {
  message: PropTypes.string,
  subject: PropTypes.string,
  templateName: PropTypes.string,
};

export default PreviewEmailTemplate;
