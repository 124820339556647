import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import IntegrationsAPI from '../../../IntegrationsAPI';
import { Toaster } from '@knockrentals/knock-react';
import CircularProgress from '@material-ui/core/CircularProgress';


// TODO: move Integrations Table into own folder, and add these as subcomponents
const TableRow = ({ cells }) => (
  <tr>
    {cells.map((cell, index) => (
      <td key={'cell-' + cell + '-' + index}>{cell}</td>
    ))}
  </tr>
);

const SelectInput = ({ selected, onChange, options }) => {
  return (
    <select style={{ minWidth: '100%' }} value={selected} onChange={onChange}>
      {options.map(({ value, label }, index) => {
        return (
          <option
            key={'select-input-options-' + value + '-' + index}
            value={value}
          >
            {label}
          </option>
        );
      })}
    </select>
  );
};


const SubmitButton = ({ onClick, loading }) => {
  const classes = {
    'btn-md': true,
    'btn-success': !loading,
    'btn-disabled': loading,
  };

  const styles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
  };

  if (loading) {
    // "disabled" style
    styles.backgroundColor = '#EFEFEF';
  }

  const icon = loading ?
                  (<CircularProgress size={13} />)
                :
                  (<i className="fa fa-lg fa-check-circle" />);

  const text = (
    <span
      style={{ marginLeft: '10px' }}
    >
    { loading ? 'Submitting...' : 'Submit' }
    </span>);

  return (
    <button
      onClick={onClick}
      style={styles}
      className={classnames(classes)}
      disabled={loading}
    >
      { icon }
      { text }
    </button>
  );
};


class IntegrationsTable extends Component {
  static propTypes = {
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        community_id: PropTypes.string,
        location: PropTypes.shape({
          name: PropTypes.string,
        }),
        property_id: PropTypes.number,
      })
    ),
    fbPages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        tasks: PropTypes.arrayOf(PropTypes.string),
        access_token: PropTypes.string
      })
    ),
    fbPageMapping: PropTypes.arrayOf(
      PropTypes.shape({
        community_id: PropTypes.string,
        community_name: PropTypes.string,
        facebook_page_id: PropTypes.string
      })
    ),
    fbAccessToken: PropTypes.string.isRequired
  };

  state = {
    loading: false,
    propertiesNotIntegrated: [],
    propertiesAlreadyIntegrated: [],
    fbPageOptions: []
  };

  componentDidMount() {
    const { fbPageMapping, properties } = this.props;

    if (!fbPageMapping || !fbPageMapping.length) {
      const message =
        'No community data provided for Edit Facebook Integrations table.';
      this.handleError('Error loading facebook integrations!', message);
    }

    const propertiesAlreadyIntegrated = fbPageMapping
      .filter(({ community_name }) => community_name !== null)
      .sort(this.sortBy('community_name'));

    const propertiesNotIntegrated = properties
      // get only properties whose community_id is not present in the face book mapping
      .filter(
        ({ community_id }) =>
          !fbPageMapping.some(page => page.community_id === community_id)
      )
      .map(property => ({
        community_id: property.community_id,
        community_name: property.location.name,
        facebook_page_name: null,
        facebook_page_id: null
      }))
      .sort(this.sortBy('community_name'));

    this.setState({
      propertiesNotIntegrated,
      propertiesAlreadyIntegrated,
      fbPageOptions: [...fbPageMapping.sort(this.sortBy('facebook_page_name'))]
    });
  }

  sortBy = property => (a, b) => {
    // ignore casing when sorting
    const nameA = a[property].toUpperCase();
    const nameB = b[property].toUpperCase();
    if (nameA > nameB) return 1;
    else return -1;
  };

  handleError = (toastMessage, logMessage) => {
    if (logMessage) {
      console.error(logMessage);
    }
    this.setState({ loading: false });
    Toaster.showToast(toastMessage, 2000, Toaster.ToastClasses.error);
  };

  handleSuccess = (toastMessage, logMessage) => {
    if (logMessage) {
      console.info(logMessage);
    }
    this.setState({ loading: false });
    Toaster.showToast(toastMessage, 2000, Toaster.ToastClasses.success);
  };

  onSubmit = async data => {
    const { properties, fbPages, fbAccessToken } = this.props;

    this.setState({ loading: true });

    // This is a little bit odd because the existing backend logic requires a propertyId
    // rather than a communityId
    const integrationsPayload = properties.map((property) => {
      const { property_id, community_id } = property;

      // Does this property's community_id match with any of the integrations submitted?
      const {
        community_id: integrationCommunityId,
        facebook_page_id,
      } = data.find(integration => (integration.community_id === community_id)) || {};

      // If there is no match, we want to explicity state that this property has no Facebook integration:
      if(!integrationCommunityId) {
        return {
          property_id,
          user_access_token: null,
          page_access_token: null,
          facebook_page_id: null,
        };
      }

      // Otherwise, find and map the rest of the associated Facebook data:
      const {
        access_token: page_access_token,
      } = fbPages.find(page => (page.id === facebook_page_id));

      return {
        property_id,
        user_access_token: fbAccessToken,
        page_access_token,
        facebook_page_id,
      };
    });

    try {
      const response = await IntegrationsAPI.addMultipleFacebookIntegrations(
        integrationsPayload
      );
      if (response.ok) {
        this.handleSuccess('Saved!');
      } else {
        this.handleError('Error creating integration!', response);
      }
    } catch (error) {
      this.handleError('Error creating integration!', error);
    }
  };

  filterSelectOptions = communityId => page =>
    page.community_id === communityId || page.community_id === null;

  setOptionValues = ({ facebook_page_name, facebook_page_id }) => ({
    value: JSON.stringify({ facebook_page_id, facebook_page_name }),
    label: facebook_page_name
  });

  buildSelectOptions = (communityId, fbPageOptions) => {
    return [
      { value: null, label: '' },
      ...fbPageOptions
        // only get pages that are either associated with the current row id or not associated with any id
        .filter(this.filterSelectOptions(communityId))
        // add the facebook integration data as a value for the select
        .map(this.setOptionValues)
    ];
  };

  onSelectValue = (community_id, community_name) => event => {
    const {
      target: { value }
    } = event;
    if (!value) {
      // if the value is falsy then we are removing an integration that we have previously added
      const newFacebookPageOptions = this.state.fbPageOptions.map(page => {
        if (page.community_id === community_id) {
          return {
            community_id: null,
            community_name: null,
            facebook_page_id: page.facebook_page_id,
            facebook_page_name: page.facebook_page_name
          };
        } else {
          return page;
        }
      });
      this.setState({ fbPageOptions: newFacebookPageOptions });
    } else {
      // otherwise we are adding a new facebook integration or updating an existing one
      const { facebook_page_id, facebook_page_name } = JSON.parse(value);

      const update = {
        community_id,
        community_name,
        facebook_page_id,
        facebook_page_name
      };

      const newFacebookPageOptions = this.state.fbPageOptions.map(page => {
        // user is adding a new integration to this page
        if (page.facebook_page_id === update.facebook_page_id) {
          return update;
        }
        // user is updating a previously added integration
        if (page.community_id === update.community_id) {
          return { ...page, community_id: null, community_name: null };
        } else {
          // this page is not relevant to the user's action
          return page;
        }
      });
      this.setState({ fbPageOptions: newFacebookPageOptions });
    }
  };

  getSelected = communityId => {
    const selected = this.state.fbPageOptions.find(
      page => page.community_id === communityId
    );
    if (selected) {
      const { facebook_page_name, facebook_page_id } = selected;
      return JSON.stringify({ facebook_page_id, facebook_page_name });
    } else {
      return '';
    }
  };

  render() {
    const { fbPages } = this.props;

    const {
      propertiesNotIntegrated,
      propertiesAlreadyIntegrated,
      fbPageOptions,
      loading
    } = this.state;

    if (!fbPages || !fbPages.length) {
      return (
        <div className="row-null">
          <i className="fa fa-frown-o" /> No pages to show for this Facebook
          user
        </div>
      );
    }

    // Properties that have no Facebook page integration yet appear at the top of the table
    // but we don't want to re-order them if the user selects an integration.
    const properties = [
      ...propertiesNotIntegrated,
      ...propertiesAlreadyIntegrated
    ];

    // Each row has different select options depending on what the user has already chosen
    const rows = properties.map(({ community_id, community_name }, index) => {
      const options = this.buildSelectOptions(community_id, fbPageOptions);
      return (
        <TableRow
          key={'properties-row-' + index}
          cells={[
            community_name,
            <SelectInput
              onChange={this.onSelectValue(community_id, community_name)}
              options={options}
              selected={this.getSelected(community_id)}
            />
          ]}
        />
      );
    });

    return (
      <div>
        <table className="wire-frame-table">
          <thead>
            <tr>
              <th>Community Name</th>
              <th>Facebook Page Name</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        <SubmitButton
          onClick={() => this.onSubmit(fbPageOptions)}
          loading={loading}
        />
      </div>
    );
  }
}

export default IntegrationsTable;
