import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import { ColorSwatch } from '../../Components/Icons';

const useStyles = makeStyles({
  colorDisplayWrapper: {
    display: 'inline-block',
    marginLeft: '24px',
    textAlign: 'center',
  },
  colorPickerCard: {
    '& .chrome-picker': {
      display: 'inline-block',
    },
  },
  pickerActions: {
    justifyContent: 'flex-end',
  },
  pickerCardContent: {
    backgroundColor: '#F7F7F8',
    padding: '24px',
  },
  colorSwatch: {
    height: '120px',
    width: '120px',
    zIndex: 'unset',
  },
});

const ColorPickerCard = ({
  color,
  isIndeterminate,
  onCancel,
  onConfirmColor,
}) => {
  const initialColor = isIndeterminate ? null : color;
  const [selectedColor, setSelectedColor] = useState(initialColor);
  const classes = useStyles();

  const handleChange = (pickerColor) => {
    setSelectedColor(pickerColor.hex);
  };

  const handleClearOnClick = () => {
    setSelectedColor('');
  };

  const handleConfirmOnClick = () => {
    onConfirmColor(selectedColor);
  };

  return (
    <Card className={classes.colorPickerCard}>
      <CardContent className={classes.pickerCardContent}>
        <ChromePicker
          color={selectedColor || ''}
          disableAlpha={true}
          onChangeComplete={handleChange}
        />

        <div className={classes.colorDisplayWrapper}>
          <ColorSwatch
            className={classes.colorSwatch}
            color={selectedColor}
            isIndeterminate={selectedColor === null}
          />

          <div>
            <Button color="primary" onClick={handleClearOnClick} variant="text">
              clear color
            </Button>
          </div>
        </div>
      </CardContent>

      <CardActions className={classes.pickerActions}>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>

        <Button
          className={classes.confirmButton}
          disabled={selectedColor === initialColor}
          onClick={handleConfirmOnClick}
        >
          Ok
        </Button>
      </CardActions>
    </Card>
  );
};

ColorPickerCard.propTypes = {
  color: PropTypes.string,
  isIndeterminate: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirmColor: PropTypes.func,
};

export default ColorPickerCard;
