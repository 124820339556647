import React from 'react';

export const BinIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 3.75H17.25M7.3125 13.3125V7.6875M10.6875 13.3125V7.6875M10.6875 0.75H7.3125C7.01413 0.75 6.72798 0.868526 6.517 1.0795C6.30603 1.29048 6.1875 1.57663 6.1875 1.875V3.75H11.8125V1.875C11.8125 1.57663 11.694 1.29048 11.483 1.0795C11.272 0.868526 10.9859 0.75 10.6875 0.75ZM14.145 16.215C14.1241 16.4969 13.9973 16.7603 13.7899 16.9524C13.5825 17.1444 13.3101 17.2508 13.0275 17.25H4.9725C4.68988 17.2508 4.41746 17.1444 4.2101 16.9524C4.00275 16.7603 3.87586 16.4969 3.855 16.215L2.8125 3.75H15.1875L14.145 16.215Z"
        stroke="#5C5C5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
