import React from 'react';
import PropTypes from 'prop-types';

import {
  makeStyles,
  Box,
  Text,
} from '@knockrentals/knock-shared-web';

import { RobotIcon } from '../../../../Components/Icons';

const useStyles = makeStyles({
  templateTabs: {
    borderBottom: '1px solid #E0E0E0',
    marginBottom: '20px',
  },
  messageContainer: {
    textAlign: 'center',
    paddingTop: '70px',
  },
  messageIcon: {
    marginBottom: '20px',
  },
  messageTitle: {
    marginBottom: '8px',
  },
  messageText: {
    color: '#404459',
  },
});

const AiNotEnabledMessage = ({ contact = "Knock Support Representative" }) => {
  const classes = useStyles();
  return (
    <Box className={classes.messageContainer}>
      <Box className={classes.messageIcon}>
        <RobotIcon />
      </Box>
      <Text className={classes.messageTitle} variant="h6">You do not have AI enabled</Text>
      <Text className={classes.messageText}>Contact your {contact} to see how AI can help you!</Text>
    </Box>
  );
};

AiNotEnabledMessage.propTypes = {
  contact: PropTypes.string
};

export default AiNotEnabledMessage;
