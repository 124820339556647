import React from 'react';
import { List, ListItem, ListItemText, Text } from '@knockrentals/knock-shared-web';
import PropTypes from 'prop-types';

import { FEATURE_SWITCHES_DESCRIPTION, FEATURE_SWITCHES_TITLES } from '../BulkPropertiesUpdateDrawer/constants';
import { IndeterminateSwitch } from '../../common/IndeterminateSwitch';

export const FeaturesSwitchers = ({ features, handleUpdateFeatureSwitch }) => {
  return (
    <List className='toggles-list'>
      {Object.keys(features).map((featureKey) => (
        <React.Fragment key={featureKey}>
          <ListItem disableGutters>
            <IndeterminateSwitch
              ariaLabel={FEATURE_SWITCHES_TITLES[featureKey]}
              isChecked={!!features[featureKey]}
              isIndeterminate={features[featureKey] === undefined}
              name={featureKey}
              onChangeHandler={handleUpdateFeatureSwitch}
            />
            <ListItemText primary={FEATURE_SWITCHES_TITLES[featureKey]}></ListItemText>
          </ListItem>
          <Text variant='body2' className='toggles-list-description'>{FEATURE_SWITCHES_DESCRIPTION[featureKey]}</Text>
        </React.Fragment>
      ))}
    </List>
  );
};

FeaturesSwitchers.propTypes = {
  features: PropTypes.shape({
      isAnnotated: PropTypes.bool,
      isStandalone: PropTypes.bool,
      isTranscribed: PropTypes.bool,
  }),
  handleUpdateFeatureSwitch: PropTypes.func,
};