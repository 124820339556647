import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, LoadingOverlay, Text } from '@knockrentals/knock-shared-web';

import FeatureCard from '../../common/FeatureCard';
import { FeatureActions } from '../../common/FeatureActions';
import { WarningBanner } from './WarningBanner';
import { PropertyChips } from '../../common/PropertyChips';
import { FeaturesSwitchers } from '../FeaturesSwitchers/FeaturesSwitchers';
import { SaveSettingsDialog } from '../SaveSettingsDialog/SaveSettingsDialog';
import { useStyles } from './styles';
import { DO_NOT_SHOW_CONFIRMATION_DIALOG_LS_KEY, IS_SCORED, IS_STANDALONE, IS_TRANSCRIBED } from './constants';

import './_BulkPropertiesUpdateDrawer.scss';

export const getPropertiesListBasedOnPropertiesMap = (selectedPropertiesMap) => {
  return Object.keys(selectedPropertiesMap).reduce((propertySettings, propertyId) => {
    const property = selectedPropertiesMap[propertyId];
    if (property.isSelected) {
      propertySettings.push({ 
          propertyId: Number(propertyId),
          propertyName: property.propertyName,
       });
    }

    return propertySettings;
  }, []);
};

export const FEATURES_INITIAL_STATE = { [IS_TRANSCRIBED]: undefined, [IS_SCORED]: undefined, [IS_STANDALONE]: undefined };

export const BulkPropertiesUpdateDrawer = ({ closeDrawer, handleUpdateProperties, isLoading, selectedProperties, selectedPropertiesHaveSameFeatures }) => {
  const [features, setFeatures] = useState(FEATURES_INITIAL_STATE);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [propertiesList, setPropertiesList] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    setPropertiesList(getPropertiesListBasedOnPropertiesMap(selectedProperties));
  }, [selectedProperties]);

  const handleConfirmation = (doNotShowDialogSelected = false) => {
    if (doNotShowDialogSelected) {
      localStorage.setItem(DO_NOT_SHOW_CONFIRMATION_DIALOG_LS_KEY, true);
    }

    setIsConfirmationOpen(false);
    handleUpdateProperties(propertiesList, features);
  };

  const handleSaveOnClick = () => {
    const shouldShowDialog = !(localStorage.getItem(DO_NOT_SHOW_CONFIRMATION_DIALOG_LS_KEY) === 'true');
    if (shouldShowDialog) {
      setIsConfirmationOpen(true);
    } else {
      handleConfirmation();
    }
  };

  const handleUpdateFeatureSwitch = (featureKeyValue) => {
    setFeatures({ ...features, ...featureKeyValue });
  };

  const isSaveButtonDisabled = () => {
    return Object.keys(features).every(featureKey => features[featureKey] === undefined);
  };

  return (
    <section className='call-intel-drawer'>
      <FeatureCard
        closeDrawer={closeDrawer}
        subheader={`${propertiesList.length} ${propertiesList.length === 1 ? 'Property' : 'Properties'}`}
        title="Call Intelligence"
      >
        <CardContent className={classes.callIntelCardContent}>
          {!selectedPropertiesHaveSameFeatures && <WarningBanner />}

          <PropertyChips properties={propertiesList} />

          <Box className='toggle-wrapper'>
            <Text variant="body2" color="textPrimary">Toggle features on or off</Text>

            <FeaturesSwitchers features={features} handleUpdateFeatureSwitch={handleUpdateFeatureSwitch} />
          </Box>
        </CardContent>

        <FeatureActions
          handleCancelOnClick={closeDrawer}
          handleSaveOnClick={handleSaveOnClick}
          primaryText="Save"
          isSaveButtonDisabled={isSaveButtonDisabled()}
        />
      </FeatureCard>

      <SaveSettingsDialog
        selectedPropertiesAmount={propertiesList.length}
        closeDialog={() => setIsConfirmationOpen(false)}
        handleConfirmation={handleConfirmation}
        isOpen={isConfirmationOpen}
      />

      <LoadingOverlay open={isLoading} />
    </section>
  );
};

BulkPropertiesUpdateDrawer.propTypes = {
  closeDrawer: PropTypes.func,
  handleUpdateProperties: PropTypes.func,
  isLoading: PropTypes.bool,
  selectedProperties: PropTypes.objectOf(
    PropTypes.shape({
      isSelected: PropTypes.bool,
      propertyName: PropTypes.string,
    })
  ),
  selectedPropertiesHaveSameFeatures: PropTypes.bool
};