import { apiRemote } from '../../Services/RemoteService';

class SheetsAPI {
  static getPropertySpreadsheets() {
    return apiRemote.get(`admin/property-spreadsheets`).then(response => response.json());
  }

  static refreshPropertySpreadsheets(propertyId) {
    return apiRemote
      .post(`public/refresh-property-spreadsheet/${propertyId}`)
      .then(response => response.json());
  }

  static refreshAllPropertySpreadsheetsOnCompany() {
    return apiRemote
      .post(`admin/property-spreadsheets/refresh-all`)
      .then(response => response.json());
  }
}

export default SheetsAPI;
