import { useEffect, useState } from 'react';
import { NotificationService } from '../../../../../Components/Notifications';
import * as schedulingApi from '../../schedulingApi';
import { setInitialTourHours } from './utils';
import { ERROR_MESSAGES } from '../../constants';
import { SAVED_NOTIFICATION } from '../../../../../constants';

const useSchedulingPreferences = ({
  closeDrawer,
  isBatchEdit,
  selectedProperties = [],
  setErrorMessage,
  updateSchedulingGridData,
}) => {
  const [property = {}] = selectedProperties;

  const [features, setFeatures] = useState({});
  const [isLoading, setIsLoading] = useState(!isBatchEdit);
  const [inPersonTourHours, setInPersonTourHours] = useState(
    setInitialTourHours({})
  );
  const [modifiedStatusMap, setModifiedStatusMap] = useState({});
  const [prescreeningRequirements, setPrescreeningRequirements] = useState({});
  const [selfGuidedTourHours, setSelfGuidedTourHours] = useState(
    setInitialTourHours({})
  );
  const [timezone, setTimezone] = useState('');

  useEffect(() => {
    const fetchSchedulingPreferences = async () => {
      try {
        const {
          inPersonTouringHours = {},
          preferences = {},
          screeningData = {},
          selfGuidedTouringHours = {},
          propertyOwnerTimezone = '',
        } = await schedulingApi.getSchedulingPreferences(property.id);

        setFeatures(preferences);
        setPrescreeningRequirements(screeningData);
        setInPersonTourHours(setInitialTourHours(inPersonTouringHours, false));
        setTimezone(propertyOwnerTimezone);

        setSelfGuidedTourHours(
          setInitialTourHours(selfGuidedTouringHours, false)
        );
      } catch (_e) {
        setErrorMessage(ERROR_MESSAGES.FETCH);
      }

      setIsLoading(false);
    };

    if (!isBatchEdit) {
      fetchSchedulingPreferences();
    }
  }, [isBatchEdit, property.id]);

  const getHasUpdate = (modifiedStatuses) =>
    Object.values(modifiedStatuses).some((status) => status);

  const getIsSaveButtonDisabled = () =>
    !Object.values(modifiedStatusMap).some((preferences) =>
      getHasUpdate(preferences)
    );

  const getUpdatedPreferences = () => {
    const allPreferences = {
      inPersonTourHours,
      selfGuidedTourHours,
      timezone: { timezone },
      features,
      prescreeningRequirements,
    };
    return Object.entries(modifiedStatusMap).reduce(
      (updatedPreferences, [preferenceType, preferences]) => {
        Object.entries(preferences).forEach(([preferenceName, isModified]) => {
          if (isModified) {
            if (!updatedPreferences[preferenceType]) {
              updatedPreferences[preferenceType] = {};
            }
            updatedPreferences[preferenceType][preferenceName] =
              allPreferences[preferenceType][preferenceName];
          }
        });
        return updatedPreferences;
      },
      {}
    );
  };

  const savePreferences = async () => {
    setIsLoading(true);

    const propertyIds = selectedProperties.map((property) => property.id);
    const updatedPreferences = getUpdatedPreferences();

    const payload = {
      propertyIds,
      ...updatedPreferences,
    };

    try {
      await schedulingApi.updateSchedulingPreferences(payload);

      updateSchedulingGridData();
      closeDrawer();
      NotificationService.notify(SAVED_NOTIFICATION);
    } catch (_e) {
      setIsLoading(false);
      setErrorMessage(ERROR_MESSAGES.UPDATE);
    }
  };

  return {
    features,
    isLoading,
    inPersonTourHours,
    isSaveButtonDisabled: getIsSaveButtonDisabled(),
    modifiedStatusMap,
    prescreeningRequirements,
    savePreferences,
    selfGuidedTourHours,
    setFeatures,
    setSelfGuidedTourHours,
    setInPersonTourHours,
    setModifiedStatusMap,
    setPrescreeningRequirements,
    setTimezone,
    timezone,
  };
};

export default useSchedulingPreferences;
