import React from 'react';


export const TableRow = ({ cells }) => (
  <tr>
    {cells.map((cell, index) => (
      <td key={'cell-' + cell + '-' + index}>{cell}</td>
    ))}
  </tr>
);

export const SelectInput = ({ selected, onChange, options }) => {
  return (
    <select style={{ minWidth: '100%' }} value={selected} onChange={onChange}>
      {options.map(({ value, label }, index) => {
        return (
          <option
            key={'select-input-options-' + value + '-' + index}
            value={value}
          >
            {label}
          </option>
        );
      })}
    </select>
  );
};