import React, { useState } from 'react';

import { LoadingOverlay } from '@knockrentals/knock-shared-web';
import { CompanyTemplate } from './CompanyTemplate';
import { CompanyTemplatesList } from './CompanyTemplatesList';
import { ErrorDialog } from '../common/ErrorDialog';
import FeatureDrawer from '../common/FeatureDrawer';
import useCompanyTemplates from './useCompanyTemplates';
import { useMergeTags } from '../common/MergeTags';

import './_CompanyTemplatesPage.scss';

export const DESCRIPTION = '';

const AutoResponderTemplates = () => {
  const [companyTemplate, setCompanyTemplate] = useState(null);
  const { mergeTags } = useMergeTags();
  const {
    companyTemplates,
    errorMessage,
    fetchCompanyTemplates,
    isLoading,
    setErrorMessage,
    updateIsEnabled,
  } = useCompanyTemplates();

  const closeDrawer = () => {
    setCompanyTemplate(null);
  };

  const handleCloseErrorDialog = () => {
    setErrorMessage('');
  };

  return (
    <React.Fragment>
      <LoadingOverlay open={isLoading} />

      <ErrorDialog
        closeDialog={handleCloseErrorDialog}
        errorMessage={errorMessage}
        isOpen={Boolean(errorMessage)}
      />

      <FeatureDrawer disableEnforceFocus isOpen={Boolean(companyTemplate)}>
        <CompanyTemplate
          closeDrawer={closeDrawer}
          companyTemplate={companyTemplate}
          fetchCompanyTemplates={fetchCompanyTemplates}
          mergeTags={mergeTags}
        />
      </FeatureDrawer>

      {companyTemplates && (
        <CompanyTemplatesList
          setCompanyTemplate={setCompanyTemplate}
          templatesByType={companyTemplates}
          updateIsEnabled={updateIsEnabled}
        />
      )}
    </React.Fragment>
  );
};

export default AutoResponderTemplates;
