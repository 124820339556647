import React from 'react';

export const DeleteIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0576 22.3892H5.94219C5.48317 22.3892 5.04294 22.2069 4.71836 21.8823C4.39377 21.5577 4.21143 21.1175 4.21143 20.6584V5.08154H19.7883V20.6584C19.7883 21.1175 19.606 21.5577 19.2814 21.8823C18.9568 22.2069 18.5166 22.3892 18.0576 22.3892Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.40381 17.1969V10.2738"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5962 17.1969V10.2738"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.75 5.08154H23.25"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5962 1.62H9.40386C8.94484 1.62 8.50461 1.80235 8.18003 2.12693C7.85544 2.45151 7.6731 2.89174 7.6731 3.35077V5.08154H16.3269V3.35077C16.3269 2.89174 16.1446 2.45151 15.82 2.12693C15.4954 1.80235 15.0552 1.62 14.5962 1.62Z"
      stroke="#353950"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
