import React from 'react';
import PropTypes from 'prop-types';

import Check from '@material-ui/icons/Check';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import {
  Button,
  ListItem,
  ListItemText,
  makeStyles,
  Text,
} from '@knockrentals/knock-shared-web';
import { NO, YES } from '../../constants';

const useStyles = makeStyles({
  desiredAnswer: {
    margin: '0 20px',
  },
  desiredAnswerText: { textTransform: 'capitalize' },
  prompt: {
    display: 'inline-block',
    marginLeft: '10px',
    width: '220px',
  },
  title: {
    '& .MuiTypography-root': {
      fontWeight: 600,
    },
  },
});

const CustomRequirement = ({
  deleteCustomRequirement,
  desiredAnswer,
  prompt,
}) => {
  const classes = useStyles();
  return (
    <ListItem>
      <ListItemText inset>
        <span className="custom-requirements-check">
          <Check fontSize="small" color="secondary" />
        </span>

        <Text className={classes.prompt} component="span">
          {prompt}
        </Text>

        <Text className={classes.desiredAnswer} component="span">
          Desired answer:
        </Text>

        <Text
          className={classes.desiredAnswerText}
          color="secondary"
          component="span"
        >
          {desiredAnswer ? YES : NO}
        </Text>
      </ListItemText>

      <ListItemSecondaryAction>
        <Button
          color="primary"
          variant="text"
          onClick={deleteCustomRequirement}
        >
          delete
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

CustomRequirement.propTypes = {
  deleteCustomRequirement: PropTypes.func,
  desiredAnswer: PropTypes.bool,
  prompt: PropTypes.string,
};

export default CustomRequirement;
