import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Text, TextField } from '@knockrentals/knock-shared-web';
import {
  PLACE_HOLDER_TEXT,
  SMS_CHARACTER_LIMIT,
  TEMPLATE,
  TEMPLATE_COMPOSE_HEADER,
} from '../constants';

const useStyles = makeStyles(() => ({
  smsTitleInputText: {
    '& .MuiFilledInput-root': {
      background: '#F7F7F8',
      border: 'none',
    },
    '& label.MuiInputLabel-formControl': {
      fontWeight: '400',
      paddingLeft: '12px',
      transform: 'translate(0, 24px) scale(.85)',
      top: '5px',
      left: 0,
    },
    '& .MuiFilledInput-input': {
      padding: '0px 10px 0px 0px',
      marginTop: '-17px',
    },
  },
  smsCharacterCount: {
    position: 'absolute',
    right: '50px',
  },
  smsTemplateSubheader: {
    marginTop: '15px',
  },
}));

const SmsTemplate = ({
  clearValidationErrorMessage,
  handleOnChange,
  message,
  validationErrorMessage,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Text variant="subtitle1" className={classes.smsTemplateSubheader}>
        {TEMPLATE_COMPOSE_HEADER.SMS}
      </Text>
      <Text variant="caption" paragraph gutterBottom>
        {TEMPLATE_COMPOSE_HEADER.SMS_COMPOSE_MESSAGE}
      </Text>
      <TextField
        fullWidth
        error={Boolean(validationErrorMessage.message)}
        helperText={validationErrorMessage.message}
        className={classes.smsTitleInputText}
        inputProps={{
          maxLength: SMS_CHARACTER_LIMIT,
          'aria-label': TEMPLATE.MESSAGE,
        }}
        multiline
        name={TEMPLATE.MESSAGE}
        onFocus={() => {
          clearValidationErrorMessage(TEMPLATE.MESSAGE);
        }}
        onChange={handleOnChange}
        placeholder={PLACE_HOLDER_TEXT.MESSAGE}
        value={message}
        variant="filled"
      />
      <Text variant="caption" className={classes.smsCharacterCount}>
        {message.length}/{SMS_CHARACTER_LIMIT}
      </Text>
    </React.Fragment>
  );
};

SmsTemplate.propTypes = {
  clearValidationErrorMessage: PropTypes.func,
  handleOnChange: PropTypes.func,
  message: PropTypes.string,
  validationErrorMessage: PropTypes.object,
};

export default SmsTemplate;
