import React from 'react';

export const IndeterminateSwitchIcon = () => (
  <svg
    width="34"
    height="14"
    viewBox="0 0 34 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.38">
      <rect width="34" height="14" rx="7" fill="#697FF7" />
    </g>
    <line x1="10" y1="6.5" x2="23" y2="6.5" stroke="white" />
  </svg>
);
