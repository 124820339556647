import React from 'react';

export const AddCircleOutlineIcon = () => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12 21.77C10.0725 21.77 8.18823 21.1987 6.58529 20.1282C4.98235 19.0578 3.73266 17.5362 2.99412 15.7558C2.25558 13.9754 2.06133 12.016 2.43592 10.1252C2.81051 8.23447 3.73712 6.49716 5.09868 5.1328C6.46025 3.76845 8.19566 2.83827 10.0857 2.45981C11.9756 2.08135 13.9354 2.27158 15.7173 3.00647C17.4993 3.74136 19.0234 4.98793 20.0971 6.58867C21.1709 8.18942 21.7461 10.0725 21.75 12C21.7513 13.2817 21.5002 14.5511 21.0109 15.7358C20.5216 16.9204 19.8038 17.9971 18.8985 18.9043C17.9931 19.8115 16.9179 20.5316 15.7342 21.0233C14.5506 21.515 13.2817 21.7687 12 21.77ZM12 3.77002C10.3675 3.77003 8.77162 4.25439 7.41449 5.1618C6.05735 6.06921 4.99994 7.35887 4.37611 8.86752C3.75228 10.3762 3.59008 12.036 3.91003 13.6369C4.22998 15.2378 5.01771 16.7077 6.17349 17.8607C7.32928 19.0137 8.80116 19.7979 10.4028 20.1139C12.0045 20.43 13.6639 20.2638 15.171 19.6363C16.6782 19.0088 17.9653 17.9482 18.8694 16.5889C19.7735 15.2296 20.254 13.6326 20.25 12C20.2421 9.81627 19.3697 7.72451 17.8237 6.18223C16.2776 4.63995 14.1838 3.77265 12 3.77002Z'
      fill='black'
    />
    <path
      d='M12 16.37C11.8019 16.3675 11.6126 16.2876 11.4725 16.1475C11.3324 16.0074 11.2526 15.8182 11.25 15.62V8.42004C11.25 8.22113 11.329 8.03037 11.4697 7.88971C11.6103 7.74906 11.8011 7.67004 12 7.67004C12.1989 7.67004 12.3897 7.74906 12.5303 7.88971C12.671 8.03037 12.75 8.22113 12.75 8.42004V15.62C12.7474 15.8182 12.6676 16.0074 12.5275 16.1475C12.3874 16.2876 12.1981 16.3675 12 16.37Z'
      fill='black'
    />
    <path
      d='M15.6 12.77H8.40002C8.20111 12.77 8.01035 12.691 7.86969 12.5503C7.72904 12.4097 7.65002 12.2189 7.65002 12.02C7.65002 11.8211 7.72904 11.6303 7.86969 11.4897C8.01035 11.349 8.20111 11.27 8.40002 11.27H15.6C15.7989 11.27 15.9897 11.349 16.1304 11.4897C16.271 11.6303 16.35 11.8211 16.35 12.02C16.35 12.2189 16.271 12.4097 16.1304 12.5503C15.9897 12.691 15.7989 12.77 15.6 12.77Z'
      fill='black'
    />
  </svg>
);
