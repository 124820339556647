import { DRIP_CAMPAIGN_ACTION_TYPES } from '../../constants';
import { RECIPIENT_BY_TRIGGER_TYPE } from '../DrawerView/Campaign/constants';

export const normalizeActions = (actions) =>
  actions.map((action) => {
    const { action_type: actionType } = action;

    const template = action.template || {};

    const actionTask =
      actionType === DRIP_CAMPAIGN_ACTION_TYPES.STATE_TRANSITION
        ? {
            nextProspectState: action.next_prospect_state,
          }
        : {
            templateName: template.template_name,
            templateId: template.template_id,
          };

    const hasNoDelay = action.days_to_delay === 0;

    return {
      actionId: action.id,
      actionType,
      daysToDelay: hasNoDelay ? null : action.days_to_delay,
      hasNoDelay,
      ...actionTask,
    };
  });

export const normalizeProperties = (properties) =>
  properties.map((property) => ({
    propertyId: property.id,
    propertyName: property.name,
  }));

export const normalizeCampaign = (campaign) => {
  const { actions = [], properties = [], trigger_type } = campaign;
  return {
    actions: normalizeActions(actions),
    associatedProperties: normalizeProperties(properties),
    campaignId: campaign.id,
    campaignName: campaign.title,
    campaignTrigger: trigger_type,
    recipient: RECIPIENT_BY_TRIGGER_TYPE[trigger_type],
    status: campaign.status,
  };
};

export const normalizeCampaigns = (campaigns) => {
  return campaigns.map((campaign) => {
    const triggerType = campaign.trigger_type;
    return {
      associatedPropertiesCount: campaign.total_property_count,
      campaignId: campaign.campaign_id,
      campaignName: campaign.title,
      campaignTrigger: triggerType,
      recipient: RECIPIENT_BY_TRIGGER_TYPE[triggerType],
      status: campaign.status,
    };
  });
};

export const normalizePropertyList = (properties) =>
  properties.map((property) => ({
    isPropertyAvailableForTriggerType: property.availability,
    propertyId: property.property_id,
    propertyName: property.property_name,
  }));

export const normalizeTemplate = (template) => ({
  contentType: template.content_type,
  message: template.content,
  templateId: template.id,
  templateName: template.title,
  status: template.status.toLowerCase(),
  subject: template.subject,
});

export const sortColumnMap = {
  campaignName: 'title',
  templateName: 'title',
  subject: 'subject',
  status: 'status',
};

export const getQueryString = (params) => {
  const { page, pageSize, sortColumn, sortDirection, contentType } = params;
  const offSet = page * pageSize;
  const contentTypeQuery = contentType ? `&content_type=${contentType}` : '';
  return `?offset=${offSet}&page_size=${pageSize}&sort_column=${sortColumnMap[sortColumn]}&sort_direction=${sortDirection}${contentTypeQuery}`;
};

export const normalizeTemplates = (templates) => {
  return templates.map((template) => ({
    contentType: template.content_type,
    message: template.content || '',
    status: template.status.toLowerCase(),
    subject: template.subject || '',
    templateId: template.id,
    templateName: template.title || '',
  }));
};

export const transformActions = (actions) =>
  actions.map((action) => {
    const actionEntries = [
      ['id', action.actionId],
      ['action_type', action.actionType],
      ['days_to_delay', action.daysToDelay],
      ['next_prospect_state', action.nextProspectState],
      ['template_id', action.templateId],
      ['is_deleted', action.isDeleted],
    ].filter(([_, value]) => value);

    if (action.hasNoDelay) {
      actionEntries.push(['days_to_delay', 0]);
    }

    return Object.fromEntries(actionEntries);
  });

export const transformCampaignSavePayload = (campaign) => {
  const { actions = [], associatedProperties = [] } = campaign;
  return {
    actions: transformActions(actions),
    property_ids: associatedProperties.map((property) => property.propertyId),
    title: campaign.campaignName,
    trigger_type: campaign.campaignTrigger,
    status: campaign.status,
  };
};

export const transformEditedProperties = (properties) =>
  properties.map((property) => {
    const { isDeleted, propertyId } = property;

    const transformedProperty = {
      property_id: propertyId,
    };

    if (isDeleted) {
      transformedProperty.is_deleted = isDeleted;
    }
    return transformedProperty;
  });

export const transformCampaignUpdatePayload = (campaign) => {
  const {
    actions = [],
    associatedProperties = [],
    campaignName,
    campaignTrigger,
    status,
  } = campaign;

  const payloadEntries = [
    ['actions', actions.length && transformActions(actions)],
    ['title', campaignName],
    ['trigger_type', campaignTrigger],
    ['status', status],
    [
      'properties',
      associatedProperties.length &&
        transformEditedProperties(associatedProperties),
    ],
  ].filter(([_, value]) => value);

  return Object.fromEntries(payloadEntries);
};

export const transformTemplateUpdatePayload = (template) => {
  const { message, subject, status, templateName } = template;

  const payloadEntries = [
    ['status', status],
    ['subject', subject],
    ['text', message],
    ['title', templateName],
  ].filter(([_, value]) => value);

  return Object.fromEntries(payloadEntries);
};

export const getPropertyListQueryString = ({ campaignId, triggerType }) => {
  const queryParams = [];

  if (campaignId) {
    queryParams.push(`campaign_id=${campaignId}`);
  }
  if (triggerType) {
    queryParams.push(`trigger_type=${triggerType}`);
  }

  return queryParams.length ? `?${queryParams.join('&')}` : '';
};
