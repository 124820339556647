import { apiRemote } from '../../Services/RemoteService';

class IntegrationsAPI {
  static getProperties() {
    return apiRemote
      .get(`admin/integrations/properties`)
      .then(response => response.json());
  }

  static updateProperty(propertyId, updatedProperty) {
    return apiRemote.put(`admin/integrations/properties/${propertyId}`, {
      ...updatedProperty,
    });
  }

  static addIntegration(propertyId, vendor, newIntegration) {
    return apiRemote.post(`admin/integrations/properties/${propertyId}`, {
      integration: { vendor, ...newIntegration },
    });
  }

  static addFacebookIntegration(propertyId, newIntegration) {
    return apiRemote.post(
      `admin/integrations/properties/${propertyId}/facebook`,
      {
        integration: { ...newIntegration },
      }
    );
  }

  static addMultipleFacebookIntegrations(integrations) {
    return apiRemote.post(
      `admin/integrations/properties/facebook`,
      integrations
    );
  }

  static removeIntegration(propertyId, credentialId, vendor) {
    return apiRemote.delete(
      `admin/integrations/properties/${propertyId}/credential/${credentialId}`,
      { vendor }
    );
  }

  static removeFacebookIntegration(propertyId, credentialId) {
    return apiRemote.delete(
      `admin/integrations/properties/${propertyId}/facebook/${credentialId}`
    );
  }

  static synchronizeIntegration(propertyId, vendorPropertyId, vendor) {
    return apiRemote.post(
      `admin/integrations/properties/${propertyId}/credential/${vendorPropertyId}/synchronize`,
      { vendor }
    );
  }

  static getFacebookPages(accessToken) {
    return apiRemote
      .get(`admin/integrations/facebook/pages?access_token=${accessToken}`)
      .then(response => response.json());
  }

  static addEngrainIntegration = async (newIntegrations) => {
    const response =  await apiRemote.post(
      `admin/integrations/properties/engrain`,
      { ...newIntegrations }
    );
    if (response.status !== 201) {
      throw new Error(response.message);
    }
    return response;
  }

  static removeEngrainIntegration = async (property_id, engrain_unitmap_id) => {
    const response = await apiRemote.delete(
      `admin/integrations/properties/${property_id}/engrain/${engrain_unitmap_id}`
    );
    if (response.status !== 200) {
      throw new Error(response.message);
    }
    return response;
  }
}

export default IntegrationsAPI;
