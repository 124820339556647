import React from 'react';
import { PropTypes } from 'prop-types';
import ConfirmationDialog from '../../../common/ConfirmationDialog';
import {
  CAMPAIGN_CONFIRMATION_MESSAGES,
  CONFIRMATION_BUTTON_TEXT,
  CONFIRMATION_SECONDARY_BUTTON_TEXT,
  CONFIRMATION_TITLES,
  TEMPLATE_CONFIRMATION_MESSAGES,
} from '../constants';

const Confirmation = ({
  confirmationMode,
  handleConfirmation,
  isCampaign = false,
  setConfirmationMode,
}) => {
  const messages = isCampaign
    ? CAMPAIGN_CONFIRMATION_MESSAGES
    : TEMPLATE_CONFIRMATION_MESSAGES;

  return (
    <ConfirmationDialog
      closeDialog={() => setConfirmationMode(null)}
      handleConfirmation={handleConfirmation}
      isOpen={Boolean(confirmationMode)}
      message={messages[confirmationMode]}
      primaryButtonText={CONFIRMATION_BUTTON_TEXT[confirmationMode]}
      secondaryButtonText={CONFIRMATION_SECONDARY_BUTTON_TEXT[confirmationMode]}
      title={CONFIRMATION_TITLES[confirmationMode]}
    />
  );
};

Confirmation.propTypes = {
  confirmationMode: PropTypes.string,
  handleConfirmation: PropTypes.func,
  isCampaign: PropTypes.bool,
  setConfirmationMode: PropTypes.func,
};

export default Confirmation;
