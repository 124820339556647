import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Button = (props) => {
  const {
    className,
    style,
    onClick,
    iconType,
    children,
  } = props;

  const buttonClassNames = classnames(['knock-react-button', className]);
  return (
    <button
      className={buttonClassNames}
      onClick={onClick}
      style={style}
    >
      { iconType && <i className={`fa fa-${iconType}`} /> }{' '}{ children }
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  iconType: PropTypes.string,
  children: PropTypes.node,
};

export default Button;
