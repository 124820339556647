export const getOfferTimes = () => {
  const offerTimes = [{
    label: '-- Select --',
    value: 0,
    disabled: true
  }];

  for (let i = 6; i < 31; i++) {
    offerTimes.push({
      label: `${i} seconds`,
      value: i
    });
  }

  return offerTimes;
};

export const getChatRollovers = () => {
  const chatRollovers = [{
    label: 'None',
    value: 'NONE',
  }, {
    label: 'RPCC',
    value: 'RPCC',
  }];

  return chatRollovers;
};