import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, makeStyles } from '@knockrentals/knock-shared-web';

import ActionTemplatePreview from './ActionTemplatePreview';
import { DrawerActions } from '../../../DrawerActionButtons';
import { DripCampaignCard } from '../../../DripCampaignCard';
import FeatureDrawer from '../../../../common/FeatureDrawer';
import FeatureCardContent from '../../../../common/FeatureCardContent';
import { SelectTemplateDataTable } from '../../SelectTemplateDataTable';
import SelectedTemplatePreview from './SelectedTemplatePreview';
import { MODES } from '../../../constants';

export const SELECT_SUB_HEADER = 'Select Template';
export const PREVIEW_SUB_HEADER = 'View Template';

const useStyles = makeStyles(() => ({
  templateCardContent: {
    background: '#FFFFFF',
  },
}));

const ActionTemplate = ({
  closeParentDrawer,
  mode,
  setActionTemplate,
  template,
  updateActions,
}) => {
  const classes = useStyles();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const { actionIndex, templateId } = template;

  const subheader = templateId ? PREVIEW_SUB_HEADER : SELECT_SUB_HEADER;

  const closeDrawer = () => {
    setActionTemplate(null);
  };

  const handleSelectOnClick = () => {
    const {  contentType, templateId, templateName } = selectedTemplate;

    updateActions(actionIndex, {
      actionType: contentType,
      templateId: templateId,
      templateName: templateName,
    });

    closeDrawer();
  };

  const showSelectTemplateDataTable = () => {
    setActionTemplate({ actionIndex });
  };

  return (
    <DripCampaignCard
      closeDrawer={closeParentDrawer}
      handleBackButtonOnClick={closeDrawer}
      subheader={subheader}
    >
      <FeatureDrawer isOpen={Boolean(selectedTemplate)}>
        <SelectedTemplatePreview
          closeParentDrawer={closeParentDrawer}
          closeDrawer={() => setSelectedTemplate(null)}
          handleSelectOnClick={handleSelectOnClick}
          template={selectedTemplate}
        />
      </FeatureDrawer>

      <FeatureCardContent className={classes.templateCardContent}>
        {templateId ? (
          <ActionTemplatePreview
            templateId={templateId}
          />
        ) : (
          <SelectTemplateDataTable
            isActionTemplates
            setSelectedTemplate={setSelectedTemplate}
          />
        )}
      </FeatureCardContent>

      <DrawerActions>
        {mode === MODES.FORM && templateId && (
          <Button
            color="primary"
            onClick={showSelectTemplateDataTable}
            variant="outlined"
          >
            Select Different Template
          </Button>
        )}
      </DrawerActions>
    </DripCampaignCard>
  );
};

ActionTemplate.propTypes = {
  closeParentDrawer: PropTypes.func,
  mode: PropTypes.string,
  setActionTemplate: PropTypes.func,
  template: PropTypes.shape({
    actionIndex: PropTypes.number,
    contentType: PropTypes.string,
    templateId: PropTypes.number,
  }),
  updateActions: PropTypes.func,
};

export default ActionTemplate;
