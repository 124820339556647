import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  CardContent,
  Divider,
  InputText,
  LoadingOverlay,
  NamedColors,
  Text,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import { AddIconButton } from '../../common/AddIconButton';
import { AddProperty } from './AddProperty';
import { AssignableProperties } from './AssignableProperties';
import { CompanyTemplateActions } from './CompanyTemplateActions';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { CharacterLimitedTextArea } from '../../common/CharacterLimitedTextArea';
import { EmailTemplateEditor } from '../../../../EmailTemplateEditor';
import { ErrorDialog } from '../../common/ErrorDialog';
import FeatureDrawer from '../../common/FeatureDrawer';
import CompanyTemplateCard from './CompanyTemplateCard';
import { PropertiesDataTable } from './PropertiesDataTable';

import useCompanyTemplate from './useCompanyTemplate';
import { validateMessage } from './utils';
import { CHAR_LIMITS } from '../../constants';
import {
  COMPANY_TEMPLATE,
  CONFIRMATION_BUTTON_TEXT,
  CONFIRMATION_MESSAGES,
  CONFIRMATION_MODES,
  CONFIRMATION_TITLES,
  EMAIL_PLACE_HOLDER_TEXT,
  MESSAGE_TYPES,
  PROPERTY_TABLE_VIEWS,
  TEMPLATE_TYPES,
  TEMPLATE_TYPE_TITLES,
  VALIDATION_ERROR_MESSAGES,
} from '../constants';
import './_CompanyTemplate.scss';

const useStyles = makeStyles(() => ({
  assignablePropertiesDivider: {
    margin: '32px 0',
  },
  companyTemplateCardContent: {
    flexGrow: 1,
    background: '#F7F7F8',
    overflowY: 'auto',
    padding: '26px 40px 40px',
    '& .cke_chrome': {
      border: `2px solid ${NamedColors.slate[300]}`,
    },
    '& .error': {
      '& .cke_chrome': {
        borderColor: '#F599A8',
      },
    },
  },
  textLabel: { marginLeft: '5px', marginBottom: '3px' },
  errorText: {
    marginTop: '3px',
    marginLeft: '5px',
  },
}));

const IS_SUBJECT_VISIBLE = false;

const CompanyTemplate = ({
  companyTemplate: {
    isDefault: isDefaultTemplate,
    propertyCount,
    templateId,
    templateType,
  } = {},
  closeDrawer,
  fetchCompanyTemplates,
  mergeTags,
}) => {
  const [confirmationMode, setConfirmationMode] = useState(null);
  const [isAddPropertyOpen, setIsAddPropertyOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});

  const classes = useStyles();

  const closeConfirmationDialog = () => {
    setConfirmationMode(null);
  };

  const {
    addProperties,
    assignablePropertiesMap,
    deleteCompanyTemplate,
    errorMessage,
    isAssignablePropertiesVisible,
    isEditing,
    isLoading,
    isSaveButtonDisabled,
    isTemplateDeletableByUser,
    handleAllAssignableChange,
    hasAnyPropertySelectedOrEnabled,
    properties,
    saveCompanyTemplate,
    setAssignablePropertiesMap,
    setErrorMessage,
    setTablePropertiesMap,
    template,
    templateMessageType,
    tablePropertiesMap,
    updateTemplate,
  } = useCompanyTemplate({
    closeConfirmationDialog,
    closeDrawer,
    fetchCompanyTemplates,
    isDefault: isDefaultTemplate,
    templateId,
    templateType,
  });

  const {
    assignableProperties = [],
    isEveryPropertyAssignable,
    isDefault,
    message = '',
    subject = '',
    templateName = '',
  } = template;

  const subheader = isEditing ? templateName : 'Create New Template';

  const clearErrorMessage = (field) => {
    setErrorMessages((prevState) => {
      const updatedMessages = { [field]: '' };

      return { ...prevState, ...updatedMessages };
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    updateTemplate({ [name]: value });
  };

  const handleOnChangeMessage = (value) => {
    if (errorMessages.message) {
      clearErrorMessage('message');
    }

    updateTemplate({ [COMPANY_TEMPLATE.MESSAGE]: value });
  };

  const handleOnFocus = (e) => {
    clearErrorMessage(e.target.name);
  };

  const validateCompanyTemplate = () => {
    const messages = {};
    if (!templateName.trim()) {
      messages.templateName = VALIDATION_ERROR_MESSAGES.TEMPLATE_NAME;
    }

    const messageValidationError = validateMessage(
      templateMessageType,
      message.trim()
    );
    if (messageValidationError) {
      messages.message = messageValidationError;
    }

    if (Object.keys(messages).length > 0) {
      return messages;
    }
  };

  const handleSaveOnClick = () => {
    const hasError = Object.values(errorMessages).some((message) => message);
    if (hasError) {
      return;
    }

    const messages = validateCompanyTemplate();
    if (messages) {
      setErrorMessages(messages);
      return;
    }

    setConfirmationMode(
      isEditing ? CONFIRMATION_MODES.EDIT : CONFIRMATION_MODES.CREATE
    );
  };

  const handleConfirmation = () => {
    confirmationMode === CONFIRMATION_MODES.DELETE
      ? deleteCompanyTemplate()
      : saveCompanyTemplate();
  };

  const handleCloseErrorDialog = () => {
    closeDrawer();
    setErrorMessage('');
  };

  const closePropertyDrawer = () => setIsAddPropertyOpen(false);

  const clearPropertiesValidationError = () => {
    if (errorMessages.properties) {
      clearErrorMessage('properties');
    }
  };

  return (
    <div className="company-template-container">
      <LoadingOverlay open={isLoading} />

      <ErrorDialog
        closeDialog={handleCloseErrorDialog}
        errorMessage={errorMessage}
        isOpen={Boolean(errorMessage)}
      />

      <FeatureDrawer isOpen={isAddPropertyOpen}>
        <AddProperty
          addProperties={addProperties}
          closeDrawer={closePropertyDrawer}
          templateName={templateName}
          templatePropertiesMap={tablePropertiesMap}
          templateType={templateType}
          isDefaultTemplate={isDefaultTemplate}
        />
      </FeatureDrawer>

      <ConfirmationDialog
        closeDialog={closeConfirmationDialog}
        handleConfirmation={handleConfirmation}
        isOpen={Boolean(confirmationMode)}
        message={CONFIRMATION_MESSAGES[confirmationMode]}
        primaryButtonText={CONFIRMATION_BUTTON_TEXT[confirmationMode]}
        title={CONFIRMATION_TITLES[confirmationMode]}
      />

      <CompanyTemplateCard
        closeDrawer={closeDrawer}
        subheader={subheader}
        title={TEMPLATE_TYPE_TITLES[templateType]}
      >
        <CardContent className={classes.companyTemplateCardContent}>
          <InputText
            disabled={isDefault}
            error={Boolean(errorMessages.templateName)}
            helperText={errorMessages.templateName}
            fullWidth
            inputProps={{ maxLength: 100 }}
            label="Template Name"
            name={COMPANY_TEMPLATE.TEMPLATE_NAME}
            onChange={handleOnChange}
            onFocus={handleOnFocus}
            value={templateName}
            requiredMessage="required"
          />

          {IS_SUBJECT_VISIBLE &&
            templateType === TEMPLATE_TYPES.AUTORESPONSE_EMAIL && (
              <InputText
                disabled={isDefault}
                fullWidth
                inputProps={{ maxLength: 100 }}
                label="Subject"
                name={COMPANY_TEMPLATE.SUBJECT}
                onChange={handleOnChange}
                value={subject}
              />
            )}

          <div
            className={classnames(
              'message-container',
              errorMessages.message && 'error'
            )}
          >
            {templateMessageType === MESSAGE_TYPES.EMAIL && (
              <div
                className={classnames(isDefault && 'editor-disabled')}
                data-testid="template-editor-wrapper"
              >
                <Text
                  variant="subtitle2"
                  color={errorMessages.message ? 'error' : 'secondary'}
                  className={classes.textLabel}
                >
                  Message
                </Text>

                <EmailTemplateEditor
                  content={message}
                  isSourceModeEnabled
                  mergeTags={mergeTags}
                  minResizeWidth={'100%'}
                  name={COMPANY_TEMPLATE.MESSAGE}
                  onChange={handleOnChangeMessage}
                  placeHolderText={EMAIL_PLACE_HOLDER_TEXT}
                />
              </div>
            )}

            {templateMessageType === MESSAGE_TYPES.EMAIL &&
              errorMessages.message && (
                <Text
                  color="error"
                  className={classes.errorText}
                  variant="caption"
                >
                  {errorMessages.message}
                </Text>
              )}

            {(templateMessageType === MESSAGE_TYPES.SMS ||
              templateMessageType === MESSAGE_TYPES.PLAIN_TEXT) && (
              <CharacterLimitedTextArea
                isDisabled={isDefault}
                characterLimit={
                  templateMessageType === MESSAGE_TYPES.SMS
                    ? CHAR_LIMITS.short
                    : CHAR_LIMITS.medium
                }
                error={Boolean(errorMessages.message)}
                helperText={errorMessages.message}
                hideOptionalLabel
                label="Message"
                name={COMPANY_TEMPLATE.MESSAGE}
                value={message}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
              />
            )}

            {templateMessageType === MESSAGE_TYPES.URL && (
              <InputText
                disabled={isDefault}
                error={Boolean(errorMessages.message)}
                fullWidth
                helperText={errorMessages.message}
                hideOptionalLabel
                label="Message"
                name={COMPANY_TEMPLATE.MESSAGE}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                placeholder="https://www.example.com"
                type="url"
                value={message}
              />
            )}
          </div>

          {isAssignablePropertiesVisible && (
            <React.Fragment>
              <Divider className={classes.assignablePropertiesDivider} />
              <AssignableProperties
                assignableProperties={assignableProperties}
                assignablePropertiesMap={assignablePropertiesMap}
                handleAllAssignableChange={handleAllAssignableChange}
                isEditing={isEditing}
                isEveryPropertyAssignable={isEveryPropertyAssignable}
                propertyCount={propertyCount}
                setAssignablePropertiesMap={setAssignablePropertiesMap}
              />
              <Divider className={classes.assignablePropertiesDivider} />
            </React.Fragment>
          )}

          <div className="assigned-properties">
            {isEditing ? (
              <Text
                variant="subtitle2"
                color="secondary"
                className={classes.textLabel}
              >
                {`Properties included in ${template.templateName}`}
              </Text>
            ) : (
              <React.Fragment>
                <Text
                  variant="subtitle2"
                  color={errorMessages.properties ? 'error' : 'secondary'}
                  className={classes.textLabel}
                >
                  Select Properties for New Template
                </Text>
                <Text
                  variant="subtitle2"
                  color={errorMessages.properties ? 'error' : 'secondary'}
                  className={classes.textLabel}
                >
                  These properties will be removed from their current template.
                </Text>
              </React.Fragment>
            )}

            {!isLoading && (
              <React.Fragment>
                <div
                  className={errorMessages.properties && 'property-error'}
                  onFocus={clearPropertiesValidationError}
                  tabIndex={0}
                >
                  <PropertiesDataTable
                    isEditing={isEditing}
                    properties={properties}
                    propertyCount={propertyCount}
                    tablePropertiesMap={tablePropertiesMap}
                    setTablePropertiesMap={setTablePropertiesMap}
                    view={PROPERTY_TABLE_VIEWS.MANAGE_PROPERTY_ASSIGNMENT}
                  />
                </div>
                {errorMessages.properties && (
                  <Text
                    color="error"
                    className={classes.errorText}
                    variant="caption"
                  >
                    {errorMessages.properties}
                  </Text>
                )}
              </React.Fragment>
            )}
          </div>

          {isEditing && (
            <AddIconButton
              handleOnClick={() => setIsAddPropertyOpen(true)}
              primaryText="Select Additional Properties"
            />
          )}
        </CardContent>

        <CompanyTemplateActions
          handleCancelOnClick={closeDrawer}
          handleDeleteOnClick={() =>
            setConfirmationMode(CONFIRMATION_MODES.DELETE)
          }
          handlePreviewOnClick={() => {}}
          handleSaveOnClick={handleSaveOnClick}
          isDeleteButtonVisible={isTemplateDeletableByUser}
          isSaveButtonDisabled={isSaveButtonDisabled}
        />
      </CompanyTemplateCard>
    </div>
  );
};

CompanyTemplate.propTypes = {
  closeDrawer: PropTypes.func,
  companyTemplate: PropTypes.shape({
    propertyCount: PropTypes.number,
    templateId: PropTypes.number,
    templateType: PropTypes.string,
  }),
  fetchCompanyTemplates: PropTypes.func,
  mergeTags: PropTypes.array,
};

export default CompanyTemplate;
