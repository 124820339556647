import { useState, useEffect } from 'react';

const useAddAiProperty = ({
  intentTemplates,
  templateId,
  templatePropertiesMap,
}) => {
  const [propertiesTemplatesList, setPropertiesTemplatesList] = useState([]);
  const [tablePropertiesMap, setTablePropertiesMap] = useState({});

  const getMappedPropertiesFromIntentTemplates = () => {
    const propertiesTemplatesList = [];
    const mappedProperties = {};
    if (intentTemplates && intentTemplates.templates) {
      intentTemplates.templates.forEach((template) => {
        if (template.templateId === templateId) {
          return;
        }
        template.properties.forEach((property) => {
          if (!templatePropertiesMap[property.property_id]) {
            propertiesTemplatesList.push({
              propertyId: property.property_id,
              propertyName: property.property_name,
              assignedTemplateName: template.templateName,
            });
            mappedProperties[property.property_id] = {
              propertyId: property.property_id,
              name: property.property_name,
              template: {
                id: template.templateId,
                name: template.templateName,
              },
            };
          }
        });
      });
    }
    return { mappedProperties, propertiesTemplatesList };
  };

  useEffect(() => {
    const { mappedProperties, propertiesTemplatesList } =
      getMappedPropertiesFromIntentTemplates();
    setTablePropertiesMap(mappedProperties);
    setPropertiesTemplatesList(propertiesTemplatesList);
  }, [intentTemplates]);

  return {
    propertiesTemplatesList,
    setTablePropertiesMap,
    tablePropertiesMap,
  };
};

export default useAddAiProperty;
