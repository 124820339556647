import { apiRemote } from '../../Services/RemoteService';
import { transformAccessSettings } from './utils';

class AccessAPI {
  static getCompanyAccessSettings = async () => {
    try {
      const response = await apiRemote.get(`admin/access`);

      if (!response.ok || response.status !== 200) {
        throw new Error();
      }

      return response.json();
    } catch {
      throw new Error();
    }
  };

  static setCompanyAccessSettings(updatedAccessSettings) {
    return apiRemote
      .post(`admin/access/`, { ...updatedAccessSettings })
      .then((response) => response.json())
      .catch((e) => e);
  }

  static updateAccessSettings = async (updatedAccessSettings) => {
    const payload = transformAccessSettings(updatedAccessSettings);
    try {
      const response = await apiRemote.patch(`admin/access`, payload);

      if (!response.ok || response.status !== 204) {
        throw new Error();
      }

      return;
    } catch {
      throw new Error();
    }
  };
}

export default AccessAPI;
