import { useEffect, useState } from 'react';
import { AuthenticationService, Toaster } from '@knockrentals/knock-react';

import {
  createAiCompanyTemplate,
  deleteAiCompanyTemplate,
  editAiCompanyTemplate,
  getAiCompanyTemplate,
} from '../AiTemplateApi/aiTemplateApi';
import { MERGE_TAG_LIST_OF_URLS } from '../AiConstants';

export const getSelectedPropertyIds = (propertiesMap) =>
  Object.values(propertiesMap).reduce((propertyIds, property) => {
    if (property.isSelected) {
      propertyIds.push(property.propertyId);
    }
    return propertyIds;
  }, []);

export const removeHashFromURLMergeTag = (message = '') => {
  let processedMessage = message;
  MERGE_TAG_LIST_OF_URLS.forEach(mergeTag => {
    const regex = new RegExp(`a href="#${mergeTag}"`, 'g');
    processedMessage = processedMessage.replace(regex, `a href="${mergeTag}"`);
  });

  return processedMessage;
};

export const addHashToMergeTag = (message = '') => {
  let processedMessage = message;
  MERGE_TAG_LIST_OF_URLS.forEach(mergeTag => {
    const regex = new RegExp(`a href="${mergeTag}"`, 'g');
    processedMessage = processedMessage.replace(regex, `a href="#${mergeTag}"`);
  });

  return processedMessage;
};

const useAiTemplate = ({
  closeDrawer,
  fetchCompanyTemplates,
  intentTemplates,
  templateId,
}) => {
  const isEditing = Boolean(templateId);
  const [totalIntentAssignedProperties, setTotalIntentAssignedProperties] =
    useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState({});
  const [propertiesTemplatesList, setPropertiesTemplatesList] = useState([]);
  const [tablePropertiesMap, setTablePropertiesMap] = useState({});
  const [companyId, setCompanyId] = useState();

  useEffect(() => {
    const tokenPayload = AuthenticationService.getTokenPayload() || {};
    const { company_id: companyId } = tokenPayload.sub || {};
    setCompanyId(companyId);
  }, []);

  useEffect(() => {
    const fetchCompanyTemplate = async () => {
      try {
        setIsLoading(true);
        const template = await getAiCompanyTemplate(templateId);
        template.message = removeHashFromURLMergeTag(template.message);
        setTemplate(template);
      } catch (error) {
        setErrorMessage(error.message);
      }
      setIsLoading(false);
    };

    if (templateId) {
      fetchCompanyTemplate();
    }
  }, [templateId]);

  const getMappedPropertiesFromIntentTemplates = () => {
    const propertiesTemplatesList = [];
    const mappedProperties = {};
    if (intentTemplates && intentTemplates.templates) {
      intentTemplates.templates.forEach((template) => {
        if (isEditing && template.templateId !== templateId) {
          return;
        }
        template.properties.forEach((property) => {
          propertiesTemplatesList.push({
            propertyId: property.property_id,
            propertyName: property.property_name,
            assignedTemplateName: template.templateName,
          });
          mappedProperties[property.property_id] = {
            propertyId: property.property_id,
            name: property.property_name,
            isSelected: isEditing ? true : false,
            template: {
              id: template.templateId,
              name: template.templateName,
            },
          };
        });
      });
    }
    return { mappedProperties, propertiesTemplatesList };
  };

  useEffect(() => {
    const { mappedProperties, propertiesTemplatesList } =
      getMappedPropertiesFromIntentTemplates();
    setTablePropertiesMap(mappedProperties);
    setPropertiesTemplatesList(propertiesTemplatesList);
  }, []);

  useEffect(() => {
    let totalIntentAssignedProperties = 0;
    intentTemplates.templates.forEach((template) => {
      totalIntentAssignedProperties += template.assignedPropertiesCount;
    });

    setTotalIntentAssignedProperties(totalIntentAssignedProperties);
  }, [intentTemplates]);

  const createAiTemplate = async () => {
    setIsLoading(true);
    const { message, subject, templateName } = template;

    try {
      const payload = {
        body: addHashToMergeTag(message),
        subject,
        template_name: templateName,
        property_ids: getSelectedPropertyIds(tablePropertiesMap),
        intent_id: intentTemplates.intentId,
        company_id: companyId,
      };

      await createAiCompanyTemplate(payload);

      closeDrawer();

      await fetchCompanyTemplates();

      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  };

  const editAiTemplate = async () => {
    setIsLoading(true);
    const { message, subject, templateName } = template;

    try {
      const payload = {
        body: addHashToMergeTag(message),
        subject,
        template_name: templateName,
        intent_id: intentTemplates.intentId,
        id: templateId,
        company_id: companyId,
        property_ids: getSelectedPropertyIds(tablePropertiesMap),
      };

      await editAiCompanyTemplate(payload);

      closeDrawer();

      await fetchCompanyTemplates();

      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  };

  const deleteAiTemplate = async () => {
    setIsLoading(true);

    try {
      const payload = {
        intent_id: intentTemplates.intentId,
        id: templateId,
        company_id: companyId,
      };
      await deleteAiCompanyTemplate(payload);

      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.message);
    }
    fetchCompanyTemplates();
    closeDrawer();
  };

  const saveAiTemplate = isEditing ? editAiTemplate : createAiTemplate;

  const updateTemplate = (templateUpdate) => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      ...templateUpdate,
    }));
  };

  const addProperties = (properties) => {
    const mappedProperties = {};
    const addedProperties = properties.map((property) => {
      mappedProperties[property.propertyId] = {
        propertyId: property.propertyId,
        name: property.propertyName,
        isSelected: true,
        template: {
          id: template.templateId,
          name: template.templateName,
        },
      };
      return {
        propertyId: property.propertyId,
        propertyName: property.propertyName,
        assignedTemplateName: template.templateName,
      };
    });

    setPropertiesTemplatesList((prevState) => [
      ...prevState,
      ...addedProperties,
    ]);

    setTablePropertiesMap((prevProperties) => ({
      ...prevProperties,
      ...mappedProperties,
    }));
  };

  return {
    addProperties,
    deleteAiTemplate,
    errorMessage,
    isEditing,
    isLoading,
    propertiesTemplatesList,
    saveAiTemplate,
    setErrorMessage,
    setTablePropertiesMap,
    tablePropertiesMap,
    template,
    totalIntentAssignedProperties,
    updateTemplate,
  };
};

export default useAiTemplate;
