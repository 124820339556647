import { LINEAR_GRADIENT } from '../utils';
export const DEFAULT_EDITOR_HEIGHT = 50;
export const DEFAULT_MARGIN_TOP = 8;

const getBackgroundColor = (brandColor, isBrandColorMixed) => {
  if (isBrandColorMixed) {
    return `background-image: ${LINEAR_GRADIENT.IMAGE};
    background-size: ${LINEAR_GRADIENT.SIZE};\n`;
  }
  return brandColor ? `background-color: ${brandColor};\n\t` : '';
};

const getTextColor = (textColor) =>
  textColor ? `\n\tcolor: ${textColor};` : '';

export const getCustomStyleText = ({
  brandColor,
  bodyClassName,
  isBrandColorMixed,
  textColor,
}) => {
  const backgroundColorStyle = getBackgroundColor(
    brandColor,
    isBrandColorMixed
  );
  const textColorStyle = getTextColor(textColor);

  return (
    `.${bodyClassName} {
    ${backgroundColorStyle}` +
    `box-sizing: border-box;` +
    `${textColorStyle} 
    margin-top: ${DEFAULT_MARGIN_TOP}px;
    height: ${DEFAULT_EDITOR_HEIGHT}px;
    overflow: hidden;

      ol, p, ul {
        line-height: 16px;
        margin: 0;
      }
  }`
  );
};
