import React, { Component } from 'react';
import SourcesAPI from '../SourcesAPI';
import { Modal, ModalHeader, ModalContent, ModalFooter, Toaster } from '@knockrentals/knock-react';

import './_greetingRecordingEditor.scss';

import * as _ from 'lodash';

class GreetingRecordingEditor extends Component {
  state = {
    recordingType: 'prospect', // either 'prospect' or 'resident'
    originalRecordingUrl: '',
    uploadedRecordingUrl: '',
    testPhoneNumber: '',
    isUploadingFile: false,
    isAudioLoading: false,
    isAudioPlaying: false,
  };

  _audioPlayer = null;
  _fileInput = null;

  componentWillMount() {
    this.setState({
      recordingType: this.props.recordingType,
      isAudioLoading: true,
    });

    SourcesAPI.getPhoneRecordingTemplates(this.props.propertyId).then(
      ({ phone_recording_templates }) => {
        this.setState({
          originalRecordingUrl:
            this.state.recordingType === 'resident'
              ? phone_recording_templates.greeting_url_resident
              : phone_recording_templates.greeting_url,
          isAudioLoading: false,
        });
      }
    );
  }

  render() {
    const recordingUrl = this.state.uploadedRecordingUrl
      ? this.state.uploadedRecordingUrl
      : this.state.originalRecordingUrl;

    return (
      <Modal className="greeting-recording-editor" onBackdropClicked={this.props.onCancel}>
        <form onSubmit={this.onSubmit.bind(this)} autoComplete="off">
          <ModalHeader>
            <h3>{_.startCase(this.state.recordingType)} Greeting Audio</h3>
          </ModalHeader>

          <ModalContent>
            <div className="hidden-elements">
              <audio
                ref={player => {
                  this._audioPlayer = player;
                }}
                onEnded={this.toggleAudioPlayingState.bind(this, false)}
                controls
              >
                <source src={recordingUrl} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
              <input
                ref={input => {
                  this._fileInput = input;
                }}
                className="recording-file-input"
                type="file"
                accept="audio/wav,audio/mpeg"
                onChange={this.handleFileFromInputChange.bind(this)}
              />
            </div>

            <div className="knock-react-flex">
              <div className="title-column">
                <strong>Recording:</strong>
              </div>
              <div>
                <div>
                  <em>{this.extractNameFromRecordingUrl(recordingUrl)}</em>
                </div>
                <div>
                  {this.renderPlayerActions()}
                  {this.renderRecordingActions()}
                </div>
              </div>
            </div>

            <hr />

            <div className="knock-react-flex">
              <div className="title-column">
                <strong>Test via:</strong>
              </div>
              <div className="value-column">
                <div>
                  <input
                    className="knock-react-input input-longer"
                    value={this.state.testPhoneNumber}
                    name="testPhoneNumber"
                    placeholder="Phone number to dial"
                    onChange={this.inputChanged.bind(this, 'testPhoneNumber', 'value')}
                  />
                </div>
                <div>
                  <button
                    className="knock-react-button btn-success mTop5 margin-none"
                    onClick={this.placeRecordingTestCall.bind(this)}
                    disabled={this.state.isAudioLoading}
                  >
                    <i className="fa fa-phone-square" /> Test recording in call
                  </button>
                </div>
              </div>
            </div>
          </ModalContent>

          <ModalFooter>
            <button className="knock-react-button" onClick={this.props.onCancel}>
              Cancel
            </button>
            <button
              className="knock-react-button"
              disabled={!this.state.uploadedRecordingUrl}
              onClick={this.onSave.bind(this)}
            >
              Save
            </button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }

  renderPlayerActions() {
    if (this.state.isAudioLoading) {
      return (
        <button className="knock-react-button corner-right btn-sm margin-none" disabled={true}>
          <i className="fa fa-spin fa-spinner" /> Loading
        </button>
      );
    } else {
      if (this.state.isAudioPlaying) {
        return (
          <button
            className="knock-react-button corner-right btn-sm margin-none"
            onClick={this.stopAudio.bind(this)}
          >
            <i className="fa fa-stop-circle" /> Stop
          </button>
        );
      } else {
        return (
          <button
            className="knock-react-button corner-right btn-sm margin-none"
            onClick={this.playAudio.bind(this)}
          >
            <i className="fa fa-play-circle" /> Listen
          </button>
        );
      }
    }
  }

  renderRecordingActions() {
    if (this.state.uploadedRecordingUrl) {
      return (
        <button
          className="knock-react-button btn-warning btn-sm corner-left margin-none"
          onClick={this.clearUploadedRecordingUrl.bind(this)}
        >
          <i className="fa fa-undo" /> Revert to original
        </button>
      );
    } else {
      if (this.state.isUploadingFile) {
        return (
          <span className="knock-react-button btn-success mLeft10 text-small corner-left margin-none">
            <i className="fa fa-spinner fa-spin" /> Uploading Audio
          </span>
        );
      } else {
        return (
          <button
            className="knock-react-button btn-success btn-sm corner-left margin-none"
            onClick={this.openFileInputDialog.bind(this)}
          >
            <i className="fa fa-upload" /> Upload New Recording
          </button>
        );
      }
    }
  }

  extractNameFromRecordingUrl(recordingUrl) {
    return recordingUrl.split('/').pop();
  }

  openFileInputDialog() {
    if (this._fileInput) {
      this.stopAudio();
      this._fileInput.click();
    }
  }

  playAudio() {
    this._audioPlayer.load();
    this._audioPlayer.play();
    this.toggleAudioPlayingState(true);
  }

  stopAudio() {
    this._audioPlayer.pause();
    this.toggleAudioPlayingState(false);
  }

  toggleAudioPlayingState(isAudioPlaying) {
    this.setState({ isAudioPlaying });
  }

  clearUploadedRecordingUrl() {
    this.stopAudio();
    this.setState({ uploadedRecordingUrl: '' });
  }

  handleFileFromInputChange(event) {
    const file = event.target.files[0];

    this.setState({
      isAudioLoading: true,
      isUploadingFile: true,
    });

    SourcesAPI.uploadPhoneRecordingFile(file).then(({ recording_url }) => {
      this.setState({
        isAudioLoading: false,
        isUploadingFile: false,
        uploadedRecordingUrl: recording_url,
      });
    });
  }

  placeRecordingTestCall() {
    const phoneNumber = this.state.testPhoneNumber;

    if (!phoneNumber) {
      Toaster.showToast(`Test phone number is invalid`, 2000, Toaster.ToastClasses.error);
      return;
    }

    const recordingUrl = this.state.uploadedRecordingUrl
      ? this.state.uploadedRecordingUrl
      : this.state.originalRecordingUrl;

    SourcesAPI.placeTestCallForPhoneRecordingUrl(phoneNumber, recordingUrl)
      .then(() => {
        Toaster.showToast(`Test call made to ${phoneNumber}`, 2000, Toaster.ToastClasses.success);
      })
      .catch(() => {
        console.log(`Failed to place call for ${recordingUrl} to ${phoneNumber}`);
      });
  }

  inputChanged(key, target, event) {
    this.setState({
      [key]: event.target[target],
    });
  }

  onSave() {
    if (this.state.uploadedRecordingUrl) {
      if (this.state.recordingType === 'prospect') {
        SourcesAPI.putPhoneRecordingTemplates(
          this.props.propertyId,
          this.state.uploadedRecordingUrl
        ).then(() => {
          this.props.onCancel();
        });
      } else {
        SourcesAPI.putPhoneResidentRecordingTemplates(
          this.props.propertyId,
          this.state.uploadedRecordingUrl
        ).then(() => {
          this.props.onCancel();
        });
      }
    }
  }

  onSubmit(event) {
    event.preventDefault();
  }
}

export default GreetingRecordingEditor;
