import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AuthenticationService, Toaster } from '@knockrentals/knock-react';
import Collapse from '../../Collapse/Collapse';
import SourcesAPI from './SourcesAPI';
class SourceListTable extends Component {
  state = {
    editingSourceId: null,
    userRole: AuthenticationService.getRole(),
    isLoading: false,
  };

  async getSources() {
    const {
      account: { id },
      updateAccountSources,
    } = this.props;

    try {
      this.setState({ isLoading: true });

      const {
        account_source_data: { sources },
      } = await SourcesAPI.getSourceById(id);
      const sortedSourcesData = sources.sort((groupA, groupB) => {
        return groupA.source_name > groupB.source_name ? 1 : -1;
      });

      updateAccountSources(id, sortedSourcesData);
    } catch (error) {
      Toaster.showToast(
        'Error getting sources in source-list',
        2000,
        Toaster.ToastClasses.error
      );
    }

    this.setState({ isLoading: false });
  }

  async onToggled() {
    !this.props.sources.length && this.getSources();
  }

  render() {
    const {
      account: { name },
    } = this.props;
    const { isLoading, userRole } = this.state;

    return (
      <Collapse
        onToggled={this.onToggled.bind(this)}
        title={
          <span>
            {name} {isLoading && <i className="fa fa-spin fa-spinner" />}
          </span>
        }
      >
        {userRole === 'master' ? (
          <button
            className="knock-react-button"
            onClick={this.onStartApplyToAllTeams.bind(this)}
          >
            Apply to all teams
          </button>
        ) : null}

        <table className="wire-frame-table">
          <thead>
            <tr>
              <th />
              <th>Source</th>
              <th>Group</th>
            </tr>
          </thead>
          <tbody>{this.renderAccountSources()}</tbody>
        </table>
      </Collapse>
    );
  }

  renderAccountSources() {
    const { sources } = this.props;

    if (!sources.length) {
      return null;
    }

    const getSourceEnabledChangeHandler = (sourceIndex) => {
      return (event) => {
        const {
          onSourceChanged,
          account: { id: accountId },
          sources,
        } = this.props;
        const { source_id, parent_source_id } = {
          ...sources[sourceIndex],
        };
        const isEnabled = event.target.checked;

        onSourceChanged(accountId, source_id, isEnabled, parent_source_id);
      };
    };

    return sources.map((source, index) => {
        const { is_enabled, source_name } = source;

        return (
          <tr key={source.source_id}>
            <td>
              <input
                type="checkbox"
                checked={is_enabled}
                onChange={getSourceEnabledChangeHandler(index)}
              />
            </td>
            <td>{source_name}</td>
            <td>{this.renderGroupingSelect(source)}</td>
          </tr>
        );
      });
  }

  renderGroupingSelect(source) {
    const { source_id } = source;
    const { editingSourceId } = this.state;
    const { sources } = this.props;

    if (editingSourceId !== source_id) {
      const group = sources.find(
        (s) => s.source_id === parseInt(source.parent_source_id, 10)
      );

      if (!group) {
        return (
          <a href="#" onClick={this.startEditingGroup.bind(this, source_id)}>
            None
          </a>
        );
      }

      return (
        <a href="#" onClick={this.startEditingGroup.bind(this, source_id)}>
          {group.source_name}
        </a>
      );
    }

    const options = sources.map((accountSource) => {
      return (
        <option key={accountSource.source_id} value={accountSource.source_id}>
          {accountSource.source_name}
        </option>
      );
    });

    const groupChanged = (event) => {
      const {
        onSourceChanged,
        account: { id: accountId },
      } = this.props;
      const { source_id, is_enabled } = source;
      const parentSourceId = event.target.value;

      onSourceChanged(accountId, source_id, is_enabled, parentSourceId);
    };

    return (
      <select value={source.parent_source_id || ''} onChange={groupChanged}>
        <option value={null}>None</option>
        {options}
      </select>
    );
  }

  startEditingGroup(sourceId, event) {
    event.preventDefault();
    this.setState({ editingSourceId: sourceId });
  }

  onStartApplyToAllTeams() {
    this.props.onStartApplyToAllTeams(this.props.account.id);
  }
}

SourceListTable.propTypes = {
  account: PropTypes.object,
  sources: PropTypes.array,
  onStartApplyToAllTeams: PropTypes.func,
  onSourceChanged: PropTypes.func,
  updateAccountSources: PropTypes.func,
};

export default SourceListTable;
