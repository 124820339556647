import React, { Component } from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter } from '@knockrentals/knock-react';

class PropertyImport extends Component {
  state = {
    importInProgress: false,
  };

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.importFileAdded = this.importFileAdded.bind(this);
  }

  importFileAdded(fileInput) {
    this.fileInput = fileInput;
  }

  submit(event) {
    event.preventDefault();

    this.setState({ importInProgress: true });

    this.props.onImportProperties(this.fileInput.files[0]).then(() => {
      this.setState({ importInProgress: false });
    });
  }

  cancel(event) {
    event.preventDefault();
    this.props.onCancel();
  }

  renderImportErrors() {
    if (!this.props.importErrors) {
      return null;
    }

    const errorMessages = this.props.importErrors.errorMessages.map(errorMessage => {
      return <div>{errorMessage}</div>;
    });

    const duplicatePropertyMailerUsernames = this.props.importErrors.duplicatePropertyMailerUsernames.map(
      duplicatePropertyMailerUsername => {
        return <div>{duplicatePropertyMailerUsername}</div>;
      }
    );

    const invalidOwnersUsernames = this.props.importErrors.invalidOwnersUsernames.map(username => {
      return <div>{username}</div>;
    });

    const existingPropertyMailerUsernames = this.props.importErrors.existingPropertyMailerUsernames.map(
      username => {
        return <div>{username}</div>;
      }
    );

    return (
      <div>
        <div>
          <strong>Formatting Errors</strong>
          {errorMessages}
        </div>
        <div>
          <strong>Duplicate mailer usernames</strong>
          {duplicatePropertyMailerUsernames}
        </div>
        <div>
          <strong>Invalid owner usernames</strong>
          {invalidOwnersUsernames}
        </div>
        <div>
          <strong>Existing mailer usernames</strong>
          {existingPropertyMailerUsernames}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal className="user-editor-container">
        <form onSubmit={this.submit}>
          <ModalHeader>
            <h2>Import properties</h2>
          </ModalHeader>
          <ModalContent>
            <div className="knock-react-flex">
              <div>
                <strong>Property import CSV: </strong>
              </div>
              <div>
                <input type="file" className="knock-react-input" ref={this.importFileAdded} />
              </div>
            </div>
            {this.renderImportErrors()}
          </ModalContent>
          {this.state.importInProgress ? (
            <ModalFooter>
              <button className="knock-react-button" disabled>
                Uploading...
              </button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <button className="knock-react-button" onClick={this.cancel.bind(this)}>
                Cancel
              </button>
              <button className="knock-react-button">Upload</button>
            </ModalFooter>
          )}
        </form>
      </Modal>
    );
  }
}

export default PropertyImport;
