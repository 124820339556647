import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
} from '@knockrentals/knock-shared-web';

import { IndeterminateSwitch } from '../../../../common/IndeterminateSwitch';
import { BrandContentTooltip } from '../BrandContentTooltip';
import { BrandHeaderDescription } from './BrandHeaderDescription';
import { BrandHeaderPreview } from './BrandHeaderPreview';

import { getTooltipMessage } from '../utils';
import { ALIGNMENT } from '../../../constants';

export const BRAND_HEADER_TITLE = 'Header';
export const RADIO_GROUP_ARIA_LABEL = 'header alignment radio group';
export const IS_HEADER_ENABLED_LABEL = 'Display Header';

export const ALIGNMENT_LABELS = {
  [ALIGNMENT.CENTER]: 'Logo Center',
  [ALIGNMENT.LEFT]: 'Logo Left',
  [ALIGNMENT.RIGHT]: 'Logo Right',
};

const radioOptions = [
  { label: ALIGNMENT_LABELS[ALIGNMENT.LEFT], value: ALIGNMENT.LEFT },
  { label: ALIGNMENT_LABELS[ALIGNMENT.CENTER], value: ALIGNMENT.CENTER },
  { label: ALIGNMENT_LABELS[ALIGNMENT.RIGHT], value: ALIGNMENT.RIGHT },
];

const useStyles = makeStyles({
  labelControl: {
    margin: '16px',
  },
});

const BrandHeader = ({
  alignment,
  brandColor,
  contrastingTextColor,
  hasMixedHeaderSettings,
  isBatchUpdate,
  isBrandColorMixed,
  isHeaderEnabled,
  isHeaderEnabledIndeterminate,
  logoUrl,
  propertyCount,
  propertyName,
  updateBrandContent,
}) => {
  const classes = useStyles();

  const getTooltipTitle = () => {
    if (hasMixedHeaderSettings || isHeaderEnabledIndeterminate) {
      return getTooltipMessage(propertyCount);
    }
  };

  const isRadioDisabled = !isHeaderEnabled;

  const handleAlignmentOnChange = (e) => {
    const headerAlignment = e.target.value;
    updateBrandContent({ headerAlignment });
  };

  const handleIsHeaderEnabledOnChange = (updateMap) => {
    if (isHeaderEnabledIndeterminate) {
      updateMap.isHeaderEnabledIndeterminate = false;
    }
    updateBrandContent(updateMap);
  };

  return (
    <Card variant="outlined">
      <CardHeader title={BRAND_HEADER_TITLE} />

      <CardContent>
        <FormControlLabel
          control={
            <BrandContentTooltip title={getTooltipTitle()}>
              <IndeterminateSwitch
                ariaLabel={IS_HEADER_ENABLED_LABEL}
                isChecked={isHeaderEnabled || false}
                name="isHeaderEnabled"
                isIndeterminate={isHeaderEnabledIndeterminate}
                onChangeHandler={handleIsHeaderEnabledOnChange}
              />
            </BrandContentTooltip>
          }
          label={IS_HEADER_ENABLED_LABEL}
        />

        <BrandHeaderDescription
          hasBrandColor={Boolean(brandColor)}
          hasLogo={Boolean(logoUrl)}
          isBatchUpdate={isBatchUpdate}
        />

        <BrandHeaderPreview
          alignment={alignment}
          brandColor={brandColor}
          contrastingTextColor={contrastingTextColor}
          isBatchUpdate={isBatchUpdate}
          isBrandColorMixed={isBrandColorMixed}
          logoUrl={logoUrl}
          propertyName={propertyName}
        />

        <RadioGroup
          aria-label={RADIO_GROUP_ARIA_LABEL}
          onChange={handleAlignmentOnChange}
          row
          value={alignment || ''}
        >
          {radioOptions.map((option) => (
            <FormControlLabel
              className={classes.labelControl}
              control={<Radio color="primary" disabled={isRadioDisabled} />}
              key={option.value}
              label={option.label}
              labelPlacement="end"
              value={option.value}
            />
          ))}
        </RadioGroup>
      </CardContent>
    </Card>
  );
};

BrandHeader.propTypes = {
  alignment: PropTypes.string,
  contrastingTextColor: PropTypes.string,
  brandColor: PropTypes.string,
  hasMixedHeaderSettings: PropTypes.bool,
  isBrandColorMixed: PropTypes.bool,
  isBatchUpdate: PropTypes.bool,
  isHeaderEnabled: PropTypes.bool,
  isHeaderEnabledIndeterminate: PropTypes.bool,
  logoUrl: PropTypes.string,
  propertyCount: PropTypes.number,
  propertyName: PropTypes.string,
  updateBrandContent: PropTypes.func,
};

export default BrandHeader;
