import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import queryString from 'query-string';
import { AuthenticationService } from '@knockrentals/knock-react';
import LoginAPI from './LoginAPI';
import {
  deleteAccessTokenCookie,
  getCookie,
  getRoleFromToken,
  handleError,
  isUserRoleValid,
} from './utils';

const useLogin = ({ history }) => {
  const unifiedLoginAuth = useAuth();
  const [loading, setLoading] = useState(false);
  const [knockAccessToken, setKnockAccessToken] = useState('');

  const unifiedLoginAuthUser = unifiedLoginAuth.user || {};

  useEffect(() => {
    const exchangeUnifiedToken = async (unifiedLoginAccessToken) => {
      try {
        const accessToken = await LoginAPI.exchangeUnifiedToken(
          unifiedLoginAccessToken
        );

        setKnockAccessToken(accessToken);
      } catch (_e) {
        handleError('token-exchange');
      }
    };

    if (unifiedLoginAuth.isAuthenticated && unifiedLoginAuthUser.access_token) {
      exchangeUnifiedToken(unifiedLoginAuthUser.access_token);
    }
  }, [unifiedLoginAuth.isAuthenticated, unifiedLoginAuthUser.access_token]);

  const locationSearchString = () => history.location.search;

  const handleKnockLogin = async ({ username, password }) => {
    setLoading(true);

    try {
      const { accessToken, refreshToken } = await LoginAPI.login(
        username,
        password
      );

      const role = getRoleFromToken(accessToken);
      isUserRoleValid(role);

      AuthenticationService.setToken(accessToken);
      AuthenticationService.setRefreshToken(refreshToken);
      window.location.reload();
    } catch (error) {
      const { message } = error || {};
      handleError(message, username);
    }

    setLoading(false);
  };

  const handleKnockAccessTokenLogin = async () => {
    setLoading(true);
    AuthenticationService.clearRefreshToken();

    try {
      const role = getRoleFromToken(knockAccessToken);
      isUserRoleValid(role);

      const { accessToken, refreshToken } = await LoginAPI.getUserRefreshToken(
        knockAccessToken
      );

      AuthenticationService.setToken(accessToken);
      AuthenticationService.setRefreshToken(refreshToken);
      window.location.reload();
    } catch (error) {
      const { message } = error || {};
      setKnockAccessToken('');
      handleError(message);
      setLoading(false);
    }
  };

  const removeQueryString = () => {
    history.replace({ search: '' });
  };

  const handleParsedKnockToken = (parsedKnockToken) => {
    removeQueryString();
    AuthenticationService.clearToken();
    setKnockAccessToken(parsedKnockToken);
  };

  const handleCookieAccess = () => {
    const knockCookieAccessToken = getCookie('accessToken');
    if (knockCookieAccessToken) {
      deleteAccessTokenCookie();
      handleParsedKnockToken(knockCookieAccessToken);
    }
  };

  const handleQueryStringAccess = () => {
    if (!locationSearchString()) {
      return;
    }
    const queryParams = queryString.parse(locationSearchString());
    const knockQueryAccessToken = queryParams.accessToken;

    if (knockQueryAccessToken) {
      handleParsedKnockToken(knockQueryAccessToken);
    }
  };

  return {
    handleCookieAccess,
    handleKnockAccessTokenLogin,
    handleKnockLogin,
    handleQueryStringAccess,
    knockAccessToken,
    loading,
    unifiedLoginAuth,
  };
};

export default useLogin;
