import React from 'react';
import {
  PHONE_REGISTRATION_COLUMN_NAMES,
  PHONE_REGISTRATION_COLUMNS,
} from '../constants';

const PhoneRegistrationColumnHeaders = () =>
  PHONE_REGISTRATION_COLUMNS.map((fieldName) => {
    const columnName = PHONE_REGISTRATION_COLUMN_NAMES[fieldName];
    return <th key={fieldName}>{columnName}</th>;
  });

export default PhoneRegistrationColumnHeaders;
