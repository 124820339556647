import React from 'react';
import PropTypes from 'prop-types';
import PropertyInfoEditBasics from './PropertyInfoEditBasics';
import PropertyInfoEditAmenities from './PropertyInfoEditAmenities';
import PropertyInfoEditLeasing from './Leasing/PropertyInfoEditLeasing';
import PropertyInfoEditNearbyCommunities from './PropertyInfoEditNearbyCommunities';
import PropertyInfoEditSettings from './PropertyInfoEditSettings';
import { useAppContext } from '../../Context/AppContext';
import { ACCESS_SETTINGS } from '../../constants';
import { useFeatureFlagContext } from '../../Context/FeatureFlagContext';

export const CROSS_SELL_TAB_OPTION = 'Cross Sell';

const TAB_OPTIONS = [
  'Basics',
  'Amenities',
  'Leasing',
  'Settings',
  CROSS_SELL_TAB_OPTION,
];

const PropertyInfo = ({
  selectedTab,
  property,
  settings,
  teams,
  changeSelectedTab,
  onInformationChanged,
}) => {
  const { isAuthorized } = useAppContext();
  const { isEnhancedAccessEnabled } = useFeatureFlagContext();

  const isCrossSellAccessEnabled =
    !isEnhancedAccessEnabled || isAuthorized(ACCESS_SETTINGS.CROSS_SELL_TAB);

  const getActiveStatusSubClassOfTab = (tabOption) => {
    return selectedTab === tabOption ? 'active' : 'inactive';
  };

  return (
    <div>
      <h2>{property.data.location.name}</h2>

      <div className="property-info-nav-tabs-menu" role="tablist">
        {TAB_OPTIONS.map((tabOption) => {
          if (
            tabOption === CROSS_SELL_TAB_OPTION &&
            !isCrossSellAccessEnabled
          ) {
            return null;
          }

          return (
            <div
              key={tabOption}
              className={`property-info-nav-tab ${getActiveStatusSubClassOfTab(
                tabOption
              )}`}
              onClick={() => {
                changeSelectedTab(tabOption);
              }}
              role="tab"
            >
              {tabOption}
            </div>
          );
        })}
      </div>

      {selectedTab === 'Basics' && (
        <PropertyInfoEditBasics
          teams={teams}
          property={property}
          onChange={onInformationChanged}
        />
      )}

      {selectedTab === 'Amenities' && (
        <PropertyInfoEditAmenities
          property={property}
          onChange={onInformationChanged}
        />
      )}

      {selectedTab === 'Leasing' && (
        <PropertyInfoEditLeasing
          property={property}
          onChange={onInformationChanged}
        />
      )}

      {selectedTab === 'Settings' && (
        <PropertyInfoEditSettings
          property={property}
          onChange={onInformationChanged}
        />
      )}

      {isCrossSellAccessEnabled && selectedTab === CROSS_SELL_TAB_OPTION && (
        <PropertyInfoEditNearbyCommunities
          property={property}
          settings={settings}
          onChange={onInformationChanged}
        />
      )}
    </div>
  );
};

PropertyInfo.propTypes = {
  selectedTab: PropTypes.string,
  property: PropTypes.object,
  settings: PropTypes.array,
  teams: PropTypes.array,
  changeSelectedTab: PropTypes.func,
  onInformationChanged: PropTypes.func,
};

export default PropertyInfo;
