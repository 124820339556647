import React from 'react';

import AddAmsiIntegration from './AddAmsiIntegration';
import AddAppfolioIntegration from './AddAppfolioIntegration';
import AddEntrataIntegration from './AddEntrataIntegration';
import AddFacebookIntegration from './AddFacebookIntegration';
import AddMriIntegration from './AddMriIntegration';
import AddOnsiteIntegration from './AddOnsiteIntegration';
import AddRealPageIntegration from './AddRealPageIntegration';
import AddResmanIntegration from './AddResmanIntegration';
import AddYardiIntegration from './AddYardiIntegration';
import { IntegrationVendorNamesLowerCase } from '../constants';

const getIntegration = (vendor) => {
  switch (vendor) {
    case IntegrationVendorNamesLowerCase.AMSI:
      return AddAmsiIntegration;

    case IntegrationVendorNamesLowerCase.APPFOLIO:
      return AddAppfolioIntegration;

    case IntegrationVendorNamesLowerCase.ENTRATA:
      return AddEntrataIntegration;

    case IntegrationVendorNamesLowerCase.FACEBOOK:
      return AddFacebookIntegration;

    case IntegrationVendorNamesLowerCase.MRI:
      return AddMriIntegration;

    case IntegrationVendorNamesLowerCase.ONSITE:
      return AddOnsiteIntegration;

    case IntegrationVendorNamesLowerCase.REALPAGE:
      return AddRealPageIntegration;

    case IntegrationVendorNamesLowerCase.RESMAN:
      return AddResmanIntegration;

    case IntegrationVendorNamesLowerCase.YARDI:
      return AddYardiIntegration;

    default:
      return null;
  }
};

const AddIntegration = ({
  onAddIntegration,
  onCancelAddIntegration,
  vendor,
}) => {
  const Integration = getIntegration(vendor);

  if (!Integration) {
    return null;
  }

  return (
    <Integration
      onAddIntegration={onAddIntegration}
      onCancelAddIntegration={onCancelAddIntegration}
    />
  );
};

export default AddIntegration;
