import React from 'react';
import { Collapse } from '@material-ui/core';
import { Alert } from '@knockrentals/knock-shared-web';

const ErrorAlert = ({
  alertMessage,
  'data-testid': dataTestId,
  isVisible = false,
  severity = 'error'
}) => {
  return (
    <Collapse in={isVisible} mountOnEnter unmountOnExit>
      <Alert data-testid={dataTestId} severity={severity}>
        {alertMessage}
      </Alert>
    </Collapse>
  );
};

export default ErrorAlert;
