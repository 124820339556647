import React, { Component } from 'react';
import { PageContainer } from '@knockrentals/knock-react';
import UsersAPI from './UsersAPI';
import './_addLeasingTeams.scss';
import * as _ from 'lodash';
import classnames from 'classnames';

class LeasingTeamsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      companyLeasingTeams: props.groups,
      searchTerm: '',
      currentLeasingTeams: [],
      fixedTeams: [],
      hasCognitoTeams: false,
    };
  }

  componentDidMount() {
    const userId = this.props.userId;
    if (userId) {
      UsersAPI.getCognitoUserLeasingTeams(userId)
        .then(response => {
          this.setState({
            hasCognitoTeams: !!response.manager_leasing_teams
              && response.manager_leasing_teams.length > 0,
            fixedTeams: !!response.manager_leasing_teams
              && response.manager_leasing_teams.length <= 0
              ? [this.props.group]
              : response.manager_leasing_teams.map(team => {
                  return {
                    leasing_team_name: team.name,
                    leasing_team_id: team.id,
                    leasing_team_public_id: team.public_id,
                  };
                }),
            isLoading: false,
          });
          this.props.updateParentLeasingTeams([...this.state.fixedTeams]);
        });
    } else {
      return this.setState({
        fixedTeams: [this.props.group],
        isLoading: false,
        hasCognitoTeams: false,
      });
    }
  }

  render() {
    return (
      <PageContainer className="leasing-teams-editor" isLoading={this.state.isLoading}>
        <div className="knock-react-flex">
          <div className="modal-field-label">
            <strong>Leasing Teams*: </strong>
          </div>

          <div className={classnames("knock-react-input", this.props.disabled && "read-only-leasing-teams")} id="leasing-teams">
            {this.state.fixedTeams && this.listLeasingTeams(this.state.fixedTeams, 'fixed-')}
            {this.state.currentLeasingTeams &&
              this.listLeasingTeams(this.state.currentLeasingTeams, 'added-')}

            {(this.state.hasCognitoTeams || !this.props.userId) && (
              <input
                className="knock-react-input leasing-team-search"
                name="searchTerm"
                value={this.state.searchTerm}
                type="text"
                onChange={this.inputChanged('searchTerm').bind(this)}
                placeholder="Search Teams..."
              />
            )}

            {(this.state.hasCognitoTeams || !this.props.userId) &&
              this.state.searchTerm &&
              this.leasingTeamSearch()}
          </div>
        </div>
      </PageContainer>
    );
  }

  listLeasingTeams = (leasingTeams, key) => {
    let active = true;
    if (key === 'fixed-') {
      active = false;
    }

    return (
      <ul className="modal-list" id="current-leasing-team-list">
        {leasingTeams.map(leasingTeam => {
          return (
            <li
              className="modal-list-item"
              key={key + leasingTeam.leasing_team_id}
              value={leasingTeam.leasing_team_id}
            >
              <button
                type="button"
                id={key + leasingTeam.leasing_team_id}
                className="inline-list-button"
                onClick={active ? this.updateLeasingTeams.bind(this, leasingTeam) : undefined}
                value={leasingTeam.leasing_team_id}
              >
                {leasingTeam.leasing_team_name}
                {active &&
                  (this.state.currentLeasingTeams.includes(leasingTeam) ? (
                    <i className="fa fa-times-circle fa-lg button-remove"></i>
                  ) : (
                    <i className="fa fa-plus-circle fa-lg button-add"></i>
                  ))}
              </button>
            </li>
          );
        })}
      </ul>
    );
  };

  inputChanged(field) {
    return event => {
      let update = {};
      update[field] = event.target.value;

      this.setState(update);
    };
  }

  updateLeasingTeams(selectedLeasingTeam) {
    const currentLeasingTeams = this.state.currentLeasingTeams;
    const noCurrentLeasingTeams = typeof currentLeasingTeams === undefined || !currentLeasingTeams;
    const addLeasingTeam = !currentLeasingTeams.includes(selectedLeasingTeam);
    const removeLeasingTeam = currentLeasingTeams.includes(selectedLeasingTeam);

    if (noCurrentLeasingTeams) {
      this.setState({
        currentLeasingTeams: [selectedLeasingTeam],
      });
    }

    if (addLeasingTeam) {
      this.setState(
        prevState => ({
          currentLeasingTeams: [...prevState.currentLeasingTeams, selectedLeasingTeam],
        }),
        this.props.updateParentLeasingTeams(
          [
            ...this.state.fixedTeams,
            ...this.state.currentLeasingTeams,
            selectedLeasingTeam,
          ],
          selectedLeasingTeam
        )
      );
    }

    if (removeLeasingTeam) {
      const index = currentLeasingTeams.findIndex(
        team => team.leasing_team_id === selectedLeasingTeam.leasing_team_id
      );

      this.setState(
        prevState => ({
          currentLeasingTeams: [
            ...prevState.currentLeasingTeams.slice(0, index),
            ...prevState.currentLeasingTeams.slice(index + 1),
          ],
        }),
        this.props.updateParentLeasingTeams(
          [
            ...this.state.fixedTeams,
            ...this.state.currentLeasingTeams.slice(0, index),
            ...this.state.currentLeasingTeams.slice(index + 1),
          ],
          selectedLeasingTeam,
          false
        )
      );
    }

    this.setState({ searchTerm: '' });
  }

  leasingTeamSearch() {
    const {
      currentLeasingTeams,
      fixedTeams,
      companyLeasingTeams,
    } = this.state;

    const mappedSelectedTeams = currentLeasingTeams.reduce((result, current) => {
      result[current.leasing_team_name] = true;
      return result;
    }, {});

    const filteredTeams = companyLeasingTeams.filter(team => {
      const nameDoesMatch =
        team.leasing_team_name
          .trim()
          .toLowerCase()
          .indexOf(this.state.searchTerm.trim().toLowerCase()) !== -1;
      const teamIsSelected = mappedSelectedTeams.hasOwnProperty(team.leasing_team_name);
      const teamIsFixed = !!_.find(fixedTeams, { leasing_team_name: team.leasing_team_name });

      return nameDoesMatch && !teamIsSelected && !teamIsFixed;
    });

    if (filteredTeams.length === 0) {
      return <div>No teams found...</div>;
    }

    return this.listLeasingTeams(filteredTeams, 'results-');
  }
}

export default LeasingTeamsModal;
