import React from 'react';
import './DraggableList.scss';

/**
 * The presentational element of the `DraggableList` component. Renders as a `<table>` element. Expects React component children to be `<tr>` elements that have been configured as `Draggables`.
 *
 * @param {string} props.title The title to be displayed
 * @param {Array<string>} props.headers The headers to be displayed
 * @param {Array<React.Component>} props.children An array of React components as `<tr>` elements that have been configured as `Draggables`
 * @param {string} props.errorMessage The error message to be displayed
 * @param {boolean} props.isAddButtonDisabled Whether the user is currently allowed to add new items
 * @param {function} props.handleAdd A handler to be called whenever a new item is being added
 * @param {object} props.provided An object required by `react-beautiful-dnd` to be unpacked to make a given element a `Droppabale`
 */
export const DraggableList = ({
  title,
  headers,
  children,
  errorMessage,
  isAddButtonDisabled,
  handleAdd,
  provided,
}) => (
  <React.Fragment>
    <label>{title}</label>
    <table
      className="draggable-list-table wire-frame-table"
      ref={provided.innerRef}
      {...provided.droppableProps}
    >
      <thead>
        <tr>
          <td
            className="draggable-list-header-cell-lead"
            key="draggable-list-header-cell-lead"
            colSpan="2"
          >
            {headers[0]}
          </td>
          {headers.slice(1).map((header, index) => (
            <td className="draggable-list-header-cell" key={index.toString()}>
              {header}
            </td>
          ))}
          <td
            className="draggable-list-header-cell-tail"
            key="draggable-list-header-cell-tail"
          ></td>
        </tr>
      </thead>
      <tbody>
        {children}
        {provided.placeholder}
      </tbody>
    </table>
    <div className="draggable-list-table-footer">
      <button
        className="knock-react-button"
        disabled={isAddButtonDisabled}
        onClick={handleAdd}
      >
        Add
      </button>
      {errorMessage ? (
        <div className="draggable-list-table-error">{errorMessage}</div>
      ) : null}
    </div>
  </React.Fragment>
);
