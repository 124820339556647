import { useCallback, useRef, useState } from 'react';

export const DEFAULT_ROWS_PER_PAGE = 50;

export const useTablePagination = () => {
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const tableRef = useRef(null);
  const reloadTableData = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const tableDetailsRef = useRef({
    pageIndex: 0,
    shouldLoadPageIndex: false,
    sortDirection: 'asc',
    totalCount: 0,
  });

  const getPageIndex = () => tableDetailsRef.current.pageIndex;
  const setPageIndex = (index) => {
    tableDetailsRef.current.pageIndex = index;
  };

  const getSortColumn = () => tableDetailsRef.current.sortColumn;
  const setSortColumn = (sortColumn) =>
    (tableDetailsRef.current.sortColumn = sortColumn);

  const getSortDirection = () => tableDetailsRef.current.sortDirection;
  const setSortDirection = (sortDirection) =>
    (tableDetailsRef.current.sortDirection = sortDirection);

  const getShouldLoadPageIndex = () =>
    tableDetailsRef.current.shouldLoadPageIndex;

  const setShouldLoadPageIndex = useCallback((shouldLoad) => {
    tableDetailsRef.current.shouldLoadPageIndex = shouldLoad;
  }, []);

  const getTotalCount = () => tableDetailsRef.current.totalCount;
  const setTotalCount = (count) => {
    tableDetailsRef.current.totalCount = count;
  };

  const getPage = (query) => {
    if (getShouldLoadPageIndex()) {
      setShouldLoadPageIndex(false);
      return getPageIndex();
    }
    return query.page;
  };

  const parseQuery = (query, defaultSortColumn) => {
    const { page, pageSize, orderBy, orderDirection } = query;

    const hasRowsPerPageChanged = pageSize !== rowsPerPage;
    const previousSortColumn = getSortColumn();
    const previousSortDirection = getSortDirection();

    const field = orderBy && orderBy.field;

    setSortColumn(field);
    setSortDirection(orderDirection);

    const hasSortColumnChanged = previousSortColumn !== field;
    const hasSortDirectionChanged = previousSortDirection !== orderDirection;

    const isSorting = hasSortColumnChanged || hasSortDirectionChanged;

    const shouldResetTableData = hasRowsPerPageChanged || isSorting;

    if (shouldResetTableData) {
      setRowsPerPage(pageSize);
    }

    const paginationParams = {
      page,
      pageSize,
      sortColumn: field || defaultSortColumn,
      sortDirection: orderDirection || 'asc',
    };

    return {
      paginationParams,
      shouldResetTableData,
    };
  };

  return {
    getPage,
    getPageIndex,
    parseQuery,
    getTotalCount,
    reloadTableData,
    rowsPerPage,
    setPageIndex,
    setRowsPerPage,
    setShouldLoadPageIndex,
    setTotalCount,
    tableRef,
  };
};
