import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Switch } from '@knockrentals/knock-shared-web';
import { IndeterminateSwitchIcon } from '../../../../../../Components/Icons';

const PreferencesSwitch = ({
  ariaLabel,
  isChecked,
  isIndeterminate,
  name,
  updatePreference,
}) => {
  const handleUpdatePreference = (e) => {
    updatePreference({ [e.target.name]: e.target.checked });
  };

  const setDefaultPreference = (e) => {
    e.preventDefault();
    updatePreference({ [name]: true });
  };

  if (isIndeterminate) {
    return (
      <IconButton aria-label={ariaLabel} onClick={setDefaultPreference}>
        <IndeterminateSwitchIcon />
      </IconButton>
    );
  }

  return (
    <Switch
      checked={isChecked || false}
      color="primary"
      disableRipple
      inputProps={{ 'aria-label': ariaLabel }}
      name={name}
      onChange={handleUpdatePreference}
    />
  );
};

PreferencesSwitch.propTypes = {
  ariaLabel: PropTypes.string,
  isChecked: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  name: PropTypes.string,
  updatePreference: PropTypes.func,
};

export default PreferencesSwitch;
