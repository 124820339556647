import React from 'react';
import PropTypes from 'prop-types';
import IncludeInQuickLaunchItem from './IncludeInQuickLaunchItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Tooltip } from '@knockrentals/knock-shared-web';

export const mapItemToLabel = {
  availability: 'Prices/Availability',
  gallery: 'View Photos',
  schedule: 'Schedule a Tour',
  virtualTour: 'Virtual Tour',
  selfGuidedTour: 'Self-Guided Tour',
  applyNow: 'Apply Now',
};

const reorderItemInList = (list, fromIndex, toIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, removed);
  return result;
};

const filteredItems = (
  items,
  availabilityIsActive,
  galleryIsActive,
  applyNowIsActive
) => {
  const shouldInclude = (item) => {
    if (availabilityIsActive === false && item.name === 'availability') {
      return false;
    }
    if (galleryIsActive === false && item.name === 'gallery') {
      return false;
    }
    if (applyNowIsActive === false && item.name === 'applyNow') {
      return false;
    }
    return true;
  };

  return items.filter(shouldInclude);
};

const IncludeInQuickLaunchMenuSection = ({
  includeInQuickLaunchMenuList: items,
  availabilityIsActive,
  galleryIsActive,
  applyNowIsActive,
  handleIncludeInQuickLaunchChange,
}) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorderItemInList(
      items,
      result.source.index,
      result.destination.index
    );

    handleIncludeInQuickLaunchChange(newItems);
  };

  const onEnabledToggle = (event) => {
    if (event.target.validity.valid) {
      const isTargetChecked = event.target.checked;
      const targetName = event.target.name;

      const newItems = items.slice();

      const element = newItems.find((item) => {
        return item.name === targetName;
      });
      element.enabled = isTargetChecked;

      handleIncludeInQuickLaunchChange(newItems);
    }
  };

  const tooltipText =
    'The buttons enabled here will appear as "Quick Launch" items above the Knockbot, and will be displayed for first-time users. The order of the buttons can be configured by dragging and dropping them.';

  return (
    <div className="property-info-edit-custom-item">
      <label>Include in Quick Launch Menu</label>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className={
                snapshot.isDraggingOver ? 'dragging-over-container' : ''
              }
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <div className="property-info-include-in-quick-launch-section-container">
                {filteredItems(
                  items,
                  availabilityIsActive,
                  galleryIsActive,
                  applyNowIsActive
                ).map((item, idx) => {
                  return (
                    <IncludeInQuickLaunchItem
                      label={mapItemToLabel[item.name]}
                      checked={item.enabled}
                      onChange={onEnabledToggle}
                      name={item.name}
                      id={item.name}
                      idx={idx}
                      key={item.name}
                      fooEnabled={item.enabled}
                    />
                  );
                })}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Tooltip title={tooltipText}>
        <i className="fa-regular fa-question-circle tooltip"></i>
      </Tooltip>
    </div>
  );
};

IncludeInQuickLaunchMenuSection.propTypes = {
  includeInQuickLaunchMenuList: PropTypes.array,
  availabilityIsActive: PropTypes.bool,
  galleryIsActive: PropTypes.bool,
  applyNowIsActive: PropTypes.bool,
  handleIncludeInQuickLaunchChange: PropTypes.func,
};

export default IncludeInQuickLaunchMenuSection;
