import React from 'react';
import PropTypes from 'prop-types';

import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import { PreferencesSwitch } from '../PreferencesSwitch';
import { PreferencesSelect } from '../PreferencesSelect';
import { CustomRequirements } from './CustomRequirements';
import { IsModifiedCheckbox } from '../IsModifiedCheckbox';
import { getOptions } from '../utils';

import {
  INCOME_MULTIPLIER,
  IS_BUDGET_REQUIRED,
  IS_EVICTION_HISTORY_REQUIRED,
  IS_MOVE_DATE_REQUIRED,
  IS_PET_POLICY_REQUIRED,
  PREFERENCES_TITLES,
} from '../constants';

const useStyles = makeStyles({
  prescreeningRequirements: {
    '& .MuiInputBase-root': {
      minWidth: '140px',
    },
  },
});

const Prescreening = ({
  isBatchEdit,
  modifiedStatusMap = {},
  requirements,
  setRequirements,
  updateModifiedStatus,
}) => {
  const classes = useStyles();

  const getIsSwitchIndeterminate = (value) =>
    isBatchEdit && typeof value !== 'boolean';

  const getIsSelectIndeterminate = (value) =>
    isBatchEdit && typeof value !== 'number';

  const updatePreference = (preference) => {
    setRequirements((prevState) => ({
      ...prevState,
      ...preference,
    }));
  };

  const renderSwitch = (name) => (
    <PreferencesSwitch
      ariaLabel={PREFERENCES_TITLES[name]}
      isChecked={requirements[name]}
      isIndeterminate={getIsSwitchIndeterminate(requirements[name])}
      name={name}
      updatePreference={updatePreference}
    />
  );

  const renderSelection = (name) => (
    <PreferencesSelect
      ariaLabel={PREFERENCES_TITLES[name]}
      isIndeterminate={getIsSelectIndeterminate(requirements[name])}
      name={name}
      options={getOptions(name)}
      value={requirements[name]}
      updatePreference={updatePreference}
    />
  );

  const prescreeningRequirements = [
    [IS_MOVE_DATE_REQUIRED, renderSwitch],
    [INCOME_MULTIPLIER, renderSelection],
    [IS_BUDGET_REQUIRED, renderSwitch],
    [IS_PET_POLICY_REQUIRED, renderSwitch],
    [IS_EVICTION_HISTORY_REQUIRED, renderSwitch],
  ];

  const renderRequirements = ([name, renderItem]) => (
    <ListItem key={name}>
      <ListItemText>
        <IsModifiedCheckbox
          isModified={modifiedStatusMap[name]}
          updateModifiedStatus={updateModifiedStatus}
          name={name}
          value={requirements[name]}
          resetPreference={updatePreference}
        />
        {PREFERENCES_TITLES[name]}
      </ListItemText>
      {renderItem(name)}
    </ListItem>
  );

  return (
    <List>
      <List className={classes.prescreeningRequirements}>
        {prescreeningRequirements.map(renderRequirements)}

        <CustomRequirements
          customRequirements={requirements.customRequirements}
          isDisabled={isBatchEdit}
          modifiedStatusMap={modifiedStatusMap}
          setRequirements={setRequirements}
          updateModifiedStatus={updateModifiedStatus}
        />
      </List>
    </List>
  );
};

Prescreening.propTypes = {
  isBatchEdit: PropTypes.bool,
  modifiedStatusMap: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.bool)])
  ),
  requirements: PropTypes.shape({}),
  setRequirements: PropTypes.func,
  updateModifiedStatus: PropTypes.func,
};

export default Prescreening;
