import React, { useEffect, useState } from 'react';
import { Slide } from '@material-ui/core';

import { Snackbar } from '@knockrentals/knock-shared-web';
import NotificationService from './NotificationService';

const LEFT = 'left';
const RIGHT = 'right';
const TOP = 'top';

const SLIDE_DIRECTION = {
  [LEFT]: RIGHT,
  [RIGHT]: LEFT,
};

const Notifications = () => {
  const [notificationConfig, setNotificationConfig] = useState(null);

  useEffect(() => {
    NotificationService.setNotificationHandler(setNotificationConfig);
  }, []);

  const {
    duration = 2000,
    message,
    placement = [TOP, RIGHT],
  } = notificationConfig || {};

  const notificationKey = Math.random();

  const [vertical, horizontal] = placement;

  return (
    <Snackbar
      anchorOrigin={{ horizontal, vertical }}
      autoHideDuration={duration}
      key={notificationKey}
      message={message}
      open={Boolean(message)}
      onClose={() => {
        setNotificationConfig(null);
      }}
      TransitionComponent={(props) => (
        <Slide {...props} direction={SLIDE_DIRECTION[horizontal]} />
      )}
    />
  );
};

export default Notifications;
