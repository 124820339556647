import { useState, useEffect } from 'react';
import { companyTemplatesApi } from '../../CompanyTemplatesApi';
import { getSelectedProperties, getTablePropertiesMap } from '../utils';

const useAddProperty = ({
  templatePropertiesMap,
  templateType,
  isDefaultTemplate,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [tablePropertiesMap, setTablePropertiesMap] = useState({});

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const properties =
          await companyTemplatesApi.getPropertiesForTemplateType(
            templateType,
            isDefaultTemplate
          );

        const filteredProperties = properties.filter(
          (property) => !templatePropertiesMap[property.propertyId]
        );

        setProperties(filteredProperties);
        setTablePropertiesMap(getTablePropertiesMap(filteredProperties));
      } catch (error) {
        setErrorMessage(error.message);
      }
      setIsLoading(false);
    };

    fetchProperties();
  }, []);

  const hasAnyPropertySelected = () =>
    Object.values(tablePropertiesMap).some((property) => property.isSelected);

  return {
    errorMessage,
    getSelectedProperties: () => getSelectedProperties(tablePropertiesMap),
    isLoading,
    properties,
    setErrorMessage,
    setTablePropertiesMap,
    tablePropertiesMap,
    isSaveButtonDisabled: !hasAnyPropertySelected(),
  };
};

export default useAddProperty;
