import { useEffect, useRef, useState } from 'react';
import { dripCampaignsApi } from '../../DripCampaignsApi';
import { NotificationService } from '../../../../../Components/Notifications';
import { MODES } from '../../constants';

const useTemplate = ({
  closeAllDrawers,
  reloadTemplatesViewData,
  selectedTemplate,
}) => {
  const { templateId: selectedTemplateId } = selectedTemplate;

  const [template, setTemplate] = useState({ ...selectedTemplate });
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(Boolean(selectedTemplateId));
  const [errorMessage, setErrorMessage] = useState('');

  const templateRef = useRef({});
  const duplicatedTemplateRef = useRef({});

  const { templateId } = template;

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const template = await dripCampaignsApi.getTemplateById(templateId);

        templateRef.current = template;
        setTemplate(template);
      } catch (e) {
        setErrorMessage(e.message);
      }
      setIsLoading(false);
    };

    if (templateId && !duplicatedTemplateRef.current.templateId) {
      fetchTemplate();
    }
  }, [templateId]);

  const handleDuplicate = () => {
    duplicatedTemplateRef.current = templateRef.current;

    setTemplate((prevState) => {
      const { contentType, message, subject } = prevState;
      const duplicateTemplate = { contentType, message, subject };

      templateRef.current = duplicateTemplate;

      return duplicateTemplate;
    });
  };

  const getTemplateChanges = () => {
    const fields = ['message', 'subject', 'templateName'];

    const templateUpdates = fields.reduce((updates, field) => {
      if (template[field] !== templateRef.current[field]) {
        updates[field] = template[field];
      }
      return updates;
    }, {});

    return templateUpdates;
  };

  const apiUpdateTemplate = (status) => {
    const editedTemplateChanges = getTemplateChanges();

    const payload = {
      ...editedTemplateChanges,
      status,
    };

    return dripCampaignsApi.updateTemplate(templateId, payload);
  };

  const saveTemplate = async (status) => {
    try {
      templateId
        ? await apiUpdateTemplate(status)
        : await dripCampaignsApi.saveTemplate({ ...template, status });
      reloadTemplatesViewData();
      closeAllDrawers();
      NotificationService.notify('Saved!');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const clearErrorMessage = () => setErrorMessage('');

  const updateTemplate = (templateUpdate) => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      ...templateUpdate,
    }));
  };

  const {
    contentType,
    message = '',
    status,
    subject = '',
    templateName = '',
  } = template;

  const getHasTemplateChanged = () => {
    const {
      message: originalMessage = '',
      subject: originalSubject = '',
      templateName: originalTemplateName = '',
    } = templateRef.current;

    return (
      message !== originalMessage ||
      subject !== originalSubject ||
      templateName !== originalTemplateName
    );
  };

  const hasTemplateChanged = getHasTemplateChanged();

  const onFormGoBack = (isOriginCreateTemplate, closeDrawer) => {
    if (isEditing) {
      setIsEditing(false);
      if (hasTemplateChanged) {
        setTemplate(templateRef.current);
      }
      return;
    }

    if (duplicatedTemplateRef.current.templateId && !isOriginCreateTemplate) {
      const duplicatedTemplate = duplicatedTemplateRef.current;
      duplicatedTemplateRef.current = {};
      setTemplate(duplicatedTemplate);
      return;
    }

    closeDrawer();
  };

  const deleteTemplate = async () => {
    try {
      await dripCampaignsApi.deleteTemplate(templateId);
      reloadTemplatesViewData();
      closeAllDrawers();
      NotificationService.notify('Template Deleted!');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const archiveTemplate = async () => {
    try {
      await dripCampaignsApi.archiveTemplate(templateId);
      reloadTemplatesViewData();
      closeAllDrawers();
      NotificationService.notify('Template Archived!');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const mode = !template.templateId || isEditing ? MODES.FORM : MODES.DISPLAY;

  return {
    archiveTemplate,
    clearErrorMessage,
    contentType,
    deleteTemplate,
    errorMessage,
    handleDuplicate,
    isEditing,
    isLoading,
    hasTemplateChanged,
    message,
    mode,
    onFormGoBack,
    saveTemplate,
    setIsEditing,
    status,
    subject,
    templateName,
    updateTemplate,
  };
};

export default useTemplate;
