import React from 'react';
import DataTable from 'material-table';
import PropTypes from 'prop-types';

import { getColumns } from './table-columns';
import { getTableOptions } from './table-options';
import { PROPERTY_TABLE_VIEWS } from '../../constants';

const getPropertyCountText = (propertiesCount, propertyCount) => {
  return `(${propertiesCount}/${propertyCount})`;
};

const getPropertySelectionText = (propertiesMap, propertiesCount) => {
  const selectedPropertiesCount = Object.values(propertiesMap).filter(
    (property) => property.isSelected
  ).length;
  return `(${selectedPropertiesCount}/${propertiesCount} selected)`;
};

const PropertiesDataTable = ({
  isEditing,
  properties,
  propertyCount,
  tablePropertiesMap,
  setTablePropertiesMap,
  view,
}) => {
  const handleOmniOnChange = (updatedSetting) => {
    setTablePropertiesMap((prevState) => {
      const updatedPropertyMap = Object.entries(prevState).reduce(
        (updatedMap, [id, settings]) => {
          updatedMap[id] = { ...settings, ...updatedSetting };
          return updatedMap;
        },
        {}
      );
      return updatedPropertyMap;
    });
  };

  const handleRowDataOnChange = (propertyId) => (updatedSetting) => {
    setTablePropertiesMap((prevState) => {
      const propertySettings = prevState[propertyId] || {};
      return {
        ...prevState,
        [propertyId]: { ...propertySettings, ...updatedSetting },
      };
    });
  };

  const propertyCountsText = isEditing
    ? getPropertyCountText(properties.length, propertyCount)
    : getPropertySelectionText(tablePropertiesMap, properties.length);

  const tableColumns = getColumns({
    handleOmniOnChange,
    handleRowDataOnChange,
    isEditing,
    propertyCountsText,
    tablePropertiesMap,
    view,
  });

  return (
    <div>
      <DataTable
        columns={tableColumns}
        data={properties.map((property) => ({ ...property }))}
        options={getTableOptions()}
        style={{ overflow: 'hidden' }}
      />
    </div>
  );
};

PropertiesDataTable.propTypes = {
  isEditing: PropTypes.bool,
  properties: PropTypes.array,
  propertyCount: PropTypes.number,
  tablePropertiesMap: PropTypes.object,
  setTablePropertiesMap: PropTypes.func,
  view: PropTypes.oneOf(Object.values(PROPERTY_TABLE_VIEWS)),
};

export default PropertiesDataTable;
