export const LeasingProviderOptionsEnum = {
  OnSite: 'On-Site',
  RealPage: 'RealPage',
  AppFolio: 'AppFolio',
  CustomURL: 'CustomURL',
  OneSiteOLL: 'OneSite/OLL',
  MriProspectConnect: 'MRI/ProspectConnect',
};

export const COVERABLE_COST_TYPES = ['Covered cost one', 'Covered cost dos'];

export const AFFORDABLE_HOUSING_TYPES = [
  'Affordable house 1',
  'Affordable house 2',
];

export const LEASE_LENGTH_OPTIONS = [
  {
    leaseLength: 'Month to month',
    isAvailable: false,
  },
  {
    leaseLength: '3',
    lengthUnit: 'months',
    isAvailable: false,
  },
  {
    leaseLength: '6',
    lengthUnit: 'months',
    isAvailable: false,
  },
  {
    leaseLength: '9',
    lengthUnit: 'months',
    isAvailable: false,
  },
  {
    leaseLength: '9+',
    lengthUnit: 'months',
    isAvailable: false,
  },
  {
    leaseLength: '12',
    lengthUnit: 'months',
    isAvailable: false,
  },
  {
    leaseLength: '12+',
    lengthUnit: 'months',
    isAvailable: false,
  },
];

export const UPCHARGE_LEASE_LENGTH_OPTIONS = [
  {
    leaseLength: 'Month to month',
    isAvailable: false,
    upchargeAmount: 0,
  },
  {
    leaseLength: '2',
    lengthUnit: 'months',
    isAvailable: false,
    upchargeAmount: 0,
  },
  {
    leaseLength: '3',
    lengthUnit: 'months',
    isAvailable: false,
    upchargeAmount: 0,
  },
  {
    leaseLength: '4',
    lengthUnit: 'months',
    isAvailable: false,
    upchargeAmount: 0,
  },
  {
    leaseLength: '5',
    lengthUnit: 'months',
    isAvailable: false,
    upchargeAmount: 0,
  },
  {
    leaseLength: '6',
    lengthUnit: 'months',
    isAvailable: false,
    upchargeAmount: 0,
  },
  {
    leaseLength: '7',
    lengthUnit: 'months',
    isAvailable: false,
    upchargeAmount: 0,
  },
  {
    leaseLength: '8',
    lengthUnit: 'months',
    isAvailable: false,
    upchargeAmount: 0,
  },
  {
    leaseLength: '9',
    lengthUnit: 'months',
    isAvailable: false,
    upchargeAmount: 0,
  },
  {
    leaseLength: '10',
    lengthUnit: 'months',
    isAvailable: false,
    upchargeAmount: 0,
  },
  {
    leaseLength: '11',
    lengthUnit: 'months',
    isAvailable: false,
    upchargeAmount: 0,
  },
  {
    leaseLength: '12',
    lengthUnit: 'months',
    isAvailable: false,
    upchargeAmount: 0,
  },
  {
    leaseLength: '12+',
    lengthUnit: 'months',
    isAvailable: false,
    upchargeAmount: 0,
  },
];

export const TOOLTIP_MESSAGES = {
  AFFORDABLE_HOUSING:
    "Affordable Housing is displayed in the Leasing Manager Application's Leasing Binder",
  APPLICATION_URL:
    'The URL is customizable for OLL providers other than Onesite and Onsite',
  COVERED_COSTS:
    "Covered Costs are displayed in the Leasing Manager Application's Leasing Binder",
  CUSTOM_FEES: 'Add additional fees for calculating Knock Quotes',
  LEASING:
    "Leasing Information is displayed in the Leasing Manager Application's Leasing Binder",
  ONLINE_LEASING_PROVIDER:
    "Select this property's Online Leasing Provider. This will auto-generate the correct Application URL for applicants, or if the Custom option is chosen, allow setting a custom URL.",
  PET_POLICY:
    "Pet Policies are displayed in the Leasing Manager Application's Leasing Binder",
};
