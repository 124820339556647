import React from 'react';
import PropTypes from 'prop-types';

import { TextField, makeStyles } from '@knockrentals/knock-shared-web';
import CharacterLimitDisplay from './CharacterLimitDisplay';

const useStyles = makeStyles(() => ({
  characterMessage: {
    marginLeft: '5px',
  },
  limitedTextArea: {
    marginBottom: '.5rem',
    '& textarea': {
      minHeight: '80px',
      boxSizing: 'border-box',
    },
  },
}));

const CharacterLimitedTextArea = ({
  characterLimit,
  error,
  helperText,
  isDisabled,
  label,
  name,
  onChange,
  onFocus,
  value,
}) => {
  const classes = useStyles();
  return (
    <div className="text-area-wrapper">
      <TextField
        className={classes.limitedTextArea}
        disabled={isDisabled}
        label={label}
        inputProps={{
          maxLength: characterLimit,
          style: { padding: '0 10px', fontSize: '14px' },
        }}
        name={name}
        margin="none"
        multiline
        maxRows={4}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        fullWidth
        helperText={helperText}
        error={error}
      />

      {characterLimit && (
        <CharacterLimitDisplay
          limit={characterLimit}
          textLength={value.length}
        />
      )}
    </div>
  );
};

CharacterLimitedTextArea.propTypes = {
  characterLimit: PropTypes.number,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
};

export default CharacterLimitedTextArea;
