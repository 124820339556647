import { apiRemote } from '../../Services/RemoteService';

class DoorwayAPI {
  static getDoorwaySettings() {
    return apiRemote
      .get(`admin/doorway`)
      .then((response) => response.json())
      .catch((e) => e);
  }

  static getPropertiesScheduling() {
    return apiRemote
      .get('admin/scheduling')
      .then((response) => response.json())
      .catch((e) => e);
  }

  static updateDoorwaySettings(
    propertyId,
    { preferences, hours, message_templates }
  ) {
    return apiRemote
      .put(`admin/doorway/${propertyId}`, {
        preferences,
        hours,
        message_templates,
      })
      .then((response) => response.json())
      .catch((e) => e);
  }

  static updatePropertyOwnerSchedulingPreferences(
    propertyId,
    {
      selfSchedule,
      availabilityBuffer,
      clusterTours,
      numberOfSchedulingDays,
      postAppointmentBuffer,
      preAppointmentBuffer,
      tourDuration,
      hideScheduling,
      inPerson,
      liveVideo,
      selfGuidedToursBlock,
      virtualTourLinks,
      virtualTourLinksMapping,
      selfGuidedToursEnabled,
    }
  ) {
    const payload = {
      all_showings: selfSchedule,
      availability_buffer: availabilityBuffer,
      cluster_tours: clusterTours,
      number_of_scheduling_days: numberOfSchedulingDays,
      post_appointment_buffer: postAppointmentBuffer,
      pre_appointment_buffer: preAppointmentBuffer,
      tour_duration: tourDuration,
      hide_scheduling: hideScheduling,
      in_person_tours: inPerson,
      live_video_tour_type: liveVideo === true ? 'external' : false,
      self_guided_tours_block: selfGuidedToursBlock,
      virtual_tour_links: virtualTourLinks,
      virtual_tour_links_mapping: virtualTourLinksMapping,
      self_guided_tours_enabled: selfGuidedToursEnabled,
    };

    return apiRemote
      .put(`admin/scheduling/${propertyId}`, payload)
      .then((response) => response.json());
  }
}

export default DoorwayAPI;
