import React, { useState } from 'react';
import { Text, ThemeProvider } from '@knockrentals/knock-shared-web';
import { ErrorDialog } from './ErrorDialog';
import {
  SchedulingGrid,
  usePropertyList,
  useSchedulingGrid,
} from './SchedulingGrid';
import { SchedulingPreferences } from './SchedulingPreferences';
import FeatureDrawer from '../common/FeatureDrawer';
import './_SchedulingPage.scss';

const DESCRIPTION_MESSAGE =
  'Select the pencil icon to update the settings for a single location. Select the check boxes to change settings for multiple properties. When editing multiple properties, the settings you do not change will remain as they were for each property.';

const SchedulingPage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [propertiesEditList, setPropertiesEditList] = useState([]);

  const {
    isFetchingPropertiesList,
    propertySelectionMap,
    selectedPropertyCount,
    setPropertySelectionMap,
  } = usePropertyList({ setErrorMessage });

  const {
    getCurrentPagePropertyCount,
    getPageIndex,
    getTableData,
    getTotalPropertyCount,
    propertiesByPage,
    rowsPerPage,
    rowsPerPageOptions,
    setPageIndex,
    setPropertiesByPage,
    setRowsPerPage,
    setShouldLoadPageIndex,
    tableRef,
    toggleSchedulingSwitch,
    updateSchedulingGridData,
  } = useSchedulingGrid({ setErrorMessage });

  const handleEditProperties = (properties) => {
    setPropertiesEditList(properties);
  };

  const handleBackButtonOnClick = () => {
    setPropertiesEditList([]);
  };

  const isEditing = propertiesEditList.length > 0;

  return (
    <ThemeProvider>
      <ErrorDialog
        closeDialog={() => setErrorMessage('')}
        isOpen={Boolean(errorMessage)}
        message={errorMessage}
      />

      <FeatureDrawer isOpen={isEditing}>
        <SchedulingPreferences
          handleBackButtonOnClick={handleBackButtonOnClick}
          selectedProperties={propertiesEditList}
          setErrorMessage={setErrorMessage}
          updateSchedulingGridData={updateSchedulingGridData}
        />
      </FeatureDrawer>

      <section className="scheduling-page">
        <Text variant="h6">Scheduling</Text>
        <Text className="scheduling-page-description" variant="body1">
          {DESCRIPTION_MESSAGE}
        </Text>

        <SchedulingGrid
          getCurrentPagePropertyCount={getCurrentPagePropertyCount}
          getPageIndex={getPageIndex}
          getTableData={getTableData}
          getTotalPropertyCount={getTotalPropertyCount}
          isFetchingPropertiesList={isFetchingPropertiesList}
          handleEditProperties={handleEditProperties}
          propertiesByPage={propertiesByPage}
          propertySelectionMap={propertySelectionMap}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          selectedPropertyCount={selectedPropertyCount}
          setPageIndex={setPageIndex}
          setPropertiesByPage={setPropertiesByPage}
          setPropertySelectionMap={setPropertySelectionMap}
          setRowsPerPage={setRowsPerPage}
          setShouldLoadPageIndex={setShouldLoadPageIndex}
          tableRef={tableRef}
          toggleSchedulingSwitch={toggleSchedulingSwitch}
        />
      </section>
    </ThemeProvider>
  );
};

export default SchedulingPage;
