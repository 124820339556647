import React from 'react';
import { IndeterminateSwitch } from '../../../common/IndeterminateSwitch';
import { DataTableCheckbox } from './DataTableCheckbox';
import { PROPERTY_TABLE_VIEWS } from '../../constants';

export const getInputSummaries = (propertiesMap) => {
  const initialCounts = {
    selected: 0,
    unselected: 0,
    enabled: 0,
    disabled: 0,
  };

  const properties = Object.values(propertiesMap);
  const omniCounts = properties.reduce((countMap, property) => {
    property.isSelected ? countMap.selected++ : countMap.unselected++;
    property.isEnabled ? countMap.enabled++ : countMap.disabled++;

    return countMap;
  }, initialCounts);

  const propertyCount = properties.length;

  return {
    areAllSelected:
      propertyCount !== 0 && omniCounts.selected === propertyCount,
    areAllUnselected: omniCounts.unselected === propertyCount,
    areAllSwitchedOn:
      propertyCount !== 0 && omniCounts.enabled === propertyCount,
    areAllSwitchedOff: omniCounts.disabled === propertyCount,
  };
};

export const getColumns = ({
  handleOmniOnChange,
  handleRowDataOnChange,
  isEditing = false,
  propertyCountsText,
  tablePropertiesMap,
  view,
}) => {
  const {
    areAllSelected,
    areAllUnselected,
    areAllSwitchedOn,
    areAllSwitchedOff,
  } = getInputSummaries(tablePropertiesMap);

  return [
    (!isEditing ||
      view !== PROPERTY_TABLE_VIEWS.MANAGE_PROPERTY_ASSIGNMENT) && {
      align: 'left',
      field: 'isSelected',
      title: (
        <DataTableCheckbox
          ariaLabel="select all properties checkbox"
          isChecked={areAllSelected}
          isIndeterminate={!areAllSelected && !areAllUnselected}
          onChangeHandler={handleOmniOnChange}
        />
      ),
      render: (rowData) => {
        const { isSelected } = tablePropertiesMap[rowData.propertyId] || {};
        return (
          <DataTableCheckbox
            arialLabel="select property checkbox"
            isChecked={isSelected}
            onChangeHandler={handleRowDataOnChange(rowData.propertyId)}
          />
        );
      },
      minWidth: '20px',
      maxWidth: '20px',
    },
    {
      align: 'left',
      field: 'propertyName',
      title: `Property Name ${propertyCountsText}`,
      width: '200px',
      sorting: true,
    },
    (!isEditing ||
      view !== PROPERTY_TABLE_VIEWS.MANAGE_PROPERTY_ASSIGNMENT) && {
      align: 'right',
      field: 'assignedTemplateName',
      title: 'Template Name',
      width: '200px',
    },
    isEditing &&
      view === PROPERTY_TABLE_VIEWS.MANAGE_PROPERTY_ASSIGNMENT && {
        align: 'right',
        field: 'isEnabled',
        title: (
          <IndeterminateSwitch
            ariaLabel="all enabled switch"
            isChecked={areAllSwitchedOn}
            isIndeterminate={!areAllSwitchedOn && !areAllSwitchedOff}
            name="isEnabled"
            onChangeHandler={handleOmniOnChange}
          />
        ),
        render: (rowData) => {
          const { isEnabled } = tablePropertiesMap[rowData.propertyId] || {};
          return (
            <IndeterminateSwitch
              ariaLabel="enabled switch"
              isChecked={isEnabled}
              name="isEnabled"
              onChangeHandler={handleRowDataOnChange(rowData.propertyId)}
            />
          );
        },
        width: '160px',
      },
  ].filter((column) => column);
};
