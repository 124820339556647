import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@knockrentals/knock-shared-web';
import { AddCircleSvgIcon } from '../../../../Components/Icons';

const useStyles = makeStyles({
  addIcon: {
    height: '18px',
    width: '18px',
  },
  createButton: {
    margin: '28px 0 16px',
  },
});

const AddIconButton = ({ disabled, handleOnClick, primaryText }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.createButton}
      color="primary"
      onClick={handleOnClick}
      startIcon={<AddCircleSvgIcon className={classes.addIcon} />}
      variant="text"
      disabled={!!disabled}
    >
      {primaryText}
    </Button>
  );
};

AddIconButton.propTypes = {
  disabled: PropTypes.bool,
  handleOnClick: PropTypes.func,
  primaryText: PropTypes.string,
};

export default AddIconButton;
