import React, { Component } from 'react';
import AttributesAPI from '../AttributesAPI';

import AddAttributeModal from './Modals/AddAttributeModal';
import AddAttributeTypeModal from './Modals/AddAttributeTypeModal';
import EditAttributeModal from './Modals/EditAttributeModal';
import EditAttributeTypeModal from './Modals/EditAttributeTypeModal';

import '../_attributeInfo.scss';
import * as _ from 'lodash';

class PropertyAttributes extends Component {
  state = {
    attributeTypes: [],
    properties: [],

    isAddingAttribute: false,
    isAddingAttributeType: false,

    isEditingAttribute: false,
    isEditingAttributeType: false,
  };

  ATTRIBUTE_TYPE_VALUE_SUGGESTIONS = {};

  UNSAFE_componentWillMount() {
    this.loadProperties();
    this.loadAttributeTypes();
  }

  loadProperties() {
    AttributesAPI.getPropertyAttributes()
      .then(response => {
        const properties = response.company_property_attributes;
        properties.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });

        _.forEach(properties, (property) => {
          _.forEach(property.attributes, (attribute) => {
            const suggestions =
              this.ATTRIBUTE_TYPE_VALUE_SUGGESTIONS[attribute.attribute_type_id] || {};
            suggestions[attribute.attribute_type_value] = true;
            this.ATTRIBUTE_TYPE_VALUE_SUGGESTIONS[attribute.attribute_type_id] = suggestions;
          });
        });

        this.setState({ properties });
      })
      .catch(e => {
        console.log(e);
      });
  }

  loadAttributeTypes() {
    AttributesAPI.getPropertyAttributeTypes()
      .then(response => {
        const attributeTypes = response.attribute_types;
        attributeTypes.sort((a, b) => {
          return a.id > b.id ? 1 : -1;
        });

        _.forEach(attributeTypes, (attributeType) => {
          const suggestions = this.ATTRIBUTE_TYPE_VALUE_SUGGESTIONS[attributeType.id] || {};
          this.ATTRIBUTE_TYPE_VALUE_SUGGESTIONS[attributeType.id] = suggestions;
        });

        this.setState({ attributeTypes });
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    return (
      <div>
        {this.state.isAddingAttribute ? (
          <AddAttributeModal
            property={this.state.isAddingAttribute.property}
            attributeType={this.state.isAddingAttribute.attributeType}
            suggestions={
              Object.keys(this.ATTRIBUTE_TYPE_VALUE_SUGGESTIONS[this.state.isAddingAttribute.attributeType.id]).sort()
            }
            createAttribute={this.createAttribute.bind(this)}
            toggleModal={this.toggleAddAttributeModal.bind(this)}
          />
        ) : null}
        {this.state.isAddingAttributeType ? (
          <AddAttributeTypeModal
            createAttributeType={this.createAttributeType.bind(this)}
            toggleModal={this.toggleAddAttributeTypeModal.bind(this)}
          />
        ) : null}
        {this.state.isEditingAttribute ? (
          <EditAttributeModal
            property={this.state.isEditingAttribute.property}
            attributeType={this.state.isEditingAttribute.attributeType}
            attribute={this.state.isEditingAttribute.attribute}
            suggestions={
              Object.keys(this.ATTRIBUTE_TYPE_VALUE_SUGGESTIONS[this.state.isEditingAttribute.attributeType.id]).sort()
            }
            updateAttribute={this.updateAttribute.bind(this)}
            deleteAttribute={this.deleteAttribute.bind(this)}
            toggleModal={this.toggleEditAttributeModal.bind(this)}
          />
        ) : null}
        {this.state.isEditingAttributeType ? (
          <EditAttributeTypeModal
            attributeType={this.state.isEditingAttributeType.attributeType}
            updateAttributeType={this.updateAttributeType.bind(this)}
            deleteAttributeType={this.deleteAttributeType.bind(this)}
            toggleModal={this.toggleEditAttributeTypeModal.bind(this)}
          />
        ) : null}

        <table className="wire-frame-table">
          <thead>
            <tr>
              <th></th>
              {this.state.attributeTypes.map((attributeType) => {
                return (
                  <th key={`attribute-type-${attributeType.id}`}>
                    <span
                      className="actionable cell-value-wrap"
                      onClick={this.toggleEditAttributeTypeModal.bind(this, {
                        attributeType,
                      })}
                    >
                      {attributeType.attribute_type} <i className="fa fa-pencil" />
                    </span>
                  </th>
                );
              })}
              <th>
                <span
                  className="actionable"
                  onClick={this.toggleAddAttributeTypeModal.bind(this, true)}
                >
                  <i className="fa fa-plus-circle" /> Attribute
                </span>
              </th>
            </tr>
          </thead>
          <tbody>{this.renderPropertyRows()}</tbody>
        </table>
      </div>
    );
  }

  renderPropertyRows() {
    return this.state.properties.map((property, i) => {
      return (
        <tr key={`property-${property.id}`}>
          <td>{property.name}</td>
          {this.state.attributeTypes.map((attributeType, j) => {
            let valuesInCell = 0;
            return (
              <td key={`cell-${i}-${j}`}>
                <div>
                  {property.attributes.map((attribute, k) => {
                    if (attributeType.id === attribute.attribute_type_id) {
                      valuesInCell++;
                      return (
                        <div key={`cell-value-${i}-${j}-${k}`}>
                          <button
                            className="btn-sm btn-primary cell-value cell-value-wrap"
                            onClick={this.toggleEditAttributeModal.bind(this, {
                              property,
                              attributeType,
                              attribute,
                            })}
                          >
                            <i className="fa fa-tag" /> {attribute.attribute_type_value}
                          </button>
                        </div>
                      );
                    }

                    return null;
                  })}
                  {valuesInCell === 0 ? (
                    <button
                      className="btn-sm btn-success cell-value"
                      onClick={this.toggleAddAttributeModal.bind(this, {
                        property,
                        attributeType,
                      })}
                    >
                      <i className="fa fa-plus-circle" /> New
                    </button>
                  ) : null}
                </div>
              </td>
            );
          })}
          <td className="shaded" />
        </tr>
      );
    });
  }

  createAttributeType(attributeType) {
    AttributesAPI.postPropertyAttributeType(attributeType).then(() => {
      this.loadProperties();
      this.loadAttributeTypes();
      this.toggleAddAttributeTypeModal(false);
    });
  }

  updateAttributeType(attributeTypeId, attributeType) {
    AttributesAPI.updatePropertyAttributeType(attributeTypeId, attributeType).then(() => {
      this.loadProperties();
      this.loadAttributeTypes();
      this.toggleEditAttributeTypeModal(false);
    });
  }

  deleteAttributeType(attributeTypeId) {
    AttributesAPI.deletePropertyAttributeType(attributeTypeId).then(() => {
      this.loadProperties();
      this.loadAttributeTypes();
      this.toggleEditAttributeTypeModal(false);
    });
  }

  createAttribute(propertyId, attributeTypeId, attributeTypeValue) {
    AttributesAPI.createPropertyAttribute(propertyId, attributeTypeId, attributeTypeValue).then(
      () => {
        this.loadProperties();
        this.toggleAddAttributeModal(false);
      }
    );
  }

  updateAttribute(attributeId, attributeTypeValue) {
    AttributesAPI.updatePropertyAttribute(attributeId, attributeTypeValue).then(() => {
      this.loadProperties();
      this.toggleEditAttributeModal(false);
    });
  }

  deleteAttribute(attributeId) {
    AttributesAPI.deletePropertyAttribute(attributeId).then(() => {
      this.loadProperties();
      this.toggleEditAttributeModal(false);
    });
  }

  toggleAddAttributeModal(obj) {
    this.setState({
      isAddingAttribute: obj,
    });
  }

  toggleAddAttributeTypeModal(obj) {
    this.setState({
      isAddingAttributeType: obj,
    });
  }

  toggleEditAttributeModal(obj) {
    this.setState({
      isEditingAttribute: obj,
    });
  }

  toggleEditAttributeTypeModal(obj) {
    this.setState({
      isEditingAttributeType: obj,
    });
  }
}

export default PropertyAttributes;
