import React, { Component } from 'react';
import './DraggableListCell.scss';

/**
 * A cell of data that is managed by a DraggableListRow parent. In order to prevent resizing bugs, maintains
 * a ref that it used to explicitly set cell dimensions when certain actions (ie, dragging) occur.
 *
 * @param {string} props.value The display value when the cell is not editing
 * @param {string} props.editedValue The display value when the cell is editing
 * @param {boolean} props.isEditing The editing state of the cell
 * @param {function} props.handleChange The function to be called when the cell's value is updated. Received the updated value and field as arguments.
 */
export class DraggableListCell extends Component {
  ref;

  // We take snapshot when drag is beginning so we can retain the current dimensions throughout the action.
  getSnapshotBeforeUpdate(prevProps) {
    if (!this.ref) {
      return null;
    }

    const isDragStarting =
      this.props.isDragOccurring && !prevProps.isDragOccurring;

    if (!isDragStarting && !this.props.isEditing) {
      return null;
    }

    const { width, height } = this.ref.getBoundingClientRect();

    const snapshot = {
      width,
      height,
    };

    return snapshot;
  }

  // If we took a snapshot, then drag is beginning, so we want to explicitly retain the current width or height
  componentDidUpdate(prevProps, prevState, snapshot) {
    const ref = this.ref;

    if (!ref) {
      return;
    }

    if (snapshot) {
      if (ref.style.width === snapshot.width) {
        return;
      }
      ref.style.width = `${snapshot.width}px`;
      ref.style.height = `${snapshot.height}px`;
      return;
    }

    if (this.props.isDragOccurring) {
      return;
    }

    // inline styles not applied
    if (ref.style.width == null) {
      return;
    }

    // no snapshot and drag is finished - clear the inline styles
    ref.style.removeProperty('height');
    ref.style.removeProperty('width');
  }

  // Sets a ref during render that is used to maintain component dimensions
  setRef = (ref) => {
    this.ref = ref;
  };

  onContentChange = (e) => {
    this.props.handleChange(e.target.value, this.props.field);
  };

  render() {
    return (
      <td ref={this.setRef} className="draggable-list-cell">
        {this.props.isEditing ? (
          <input
            name="draggable-list-cell-input"
            className="draggable-list-cell-value-input"
            type="text"
            onChange={this.onContentChange}
            value={this.props.editedValue}
          />
        ) : (
          <div className="draggable-list-cell-value">{this.props.value}</div>
        )}
      </td>
    );
  }
}
