import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@material-ui/core';
import {
  InputCheckbox,
  List,
  ListItem,
  makeStyles,
  Text,
  Tooltip,
} from '@knockrentals/knock-shared-web';
import { DISABLED_PROPERTY_TOOLTIP_TEXT } from '../Pages/Features/DripCampaignPage/DrawerView/Campaign/constants';

export const EMPTY_MESSAGE = 'No additional properties to select';

const useStyles = makeStyles({
  propertyListSubheader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  messageItem: {
    justifyContent: 'center',
  },
});

const PropertyList = ({
  propertiesList,
  selectedCount,
  selectedPropertiesMap,
  setSelectedPropertiesMap,
}) => {
  const classes = useStyles();

  const isSelectAllChecked =
    selectedCount === propertiesList.length && propertiesList.length > 0;
  const isSelectAllIndeterminate = selectedCount > 0 && !isSelectAllChecked;

  const handleSelectProperty = (propertyId) => (e) => {
    const { checked } = e.target;
    setSelectedPropertiesMap((prevState) => ({
      ...prevState,
      [propertyId]: checked,
    }));
  };

  const handleSelectAllOnChange = (e) => {
    const isSelected = isSelectAllIndeterminate ? true : e.target.checked;

    const updatedSelections = propertiesList.reduce(
      (selectionMap, property) => {
        selectionMap[property.propertyId] = property.isPropertyAvailableForTriggerType !== false && isSelected;
        return selectionMap;
      },
      {}
    );

    setSelectedPropertiesMap(updatedSelections);
  };

  return (
    <Paper elevation={2}>
      <List
        disablePadding
        subheader={
          <ListItem className={classes.propertyListSubheader} divider>
            <InputCheckbox
              checked={isSelectAllChecked}
              color="primary"
              disabled={!propertiesList.length}
              indeterminate={isSelectAllIndeterminate}
              label="Select All"
              onChange={handleSelectAllOnChange}
            />
            <Text color="secondary" variant="body2">
              {`${selectedCount || 0} / ${propertiesList.length}`}
            </Text>
          </ListItem>
        }
      >
        {propertiesList.length === 0 && (
          <ListItem className={classes.messageItem} divider>
            <Text variant="body2" color="secondary">
              {EMPTY_MESSAGE}
            </Text>
          </ListItem>
        )}

        {propertiesList.length > 0 &&
          propertiesList.map((property) => {
            const isDisabled = property.isPropertyAvailableForTriggerType === false;

            return (
              <ListItem
                disabled={isDisabled} 
                divider 
                key={property.propertyId}
              >
                <Tooltip 
                  arrow
                  placement="right" 
                  title={ isDisabled ? DISABLED_PROPERTY_TOOLTIP_TEXT : "" }
                >
                  <span>
                    <InputCheckbox
                      checked={selectedPropertiesMap[property.propertyId] || false}
                      color="primary"
                      disabled={isDisabled}
                      label={property.propertyName}
                      onChange={handleSelectProperty(property.propertyId)}
                    />
                  </span>
                </Tooltip>
              </ListItem>
            );
          })}
      </List>
    </Paper>
  );
};

PropertyList.propTypes = {
  propertiesList: PropTypes.arrayOf(
    PropTypes.shape({
      isPropertyAvailableForTriggerType: PropTypes.bool,
      propertyId: PropTypes.number,
      propertyName: PropTypes.string,
    }),
  ),
  selectedCount: PropTypes.number,
  selectedPropertiesMap: PropTypes.objectOf(PropTypes.bool),
  setSelectedPropertiesMap: PropTypes.func,
};

export default PropertyList;
