import { getDefaultTableOptions } from "../../../../Services/SharedService";

const defaultTableOptions = getDefaultTableOptions();

export const getTableOptions = () => ({
  ...defaultTableOptions,
  actionsCellStyle: {
    width: '40px',
  },
  cellStyle: {
    padding: '12px 16px 12px 16px',
  },
  headerStyle: {
    fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
    lineHeight: '24px',
    padding: '8px 16px',
    fontSize: '14px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
});
