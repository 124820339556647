import React, { Component } from 'react';
import * as _ from 'lodash';

class PropertyInfoEditAmenities extends Component {
  state = {
    utilitiesEstimate: '',
    utilitiesIncluded: [],

    parkingNotes: '',
    parkingTypes: [],

    furnishing: [],

    laundryNotes: '',
    laundryTypes: [],

    appliances: [],

    views: [],

    exterior: [],
    heatingAndCooling: [],
    wiring: [],
    additionalFeatures: [],
    securityAndAccess: [],
    facilitiesAndRecreation: [],

    newUtilityIncluded: '',
    newParkingType: '',
    newFurnishingType: '',
    newLaundryType: '',
    newApplianceType: '',
    newViewType: '',
    newVentilationType: '',
    newExteriorType: '',
    newWiringType: '',
    newSecurityType: '',
    newRecreationType: '',
    newAdditionalType: '',

    featuredLaundry: [],
    featuredParking: [],
    featuredPool: [],
    featuredGym: [],
    featuredPets: [],

    newFeaturedLaundryItem: '',
    newFeaturedParkingItem: '',
    newFeaturedPoolItem: '',
    newFeaturedGymItem: '',
    newFeaturedPetsItem: '',
  };

  VIEW_TYPES = ['City', 'Mountain', 'Water', 'Park'];
  UTILITY_TYPES = ['Water', 'Sewage', 'Garbage', 'Gas', 'Electricity', 'Cable', 'Internet'];
  APPLIANCE_TYPES = ['Dishwasher', 'Refrigerator', 'Garbage disposal', 'Microwave', 'Range/Oven'];
  SECURITY_TYPES = [
    'Elevator',
    'Security system',
    'Controlled access',
    'Gated entry',
    'Doorman',
    'Disability access',
  ];
  WIRING_TYPES = ['Cable-ready', 'High-speed internet', 'Wired', 'Intercom system'];
  PARKING_TYPES = ['Garage', 'Covered', 'Off-Street', 'On-Street'];
  FURNISH_TYPES = ['Furnished', 'Unfurnished'];
  LAUNDRY_TYPES = ['In Unit', 'Shared'];

  VENTILATION_TYPES = [
    'Heat: forced air',
    'Central AC',
    'Air conditioning',
    'Ceiling fans',
    'Double pane / Storm windows',
  ];

  EXTERIOR_TYPES = [
    'Private pool',
    'Balcony/patio',
    'Deck',
    'Yard',
    'Yard-Fenced',
    'Pond',
    'Lake',
    'Porch',
    'Garden',
    'Sprinkler system',
    'Greenhouse',
  ];

  RECREATION_TYPES = [
    'Shared pool',
    'Fitness center',
    'Clubhouse',
    'Playground',
    'Barbecue',
    'Business center',
    'Tennis court',
    'Basketball court',
    'Sports court',
    'Golf course',
    'Dock',
  ];

  ADDITIONAL_TYPES = [
    'Hardwood floor',
    'Tile floor',
    'Granite countertop',
    'Fireplace',
    'Vaulted ceiling',
    'Jacuzzi',
    'Sauna',
    'Hot tub / Spa',
    'Skylights',
    'Non-Smoking Property',
  ];

  FEATURED_LAUNDRY_TYPES = ['In Unit', 'Shared'];
  FEATURED_PARKING_TYPES = ['Garage', 'Covered', 'Off-Street', 'On-Street'];
  FEATURED_POOL_TYPES = ['Private Pool', 'Shared Pool'];
  FEATURED_GYM_TYPES = ['Fitness Center'];
  FEATURED_PETS_TYPES = ['Small Dogs', 'Large Dogs', 'Cats'];

  componentWillMount() {
    this.setPropertyIntoState(this.props.property);
  }

  componentWillReceiveProps(nextProps) {
    this.setPropertyIntoState(nextProps.property);
  }

  setPropertyIntoState(property) {
    property.data.featured_amenities = (!_.isEmpty(property.data.featured_amenities))
      ? property.data.featured_amenities
      : {
        laundry: {
          types: [],
        },
        parking: {
          types: [],
        },
        pool: {
          types: [],
        },
        gym: {
          types: [],
        },
        pets: {
          types: [],
        },
      };
    this.setState({
      utilitiesEstimate: property.data.utilities.estimatedCost
        ? property.data.utilities.estimatedCost
        : '',
      utilitiesIncluded: property.data.utilities.types
        ? property.data.utilities.types
        : this.generateNewBooleanMapping(this.UTILITY_TYPES, 'name', 'included'),

      parkingNotes: property.data.parking.notes ? property.data.parking.notes : '',
      parkingTypes: property.data.parking.types
        ? property.data.parking.types
        : this.generateNewBooleanMapping(this.PARKING_TYPES, 'name', 'available'),

      furnishing:
        property.data.furnishing.length > 0
          ? property.data.furnishing
          : this.generateNewBooleanMapping(this.FURNISH_TYPES, 'name', 'available'),

      laundryNotes: property.data.laundry.notes ? property.data.laundry.notes : '',
      laundryTypes: property.data.laundry.types
        ? property.data.laundry.types
        : this.generateNewBooleanMapping(this.LAUNDRY_TYPES, 'name', 'available'),

      appliances:
        property.data.appliances.length > 0
          ? property.data.appliances
          : this.generateNewBooleanMapping(this.APPLIANCE_TYPES, 'name', 'available'),

      views:
        property.data.views.length > 0
          ? property.data.views
          : this.generateNewBooleanMapping(this.VIEW_TYPES, 'name', 'available'),

      exterior: property.data.amenities.exterior
        ? property.data.amenities.exterior
        : this.generateNewBooleanMapping(this.EXTERIOR_TYPES, 'name', 'available'),

      heatingAndCooling: property.data.amenities.heatingAndCooling
        ? property.data.amenities.heatingAndCooling
        : this.generateNewBooleanMapping(this.VENTILATION_TYPES, 'name', 'available'),

      wiring: property.data.amenities.wiring
        ? property.data.amenities.wiring
        : this.generateNewBooleanMapping(this.WIRING_TYPES, 'name', 'available'),

      additionalFeatures: property.data.amenities.additional
        ? property.data.amenities.additional
        : this.generateNewBooleanMapping(this.ADDITIONAL_TYPES, 'name', 'available'),

      securityAndAccess: property.data.amenities.security
        ? property.data.amenities.security
        : this.generateNewBooleanMapping(this.SECURITY_TYPES, 'name', 'available'),

      facilitiesAndRecreation: property.data.amenities.recreation
        ? property.data.amenities.recreation
        : this.generateNewBooleanMapping(this.RECREATION_TYPES, 'name', 'available'),

      featuredLaundry:
        property.data.featured_amenities.laundry.types.length > 0
          ? property.data.featured_amenities.laundry.types
          : this.generateNewBooleanMapping(this.FEATURED_LAUNDRY_TYPES, 'name', 'available'),

      featuredParking:
        property.data.featured_amenities.parking.types.length > 0
          ? property.data.featured_amenities.parking.types
          : this.generateNewBooleanMapping(this.FEATURED_PARKING_TYPES, 'name', 'available'),

      featuredPool:
        property.data.featured_amenities.pool.types.length > 0
          ? property.data.featured_amenities.pool.types
          : this.generateNewBooleanMapping(this.FEATURED_POOL_TYPES, 'name', 'available'),

      featuredGym:
        property.data.featured_amenities.gym.types.length > 0
          ? property.data.featured_amenities.gym.types
          : this.generateNewBooleanMapping(this.FEATURED_GYM_TYPES, 'name', 'available'),

      featuredPets:
        property.data.featured_amenities.pets.types.length > 0
          ? property.data.featured_amenities.pets.types
          : this.generateNewBooleanMapping(this.FEATURED_PETS_TYPES, 'name', 'available'),
    });
  }

  render() {
    return (
      <div className="property-info-edit-container">
        <div className="full-width">
          <h2 className="side-margin-lg">Featured Amenities</h2>
          <div className="property-info-edit-field-container">
            <h3 className="side-margin-lg">Laundry</h3>
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.featuredLaundry.map((featuredLaundryType, key) => {
                  return (
                    <span
                      className="inline-block side-margin-lg"
                      key={`featured-laundry-type-${key}`}
                    >
                      <input
                        type="checkbox"
                        checked={featuredLaundryType.available}
                        onChange={this.checkboxUpdated(
                          'featuredLaundry',
                          key,
                          'name',
                          'available',
                          this.FEATURED_LAUNDRY_TYPES
                        )}
                      />
                      <span>
                        {featuredLaundryType.name}
                        {!_.includes(this.FEATURED_LAUNDRY_TYPES, featuredLaundryType.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'featuredLaundry', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newFeaturedLaundryItem}
                  name="newFeaturedLaundryItem"
                  onChange={this.inputChanged('newFeaturedLaundryItem').bind(this)}
                  className="knock-react-input"
                  placeholder="Another included utility"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'featuredLaundry',
                    'name',
                    'newFeaturedLaundryItem',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
          <div className="property-info-edit-field-container">
            <h3 className="side-margin-lg">Parking</h3>
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.featuredParking.map((featuredParkingType, key) => {
                  return (
                    <span
                      className="inline-block side-margin-lg"
                      key={`featured-parking-type-${key}`}
                    >
                      <input
                        type="checkbox"
                        checked={featuredParkingType.available}
                        onChange={this.checkboxUpdated(
                          'featuredParking',
                          key,
                          'name',
                          'available',
                          this.FEATURED_PARKING_TYPES
                        )}
                      />
                      <span>
                        {featuredParkingType.name}
                        {!_.includes(this.FEATURED_PARKING_TYPES, featuredParkingType.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'featuredParking', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newFeaturedParkingItem}
                  name="newFeaturedParkingItem"
                  onChange={this.inputChanged('newFeaturedParkingItem').bind(this)}
                  className="knock-react-input"
                  placeholder="Another included utility"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'featuredParking',
                    'name',
                    'newFeaturedParkingItem',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
          <div className="property-info-edit-field-container">
            <h3 className="side-margin-lg">Pool</h3>
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.featuredPool.map((featuredPoolType, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`featured-pool-type-${key}`}>
                      <input
                        type="checkbox"
                        checked={featuredPoolType.available}
                        onChange={this.checkboxUpdated(
                          'featuredPool',
                          key,
                          'name',
                          'available',
                          this.FEATURED_POOL_TYPES
                        )}
                      />
                      <span>
                        {featuredPoolType.name}
                        {!_.includes(this.FEATURED_POOL_TYPES, featuredPoolType.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'featuredPool', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newFeaturedPoolItem}
                  name="newFeaturedPoolItem"
                  onChange={this.inputChanged('newFeaturedPoolItem').bind(this)}
                  className="knock-react-input"
                  placeholder="Another included utility"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'featuredPool',
                    'name',
                    'newFeaturedPoolItem',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
          <div className="property-info-edit-field-container">
            <h3 className="side-margin-lg">Gym</h3>
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.featuredGym.map((featuredGymType, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`featured-gym-type-${key}`}>
                      <input
                        type="checkbox"
                        checked={featuredGymType.available}
                        onChange={this.checkboxUpdated(
                          'featuredGym',
                          key,
                          'name',
                          'available',
                          this.FEATURED_GYM_TYPES
                        )}
                      />
                      <span>
                        {featuredGymType.name}
                        {!_.includes(this.FEATURED_GYM_TYPES, featuredGymType.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'featuredGym', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newFeaturedGymItem}
                  name="newFeaturedGymItem"
                  onChange={this.inputChanged('newFeaturedGymItem').bind(this)}
                  className="knock-react-input"
                  placeholder="Another included utility"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'featuredGym',
                    'name',
                    'newFeaturedGymItem',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
          <div className="property-info-edit-field-container">
            <h3 className="side-margin-lg">Pets</h3>
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.featuredPets.map((featuredPetsType, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`featured-pets-type-${key}`}>
                      <input
                        type="checkbox"
                        checked={featuredPetsType.available}
                        onChange={this.checkboxUpdated(
                          'featuredPets',
                          key,
                          'name',
                          'available',
                          this.FEATURED_PETS_TYPES
                        )}
                      />
                      <span>
                        {featuredPetsType.name}
                        {!_.includes(this.FEATURED_PETS_TYPES, featuredPetsType.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'featuredPets', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newFeaturedPetsItem}
                  name="newFeaturedPetsItem"
                  onChange={this.inputChanged('newFeaturedPetsItem').bind(this)}
                  className="knock-react-input"
                  placeholder="Another included utility"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'featuredPets',
                    'name',
                    'newFeaturedPetsItem',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">Utilities</h2>
          <div className="property-info-edit-field-container">
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label">Estimated Cost</div>
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.utilitiesEstimate}
                  name="utilitiesEstimate"
                  onBlur={this.inputChanged('utilitiesEstimate', true).bind(this)}
                  onChange={this.inputChanged('utilitiesEstimate').bind(this)}
                  className="knock-react-input"
                  placeholder="e.g. $100"
                />
                <span className="input-append-text">USD / month</span>
              </div>
            </div>
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Included</div>
              <div className="property-info-edit-field-input">
                {this.state.utilitiesIncluded.map((utility, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`utility-${key}`}>
                      <input
                        type="checkbox"
                        checked={utility.included}
                        onChange={this.checkboxUpdated(
                          'utilitiesIncluded',
                          key,
                          'name',
                          'included',
                          this.UTILITY_TYPES
                        )}
                      />
                      <span>
                        {utility.name}
                        {!_.includes(this.UTILITY_TYPES, utility.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'utilitiesIncluded', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newUtilityIncluded}
                  name="newUtilityIncluded"
                  onChange={this.inputChanged('newUtilityIncluded').bind(this)}
                  className="knock-react-input"
                  placeholder="Another included utility"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'utilitiesIncluded',
                    'name',
                    'newUtilityIncluded',
                    'included'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">Parking</h2>
          <div className="property-info-edit-field-container">
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label">Parking Notes</div>
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.parkingNotes}
                  name="parkingNotes"
                  onBlur={this.inputChanged('parkingNotes', true).bind(this)}
                  onChange={this.inputChanged('parkingNotes').bind(this)}
                  className="knock-react-input"
                  placeholder="Monthly cost, etc."
                />
              </div>
            </div>
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.parkingTypes.map((parking, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`parking-${key}`}>
                      <input
                        type="checkbox"
                        checked={parking.available}
                        onChange={this.checkboxUpdated(
                          'parkingTypes',
                          key,
                          'name',
                          'available',
                          this.PARKING_TYPES
                        )}
                      />
                      <span>
                        {parking.name}
                        {!_.includes(this.PARKING_TYPES, parking.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'parkingTypes', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newParkingType}
                  name="newParkingType"
                  onChange={this.inputChanged('newParkingType').bind(this)}
                  className="knock-react-input"
                  placeholder="Another parking type"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'parkingTypes',
                    'name',
                    'newParkingType',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">Furnishing</h2>
          <div className="property-info-edit-field-container">
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.furnishing.map((furnishingType, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`furnishing-type-${key}`}>
                      <input
                        type="checkbox"
                        checked={furnishingType.available}
                        onChange={this.checkboxUpdated(
                          'furnishing',
                          key,
                          'name',
                          'available',
                          this.FURNISH_TYPES
                        )}
                      />
                      <span>
                        {furnishingType.name}
                        {!_.includes(this.FURNISH_TYPES, furnishingType.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'furnishing', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newFurnishingType}
                  name="newFurnishingType"
                  onChange={this.inputChanged('newFurnishingType').bind(this)}
                  className="knock-react-input"
                  placeholder="Another furnishing type"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'furnishing',
                    'name',
                    'newFurnishingType',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">Laundry</h2>
          <div className="property-info-edit-field-container">
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label">Laundry Notes</div>
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.laundryNotes}
                  name="laundryNotes"
                  onBlur={this.inputChanged('laundryNotes', true).bind(this)}
                  onChange={this.inputChanged('laundryNotes').bind(this)}
                  className="knock-react-input"
                />
              </div>
            </div>
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.laundryTypes.map((laundry, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`laundry-${key}`}>
                      <input
                        type="checkbox"
                        checked={laundry.available}
                        onChange={this.checkboxUpdated(
                          'laundryTypes',
                          key,
                          'name',
                          'available',
                          this.LAUNDRY_TYPES
                        )}
                      />
                      <span>
                        {laundry.name}
                        {!_.includes(this.LAUNDRY_TYPES, laundry.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'laundryTypes', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newLaundryType}
                  name="newLaundryType"
                  onChange={this.inputChanged('newLaundryType').bind(this)}
                  className="knock-react-input"
                  placeholder="Another laundry type"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'laundryTypes',
                    'name',
                    'newLaundryType',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">Appliances</h2>
          <div className="property-info-edit-field-container">
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.appliances.map((appliance, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`appliance-${key}`}>
                      <input
                        type="checkbox"
                        checked={appliance.available}
                        onChange={this.checkboxUpdated(
                          'appliances',
                          key,
                          'name',
                          'available',
                          this.APPLIANCE_TYPES
                        )}
                      />
                      <span>
                        {appliance.name}
                        {!_.includes(this.APPLIANCE_TYPES, appliance.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'appliances', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newApplianceType}
                  name="newApplianceType"
                  onChange={this.inputChanged('newApplianceType').bind(this)}
                  className="knock-react-input"
                  placeholder="Another appliance type"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'appliances',
                    'name',
                    'newApplianceType',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">Heating / Cooling</h2>
          <div className="property-info-edit-field-container">
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.heatingAndCooling.map((heatingAndCoolingType, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`heat-cool-${key}`}>
                      <input
                        type="checkbox"
                        checked={heatingAndCoolingType.available}
                        onChange={this.checkboxUpdated(
                          'heatingAndCooling',
                          key,
                          'name',
                          'available',
                          this.VENTILATION_TYPES
                        )}
                      />
                      <span>
                        {heatingAndCoolingType.name}
                        {!_.includes(this.VENTILATION_TYPES, heatingAndCoolingType.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'heatingAndCooling', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newVentilationType}
                  name="newVentilationType"
                  onChange={this.inputChanged('newVentilationType').bind(this)}
                  className="knock-react-input"
                  placeholder="Another ventilation type"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'heatingAndCooling',
                    'name',
                    'newVentilationType',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">Views</h2>
          <div className="property-info-edit-field-container">
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.views.map((view, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`view-${key}`}>
                      <input
                        type="checkbox"
                        checked={view.available}
                        onChange={this.checkboxUpdated(
                          'views',
                          key,
                          'name',
                          'available',
                          this.VIEW_TYPES
                        )}
                      />
                      <span>
                        {view.name}
                        {!_.includes(this.VIEW_TYPES, view.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'views', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newViewType}
                  name="newViewType"
                  onChange={this.inputChanged('newViewType').bind(this)}
                  className="knock-react-input"
                  placeholder="Another view type"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'views',
                    'name',
                    'newViewType',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">Exterior</h2>
          <div className="property-info-edit-field-container">
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.exterior.map((exteriorType, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`exterior-type-${key}`}>
                      <input
                        type="checkbox"
                        checked={exteriorType.available}
                        onChange={this.checkboxUpdated(
                          'exterior',
                          key,
                          'name',
                          'available',
                          this.EXTERIOR_TYPES
                        )}
                      />
                      <span>
                        {exteriorType.name}
                        {!_.includes(this.EXTERIOR_TYPES, exteriorType.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'exterior', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newExteriorType}
                  name="newExteriorType"
                  onChange={this.inputChanged('newExteriorType').bind(this)}
                  className="knock-react-input"
                  placeholder="Another exterior type"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'exterior',
                    'name',
                    'newExteriorType',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">Wiring</h2>
          <div className="property-info-edit-field-container">
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.wiring.map((wiringType, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`wiring-type-${key}`}>
                      <input
                        type="checkbox"
                        checked={wiringType.available}
                        onChange={this.checkboxUpdated(
                          'wiring',
                          key,
                          'name',
                          'available',
                          this.WIRING_TYPES
                        )}
                      />
                      <span>
                        {wiringType.name}
                        {!_.includes(this.WIRING_TYPES, wiringType.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'wiring', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newWiringType}
                  name="newWiringType"
                  onChange={this.inputChanged('newWiringType').bind(this)}
                  className="knock-react-input"
                  placeholder="Another wiring type"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'wiring',
                    'name',
                    'newWiringType',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">Security / Access</h2>
          <div className="property-info-edit-field-container">
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.securityAndAccess.map((securityType, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`security-type-${key}`}>
                      <input
                        type="checkbox"
                        checked={securityType.available}
                        onChange={this.checkboxUpdated(
                          'securityAndAccess',
                          key,
                          'name',
                          'available',
                          this.SECURITY_TYPES
                        )}
                      />
                      <span>
                        {securityType.name}
                        {!_.includes(this.SECURITY_TYPES, securityType.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(this, 'securityAndAccess', key)}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newSecurityType}
                  name="newSecurityType"
                  onChange={this.inputChanged('newSecurityType').bind(this)}
                  className="knock-react-input"
                  placeholder="Another security type"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'securityAndAccess',
                    'name',
                    'newSecurityType',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">Facilities / Recreation</h2>
          <div className="property-info-edit-field-container">
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.facilitiesAndRecreation.map((facility, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`facility-${key}`}>
                      <input
                        type="checkbox"
                        checked={facility.available}
                        onChange={this.checkboxUpdated(
                          'facilitiesAndRecreation',
                          key,
                          'name',
                          'available',
                          this.RECREATION_TYPES
                        )}
                      />
                      <span>
                        {facility.name}
                        {!_.includes(this.RECREATION_TYPES, facility.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(
                              this,
                              'facilitiesAndRecreation',
                              key
                            )}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newRecreationType}
                  name="newRecreationType"
                  onChange={this.inputChanged('newRecreationType').bind(this)}
                  className="knock-react-input"
                  placeholder="Another facility type"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'facilitiesAndRecreation',
                    'name',
                    'newRecreationType',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="full-width">
          <h2 className="side-margin-lg">Additional Amenities</h2>
          <div className="property-info-edit-field-container">
            <div className="knock-react-flex half-width readable-width">
              <div className="property-info-edit-field-label">Available</div>
              <div className="property-info-edit-field-input">
                {this.state.additionalFeatures.map((additionalType, key) => {
                  return (
                    <span className="inline-block side-margin-lg" key={`additional-type-${key}`}>
                      <input
                        type="checkbox"
                        checked={additionalType.available}
                        onChange={this.checkboxUpdated(
                          'additionalFeatures',
                          key,
                          'name',
                          'available',
                          this.ADDITIONAL_TYPES
                        )}
                      />
                      <span>
                        {additionalType.name}
                        {!_.includes(this.ADDITIONAL_TYPES, additionalType.name) ? (
                          <button
                            className="knock-react-button btn-danger btn-checkbox-inline"
                            onClick={this.removeCustomCheckbox.bind(
                              this,
                              'additionalFeatures',
                              key
                            )}
                          >
                            <strong>×</strong>
                          </button>
                        ) : null}
                      </span>
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="knock-react-flex">
              <div className="property-info-edit-field-label" />
              <div className="property-info-edit-field-input">
                <input
                  value={this.state.newAdditionalType}
                  name="newAdditionalType"
                  onChange={this.inputChanged('newAdditionalType').bind(this)}
                  className="knock-react-input"
                  placeholder="Another additional amenity"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.addCustomCheckbox.bind(
                    this,
                    'additionalFeatures',
                    'name',
                    'newAdditionalType',
                    'available'
                  )}
                >
                  <i className="fa fa-plus" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  generateNewBooleanMapping(nameList, nameString, booleanString) {
    const mapping = [];

    _.forEach(nameList, (nameValue) => {
      const obj = {};
      obj[nameString] = nameValue;
      obj[booleanString] = false;
      mapping.push(obj);
    });

    return mapping;
  }

  addCustomCheckbox(type, nameKey, nameValueFromState, boolKey) {
    const thisAttribute = this.state[type];
    const nameValue = this.state[nameValueFromState];

    if (_.isEmpty(nameValue)) {
      return;
    }

    const newCheckItem = {};
    newCheckItem[nameKey] = nameValue;
    newCheckItem[boolKey] = true;
    thisAttribute.push(newCheckItem);

    this.setState({
      [nameValueFromState]: '',
      [type]: thisAttribute,
    });

    this.submit();
  }

  removeCustomCheckbox(type, index) {
    const thisAttribute = this.state[type];
    thisAttribute.splice(index, 1);
    this.setState({
      [type]: thisAttribute,
    });
    this.submit();
  }

  checkboxUpdated(type, index, nameKey, boolKey) {
    return event => {
      const thisAttribute = this.state[type];
      thisAttribute[index][boolKey] = event.target.checked;

      this.setState({
        [type]: thisAttribute,
      });

      this.submit();
    };
  }

  inputChanged(field, isSubmit) {
    return event => {
      if (event.target.validity.valid) {
        this.setState({
          [field]: event.target.value,
        });

        if (isSubmit) {
          this.submit();
        }
      }
    };
  }

  submit() {
    setTimeout(() => {
      const updatedCommunity = this.props.property.data;

      updatedCommunity.utilities.estimatedCost = this.state.utilitiesEstimate;
      updatedCommunity.utilities.types = this.state.utilitiesIncluded;

      updatedCommunity.parking.notes = this.state.parkingNotes;
      updatedCommunity.parking.types = this.state.parkingTypes;

      updatedCommunity.laundry.notes = this.state.laundryNotes;
      updatedCommunity.laundry.types = this.state.laundryTypes;

      updatedCommunity.furnishing = this.state.furnishing;
      updatedCommunity.appliances = this.state.appliances;
      updatedCommunity.views = this.state.views;

      updatedCommunity.amenities.exterior = this.state.exterior;
      updatedCommunity.amenities.heatingAndCooling = this.state.heatingAndCooling;
      updatedCommunity.amenities.wiring = this.state.wiring;
      updatedCommunity.amenities.additional = this.state.additionalFeatures;
      updatedCommunity.amenities.security = this.state.securityAndAccess;
      updatedCommunity.amenities.recreation = this.state.facilitiesAndRecreation;

      updatedCommunity.featured_amenities.laundry.types = this.state.featuredLaundry;
      updatedCommunity.featured_amenities.parking.types = this.state.featuredParking;
      updatedCommunity.featured_amenities.pool.types = this.state.featuredPool;
      updatedCommunity.featured_amenities.gym.types = this.state.featuredGym;
      updatedCommunity.featured_amenities.pets.types = this.state.featuredPets;

      const updatedProperty = this.props.property;
      updatedProperty.data = updatedCommunity;

      this.props.onChange(updatedProperty);
    }, 100);
  }
}

export default PropertyInfoEditAmenities;
