import { useCallback, useEffect, useRef, useState } from "react";
import { Toaster } from "@knockrentals/knock-react";

import PropertiesAPI from "../../Properties/PropertiesAPI";
import { 
  getCallIntelData, 
  getCompanyId, 
  updatePropertiesCallIntelData, 
  updatePropertyCallIntelData } from "./CallIntelApi/callIntelApi";
import { NotificationService } from "../../../Components/Notifications";

export const useCallIntel = () => {
  const [editDrawerOpened, setEditDrawerOpened] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingFeatureSwitch, setIsUpdatingFeatureSwitch] = useState(false);
  const [propertiesCallIntelData, setPropertiesCallIntelData] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState({});
  const propertiesMap = useRef(null);

  const fetchProperties = async () => {
    if (propertiesMap.current) return propertiesMap.current;

    const properties = await PropertiesAPI.getCommunityInfo();

    const propertiesMapped = properties.properties.reduce((acc, propertyInfo) => {
      acc[propertyInfo.id] = { ...propertyInfo };
      return acc;
    }, {});

    propertiesMap.current = propertiesMapped;

    return propertiesMapped;
  };

  const fetchCallIntelData = async () => {
    const companyId = getCompanyId();
    
    const callIntelData = await getCallIntelData(companyId);

    const callIntelDataMapped = callIntelData.reduce((acc, propertyInfo) => {
      acc[propertyInfo.property_id] = { ...propertyInfo };
      return acc;
    }, {});

    return callIntelDataMapped;
  };

  
  const getPropertiesCallIntelData = useCallback(async () => {
    try {
      setIsLoading(true);

      const [propertiesInfo, propertiesData] = await Promise.all([fetchProperties(), fetchCallIntelData()]);
      const callIntelProperyIds = Object.keys(propertiesData);
      const selectedProperties = {};

      const propertiesCallIntelData = Object.keys(propertiesInfo).map((propertyId) => {
        selectedProperties[propertyId] = {
          propertyName: propertiesInfo[propertyId].data.location.name,
          isSelected: false,
        };

        const propertiesSynced = callIntelProperyIds.includes(propertyId)

        return {
          propertyName: propertiesInfo[propertyId].data.location.name,
          propertyId: propertiesInfo[propertyId].id,
          is_transcribed: propertiesSynced ? propertiesData[propertyId].is_transcribed : false,
          is_scored: propertiesSynced ? propertiesData[propertyId].is_scored : false,
          is_standalone: propertiesSynced ? propertiesData[propertyId].is_standalone : false,
        };
      }).sort((lValue, rValue) => {
        return lValue.propertyName >= rValue.propertyName ? 1 : -1;
      });

      setPropertiesCallIntelData(propertiesCallIntelData);
      setSelectedProperties(selectedProperties);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onUpdateTableValue = async ({ fieldName, fieldValue, rowData }) => {
    try {
      setIsUpdatingFeatureSwitch(true);
      const companyId = getCompanyId();
      const payload = {
        company_id: companyId,
        [fieldName]: fieldValue
      };
      await updatePropertyCallIntelData(payload, rowData.propertyId);

      const updatedPropertiesCallIntelData = propertiesCallIntelData.map(propertyData => {
        if (rowData.propertyId === propertyData.propertyId) {
          return {
            ...propertyData,
            [fieldName]: fieldValue
          };
        }
        return propertyData;
      });
  
      setPropertiesCallIntelData(updatedPropertiesCallIntelData);

      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsUpdatingFeatureSwitch(false);
    }
  };

  useEffect(() => {
    getPropertiesCallIntelData();
  }, [getPropertiesCallIntelData]);

  const handleUpdateProperties = async (propertiesList, features) => {
    try {
      setIsLoading(true);

      const companyId = getCompanyId();
      const payload = {
        company_id: companyId,
        property_ids: propertiesList.map(property => property.propertyId),
        ...(Object.keys(features).reduce((acc, featureKey) => {
          if (features[featureKey] !== undefined) {
            acc[featureKey] = features[featureKey];
          }

          return acc;
        }, {}))
      };

      await updatePropertiesCallIntelData(payload);

      await getPropertiesCallIntelData();

      setEditDrawerOpened(false);
      
      NotificationService.notify(`Preferences saved at ${propertiesList.length} ${propertiesList.length === 1 ? 'property' : 'properties'}`);
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    editDrawerOpened,
    errorMessage,
    handleUpdateProperties,
    isLoading,
    isUpdatingFeatureSwitch,
    onUpdateTableValue,
    propertiesCallIntelData,
    selectedProperties,
    setEditDrawerOpened,
    setErrorMessage,
    setSelectedProperties,
  };
};