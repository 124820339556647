import React, { useContext, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  DraggableListCell,
  DraggableListRow,
  DraggableListContext,
} from './index';

const validateData = async (validator, data) => {
  if (!validator) {
    return true;
  }

  await validator.validate(data);

  return true;
};

/**
 * A draggable row of data that handles an arbitrary number of editable data fields. Manages draft data before it is submitted to parent.
 * Accesses `DraggableListContext` for state, handlers, and validators
 *
 * @param {number} props.index The row's index within the array of current displayed data
 * @param {boolean} props.initInEditMode An optional field that will force a row to render in edit mode (used for added but incomplete items)
 * @param {object} props.row The data to be displayed. Only those values with keys in the `fields` array will be displayed and interactable.
 * @param {Array<strings>} props.fields The keys for the values in `row` which are displayed and interactable.
 */
export const DraggableListRowContainer = ({
  index,
  initInEditMode,
  row,
  fields,
}) => {
  const draggableListContext = useContext(DraggableListContext);
  const {
    isDragging,
    isEditOccuring,
    handleCancel,
    handleDelete,
    handleEdit,
    handleSave,
    raiseErrorMessage,
    validator,
  } = draggableListContext;

  const [editingState, setEditingState] = useState({
    editedRow: { ...row },
    isEditing: initInEditMode || false,
  });
  const { isEditing, editedRow } = editingState;

  // Internal Handlers
  const handleInput = (newInput, fieldToUpdate) => {
    const updatedEditedRow = { ...editedRow };

    updatedEditedRow[fieldToUpdate] = newInput;

    setEditingState({
      ...editingState,
      editedRow: updatedEditedRow,
    });
  };

  // Button Clicks - Call Context Handlers
  const onClickCancelButton = () => {
    setEditingState({ editedRow: row, isEditing: false });

    handleCancel(index);
  };

  const onClickDeleteButton = () => {
    handleDelete(index);
  };

  const onClickEditButton = () => {
    setEditingState({
      ...editingState,
      isEditing: true,
    });

    handleEdit(index);
  };

  const onClickSaveButton = async () => {
    try {
      await validateData(validator, editedRow);
    } catch (e) {
      raiseErrorMessage(e.message);
      return;
    }

    setEditingState({
      ...editingState,
      isEditing: false,
    });

    handleSave(index, editedRow);
  };

  // Styling Helpers
  const checkIsDragDisabed = () => isEditing || isEditOccuring;

  const areButtonsDeactivated = () => isEditOccuring && !isEditing;

  const getRowClassName = () => {
    if (isEditing) {
      return 'draggable-list-row-editing';
    }
    if (isEditOccuring) {
      return 'draggable-list-row-locked';
    }
    return 'draggable-list-row';
  };

  return (
    <Draggable
      draggableId={row.id}
      index={index}
      key={row.id}
      isDragDisabled={checkIsDragDisabed()}
    >
      {(provided) => (
        <DraggableListRow
          areButtonsDeactivated={areButtonsDeactivated()}
          isEditing={isEditing}
          rowClassName={getRowClassName()}
          onClickCancelButton={onClickCancelButton}
          onClickDeleteButton={onClickDeleteButton}
          onClickEditButton={onClickEditButton}
          onClickSaveButton={onClickSaveButton}
          provided={provided}
        >
          {fields.map((field) => (
            <DraggableListCell
              key={field}
              handleChange={handleInput}
              isEditing={isEditing}
              isDragOccurring={isDragging}
              value={row[field]}
              field={field}
              editedValue={editedRow[field]}
            />
          ))}
        </DraggableListRow>
      )}
    </Draggable>
  );
};
