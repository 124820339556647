import React from 'react';
import PropTypes from 'prop-types';

import { Chip } from '@material-ui/core';
import { makeStyles, NamedColors } from '@knockrentals/knock-shared-web';

const ARCHIVE = 'archived';
const DRAFT = 'draft';
const LIVE = 'live';

const useStyles = makeStyles(() => ({
  [ARCHIVE]: {
    backgroundColor: NamedColors.slate[100],
    textTransform: 'capitalize',
  },
  [DRAFT]: {
    backgroundColor: NamedColors.banana[100],
    textTransform: 'capitalize',
  },
  [LIVE]: {
    backgroundColor: NamedColors.grass[100],
    textTransform: 'capitalize',
  },
}));

const StatusChip = ({ status }) => {
  const classes = useStyles();

  const statusClass =
    (status === LIVE && classes[LIVE]) ||
    (status === DRAFT && classes[DRAFT]) ||
    (status === ARCHIVE && classes[ARCHIVE]);

  return <Chip label={status} className={statusClass || ''} />;
};

StatusChip.propTypes = {
  status: PropTypes.string,
};

export default StatusChip;
