import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@knockrentals/knock-shared-web';

const TITLE = 'An unexpected error has occurred';

const ErrorDialog = ({ closeDialog, errorMessage, isOpen }) => {
  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>{TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText>{errorMessage}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={closeDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ErrorDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
};

export default ErrorDialog;
