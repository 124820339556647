import React, { useState } from "react";
import PropTypes from 'prop-types';
import PropertiesAPI from "../Properties/PropertiesAPI";
import { Toaster } from '@knockrentals/knock-react';
import { Tooltip } from '@knockrentals/knock-shared-web';
import './_selfieScanSettings.scss';

const SelfieScanSettings = ({ groupProperties }) => {
  const [properties, setProperties] = useState(groupProperties);

  const handleEnableAllClicked = (e) => {
    const newValue = e.target.checked;
    const items = [...properties];
    const changedItems = [];

    items.forEach((item) => {
      if (item.preferences.enable_selfie_scan !== newValue) {
        item.preferences.enable_selfie_scan = newValue;
        changedItems.push(item);
      }
    });

    const allDoneCallback = () => {
      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      setProperties(items);
    };

    const asyncPutRequest = async (item, callback) => {
      PropertiesAPI
        .updateProperty(item.data.property_id, item)
        .then(() => {
          callback();
        }).catch(e => {
          Toaster.showToast('Error saving property info.', 2000, Toaster.ToastClasses.error);
          console.log(e);
        });
    };

    let numItemsProcessed = 0;

    changedItems.forEach((item) => {
      asyncPutRequest(item, () => {
        numItemsProcessed += 1;
        if (numItemsProcessed === changedItems.length) {
          allDoneCallback();
        }
      });
    });
  };

  const handleEnableSingleClicked = (e) => {
    const targetId = parseInt(e.target.name, 10);

    const items = [...properties];

    const targetProperty = items.find(prop => prop.id === targetId);
    targetProperty.preferences.enable_selfie_scan = e.target.checked;

    PropertiesAPI.updateProperty(targetProperty.data.property_id, targetProperty).then(() => {
      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      setProperties(items);
    });
  };

  const allEnabled = properties.every((property) => property.preferences.enable_selfie_scan === true);
  const noProperties = properties.length === 0;
  const moreThanOneProperty = properties.length > 1;

  const noPropertiesText = 'Leasing team does not have any Properties.';
  const tooltipText = 'Turning this field on enables SelfieScan functionality such as being able to send a Verify ID link from the LMA.';

  const renderProperties = () => (
    <div className='selfie-scan-settings-table-container'>
      <table className="wire-frame-table selfie-scan-settings-table">
        {moreThanOneProperty &&
          <thead>
            <tr>
              <th><b>Enable All</b></th>
              <th className="text-center">
                <input
                  type="checkbox"
                  checked={allEnabled}
                  onChange={handleEnableAllClicked}
                />
              </th>
            </tr>
          </thead>
        }
        <tbody>
          {properties.map((property) =>
          (<tr key={property.data.property_id}>
            <td>
              {property.data.location.name}
            </td>
            <td className="text-center">
              <input
                type="checkbox"
                checked={property.preferences.enable_selfie_scan}
                onChange={handleEnableSingleClicked}
                name={property.id}
              />
            </td>
          </tr>)
          )}
        </tbody>
      </table>
      <Tooltip title={tooltipText}>
        <i className="fa-regular fa-question-circle tooltip"></i>
      </Tooltip>
    </div>
  );

  return (
    <div className='selfie-scan-settings-container'>
      <h3>SelfieScan Settings</h3>
      {noProperties ? <div>{noPropertiesText}</div> : renderProperties()}
    </div>
  );
};

SelfieScanSettings.propTypes = {
  groupProperties: PropTypes.array
};

export default SelfieScanSettings;
