import React, { Component } from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter } from '@knockrentals/knock-react';
import { Formik } from 'formik';
import { trimValue } from '../../../Utils';

import './_addIntegrationForm.scss';

class AddAppfolioIntegration extends Component {
  state = {
    appfolio_community_id: '',
    username: '',
    password: '',
  };

  render() {
    return (
      <Modal className="add-integration-form">
        <ModalHeader>
          <h3>Add Appfolio Integration</h3>
        </ModalHeader>
        <Formik
          initialValues={{
            username: '',
            password: '',
            appfolio_community_id: '',
          }}
          onSubmit={this.submit.bind(this)}
          validate={this.validate}
        >
          {props => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <ModalContent>
                <div className="knock-react-flex">
                  <div>
                    <strong>Property ID: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      type="text"
                      value={props.values.appfolio_community_id}
                      name="appfolio_community_id"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.appfolio_community_id && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.appfolio_community_id}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Username: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.username}
                      name="username"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                      type="text"
                    />
                    {!!props.errors.username && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.username}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Password: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.password}
                      name="password"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.password && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.password}
                      </span>
                    )}
                  </div>
                </div>
              </ModalContent>
              <ModalFooter>
                <button className="knock-react-button" type="submit">
                  Save
                </button>
                <button className="knock-react-button" onClick={this.cancel.bind(this)}>
                  Cancel
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  submit(values) {
    const {
      username,
      password,
      appfolio_community_id,
    } = values;

    const data = {
      username,
      password,
      appfolio_community_id,
      vendor: 'appfolio',
    };

    this.props.onAddIntegration({ ...data });
  }

  cancel() {
    this.props.onCancelAddIntegration();
  }

  validate(values) {
    const errors = {};

    if (!values.username) {
      errors.username = 'Required';
    }

    if (!values.password) {
      errors.password = 'Required';
    }

    if (!values.appfolio_community_id) {
      errors.appfolio_community_id = 'Required';
    }

    return errors;
  }
}

export default AddAppfolioIntegration;
