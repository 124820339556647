import React from 'react';
import DataTable from 'material-table';
import { Paper, LoadingOverlay } from '@knockrentals/knock-shared-web';
import { getDefaultTableOptions } from '../../../Services/SharedService';
import { getColumnHeaderConfig, getColumns } from './table-columns';
import { useBenchmarkingAttributes } from './useBenchmarkingAttributes';

const BenchmarkingAttributes = () => {
  const { isLoading, properties, saveData } = useBenchmarkingAttributes();
  const tableColumns = getColumns({ saveData }).map(getColumnHeaderConfig);

  return (
    <React.Fragment>
      <LoadingOverlay
        open={isLoading}
        style={{ position: 'absolute', opacity: '0.5' }}
      />

      <DataTable
        columns={tableColumns}
        components={{
          Container: (props) => <Paper {...props} elevation={1} />,
        }}
        data={properties}
        options={{ ...getDefaultTableOptions(), tableLayout: 'fixed' }}
        style={{ width: 'min-content' }}
      />
    </React.Fragment>
  );
};

export default BenchmarkingAttributes;
