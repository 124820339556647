import React from 'react';
import { Button } from '@knockrentals/knock-shared-web';
import PropTypes from 'prop-types';

import { EditIcon } from '../../../Components/Icons';

import './_CallIntelPage.scss';

export const EditButton = ({ count, onClick }) => {
  return (
    <div className='edit-button-wrapper'>
      <Button
        onClick={onClick}
        startIcon={<div className='edit-icon-wrapper'><EditIcon /></div>}            
        variant="text"
        disabled={count === 0}
        className='edit-button'
      >
        Edit {count > 0 && ` (${count})`}
      </Button>
    </div>
  );
};

EditButton.propTypes = {
  count: PropTypes.number,
  onClick: PropTypes.func,
};