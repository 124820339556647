import { useEffect, useState } from 'react';
import { brandContentApi } from './brand-content-api';
import { ERROR_MESSAGE } from './brand-content-api/brand-content-api';

export const getPropertySelectionMap = (propertiesList) => {
  const isSelected = false;
  return propertiesList.reduce((propertiesMap, property) => {
    const { name, id } = property;
    propertiesMap[id] = {
      propertyName: name,
      propertyId: id,
      isSelected,
    };
    return propertiesMap;
  }, {});
};

export const getSelectedCount = (propertySelectionMap) =>
  Object.values(propertySelectionMap).filter((property) => property.isSelected)
    .length;

const usePropertySelection = ({
  getCurrentPageProperties,
  setErrorMessage,
}) => {
  const [isFetchingPropertiesList, setIsFetchingPropertiesList] =
    useState(true);
  const [propertySelectionMap, setPropertySelectionMap] = useState({});

  useEffect(() => {
    const fetchPropertiesList = async () => {
      setIsFetchingPropertiesList(true);
      try {
        const propertyList = await brandContentApi.getPropertyList();

        setPropertySelectionMap(getPropertySelectionMap(propertyList));

        setIsFetchingPropertiesList(false);
      } catch (_e) {
        setIsFetchingPropertiesList(false);
        setErrorMessage(ERROR_MESSAGE);
      }
    };

    fetchPropertiesList();
  }, [setErrorMessage]);

  const getSelectedProperties = () =>
    Object.values(propertySelectionMap).filter(
      (property) => property.isSelected
    );

  const updatePropertySelection = (propertyId, isSelected) => {
    setPropertySelectionMap((prevState) => ({
      ...prevState,
      [propertyId]: {
        ...prevState[propertyId],
        isSelected,
      },
    }));
  };

  const updateAllPropertiesSelections = (isSelected) => {
    const updatedSelections = Object.entries(propertySelectionMap).reduce(
      (selectionMap, [propertyId, property]) => {
        selectionMap[propertyId] = {
          ...property,
          isSelected,
        };
        return selectionMap;
      },
      {}
    );
    setPropertySelectionMap(updatedSelections);
  };

  const selectAllOnPage = () => {
    const updatedPropertySelections = { ...propertySelectionMap };
    const currentPageProperties = getCurrentPageProperties();
    currentPageProperties.forEach((property) => {
      updatedPropertySelections[property.propertyId].isSelected = true;
    });
    setPropertySelectionMap(updatedPropertySelections);
  };

  return {
    getSelectedProperties,
    isFetchingPropertiesList,
    propertySelectionMap,
    selectedPropertyCount: getSelectedCount(propertySelectionMap),
    selectAllOnPage,
    updateAllPropertiesSelections,
    updatePropertySelection,
  };
};

export default usePropertySelection;
