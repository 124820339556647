import React, { useState } from 'react';

import { Box, Divider, makeStyles, Tab, TabPanel, Tabs, ThemeProvider } from '@knockrentals/knock-shared-web';
import AttributesPage from './AttributesPage';
import BenchmarkingAttributes from './Benchmarking/BenchmarkingAttributes';

const useStyles = makeStyles({
  tabPanel: {
    marginTop: '10px'
  },

  divider: {
    marginTop: '0 !important',
    position: 'relative',
    top: '-1px',
  },
});

const Attributes = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(1);

  const handleTabsChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <ThemeProvider>
      <h1>Attributes</h1>

      <Box>
        <Tabs value={currentTab} onChange={handleTabsChange} textColor="primary" indicatorColor="primary">
          <Tab label="Benchmarking" />
          <Tab label="Custom" />
        </Tabs>
        <Divider className={classes.divider} />
      </Box>

      <TabPanel id="BenchmarkingTabPanel" value={currentTab} index={0} className={classes.tabPanel}>
        <BenchmarkingAttributes />
      </TabPanel>
      <TabPanel id="CustomTabPanel" value={currentTab} index={1} className={classes.tabPanel}>
        <AttributesPage />
      </TabPanel>
    </ThemeProvider>
  );
};

export default Attributes;