import { apiRemote } from '../../../../Services/RemoteService';
import { TRIGGER_TYPE_NAMES, TRIGGER_TYPE_NUMBERS } from '../constants';
import {
  getIsDefaultQueryString,
  getTransformedPayload,
  normalizeCompanyTemplatesByType,
  normalizeProperties,
  normalizeTemplateData,
} from './utils';

export const getCompanyTemplates = async () => {
  try {
    const response = await apiRemote.get('admin/company-templates');

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const { templates } = await response.json();
    return normalizeCompanyTemplatesByType(templates);
  } catch (error) {
    throw new Error('We are unable to manage company templates at this time.');
  }
};

export const getCompanyTemplateById = async (
  companyTemplateId,
  isDefaultTemplate
) => {
  const queryString = getIsDefaultQueryString(isDefaultTemplate);

  try {
    const response = await apiRemote.get(
      `admin/company-templates/${companyTemplateId}${queryString}`
    );

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const { template_data } = await response.json();
    return normalizeTemplateData(template_data);
  } catch (error) {
    throw new Error(
      'We are unable to edit the requested company template at this time.'
    );
  }
};

export const getPropertiesForTemplateType = async (
  templateType,
  isDefaultTemplate
) => {
  try {
    const queryString = getIsDefaultQueryString(isDefaultTemplate);

    const response = await apiRemote.get(
      `admin/company-templates/trigger/${TRIGGER_TYPE_NAMES[templateType]}${queryString}`
    );

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const { properties = [] } = await response.json();
    return normalizeProperties(properties);
  } catch (_e) {
    throw new Error(
      'We are unable assign properties to the company template at this time.'
    );
  }
};

export const createCompanyTemplate = async (payload) => {
  const transformedPayload = {
    all_properties_assignable: payload.isEveryPropertyAssignable,
    assignable_properties: payload.assignablePropertyIds,
    message: payload.message,
    title: payload.templateName,
    property_ids: payload.propertyIds,
    trigger_type: TRIGGER_TYPE_NUMBERS[payload.templateType],
  };

  if (payload.subject) {
    transformedPayload.subject = payload.subject;
  }

  try {
    const response = await apiRemote.post(
      'admin/company-templates',
      transformedPayload
    );

    if (!response.ok || response.status !== 201) {
      throw new Error();
    }
    const responseData = await response.json();
    return responseData.id;
  } catch (error) {
    throw new Error('We are unable to save the company template at this time.');
  }
};

export const updateCompanyTemplate = async (id, payload) => {
  const transformedPayload = getTransformedPayload(payload);
  try {
    const response = await apiRemote.patch(
      `admin/company-templates/${id}`,
      transformedPayload
    );

    if (!response.ok || response.status !== 204) {
      throw new Error();
    }
  } catch (error) {
    throw new Error(
      'We are unable to update the company template at this time.'
    );
  }
};

export const deleteCompanyTemplate = async (companyTemplateId) => {
  try {
    const response = await apiRemote.delete(
      `admin/company-templates/${companyTemplateId}`
    );

    if (!response.ok || response.status !== 204) {
      throw new Error();
    }
  } catch (error) {
    throw new Error(
      'We are unable to remove the requested company template at this time.'
    );
  }
};
