import React, { Component } from 'react';
import {
  PageContainer,
  AuthenticationService,
} from '@knockrentals/knock-react';
import Link from '@material-ui/core/Link';
import PropertyAttributes from './Properties/PropertyAttributes';
import SourceAttributes from './Sources/SourceAttributes';

import './_attributeInfo.scss';

const SUPPORT_ARTICLE_LINK =
  'https://kb.realpage.com/articles/Solution/Adding-Property-Attributes';

class AttributesPage extends Component {
  TAB_OPTIONS = ['Properties', 'Sources'];

  state = {
    isLoading: false,
    mode: this.TAB_OPTIONS[0],
  };

  render() {
    return (
      <PageContainer
        className="attributes-info-page-container"
        isLoading={this.state.isLoading}
      >
        <Link href={SUPPORT_ARTICLE_LINK} rel="noopener" target="_blank">
          View support article
        </Link>

        {AuthenticationService._internalMode ? (
          <div className="attributes-nav-tabs-menu">
            {this.TAB_OPTIONS.map((tabOption, i) => {
              return (
                <div
                  key={`tab-option-${i}`}
                  className={
                    'attributes-nav-tab ' +
                    (this.state.mode === tabOption ? 'active' : 'inactive')
                  }
                  onClick={this.changeMode.bind(this, tabOption)}
                >
                  {tabOption}
                </div>
              );
            })}
          </div>
        ) : null}

        {this.state.mode === 'Properties' ? <PropertyAttributes /> : null}

        {this.state.mode === 'Sources' ? <SourceAttributes /> : null}
      </PageContainer>
    );
  }

  changeMode(mode) {
    this.setState({ mode });
  }
}

export default AttributesPage;
