import React, { useState } from 'react';
import Button from '../../Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntegrationsAPI from '../../../IntegrationsAPI';
import { Toaster } from '@knockrentals/knock-react';
import PropTypes from 'prop-types';

const TableRow = ({ cells, rowIndex }) => {
  return (
    <tr>
      {
        cells.map((cell, index) => (
          <td
            key={`RemoveIntegrations-table-row-${rowIndex}-cell-${index}`}
            style={{
              textAlign: index !== 0 ? 'center' : 'inherit',
            }}
          >
            {cell}
          </td>
        ))
      }
    </tr>
  );
};


const RemoveButton = (props) => {
  const {
    onError,
    onSuccess,
    onRequestRemove,
  } = props;

  // Enum of all view states the remove button can have:
  const RemoveButtonViews = {
    DEFAULT: 'DEFAULT',
    CONFIRM: 'CONFIRM',
    REQUESTED: 'REQUESTED',
    SUCCESS: 'SUCCESS',
  };
  const [currentView, setCurrentView] = useState(RemoveButtonViews.DEFAULT);

  const handleError = (toastMessage, logMessage) => {
    onError(toastMessage, logMessage);
    setCurrentView(RemoveButtonViews.DEFAULT);
  };

  const handleSuccess = (toastMessage, logMessage) => {
    onSuccess(toastMessage, logMessage);
    setCurrentView(RemoveButtonViews.SUCCESS);
  };

  const handleRequestRemove = async () => {
    setCurrentView(RemoveButtonViews.REQUESTED);
    await onRequestRemove(handleSuccess, handleError);
  };

  const Wrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      {children}
    </div>
  );

  const DefaultView = (
    <Button
      className='danger'
      iconType='trash'
      onClick={() => setCurrentView(RemoveButtonViews.CONFIRM)}
    >
      Remove Integration
    </Button>
  );

  const ConfirmView = (
    <div>
      <span style={{ marginLeft: '5px' }} >
        Are you sure?
      </span>
      <Button
        className='danger'
        iconType='trash'
        onClick={handleRequestRemove}
        style={{
          marginRight: '-5px',
        }}
      >
        Remove
      </Button>
      <Button
        iconType='times'
        onClick={() => setCurrentView(RemoveButtonViews.DEFAULT)}
      >
        Cancel
      </Button>
    </div>
  );

  const RequestedView = (
    <Wrapper>
      <CircularProgress size={15} />
      <span style={{ marginLeft: '5px' }}>
        Removing...
      </span>
    </Wrapper>
  );

  const SuccessView = (
    <Wrapper>
      <i className='fa fa-check' style={{ color: '#5cb85c' }} />
      <span style={{ marginLeft: '10px' }}>
        Removed
      </span>
    </Wrapper>
  );

  switch (currentView) {
    case RemoveButtonViews.DEFAULT:
      return DefaultView;

    case RemoveButtonViews.CONFIRM:
      return ConfirmView;

    case RemoveButtonViews.REQUESTED:
      return RequestedView;

    case RemoveButtonViews.SUCCESS:
      return SuccessView;

    default:
      return DefaultView;
  }
};


const RemoveIntegrations = (props) => {
  const {
    propertyMapping,
    setReloadOnHide,
  } = props;

  const handleError = (toastMessage, logMessage) => {
    if (logMessage) {
      console.error(logMessage);
    }
    Toaster.showToast(toastMessage, 2000, Toaster.ToastClasses.error);
  };

  const handleSuccess = (toastMessage, logMessage) => {
    if (logMessage) {
      console.info(logMessage);
    }
    Toaster.showToast(toastMessage, 2000, Toaster.ToastClasses.success);
    setReloadOnHide(true);
  };

  const handleRequestRemove = (propertyId, credentialId) => async (onSuccess, onError) => {
    try {
      await IntegrationsAPI.removeFacebookIntegration(propertyId, credentialId);
      onSuccess('Successfully removed Facebook integration!');
    } catch (error) {
      onError('Error removing Facebook integration', error);
    }
  };

  const rows = propertyMapping.map((mapping, index) => {
    const {
      community_name: communityName,
      property_id: propertyId,
      credential_id: credentialId,
      facebook_page_id: fbPageId,
    } = mapping;

    return (
      <TableRow
        key={`RemoveIntegrations-table-row-${index}`}
        rowIndex={index}
        cells={[
          communityName,
          fbPageId,
          <RemoveButton
            onError={handleError}
            onSuccess={handleSuccess}
            onRequestRemove={handleRequestRemove(propertyId, credentialId)}
          />
        ]}
      />
    );
  });

  if (!rows || !rows.length) {
    return (
      <div className="row-null">
        <i className="fa fa-frown-o" />
        <span style={{ marginLeft: '10px' }}>
          No integrations available for this Facebook user
        </span>
      </div>
    );
  }


  return (
    <div>
      <table className="wire-frame-table">
        <thead>
          <tr>
            <th>
              Community Name
            </th>
            <th
              style={{
                textAlign: 'center',
              }}
            >
              Facebook Page ID
            </th>
            <th
              style={{
                textAlign: 'center',
              }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

RemoveIntegrations.propTypes = {
  setReloadOnHide: PropTypes.func,
  propertyMapping: PropTypes.arrayOf(
    PropTypes.shape({
      community_id: PropTypes.string,
      community_name: PropTypes.string,
      property_id: PropTypes.number,
      credential_id: PropTypes.number,
      facebook_page_id: PropTypes.string,
    })
  ),
};

export default RemoveIntegrations;
