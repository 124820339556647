import React, { useState, useEffect } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';

const KNOCKBOT_SUPPORT_ARTICLE =
  'https://kb.realpage.com/articles/Solution/Embedding-your-Knockbot-Contact-or-Scheduling-Form';
export const createScript = (config, embeddingOptions = null) => {
  const embeddingScript =
    embeddingOptions !== null
      ? `,\n\t\t{embedding: {page: '${embeddingOptions.selectedPage}', container: '${embeddingOptions.containerEl}', showChatbot: ${embeddingOptions.showChatbot}}}`
      : '';

  const script =
    `<script type="text/javascript" src="${config.host}/latest/doorway.min.js"></script>\n` +
    `<script text="text/javascript">\n` +
    `\twindow.knockDoorway.init('${config.token}', 'community', '${config.community_id}'${embeddingScript});\n` +
    `</script>`;

  return script;
};

const IntegrationInstructions = ({ snippetConfig }) => {
  const pageOptions = ['schedule', 'contact'];

  const [selectedPage, setSelectedPage] = useState(pageOptions[0]);
  const [containerEl, setContainerEl] = useState('exampleContainerId');
  const [showChatbot, setShowChatbot] = useState(true);

  const [script, setScript] = useState('');

  useEffect(() => {
    setScript(
      createScript(snippetConfig, { selectedPage, containerEl, showChatbot })
    );
  }, [selectedPage, showChatbot, containerEl]);

  const handleSelectPageClicked = (e) => {
    setSelectedPage(e.target.value);
  };

  const handleShowChatBotClicked = (e) => {
    setShowChatbot(e.target.checked);
  };

  const handleContainerChanged = (e) => {
    setContainerEl(e.target.value);
  };

  const selectedPageHelpText =
    'Determines which page of Doorway will be displayed when you include the below code on your website.';
  const showChatbotHelpText =
    'If enabled, the Knockbot will appear (in bottom-right of browser) while simultaneously the chosen Doorway page is embedded.';
  const selectContainerHelpText =
    'The ID of the html element inside of which the chosen Doorway page will be embedded. For example, if the value here is "knockContainer", then the Doorway page will be embedded inside the html element that has the property id="knockContainer". Recommendation is to create an empty div to act as a container, for example `<div id="knockContactForm"></div>`. The ID is case-sensitive.';

  return (
    <div>
      <h3>Integration and Embedding Instructions</h3>

      <h4>Knockbot Integration</h4>
      <div>
        <span>
          Copy and paste the below snippet into the "head" section of the page
          you wish to integrate with Knock.
        </span>
        <div>
          <pre>{createScript(snippetConfig)}</pre>
        </div>
      </div>

      <h4>Embedding Specific Doorway Page</h4>
      <Link href={KNOCKBOT_SUPPORT_ARTICLE} target="_blank" rel="noreferrer">
        Support Article
      </Link>
      <div>
        <div>
          To embed a specific Doorway page into your webpage, set the properties
          below. This will generate a script below which should be included in
          the "head" section of your page.
        </div>
        <div>
          The embedded Doorway page will be inserted into the html element with
          the ID that is specified below.
        </div>
      </div>
      <div>
        <div className="property-info-edit-custom-item">
          <div>Doorway page to display</div>
          <select value={selectedPage} onChange={handleSelectPageClicked}>
            {pageOptions.map((value, index) => {
              return (
                <option key={index} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
          <Tooltip title={selectedPageHelpText}>
            <i className="fa-regular fa-question-circle tooltip"></i>
          </Tooltip>
        </div>
        <div className="property-info-edit-custom-item">
          <label>Show Knockbot as well</label>
          <input
            type="checkbox"
            checked={showChatbot}
            onChange={handleShowChatBotClicked}
          />
          <Tooltip title={showChatbotHelpText}>
            <i className="fa-regular fa-question-circle tooltip"></i>
          </Tooltip>
        </div>
        <div className="property-info-edit-custom-item">
          <label>Container ID</label>
          <input
            type="text"
            value={containerEl}
            onChange={handleContainerChanged}
          />
          <Tooltip title={selectContainerHelpText}>
            <i className="fa-regular fa-question-circle tooltip"></i>
          </Tooltip>
        </div>
      </div>
      <div>
        <pre>{script}</pre>
      </div>
    </div>
  );
};

export default IntegrationInstructions;
