import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  PageContainer,
} from '@knockrentals/knock-react';
class DeleteGroupUserSelect extends Component {
  state = {
    loading: true,
    transferToUserId: this.props.transferToUserId,
  };

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ transferToUserId: nextProps.transferToUserId });
  }

  render() {
    const { loading } = this.state.loading;
    const { deletionsInProgress } = this.props;

    const userToDelete = this.props.users.find(
      (user) => user.user_id === this.props.deletingUserId
    );
    const otherUserOptions = this.props.users.map((user, index) => {
      if (
        user.user_id !== this.props.deletingUserId &&
        !deletionsInProgress.includes(user.user_id)
      ) {
        return (
          <option key={index} value={user.user_id}>
            {user.first_name} {user.last_name}
          </option>
        );
      }
      return false;
    });

    return (
      <PageContainer isLoading={loading}>
        <Modal>
          <ModalHeader>
            <h2>
              {' '}
              Delete {userToDelete.cognito_username ||
                userToDelete.username}{' '}
            </h2>
          </ModalHeader>

          <ModalContent>
            <div>
              Which user should we transfer their data to? <br />
              <select
                id="selectTransferToUser"
                defaultValue={this.state.transferToUserId}
                onChange={this.onTransferToUserChanged}
              >
                {otherUserOptions}
              </select>
            </div>
          </ModalContent>

          <ModalFooter>
            <button
              className="knock-react-button"
              id="cancelDeleteGroupUser"
              onClick={this.props.onCancel.bind(this)}
            >
              Cancel
            </button>

            <button
              className="knock-react-button & danger"
              id="confirmDeleteGroupUser"
              onClick={this.props.onConfirm}
              disabled={loading}
            >
              {loading && (
                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
              )}
              {!loading && <span>Confirm</span>}
            </button>
          </ModalFooter>
        </Modal>
      </PageContainer>
    );
  }

  onTransferToUserChanged = (event) => {
    const id = parseInt(event.target.value, 10);
    this.setState({ transferToUserId: id }, () => {
      this.props.onTransferToUserChanged(id);
    });
  };
}

export default DeleteGroupUserSelect;
