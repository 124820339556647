import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Text,
} from '@knockrentals/knock-shared-web';
import TourHoursDay from './TourHoursDay';
import { DAYS_OF_THE_WEEK } from '../constants';

const useStyles = makeStyles({
  maxToursLabel: {
    paddingTop: 0,
    paddingBottom: 0,
    '&.hidden': {
      visibility: 'hidden',
    },
    '& .MuiListItemText-root': {
      margin: 0,
    },
  },
  daysLst: {
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiListItem-root': { height: '54px' },
  },
});

const TourHours = ({
  isBatchEdit,
  modifiedStatusMap = {},
  setHours,
  tourHours = {},
  updateModifiedStatus,
}) => {
  const classes = useStyles();

  const hasActiveDay = Object.values(tourHours).some(
    (hoursToday) => hoursToday.isActive
  );

  return (
    <List>
      <ListItem
        className={classnames(classes.maxToursLabel, !hasActiveDay && 'hidden')}
      >
        <ListItemText
          primary={
            <Text aria-hidden={!hasActiveDay} variant="body2">
              Max simultaneous tours
            </Text>
          }
          primaryTypographyProps={{ align: 'right' }}
        />
      </ListItem>

      <List className={classes.daysLst}>
        {DAYS_OF_THE_WEEK.map((day) => (
          <TourHoursDay
            day={day}
            isBatchEdit={isBatchEdit}
            isModified={modifiedStatusMap[day]}
            key={day}
            setHours={setHours}
            tourHoursToday={tourHours[day] || {}}
            updateModifiedStatus={updateModifiedStatus}
          />
        ))}
      </List>
    </List>
  );
};

TourHours.propTypes = {
  isBatchEdit: PropTypes.bool,
  modifiedStatusMap: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.bool)])
  ),
  setHours: PropTypes.func,
  tourHours: PropTypes.objectOf(
    PropTypes.shape({
      endTime: PropTypes.string,
      isActive: PropTypes.bool,
      maxVisitors: PropTypes.number,
      startTime: PropTypes.string,
      weekday: PropTypes.number,
    })
  ),
  updateModifiedStatus: PropTypes.func,
};

export default TourHours;
