import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { InputCheckbox, makeStyles } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  hidden: {
    visibility: 'hidden',
  },
});

const IsModifiedCheckbox = ({
  customValueComparison,
  isModified,
  name,
  resetPreference,
  updateModifiedStatus,
  value = null,
}) => {
  const classes = useStyles();
  const initialValueRef = useRef(value);

  const isChecked = isModified || false;

  useEffect(() => {
    const isSameValue = customValueComparison
      ? customValueComparison(value, initialValueRef.current)
      : value === initialValueRef.current;

    if (!isSameValue || isModified === true)
      updateModifiedStatus({ [name]: !isSameValue });
  }, [value]);

  const handleOnChange = (e) => {
    const { checked } = e.target;
    if (!checked) {
      resetPreference({ [name]: initialValueRef.current });
    }
  };

  return (
    <InputCheckbox
      aria-hidden={!isChecked}
      checked={isChecked}
      className={!isChecked ? classes.hidden : ''}
      color="primary"
      data-testid={`${name}_isModified`}
      onChange={handleOnChange}
    />
  );
};

IsModifiedCheckbox.propTypes = {
  customValueComparison: PropTypes.func,
  isModified: PropTypes.bool,
  name: PropTypes.string,
  resetPreference: PropTypes.func,
  updateModifiedStatus: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default IsModifiedCheckbox;
