import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';


import {
  Button,
  Divider,
  Switch,
  Text,
  TextField,
} from '@knockrentals/knock-shared-web';

import { AddCircleSvgIcon, BinIcon } from '../../../Components/Icons';
import { NotificationService } from '../../../Components/Notifications';
import { FeatureActions } from '../../Features/common/FeatureActions';
import FeatureCard from '../../Features/common/FeatureCard';
import FeatureCardContent from '../../Features/common/FeatureCardContent';
import { createUser } from '../SSDAGraphQLAPI';
import { SSDA_PAGE_VIEWS } from '../constants';
import IndividualAccountsForm from './IndividualAccountsForm';
import ServiceAccountsForm from './ServiceAccountsForm';
import { useAppContext } from '../../../Context/AppContext';
import { useStyles } from './styles';
import './_SetupForm.scss';


const SetupForm = ({
  closeDrawer,
  setCurrentView,
}) => {
  const { companyId, companyName } = useAppContext;

  const classes = useStyles();

  const [isServiceAccountVisible, setIsServiceAccountVisible] = useState(false);
  const [isIndividualAccountVisible, setIsIndividualAccountVisible] = useState(false);

  const initialValues = {
    ahtung: false,
    companyName,
    userInfo: [
      {
        isServiceAccount: false,
        email: '',
        firstName: '',
        lastName: '',
      },
    ],
    userInfoServiceAccounts: [
      {
        isServiceAccount: true,
        email: '',
        firstName: '',
        lastName: '',
      },
    ],
    ipAddresses: [''],
  };

  const validationSchema = Yup.object({
    userInfo: Yup.array(
      Yup.object().when('userInfo', {
        is: () => isIndividualAccountVisible === true,
        then: () =>
          Yup.object({
            email: Yup.string()
              .email('Invalid email')
              .required('Email is required'),
            firstName: Yup.string().trim().required('First Name required'),
            lastName: Yup.string().trim().required('Last Name required'),
          }),
      })
    ),

    userInfoServiceAccounts: Yup.array(
      Yup.object({
        email: Yup.string().when('userInfoServiceAccounts', {
          is: () => isServiceAccountVisible === true,
          then: () =>
            Yup.string().email('Invalid email').required('Email is required'),
        }),
      })
    ),

    ipAddresses: Yup.array(
      Yup.string()
        .matches(
          /^.([0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?$)/,
          'IP Address is invalid'
        )
        .required('IP Address is required')
    ),
  });

  const validate = (values) => {
    if (
      values.userInfo.length === 1 &&
      values.userInfoServiceAccounts.length === 1
    ) {
      if (
        values.userInfo[0].email.trim() === '' &&
        values.userInfo[0].firstName.trim() === '' &&
        values.userInfo[0].lastName.trim() === '' &&
        values.userInfoServiceAccounts[0].email.trim() === '' &&
        values.userInfoServiceAccounts[0].firstName.trim() === '' &&
        values.userInfoServiceAccounts[0].lastName.trim() === ''
      ) {
        return {
          userInfos: 'No user info',
        };
      }
    }
  };

  const onSubmit = async (values) => {
    let allUserInformation = values.userInfo;

    if (isServiceAccountVisible && isIndividualAccountVisible) {
      allUserInformation = values.userInfo.concat(
        values.userInfoServiceAccounts
      );
    } else if (isServiceAccountVisible) {
      allUserInformation = values.userInfoServiceAccounts;
    }

    try {
      await createUser({
        ipAddresses: values.ipAddresses,
        userInfo: allUserInformation,
      });
      setCurrentView(SSDA_PAGE_VIEWS.LOADING);
    } catch (error) {
      NotificationService.notify("Error creating SSDA user");
      setCurrentView(SSDA_PAGE_VIEWS.ERROR);
    }


    closeDrawer();
  };

  const handleSidebarClose = () => {
    closeDrawer();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
    validate: validate,
    validateOnBlur: false,
    validateOnChange: false,
  });


  const {
    errors = {},
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
  } = formik;

  const ipAddressesErrors = errors.ipAddresses || [];

  return (
    <section className="ssda-form-container">
      <FeatureCard
        title={"KNOCK DATA CLOUD"}
        subheader={"Create"}
        closeDrawer={handleSidebarClose}
      >

        <FeatureCardContent>
          <FormikProvider value={formik}>
            <Text variant="subtitle2">
              PMC ID
            </Text>
            <Text className={classes.formInput}>
              {companyId}
            </Text>

            <Divider className={classes.divider} />

            <div className="switch-container">
              <Text variant="subtitle1">Service Account</Text>
              <Switch
                color="primary"
                value={isServiceAccountVisible}
                checked={isServiceAccountVisible}
                inputProps={{
                  "aria-label": "service-account-switch"
                }}
                onChange={() => setIsServiceAccountVisible(!isServiceAccountVisible)}
              />

              {isServiceAccountVisible && <ServiceAccountsForm />}
            </div>

            <Divider className={classes.divider} />

            <div className="switch-container">
              <Text variant="subtitle1">Individual Account</Text>

              <Switch
                color="primary"
                value={isIndividualAccountVisible}
                checked={isIndividualAccountVisible}
                inputProps={{
                  "aria-label": "individual-account-switch"
                }}
                onChange={(event) =>
                  setIsIndividualAccountVisible(!isIndividualAccountVisible)
                }
              />

              {isIndividualAccountVisible && <IndividualAccountsForm />}
            </div>

            <Divider className={classes.divider} />

            <FieldArray name="ipAddresses">
              {({ push, remove }) => {
                const { ipAddresses } = values;
                return (
                  <>
                    {ipAddresses.map((_, index) => {
                      const ipAddressesErrorMessage = ipAddressesErrors[index];
                      return (
                        <div
                          className={classes.ipAddresses}
                          key={`ip-addresses-${index}`}
                        >
                          {index > 0 && (
                            <div className="form-controls">
                              <Button
                                variant="text"
                                startIcon={<BinIcon />}
                                onClick={() => remove(index)}
                              >
                                Remove this IP address
                              </Button>
                            </div>
                          )}

                          <TextField
                            className={classes.textFieldInput}
                            error={Boolean(ipAddressesErrorMessage)}
                            helperText={ipAddressesErrorMessage}
                            label={`IP Address ${index > 1 ? index : ''}`}
                            name={`ipAddresses.${index}`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={index > 1 ? 'Add Another IP Address' : `Add IP Address`}
                            value={values[`ipAddresses.${index}`]}
                            variant="filled"
                            fullWidth
                            data-testid="ipAddress"
                          />
                        </div>
                      );
                    })}

                    <Button
                      onClick={() => push('')}
                      startIcon={<AddCircleSvgIcon />}
                      variant="text"
                      disabled={
                        Boolean(
                          errors.ipAddresses && touched.ipAddresses
                        ) || !touched.ipAddresses
                      }
                    >
                      Add IP Address
                    </Button>
                  </>
                );
              }}
            </FieldArray>
          </FormikProvider>
        </FeatureCardContent>

        <FeatureActions
          isCancelButtonHidden={true}
          handleSaveOnClick={handleSubmit}
          isSaveButtonDisabled={
            (!isIndividualAccountVisible && !isServiceAccountVisible)
          }
        />
      </FeatureCard>
    </section>
  );
};

SetupForm.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired,
};

export default SetupForm;
