import React, { useState } from 'react';

import { Text, ThemeProvider } from '@knockrentals/knock-shared-web';
import {
  CompanyQuickRepliesGrid,
  useQuickReplyGrid,
} from './CompanyQuickRepliesGrid';
import { ErrorDialog } from '../common/ErrorDialog';
import { QuickReply } from './QuickReply';
import FeatureDrawer from '../common/FeatureDrawer';
import { useMergeTags } from '../common/MergeTags';
import './_CompanyQuickRepliesPage.scss';

export const DESCRIPTION =
  'Company Quick Replies listed here are not editable by individual agents in Knock CRM.';

const CompanyQuickRepliesPage = () => {
  const [quickReply, setQuickReply] = useState(null);

  const { mergeTags } = useMergeTags();

  const {
    errorMessage,
    getTableData,
    resetTableData,
    rowsPerPage,
    rowsPerPageOptions,
    setErrorMessage,
    setPageIndex,
    setRowsPerPage,
    tableRef,
  } = useQuickReplyGrid();

  const closeDrawer = () => {
    setQuickReply(null);
  };

  const handleEditQuickReply = (quickReply) => {
    setQuickReply(quickReply);
  };

  return (
    <ThemeProvider>
      <section className="quick-reply-page">
        <Text variant="h6">Company QRs</Text>
        <div className="quick-reply-page-description">
          <Text variant="body1">{DESCRIPTION}</Text>
        </div>

        <ErrorDialog
          closeDialog={() => {
            setErrorMessage('');
          }}
          errorMessage={errorMessage}
          isOpen={Boolean(errorMessage)}
        />

        <FeatureDrawer disableEnforceFocus isOpen={Boolean(quickReply)}>
          <QuickReply
            closeDrawer={closeDrawer}
            mergeTags={mergeTags}
            quickReply={quickReply || {}}
            resetTableData={resetTableData}
          />
        </FeatureDrawer>

        <CompanyQuickRepliesGrid
          getTableData={getTableData}
          handleCreateQuickReply={() => {
            setQuickReply({});
          }}
          handleEditQuickReply={handleEditQuickReply}
          resetTableData={resetTableData}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          setPageIndex={setPageIndex}
          setRowsPerPage={setRowsPerPage}
          tableRef={tableRef}
        />
      </section>
    </ThemeProvider>
  );
};

export default CompanyQuickRepliesPage;
