import React from 'react';
import PropTypes from 'prop-types';

import { Button, makeStyles, Tooltip } from '@knockrentals/knock-shared-web';
import HelpOutline from '@material-ui/icons/HelpOutline';

import { EditIcon } from '../../../../../Components/Icons';

const useStyles = makeStyles((theme) => ({
  editButton: {
    textTransform: 'none',

    '& .MuiSvgIcon-root': {
      height: '18px',
      width: '18px',
    },

    '&:disabled .MuiSvgIcon-root path': {
      stroke: theme.palette.action.disabled,
    },
  },
}));

const IS_TOOLTIP_FEATURE_VISIBLE = false;

const EditButton = ({ onClick, tooltip, selectedCount = 0 }) => {
  const classes = useStyles();
  const isDisabled = selectedCount === 0;

  return (
    <div className="edit-scheduling-button">
      <Button
        className={classes.editButton}
        disabled={isDisabled}
        color="primary"
        onClick={onClick}
        startIcon={<EditIcon />}
        variant="text"
      >
        {`Edit (${selectedCount} selected)`}
      </Button>

      {IS_TOOLTIP_FEATURE_VISIBLE && (
        <Tooltip
          className="scheduling-tooltip"
          placement="top"
          title={tooltip || ''}
        >
          <HelpOutline />
        </Tooltip>
      )}
    </div>
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func,
  selectedCount: PropTypes.number,
  tooltip: PropTypes.string,
};

export default EditButton;
