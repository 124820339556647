import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  InputText,
  Text,
  makeStyles,
  Tooltip,
} from '@knockrentals/knock-shared-web';
import HelpOutline from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles(() => ({
  characterCount: {
    display: 'inline-block',
    alignSelf: 'flex-end',
  },
  intentContainer: {
    paddingBottom: 20,
  },
  inputField: {
    width: '100%',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '40px',
  },
  productNotActiveToolTip: {
    verticalAlign: 'middle',
    marginLeft: '3px',
  },
}));

export const VOICE_RESPONSE_CHAR_LIMIT = 300;
export const VOICE_RESPONSE_CHAR_LIMIT_ERROR = `Voice Response should not have more than ${VOICE_RESPONSE_CHAR_LIMIT} characters`;

const PropertyVoiceResponseTemplate = ({ voiceResponse, onVoiceResponseChange, active, generatedByAi }) => {
  const classes = useStyles();
  const [voiceResponseCharCount, setVoiceResponseCharCount] = useState(voiceResponse?.length || 0);

  useEffect(() => {
    setVoiceResponseCharCount(voiceResponse?.length || 0);
  }, [voiceResponse]);

  const isVoiceResponseCharExceeded =
    voiceResponseCharCount > VOICE_RESPONSE_CHAR_LIMIT;

  const handleVoiceResponseChange = (response) => {
    setVoiceResponseCharCount(response.length);
    onVoiceResponseChange && onVoiceResponseChange(response);
  };

  return (
    <Box className={classes.intentContainer}>
      <div className="knock-react-flex">
        <div className="input-label-text">
        <Text variant="subtitle2" className={classes.labelContainer}>
          Voice 
        </Text>
        </div>
        {!active && (
          <Tooltip title="AI Voice is not active">
              <HelpOutline
                className={classes.productNotActiveToolTip}
                fontSize="small"
              />
          </Tooltip>
        )}
      </div>
      <InputText
        multiline
        className={classes.inputField}
        value={voiceResponse}
        hideOptionalLabel={true}
        onChange={(event) => handleVoiceResponseChange(event.target.value)}
        error={isVoiceResponseCharExceeded}
        helperText={
          isVoiceResponseCharExceeded && VOICE_RESPONSE_CHAR_LIMIT_ERROR
        }
        InputProps={{
          endAdornment: (
            <Text variant="body2" className={classes.characterCount}>
              {voiceResponseCharCount}/{VOICE_RESPONSE_CHAR_LIMIT}
            </Text>
          ),
        }}
      />
    </Box>
  );
};

PropertyVoiceResponseTemplate.propTypes = {
  voiceResponse: PropTypes.string,
  onVoiceResponseChange: PropTypes.func,
  active: PropTypes.bool,
  generatedByAi: PropTypes.bool,
};

export default PropertyVoiceResponseTemplate;
