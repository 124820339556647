import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@knockrentals/knock-shared-web';

export const BUTTON_TEXT = 'Create New';

const CreateNewButton = ({ onClick }) => (
  <Button color="primary" onClick={onClick} fullWidth variant="outlined">
    {BUTTON_TEXT}
  </Button>
);

CreateNewButton.propTypes = {
  onClick: PropTypes.func,
};

export default CreateNewButton;
