import React, { useState } from 'react';
import CompanySourceRow from './CompanySourceRow';
import SourceProperties from './SourceProperties';

export const getUpdatedSourceProperties = (
  sourceProperties,
  mappedPropertyUpdates
) => {
  Object.entries(mappedPropertyUpdates).forEach(
    ([leasingTeamId, propertyUpdate]) => {
      sourceProperties[leasingTeamId] = {
        ...sourceProperties[leasingTeamId],
        ...propertyUpdate,
      };
    }
  );
  return sourceProperties;
};

const CompanySourcesAccordion = ({ companySources = [] }) => {
  const [sourceProperties, setSourceProperties] = useState({});

  const updatePropertiesMap = (sourceId) => (propertiesMap) => {
    setSourceProperties((prevState) => ({
      ...prevState,
      [sourceId]: {
        ...prevState[sourceId],
        ...propertiesMap,
      },
    }));
  };

  const updateSourceProperties = (sourceId) => (mappedPropertyUpdates) => {
    setSourceProperties((prevState) => {
      const sourceProperties = { ...prevState[sourceId] };
      const updatedSourceProperties = getUpdatedSourceProperties(
        sourceProperties,
        mappedPropertyUpdates
      );

      return {
        ...prevState,
        [sourceId]: {
          ...updatedSourceProperties,
        },
      };
    });
  };

  const renderCompanySourceRow = (source) => {
    const sourceId = source.id;
    const propertiesMap = sourceProperties[sourceId];
    return (
      <CompanySourceRow key={sourceId} title={source.name}>
        <SourceProperties
          propertiesMap={propertiesMap}
          sourceId={sourceId}
          updatePropertiesMap={updatePropertiesMap(sourceId)}
          updateSourceProperties={updateSourceProperties(sourceId)}
        />
      </CompanySourceRow>
    );
  };

  return (
    <div
      className="company-source-accordion"
      data-testid="company-source-accordion"
    >
      {companySources.map(renderCompanySourceRow)}
    </div>
  );
};

export default CompanySourcesAccordion;
