import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@knockrentals/knock-shared-web';
import { DeleteIcon } from './index';

const useStyles = makeStyles({
  deleteIcon: {
    height: '18px',
    width: '18px',
    '&.MuiSvgIcon-root path': {
      stroke: 'currentColor',
    },
  },
});

export const DeleteSvgIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.deleteIcon}>
      <DeleteIcon />
    </SvgIcon>
  );
};
