import React from 'react';
import PropTypes from 'prop-types';

import { Switch } from '@knockrentals/knock-shared-web';

const SchedulingSwitch = ({
  isChecked = false,
  label,
  name,
  updateSchedulingSwitch,
}) => {
  const handleOnChange = (e) => {
    updateSchedulingSwitch(e.target.name);
  };

  return (
    <Switch
      checked={isChecked}
      color="primary"
      name={name}
      onChange={handleOnChange}
      inputProps={{ 'aria-label': `${label} checkbox` }}
    />
  );
};

SchedulingSwitch.propTypes = {
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  updateSchedulingSwitch: PropTypes.func,
};

export default SchedulingSwitch;
