import {
  AVAILABILITY_BUFFER,
  CUSTOM_REQUIREMENTS,
  DAYS_OF_THE_WEEK,
  END_TIME,
  INCOME_MULTIPLIER,
  IS_BUDGET_REQUIRED,
  IS_EVICTION_HISTORY_REQUIRED,
  IS_MOVE_DATE_REQUIRED,
  IS_PET_POLICY_REQUIRED,
  IS_SELF_SCHEDULE_ENABLED,
  IS_SELF_GUIDED_TOURS_ENABLED,
  IS_IN_PERSON_TOURS_ENABLED,
  IS_LIVE_VIDEO_TOURS_ENABLED,
  MAX_VISITORS,
  NUMBER_OF_SCHEDULING_DAYS,
  PRE_APPOINTMENT_BUFFER,
  POST_APPOINTMENT_BUFFER,
  SHOULD_HIDE_SCHEDULING_CALL_TO_ACTION,
  SHOULD_SELF_GUIDED_TOURS_BLOCK,
  SHOULD_CLUSTER_TOURS,
  START_TIME,
  TOUR_DURATION,
} from '../constants';

export const sortColumnMap = {
  propertyId: 'property_id',
  propertyName: 'property_name',
  leasingTeamId: 'leasing_team_id',
  leasingTeamName: 'leasing_team_name',
  [IS_SELF_SCHEDULE_ENABLED]: 'is_self_scheduling_enabled',
  [IS_IN_PERSON_TOURS_ENABLED]: 'is_in_person_enabled',
  [IS_LIVE_VIDEO_TOURS_ENABLED]: 'is_live_video_enabled',
};

export const getQueryString = (params) => {
  const { page, pageSize, sortColumn, sortDirection } = params;
  const offSet = page * pageSize;
  return `?offset=${offSet}&page_size=${pageSize}&sort_column=${sortColumnMap[sortColumn]}&sort_direction=${sortDirection}`;
};

export const normalizePropertyList = (properties) =>
  properties.map((property) => ({
    id: property.property_id,
    name: property.property_name,
  }));

export const normalizeSchedulingProperties = (properties) => {
  return properties.map((property) => ({
    propertyId: property.property_id,
    propertyName: property.property_name || '',
    leasingTeamId: property.leasing_team_id,
    leasingTeamName: property.leasing_team_name || '',
    [IS_SELF_SCHEDULE_ENABLED]: property.is_self_scheduling_enabled || false,
    [IS_IN_PERSON_TOURS_ENABLED]: property.is_in_person_enabled || false,
    [IS_LIVE_VIDEO_TOURS_ENABLED]: property.is_live_video_enabled || false,
  }));
};

const getIsLiveVideoToursEnabled = (liveVideoTourType) =>
  liveVideoTourType === 'external' || liveVideoTourType === true;

export const normalizeFeatures = (features) => ({
  [AVAILABILITY_BUFFER]: features.availability_buffer || '01:00:00',
  [IS_IN_PERSON_TOURS_ENABLED]: features.in_person_tours || false,
  [IS_LIVE_VIDEO_TOURS_ENABLED]: getIsLiveVideoToursEnabled(
    features.live_video_tour_type
  ),
  [IS_SELF_GUIDED_TOURS_ENABLED]: features.self_guided_tours_enabled || false,
  [IS_SELF_SCHEDULE_ENABLED]: features.all_showings || false,
  [NUMBER_OF_SCHEDULING_DAYS]: features.number_of_scheduling_days || 7,
  [POST_APPOINTMENT_BUFFER]: features.post_appointment_buffer || '00:30:00',
  [PRE_APPOINTMENT_BUFFER]: features.pre_appointment_buffer || '00:30:00',
  [SHOULD_CLUSTER_TOURS]: features.cluster_tours || false,
  [SHOULD_HIDE_SCHEDULING_CALL_TO_ACTION]: features.hide_scheduling || false,
  [SHOULD_SELF_GUIDED_TOURS_BLOCK]: features.self_guided_tours_block || false,
  [TOUR_DURATION]: features.tour_duration || '0:15:00',
});

export const normalizeCustomRequirements = (customRequirements) =>
  customRequirements.map(({ prompt, desired_answer: desiredAnswer }) => ({
    prompt,
    desiredAnswer,
  }));

export const normalizePrescreeningRequirements = (requirements) => ({
  customRequirements: normalizeCustomRequirements(
    requirements.custom_requirements || []
  ),
  incomeMultiplier: requirements.income_multiplier || 0,
  isBudgetRequired: requirements.budget_check || false,
  isEvictionHistoryRequired: requirements.eviction_check || false,
  isMoveDateRequired: requirements.move_dates || false,
  isPetPolicyRequired: requirements.pet_policy || false,
});

export const normalizeDayHours = (hours) => ({
  endTime: hours.end_time,
  isActive: true,
  maxVisitors: hours.max_visitors,
  startTime: hours.start_time,
  weekday: hours.weekday,
});

export const normalizeTourHours = (hours) =>
  hours.reduce((hoursByDay, dayHours) => {
    const dayName = DAYS_OF_THE_WEEK[dayHours.weekday - 1];
    hoursByDay[dayName] = normalizeDayHours(dayHours);
    return hoursByDay;
  }, {});

export const normalizeSchedulingPreferences = ({
  property_owner_timezone = '',
  preferences = {},
  screening_data = {},
  touring_hours = {},
}) => {
  const { in_person, self_guided } = touring_hours;
  return {
    inPersonTouringHours: normalizeTourHours(in_person || []),
    preferences: normalizeFeatures(preferences),
    screeningData: normalizePrescreeningRequirements(screening_data),
    selfGuidedTouringHours: normalizeTourHours(self_guided || []),
    propertyOwnerTimezone: property_owner_timezone,
  };
};

const preferencesPayloadMap = {
  [AVAILABILITY_BUFFER]: 'availability_buffer',
  [CUSTOM_REQUIREMENTS]: 'custom_requirements',
  [END_TIME]: 'end_time',
  [INCOME_MULTIPLIER]: 'income_multiplier',
  [IS_BUDGET_REQUIRED]: 'budget_check',
  [IS_EVICTION_HISTORY_REQUIRED]: 'eviction_check',
  [IS_MOVE_DATE_REQUIRED]: 'move_dates',
  [IS_PET_POLICY_REQUIRED]: 'pet_policy',
  [IS_SELF_SCHEDULE_ENABLED]: 'all_showings',
  [IS_SELF_GUIDED_TOURS_ENABLED]: 'self_guided_tours_enabled',
  [IS_IN_PERSON_TOURS_ENABLED]: 'in_person_tours',
  [IS_LIVE_VIDEO_TOURS_ENABLED]: 'live_video_tour_type',
  [MAX_VISITORS]: 'max_visitors',
  [NUMBER_OF_SCHEDULING_DAYS]: 'number_of_scheduling_days',
  [PRE_APPOINTMENT_BUFFER]: 'pre_appointment_buffer',
  [POST_APPOINTMENT_BUFFER]: 'post_appointment_buffer',
  [SHOULD_HIDE_SCHEDULING_CALL_TO_ACTION]: 'hide_scheduling',
  [SHOULD_SELF_GUIDED_TOURS_BLOCK]: 'self_guided_tours_block',
  [SHOULD_CLUSTER_TOURS]: 'cluster_tours',
  [START_TIME]: 'start_time',
  [TOUR_DURATION]: 'tour_duration',
};

export const transformHours = (tourHours) =>
  Object.values(tourHours).map((tourDayHours) => ({
    end_time: tourDayHours[END_TIME],
    start_time: tourDayHours[START_TIME],
    is_inactive: !tourDayHours.isActive,
    max_visitors: tourDayHours[MAX_VISITORS],
    weekday: tourDayHours.weekday,
  }));

export const transformPreferences = (preferences) =>
  Object.entries(preferences).reduce(
    (transformedPreferences, [name, value]) => {
      transformedPreferences[preferencesPayloadMap[name]] = value;
      return transformedPreferences;
    },
    {}
  );

export const transformPreferencesPayload = (payload) => {
  const {
    timezone: { timezone } = {},
    prescreeningRequirements,
    propertyIds,
    inPersonTourHours,
    selfGuidedTourHours,
    features,
  } = payload;

  const transformedPayload = { property_ids: propertyIds };
  const transformedTourHours = {};

  if (timezone) {
    transformedPayload.property_owner_timezone = timezone;
  }

  if (prescreeningRequirements) {
    const requirements = transformPreferences(prescreeningRequirements);
    if (requirements.custom_requirements) {
      requirements.custom_requirements = requirements.custom_requirements.map(
        (requirement) => ({
          desired_answer: requirement.desiredAnswer,
          prompt: requirement.prompt,
        })
      );
    }
    transformedPayload.screening_data = requirements;
  }

  if (features) {
    transformedPayload.updatedPreference = transformPreferences(features);
  }

  if (inPersonTourHours) {
    transformedTourHours.in_person = transformHours(inPersonTourHours);
  }

  if (selfGuidedTourHours) {
    transformedTourHours.self_guided = transformHours(selfGuidedTourHours);
  }

  if (Object.keys(transformedTourHours).length) {
    transformedPayload.touring_hours = transformedTourHours;
  }

  return transformedPayload;
};
