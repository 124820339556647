import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  List,
  LoadingOverlay,
  Text,
} from '@knockrentals/knock-shared-web';

import { Features } from './Features';
import { PreferencesAccordion } from './PreferencesAccordion';
import { Prescreening } from './Prescreening';
import { ConfirmationDialog } from './ConfirmationDialog';
import { TourHours } from './TourHours';
import { Timezone } from './Timezone';
import useSchedulingPreferences from './useSchedulingPreferences';
import { getUpdateCount } from './utils';
import { PREFERENCE_TYPES } from './constants';
import './_SchedulingPreferencesEdit.scss';

const SchedulingPreferencesEdit = ({
  closeDrawer,
  isBatchEdit,
  selectedProperties = [],
  setErrorMessage,
  updateSchedulingGridData,
}) => {
  const {
    features,
    isLoading,
    inPersonTourHours,
    isSaveButtonDisabled,
    modifiedStatusMap,
    prescreeningRequirements,
    savePreferences,
    selfGuidedTourHours,
    setFeatures,
    setInPersonTourHours,
    setModifiedStatusMap,
    setPrescreeningRequirements,
    setSelfGuidedTourHours,
    setTimezone,
    timezone,
  } = useSchedulingPreferences({
    closeDrawer,
    isBatchEdit,
    selectedProperties,
    setErrorMessage,
    updateSchedulingGridData,
  });

  const [isConformationVisible, setIsConformationVisible] = useState(false);

  const updateModifiedStatus = (preferenceType) => (modifiedStatus) => {
    setModifiedStatusMap((prevState) => ({
      ...prevState,
      [preferenceType]: {
        ...prevState[preferenceType],
        ...modifiedStatus,
      },
    }));
  };

  return (
    <div className="scheduling-preferences-edit">
      <LoadingOverlay open={isLoading} />

      <ConfirmationDialog
        isOpen={isConformationVisible}
        preferenceCount={getUpdateCount(modifiedStatusMap)}
        propertyCount={selectedProperties.length}
        handleSaveOnClick={savePreferences}
        closeDialog={() => setIsConformationVisible(false)}
      />

      <div className="scheduling-preferences-edit-header">
        <Text variant="h5">Preferences</Text>
        <Button
          color="primary"
          disabled={isSaveButtonDisabled}
          onClick={() => setIsConformationVisible(true)}
        >
          Save
        </Button>
      </div>
      {!isLoading && (
        <div className="scheduling-preferences-edit-list">
          <List>
            <PreferencesAccordion title="Features">
              <Features
                features={features}
                isBatchEdit={isBatchEdit}
                modifiedStatusMap={modifiedStatusMap[PREFERENCE_TYPES.FEATURES]}
                setFeatures={setFeatures}
                updateModifiedStatus={updateModifiedStatus(
                  PREFERENCE_TYPES.FEATURES
                )}
              />
            </PreferencesAccordion>

            <PreferencesAccordion title="Pre-screening Questions">
              <Prescreening
                isBatchEdit={isBatchEdit}
                modifiedStatusMap={
                  modifiedStatusMap[PREFERENCE_TYPES.PRESCREENING_REQUIREMENTS]
                }
                requirements={prescreeningRequirements}
                updateModifiedStatus={updateModifiedStatus(
                  PREFERENCE_TYPES.PRESCREENING_REQUIREMENTS
                )}
                setRequirements={setPrescreeningRequirements}
              />
            </PreferencesAccordion>

            <PreferencesAccordion title="Property Timezone">
              <Timezone
                isBatchEdit={isBatchEdit}
                modifiedStatusMap={modifiedStatusMap[PREFERENCE_TYPES.TIMEZONE]}
                setTimezone={setTimezone}
                timezone={timezone}
                updateModifiedStatus={updateModifiedStatus(
                  PREFERENCE_TYPES.TIMEZONE
                )}
              />
            </PreferencesAccordion>

            <PreferencesAccordion title="Touring Hours">
              <TourHours
                isBatchEdit={isBatchEdit}
                modifiedStatusMap={
                  modifiedStatusMap[PREFERENCE_TYPES.IN_PERSON_TOUR_HOURS]
                }
                setHours={setInPersonTourHours}
                tourHours={inPersonTourHours}
                updateModifiedStatus={updateModifiedStatus(
                  PREFERENCE_TYPES.IN_PERSON_TOUR_HOURS
                )}
              />
            </PreferencesAccordion>
            <PreferencesAccordion title="Self-Guided Touring Hours">
                <TourHours
                  isBatchEdit={isBatchEdit}
                  modifiedStatusMap={
                    modifiedStatusMap[PREFERENCE_TYPES.SELF_GUIDED_TOUR_HOURS]
                  }
                  setHours={setSelfGuidedTourHours}
                  tourHours={selfGuidedTourHours}
                  updateModifiedStatus={updateModifiedStatus(
                    PREFERENCE_TYPES.SELF_GUIDED_TOUR_HOURS
                  )}
                />
              </PreferencesAccordion>
          </List>
        </div>
      )}
    </div>
  );
};

SchedulingPreferencesEdit.propTypes = {
  closeDrawer: PropTypes.func,
  isBatchEdit: PropTypes.bool,
  selectedProperties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      isSelected: PropTypes.bool,
    })
  ),
  setErrorMessage: PropTypes.func,
  updateEditedSchedulingSwitches: PropTypes.func,
};

export default SchedulingPreferencesEdit;
