import {
  apiRemote,
  phoneRegistrationRemote,
} from '../../Services/RemoteService';

class RegistrationAPI {
  static createRegistration(twilioBrandRegistrationSid) {
    return apiRemote.post(`admin/registration`, {
      twilio_brand_registration_sid: twilioBrandRegistrationSid,
    });
  }

  static getCompanyPhoneRegistration = async () => {
    try {
      const response = await phoneRegistrationRemote.get('/company');

      if (response.status !== 200) {
        throw new Error();
      }

      return await response.json();
    } catch {
      throw new Error();
    }
  };

  static getPropertyPhoneRegistration = async (propertyId) => {
    try {
      const response = await phoneRegistrationRemote.get(
        `/property?propertyId=${propertyId}`
      );

      if (response.status !== 200) {
        throw new Error();
      }

      return await response.json();
    } catch {
      throw new Error();
    }
  };

  static getRegistration(twilioBrandRegistrationSid) {
    const queryParam = twilioBrandRegistrationSid
      ? `?sid=${twilioBrandRegistrationSid}`
      : '';
    return apiRemote.get(`admin/registration${queryParam}`);
  }
}

export default RegistrationAPI;
