import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text, Tooltip, makeStyles } from '@knockrentals/knock-shared-web';

export const TOOLTIP_ARIA_LABEL = 'informational tooltip icon';

const useStyles = makeStyles({
  infoIconTooltip: ({ iconFontSize }) => ({
    borderBottom: 'unset',
    fontSize: iconFontSize,
    fontWeight: '400',
    margin: '5px',
    verticalAlign: 'middle',
  }),
});

const renderMessage = (message) =>
  message ? <Text variant="caption">{message}</Text> : '';

const InfoIconTooltip = ({
  className = '',
  iconFontSize = '14px',
  message,
  placement = 'right',
}) => {
  const classes = useStyles({ iconFontSize });
  return (
    <Tooltip
      arrow
      className={classnames(classes.infoIconTooltip, className)}
      placement={placement}
      title={renderMessage(message)}
    >
      <i
        aria-label={TOOLTIP_ARIA_LABEL}
        className="fa-regular fa-question-circle tooltip"
      ></i>
    </Tooltip>
  );
};

InfoIconTooltip.propTypes = {
  className: PropTypes.string,
  iconFontSize: PropTypes.string,
  message: PropTypes.string,
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
};

export default InfoIconTooltip;
