import React, { Component } from 'react';
import { DayHoursPicker, Collapse } from '@knockrentals/knock-react';
import moment from 'moment';
import PropertyInfoEditCustom from '../Properties/PropertyInfoEditCustom';
import IntegrationInstructions from './IntegrationInstructions';
import { DraggableListContainer } from './DraggableList/index';
import * as Yup from 'yup';
import './PropertyDoorway.scss';
import DoorwayCheckboxInput from './components/DoorwayCheckboxInput';
import DoorwayColorPickerInput from './components/DoorwayColorPickerInput';
import WebsiteUrl from './components/WebsiteUrl';

const virtualTourLinksValidator = Yup.object().shape({
  name: Yup.string()
    .required('Name is a required field')
    .typeError('Name must be a string'),
  url: Yup.string()
    .matches(
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/,
      'URL must begin with http:// or https://'
    )
    .required('URL is a required field')
    .typeError('URL must be a string'),
});

class PropertyDoorway extends Component {
  state = {
    hourOptions: [],
    preferences: {},
    templates: {},
  };

  componentDidMount() {
    const dayStart = moment().hour(7).minute(0).second(0);
    const dayEnd = moment().hour(22).minute(0).second(0);
    const timeWalker = moment(dayStart);

    const hourOptions = [];

    while (timeWalker.isBefore(dayEnd)) {
      hourOptions.push(timeWalker.format('HH:mm:00'));
      timeWalker.add(15, 'minutes');
    }

    this.setState({
      hourOptions,
      preferences: {
        button_greeting:
          this.props.doorwaySettings.settings.preferences.button_greeting,
      },
      templates: {
        greeting:
          this.props.doorwaySettings.settings.message_templates.greeting,
        guest: this.props.doorwaySettings.settings.message_templates.guest,
        away: this.props.doorwaySettings.settings.message_templates.away,
        away_response:
          this.props.doorwaySettings.settings.message_templates.away_response,
      },
    });
  }

  render() {
    const websiteUrl = this.props.doorwaySettings.website;

    return (
      <Collapse title={this.props.doorwaySettings.name}>
        {websiteUrl && <WebsiteUrl url={this.props.doorwaySettings.website} />}

        <div className="doorway-property-content-container">
          <h3>General Settings</h3>

          <DoorwayCheckboxInput
            label="Require unit scheduling"
            settingName="requireUnitScheduling"
            checked={this.props.property.preferences.require_unit_scheduling}
            onChange={this.requireUnitSchedulingChange.bind(this)}
            id="requireUnitScheduling"
          />
          <DoorwayCheckboxInput
            label="Send Google Analytics Events"
            checked={
              this.props.doorwaySettings.settings.preferences
                .emit_analytics_events
            }
            onChange={this.sendGoogleAnalyticsEventsChanged}
            id="analytics"
          />
          <DoorwayCheckboxInput
            label="Send Google Tag Manager Events"
            checked={
              this.props.doorwaySettings.settings.preferences
                .emit_tag_manager_events
            }
            onChange={this.sendGoogleTagManagerEventsChanged}
            id="tag-manager"
          />

          <hr />

          <h3>Button Settings</h3>

          <DoorwayCheckboxInput
            label="Display button in bottom right"
            checked={
              this.props.doorwaySettings.settings.preferences
                .display_launcher_button
            }
            onChange={this.displayButtonChanged.bind(this)}
            id="tag-manager"
          />

          <DoorwayColorPickerInput
            label="Button color"
            color={this.props.doorwaySettings.settings.preferences.button_color}
            onChange={this.buttonColorChanged}
            testId="color-picker-button"
          />
          <DoorwayColorPickerInput
            label="Theme color"
            color={
              this.props.doorwaySettings.settings.preferences.primary_color
            }
            onChange={this.primaryColorChanged}
            testId="color-picker-theme"
          />

          <div className="doorway-property-setting-item">
            <div>
              <span>Button greeting text</span>
            </div>
            <textarea
              value={this.state.preferences.button_greeting}
              onChange={this.handleButtonGreetingChange}
              onBlur={this.handleButtonGreetingSubmit}
            />
          </div>

          <hr />

          <h3>Hours</h3>

          <div>{this.renderHours()}</div>

          <hr />

          <div>
            <PropertyInfoEditCustom
              property={this.props.property}
              isChatEnabled={this.props.isChatEnabled}
              onChange={this.props.onPropertyChanged}
            />
          </div>

          <hr />

          <h3>Virtual Tours Settings</h3>

          <div>
            <div className="doorway-property-setting-item">
              <label
                className="doorway-label-text"
                htmlFor="enableVirtualTourLinks"
              >
                Virtual Tour Links
              </label>
              <input
                id="enableVirtualTourLinks"
                type="checkbox"
                checked={
                  this.props.schedulingSettings.preferences.virtual_tour_links
                }
                onChange={this.virtualTourLinksChange.bind(this)}
              />
            </div>

            <div className="doorway-help-text">
            Add up to 10 Virtual Tours to Doorway. Reorder items to customize display order.
            To improve accessibility and support ADA compliance, please upload videos with subtitles
            or some form of descriptive narration. 
            </div>
          </div>

          <DraggableListContainer
            data={
              this.props.schedulingSettings.preferences
                .virtual_tour_links_mapping
            }
            fields={['name', 'url']}
            headers={['Name', 'Url']}
            maxSize={10}
            onDataChange={this.virtualTourLinksMappingChange.bind(this)}
            validator={virtualTourLinksValidator}
          />

          <hr />

          <IntegrationInstructions snippetConfig={this.props.doorwaySettings.settings.snippetConfig} />

        </div>
      </Collapse>
    );
  }

  // A temporary feature flag that will be removed when the feature is fully
  // exposed to users, see: https://knockr.atlassian.net/browse/SP-1255
  isVirtualTourFeatureFlagActive() {
    return (
      this.props.schedulingSettings &&
      this.props.schedulingSettings.preferences &&
      this.props.schedulingSettings.preferences.virtual_tour_links &&
      this.props.schedulingSettings.preferences.virtual_tour_links_mapping
    );
  }

  buttonColorChanged = (color) => {
    const updatedSettings = this.props.doorwaySettings.settings;

    updatedSettings.preferences.button_color = color;
    this.settingsUpdated(updatedSettings);
  };

  handleButtonGreetingChange = (event) => {
    this.setState({
      preferences: {
        button_greeting: event.target.value,
      },
    });
  };

  handleButtonGreetingSubmit = () => {
    const updatedSettings = this.props.doorwaySettings.settings;

    updatedSettings.preferences.button_greeting =
      this.state.preferences.button_greeting;
    this.settingsUpdated(updatedSettings);
  };

  primaryColorChanged = (color) => {
    const updatedSettings = this.props.doorwaySettings.settings;

    updatedSettings.preferences.primary_color = color;
    this.settingsUpdated(updatedSettings);
  };

  displayButtonChanged(event) {
    const updatedSettings = this.props.doorwaySettings.settings;

    updatedSettings.preferences.display_launcher_button = event.target.checked;
    this.settingsUpdated(updatedSettings);
  }

  sendGoogleTagManagerEventsChanged = (event) => {
    const updatedSettings = this.props.doorwaySettings.settings;

    updatedSettings.preferences.emit_tag_manager_events = event.target.checked;
    updatedSettings.preferences.emit_analytics_events = false;

    this.settingsUpdated(updatedSettings);
  }

  sendGoogleAnalyticsEventsChanged = (event) => {
    const updatedSettings = this.props.doorwaySettings.settings;

    updatedSettings.preferences.emit_analytics_events = event.target.checked;
    updatedSettings.preferences.emit_tag_manager_events = false;

    this.settingsUpdated(updatedSettings);
  }

  renderHours() {
    return new Array(7).fill(0).map((_, index) => {
      const weekday = index + 1;
      const dayHours = this.props.doorwaySettings.settings.hours[weekday];

      const startTimeChanged = (event) => {
        const updatedHours = this.props.doorwaySettings.settings.hours[weekday];
        updatedHours.start_time = event.target.value;

        this.settingsUpdated(this.props.doorwaySettings.settings);
      };

      const endTimeChanged = (event) => {
        const updatedHours = this.props.doorwaySettings.settings.hours[weekday];
        updatedHours.end_time = event.target.value;

        this.settingsUpdated(this.props.doorwaySettings.settings);
      };

      const isActiveChanged = (event) => {
        const updatedHours = this.props.doorwaySettings.settings.hours[weekday];
        updatedHours.is_active = event.target.checked;

        this.settingsUpdated(this.props.doorwaySettings.settings);
      };

      return (
        <DayHoursPicker
          key={weekday}
          weekday={weekday}
          startTime={dayHours.start_time}
          endTime={dayHours.end_time}
          timeOptions={this.state.hourOptions}
          isActive={dayHours.is_active}
          startTimeChanged={startTimeChanged}
          endTimeChanged={endTimeChanged}
          isActiveChanged={isActiveChanged}
        />
      );
    });
  }

  templatesChanged() {
    const updatedSettings = this.props.doorwaySettings.settings;

    updatedSettings.message_templates = this.state.templates;
    this.settingsUpdated(updatedSettings);
  }

  settingsUpdated(updatedSettings) {
    this.props.onDoorwayChanged(this.props.doorwaySettings.id, updatedSettings);
  }

  requireUnitSchedulingChange(event) {
    const updateRequireUnitScheduling = event.target.checked;

    const updatedProperty = this.props.property;

    updatedProperty.preferences.require_unit_scheduling =
      updateRequireUnitScheduling;

    this.props.onPropertyChanged(updatedProperty);
  }

  virtualTourLinksChange(event) {
    const virtualTourLinksFlag = event.target.checked;

    const updatedSettings = { ...this.props.schedulingSettings };

    updatedSettings.preferences.virtual_tour_links = virtualTourLinksFlag;

    this.props.onSchedulingChanged(
      this.props.doorwaySettings.id,
      updatedSettings.preferences
    );
  }

  virtualTourLinksMappingChange(updatedData) {
    const updatedSettings = { ...this.props.schedulingSettings };

    updatedSettings.preferences.virtual_tour_links_mapping = updatedData;

    this.props.onSchedulingChanged(
      this.props.doorwaySettings.id,
      updatedSettings.preferences
    );
  }
}

export default PropertyDoorway;
