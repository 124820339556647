class CommunityService {
  static enforceCommunityIntegrity = (community) => {
    const knownObjects = [
      'affordableHousing',
      'amenities',
      'coverPhoto',
      'description',
      'doorway',
      'extra',
      'laundry',
      'leasing',
      'location',
      'parking',
      'pets',
      'social',
      'utilities',
    ];

    knownObjects.forEach((knownObject) => {
      if (!community[knownObject]) {
        community[knownObject] = {};
      }
    });

    community.leasing.terms = community.leasing.terms || {};
    community.leasing.application = community.leasing.application || {};

    const knownArrays = [
      'appliances',
      'costables',
      'customDetails',
      'floorplans',
      'furnishing',
      'logos',
      'photos',
      'videos',
      'views',
    ];

    knownArrays.forEach((knownArray) => {
      if (!community[knownArray]) {
        community[knownArray] = [];
      }
    });

    return community;
  }
}

export default CommunityService;
