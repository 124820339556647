import React, { useState } from 'react';
import { LoadingOverlay } from '@knockrentals/knock-shared-web';

import AiTemplate from './AiTemplate/AiTemplate';
import { ErrorDialog } from '../../common/ErrorDialog';
import FeatureDrawer from '../../common/FeatureDrawer';
import useAiTemplates from './useAiTemplates';
import { useMergeTags } from '../../common/MergeTags';
import AiTemplatesList from './AiTemplatesList/AiTemplatesList';
import { MERGE_TAG_TYPES } from '../../../../Services/message-service';
import AiNotEnabledMessage from './AiNotEnabledMessage';
import { OFF, ON, SETUP } from './AiConstants';

const AiTemplates = () => {
  const [aiTemplate, setAiTemplate] = useState(null);
  const { mergeTags } = useMergeTags(MERGE_TAG_TYPES.PROSPECT);
  const {
    aiTemplates,
    errorMessage,
    fetchAiTemplates,
    isLoading,
    setErrorMessage,
    aiView,
  } = useAiTemplates();

  const closeDrawer = () => {
    setAiTemplate(null);
  };

  const handleCloseErrorDialog = () => {
    setErrorMessage('');
  };

  return (
    <React.Fragment>
      <LoadingOverlay open={isLoading} />

      <ErrorDialog
        closeDialog={handleCloseErrorDialog}
        errorMessage={errorMessage}
        isOpen={Boolean(errorMessage)}
      />

      <FeatureDrawer
        disableEnforceFocus
        isOpen={Boolean(aiTemplate)}
        onClose={closeDrawer}
      >
        <AiTemplate
          closeDrawer={closeDrawer}
          aiTemplate={aiTemplate}
          fetchCompanyTemplates={fetchAiTemplates}
          mergeTags={mergeTags}
        />
      </FeatureDrawer>

      {aiView === OFF && !isLoading && <AiNotEnabledMessage />}

      {(aiView === ON || aiView === SETUP) && !isLoading  && aiTemplates && (
        <AiTemplatesList
          displayAlertSetup={aiView === SETUP}
          setAiTemplate={setAiTemplate}
          templatesByType={aiTemplates}
        />
      )}
    </React.Fragment>
  );
};

export default AiTemplates;
