import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { makeStyles, Text } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  propertiesChip: {
    margin: '6px 3px',
  },
});

export const INITIAL_DISPLAY_COUNT = 3;

export const PropertyChips = ({ selectedProperties }) => {
  const classes = useStyles();
  const [isListTruncated, setIsListTruncated] = useState(true);

  const toggleIsListTruncated = () => {
    setIsListTruncated((prevState) => !prevState);
  };

  const visiblePropertyChips = isListTruncated
    ? selectedProperties.slice(0, INITIAL_DISPLAY_COUNT)
    : selectedProperties;

  const hiddenPropertiesCount =
    selectedProperties.length - INITIAL_DISPLAY_COUNT;
  const hasHiddenPropertyChips = hiddenPropertiesCount > 0;

  const clickableChipText = isListTruncated
    ? `+${hiddenPropertiesCount}`
    : 'show less';

  const ClickableChipIcon = isListTruncated ? ExpandMore : ExpandLess;

  return (
    <div className="property-chips">
      {visiblePropertyChips.map((property) => (
        <Chip
          className={classes.propertiesChip}
          key={property.id}
          label={<Text variant="body1">{property.name}</Text>}
        />
      ))}

      {hasHiddenPropertyChips && (
        <Chip
          className={classes.propertiesChip}
          clickable
          icon={<ClickableChipIcon fontSize="small" />}
          label={<Text variant="body1">{clickableChipText}</Text>}
          onClick={toggleIsListTruncated}
        />
      )}
    </div>
  );
};

PropertyChips.propTypes = {
  selectedProperties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      isSelected: PropTypes.bool,
    })
  ),
};

export default PropertyChips;
