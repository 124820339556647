export const ERROR_MESSAGES = {
  COMPANY_SOURCES:
    'We ran into an issue fetching the source data for the company. Please try again later.',
  COMPANY_SOURCE_PROPERTIES:
    'We ran into an issue fetching the property data for this source. Please try again later.',
  COMPANY_SOURCE_PROPERTIES_NOT_FOUND:
    'No properties associated with the company and this source.',
  ADD_SOURCE:
    'We were unable to add the source to the company source list. Please try again later.',
  ALL_SOURCES:
    'An unexpected error occurred when fetching a list of sources. Please try again later.',
  COMPANY_SOURCES_NOT_FOUND:
    'There are no sources to display yet.',
};
