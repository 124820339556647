import { useCallback, useEffect } from 'react';
import * as FullStory from '@fullstory/browser';
import { AuthenticationService } from '@knockrentals/knock-react';
import {
  dataDogInit,
  setDataDogUser,
} from '../../../ExternalServices/DataDogService';
import { ENVIRONMENT } from '../../../config';

dataDogInit();

try {
  FullStory.init({ orgId: 'D4SNF', debug: false });
} catch (e) {
  console.log('FullStory failed to initialize');
}

const trackingIdentify = (userId, config) => {
  const FSConfig = {
    companyId: config['Company ID'] + '',
    companyName: config['Company Name'] + '',
    internalMode: config['Internal Mode'],
    userType: config['User Type'] + '',
  };
  FullStory.identify('Knock' + userId, FSConfig);
};

const trackAppcues = (companyId, companyName, isInternalMode, userId) => {
  if (ENVIRONMENT === 'production') {
    window.Appcues.identify(userId, {
      company: companyName,
      companyId: companyId,
      internalLogin: isInternalMode,
      userId,
      userLevel: AuthenticationService.getRole(),
    });
  }
};

const setTrackingServices = ({
  companyId,
  companyName,
  isInternalMode,
  userId,
  userType,
}) => {
  const identifyConfig = {
    'Company ID': companyId,
    'Company Name': companyName,
    'Internal Mode': isInternalMode,
    'User Type': userType,
  };
  trackingIdentify(userId, identifyConfig);

  setDataDogUser(companyName, companyId, userId);

  if (window.heap) {
    const userProperties = {
      'Admin ID': userId,
      Company: companyName,
      'Company ID': companyId,
      'Internal Login': isInternalMode,
      'User ID': userId,
      'User Level': AuthenticationService.getRole(),
    };
    window.heap.addUserProperties(userProperties);
  }
};

export const useServices = ({
  companyId,
  companyName,
  isInternalMode,
  userId,
  userType,
}) => {
  useEffect(() => {
    if (companyName) {
      setTrackingServices({
        companyId,
        companyName,
        isInternalMode,
        userId,
        userType,
      });
    }
  }, [companyId, companyName, isInternalMode, userId, userType]);

  const pathname = window.location.pathname;

  const trackAppcuesOnPathChange = useCallback(() => {
    trackAppcues(companyId, companyName, isInternalMode, userId);
  }, [companyId, companyName, isInternalMode, userId]);

  useEffect(() => {
    if (window.Appcues && companyName) {
      trackAppcuesOnPathChange();
    }
  }, [companyName, pathname, trackAppcuesOnPathChange]);
};
