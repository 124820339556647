import React from 'react';

const DoorwayCheckboxInput = ({ label, settingName = null, id, checked, onChange }) => (
    <div className='doorway-property-setting-item'>
        <label className='property-info-edit-field-label' htmlFor={id}>{label}</label>
        <input className='property-info-edit-field-input'
            type="checkbox"
            id={id}
            name={settingName}
            checked={checked}
            onChange={onChange}
        />
    </div>
);

export default DoorwayCheckboxInput;
