import React, { Component } from 'react';
import PricingAPI from './PricingAPI';
import PropertiesAPI from '../Properties/PropertiesAPI';
import PropertyPricing from './PropertyPricing';
import { PageContainer, Toaster, Alert } from '@knockrentals/knock-react';

class PricingPage extends Component {
  state = {
    isLoadingPricingData: true,
    isLoadingCommunityData: true,
    properties: [],
    pricingOptions: {
      yardi: [],
      realpage: [],
      resman: [],
      amsi: [],
      mri: [],
      entrata: [],
    },
    residentImportTypes: {
      yardi: [],
    },
    selectedPropertyId: 'all',
    leasingTeamByPropertyId: {},
  };

  componentDidMount() {
    // Map property id to leasing team id, in order to pass leasing team id
    // to the correct PropertyPricing component, and initialize feature flags
    PropertiesAPI.getCommunityInfo()
      .then((response) => {
        const { properties = [] } = response || {};

        const leasingTeamByPropertyId = properties.reduce(
          (leasingTeamMap, property) => {
            const { id: propertyId, leasing_team_id } = property;
            leasingTeamMap[propertyId] = leasing_team_id;
            return leasingTeamMap;
          },
          {}
        );

        this.setState({ leasingTeamByPropertyId });
      })
      .catch((e) =>
        console.log(
          `Error fetching community info for feature flag initialization: ${e}`
        )
      )
      .finally(() => this.setState({ isLoadingCommunityData: false }));

    PricingAPI.getPropertiesPricing().then((response) => {
      const sortedProperties = response.pricing_data.sort(
        (propertyA, propertyB) => {
          return propertyA.property_name > propertyB.property_name ? 1 : -1;
        }
      );

      this.setState({
        properties: sortedProperties,
        pricingOptions: {
          realpage: response.realpage_rent_options,
          yardi: response.yardi_rent_options,
          resman: response.resman_rent_options,
          mri: response.mri_rent_options,
          entrata: response.entrata_rent_options,
        },
        residentImportTypes: {
          yardi: response.yardi_resident_import_types,
        },
        isLoadingPricingData: false,
        selectedPropertyId:
          sortedProperties && sortedProperties.length
            ? sortedProperties[0].property_id
            : 'all',
      });
    });
  }

  render() {
    return (
      <PageContainer
        className="pricing-page-container"
        isLoading={
          this.state.isLoadingPricingData || this.state.isLoadingCommunityData
        }
      >
        <h1>Pricing</h1>

        <div className="knock-react-flex knock-react-flex-row">
          <div>
            <div>Property</div>
            <select
              value={this.state.selectedPropertyId}
              onChange={this.onPropertyChanged.bind(this)}
            >
              <option value={'all'}>All</option>
              {this.renderPropertyOptions()}
            </select>
          </div>
        </div>

        {this.renderProperties()}
      </PageContainer>
    );
  }

  onPropertyChanged(event) {
    this.setState({
      selectedPropertyId:
        event.target.value === 'all'
          ? event.target.value
          : parseInt(event.target.value, 10),
    });
  }

  renderPropertyOptions() {
    return this.state.properties.map((property) => {
      return (
        <option
          key={'property-' + property.property_id}
          value={property.property_id}
        >
          {property.property_name}
        </option>
      );
    });
  }

  renderProperties() {
    if (this.state.properties.length === 0) {
      return <Alert>No properties to display.</Alert>;
    }

    if (this.state.selectedPropertyId === 'all') {
      return this.state.properties.map((property) => {
        return (
          !this.state.isLoadingPricingData && (
            <PropertyPricing
              key={property.property_id}
              property={property}
              pricingOptions={this.state.pricingOptions}
              residentImportTypes={this.state.residentImportTypes}
              onPreferencesChanged={this.onPreferencesChanged.bind(this)}
              onDataRetrieved={this.onDataRetrieved.bind(this)}
              leasingTeamId={
                this.state.leasingTeamByPropertyId[property.property_id] || null
              }
            />
          )
        );
      });
    }

    const selectedProperty = this.state.properties.find(
      (property) => property.property_id === this.state.selectedPropertyId
    );

    return (
      !this.state.isLoadingPricingData && (
        <PropertyPricing
          key={selectedProperty.property_id}
          property={selectedProperty}
          pricingOptions={this.state.pricingOptions}
          residentImportTypes={this.state.residentImportTypes}
          onPreferencesChanged={this.onPreferencesChanged.bind(this)}
          onDataRetrieved={this.onDataRetrieved.bind(this)}
          leasingTeamId={
            this.state.leasingTeamByPropertyId[this.state.selectedPropertyId] ||
            null
          }
        />
      )
    );
  }

  onDataRetrieved(propertyId, propertyData, callback = () => {}) {
    const updatedProperties = this.state.properties.slice();
    const updatedPropertyIndex = updatedProperties.findIndex(
      (property) => property.property_id === propertyId
    );

    if (!propertyData) {
      propertyData = {
        ...updatedProperties[updatedPropertyIndex],
        missingIntegration: true,
      };
    }

    updatedProperties[updatedPropertyIndex] = propertyData;

    return this.setState({ properties: updatedProperties }, callback);
  }

  onPreferencesChanged(propertyId, vendorPropertyId, updatedPreferences) {
    if (updatedPreferences.default_preferred_property_floorplan_id === 'None') {
      updatedPreferences.default_preferred_property_floorplan_id = null;
    }
    if (updatedPreferences.default_preferred_layout_id === 'None') {
      updatedPreferences.default_preferred_layout_id = null;
    }

    const updatedProperties = this.state.properties.slice();

    updatedProperties
      .find((property) => property.property_id === propertyId)
      .integrations.find(
        (integration) => integration.vendor_property_id === vendorPropertyId
      ).preferences = updatedPreferences;

    this.setState({ properties: updatedProperties }, () => {
      PricingAPI.updatePropertyPricing(
        propertyId,
        vendorPropertyId,
        updatedPreferences
      )
        .then(() => {
          Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        })
        .catch(() => {
          Toaster.showToast(
            'Error saving pricing settings.',
            2000,
            Toaster.ToastClasses.error
          );
        });
    });
  }
}

export default PricingPage;
