import React from 'react';
import PropTypes from 'prop-types';

import VirtualAgentSetupChatResponses from './VirtualAgentSetupChatResponses';

const VirtualAgentSetupShortcuts = ({ companyId }) => {
  return <VirtualAgentSetupChatResponses companyId={companyId} />;
};

VirtualAgentSetupShortcuts.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default VirtualAgentSetupShortcuts;
