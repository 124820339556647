import { useEffect, useState } from 'react';
import { NotificationService } from '../../../Components/Notifications';
import PropertiesAPI from '../../Properties/PropertiesAPI';
import { normalizePropertiesBenchmarkingAttributes } from './utils';
import { BENCHMARKING_NOTIFICATIONS } from './constants';
import { SAVED_NOTIFICATION } from '../../../constants';

export const useBenchmarkingAttributes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState([]);

  const getProperties = async () => {
    try {
      const propertiesResponse = await PropertiesAPI.getCommunityInfo();
      const propertyData =
        normalizePropertiesBenchmarkingAttributes(propertiesResponse);

      propertyData.sort((lValue, rValue) => {
        return lValue.propertyName >= rValue.propertyName ? 1 : -1;
      });

      setProperties(propertyData);
    } catch (e) {
      NotificationService.notify(BENCHMARKING_NOTIFICATIONS.ERROR_FETCHING);
    }
  };

  useEffect(() => {
    if (!properties.length) {
      setIsLoading(true);

      getProperties().finally(() => {
        setIsLoading(false);
      });
    }
  }, [properties.length]);

  const saveData = async (field, newValue, propertyId) => {
    const newPropertyData = [...properties];
    const index = newPropertyData.findIndex(
      (item) => item.propertyId === propertyId
    );
    const previousBenchmarkingData = newPropertyData[index].data.benchmarking;

    // The Autocomplete fields for the year values allow you to re-select the current value, and it will fire a change event in that case. We don't
    // want to make an unneeded request, so we exit if the value is unchanged.
    if (newValue === previousBenchmarkingData[field]) {
      return;
    }

    const newBenchmarkingData = { [field]: newValue };
    previousBenchmarkingData[field] = newValue;

    const payload = {
      data: {
        ...newPropertyData[index].data,
        benchmarking: {
          ...previousBenchmarkingData,
          ...newBenchmarkingData,
        },
      },
      preferences: newPropertyData[index].preferences,
      propertyCustomFees: newPropertyData[index].propertyCustomFees,
    };

    setProperties(newPropertyData);
    try {
      await PropertiesAPI.updateProperty(propertyId, payload);
      NotificationService.notify(SAVED_NOTIFICATION);
    } catch (error) {
      NotificationService.notify(
        BENCHMARKING_NOTIFICATIONS.ERROR_SAVING_SETTINGS
      );
    }
  };

  return { isLoading, properties, saveData };
};
