import React from 'react';
import { InputCheckbox } from '@knockrentals/knock-shared-web';

import { BrandColorIcon } from './BrandColorIcon';
import { LogoAvatar } from './LogoAvatar';
import { MultiSelectCheckbox, LIST } from '../../common/MultiSelectCheckbox';
import { StatusChip } from './StatusChip';

export const getMultiSelectCheckboxConfig = (propertySelectionMap) => {
  const selectionValues = Object.values(propertySelectionMap);
  const selectedCount = selectionValues.filter(
    (property) => property.isSelected
  ).length;

  const isChecked =
    selectedCount > 0 && selectedCount === selectionValues.length;
  const isIndeterminate = !isChecked && selectedCount > 0;

  return { isChecked, isIndeterminate };
};

export const getTableColumns = ({
  getCurrentPagePropertyCount,
  propertyCount,
  propertySelectionMap,
  selectAllOnPage,
  updateAllPropertiesSelections,
  updatePropertySelection,
}) => {
  const handlePropertyCheckboxOnChange = (propertyId) => (e) => {
    const isSelected = e.target.checked;
    updatePropertySelection(propertyId, isSelected);
  };

  const handleSelectProperties = (selectionType) => {
    selectionType === LIST
      ? updateAllPropertiesSelections(true)
      : selectAllOnPage();
  };

  const multiSelectCheckboxConfig =
    getMultiSelectCheckboxConfig(propertySelectionMap);

  return [
    {
      align: 'left',
      defaultSort: 'asc',
      field: 'propertyName',
      render: (rowData) => {
        const { propertyName, propertyId } = rowData || {};
        const { isSelected = false } = propertySelectionMap[propertyId] || {};
        return (
          <InputCheckbox
            checked={isSelected}
            className="property-select-checkbox"
            color="primary"
            label={propertyName}
            onChange={handlePropertyCheckboxOnChange(propertyId)}
          />
        );
      },
      title: (
        <MultiSelectCheckbox
          deselectAll={() => updateAllPropertiesSelections(false)}
          getPageItemCount={getCurrentPagePropertyCount}
          handleSelect={handleSelectProperties}
          isChecked={multiSelectCheckboxConfig.isChecked}
          isIndeterminate={multiSelectCheckboxConfig.isIndeterminate}
          label="Property"
          stopPropagation
          totalCount={propertyCount}
        />
      ),
      width: '392px',
    },
    {
      align: 'left',
      field: 'logoUrl',
      render: (rowData) => <LogoAvatar logoUrl={rowData.logoUrl} />,
      title: 'Logo',
      sorting: false,
      width: '72px',
    },
    {
      align: 'left',
      field: 'brandColor',
      render: (rowData) => (
        <BrandColorIcon brandColor={rowData.brandColor} fontSize="medium" />
      ),
      title: 'Brand Color',
      sorting: false,
      width: '122px',
    },
    {
      align: 'center',
      field: 'isHeaderEnabled',
      render: (rowData) => <StatusChip isEnabled={rowData.isHeaderEnabled} />,
      title: 'Header',
      sorting: false,
      width: '162px',
    },
    {
      align: 'center',
      field: 'isFooterEnabled',
      render: (rowData) => <StatusChip isEnabled={rowData.isFooterEnabled} />,
      title: 'Footer',
      sorting: false,
      width: '162px',
    },
  ];
};
