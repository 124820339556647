import React from 'react';
import {
  makeStyles,
  Box,
  Text,
  Alert,
} from '@knockrentals/knock-shared-web';


const useStyles = makeStyles({
  bannerContainer: {
    marginBottom: '24px',
    width: '500px'
  },
  messageTitle: {
    marginBottom: '4px',
    fontSize: '16px',
    letterSpacing: '0.2px',
    fontWeight: 600,
    lineHeight: '24px',
  },
  messageText: {
    paddingBottom: '6px'
  }
});

export const WarningBanner = ({ virtualAgent }) => {
  const classes = useStyles();

  return (
    <Box className={classes.bannerContainer}>
      <Alert severity="warning" className={classes.alert}>
        <Box className={classes.contentContainer}>
          <Text className={classes.messageTitle}>Settings Mixed Across Selected Properties</Text>
          {virtualAgent ?
            <Text className={classes.messageText} variant="body2">
              If the selected properties have different settings,
              changing the settings here will update ALL selected properties.
            </Text>
           : <Text className={classes.messageText} variant="body2">
                The selected properties have different settings for Transcription, Annotations and Standalone. 
                Changing the settings will update ALL selected properties.
            </Text>}
        </Box>
      </Alert>
    </Box>
  );
};