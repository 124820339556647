import { useEffect, useRef, useState } from 'react';
import { NotificationService } from '../../../../Components/Notifications';
import { companyQuickRepliesApi } from '../CompanyQuickRepliesApi';
import { QUICK_REPLY_NOTIFICATIONS } from './constants';

const getIsPropertyEnabledMap = (properties) =>
  properties.reduce((isEnabledMap, property) => {
    isEnabledMap[property.propertyId] = property.isEnabled;
    return isEnabledMap;
  }, {});

const getUpdatedProperties = (properties, isEnabledPropertyMap) =>
  properties.reduce((updatedProperties, property) => {
    const { propertyId } = property;
    const isEnabled = isEnabledPropertyMap[propertyId];
    if (isEnabled !== property.isEnabled) {
      updatedProperties.push({ propertyId, isEnabled });
    }
    return updatedProperties;
  }, []);

const getEditedQuickReply = (quickReply, originalReply) =>
  Object.entries(quickReply).reduce(
    (updatedReply, [quickReplyKey, currentValue]) => {
      if (currentValue !== originalReply[quickReplyKey]) {
        updatedReply[quickReplyKey] = currentValue;
      }
      return updatedReply;
    },
    {}
  );

const useQuickReplies = ({ closeDrawer, quickReplyId, resetTableData }) => {
  const isEditing = Boolean(quickReplyId);

  const [errorMessage, setErrorMessage] = useState('');
  const [isEnabledPropertyMap, setIsEnabledPropertyMap] = useState({});
  const [isLoadingOverlayVisible, setIsLoadingOverlayVisible] =
    useState(isEditing);
  const [quickReply, setQuickReply] = useState({
    title: '',
    email: '',
    sms: '',
  });

  const propertiesRef = useRef([]);
  const quickReplyRef = useRef({});

  useEffect(() => {
    const fetchQuickReply = async () => {
      try {
        const responseData = await companyQuickRepliesApi.getCompanyQuickReply(
          quickReplyId
        );

        const { properties, ...quickReply } = responseData;

        quickReplyRef.current = quickReply;
        setQuickReply(quickReply);

        propertiesRef.current = properties;
        setIsEnabledPropertyMap(getIsPropertyEnabledMap(properties));
      } catch (error) {
        setErrorMessage(error.message);
      }
      setIsLoadingOverlayVisible(false);
    };

    if (quickReplyId) {
      fetchQuickReply();
    }
  }, [quickReplyId]);

  const hasChanges = () => {
    const { email = '', sms = '', title = '' } = quickReplyRef.current;
    return (
      quickReply.email !== email ||
      quickReply.sms !== sms ||
      quickReply.title !== title ||
      propertiesRef.current.some(
        (property) =>
          property.isEnabled !== isEnabledPropertyMap[property.propertyId]
      )
    );
  };

  const getIsSaveButtonDisabled = () => !hasChanges();

  const updateQuickReply = (key) => (value) => {
    setQuickReply((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const deleteQuickReply = async () => {
    setIsLoadingOverlayVisible(true);
    try {
      await companyQuickRepliesApi.deleteCompanyQuickReply(quickReplyId);
      resetTableData();
      closeDrawer();
      NotificationService.notify(QUICK_REPLY_NOTIFICATIONS.DELETED);
    } catch (error) {
      setErrorMessage(error.message);
    }
    setIsLoadingOverlayVisible(false);
  };

  const saveNewQuickReply = async () => {
    try {
      await companyQuickRepliesApi.createCompanyQuickReply(quickReply);
      resetTableData();
      NotificationService.notify(QUICK_REPLY_NOTIFICATIONS.CREATED);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const saveEditedQuickReply = async () => {
    const updatedProperties = getUpdatedProperties(
      propertiesRef.current,
      isEnabledPropertyMap
    );

    const editedQuickReply = getEditedQuickReply(
      quickReply,
      quickReplyRef.current
    );
    try {
      await companyQuickRepliesApi.updateCompanyQuickReply(quickReplyId, {
        quickReply: editedQuickReply,
        properties: updatedProperties,
      });
      resetTableData();
      NotificationService.notify(QUICK_REPLY_NOTIFICATIONS.SAVED);
    } catch (error) {
      setErrorMessage(error.message); 
    }
  };

  const saveQuickReply = async () => {
    setIsLoadingOverlayVisible(true);

    const save = isEditing ? saveEditedQuickReply : saveNewQuickReply;

    try {
      await save();
      closeDrawer();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const { title, email, sms } = quickReply;

  return {
    apiErrorMessage: errorMessage,
    email,
    deleteQuickReply,
    isEditing,
    isEnabledPropertyMap,
    isLoadingOverlayVisible,
    isSaveButtonDisabled: getIsSaveButtonDisabled(),
    properties: propertiesRef.current,
    saveQuickReply,
    sms,
    setApiErrorMessage: setErrorMessage,
    setIsEnabledPropertyMap,
    title,
    updateQuickReply,
  };
};

export default useQuickReplies;
