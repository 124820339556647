import React from 'react';

import { AllPropertyCheckbox } from './AllPropertyCheckbox';
import { PropertyCheckbox } from './PropertyCheckbox';

export const getColumns = ({
  enabledCountDisplay,
  isAllChecked,
  isEnabledPropertyMap,
  isIndeterminate,
  updateIsEnabledPropertyMap,
  updateAllPropertiesIsEnabled,
}) => [
  {
    align: 'left',
    field: 'enabled',
    title: (
      <AllPropertyCheckbox
        isChecked={isAllChecked}
        updateAllPropertiesIsEnabled={updateAllPropertiesIsEnabled}
        isIndeterminate={isIndeterminate}
      />
    ),
    render: (rowData) => {
      const { propertyId } = rowData;
      return (
        <PropertyCheckbox
          isChecked={isEnabledPropertyMap[propertyId]}
          updateIsEnabled={updateIsEnabledPropertyMap(propertyId)}
        />
      );
    },
    minWidth: '20px',
    maxWidth: '20px',
  },
  {
    align: 'left',
    field: 'propertyName',
    title: `Property Name ${enabledCountDisplay}`,
    width: '200px',
  },
  {
    align: 'left',
    field: 'leasingTeamName',
    title: 'Leasing Team',
    width: '200px',
  },
];
