import React from 'react';
import PropTypes from 'prop-types';

import { InputCheckbox } from '@knockrentals/knock-shared-web';

const PropertyCheckbox = ({
  propertyId,
  propertyName,
  isPropertySelected = false,
  updatePropertyIsChecked,
}) => {
  const handleOnChange = (e) =>
    updatePropertyIsChecked(propertyId, e.target.checked);

  return (
    <InputCheckbox
      checked={isPropertySelected}
      className="property-select-checkbox"
      color="primary"
      label={propertyName}
      onChange={handleOnChange}
      inputProps={{ 'aria-label': 'selected property checkbox' }}
    />
  );
};

PropertyCheckbox.propTypes = {
  propertyId: PropTypes.number,
  propertyName: PropTypes.string,
  isPropertySelected: PropTypes.bool,
  updatePropertyIsChecked: PropTypes.func,
};

export default PropertyCheckbox;
