import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Card,
  CardHeader,
  CloseIcon,
  IconButton,
  makeStyles,
} from '@knockrentals/knock-shared-web';

const useStyles = makeStyles(() => ({
  companyTemplateCard: {
    display: 'flex',
    flexFlow: 'column',
    borderRadius: 0,
    overflow: 'hidden',
    height: '100vh',
  },
  addPropertyHeader: {
    zIndex: 1,
    boxShadow:
      '0px 2px 12px -4px rgba(24, 39, 75, 0.04), 0px 4px 16px -2px rgba(24, 39, 75, 0.02)',
  },
}));

const CompanyTemplateCard = ({ children, closeDrawer, subheader, title }) => {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.companyTemplateCard}>
      <CardHeader
        action={
          <IconButton onClick={closeDrawer} aria-label="close">
            <CloseIcon />
          </IconButton>
        }
        className={classes.companyTemplateHeader}
        subheader={subheader}
        subheaderTypographyProps={{ color: 'textPrimary', variant: 'h6' }}
        title={title}
        titleTypographyProps={{ color: 'textPrimary', variant: 'body2' }}
      />

      {children}
    </Card>
  );
};

CompanyTemplateCard.propTypes = {
  children: PropTypes.node.isRequired,
  closeDrawer: PropTypes.func,
  subheader: PropTypes.string,
  title: PropTypes.string,
};

export default CompanyTemplateCard;
