import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@knockrentals/knock-shared-web';

const htmlParser = new DOMParser();

const parseHtmlString = (htmlString) => {
  const htmlDocument = htmlParser.parseFromString(htmlString, 'text/html');
  const errorNode = htmlDocument.querySelector('parsererror');

  return errorNode ? '' : htmlDocument.body.firstChild.textContent.trim();
};

const QuickReplyMessage = ({ email, sms }) => {
  const emailText = email ? parseHtmlString(email) : '';
  const displayText = emailText || sms;

  return <Text noWrap>{displayText}</Text>;
};

QuickReplyMessage.propTypes = {
  email: PropTypes.string,
  sms: PropTypes.string,
};

export default QuickReplyMessage;
