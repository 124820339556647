import React from 'react';
import PropTypes from 'prop-types';
import PropertySources from './PropertySources';
import { PropertyPhoneRegistration } from './PhoneRegistration';
import { usePropertyPhoneRegistration } from './PhoneRegistration/PropertyPhoneRegistration/use-property-phone-registration';

export const WrappedPropertySources = (props) => {
  const property = props.property;
  const propertyName = property.data.location.name;
  const propertyId = property.id;

  const {
    errorMessage,
    fetchPropertyPhoneRegistration,
    registrationStats,
    relayPhoneMap,
  } = usePropertyPhoneRegistration({
    propertyId,
    propertyName,
  });

  return (
    <PropertySources
      PropertyPhoneRegistration={() => (
        <PropertyPhoneRegistration
          errorMessage={errorMessage}
          fetchPropertyPhoneRegistration={fetchPropertyPhoneRegistration}
          registrationStats={registrationStats}
        />
      )}
      propertyPhoneRegistrationRelayMap={relayPhoneMap}
      {...props}
    />
  );
};

WrappedPropertySources.propTypes = {
  property: PropTypes.shape({
    data: PropTypes.shape({
      location: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    id: PropTypes.number,
  }),
};
