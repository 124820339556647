import { useState, useEffect, useRef, useCallback } from 'react';
import { AuthenticationService } from '@knockrentals/knock-react';
import { companyTemplatesApi } from '../CompanyTemplatesApi';
import {
  getIsEveryPropertyAssignable,
  getModifiedAssignableProperties,
  getModifiedProperties,
  getModifiedTemplateData,
  getSelectedPropertyIds,
  getTablePropertiesMap,
  getUpdatedMap,
  hasAssignablePropertiesChanged,
} from './utils';
import { NotificationService } from '../../../../Components/Notifications';
import { ADMIN_USER_ROLES } from '../../../../constants';
import { TEMPLATE_MESSAGE_TYPES, TEMPLATE_NOTIFICATIONS } from '../constants';

const useCompanyTemplate = ({
  closeConfirmationDialog,
  closeDrawer,
  fetchCompanyTemplates,
  isDefault,
  templateId,
  templateType,
}) => {
  const isEditing = Boolean(templateId);
  const templateRef = useRef({});
  const [assignablePropertiesMap, setAssignablePropertiesMap] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [template, setTemplate] = useState({
    message: '',
    templateName: '',
  });
  const [properties, setProperties] = useState([]);
  const [tablePropertiesMap, setTablePropertiesMap] = useState({});

  const shouldFetchAllProperties = !isEditing && Boolean(templateType);

  const updateTemplate = (templateUpdate) => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      ...templateUpdate,
    }));
  };

  const fetchProperties = useCallback(async () => {
    try {
      const properties = await companyTemplatesApi.getPropertiesForTemplateType(
        templateType,
        isDefault
      );
      return properties || [];
    } catch (error) {
      setErrorMessage(error.message);
      return [];
    }
  }, [isDefault, templateType]);

  useEffect(() => {
    const fetchCompanyTemplate = async () => {
      try {
        const template = await companyTemplatesApi.getCompanyTemplateById(
          templateId,
          isDefault
        );

        setProperties(template.assignedProperties);
        setTablePropertiesMap(
          getTablePropertiesMap(template.assignedProperties)
        );

        setAssignablePropertiesMap(
          getTablePropertiesMap(template.assignableProperties)
        );

        templateRef.current = template;
        setTemplate(template);
      } catch (error) {
        setErrorMessage(error.message);
      }
      setIsLoading(false);
    };

    if (templateId) {
      fetchCompanyTemplate();
    }
  }, [isDefault, fetchProperties, templateId]);

  useEffect(() => {
    const getProperties = async () => {
      const properties = await fetchProperties();
      setProperties(properties);
      setTablePropertiesMap(getTablePropertiesMap(properties));

      const assignableProperties = properties.map((property) => ({
        ...property,
        isSelected: true,
      }));

      updateTemplate({ assignableProperties, isEveryPropertyAssignable: true });
      setAssignablePropertiesMap(getTablePropertiesMap(assignableProperties));

      setIsLoading(false);
    };

    if (shouldFetchAllProperties) {
      getProperties();
    }
  }, [fetchProperties, shouldFetchAllProperties]);

  const setAssignableProperties = async () => {
    const properties = await fetchProperties();
    const assignableProperties = properties.map((property) => ({
      ...property,
      isSelected: true,
    }));
    updateTemplate({ assignableProperties });
    setAssignablePropertiesMap(getTablePropertiesMap(assignableProperties));
  };

  const handleAllAssignableChange = (isSelected) => {
    if (isSelected) {
      setAssignablePropertiesMap(
        getUpdatedMap(assignablePropertiesMap, isSelected)
      );
      return;
    }

    if (!template.assignableProperties.length) {
      setAssignableProperties();
    }
  };

  const hasAnyPropertySelectedOrEnabled = () =>
    properties.some((property) => {
      const { isSelected, isEnabled } =
        tablePropertiesMap[property.propertyId] || {};
      return (!isEditing && isSelected) || isEnabled !== property.isEnabled;
    });

  const hasChanges = () => {
    if (isDefault) {
      return hasAnyPropertySelectedOrEnabled();
    }

    const { templateName = '', message = '' } = templateRef.current;

    return (
      template.templateName !== templateName ||
      template.message !== message ||
      hasAnyPropertySelectedOrEnabled() ||
      hasAssignablePropertiesChanged({
        assignablePropertiesMap,
        isEditing,
        isEveryPropertyAssignable: getIsEveryPropertyAssignable(
          template.assignableProperties,
          assignablePropertiesMap
        ),
        originalTemplate: templateRef.current,
      })
    );
  };

  const getIsSaveButtonDisabled = () => !hasChanges();

  const addProperties = (properties) => {
    const addedProperties = properties.reduce((propertyMap, { propertyId }) => {
      propertyMap[propertyId] = {
        propertyId,
        isSelected: false,
        isEnabled: true,
        isAdded: true,
      };
      return propertyMap;
    }, {});

    setTablePropertiesMap((prevState) => ({
      ...prevState,
      ...addedProperties,
    }));

    setProperties((prevState) => [...prevState, ...properties]);
  };

  const getTemplateUpdates = () => {
    const originalTemplate = templateRef.current;

    const updatedProperties = getModifiedProperties(
      tablePropertiesMap,
      originalTemplate.assignedProperties
    );

    if (isDefault) {
      return { properties: updatedProperties };
    }

    const templateUpdate = getModifiedTemplateData(template, originalTemplate);

    const modifiedAssignableProperties = getModifiedAssignableProperties({
      assignableProperties: template.assignableProperties,
      assignablePropertiesMap,
      originalTemplate,
    });

    return {
      ...templateUpdate,
      ...modifiedAssignableProperties,
      properties: updatedProperties,
    };
  };

  const updateCompanyTemplate = async () => {
    setIsLoading(true);
    const templateUpdates = getTemplateUpdates();
    const { isDefault, templateId } = template;

    try {
      await companyTemplatesApi.updateCompanyTemplate(templateId, {
        ...templateUpdates,
        isDefault,
      });
      closeDrawer();
      NotificationService.notify(TEMPLATE_NOTIFICATIONS['UPDATED']);
    } catch (error) {
      closeConfirmationDialog();
      setErrorMessage(error.message);
    }
    fetchCompanyTemplates();
  };

  const createCompanyTemplate = async () => {
    setIsLoading(true);
    const { assignableProperties, message, subject, templateName } = template;

    const isEveryPropertyAssignable = getIsEveryPropertyAssignable(
      assignableProperties,
      assignablePropertiesMap
    );

    const assignablePropertyIds = isEveryPropertyAssignable
      ? []
      : getSelectedPropertyIds(assignablePropertiesMap);

    try {
      const payload = {
        message,
        propertyIds: getSelectedPropertyIds(tablePropertiesMap),
        templateName,
        templateType,
        isEveryPropertyAssignable,
        assignablePropertyIds,
      };

      if (subject) {
        payload.subject = subject;
      }

      await companyTemplatesApi.createCompanyTemplate(payload);
      closeDrawer();
      NotificationService.notify(TEMPLATE_NOTIFICATIONS['CREATED']);
    } catch (error) {
      closeConfirmationDialog();
      setErrorMessage(error.message);
    }
    fetchCompanyTemplates();
  };

  const deleteCompanyTemplate = async () => {
    setIsLoading(true);
    try {
      await companyTemplatesApi.deleteCompanyTemplate(template.templateId);
      closeDrawer();
      NotificationService.notify(TEMPLATE_NOTIFICATIONS['DELETED']);
    } catch (error) {
      closeConfirmationDialog();
      setErrorMessage(error.message);
    }
    fetchCompanyTemplates();
  };

  const saveCompanyTemplate = isEditing
    ? updateCompanyTemplate
    : createCompanyTemplate;

  const isTemplateDeletableByUser =
    isEditing &&
    !isDefault &&
    (AuthenticationService.getRole() === ADMIN_USER_ROLES.MASTER ||
      template.createdByUserRole === ADMIN_USER_ROLES.ADMIN);

  const isAssignablePropertiesVisible =
    !isLoading &&
    AuthenticationService.getRole() === ADMIN_USER_ROLES.MASTER &&
    !isDefault;

  return {
    addProperties,
    assignablePropertiesMap,
    deleteCompanyTemplate,
    errorMessage,
    isAssignablePropertiesVisible,
    isEditing,
    isLoading,
    isSaveButtonDisabled: getIsSaveButtonDisabled(),
    isTemplateDeletableByUser,
    handleAllAssignableChange,
    hasAnyPropertySelectedOrEnabled,
    properties,
    saveCompanyTemplate,
    setAssignablePropertiesMap,
    setErrorMessage,
    setTablePropertiesMap,
    template,
    templateMessageType: TEMPLATE_MESSAGE_TYPES[templateType],
    tablePropertiesMap,
    updateTemplate,
  };
};

export default useCompanyTemplate;
