import React from 'react';
import { Tooltip } from '@knockrentals/knock-shared-web';

const PropertyInfoCheckboxInput = ({
  label,
  checked,
  onChange,
  isDisabled,
  title = '',
}) => (
  <div className="property-info-edit-custom-item">
    <label>{label}</label>
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={isDisabled}
    />
    {title && (
      <Tooltip title={title}>
        <i className="fa-regular fa-question-circle tooltip"></i>
      </Tooltip>
    )}
  </div>
);

export default PropertyInfoCheckboxInput;
