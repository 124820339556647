import React from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Text } from '@knockrentals/knock-shared-web';
import { PREVIEW_SMS } from '../constants';

const useStyles = makeStyles({
  smsTextContainer: {
    backgroundColor: '#F8F8F8',
    marginTop: '25px',
    padding: '20px',
    borderRadius: '20px',
    maxWidth: '70%',
    position: 'relative',
    wordWrap: 'break-word',
  },
  smsText: {
    whiteSpace: 'pre-line',
  },
});

const PreviewSmsTemplate = ({ message }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Text variant="subtitle1">{PREVIEW_SMS}</Text>
      <Box className={classes.smsTextContainer}>
        <Text
          className={classes.smsText}
          color="secondary"
          variant="subtitle2"
          aria-label="Sms Preview Text"
        >
          {message}
        </Text>
      </Box>
    </React.Fragment>
  );
};

PreviewSmsTemplate.propTypes = {
  message: PropTypes.string,
};

export default PreviewSmsTemplate;
