import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import DataTable from 'material-table';
import { Paper } from '@knockrentals/knock-shared-web';

import { getColumns } from './table-columns';
import { getTableOptions } from './table-options';
import { Pagination } from '../../../../common/Pagination';
import './_CampaignDataTable.scss';

const CampaignDataTable = ({
  isOriginCreateCampaign,
  getTableData,
  rowsPerPage,
  rowsPerPageOptions,
  setCampaign,
  setPageIndex,
  setShouldLoadPageIndex,
  tableRef,
}) => {
  const tableColumns = getColumns({
    setCampaign,
    isOriginCreateCampaign,
  });

  useEffect(() => {
    if (!isOriginCreateCampaign) setShouldLoadPageIndex(true);
  }, [isOriginCreateCampaign, setShouldLoadPageIndex]);

  const handleOnPageChange = (index) => {
    setPageIndex(index);
  };

  return (
    <div className="drip-campaigns-data-table">
      <DataTable
        columns={tableColumns}
        components={{
          Container: (props) => (
            <Paper {...props} elevation={4} className="data-table-paper" />
          ),
          Pagination: Pagination,
        }}
        data={getTableData}
        onChangePage={handleOnPageChange}
        options={getTableOptions(
          rowsPerPage,
          rowsPerPageOptions,
          isOriginCreateCampaign
        )}
        tableRef={tableRef}
      />
    </div>
  );
};

CampaignDataTable.propTypes = {
  isOriginCreateCampaign: PropTypes.bool,
  getTableData: PropTypes.func,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  setCampaign: PropTypes.func,
  setPageIndex: PropTypes.func,
  setShouldLoadPageIndex: PropTypes.func,
  tableRef: PropTypes.shape({ current: PropTypes.object }),
};

export default CampaignDataTable;
