import React from 'react';
import PropTypes from 'prop-types';
import { ListItemSecondaryAction } from '@material-ui/core';
import { ListItem, makeStyles } from '@knockrentals/knock-shared-web';

import TemplateDetails from './TemplateDetails';
import { EditButton } from '../../../../common/EditButton';

const useStyles = makeStyles({
  templateListItem: {
    wordBreak: 'break-word',
    boxShadow:
      '0px 2px 12px -4px rgba(24, 39, 75, 0.04), 0px 4px 16px -2px rgba(24, 39, 75, 0.02)',
  },
});

const AiTemplates = ({
  intentTemplates,
  setAiTemplate,
  templates,
  templateType,
}) => {
  const classes = useStyles();
  return templates.map(
    ({ assignedPropertiesCount, templateId, templateName }) => (
      <ListItem
        dense
        divider
        className={classes.templateListItem}
        key={templateName}
      >
        <TemplateDetails
          assignedPropertiesCount={assignedPropertiesCount}
          templateName={templateName}
        />

        <ListItemSecondaryAction>
          <EditButton
            onClick={() =>
              setAiTemplate({ templateId, templateType, intentTemplates })
            }
          />
        </ListItemSecondaryAction>
      </ListItem>
    )
  );
};

AiTemplates.propTypes = {
  intentTemplates: PropTypes.shape({
    intentId: PropTypes.number,
    templateCount: PropTypes.number,
    templates: PropTypes.arrayOf(
      PropTypes.shape({
        assignedPropertiesCount: PropTypes.number,
        templateId: PropTypes.number,
        templateName: PropTypes.string,
        properties: PropTypes.arrayOf(
          PropTypes.shape({
            property_id: PropTypes.number,
            property_name: PropTypes.string,
          })
        ),
      })
    ),
  }),
  setAiTemplate: PropTypes.func,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      assignedPropertiesCount: PropTypes.number,
      templateId: PropTypes.number,
      templateName: PropTypes.string,
    })
  ),
  templateType: PropTypes.string,
};

export default AiTemplates;
