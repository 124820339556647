import React from 'react';

export const CloudLoadingImage = () => {
  return (
    <svg
      width="354"
      height="354"
      viewBox="0 0 354 354"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_6757_48378)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M227.753 13.8143C175.614 -15.5025 110.256 3.37036 81.7711 55.9681C71.3779 75.1592 67.3296 96.1884 68.9123 116.584C46.8392 123.395 27.3124 138.572 15.3477 160.665C-9.15766 205.915 7.3386 263.042 52.1931 288.263L133.74 334.116C178.594 359.337 234.821 343.101 259.327 297.851C263.061 290.955 265.844 283.783 267.719 276.49C296.398 282.972 326.88 270.039 341.661 242.746C359.673 209.486 347.548 167.494 314.578 148.956L286.709 133.285C295.315 88.0536 274.707 40.2161 232.616 16.5491L227.753 13.8143Z"
          fill="white"
        />
      </g>
      <path
        d="M140.915 127.809L151.205 141.967M118.667 158.434L135.309 163.842M118.667 196.287L135.309 190.879M140.915 226.912L151.205 212.754M176.919 238.607V221.107M212.922 226.912L202.632 212.754M235.17 196.287L218.528 190.879M235.17 158.434L218.528 163.842M212.922 127.809L202.632 141.967M176.919 107.357V142.357"
        stroke="#5C5C5C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_6757_48378"
          x="0"
          y="0"
          width="354.002"
          height="354"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6757_48378"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6757_48378"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
