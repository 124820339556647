import { useEffect, useState } from 'react';
import PropertiesAPI from '../../Properties/PropertiesAPI';


export const useProperties = ({ setErrorMessage }) => {
  const [properties, setProperties] = useState([]);
  const [selectedPropertiesMap, setSelectedPropertiesMap] = useState({});

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const propertiesResponse = await PropertiesAPI.getCommunityInfo();
        const properties = propertiesResponse.properties.map((property) => {
          return {
            propertyId: property.id,
            propertyName: property.data.location.name,
          };
        });
        setProperties(properties);
      } catch (e) {
        setErrorMessage('There was an error fetching the properties. Please try again later.');
      }
    };
    fetchProperties();
  }, [setErrorMessage]);

  const getSelectedProperties = () =>
    properties
      .filter((property) => selectedPropertiesMap[property.propertyId])
      .sort((propertyA, propertyB) =>
        propertyA.propertyName > propertyB.propertyName ? 1 : -1,
      );

  const propertiesList = properties;

  const getSelectedPropertyCount = () => {
    return Object.values(selectedPropertiesMap).filter(
      (isSelected) => isSelected,
    ).length;
  };

  return {
    getSelectedProperties,
    propertiesList,
    selectedPropertiesCount: getSelectedPropertyCount(),
    selectedPropertiesMap,
    setSelectedPropertiesMap,
  };
};
