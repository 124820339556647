import { mlRemote } from '../../Services/RemoteService';

export const getIsAiEmailEnabled = async (ids) => {
  try {
    const queryParams = ids.map(id => {
      return `property_id=${id}`;
    });

    const response = await mlRemote.get(
      `ai-email/properties?${queryParams.join('&')}`
    );

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }
    const rows = await response.json();

    return rows;
  } catch (error) {
    throw new Error(
      'We are unable to fetch the ai enabled config at this time.'
    );
  }
};

export const editAiEmailEnabled = async (payload) => {
  try {
    const response = await mlRemote.put('ai-email/properties', payload);

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }
    const  [{ ai_email_enabled, gen_ai_enabled }] = await response.json();

    return { aiEmailEnabled: ai_email_enabled, aiEmailGenAiAdminEnabled: gen_ai_enabled };
  } catch (error) {
    throw new Error(
      'We are unable to save the ai email enabled at this time.'
    );
  }
};

export const editGenAiEmailEnabled = async (payload) => {
  try {
    const response = await mlRemote.patch('ai-email/properties', payload);

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }
    const [{ gen_ai_enabled }] = await response.json();

    return gen_ai_enabled;
  } catch (error) {
    throw new Error(
      'We are unable to save the gen ai email enabled at this time.'
    );
  }
};