import React from 'react';
import PropTypes from 'prop-types';

import { Button, makeStyles } from '@knockrentals/knock-shared-web';
import { ArchiveSvgIcon, DeleteSvgIcon } from '../../../../Components/Icons';
import { STATUS } from '../constants';

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.text.primary,
  },
  errorDark: {
    color: theme.palette.error.dark,
  },
}));

const getIcon = (status) =>
  status === STATUS.LIVE ? (
    <ArchiveSvgIcon fontSize="small" />
  ) : (
    <DeleteSvgIcon fontSize="small" />
  );

const ActionIconButton = ({ buttonText, handleOnClick, status }) => {
  const classes = useStyles();
  return (
    <Button
      className={
        status !== STATUS.LIVE ? classes.errorDark : classes.textPrimary
      }
      startIcon={getIcon(status)}
      variant="text"
      onClick={handleOnClick}
    >
      {buttonText}
    </Button>
  );
};

ActionIconButton.propTypes = {
  buttonText: PropTypes.string,
  handleOnClick: PropTypes.func,
  status: PropTypes.string,
};

export default ActionIconButton;
