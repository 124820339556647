import React, { useState, useEffect } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

import { NearbyCommunities } from './constants';

const useStyles = makeStyles(() => ({
  root: {
    width: 750,
  },
}));

const RadiusSlider = withStyles({
  root: {
    color: '#697FF7',
    height: 2,
  },
})(Slider);

export const RadiusSelect = ({ radius, onRadiusChange, onRadiusReset }) => {
  const classes = useStyles();
  const [sliderValue, setSliderValue] = useState(radius);

  useEffect(() => {
    setSliderValue(radius);
  }, [radius]);

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleResetRadius = () => {
    onRadiusReset();
  };

  const handleChangeCommitted = () => {
    onRadiusChange(sliderValue);
  };

  const renderRadiusLabelText = (value) => {
    const radiusDecorator = value === NearbyCommunities.MAX_RADIUS ? '+' : '';
    return `${value}${radiusDecorator} mi`;
  };

  return (
    <div className={classes.root}>
      <Grid container direction="row" spacing={2}>
        <Grid container item direction="column" alignItems="center" xs={6}>
          <RadiusSlider
            value={typeof sliderValue === 'number' ? sliderValue : 0}
            onChangeCommitted={handleChangeCommitted}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            min={NearbyCommunities.MIN_RADIUS}
            max={NearbyCommunities.MAX_RADIUS}
            step={5}
            marks
            testid="input-slider"
          />
          <Typography data-testid="radius-label" variant="body2" gutterBottom>
            {renderRadiusLabelText(sliderValue)}
          </Typography>
        </Grid>
        <Grid item>
          <Button color="primary" onClick={handleResetRadius}>
            Reset
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default RadiusSelect;
