import React from 'react';

import { Text, ThemeProvider } from '@knockrentals/knock-shared-web';
import AiTemplates from './AiTemplates';
import './_AiTemplatesPage.scss';

const DESCRIPTION = 'Manage your AI Email response messaging';

const AiTemplatesPage = () => (
  <ThemeProvider>
    <section className="ai-templates-page">
      <Text variant="h6">AI Email Templates</Text>

      <div className="ai-templates-page-description">
        <Text variant="body1">{DESCRIPTION}</Text>
      </div>
    </section>

    <AiTemplates />
  </ThemeProvider>
);

export default AiTemplatesPage;
