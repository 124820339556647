import { DEFAULT_TEXT_COLORS } from '../constants';

const BRIGHTNESS_MIDPOINT = 128;

export const isValidRGB = (rgbValues) =>
  rgbValues.length === 3 &&
  rgbValues.every((value) => !isNaN(value) && value >= 0 && value <= 255);

export const getRGBValues = (color) => {
  try {
    if (color[0] === '#' && color.length === 7) {
      const redHex = color.substr(1, 2);
      const greenHex = color.substr(3, 2);
      const blueHex = color.substr(5, 2);

      const rgbColorValues = [redHex, greenHex, blueHex].map((hexValue) =>
        parseInt(hexValue, 16)
      );

      if (isValidRGB(rgbColorValues)) {
        return rgbColorValues;
      }
    }

    if (color.indexOf('rgb') !== -1) {
      const [red, green, blue] = color.match(/\d+/g);

      const colorValues = [red, green, blue].map((colorValueString) =>
        Number(colorValueString)
      );

      if (isValidRGB(colorValues)) {
        return colorValues;
      }
    }
  } catch {
    return;
  }
};

export const getContrastingTextColor = (backgroundColor) => {
  if (!backgroundColor) {
    return DEFAULT_TEXT_COLORS.BLACK;
  }

  const rgbColorValues = getRGBValues(backgroundColor) || [];

  if (!rgbColorValues) {
    return DEFAULT_TEXT_COLORS.WHITE;
  }

  const [red, green, blue] = rgbColorValues;

  const colorBrightnessLevel = (red * 299 + green * 587 + blue * 114) / 1000;
  return colorBrightnessLevel >= BRIGHTNESS_MIDPOINT
    ? DEFAULT_TEXT_COLORS.BLACK
    : DEFAULT_TEXT_COLORS.WHITE;
};
