import React from 'react';

import { Button } from '@knockrentals/knock-shared-web';
import { StatusChip } from '../../StatusChip';
import { TemplateFormatIcon } from '../TemplateFormatIcon';

export const getColumns = ({ setTemplate }) => [
  {
    align: 'left',
    defaultSort: 'asc',
    title: 'Template Name',
    render: (rowData) => {
      const { status, templateId, templateName } = rowData;
      return (
        <Button
          component="span"
          onClick={() => {
            setTemplate({ status, templateId });
          }}
          variant="text"
        >
          {templateName}
        </Button>
      );
    },
    width: '349px',
  },
  {
    align: 'left',
    title: 'Subject',
    field: 'subject',
    width: '423px',
  },
  {
    align: 'left',
    field: 'contentType',
    render: (rowData) => (
      <TemplateFormatIcon contentType={rowData.contentType} />
    ),
    title: 'Format',
    width: '250px',
  },
  {
    align: 'left',
    field: 'status',
    render: (rowData) => <StatusChip status={rowData.status} />,
    title: 'Status',
    width: '102px',
  },
];
