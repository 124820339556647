import { useEffect, useCallback, useState } from 'react';
import { companyTemplatesApi } from './CompanyTemplatesApi';

const useCompanyTemplates = () => {
  const [companyTemplates, setCompanyTemplates] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const fetchCompanyTemplates = useCallback(async () => {
    setIsLoading(true);
    try {
      const templates = await companyTemplatesApi.getCompanyTemplates();
      setCompanyTemplates(templates);
    } catch (error) {
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchCompanyTemplates();
  }, [fetchCompanyTemplates]);

  const setIsEnabled = (templateType, templateId, isEnabled) => {
    setCompanyTemplates((prevState) => ({
      ...prevState,
      [templateType]: {
        ...prevState[templateType],
        templates: prevState[templateType].templates.map((template) => {
          if (template.templateId === templateId) {
            return {
              ...template,
              isEnabled,
            };
          }
          return { ...template };
        }),
      },
    }));
  };

  const updateIsEnabled = (templateType, templateId) => async (isEnabled, isDefault = false) => {
    setIsEnabled(templateType, templateId, isEnabled);

    try {
      await companyTemplatesApi.updateCompanyTemplate(templateId, {
        isEnabled,
        isDefault,
      });
      fetchCompanyTemplates();
    } catch (e) {
      setErrorMessage(
        'We are unable to update the template at this time. Please try again later.'
      );
      setIsEnabled(templateType, templateId, !isEnabled);
    }
  };

  return {
    companyTemplates,
    errorMessage,
    fetchCompanyTemplates,
    isLoading,
    setErrorMessage,
    updateIsEnabled,
  };
};

export default useCompanyTemplates;
