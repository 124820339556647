import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '@knockrentals/knock-react';

const ProspectsImport = ({ errorMessages, onCancel, onImportProspects }) => {
  const [importInProgress, setImportInProgress] = useState(false);
  const selectedFileRef = useRef();

  const handleOnChange = (e) => {
    selectedFileRef.current = e.target.files[0];
  };

  const handleOnClick = (e) => {
    e.target.value = null;
  };

  const onSubmit = async () => {
    setImportInProgress(true);
    const selectedFile = selectedFileRef.current;
    await onImportProspects(selectedFile);
    setImportInProgress(false);
  };

  return (
    <Modal className="prospect-upload-container">
      <ModalHeader>
        <h2>Import prospects</h2>
      </ModalHeader>

      <ModalContent>
        <label>
          Prospect import CSV:
          <input
            type="file"
            className="knock-react-input"
            onChange={handleOnChange}
            onClick={handleOnClick}
          />
        </label>

        {errorMessages.length > 0 && (
          <div>
            <h3>Formatting Errors</h3>
            {errorMessages.map((errorMessage) => (
              <div key={errorMessage}>{errorMessage}</div>
            ))}
          </div>
        )}
      </ModalContent>

      {importInProgress ? (
        <ModalFooter>
          <button className="knock-react-button" disabled>
            Uploading prospects...
          </button>
        </ModalFooter>
      ) : (
        <ModalFooter>
          <button className="knock-react-button" onClick={onCancel}>
            Cancel
          </button>
          <button className="knock-react-button" onClick={onSubmit}>
            Upload
          </button>
        </ModalFooter>
      )}
    </Modal>
  );
};

ProspectsImport.propTypes = {
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  onCancel: PropTypes.func,
  onImportProspects: PropTypes.func,
};

export default ProspectsImport;
