import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Text,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import { useProperties } from './useProperties';
import SearchBar from '../../../Components/SearchBar';
import PropertyList from '../../../Components/PropertyList';
import { ErrorDialog } from '../../Features/common/ErrorDialog';

const useStyles = makeStyles(() => ({
  dialogActions: {
    padding: '16px',
  },
  mBottom20: {
    marginBottom: '20px',
  },
}));

const COPY_DESTINATIONS = {
  chat: {
    display: 'Chat',
    subtext: 'chat',
    default: true,
  },
  sms: {
    display: 'SMS',
    subtext: 'SMS',
    default: false,
  },
};

/**
 * This component allows the user to copy all voice responses in all intents
 * and groups into the corresponding chat response fields
 */
const VirtualAgentSetupChatResponsesModal = ({
  isOpen,
  closeDialog,
  handleConfirmation,
  setErrorMessage,
  copyDestination,
  errorMessage,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');

  const {
    getSelectedProperties,
    selectedPropertiesCount,
    selectedPropertiesMap,
    setSelectedPropertiesMap,
    propertiesList,
  } = useProperties({
    setErrorMessage,
  });

  const handleSelectOnClick = () => {
    const selectedProperties = getSelectedProperties();
    const propertyIds = selectedProperties.map(
      (property) => property.propertyId
    );
    const copyDestKey = COPY_DESTINATIONS[copyDestination].default
      ? null
      : copyDestination;

    handleConfirmation(propertyIds, copyDestKey);
    setSelectedPropertiesMap({});
    setSearchTerm('');
  };

  const handleClose = () => {
    closeDialog();
    setSearchTerm('');
  };

  const getPropertiesList = () => {
    const filteredProperties = propertiesList.filter((property) =>
      property.propertyName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedProperties = filteredProperties.sort((a, b) =>
      a.propertyName.localeCompare(b.propertyName)
    );

    return sortedProperties.length ? sortedProperties : propertiesList;
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle disableTypography={true}>
        <Text variant="h6" className={classes.mBottom20}>
          Copy Voice Responses to {COPY_DESTINATIONS[copyDestination].display}
        </Text>
        <Text variant="body1">
          You have permissions to modify the following properties. Select which
          properties to copy voice responses to{' '}
          {COPY_DESTINATIONS[copyDestination].subtext}.
        </Text>
      </DialogTitle>

      <DialogContent dividers={false} data-testid="property-list-item">
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <PropertyList
          propertiesList={getPropertiesList()}
          selectedCount={selectedPropertiesCount}
          selectedPropertiesMap={selectedPropertiesMap}
          setSelectedPropertiesMap={setSelectedPropertiesMap}
          
        />
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          onClick={() => {
            setSelectedPropertiesMap({});
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSelectOnClick}
          disabled={selectedPropertiesCount <= 0}
        >
          Continue
        </Button>
      </DialogActions>

      <ErrorDialog
        closeDialog={() => {
          setErrorMessage('');
          closeDialog();
        }}
        errorMessage={errorMessage}
        isOpen={Boolean(errorMessage)}
      />
    </Dialog>
  );
};

VirtualAgentSetupChatResponsesModal.propTypes = {
  copyDestination: PropTypes.oneOf(Object.keys(COPY_DESTINATIONS)),
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default VirtualAgentSetupChatResponsesModal;
