import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  InputText,
  Menu,
  MenuItem,
  Text,
  makeStyles
} from '@knockrentals/knock-shared-web';

import {
  AI_COMPANY_TEMPLATE,
} from '../AiConstants';
import { getCaretCoordinates } from '../AiTemplate/utils';

const MENU_OFFSET = 20;
const TRIGGER_CHARACTER = '#';

const useStyles = makeStyles({
  menuItemName: {
    fontWeight: 'bold'
  },
  menuItemDescription: {
    fontStyle: 'italic'
  }
});

const MentionsTextInput = ({
  mergeTags,
  name,
  inputValue,
  label,
  error,
  disabled,
  onChange,
  updateTemplate }) => {
  const classes = useStyles();

  const inputRef = useRef(null);
  const caretPositionRef = useRef(inputRef.current ? inputRef.current.selectionEnd : 0);
  const [isOpen, setIsOpen] = useState(false);

  const { top, left } = getCaretCoordinates(inputRef.current);

  const handleOnChange = (e) => {
    const { value } = e.target;

    const caretPosition = inputRef.current.selectionStart;
    const lastCharacter = value[caretPosition - 1];

    caretPositionRef.current = caretPosition;

    if (lastCharacter === TRIGGER_CHARACTER) {
      setIsOpen(true);
    }
    onChange(e);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleMenuItemClick = (mergeTag) => {

    const curPosition = caretPositionRef.current;

    const prefix = inputValue.slice(0, curPosition);
    const suffix = inputValue.slice(curPosition);

    const result = prefix + mergeTag + suffix;

    updateTemplate(
      { [AI_COMPANY_TEMPLATE.SUBJECT]: result }
    );

    const mergeTagOffset = mergeTag.length;
    caretPositionRef.current = curPosition + mergeTagOffset;

    setIsOpen(false);
  };

  useEffect(() => {
    inputRef.current &&
      inputRef.current.setSelectionRange(
        caretPositionRef.current,
        caretPositionRef.current
      );
  }, [inputValue]);


  return (
    <div>
      <InputText
        fullWidth
        inputProps={{ maxLength: 100 }}
        label={label}
        name={name}
        onChange={handleOnChange}
        value={inputValue}
        requiredMessage="required"
        disabled={disabled}
        error={Boolean(error)}
        helperText={error}
        inputRef={inputRef}
      />
      <Menu
        open={isOpen}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: top + MENU_OFFSET,
          left: left
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <div>
          {mergeTags.map((mergeTag) => (
            <MenuItem
              key={mergeTag.name}
              onClick={() => handleMenuItemClick(mergeTag.name)}
              value={mergeTag.name}
            >
              <div>
                <Text variant="body2" className={classes.menuItemName}>
                  #{mergeTag.name}
                </Text>
                <Text variant="body2" className={classes.menuItemDescription}>
                  {mergeTag.description}
                </Text>
              </div>
            </MenuItem>
          ))}
        </div>
      </Menu>
    </div>
  );
};

MentionsTextInput.propTypes = {
  mergeTags: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  inputValue: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  updateTemplate: PropTypes.func,
};

export default MentionsTextInput;