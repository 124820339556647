import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import InfoIconTooltip from '../../../Properties/components/InfoTooltip';

export const INVALID_NUMBER_MESSAGE = 'A valid phone number is required';

export const TOOL_TIP_MESSAGES = {
  PROVIDER:
    'Select your dynamic number insertion service provider. A phone number is required when selecting Knock. Select G5 when managing the dynamic number insertion settings through G5.',
  TARGET:
    'Find and replace the provided phone number with the source tracking number',
};

export const TYPES = {
  G5: 'G5',
  KNOCK: 'KNOCK',
  NONE: 'NONE',
};

const typesOptions = [TYPES.NONE, TYPES.KNOCK, TYPES.G5];

const DynamicNumberInsertion = ({
  dynamicNumberInsertionType,
  formattedNumber,
  formattedNumberIsActive,
  updateDoorwaySettings,
}) => {
  const defaultTypeState = formattedNumberIsActive ? TYPES.KNOCK : TYPES.NONE;

  const [errorMessage, setErrorMessage] = useState(false);
  const [targetNumber, setTargetNumber] = useState(formattedNumber);
  const [type, setType] = useState(
    dynamicNumberInsertionType || defaultTypeState
  );

  const isTargetNumberValid = () => /\(\d{3}\) \d{3}-\d{4}/.test(targetNumber);

  const setKnockDynamicNumberInsertion = () => {
    updateDoorwaySettings({
      dynamicNumberInsertionType: TYPES.KNOCK,
      formattedNumber: targetNumber,
      formattedNumberIsActive: true,
    });
  };

  const handleSelectionOnChange = (e) => {
    const dynamicNumberInsertionType = e.target.value;
    setType(dynamicNumberInsertionType);

    if (dynamicNumberInsertionType !== TYPES.KNOCK) {
      if (errorMessage) {
        setErrorMessage('');
        setTargetNumber('');
      }

      updateDoorwaySettings({
        dynamicNumberInsertionType,
        formattedNumberIsActive: false,
      });

      return;
    }

    if (isTargetNumberValid()) {
      setKnockDynamicNumberInsertion();
    }
  };

  const handleTargetNumberOnBlur = (e) => {
    if (!isTargetNumberValid()) {
      setErrorMessage(INVALID_NUMBER_MESSAGE);
      return;
    }

    if (formattedNumber !== targetNumber) {
      setKnockDynamicNumberInsertion();
    }
  };

  const handleTargetNumberChange = (e) => {
    setTargetNumber(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="dynamic-number-insertion-type">
        <label>Dynamic Number Insertion</label>

        <select value={type} onChange={handleSelectionOnChange}>
          {typesOptions.map((typeOption) => (
            <option key={typeOption} value={typeOption}>
              {typeOption.toLocaleLowerCase()}
            </option>
          ))}
        </select>

        <InfoIconTooltip message={TOOL_TIP_MESSAGES.PROVIDER} />
      </div>

      {type === TYPES.KNOCK && (
        <div className="target-number">
          <label>
            DNI Swap Target<span>(required)</span>
          </label>
          <InputMask
            autoFocus={!targetNumber}
            mask="(999) 999-9999"
            value={targetNumber}
            name="formatted-number"
            onBlur={handleTargetNumberOnBlur}
            onChange={handleTargetNumberChange}
            onFocus={() => {
              setErrorMessage('');
            }}
            className="knock-react-input"
            placeholder="e.g. (206) 555-1234"
          />

          <InfoIconTooltip message={TOOL_TIP_MESSAGES.TARGET} />

          {errorMessage && (
            <span className="knock-react-form-hint knock-react-form-hint-error">
              {errorMessage}
            </span>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

DynamicNumberInsertion.propTypes = {
  dynamicNumberInsertionType: PropTypes.oneOf([...Object.values(TYPES), '']),
  formattedNumber: PropTypes.string,
  formattedNumberIsActive: PropTypes.bool,
  updateDoorwaySettings: PropTypes.func,
};

export default DynamicNumberInsertion;
