import React, { Component } from 'react';
import SettingsAPI from './SettingsAPI';
import PropertiesAPI from '../Properties/PropertiesAPI';
import GroupSettings from './GroupSettings';
import { PageContainer, Toaster, Alert } from '@knockrentals/knock-react';

class SettingsPage extends Component {
  state = {
    isLoading: true,
    groups: [],
    properties: [],
    selectedGroupId: 'all',
  };

  componentDidMount() {
    SettingsAPI.getSettings().then((settingsResponse) => {
      const groups = settingsResponse.leasing_team_settings.sort(
        (groupA, groupB) => {
          return groupA.group_name > groupB.group_name ? 1 : -1;
        }
      );

      PropertiesAPI.getCommunityInfo().then((propertiesResponse) => {
        this.setState({
          groups: groups,
          properties: propertiesResponse.properties,
          isLoading: false,
        });
      });
    });
  }

  render() {
    return (
      <PageContainer isLoading={this.state.isLoading}>
        <h1>Settings</h1>

        <div className="knock-react-flex knock-react-flex-row">
          <div>
            <div>Leasing team</div>
            <select
              value={this.state.selectedGroupId}
              onChange={this.onGroupChanged.bind(this)}
            >
              <option value={'all'}>All</option>
              {this.renderGroupOptions()}
            </select>
          </div>
        </div>

        {this.renderGroups()}
      </PageContainer>
    );
  }

  onGroupChanged(event) {
    this.setState({ selectedGroupId: event.target.value });
  }

  renderGroupOptions() {
    return this.state.groups.map((group) => {
      return (
        <option key={group.group_id} value={group.group_id}>
          {group.group_name}
        </option>
      );
    });
  }

  getPropertiesUnderGroup = (groupId) => {
    return this.state.properties.filter(
      (property) => property.leasing_team_id === groupId
    );
  };

  renderGroups() {
    if (this.state.groups.length === 0) {
      return <Alert>No properties to display.</Alert>;
    }

    if (this.state.selectedGroupId === 'all') {
      return this.state.groups.map((group) => {
        return (
          <GroupSettings
            group={group}
            properties={this.getPropertiesUnderGroup(group.group_id)}
            key={group.group_id}
            onSettingsChanged={this.onSettingsChanged.bind(this)}
          />
        );
      });
    }

    const selectedGroupId = parseInt(this.state.selectedGroupId, 10);

    return (
      <GroupSettings
        group={this.state.groups.find(
          (group) => group.group_id === selectedGroupId
        )}
        key={this.state.selectedGroupId}
        properties={this.getPropertiesUnderGroup(selectedGroupId)}
        onSettingsChanged={this.onSettingsChanged.bind(this)}
      />
    );
  }

  onSettingsChanged(groupId, updatedSettings) {
    const updatedGroups = this.state.groups.slice();

    updatedGroups[
      updatedGroups.findIndex((group) => group.group_id === groupId)
    ] = updatedSettings;

    this.setState({ groups: updatedGroups }, () => {
      SettingsAPI.updateGroupSettings(groupId, updatedSettings)
        .then(() => {
          Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        })
        .catch(() => {
          Toaster.showToast(
            'Error saving leasing team settings.',
            2000,
            Toaster.ToastClasses.error
          );
        });
    });
  }
}

export default SettingsPage;
