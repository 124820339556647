import React from 'react';
import { FieldArray } from 'formik';
import { Button, TextField } from '@knockrentals/knock-shared-web';
import { AddCircleSvgIcon, BinIcon } from '../../../Components/Icons';
import { useStyles } from './styles';
import './_SetupForm.scss';

const ServiceAccountsForm = () => {
  const classes = useStyles();


  return (
    <FieldArray name="userInfoServiceAccounts">
      {({ form, push, remove }) => {
        const { errors, handleBlur, handleChange, touched, values } = form;
        const { userInfoServiceAccounts } = values;
        const userInfoServiceAccountsErrors = errors.userInfoServiceAccounts || [];
        const userInfoServiceAccountsTouched = touched.userInfoServiceAccounts || [];

        const hasErrors = userInfoServiceAccountsErrors.some((error) =>
          error.email
        );

        const allFieldsTouched = userInfoServiceAccountsTouched.every((fieldTouched) =>
          fieldTouched.email
        );
        return (
          <>
            {userInfoServiceAccounts.map((_, index) => {
              const userInfoServiceAccountsErrorMessages = userInfoServiceAccountsErrors[index] || {};
              const userInfoServiceAccountsTouchedMessages = userInfoServiceAccountsTouched[index] || {};

              return (
                <div className="service-accounts" key={`service-account-${index}`}>
                  {index > 0 && (
                    <div className="form-controls">
                      <Button
                        variant="text"
                        onClick={() => remove(index)}
                        startIcon={<BinIcon />}
                      >
                        Remove This Service
                      </Button>
                    </div>
                  )}

                  <TextField
                    className={classes.textFieldInput}
                    error={Boolean(userInfoServiceAccountsErrorMessages.email && userInfoServiceAccountsTouchedMessages.email)}
                    helperText={userInfoServiceAccountsErrorMessages.email}
                    label="Email"
                    name={`userInfoServiceAccounts.${index}.email`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter Service Account Email"
                    value={values.userInfoServiceAccounts[index].email}
                    variant="filled"
                    fullWidth
                  />
                </div>
              );
            })}

            <Button
              onClick={() => {
                push({
                  email: '',
                  isServiceAccount: true,
                });
              }}
              disabled={hasErrors || !allFieldsTouched}
              variant="text"
              startIcon={<AddCircleSvgIcon />}
            >
              Add Additional Users
            </Button>
          </>
        );
      }}
    </FieldArray>
  );
};

export default ServiceAccountsForm;
