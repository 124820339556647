import React, { useEffect, useState } from 'react';
import { Collapse, Toaster } from '@knockrentals/knock-react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  LoadingOverlay,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PropertyVoiceResponseTemplate, {
  VOICE_RESPONSE_CHAR_LIMIT,
  VOICE_RESPONSE_CHAR_LIMIT_ERROR,
} from './PropertyVoiceResponseTemplate';
import PropertyChatResponseTemplate from './PropertyChatResponseTemplate';
import PropertySmsResponseTemplate, {
  SMS_RESPONSE_CHAR_LIMIT,
  SMS_RESPONSE_CHAR_LIMIT_ERROR,
} from './PropertySmsResponseTemplate';
import * as VirtualAgentAPI from '../../VirtualAgentAPI';
import VirtualAgentGenAIResponsesModal from './VirtualAgentGenAIResponsesModal';
import { useAppContext } from '../../../../Context/AppContext';
import { TABS } from '../constants';

const useStyles = makeStyles(() => ({
  accordionContainer: {
    width: '75%',
  },
  accordionContent: {
    flexDirection: 'column',
  },
  accordionHeader: {
    fontWeight: 700,
  },
  actionsContainer: {
    paddingLeft: 20,
  },
  actionBtn: {
    margin: 10,
  },
  responses: {
    padding: 10,
    paddingLeft: 35,
  },
  saveBtnContainer: {
    justifyContent: 'right',
    display: 'flex',
  },
}));

export const COLLAPSED_TEXT = 'Expand All Topics';
export const EXPANDED_TEXT = 'Collapse All Topics';
export const NO_AI_CONTACT = 'Knock Sales Representative';

const PropertyResponses = ({
  property,
  intents,
  onConfirmation,
  getResponses,
  saveResponses,
  currentTab,
}) => {
  const classes = useStyles();
  const [expandedTrack, setExpandedTrack] = useState({});
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responsesByIntent, setResponsesByIntent] = useState({});
  const [isGenAIResponsesModalOpen, setIsGenAIResponsesModalOpen] =
    useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const { isInternalMode } = useAppContext();

  useEffect(() => {
    setExpandedTrack(
      Object.assign(
        {},
        ...intents.map(({ id: intentId }) => ({ [intentId]: isAllExpanded }))
      )
    );
  }, [intents, isAllExpanded]);

  const getActiveProducts = () => {
    return {
      chat: property.agent.is_chat_enabled || isInternalMode,
      voice: property.agent.call_routing !== 'OFF' || isInternalMode,
      sms: property.agent.is_sms_enabled || isInternalMode,
    };
  };

  const getResponsesByIntent = async (intentId) => {
    if (responsesByIntent[intentId]) {
      return;
    }

    const responses = await getResponses(property.id, intentId);

    const {
      voice_response: voiceResponse,
      chat_response: chatResponse,
      sms_response: smsResponse,
      generated_by_ai_voice: isGeneratedByAiVoice,
      generated_by_ai_sms: isGeneratedByAiSms,
      generated_by_ai_chat: isGeneratedByAiChat,
    } = responses;

    setResponsesByIntent((prevState) => ({
      ...prevState,
      [intentId]: {
        voiceResponse,
        chatResponse,
        smsResponse,
        isGeneratedByAiVoice,
        isGeneratedByAiChat,
        isGeneratedByAiSms,
      },
    }));
  };

  const getExpandedResponses = async () => {
    const chunkSize = 5;
    const expandedIntents = Object.entries(expandedTrack)
      .filter(([_, value]) => value === true)
      .map(([key]) => Number(key));

    for (let i = 0; i < expandedIntents.length; i += chunkSize) {
      const chunk = expandedIntents.slice(i, i + chunkSize);
      const promises = await Promise.all(
        chunk.map((expandedIntent) => getResponses(property.id, expandedIntent))
      );

      promises.forEach((response, index) => {
        const {
          voice_response: voiceResponse,
          chat_response: chatResponse,
          sms_response: smsResponse,
          generated_by_ai_chat: isGeneratedByAiChat,
          generated_by_ai_sms: isGeneratedByAiSms,
          generated_by_ai_voice: isGeneratedByAiVoice,
        } = response;

        setResponsesByIntent((prevState) => ({
          ...prevState,
          [chunk[index]]: {
            voiceResponse,
            chatResponse,
            smsResponse,
            isGeneratedByAiVoice,
            isGeneratedByAiChat,
            isGeneratedByAiSms,
          },
        }));
      });
    }
  };

  const getAllResponses = async () => {
    const chunkSize = 5;
    const responses = [];

    for (let i = 0; i < intents.length; i += chunkSize) {
      const chunk = intents.slice(i, i + chunkSize);
      const chunkResponses = await Promise.all(
        chunk.map(async ({ id: intentId }) => {
          if (responsesByIntent[intentId] && !isGenAIResponsesModalOpen) {
            return {
              intentId,
              ...responsesByIntent[intentId],
            };
          }

          const response = await getResponses(property.id, intentId);

          const {
            voice_response: voiceResponse,
            chat_response: chatResponse,
            sms_response: smsResponse,
            generated_by_ai_chat: isGeneratedByAiChat,
            generated_by_ai_sms: isGeneratedByAiSms,
            generated_by_ai_voice: isGeneratedByAiVoice,
          } = response;

          return {
            intentId,
            voiceResponse,
            chatResponse,
            smsResponse,
            isGeneratedByAiChat,
            isGeneratedByAiSms,
            isGeneratedByAiVoice,
          };
        })
      );

      responses.push(...chunkResponses);
    }

    setResponsesByIntent(
      Object.assign(
        {},
        ...responses.map(({ intentId, ...responsesByIntent }) => ({
          [intentId]: responsesByIntent,
        }))
      )
    );
  };

  const toggleIntentExpand = async (intentId) => {
    const isExpanding = !expandedTrack[intentId];

    if (isExpanding) {
      getResponsesByIntent(intentId).catch((error) => {
        Toaster.showToast(
          'Error fetching responses data',
          2000,
          Toaster.ToastClasses.error
        );
      });
    }

    setExpandedTrack((prevState) => ({
      ...prevState,
      [intentId]: isExpanding,
    }));
  };

  const toggleIntentExpandAll = () => {
    if (!isAllExpanded) {
      setIsLoading(true);

      getAllResponses()
        .catch(() => {
          Toaster.showToast(
            'Error fetching responses data',
            2000,
            Toaster.ToastClasses.error
          );
        })
        .finally(() => {
          setIsAllExpanded(!isAllExpanded);
          setIsLoading(false);
        });
    }

    setIsAllExpanded(!isAllExpanded);
  };

  const handleVoiceResponseChange = (intentId, voiceResponse) => {
    setResponsesByIntent((prevState) => ({
      ...prevState,
      [intentId]: {
        ...prevState[intentId],
        voiceResponse,
      },
    }));
  };

  const handleChatResponseChange = (intentId, chatResponse) => {
    setResponsesByIntent((prevState) => ({
      ...prevState,
      [intentId]: {
        ...prevState[intentId],
        chatResponse,
      },
    }));
  };

  const handleSmsResponseChange = (intentId, smsResponse) => {
    setResponsesByIntent((prevState) => ({
      ...prevState,
      [intentId]: {
        ...prevState[intentId],
        smsResponse,
      },
    }));
  };

  const handleDownloadPropertyResponses = (propertyId) => {
    onConfirmation([propertyId]);
  };

  const setAiRobotIcon = (intentId, responses) => {
    const {
      chat_response: chatResponse,
      voice_response: voiceResponse,
      sms_response: smsResponse,
      generated_by_ai_voice: isGeneratedByAiVoice = false,
      generated_by_ai_sms: isGeneratedByAiSms = false,
      generated_by_ai_chat: isGeneratedByAiChat = false,
    } = responses;

    setResponsesByIntent((prevState) => ({
      ...prevState,
      [intentId]: {
        ...prevState[intentId],
        chatResponse,
        voiceResponse,
        smsResponse,
        isGeneratedByAiVoice,
        isGeneratedByAiSms,
        isGeneratedByAiChat,
      },
    }));
  };

  const saveData = async (intentId) => {
    const responses = responsesByIntent[intentId];

    if (responses.voiceResponse?.length > VOICE_RESPONSE_CHAR_LIMIT) {
      Toaster.showToast(
        VOICE_RESPONSE_CHAR_LIMIT_ERROR,
        2000,
        Toaster.ToastClasses.error
      );
      return;
    }

    if (responses.smsResponse?.length > SMS_RESPONSE_CHAR_LIMIT) {
      Toaster.showToast(
        SMS_RESPONSE_CHAR_LIMIT_ERROR,
        2000,
        Toaster.ToastClasses.error
      );
      return;
    }

    try {
      let virtualAgentResponse = await saveResponses(
        property.id,
        intentId,
        responses
      );

      if (!virtualAgentResponse.property_response) {
        virtualAgentResponse =
          (await getResponses(property.id, intentId)) ?? {};
      } else {
        virtualAgentResponse = virtualAgentResponse.property_response;
      }

      setAiRobotIcon(intentId, virtualAgentResponse);

      Toaster.showToast('Responses saved!', 2000, Toaster.ToastClasses.success);
    } catch (error) {
      Toaster.showToast(
        'Error saving response settings',
        2000,
        Toaster.ToastClasses.error
      );
    }
  };

  const handleGenerateAIResponses = async (propertyId, genAiChannels) => {
    setIsGenerating(true);
    try {
      await VirtualAgentAPI.getGenAiResponses(propertyId, genAiChannels);
      await getExpandedResponses();
      Toaster.showToast(
        'Generative AI responses set up successfully',
        2000,
        Toaster.ToastClasses.success
      );
    } catch (e) {
      Toaster.showToast(
        'An error occured while generating AI responses',
        2000,
        Toaster.ToastClasses.error
      );
    }
    setIsGenerating(false);
    setIsGenAIResponsesModalOpen(false);
  };

  const activeProducts = getActiveProducts();

  return (
    <Collapse title={property.name}>
      <Box>
        <Box className={classes.actionsContainer}>
          {isInternalMode && currentTab === TABS.LEASING && (
            <Button
              variant="text"
              className={classes.actionBtn}
              onClick={() => {
                setIsGenAIResponsesModalOpen(true);
              }}
            >
              <i className="fa fa-robot fa-fw" style={{ paddingRight: 10 }} />
              Generate AI Responses
            </Button>
          )}
          <Button
            variant="text"
            className={classes.actionBtn}
            onClick={() => handleDownloadPropertyResponses(property.id)}
          >
            <i className="fa fa-download" style={{ paddingRight: 10 }} />
            Download Responses
          </Button>
          <Button
            variant="text"
            className={classes.actionBtn}
            onClick={toggleIntentExpandAll}
          >
            <i
              className="fa fa-caret-down fa-fw"
              style={{ paddingRight: 10 }}
            />
            {isAllExpanded ? EXPANDED_TEXT : COLLAPSED_TEXT}
          </Button>
        </Box>
        <VirtualAgentGenAIResponsesModal
          isOpen={isGenAIResponsesModalOpen}
          closeDialog={() => {
            setIsGenAIResponsesModalOpen(false);
          }}
          handleGeneration={handleGenerateAIResponses}
          isGenerating={isGenerating}
          setIsGenerating={setIsGenerating}
          propertyId={property.id}
        />
        <div key={property.id} className={classes.responses}>
          <React.Fragment>
            <LoadingOverlay
              open={isLoading}
              style={{ position: 'absolute', opacity: '0.5' }}
            />
            {intents.map((intent) => {
              return (
                <Accordion
                  key={intent.id}
                  TransitionProps={{ mountOnEnter: true }}
                  className={classes.accordionContainer}
                  expanded={expandedTrack[intent.id] || false}
                >
                  <AccordionSummary
                    className={classes.accordionHeader}
                    onClick={() => toggleIntentExpand(intent.id)}
                  >
                    {intent.display_name}
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionContent}>
                    <PropertyVoiceResponseTemplate
                      voiceResponse={
                        responsesByIntent[intent.id]?.voiceResponse
                      }
                      generatedByAi={
                        responsesByIntent[intent.id]?.isGeneratedByAiVoice
                      }
                      active={activeProducts.voice}
                      onVoiceResponseChange={(response) =>
                        handleVoiceResponseChange(intent.id, response)
                      }
                    />
                    <PropertyChatResponseTemplate
                      chatResponse={responsesByIntent[intent.id]?.chatResponse}
                      generatedByAi={
                        responsesByIntent[intent.id]?.isGeneratedByAiChat
                      }
                      active={activeProducts.chat}
                      onChatResponseChange={(response) =>
                        handleChatResponseChange(intent.id, response)
                      }
                    />
                    <PropertySmsResponseTemplate
                      smsResponse={responsesByIntent[intent.id]?.smsResponse}
                      generatedByAi={
                        responsesByIntent[intent.id]?.isGeneratedByAiSms
                      }
                      active={activeProducts.sms}
                      onSmsResponseChange={(response) =>
                        handleSmsResponseChange(intent.id, response)
                      }
                    />
                    <Box className={classes.saveBtnContainer}>
                      <Button onClick={() => saveData(intent.id)}>Save</Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </React.Fragment>
        </div>
      </Box>
    </Collapse>
  );
};

PropertyResponses.propTypes = {
  intents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      display_name: PropTypes.string,
    })
  ),
  property: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  onConfirmation: PropTypes.func.isRequired,
};

export default PropertyResponses;
