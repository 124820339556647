import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  makeStyles,
  Text,
} from '@knockrentals/knock-shared-web';
import { AddCircleSvgIcon } from '../../Components/Icons';
import { useAppContext } from '../../Context/AppContext';
import { CloudSetupImage } from './images';


const useStyles = makeStyles({
  kdcInfoDescription: {
    color: '#999999',
  },
  setUpCustomerBtn: {
    left: '78px',
    position: 'absolute',
    top: '236px',
    '& svg path': {
      color: '#5C5C5C',
    },
  }
});

const SSDASetup = ({ setIsDrawerOpen }) => {
  const classes = useStyles();

  const { companyName } = useAppContext();
  return (
    <div className="knock-data-cloud-setup">
      <div className="setup-image-container">
        <span>
          <CloudSetupImage />

          <Button
            className={classes.setUpCustomerBtn}
            startIcon={<AddCircleSvgIcon />}
            variant="text"
            onClick={() => setIsDrawerOpen(true)}
          >
            Set Up Customer
          </Button>
        </span>

        <Text align="center" color="textSecondary" variant="h3">
          Knock Data Cloud
        </Text>

        <Text align="center" variant="h5" className={classes.kdcInfoDescription}>
          Set up credentials for {companyName}
        </Text>
      </div>
    </div>
  );
};

SSDASetup.propTypes = {
  setIsDrawerOpen: PropTypes.func,
};

export default SSDASetup;
