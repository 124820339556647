import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@knockrentals/knock-shared-web';
import DrawerActions from './DrawerActions';
import { MODES, STATUS } from '../constants';

export const ICON_BUTTON_TEXT = {
  [STATUS.ARCHIVED]: 'Delete Archive',
  [STATUS.DRAFT]: 'Delete Draft',
  [STATUS.LIVE]: 'Archive Campaign',
};

const CampaignActionButtons = ({
  handleSaveChangeOnClick,
  handleDuplicateOnClick,
  handleEditOnClick,
  handleEditPropertyOnClick,
  handleIconButtonOnClick,
  handleSaveDraftOnClick,
  handleSaveOnClick,
  hasCampaignChanged,
  isEditing,
  isOriginCreateCampaign,
  mode,
  status,
}) => {
  const renderFormActionButtons = () => (
    <React.Fragment>
      <Button
        className="action-button"
        color="primary"
        disabled={!hasCampaignChanged}
        onClick={handleSaveDraftOnClick}
      >
        {isEditing ? 'Save Draft' : 'Save as Draft'}
      </Button>

      <Button
        className="action-button"
        color="primary"
        disabled={!hasCampaignChanged}
        onClick={handleSaveOnClick}
      >
        Make Live
      </Button>
    </React.Fragment>
  );

  const renderLiveStatusButtons = () => (
    <React.Fragment>
      <Button
        className="action-button"
        color="primary"
        onClick={handleEditPropertyOnClick}
        variant="outlined"
      >
        Edit Properties
      </Button>

      {isEditing && (
        <Button
          className="action-button"
          color="primary"
          onClick={handleSaveChangeOnClick}
        >
          Save Change
        </Button>
      )}
    </React.Fragment>
  );

  const renderDraftStatusButtons = () => (
    <React.Fragment>
      <Button
        className="action-button"
        color="primary"
        onClick={handleEditOnClick}
      >
        Edit
      </Button>

      <Button
        className="action-button"
        color="primary"
        onClick={handleSaveOnClick}
      >
        Make Live
      </Button>
    </React.Fragment>
  );

  const renderDisplayActionButtons = () => {
    const shouldShowDuplicate =
      (!isEditing && status !== STATUS.DRAFT) || isOriginCreateCampaign;

    return (
      <React.Fragment>
        {!isOriginCreateCampaign &&
          ((status === STATUS.DRAFT && renderDraftStatusButtons()) ||
            (status === STATUS.LIVE && renderLiveStatusButtons()))}

        {shouldShowDuplicate && (
          <Button
            className="action-button"
            color="primary"
            onClick={handleDuplicateOnClick}
          >
            Duplicate and Edit
          </Button>
        )}
      </React.Fragment>
    );
  };

  return (
    <DrawerActions
      handleIconButtonOnClick={handleIconButtonOnClick}
      iconButtonText={ICON_BUTTON_TEXT[status]}
      isIconButtonVisible={
        (mode === MODES.DISPLAY || status === STATUS.DRAFT) &&
        !isOriginCreateCampaign
      }
      status={status}
    >
      {mode === MODES.DISPLAY
        ? renderDisplayActionButtons()
        : renderFormActionButtons()}
    </DrawerActions>
  );
};

CampaignActionButtons.propTypes = {
  handleDuplicateOnClick: PropTypes.func,
  handleEditOnClick: PropTypes.func,
  handleEditPropertyOnClick: PropTypes.func,
  handleIconButtonOnClick: PropTypes.func,
  handleSaveChangeOnClick: PropTypes.func,
  handleSaveDraftOnClick: PropTypes.func,
  handleSaveOnClick: PropTypes.func,
  hasCampaignChanged: PropTypes.bool,
  isEditing: PropTypes.bool,
  isOriginCreateCampaign: PropTypes.bool,
  mode: PropTypes.string,
  status: PropTypes.string,
};

export default CampaignActionButtons;
