import { getDefaultTableOptions } from '../../../Services/SharedService';

const defaultOptions = getDefaultTableOptions();

export const tableOptions = {
  ...defaultOptions,
  cellStyle: { borderRight: '1px solid #eee' },
  headerStyle: {
    ...defaultOptions.headerStyle,
    backgroundColor: '#E5E5E5',
    borderRight: '1px solid #eee',
    borderBottom: '1px solid #000000',
  },
};
