import React, { Component } from 'react';

import {
  AuthenticationService,
  PageContainer,
  Toaster,
} from '@knockrentals/knock-react';
import { showToastError } from './utils';
import { ERROR_MESSAGES, TOAST_ERROR_MESSAGES } from './constants';
import './_registration.scss';

import RegistrationAPI from './RegistrationAPI';

class RegistrationPage extends Component {
  state = {
    candidateBrandRegistrationSid: '',
    candidateBrandRegistration: null,
    candidateCustomerProfile: null,
    errorMessage: '',
    existingKnockRegistration: null,
    existingBrandRegistration: null,
    existingCustomerProfile: null,
    isCandidateValid: false,
    isLoading: true,
    phoneRegistration: null,
    showInstruction: { z: true },
    thisCompanyName: '',
  };

  getRegistration = async () => {
    try {
      const response = await RegistrationAPI.getRegistration();

      if (response.status === 418) {
        throw new Error(ERROR_MESSAGES.USER);
      }
      if (response.status !== 200) {
        throw new Error();
      }

      const responseBody = await response.json();

      this.setState({
        thisCompanyName: responseBody.company_name,
        existingKnockRegistration: responseBody.knock_registration,
        existingBrandRegistration: responseBody.twilio_brand_registration,
        existingCustomerProfile: responseBody.twilio_customer_profile,
      });
    } catch (e) {
      const errorMessage =
        e.message === ERROR_MESSAGES.USER
          ? ERROR_MESSAGES.USER
          : ERROR_MESSAGES.UNKNOWN;

      this.setState({ errorMessage });

      showToastError(TOAST_ERROR_MESSAGES.EXISTING_REGISTRATION_FETCH);
    }
  };

  getCompanyPhoneRegistration = async () => {
    try {
      const response = await RegistrationAPI.getCompanyPhoneRegistration();
      const {
        capacity,
        total_registered: totalRegistered,
        total_unregistered: totalUnregistered,
        twilio_limit: twilioLimit,
      } = response;

      this.setState({
        phoneRegistration: {
          capacity,
          totalRegistered,
          totalUnregistered,
          twilioLimit,
        },
      });
    } catch {
      this.setState({ errorMessage: ERROR_MESSAGES.UNKNOWN });
      showToastError(TOAST_ERROR_MESSAGES.EXISTING_REGISTRATION_FETCH);
    }
  };

  initRegistration = async () => {
    await this.getRegistration();

    const shouldCallCompanyPhoneRegistration =
      AuthenticationService._internalMode &&
      this.state.existingBrandRegistration &&
      !this.state.errorMessage;

    if (shouldCallCompanyPhoneRegistration) {
      await this.getCompanyPhoneRegistration();
    }

    this.setState({ isLoading: false });
  };

  componentDidMount() {
    this.initRegistration();
  }

  render() {
    return (
      <section>
        <PageContainer isLoading={this.state.isLoading}>
          <h1>Registration</h1>
          <div className="knock-react-flex knock-react-flex-row">
            <div className="a2p-registration-container">
              <h2>
                <i className="fa fa-address-book" /> Twilio Brand Registration:
                A2P-10DLC & SHAKEN/STIR
              </h2>

              {!this.state.isLoading &&
                (this.state.errorMessage ? (
                  <span>{this.state.errorMessage}</span>
                ) : (
                  this.renderAuthorizedContainer()
                ))}
            </div>
          </div>
        </PageContainer>
      </section>
    );
  }

  renderAuthorizedContainer() {
    return (
      <div>
        <div>
          <span>Currently logged in for Knock Company: </span>
          <span className="larger bold">{this.state.thisCompanyName}</span>
          <br />
        </div>

        {this.state.existingBrandRegistration &&
        this.state.existingCustomerProfile
          ? this.renderRegistrationInfo({
              brandRegistration: this.state.existingBrandRegistration,
              customerProfile: this.state.existingCustomerProfile,
              isPhoneRegistrationEnabled: AuthenticationService._internalMode,
              knockRegistration: this.state.existingKnockRegistration,
              phoneRegistration: this.state.phoneRegistration,
            })
          : this.renderA2pRegistrationContainer()}
      </div>
    );
  }

  renderA2pRegistrationContainer() {
    return (
      <div className="">
        <div className="brand-registration-container">
          <span className="">Twilio Brand Registration SID: </span>
          <input
            value={this.state.candidateBrandRegistrationSid}
            name="candidateBrandRegistrationSid"
            onChange={this.updateCandidateBrandRegistrationSid.bind(this)}
            placeholder="e.g. BNxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
            className="knock-react-input inline-block"
          />
          <button
            className="knock-react-button btn-success"
            onClick={this.getCandidateRegistration.bind(this)}
          >
            <i className="fa fa-search" /> Lookup
          </button>
        </div>

        {this.state.candidateBrandRegistration &&
        this.state.candidateCustomerProfile
          ? this.renderRegistrationInfo({
              brandRegistration: this.state.candidateBrandRegistration,
              customerProfile: this.state.candidateCustomerProfile,
            })
          : null}

        {this.state.candidateBrandRegistration &&
        this.state.candidateCustomerProfile ? (
          <button
            className="knock-react-button btn-success"
            onClick={this.submitCandidateRegistration.bind(this)}
          >
            <i className="fa fa-check" /> Link this Twilio Registration to Knock
            Company (<b>{this.state.thisCompanyName}</b>)
          </button>
        ) : null}

        {this.renderRegistrationInstructions()}
      </div>
    );
  }

  updateCandidateBrandRegistrationSid(event) {
    this.setState({ candidateBrandRegistrationSid: event.target.value });
  }

  renderRegistrationInfo({
    brandRegistration,
    customerProfile,
    isPhoneRegistrationEnabled,
    knockRegistration,
    phoneRegistration,
  }) {
    return (
      <div className="mTop10">
        <div className="half-width inline-block">
          <div className="knock-react-flex">
            <div className="registration-field-label">Friendly Name</div>
            <div className="registration-field-input">
              <input
                value={customerProfile.friendly_name}
                className="knock-react-input"
                placeholder="e.g. KNOCK, INC."
                disabled
              />
            </div>
          </div>
          <div className="knock-react-flex">
            <div className="registration-field-label">
              Brand Registration SID
            </div>
            <div className="registration-field-input">
              <input
                value={brandRegistration.sid}
                className="knock-react-input"
                placeholder="e.g. BNxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                disabled
              />
            </div>
          </div>
          <div className="knock-react-flex">
            <div className="registration-field-label">Customer Profile SID</div>
            <div className="registration-field-input">
              <input
                value={customerProfile.sid}
                className="knock-react-input"
                placeholder="e.g. BUxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                disabled
              />
            </div>
          </div>

          {knockRegistration ? (
            <div className="knock-react-flex">
              <div className="registration-field-label">
                Messaging Service SID
              </div>
              <div className="registration-field-input">
                <input
                  value={knockRegistration.twilio_messaging_service_sid}
                  className="knock-react-input"
                  placeholder="e.g. MGxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                  disabled
                />
              </div>
            </div>
          ) : null}

          <div className="knock-react-flex">
            <div className="registration-field-label">Contact Email</div>
            <div className="registration-field-input">
              <input
                value={customerProfile.email}
                className="knock-react-input"
                placeholder="e.g. willie@knockrentals.com"
                disabled
              />
            </div>
          </div>

          <div className="knock-react-flex">
            <div className="registration-field-label">Type</div>
            <div className="registration-field-input">
              <input
                value={brandRegistration.brand_type}
                className={`knock-react-input ${
                  this.checkIsBrandNonStandard(brandRegistration.brand_type)
                    ? 'error'
                    : ''
                }`}
                placeholder="e.g. STANDARD"
                disabled
              />
            </div>
          </div>

          <div className="knock-react-flex">
            <div className="registration-field-label">Status</div>
            <div className="registration-field-input">
              <input
                value={brandRegistration.status}
                className={`knock-react-input ${
                  this.checkIsBrandUnapproved(brandRegistration.status)
                    ? 'error'
                    : ''
                }`}
                placeholder="e.g. APPROVED"
                disabled
              />
            </div>
          </div>
        </div>

        <div className="half-width inline-block pull-right">
          <div className="knock-react-flex">
            <div className="registration-field-label">Brand Score</div>
            <div className="registration-field-input">
              <input
                value={brandRegistration.brand_score || ''}
                className="knock-react-input"
                placeholder="e.g. 93"
                disabled
              />
            </div>
          </div>

          <div className="knock-react-flex">
            <div className="registration-field-label">Created on</div>
            <div className="registration-field-input">
              <input
                value={brandRegistration.date_created}
                className="knock-react-input"
                placeholder="e.g. Fri, 30 Jul 2021 21:43:21 GMT"
                disabled
              />
            </div>
          </div>

          {isPhoneRegistrationEnabled && (
            <React.Fragment>
              <div className="knock-react-flex">
                <div className="registration-field-label">Total Registered</div>
                <div className="registration-field-input">
                  <input
                    value={phoneRegistration.totalRegistered}
                    className="knock-react-input"
                    placeholder="e.g. 4"
                    disabled
                  />
                </div>
              </div>

              <div className="knock-react-flex">
                <div className="registration-field-label">
                  Total Unregistered
                </div>
                <div className="registration-field-input">
                  <input
                    value={phoneRegistration.totalUnregistered}
                    className="knock-react-input"
                    placeholder="e.g. 1"
                    disabled
                  />
                </div>
              </div>

              <div className="knock-react-flex">
                <div className="registration-field-label">Twilio limit</div>
                <div className="registration-field-input">
                  <input
                    value={phoneRegistration.twilioLimit}
                    className="knock-react-input"
                    placeholder="e.g. 400"
                    disabled
                  />
                </div>
              </div>

              <div className="knock-react-flex">
                <div className="registration-field-label">Capacity</div>
                <div className="registration-field-input">
                  <input
                    value={phoneRegistration.capacity}
                    className="knock-react-input"
                    placeholder="e.g. 395"
                    disabled
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  getCandidateRegistration() {
    const candidateBrandRegistrationSid =
      this.state.candidateBrandRegistrationSid;

    if (!candidateBrandRegistrationSid) {
      Toaster.showToast(
        'Please input a twilio brand registration sid',
        2000,
        Toaster.ToastClasses.error
      );
      return;
    }

    if (!candidateBrandRegistrationSid.startsWith('BN')) {
      Toaster.showToast(
        'Twilio brand registration sid must start with a "BN..."',
        2000,
        Toaster.ToastClasses.error
      );
      return;
    }

    RegistrationAPI.getRegistration(candidateBrandRegistrationSid)
      .then((response) => response.json())
      .then((responsePayload) => {
        const candidateBrandRegistration =
          responsePayload.twilio_brand_registration;
        const candidateCustomerProfile =
          responsePayload.twilio_customer_profile;

        this.setState({
          candidateBrandRegistration: candidateBrandRegistration,
          candidateCustomerProfile: candidateCustomerProfile,
        });

        const isCandidateValid = this.checkIsCandidateValid(
          candidateBrandRegistration,
          candidateCustomerProfile
        );
        this.setState({ isCandidateValid: isCandidateValid });
      })
      .catch((e) => {
        console.log(`Error fetching candidate registration: ${e}`);
        Toaster.showToast(
          'Could not retrieve this brand registration',
          2000,
          Toaster.ToastClasses.error
        );
      });
  }

  checkIsCandidateValid(brandRegistration, customerProfile) {
    let isValid = true;

    if (brandRegistration.customer_profile_bundle_sid !== customerProfile.sid) {
      Toaster.showToast(
        'Invalid registration bundle; brand customer sid does not match with returned customer_profile',
        2000,
        Toaster.ToastClasses.error
      );
      isValid = false;
    }

    if (this.checkIsBrandNonStandard(brandRegistration.brand_type)) {
      Toaster.showToast(
        'Please only use standard registrations',
        2000,
        Toaster.ToastClasses.error
      );
      isValid = false;
    }

    if (this.checkIsBrandUnapproved(brandRegistration.status)) {
      Toaster.showToast(
        'Cannot apply an unapproved registration',
        2000,
        Toaster.ToastClasses.error
      );
      isValid = false;
    }

    this.setState({ isCandidateValid: isValid });
    return isValid;
  }

  checkIsBrandNonStandard(brandType) {
    return brandType !== 'STANDARD';
  }

  checkIsBrandUnapproved(status) {
    return status !== 'APPROVED';
  }

  submitCandidateRegistration() {
    if (
      this.checkIsCandidateValid(
        this.state.candidateBrandRegistration,
        this.state.candidateCustomerProfile
      )
    ) {
      RegistrationAPI.createRegistration(
        this.state.candidateBrandRegistration.sid
      )
        .then(() => {
          // Registration succeeded, Refresh page to see it
          location.reload();
        })
        .catch((e) => {
          console.log(`Error submitting candidate registration: ${e}`);
          Toaster.showToast(
            'Could not register, please contact Knock Support',
            2000,
            Toaster.ToastClasses.error
          );
        });
    }
  }

  renderRegistrationInstructions() {
    return (
      <div className="brand-registration-instructions">
        <h3 className="side-margin-lg">Registration FAQs</h3>
        <ul>
          <li
            className="question"
            onClick={this.toggleInstruction.bind(this, 'a')}
          >
            What is Twilio Brand Registration and why is it needed?
          </li>
          <ul
            className={`answer ${!this.state.showInstruction.a ? 'hide' : ''}`}
          >
            <li>
              This is an industry wide effort, led by major carriers like AT&T
              and T-Mobile to cut down on spam traffic on the networks.
              Registering puts us in a compliant state so that Twilio and
              carriers can better identify traffic belonging to legitimate
              businesses like ourselves and our customers, giving each of us a
              Trust Score and helping our communications to avoid getting
              filtered.
            </li>
            <li>
              For more information, read up{' '}
              <a
                href="https://support.twilio.com/hc/en-us/articles/1260800720410-What-is-A2P-10DLC-"
                target="_blank"
                rel="noreferrer"
              >
                here on Twilio
              </a>
              .
            </li>
          </ul>

          <li
            className="question"
            onClick={this.toggleInstruction.bind(this, 'b')}
          >
            What is a Twilio Brand Registration SID?
          </li>
          <ul
            className={`answer ${!this.state.showInstruction.b ? 'hide' : ''}`}
          >
            <li>
              This is a unique identifier for a Brand that you will find on
              Twilio after completing a registration on the Twilio console on
              behalf of the business (i.e. knock customer).
            </li>
            <li>
              For instructions on how to complete a registration on Twilio to
              get a Brand Registration SID, please{' '}
              <a
                href="https://knockr.atlassian.net/wiki/spaces/CO/pages/1707704321/Twilio+Brand+Registration+-+How+To"
                target="_blank"
                rel="noreferrer"
              >
                follow this guide
              </a>
              .
            </li>
          </ul>

          <li
            className="question"
            onClick={this.toggleInstruction.bind(this, 'c')}
          >
            How should I register a business that has multiple Knock
            accounts/companies?
          </li>
          <ul
            className={`answer ${!this.state.showInstruction.c ? 'hide' : ''}`}
          >
            <li>
              A common scenario at Knock: a customer (e.g. ABC Mgmt) is setup
              with multiple Knock companies (e.g. "ABC Hold", "ABC Pilot", "ABC
              Co."). In this case, "ABC Mgmt" is registered once on Twilio to
              get a Brand Registration SID. This Brand Registration SID should
              then be used to register all 3 Knock companies (Hold, Pilot, Co.)
              belonging to that customer.
            </li>
            <li>
              In essence, a Knock company can only be linked to one Twilio Brand
              Registration while a single Twilio Brand Registration can be
              linked to multiple Knock companies.
            </li>
          </ul>

          <li
            className="question"
            onClick={this.toggleInstruction.bind(this, 'd')}
          >
            How should I register Knock companies that are only for internal
            use?
          </li>
          <ul
            className={`answer ${!this.state.showInstruction.d ? 'hide' : ''}`}
          >
            <li>
              There is a Brand Registration on Twilio that is made under Knock's
              name. However, please be careful to use this only for Knock
              companies that are expected to have minimal phone call and
              messaging traffic. Any traffic that looks fraudulent/malicious to
              carriers will directly impact Knock's reputation with them and
              affect our Trust Score.
            </li>
          </ul>

          <li
            className="question"
            onClick={this.toggleInstruction.bind(this, 'e')}
          >
            What happens after a Knock Company is linked to a Twilio Brand
            Registration using this tool?
          </li>
          <ul
            className={`answer ${!this.state.showInstruction.e ? 'hide' : ''}`}
          >
            <li>
              Immediately after linking this Knock company to the Twilio Brand
              Registration, all community relay tracking numbers on the Knock
              company will be programmatically assigned to the Twilio Brand
              Registration.
            </li>
            <li>
              Under the hood, we programmatically setup the other required
              Messaging Service, Campaign Use Case, and Message Samples on
              Twilio for you, so you do not have to do much more than the
              initial creation of the Twilio Brand Registration which requires
              the input of the customer's business information.
            </li>
            <li>
              After the link is created and moving forward, all newly added
              community relay tracking numbers for this registered Knock company
              will automatically be assigned to the Twilio Brand Registration,
              which reduces the amount of maintenance needed by this compliance
              requirement.
            </li>
          </ul>

          <li
            className="question"
            onClick={this.toggleInstruction.bind(this, 'z')}
          >
            Anything else that I should know?
          </li>
          <ul
            className={`answer ${!this.state.showInstruction.z ? 'hide' : ''}`}
          >
            <li>
              A Knock company cannot be unlinked from a Twilio Brand
              Registration once it is set.
            </li>
            <li>
              Be sure to verify that the Twilio Brand Registration details from
              the Lookup are correct for the Knock company you are trying to
              register before you "Confirm Registration"
            </li>
          </ul>
        </ul>
      </div>
    );
  }

  toggleInstruction(section) {
    const showInstruction = this.state.showInstruction;
    showInstruction[section] = !showInstruction[section];
    this.setState({ showInstruction: showInstruction });
  }
}

export default RegistrationPage;
