import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text, makeStyles } from '@knockrentals/knock-shared-web';
import {
  REGISTRATION_LABELS,
  TOTAL_FAILED,
  TOTAL_REGISTERED,
  TOTAL_UNREGISTERED,
} from '../constants';
import './_PropertyPhoneRegistration.scss';

const useStyles = makeStyles({
  propertyStat: {
    marginRight: '12px',
  },
});

export const getRegistrationText = (label, value) => `${label}: ${value || 0}`;

export const registrationStatsOrder = [
  TOTAL_REGISTERED,
  TOTAL_UNREGISTERED,
  TOTAL_FAILED,
];

const PropertyPhoneRegistration = ({
  errorMessage,
  fetchPropertyPhoneRegistration,
  registrationStats,
}) => {
  const classes = useStyles();

  useEffect(() => {
    fetchPropertyPhoneRegistration();
  }, [fetchPropertyPhoneRegistration]);

  return (
    <div className="property-phone-registration-container">
      {registrationStatsOrder.map((statsKey) => {
        const label = REGISTRATION_LABELS[statsKey];
        const value = registrationStats[statsKey];

        return (
          <Text
            className={classes.propertyStat}
            component="span"
            key={statsKey}
          >
            {getRegistrationText(label, value)}
          </Text>
        );
      })}

      {errorMessage && (
        <div>
          <Text color="error" variant="caption">
            {errorMessage}
          </Text>
        </div>
      )}
    </div>
  );
};

PropertyPhoneRegistration.propTypes = {
  errorMessage: PropTypes.string,
  fetchPropertyPhoneRegistration: PropTypes.func,
  registrationStats: PropTypes.shape({
    [TOTAL_FAILED]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    [TOTAL_REGISTERED]: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    [TOTAL_UNREGISTERED]: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
};

export default PropertyPhoneRegistration;
