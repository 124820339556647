import { useState } from 'react';
import { brandContentApi } from './brand-content-api';
import { useTablePagination } from '../../../hooks/useTablePagination';
import usePropertySelection from './use-property-selection.js';
import { ERROR_MESSAGE } from './brand-content-api/brand-content-api.js';

//TODO remove 1 , 2 from page options
export const ROWS_PER_PAGE_OPTIONS = [1, 2, 25, 50, 100, 500];

export const useBrandContent = ({ setErrorMessage }) => {
  const [brandContentByPage, setBrandContentByPage] = useState({});

  const {
    getPageIndex,
    getTotalCount,
    parseQuery,
    reloadTableData,
    rowsPerPage,
    setPageIndex,
    setTotalCount,
    tableRef,
  } = useTablePagination();

  const getCurrentPageProperties = () =>
    brandContentByPage[getPageIndex()] || [];

  const {
    getSelectedProperties,
    isFetchingPropertiesList,
    propertySelectionMap,
    selectAllOnPage,
    selectedPropertyCount,
    updateAllPropertiesSelections,
    updatePropertySelection,
  } = usePropertySelection({
    getCurrentPageProperties,
    setErrorMessage,
  });

  const getCurrentPagePropertyCount = () => getCurrentPageProperties().length;

  const reloadBrandContent = () => {
    setBrandContentByPage({});
    reloadTableData();
  };

  const shouldFetchRequestedPage = (page) => {
    const nextPage = brandContentByPage[page] || [];
    return !nextPage.length;
  };

  const fetchBrandContent = async (query) => {
    try {
      const { page } = query;

      const { brandContent, totalPropertyCount } =
        await brandContentApi.getBrandContent(query);

      setTotalCount(totalPropertyCount);
      setBrandContentByPage((prevState) => ({
        ...prevState,
        [page]: brandContent,
      }));

      return brandContent;
    } catch (_e) {
      setErrorMessage(ERROR_MESSAGE);
      return [];
    }
  };

  const getTableData = async (query) => {
    const defaultSortColumn = 'propertyName';
    const { paginationParams, shouldResetTableData } = parseQuery(
      query,
      defaultSortColumn
    );

    const { page } = paginationParams;

    if (shouldResetTableData) {
      setBrandContentByPage({});
    }

    const shouldFetchNewData =
      shouldResetTableData || shouldFetchRequestedPage(page);

    const brandContent = shouldFetchNewData
      ? await fetchBrandContent(paginationParams)
      : brandContentByPage[page];

    return {
      data: brandContent.map((property) => ({ ...property })),
      page,
      totalCount: getTotalCount(),
    };
  };

  return {
    getCurrentPagePropertyCount,
    getSelectedProperties,
    getTableData,
    isLoadingOverlayVisible: isFetchingPropertiesList,
    propertySelectionMap,
    reloadBrandContent,
    rowsPerPage,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    selectAllOnPage,
    selectedPropertyCount,
    setPageIndex,
    tableRef,
    totalPropertyCount: getTotalCount(),
    updateAllPropertiesSelections,
    updatePropertySelection,
  };
};
