import { useRef, useState } from 'react';
import { dripCampaignsApi } from '../DripCampaignsApi';
import { ERROR_MESSAGES } from '../constants';

export const DEFAULT_ROWS_PER_PAGE = 50;
// TODO - update PAGE_SIZES_OPTIONS with product/UX recommendations
const ROWS_PER_PAGE_OPTIONS = [25, 50, 100, 500];

const useTemplates = ({ setErrorMessage, setIsFetching }) => {
  const [templatesByPage, setTemplatesByPage] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const tableRef = useRef();
  const reloadTableData = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const updateTemplateGridData = () => {
    setTemplatesByPage({});
    reloadTableData();
  };

  const tableDetailsRef = useRef({
    pageIndex: 0,
    shouldLoadPageIndex: false,
    sortColumn: 'templateName',
    sortDirection: 'asc',
    totalTemplateCount: 0,
  });

  const getPageIndex = () => tableDetailsRef.current.pageIndex;
  const setPageIndex = (index) => {
    tableDetailsRef.current.pageIndex = index;
  };

  const getShouldLoadPageIndex = () =>
    tableDetailsRef.current.shouldLoadPageIndex;
  const setShouldLoadPageIndex = (shouldLoad) => {
    tableDetailsRef.current.shouldLoadPageIndex = shouldLoad;
  };

  const getSortColumn = () => tableDetailsRef.current.sortColumn;

  const getSortDirection = () => tableDetailsRef.current.sortDirection;

  const getTotalTemplateCount = () =>
    tableDetailsRef.current.totalTemplateCount;
  const setTotalTemplateCount = (count) => {
    tableDetailsRef.current.totalTemplateCount = count;
  };

  const getPage = (query) => {
    if (getShouldLoadPageIndex()) {
      setShouldLoadPageIndex(false);
      return getPageIndex();
    }
    return query.page;
  };

  const shouldFetchRequestedPage = (page) => {
    const nextPage = templatesByPage[page] || [];
    return !nextPage.length;
  };

  const fetchTableData = (query) => {
    const { page, pageSize } = query;

    const queryParams = {
      page,
      pageSize,
      sortColumn: getSortColumn(),
      sortDirection: getSortDirection(),
    };

    return dripCampaignsApi
      .getTemplates(queryParams)
      .then((responseData) => {
        const { templates = [], totalTemplateCount = 0 } = responseData;

        setTotalTemplateCount(totalTemplateCount);

        setTemplatesByPage((prevState) => ({
          ...prevState,
          [page]: templates,
        }));

        return templates;
      })
      .catch(() => {
        setIsFetching(false);
        setErrorMessage(ERROR_MESSAGES.FETCH);
        return [];
      });
  };

  const getTableData = (query) =>
    new Promise(async (resolve) => {
      const { pageSize } = query;
      const page = getPage(query);
      const hasRowsPerPageChanged = pageSize !== rowsPerPage;

      if (hasRowsPerPageChanged) {
        setRowsPerPage(pageSize);
        setTemplatesByPage({});
      }

      const templates =
        hasRowsPerPageChanged || shouldFetchRequestedPage(page)
          ? await fetchTableData(query)
          : templatesByPage[page];

      resolve({
        data: templates.map((template) => ({ ...template })),
        page: page,
        totalCount: getTotalTemplateCount(),
      });
    });

  return {
    getPageIndex,
    getTableData,
    rowsPerPage,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    setPageIndex,
    setRowsPerPage,
    setShouldLoadPageIndex,
    setTemplatesByPage,
    tableRef,
    totalTemplateCount: getTotalTemplateCount(),
    templatesByPage,
    updateTemplateGridData,
  };
};

export default useTemplates;
