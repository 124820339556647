import React from 'react';
import PropTypes from 'prop-types';

const FacebookIntegrationInfo = ({ integration: { vendor, role, facebook_page_id } }) => (
  <div className="knock-admin-integration-info">
    <div>
      <strong>Integration:</strong> {vendor}
      {role === 'import' ? ' (messages import)' : ''}
    </div>
    <div>
      <strong>Facebook Page Id:</strong>{' '}
      <a href={`//facebook.com/${facebook_page_id}`} target="_blank">
        {facebook_page_id}
      </a>
    </div>
  </div>
);

FacebookIntegrationInfo.propTypes = {
  integration: PropTypes.object
};

export default FacebookIntegrationInfo;
