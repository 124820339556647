export const IntegrationVendorNames = {
  AMSI: 'Amsi',
  APPFOLIO: 'AppFolio',
  ENGRAIN: 'Engrain',
  ENTRATA: 'Entrata',
  FACEBOOK: 'Facebook',
  MRI: 'Mri',
  ONSITE: 'Onsite',
  REALPAGE: 'RealPage',
  RESMAN: 'ResMan',
  YARDI: 'Yardi',
};

export const IntegrationVendorNamesList = Object.values(IntegrationVendorNames);

export const IntegrationVendorNamesLowerCase =
  IntegrationVendorNamesList.reduce((map, name) => {
    const key = name.toUpperCase();
    const value = name.toLowerCase();
    map[key] = value;
    return map;
  }, {});

export const ADD_INTEGRATION_BUTTON_NAMES = [
  IntegrationVendorNames.YARDI,
  IntegrationVendorNames.ENTRATA,
  IntegrationVendorNames.REALPAGE,
  IntegrationVendorNames.RESMAN,
  'AMSI',
  'MRI',
  'APPFOLIO',
  IntegrationVendorNames.ONSITE,
];
