import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { CompanySourcesTab } from '../CompanySourcesTab';
import PropertySourcesTab from '../PropertySourcesTab';
import { Tabs, Tab, ThemeProvider } from '@knockrentals/knock-shared-web';

export const PROPERTY_SOURCES_TAB = 'By Property';
export const COMPANY_SOURCES_TAB = 'By Source';
const sourcesPageTabNames = [PROPERTY_SOURCES_TAB, COMPANY_SOURCES_TAB];

const useStyles = makeStyles({
  sourcePageTabs: {
    '&.MuiTabs-root': {
      borderBottom: '1px solid #e0e0e0',
      margin: '34px 0 37px',
      textTransform: 'upperCase',
    },
  },
});

const SourcesPageTabs = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [companySources, setCompanySources] = useState();

  const handleOnChangeTabs = (_e, selectedTabIndex) => {
    setActiveTabIndex(selectedTabIndex);
  };

  const isActiveTab = (tab) => tab === sourcesPageTabNames[activeTabIndex];

  const classes = useStyles();

  return (
    <React.Fragment>
      <ThemeProvider>
        <Tabs
          ariaLabel="sources-page-tabs"
          className={classes.sourcePageTabs}
          indicatorColor="primary"
          onChange={handleOnChangeTabs}
          textColor="primary"
          value={activeTabIndex}
        >
          {sourcesPageTabNames.map((tabName) => (
            <Tab key={tabName} label={tabName} ariaLabel={tabName} />
          ))}
        </Tabs>
      </ThemeProvider>

      {isActiveTab(PROPERTY_SOURCES_TAB) && <PropertySourcesTab />}

      {isActiveTab(COMPANY_SOURCES_TAB) && (
        <ThemeProvider>
          <CompanySourcesTab
            companySources={companySources}
            setCompanySources={setCompanySources}
          />
        </ThemeProvider>
      )}
    </React.Fragment>
  );
};

export default SourcesPageTabs;
