
import React, { useEffect, useState } from 'react';
import PropTypes, { object } from 'prop-types';
import DataTable from 'material-table';
import {
  LoadingOverlay,
  makeStyles,
  Switch,
  ThemeProvider,
} from '@knockrentals/knock-shared-web';
import { Toaster } from '@knockrentals/knock-react';

import { FilterProperty } from './Components/FilterBoxes';
import { getVirtualAgents, updateVirtualAgent } from '../VirtualAgentAPI';
import { editGenAiEmailEnabled, } from '../aiEmailAPI';

const useStyles = makeStyles({
  filterAndEditContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: '10px',
  }
});

export const FIELDS = {
  RPCC_AGENT_ROLLOVER: 'rpcc_agent_rollover',
  CHAT_ROLLOVER: 'chat_rollover',
  IS_GEN_AI_VOICE_ENABLED: 'is_gen_ai_voice_enabled',
  IS_GEN_AI_CHAT_ENABLED: 'is_gen_ai_chat_enabled',
  IS_GEN_AI_SMS_ENABLED: 'is_gen_ai_sms_enabled',
  IS_GEN_AI_EMAIL_ENABLED: 'gen_ai_enabled',
  RESIDENT_VIRTUAL_AGENT_SMS: 'resident_virtual_agent_sms',
  RESIDENT_VIRTUAL_AGENT_VOICE: 'resident_virtual_agent_voice',
};

export const API_ERROR = 'Error updating virtual agent';

const InternalConfigSettings = ({ companyId, properties }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [virtualAgents, setVirtualAgents] = useState([]);
  const [fetchedVirtualAgents, setFetchedVirtualAgents] = useState(false);

  const [filteredVirtualAgents, setFilteredVirtualAgents] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState(
    properties.map(({ propertyId }) => propertyId)
  );
  const [filters, setFilters] = useState({});

  const tableOptions = {
    actionsColumnIndex: -1,
    draggable: false,
    grouping: false,
    headerStyle: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '32px',
      position: 'static',
    },
    padding: 'dense',
    paging: false,
    rowStyle: {
      fontSize: '14px',
      fontWeight: 400,
    },
    search: false,
    sorting: false,
    toolbar: false,
  };

  useEffect(() => {
    const getVirtualAgentsData = async () => {
      const virtualAgentsResponse = await getVirtualAgents(
        companyId,
        1,
        1000
      );
      const virtualAgents = (virtualAgentsResponse.results || [])
        .map((virtualAgent) => {
          const property = properties.find(
            (property) => property.propertyId === virtualAgent.property_id
          );
          return {
            ...virtualAgent,
            propertyName: property.propertyName,
            ai_email_enabled: property.aiEmailEnabled,
            gen_ai_email_enabled: property.genAiEmailEnabled
          };
        });

      virtualAgents.sort((a, b) => a.propertyName.localeCompare(b.propertyName));
      setVirtualAgents(virtualAgents);
      setFilteredVirtualAgents(virtualAgents)
    };

    if (!fetchedVirtualAgents && properties.length) {
      setIsLoading(true);
      getVirtualAgentsData()
        .catch(() => {
          Toaster.showToast(
            'Error fetching virtual agent data',
            2000,
            Toaster.ToastClasses.error
          );
        })
        .finally(() => {
          setFetchedVirtualAgents(true);
          setIsLoading(false);
        });
    }

  }, [fetchedVirtualAgents, properties, selectedProperties, filters]);

  const applyFilters = (currentFilters, currentSelectedProperties) => {
    const filteredVirtualAgents = virtualAgents.filter((virtualAgent) => {
      const isRowValid = Object.keys(currentFilters).every(key => {
        if (currentFilters[key] === null) {
          return true;
        } else if (key === 'chat_rollover') {
          return (currentFilters[key] && virtualAgent[key] === 'RPCC') || (!currentFilters[key] && virtualAgent[key] === 'NONE');
        } else {
          return virtualAgent[key] === currentFilters[key];
        }
      })

      const isSelected = currentSelectedProperties.length
        ? currentSelectedProperties.includes(virtualAgent.property_id)
        : true;

      return isRowValid && isSelected;
    });

    setFilteredVirtualAgents(filteredVirtualAgents);
  };

  const handlePropertySelect = (properties) => {
    applyFilters(filters, properties)
    setSelectedProperties(properties);
  };

  const saveAiEmail = async (rowData, value) => {
    const aiEmailValue = value ? 'ON' : 'OFF';
    const payload = [
      {
        property_id: rowData.property_id,
        company_id: companyId,
        [FIELDS.IS_GEN_AI_EMAIL_ENABLED]: aiEmailValue
      }
    ];

    try {
      await editGenAiEmailEnabled(payload)
      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);

      const property = properties.find((property) => property.propertyId === rowData.property_id);
      property.genAiEmailEnabled = aiEmailValue;
      rowData.gen_ai_email_enabled = aiEmailValue;

      const existingIdx = filteredVirtualAgents.findIndex((va) => va.id === rowData.id);
      filteredVirtualAgents.splice(existingIdx, 1, rowData);
      setFetchedVirtualAgents([...filteredVirtualAgents]);
    } catch (err) {
      console.error(err);
      Toaster.showToast(
        API_ERROR,
        2000,
        Toaster.ToastClasses.error
      );
    }
  };

  const saveVaData = async (rowData, field, value) => {
    const payload = {
      virtualAgentId: rowData.id,
      propertyId: rowData.property_id,
      field,
      value: field === 'chat_rollover' ? (value ? 'RPCC' : 'NONE') : value
    };

    try {
      await updateVirtualAgent(payload);
      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      rowData[field] = field === 'chat_rollover' ? (value ? 'RPCC' : 'NONE') : value;

      const existingIdx = filteredVirtualAgents.findIndex((va) => va.id === rowData.id);
      setFetchedVirtualAgents([
        ...filteredVirtualAgents.slice(0, existingIdx),
        rowData,
        ...filteredVirtualAgents.slice(existingIdx + 1, filteredVirtualAgents.length)
      ]);
    } catch (err) {
      Toaster.showToast(
        API_ERROR,
        2000,
        Toaster.ToastClasses.error
      );
    }
  };

  const getColumns = () => {
    const tableArray = [
      {
        title: 'Properties',
        width: '100px',
        render: (rowData) => rowData.propertyName
      },
      {
        title: 'RPCC Voice Rollover',
        field: FIELDS.RPCC_AGENT_ROLLOVER,
        width: '100px',
        render: (rowData) => {
          return (
            <Switch
              color="primary"
              name="rpcc-agent-rollover"
              checked={!!rowData.rpcc_agent_rollover}
              onChange={(event) =>
                saveVaData(rowData, FIELDS.RPCC_AGENT_ROLLOVER, event.target.checked)
              }
            />
          );
        }
      },
      {
        title: 'RPCC Chat Rollover',
        field: FIELDS.CHAT_ROLLOVER,
        width: '100px',
        render: (rowData) => {
          return (
            <Switch
              color="primary"
              name="rpcc-chat-rollover"
              checked={rowData.chat_rollover === 'RPCC'}
              onChange={(event) =>
                saveVaData(rowData, FIELDS.CHAT_ROLLOVER, event.target.checked)
              }
            />
          );
        }
      },
      {
        title: 'Gen AI Voice',
        field: FIELDS.IS_GEN_AI_VOICE_ENABLED,
        width: '100px',
        render: (rowData) => {
          return (
            <Switch
              color="primary"
              name="is-gen-ai-voice-enabled"
              checked={!!rowData.is_gen_ai_voice_enabled}
              onChange={(event) =>
                saveVaData(rowData, FIELDS.IS_GEN_AI_VOICE_ENABLED, event.target.checked)
              }
            />
          );
        },
      },
      {
        title: 'Gen AI Chat',
        field: FIELDS.IS_GEN_AI_CHAT_ENABLED,
        width: '100px',
        render: (rowData) => {
          return (
            <Switch
              color="primary"
              name="is-gen-ai-chat-enabled"
              checked={!!rowData.is_gen_ai_chat_enabled}
              onChange={(event) =>
                saveVaData(rowData, FIELDS.IS_GEN_AI_CHAT_ENABLED, event.target.checked)
              }
            />
          );
        },
      },
      {
        title: 'Gen AI SMS',
        field: FIELDS.IS_GEN_AI_SMS_ENABLED,
        width: '100px',
        render: (rowData) => {
          return (
            <Switch
              color="primary"
              name="is-gen-ai-sms-enabled"
              checked={!!rowData.is_gen_ai_sms_enabled}
              onChange={(event) =>
                saveVaData(rowData, FIELDS.IS_GEN_AI_SMS_ENABLED, event.target.checked)
              }
            />
          );
        },
      },
      {
        title: 'Gen AI Email',
        field: FIELDS.IS_GEN_AI_EMAIL_ENABLED,
        width: '100px',
        render: (rowData) => {
          return (
            <Switch
              color="primary"
              name="is-gen-ai-email-enabled"
              checked={rowData.gen_ai_email_enabled === "ON"}
              onChange={(event) =>
                saveAiEmail(rowData, event.target.checked)
              }
            />
          );
        },
      },
      {
        title: 'Resident SMS',
        field: FIELDS.RESIDENT_VIRTUAL_AGENT_SMS,
        width: '100px',
        render: (rowData) => {
          return (
            <Switch
              color="primary"
              name="resident-virtual-agent-sms"
              checked={!!rowData.resident_virtual_agent_sms}
              onChange={(event) =>
                saveVaData(rowData, FIELDS.RESIDENT_VIRTUAL_AGENT_SMS, event.target.checked)
              }
            />
          );
        },
      },
      {
        title: 'Resident Voice',
        field: FIELDS.RESIDENT_VIRTUAL_AGENT_VOICE,
        width: '100px',
        render: (rowData) => {
          return (
            <Switch
              color="primary"
              name="resident-virtual-agent-voice"
              checked={!!rowData.resident_virtual_agent_voice}
              onChange={(event) =>
                saveVaData(rowData, FIELDS.RESIDENT_VIRTUAL_AGENT_VOICE, event.target.checked)
              }
            />
          );
        },
      },
    ]

    return tableArray;
  };

  return (
    <ThemeProvider>
      <div className={classes.filterAndEditContainer}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <FilterProperty
            properties={properties.map((property) => ({
              id: property.propertyId,
              name: property.propertyName,
              selected: selectedProperties.includes(property.propertyId),
            }))}
            onPropertySelect={handlePropertySelect}
          />
        </div>
      </div>
      <LoadingOverlay
        open={isLoading}
        style={{ position: 'absolute', opacity: '0.5' }}
      />
      <DataTable
        className="scheduling-grid-data-table"
        columns={getColumns(classes)}
        data={filteredVirtualAgents}
        options={tableOptions}
      />
    </ThemeProvider>
  );
};

InternalConfigSettings.propTypes = {
  companyId: PropTypes.number,
  properties: PropTypes.arrayOf(object)
};

export default InternalConfigSettings;
