import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  Card,
  CardContent,
  CardHeader,
  CloseIcon,
  IconButton,
  InputText,
  LoadingOverlay,
  makeStyles,
  NamedColors,
  Text,
} from '@knockrentals/knock-shared-web';
import { ErrorDialog } from '../../common/ErrorDialog';
import { QuickReplyDataTable } from './QuickReplyDataTable';
import { QuickReplyActions } from './QuickReplyActions';
import { ConfirmationDialog } from './ConfirmationDialog';
import { EmailTemplateEditor } from '../../../../EmailTemplateEditor';
import useQuickReplies from './useQuickReplies';
import { CharacterLimitedTextArea } from '../../common/CharacterLimitedTextArea';
import { ACTION_TYPES, CHAR_LIMITS, QUICK_REPLY } from '../constants';
import './_QuickReply.scss';

const EMAIL_PLACEHOLDER_TEXT = 'Create a rich text email reply...';

export const VALIDATION_MESSAGES = {
  TITLE: 'A title is required',
  EMAIL: 'An email or sms message is required',
  SMS: 'An sms or email message is required',
};

const useStyles = makeStyles((theme) => ({
  quickReplyCard: {
    borderRadius: 0,
    display: 'flex',
    flexFlow: 'column',
    height: '100vh',
  },
  quickReplyCardContent: {
    backgroundColor: '#F7F7F8',
    flex: 1,
    overflowY: 'auto',
    padding: '26px 40px 40px',
    '& .cke_chrome': {
      border: `2px solid ${NamedColors.slate[300]}`,
    },
    '& .MuiTableHead-root': {
      boxShadow: theme.shadows[2],
    },
    '& .error': {
      '& .cke_chrome': {
        borderColor: '#F599A8',
      },
    },
  },
  textLabel: { marginLeft: '5px', marginBottom: '3px' },
  emailErrorText: {
    marginTop: '3px',
    marginLeft: '5px',
  },
  quickReplyHeader: {
    zIndex: 1,
    boxShadow:
      '0px 2px 12px -4px rgba(24, 39, 75, 0.04), 0px 4px 16px -2px rgba(24, 39, 75, 0.02)',
  },
}));

const QuickReply = ({
  closeDrawer,
  mergeTags,
  quickReply: { quickReplyId } = {},
  resetTableData,
}) => {
  const classes = useStyles();

  const {
    apiErrorMessage,
    email,
    deleteQuickReply,
    isEditing,
    isEnabledPropertyMap,
    isLoadingOverlayVisible,
    isSaveButtonDisabled,
    properties,
    saveQuickReply,
    setApiErrorMessage,
    setIsEnabledPropertyMap,
    sms,
    title,
    updateQuickReply,
  } = useQuickReplies({ closeDrawer, quickReplyId, resetTableData });

  const [actionType, setActionType] = useState(null);
  const [errorMessages, setErrorMessages] = useState({});

  const clearErrorMessage = (field) => {
    setErrorMessages((prevState) => {
      const updatedMessages =
        field === 'title' ? { [field]: '' } : { email: '', sms: '' };

      return { ...prevState, ...updatedMessages };
    });
  };

  const handleOnChangeEmail = (value) => {
    if (errorMessages.email || errorMessages.sms) {
      clearErrorMessage('email');
    }
    updateQuickReply(QUICK_REPLY.EMAIL)(value);
  };

  const validateQuickReply = () => {
    const messages = {};
    if (!title.trim()) {
      messages.title = VALIDATION_MESSAGES.TITLE;
    }

    if (!email.trim() && !sms.trim()) {
      if (!email.trim()) {
        messages.email = VALIDATION_MESSAGES.EMAIL;
      }
      if (!sms.trim()) {
        messages.sms = VALIDATION_MESSAGES.SMS;
      }
    }

    if (Object.keys(messages).length > 0) {
      return messages;
    }
  };

  const handleSaveQuickReply = async () => {
    setActionType(null);
    saveQuickReply();
  };

  const handleDeleteQuickReply = () => {
    setActionType(null);
    deleteQuickReply();
  };

  const handleConfirmation = () => {
    actionType === ACTION_TYPES.SAVE
      ? handleSaveQuickReply()
      : handleDeleteQuickReply();
  };

  const handleCloseErrorDialog = () => {
    () => setApiErrorMessage('');
    closeDrawer();
  };

  const handleSaveOnClick = () => {
    if (Object.values(errorMessages).filter((message) => message).length > 0) {
      return;
    }

    const messages = validateQuickReply();
    if (messages) {
      setErrorMessages(messages);
      return;
    }

    setActionType(ACTION_TYPES.SAVE);
  };

  return (
    <div className="quick-reply-container">
      <LoadingOverlay open={isLoadingOverlayVisible} />

      <ErrorDialog
        closeDialog={handleCloseErrorDialog}
        errorMessage={apiErrorMessage}
        isOpen={Boolean(apiErrorMessage)}
      />

      <ConfirmationDialog
        isSaving={actionType === ACTION_TYPES.SAVE}
        isEditing={isEditing}
        handleConfirmation={handleConfirmation}
        isOpen={Boolean(actionType)}
        closeDialog={() => setActionType(null)}
      />

      <Card elevation={0} className={classes.quickReplyCard}>
        <CardHeader
          action={
            <IconButton onClick={closeDrawer}>
              <CloseIcon />
            </IconButton>
          }
          className={classes.quickReplyHeader}
          subheader={isEditing ? title : 'Create New QR'}
          subheaderTypographyProps={{ color: 'textPrimary', variant: 'h6' }}
          title="COMPANY QRS"
          titleTypographyProps={{ color: 'textPrimary', variant: 'body2' }}
        />

        <CardContent className={classes.quickReplyCardContent}>
          <InputText
            error={Boolean(errorMessages.title)}
            helperText={errorMessages.title}
            fullWidth
            inputProps={{ maxLength: 100 }}
            label="Title"
            name="title"
            onChange={(e) =>
              updateQuickReply(QUICK_REPLY.TITLE)(e.target.value)
            }
            onFocus={() => {
              clearErrorMessage('title');
            }}
            requiredMessage="required"
            value={title}
          />

          <div
            className={classnames(
              'email-container',
              errorMessages.email && 'error'
            )}
          >
            <Text
              variant="subtitle2"
              color={errorMessages.email ? 'error' : 'secondary'}
              className={classes.textLabel}
            >
              Email
            </Text>

            <EmailTemplateEditor
              content={email}
              isSourceModeEnabled
              mergeTags={mergeTags}
              minResizeWidth={'100%'}
              name="quick-replies-email"
              onChange={handleOnChangeEmail}
              placeHolderText={EMAIL_PLACEHOLDER_TEXT}
            />

            {errorMessages.email && (
              <Text
                color="error"
                className={classes.emailErrorText}
                variant="caption"
              >
                {errorMessages.email}
              </Text>
            )}
          </div>

          <div className="sms-container">
            <CharacterLimitedTextArea
              characterLimit={CHAR_LIMITS.quickReplySms}
              error={Boolean(errorMessages.sms)}
              helperText={errorMessages.sms}
              label="SMS"
              onChange={(e) =>
                updateQuickReply(QUICK_REPLY.SMS)(e.target.value)
              }
              onFocus={() => {
                clearErrorMessage('sms');
              }}
              value={sms}
            />
          </div>

          {isEditing && (
            <div className="assigned-properties">
              <Text
                variant="subtitle2"
                color="secondary"
                className={classes.textLabel}
              >
                Assigned Properties
              </Text>
              <QuickReplyDataTable
                isEnabledPropertyMap={isEnabledPropertyMap}
                properties={properties}
                setIsEnabledPropertyMap={setIsEnabledPropertyMap}
              />
            </div>
          )}
        </CardContent>

        <QuickReplyActions
          handleCancelOnClick={closeDrawer}
          handleDeleteOnClick={() => setActionType(ACTION_TYPES.DELETE)}
          handleSaveOnClick={handleSaveOnClick}
          isDeleteButtonVisible={isEditing}
          isSaveButtonDisabled={isSaveButtonDisabled}
        />
      </Card>
    </div>
  );
};

QuickReply.propTypes = {
  quickReply: PropTypes.object.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  mergeTags: PropTypes.array,
  resetTableData: PropTypes.func,
};

export default QuickReply;
