import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Switch } from '@knockrentals/knock-shared-web';
import { IndeterminateSwitchIcon } from '../../../../Components/Icons';

const IndeterminateSwitch = ({
  ariaLabel = '',
  isChecked = false,
  isIndeterminate = false,
  name,
  onChangeHandler,
}) => {
  const handleOnChange = (e) => {
    onChangeHandler({ [e.target.name]: e.target.checked });
  };

  const setDefaultIsChecked = (e) => {
    e.preventDefault();
    onChangeHandler({ [name]: true });
  };

  if (isIndeterminate) {
    return (
      <IconButton aria-label={ariaLabel} onClick={setDefaultIsChecked}>
        <IndeterminateSwitchIcon />
      </IconButton>
    );
  }

  return (
    <Switch
      checked={isChecked || false}
      color="primary"
      disableRipple
      inputProps={{ 'aria-label': ariaLabel }}
      name={name}
      onChange={handleOnChange}
    />
  );
};

IndeterminateSwitch.propTypes = {
  ariaLabel: PropTypes.string,
  isChecked: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  name: PropTypes.string,
  onChangeHandler: PropTypes.func,
};

export default IndeterminateSwitch;
