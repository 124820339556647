import { useCallback, useState } from 'react';
import RegistrationAPI from '../../../Registration/RegistrationAPI';
import {
  NO_RELAY_DEFAULTS,
  NO_RELAY_PHONE,
  REGISTRATION_LOADING_STATE,
  TOTAL_FAILED,
  TOTAL_REGISTERED,
  TOTAL_UNREGISTERED,
} from '../constants';

const getRelayPhoneMap = (phoneEntries = []) =>
  phoneEntries.reduce(
    (relayMap, phoneEntry) => {
      relayMap[phoneEntry.id] = phoneEntry;
      return relayMap;
    },
    { [NO_RELAY_DEFAULTS]: NO_RELAY_PHONE }
  );

export const getErrorMessage = (propertyName) =>
  `An unexpected error has occurred. The phone registration for ${propertyName} is currently not available`;

export const usePropertyPhoneRegistration = ({ propertyId, propertyName }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [registrationStats, setRegistrationStats] = useState(null);
  const [relayPhoneMap, setRelayPhoneMap] = useState({});

  const fetchPropertyPhoneRegistration = useCallback(async () => {
    if (!registrationStats) {
      try {
        const response = await RegistrationAPI.getPropertyPhoneRegistration(
          propertyId
        );

        setRegistrationStats({
          [TOTAL_FAILED]: response.total_failed,
          [TOTAL_REGISTERED]: response.total_registered,
          [TOTAL_UNREGISTERED]: response.total_unregistered,
        });

        const relayPhoneMap = getRelayPhoneMap(response.phoneEntries);

        setRelayPhoneMap(relayPhoneMap);
      } catch {
        setErrorMessage(getErrorMessage(propertyName));
      }
    }
  }, [registrationStats, propertyId, propertyName]);

  return {
    errorMessage,
    fetchPropertyPhoneRegistration,
    registrationStats: registrationStats || REGISTRATION_LOADING_STATE,
    relayPhoneMap,
  };
};
