import { analyticsApiRemote } from '../../../Services/RemoteService';
import { ANALYTICS_QUERIES } from './queries';


const responseHandler = async (response) => {
  const { data, errors } = response;

  if (errors) {
    const errorMessages = errors.map(err => err.message);
    throw new Error(`Request returned error: ${errorMessages.join(' ')}`);
  }

  return data;
};


const makeGraphQLRequest = async (query, operationName, variables) => {
  const response = await analyticsApiRemote
    .post('/graphql', {
      query,
      variables,
      operationName,
    });

    const responseJson = await response.json();

    return await responseHandler(responseJson);
};


export const getUsers = async (payload) => {
  const response = await makeGraphQLRequest(
    ANALYTICS_QUERIES.GET_USERS.query,
    ANALYTICS_QUERIES.GET_USERS.operationName,
    {
      input: {
        limit: payload.pageSize,
        offset: payload.pageIndex,
      }
    }
  );

  const { getSsdaUsers } = response;
  return getSsdaUsers;
};


export const createUser = async (payload) => {
  const response = await makeGraphQLRequest(
    ANALYTICS_QUERIES.CREATE_USER.query,
    ANALYTICS_QUERIES.CREATE_USER.operationName,
    {
      input: {
        userInfoInput: payload.userInfo,
        ipAddresses: payload.ipAddresses,
      }
    },
  );

  const { createSsdaUser } = response;
  return createSsdaUser;
};


export const deleteUser = async (payload) => {
  const response = await makeGraphQLRequest(
    ANALYTICS_QUERIES.DELETE_USER.query,
    ANALYTICS_QUERIES.DELETE_USER.operationName,
    {
      input: {
        usernames: payload.usernames,
      }
    }
  );

  const { deleteSsdaUsers } = response;
  return deleteSsdaUsers;
};


export const removeIPAddress = async (payload) => {
  const response = await makeGraphQLRequest(
    ANALYTICS_QUERIES.DELETE_IP_ADDRESSES.query,
    ANALYTICS_QUERIES.DELETE_IP_ADDRESSES.operationName,
    {
      input: {
        ipAddresses: payload.ipAddresses,
      }
    },
  );

  const { deleteIpAddresses } = response;
  return deleteIpAddresses;
};
