import React from 'react';

const InputWrapper = ({ handleChange, name, placeholder, value }) => (
    <div className="knock-react-flex">
        <div className="property-info-edit-field-input">
            <input
                className="knock-react-input"
                maxLength="100"
                name={name}
                onChange={handleChange}
                placeholder={placeholder}
                value={value}
            />
        </div>
    </div>
);

const KeySellingFeatures = ({ community, handleChange, location, units }) => (
    <div className="full-width">
        <h2 className="side-margin-lg">Key Selling Features</h2>
        <div className="knock-react-flex new-line">
            <div className="property-info-edit-field-label">Location</div>
            <div className="full-width">
                <InputWrapper
                    handleChange={handleChange}
                    name={'keySellingFeatures.location[0]'}
                    placeholder={'e.g. Walking distance from park'}
                    value={location[0] || ''}
                />
                <InputWrapper
                    handleChange={handleChange}
                    name={'keySellingFeatures.location[1]'}
                    placeholder={'e.g. Convenient to airport'}
                    value={location[1] || ''}
                />
                <InputWrapper
                    handleChange={handleChange}
                    name={'keySellingFeatures.location[2]'}
                    placeholder={'e.g. Convenient to l-526'}
                    value={location[2] || ''}
                />
            </div>
        </div>
        <div className="knock-react-flex new-line">
            <div className="property-info-edit-field-label">Unit</div>
            <div className="full-width">
                <InputWrapper
                    handleChange={handleChange}
                    name={'keySellingFeatures.units[0]'}
                    placeholder={'e.g. Large walk-in closet in every home'}
                    value={units[0] || ''}
                />
                <InputWrapper
                    handleChange={handleChange}
                    name={'keySellingFeatures.units[1]'}
                    placeholder={'e.g. Beautiful kitchens'}
                    value={units[1] || ''}
                />
                <InputWrapper
                    handleChange={handleChange}
                    name={'keySellingFeatures.units[2]'}
                    placeholder={'e.g. Relaxing deep soaker tub'}
                    value={units[2] || ''}
                />
            </div>
        </div>
        <div className="knock-react-flex new-line">
            <div className="property-info-edit-field-label">Community</div>
            <div className="full-width">
                <InputWrapper
                    handleChange={handleChange}
                    name={'keySellingFeatures.community[0]'}
                    placeholder={'e.g. 15,000 sqft dog park'}
                    value={community[0] || ''}
                />
                <InputWrapper
                    handleChange={handleChange}
                    name={'keySellingFeatures.community[1]'}
                    placeholder={'e.g. State-of-the-art fitness center'}
                    value={community[1] || ''}
                />
                <InputWrapper
                    handleChange={handleChange}
                    name={'keySellingFeatures.community[2]'}
                    placeholder={'e.g. Gorgeous swimming pool'}
                    value={community[2] || ''}
                />
            </div>
        </div>
    </div>
);

export default KeySellingFeatures;
