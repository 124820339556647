import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, makeStyles } from '@knockrentals/knock-shared-web';
import { DripCampaignCard } from '../../DripCampaignCard';
import FeatureDrawer from '../../../common/FeatureDrawer';
import FeatureCardContent from '../../../common/FeatureCardContent';
import { DrawerActions } from '../../DrawerActionButtons';
import { Campaign } from '../Campaign';
import { CreateNewButton } from '../CreateNewButton';
import { SelectExistingCampaign } from './SelectExistingCampaign';

export const SUBHEADER_TEXT = 'Create Campaign';
const DESCRIPTION = 'Create based on existing Campaign';

const useStyles = makeStyles({
  descriptionText: {
    paddingTop: '15px',
  },
});

const CreateCampaign = ({ closeDrawer, reloadCampaignsViewData }) => {
  const classes = useStyles();
  const [campaign, setCampaign] = useState(null);

  return (
    <DripCampaignCard closeDrawer={closeDrawer} subheader={SUBHEADER_TEXT}>
      <FeatureDrawer
        isOpen={Boolean(campaign)}
        ModalProps={{ hideBackdrop: true }}
      >
        <Campaign
          closeDrawer={() => setCampaign(null)}
          closeParentDrawer={closeDrawer}
          isOriginCreateCampaign
          reloadCampaignsViewData={reloadCampaignsViewData}
          selectedCampaign={campaign}
        />
      </FeatureDrawer>

      <FeatureCardContent>
        <CreateNewButton
          onClick={() => {
            setCampaign({});
          }}
        />

        <Text className={classes.descriptionText} color="textSecondary">
          {DESCRIPTION}
        </Text>

        <SelectExistingCampaign
          setCampaign={setCampaign}
          isOriginCreateCampaign
        />
      </FeatureCardContent>

      <DrawerActions />
    </DripCampaignCard>
  );
};

CreateCampaign.propTypes = {
  closeDrawer: PropTypes.func,
  reloadCampaignsViewData: PropTypes.func,
};

export default CreateCampaign;
