import React from 'react';
import PropTypes from 'prop-types';

import { Draggable } from "react-beautiful-dnd";

const IncludeInQuickLaunchItem = ({ label, checked, onChange, name, id, idx }) => {
  return (
    <Draggable key={id} draggableId={id.toString()} index={idx}>
      {(provided) => (
        <div className="property-info-include-in-quick-launch-item-draggable-container"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{ ...provided.draggableProps.style }}
        >
          <div className="property-info-include-in-quick-launch-item">
            <div className='enable-button-container'>
              <input
                className='enable-button'
                type="checkbox"
                checked={checked}
                onChange={onChange}
                name={name}
              />
            </div>
            <div>
              <label>{label}</label>
            </div>
            <div className='drag-icon-container'>
              <i className="drag-icon fa fa-reorder fw" />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

IncludeInQuickLaunchItem.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  idx: PropTypes.number
};

export default IncludeInQuickLaunchItem;
