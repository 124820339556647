require('dotenv').config({ silent: true });

let BASE_API_URI = process.env.REACT_APP_BASE_API_URI;

const BASE_ANALYTICS_API_URI = process.env.REACT_APP_BASE_ANALYTICS_API_URI;
const BASE_AI_URI = process.env.REACT_APP_BASE_AI_URI;
const BASE_ML_URI = process.env.REACT_APP_BASE_ML_URI;
const BASE_ANALYTICS_URI = process.env.REACT_APP_BASE_ANALYTICS_URI;
const BASE_PHONE_REGISTRATION_URI =
  process.env.REACT_APP_BASE_PHONE_REGISTRATION_URI;
const BASE_WEBAPP_URI = process.env.REACT_APP_BASE_WEBAPP_URI;
const DEPLOYMENT_VERSION = process.env.REACT_APP_DEPLOYMENT_VERSION;
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const FACEBOOK_APPLICATION_ID = process.env.REACT_APP_FACEBOOK_APPLICATION_ID;
const LAUNCH_DARKLY_CLIENTSIDE_ID =
  process.env.REACT_APP_LAUNCH_DARKLY_CLIENTSIDE_ID;
const UNIFIED_LOGIN_URL = process.env.REACT_APP_UNIFIED_LOGIN_URL;
const REALPAGE_LOGIN_URL = process.env.REACT_APP_REALPAGE_LOGIN_URL;
const AUTH_REDIRECT_URL = process.env.REACT_APP_AUTH_REDIRECT_URL;
const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
const UNIFIED_API_URL = process.env.REACT_APP_UNIFIED_API_URL;
const UNIFIED_SCOPE = process.env.REACT_APP_UNIFIED_SCOPE;

// Separate flag to avoid errors resulting from different dev environment configurations
// between knock-vagrant and knock-docker

const DEV_ENVIRONMENT_TYPE = process.env.REACT_APP_DEV_ENVIRONMENT_TYPE;

if (
  process.env.NODE_ENV === 'development' &&
  DEV_ENVIRONMENT_TYPE === 'docker'
) {
  // env variables that need to be different from the defaults above for docker dev environment
  BASE_API_URI = 'http://localhost:5000/v1/';
}

export {
  BASE_API_URI,
  BASE_AI_URI,
  BASE_ML_URI,
  BASE_ANALYTICS_URI,
  BASE_ANALYTICS_API_URI,
  BASE_PHONE_REGISTRATION_URI,
  BASE_WEBAPP_URI,
  DEPLOYMENT_VERSION,
  ENVIRONMENT,
  FACEBOOK_APPLICATION_ID,
  LAUNCH_DARKLY_CLIENTSIDE_ID,
  UNIFIED_LOGIN_URL,
  REALPAGE_LOGIN_URL,
  AUTH_REDIRECT_URL,
  AUTH_CLIENT_ID,
  UNIFIED_API_URL,
  UNIFIED_SCOPE,
};
