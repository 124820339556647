import React, { Component } from 'react';
import RelayUsernameField from '../RelayUsernameField/RelayUsernameField';
import { Modal, ModalHeader, ModalContent, ModalFooter, Toaster } from '@knockrentals/knock-react';
import { Formik } from 'formik';

class AddPropertyModal extends Component {
  state = {
    isEmailValid: false,
  };

  render() {
    return (
      <Modal className="add-property-modal">
        <Formik
          initialValues={{
            propertyName: '',
            propertyAddress: '',
            propertyCity: '',
            propertyState: '',
            propertyZip: '',

            propertyOwner: '',
            knockEmail: '',
          }}
          onSubmit={this.onConfirm.bind(this)}
          validate={this.validate}
        >
          {props => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <ModalHeader>
                <h2>Add New Property</h2>
              </ModalHeader>
              <ModalContent>
                <div className="property-info-edit-container">
                  <div className="full-width">
                    <div className="knock-react-flex">
                      <div className="property-info-edit-field-label">Property Name</div>
                      <div className="property-info-edit-field-input">
                        <input
                          value={props.values.propertyName}
                          name="propertyName"
                          onChange={props.handleChange}
                          className="knock-react-input"
                          placeholder="ABC Apartment Homes"
                          type="text"
                        />
                        {!!props.errors.propertyName && (
                          <span className="knock-react-form-hint knock-react-form-hint-error">
                            {props.errors.propertyName}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="knock-react-flex">
                      <div className="property-info-edit-field-label">Address</div>
                      <div className="property-info-edit-field-input">
                        <input
                          value={props.values.propertyAddress}
                          name="propertyAddress"
                          onChange={props.handleChange}
                          className="knock-react-input"
                          placeholder="Street address"
                          type="text"
                        />
                        {!!props.errors.propertyAddress && (
                          <span className="knock-react-form-hint knock-react-form-hint-error">
                            {props.errors.propertyAddress}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="knock-react-flex">
                      <div className="property-info-edit-field-label">City</div>
                      <div className="property-info-edit-field-input">
                        <input
                          value={props.values.propertyCity}
                          name="propertyCity"
                          onChange={props.handleChange}
                          className="knock-react-input"
                          placeholder="City"
                          type="text"
                        />
                        {!!props.errors.propertyCity && (
                          <span className="knock-react-form-hint knock-react-form-hint-error">
                            {props.errors.propertyCity}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="knock-react-flex">
                      <div className="property-info-edit-field-label">State</div>
                      <div className="property-info-edit-field-input">
                        <select
                          value={props.values.propertyState}
                          name="propertyState"
                          onChange={props.handleChange}
                        >
                          <option value="">--- Select State ---</option>
                          <option value="AL">Alabama - AL</option>
                          <option value="AK">Alaska - AK</option>
                          <option value="AZ">Arizona - AZ</option>
                          <option value="AR">Arkansas - AR</option>
                          <option value="CA">California - CA</option>
                          <option value="CO">Colorado - CO</option>
                          <option value="CT">Connecticut - CT</option>
                          <option value="DE">Delaware - DE</option>
                          <option value="FL">Florida - FL</option>
                          <option value="GA">Georgia - GA</option>
                          <option value="HI">Hawaii - HI</option>
                          <option value="ID">Idaho - ID</option>
                          <option value="IL">Illinois - IL</option>
                          <option value="IN">Indiana - IN</option>
                          <option value="IA">Iowa - IA</option>
                          <option value="KS">Kansas - KS</option>
                          <option value="KY">Kentucky - KY</option>
                          <option value="LA">Louisiana - LA</option>
                          <option value="ME">Maine - ME</option>
                          <option value="MD">Maryland - MD</option>
                          <option value="MA">Massachusetts - MA</option>
                          <option value="MI">Michigan - MI</option>
                          <option value="MN">Minnesota - MN</option>
                          <option value="MS">Mississippi - MS</option>
                          <option value="MO">Missouri - MO</option>
                          <option value="MT">Montana - MT</option>
                          <option value="NE">Nebraska - NE</option>
                          <option value="NV">Nevada - NV</option>
                          <option value="NH">New Hampshire - NH</option>
                          <option value="NJ">New Jersey - NJ</option>
                          <option value="NM">New Mexico - NM</option>
                          <option value="NY">New York - NY</option>
                          <option value="NC">North Carolina - NC</option>
                          <option value="ND">North Dakota - ND</option>
                          <option value="OH">Ohio - OH</option>
                          <option value="OK">Oklahoma - OK</option>
                          <option value="OR">Oregon - OR</option>
                          <option value="PA">Pennsylvania - PA</option>
                          <option value="RI">Rhode Island - RI</option>
                          <option value="SC">South Carolina - SC</option>
                          <option value="SD">South Dakota - SD</option>
                          <option value="TN">Tennessee - TN</option>
                          <option value="TX">Texas - TX</option>
                          <option value="UT">Utah - UT</option>
                          <option value="VT">Vermont - VT</option>
                          <option value="VA">Virginia - VA</option>
                          <option value="WA">Washington - WA</option>
                          <option value="WV">West Virginia - WV</option>
                          <option value="WI">Wisconsin - WI</option>
                          <option value="WY">Wyoming - WY</option>
                        </select>
                        {!!props.errors.propertyState && (
                          <span className="knock-react-form-hint knock-react-form-hint-error">
                            {props.errors.propertyState}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="knock-react-flex">
                      <div className="property-info-edit-field-label">Zipcode</div>
                      <div className="property-info-edit-field-input">
                        <input
                          value={props.values.propertyZip}
                          name="propertyZip"
                          pattern="[0-9\-]*"
                          onChange={props.handleChange}
                          className="knock-react-input"
                          placeholder="Zip"
                        />
                        {!!props.errors.propertyZip && (
                          <span className="knock-react-form-hint knock-react-form-hint-error">
                            {props.errors.propertyZip}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="full-width">
                    <div className="half-width inline-block">
                      <div className="knock-react-flex">
                        <div className="property-info-edit-field-label">Owner</div>
                        <div className="property-info-edit-field-input">
                          <select
                            value={props.values.propertyOwner}
                            name="propertyOwner"
                            onChange={props.handleChange}
                          >
                            <option value="">--- Select User ---</option>
                            {this.props.teams.map(team => {
                              return (
                                <optgroup key={team.leasing_team_id} label={team.leasing_team_name}>
                                  {team.users.map(user => {
                                    return (
                                      <option key={user.user_id} value={user.user_id}>
                                        {user.first_name} {user.last_name} ({user.email})
                                      </option>
                                    );
                                  })}
                                </optgroup>
                              );
                            })}
                          </select>
                          {!!props.errors.propertyOwner && (
                            <span className="knock-react-form-hint knock-react-form-hint-error">
                              {props.errors.propertyOwner}
                            </span>
                          )}
                        </div>
                      </div>

                      <RelayUsernameField
                        relayUsername={props.values.knockEmail}
                        onChange={(value) => { props.setFieldValue('knockEmail', value); }}
                        isValid={this.state.isEmailValid}
                        setIsValid={this.setIsEmailValid}
                      />
                    </div>
                  </div>
                </div>
              </ModalContent>
              <ModalFooter>
                <button
                  className="knock-react-button btn-primary"
                  onClick={this.onCancel.bind(this)}
                >
                  <i className="fa fa-lg fa-times-circle" /> Cancel
                </button>
                <button className="knock-react-button btn-success" type="submit">
                  <i className="fa fa-lg fa-check-circle" /> Create Property
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  setIsEmailValid = (isEmailValid) => {
    this.setState({ isEmailValid });
  }

  relayUsernameChanged(value, isValid) {
    this.setState({ knockEmail: value, isEmailValid: isValid });
  }

  inputChanged(field) {
    return event => {
      if (event.target.validity.valid) {
        const update = {
          [field]: event.target.value,
        };

        if (field === 'propertyName') {
          update.defaultSubjectLine = `Inquiry at ${update['propertyName']}`;
        }

        this.setState(update);
      }
    };
  }

  onConfirm(values) {
    if (!this.state.isEmailValid) {
      Toaster.showToast(
        'Email username must be available',
        2000,
        Toaster.ToastClasses.error
      );
      return;
    }

    this.props.toggleModal(false);

    this.props.createProperty(values.propertyOwner, {
      location: {
        name: values.propertyName,
        address: {
          house: '',
          street: values.propertyAddress,
          city: values.propertyCity,
          state: values.propertyState,
          zip: values.propertyZip,
        },
      },
      social: {
        knock_email: values.knockEmail,
        email_subject: values.defaultSubjectLine,
      },
      leasing: {
        terms: {},
        application: {},
      },
    });
  }

  validate(values) {
    const errors = {};

    if (!values.propertyName) {
      errors.propertyName = 'Required';
    }

    if (!values.propertyAddress) {
      errors.propertyAddress = 'Required';
    }

    if (!values.propertyCity) {
      errors.propertyCity = 'Required';
    }

    if (!values.propertyState) {
      errors.propertyState = 'Required';
    }

    if (!values.propertyZip) {
      errors.propertyZip = 'Required';
    }

    if (!values.propertyOwner) {
      errors.propertyOwner = 'Required';
    }

    if (!values.knockEmail) {
      errors.knockEmail = 'Required';
    }

    return errors;
  }

  onCancel() {
    this.props.toggleModal(false);
  }
}

export default AddPropertyModal;
