import React, { Component } from 'react';
import UtilitiesAPI from './UtilitiesAPI';
import ForgetGuestcardConfirmation from './ForgetGuestcardConfirmation';

import { PageContainer, Toaster } from '@knockrentals/knock-react';
import './_utilitiesPage.scss';


class UtilitiesPage extends Component {
  state = {
    isLoading: false,
    streamIdToForgetInput: '',
    isConfirmingForgetGuestcard: null,
    residentStreamIdToInheritSmsConsentInput: ''
  };

  render() {
    return (
      <PageContainer>
        <h1>Utilities</h1>

        <div className="forget-gc-container">

          <h3 className="utilities-title">
            <i className="fa fa-user-times" /> Forget guestcard:
          </h3>

          {this.state.isConfirmingForgetGuestcard ? (
            <ForgetGuestcardConfirmation
              isConfirmingForgetGuestcard={this.state.isConfirmingForgetGuestcard}
              onSubmit={this.submitForgetGuestcard.bind(this)}
              onCancel={this.cancelForgetGuestcard.bind(this)}
            />
          ) : null}

          <div>
            <div className="utility-actions">
              <input
                  value={this.state.streamIdToForgetInput}
                  onChange={this.updateStreamIdToForgetInput.bind(this)}
                  name="streamIdToForgetInput"
                  className="knock-react-input"
                  placeholder="e.g. CJW19TB4-1597778435"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.confirmForgetGuestcard.bind(this)}
                >
                  <i className="fa fa-user-times" /> Forget
                </button>
            </div>
            <div className="utility-description">
              <i className="fa fa-info-circle" /> The following actions will take place on "Forget":
              <ul>
                <li>The prospect/resident guestcard will have its first/last name changed to “Anonymous“. The email and phone will be emptied out.</li>
                <li>All communication messages (emails and SMS, both inbound and outbound) in the guestcard will be redacted to "Deleted".</li>
                <li>The guestcard will be archived. It will no longer be accessible by clicking through on LMA, but can still be accessed directly by URL.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="resident-sms-consent-inheritance-container">

          <h3 className="utilities-title">
            <i className="fa fa-vcard" /> Inherit SMS opt-in for resident:
          </h3>

          <div>
            <div className="utility-actions">
              <input
                  value={this.state.residentStreamIdToInheritSmsConsentInput}
                  onChange={this.updateResidentStreamIdToInheritSmsConsentInput.bind(this)}
                  name="residentStreamIdToInheritSmsConsentInput"
                  className="knock-react-input"
                  placeholder="e.g. CJW19TB4-1597778435"
                />
                <button
                  className="knock-react-button btn-success btn-input-inline"
                  onClick={this.submitResidentInheritSmsConsent.bind(this)}
                >
                  <i className="fa fa-vcard" /> Inherit
                </button>
            </div>
            <div className="utility-description">
              <i className="fa fa-info-circle" /> Here are some rules around Inheriting opt-in for residents:
              <ul>
                <li>We will not be modifying opt-in of any residents that are already <code>Granted</code>, or have either explicitly <code>Revoked</code> or <code>Declined</code>. Residents will only ever inherit a <code>Granted</code> opt-in from a <b>matching prospect</b>.</li>
                <li>Matching prospects are identified by <b>unarchived</b> prospect guestcards on the <b>same property</b> and also sharing the <b>same phone</b> number as the resident. If there are more than one matching prospects meeting these criteria, then we choose the guestcard based on the first that has these status, in this preferential order (Applied Leased, In Review, Visited, Booked). If none of the matching prospects have any of those statuses, then we simply pick the prospect guestcard with the most recent activity.</li>
              </ul>
            </div>
          </div>

        </div>
      </PageContainer>
    );
  }

  updateStreamIdToForgetInput(event) {
    this.setState({ streamIdToForgetInput: event.target.value });
  }

  updateResidentStreamIdToInheritSmsConsentInput(event) {
    this.setState({ residentStreamIdToInheritSmsConsentInput: event.target.value });    
  }

  confirmForgetGuestcard() {
    if (this.state.streamIdToForgetInput.length < 16 
        ||this.state.streamIdToForgetInput.indexOf('-') < 0) {
      Toaster.showToast('Requires a valid guestcard stream_id', 3000, Toaster.ToastClasses.error);
      return;
    }

    this.setState({ isConfirmingForgetGuestcard: this.state.streamIdToForgetInput });
  }

  cancelForgetGuestcard() {
    this.setState({ isConfirmingForgetGuestcard: null });
  }

  submitForgetGuestcard() {
    UtilitiesAPI.forgetGuestcard(this.state.isConfirmingForgetGuestcard)
      .then(async response => {
        const responsePayload = await response.json();

        if (response.status !== 201) {
          Toaster.showToast(`Error! ${responsePayload.message}`, 3000, Toaster.ToastClasses.error);          
          return;
        }

        Toaster.showToast('Forgotten!', 3000, Toaster.ToastClasses.success);
        this.setState({ streamIdToForgetInput: '' });
        this.cancelForgetGuestcard();
      })
      .catch(e => {
        Toaster.showToast('error!', 3000, Toaster.ToastClasses.error);
        console.log(e);
      });
  }

  submitResidentInheritSmsConsent() {
    if (this.state.residentStreamIdToInheritSmsConsentInput.length < 16 
        ||this.state.residentStreamIdToInheritSmsConsentInput.indexOf('-') < 0) {
      Toaster.showToast('Requires a valid resident guestcard stream_id', 3000, Toaster.ToastClasses.error);
      return;
    }

    UtilitiesAPI.inheritResidentSmsConsentFromProspect(this.state.residentStreamIdToInheritSmsConsentInput)
      .then(async response => {
        const responsePayload = await response.json();

        if (response.status !== 200) {
          Toaster.showToast(`Error! ${responsePayload.message}`, 3000, Toaster.ToastClasses.error);          
          return;
        }

        Toaster.showToast(responsePayload.message, 3000, Toaster.ToastClasses.success);
        this.setState({ residentStreamIdToInheritSmsConsentInput: '' });
      })
      .catch(e => {
        Toaster.showToast('error!', 3000, Toaster.ToastClasses.error);
        console.log(e);
      });
  }

}

export default UtilitiesPage;
