import { BASE_API_URI } from '../../config';
import { apiRemote } from '../../Services/RemoteService';
import { AuthenticationService } from '@knockrentals/knock-react';
import * as _ from 'lodash';

class PhotosAPI {
  static getPhotos() {
    return apiRemote
      .get(`admin/photos/properties`)
      .then(response => response.json())
      .catch(e => e);
  }

  static updatePhotos(propertyId, requestData, callback) {
    return apiRemote
      .put(`admin/photos/properties/${propertyId}`, requestData)
      .then(response => response.json())
      .then(() => callback())
      .catch(e => e);
  }

  static uploadForKey(key, files, uploadComplete, uploadProgress, uploadFailed) {
    var url = BASE_API_URI + 'admin/photos/upload';

    var formData = this.createFormData(key, files);
    var xhr = new XMLHttpRequest();

    if (uploadComplete) {
      xhr.addEventListener('load', uploadComplete, false);
    }

    if (uploadProgress) {
      xhr.upload.addEventListener('progress', uploadProgress, false);
    }

    if (uploadFailed) {
      xhr.addEventListener('error', uploadFailed, false);
    }

    var token = AuthenticationService.getToken();

    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    xhr.send(formData);
  }

  static createFormData(key, files) {
    var uploadForm = new FormData();
    uploadForm.append('key', key);

    var fileOrder = _.map(files, function (photo, index) {
      return { i: index, photo: photo.name };
    });

    uploadForm.append('order', JSON.stringify(fileOrder));
    uploadForm.append('key', key);

    for (const key in files) {
      const file = files[key];
      uploadForm.append(file.name, file);
    }

    return uploadForm;
  }
}

export default PhotosAPI;
