import React, { Component } from 'react';
import SpreadsheetRefreshAPI from './SpreadsheetRefreshAPI';
import { PageContainer } from '@knockrentals/knock-react';

class SpreadsheetRefreshPage extends Component {
  state = {
    isLoading: true,
    isError: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const companyId = this.props.location.pathname.split('/')[2];
    SpreadsheetRefreshAPI.getRefreshedSpreadsheetUrl(companyId)
      .then(data => {
        this.setState({ spreadsheetUrl: data.spreadsheet_url, isLoading: false });
      })
      .catch(() => {
        this.setState({ isError: true, isLoading: false });
      });
  }

  render() {
    return <PageContainer isLoading={this.state.isLoading} isError={this.state.isError}>
      <div style={{ textAlign: "center" }}>
        <h1>
          <i className="fa fa-table" />
        </h1>
        <h4>
          <span>Initiated Spreadsheet Refresh!</span>
        </h4>
        <div>
          <span>The data will become available on the spreadsheet within the next 5-10 minutes.</span>
          <br />
          <span>You may now close this window, or be <a href={this.state.spreadsheetUrl}>redirected to the spreadsheet</a>.</span>
          <br />
        </div>
      </div>
    </PageContainer>;
  }
}

export default SpreadsheetRefreshPage;
