import React from 'react';
import { Link } from 'react-router-dom';
import { VerticalNavbarHeader } from '@knockrentals/knock-react';
import { adminIconImageSrc } from '../../Components/Icons';

const NavHeader = () => {
  return (
    <Link to="/sources">
      <VerticalNavbarHeader>
        <img
          style={{ position: 'relative', top: '-1px' }}
          alt="Admin"
          className="fa-fw fa"
          src={adminIconImageSrc}
        />
        Admin
      </VerticalNavbarHeader>
    </Link>
  );
};

export default NavHeader;
