import React from 'react';
import PropTypes from 'prop-types';
import { useFeatureFlagContext } from '../../../Context/FeatureFlagContext';
import { Button } from '../components';
import {
  IntegrationVendorNames,
  ADD_INTEGRATION_BUTTON_NAMES,
} from '../constants';

const { ONSITE } = IntegrationVendorNames;

const AddIntegrationButtons = ({ selectIntegrationOption }) => {
  const { isOSMEnabled } = useFeatureFlagContext();

  return (
    <div className="add-integration-buttons">
      {ADD_INTEGRATION_BUTTON_NAMES.map((integrationName) => {
        if (integrationName === ONSITE && !isOSMEnabled) {
          return null;
        }

        const handleOnClick = () => {
          selectIntegrationOption(integrationName.toLowerCase());
        };

        return (
          <Button
            className="add-integration-button"
            key={integrationName}
            iconType={'fa fa-plus'}
            onClick={handleOnClick}
          >
            {`Add ${integrationName} integration`}
          </Button>
        );
      })}
    </div>
  );
};

AddIntegrationButtons.propTypes = {
  selectIntegrationOption: PropTypes.func,
};

export default AddIntegrationButtons;
