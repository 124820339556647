import React from 'react';
import PropTypes from 'prop-types';

const AmsiIntegrationInfo = ({ integration: { vendor, vendor_community_id, username, password, portfolio_name, url_prefix } }) => (
  <div className="knock-admin-integration-info">
    <div>
      <strong>Integration:</strong> {vendor}
    </div>
    <div>
      <strong>Vendor Property Id:</strong> {vendor_community_id}
    </div>
    <div>
      <strong>Username:</strong> {username}
    </div>
    <div>
      <strong>Password:</strong> {password}
    </div>
    <div>
      <strong>Portfolio name:</strong> {portfolio_name}
    </div>
    <div>
      <strong>URL prefix:</strong> {url_prefix}
    </div>
  </div>
);

AmsiIntegrationInfo.propTypes = {
  integration: PropTypes.object
};

export default AmsiIntegrationInfo;
