export const TEMPLATE_TYPES = {
  AUTORESPONSE_EMAIL: 'autoResponseEmail',
  AUTORESPONSE_SMS: 'autoResponseSms',
  LIVE_VIDEO_TOUR_INSTRUCTIONS: 'liveVideoTourInstructions',
  LIVE_VIDEO_TOUR_URL: 'liveVideoTourUrl',
  MY_TOUR_AGENDA: 'myTourAgenda',
  SELF_GUIDED_TOUR_DETAILED_INSTRUCTIONS: 'selfGuidedDetailedInstructions',
  SELF_GUIDED_TOUR_EMAIL: 'selfGuidedTourEmail',
  SELF_GUIDED_TOUR_INSTRUCTIONS_EMAIL: 'selfGuidedTourInstructionsEmail',
  SELF_GUIDED_TOUR_SMS: 'selfGuidedTourSms',
  SELF_GUIDED_TOUR_INSTRUCTIONS_SMS: 'selfGuidedTourInstructionsSms',
  TOUR_MOBILE_QUOTE: 'tourMobileQuote',
  WIN_BACK_EMAIL: 'winbackEmail',
};

export const TRIGGER_TYPE_NAMES = {
  [TEMPLATE_TYPES.AUTORESPONSE_EMAIL]: 'auto_response_email',
  [TEMPLATE_TYPES.AUTORESPONSE_SMS]: 'auto_response_sms',
  [TEMPLATE_TYPES.LIVE_VIDEO_TOUR_INSTRUCTIONS]: 'live_video_tour_instructions',
  [TEMPLATE_TYPES.LIVE_VIDEO_TOUR_URL]: 'live_video_tour_url',
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_DETAILED_INSTRUCTIONS]:
    'self_guided_tour_detailed_instructions',
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_EMAIL]:
    'self_guided_tour_email_instructions',
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_SMS]:
    'self_guided_tour_sms_instructions',
  [TEMPLATE_TYPES.WIN_BACK_EMAIL]: 'winback_email',
  [TEMPLATE_TYPES.MY_TOUR_AGENDA]: 'my_tour_agenda',
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_EMAIL]: 'self_guided_tour_email',
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_SMS]: 'self_guided_tour_sms',
  [TEMPLATE_TYPES.TOUR_MOBILE_QUOTE]: 'tour_mobile_quote',
};

export const TRIGGER_TYPE_NUMBERS = {
  [TEMPLATE_TYPES.AUTORESPONSE_EMAIL]: 9,
  [TEMPLATE_TYPES.AUTORESPONSE_SMS]: 10,
  [TEMPLATE_TYPES.LIVE_VIDEO_TOUR_INSTRUCTIONS]: 12,
  [TEMPLATE_TYPES.LIVE_VIDEO_TOUR_URL]: 13,
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_DETAILED_INSTRUCTIONS]: 22,
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_EMAIL]: 16,
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_SMS]: 18,
  [TEMPLATE_TYPES.WIN_BACK_EMAIL]: 20,
  [TEMPLATE_TYPES.MY_TOUR_AGENDA]: 14,
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_EMAIL]: 15,
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_SMS]: 17,
  [TEMPLATE_TYPES.TOUR_MOBILE_QUOTE]: 19,
};

export const MESSAGE_TYPES = {
  EMAIL: 'email',
  PLAIN_TEXT: 'text',
  SMS: 'sms',
  URL: 'url',
};

export const TEMPLATE_MESSAGE_TYPES = {
  [TEMPLATE_TYPES.AUTORESPONSE_EMAIL]: MESSAGE_TYPES.EMAIL,
  [TEMPLATE_TYPES.AUTORESPONSE_SMS]: MESSAGE_TYPES.SMS,
  [TEMPLATE_TYPES.LIVE_VIDEO_TOUR_INSTRUCTIONS]: MESSAGE_TYPES.SMS,
  [TEMPLATE_TYPES.LIVE_VIDEO_TOUR_URL]: MESSAGE_TYPES.URL,
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_DETAILED_INSTRUCTIONS]:
    MESSAGE_TYPES.PLAIN_TEXT,
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_EMAIL]: MESSAGE_TYPES.EMAIL,
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_SMS]: MESSAGE_TYPES.SMS,
  [TEMPLATE_TYPES.WIN_BACK_EMAIL]: MESSAGE_TYPES.EMAIL,
  [TEMPLATE_TYPES.MY_TOUR_AGENDA]: MESSAGE_TYPES.PLAIN_TEXT,
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_EMAIL]: MESSAGE_TYPES.EMAIL,
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_SMS]: MESSAGE_TYPES.SMS,
  [TEMPLATE_TYPES.TOUR_MOBILE_QUOTE]: MESSAGE_TYPES.SMS,
};

export const ADD = 'add';
export const EDIT = 'edit';
export const CREATE = 'create';
export const DELETE = 'delete';

export const CONFIRMATION_MODES = {
  ADD: ADD,
  CREATE: CREATE,
  DELETE: DELETE,
  EDIT: EDIT,
};

export const CONFIRMATION_TITLES = {
  [ADD]: 'Add Property',
  [CREATE]: 'Save Company Template',
  [DELETE]: 'Delete Company Template',
  [EDIT]: 'Save Company Template',
};

export const CONFIRMATION_MESSAGES = {
  [ADD]:
    'Selected properties will be removed from their currently assigned templates and added to ',
  [CREATE]:
    'Saving this Company Template will enable it for the included properties.',
  [DELETE]:
    'Deleting this Company Template will remove it from any assigned properties. Any unassigned properties will be reassigned to the default template.',
  [EDIT]:
    'Changes made to this Company Template will be applied to all included properties.',
};

export const CONFIRMATION_BUTTON_TEXT = {
  [ADD]: 'Add Property',
  [CREATE]: 'Create Template',
  [DELETE]: 'Delete Template',
  [EDIT]: 'Save Template',
};

export const COMPANY_TEMPLATE = {
  ASSIGNABLE_PROPERTIES: 'assignableProperties',
  IS_DEFAULT: 'isDefault',
  IS_ENABLED: 'isEnabled',
  IS_EVERY_PROPERTY_ASSIGNABLE: 'isEveryPropertyAssignable',
  MESSAGE: 'message',
  PROPERTIES: 'properties',
  SUBJECT: 'subject',
  TEMPLATE_NAME: 'templateName',
  TITLE: 'title',
};

export const TEMPLATE_TYPE_TITLES = {
  [TEMPLATE_TYPES.AUTORESPONSE_EMAIL]: 'Email',
  [TEMPLATE_TYPES.AUTORESPONSE_SMS]: 'SMS',
  [TEMPLATE_TYPES.LIVE_VIDEO_TOUR_INSTRUCTIONS]: 'Live Video Tour Instructions',
  [TEMPLATE_TYPES.LIVE_VIDEO_TOUR_URL]: 'Live Video Tour URL',
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_EMAIL]:
    'Self Guided Tour Email Instructions',
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_SMS]:
    'Self Guided Tour SMS Instructions',
  [TEMPLATE_TYPES.WIN_BACK_EMAIL]: 'Win Back Email',
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_DETAILED_INSTRUCTIONS]:
    'Self Guided Tour Detailed Instructions',
  [TEMPLATE_TYPES.MY_TOUR_AGENDA]: 'Tour Agenda',
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_EMAIL]: 'Self Guided Tour Post Visit Email',
  [TEMPLATE_TYPES.SELF_GUIDED_TOUR_SMS]: 'Self Guided Tour Post Visit SMS',
  [TEMPLATE_TYPES.TOUR_MOBILE_QUOTE]: 'Tour Mobile Quote',
};

export const EMAIL_PLACE_HOLDER_TEXT = 'Create a rich text email...';

export const VALIDATION_ERROR_MESSAGES = {
  [MESSAGE_TYPES.EMAIL]: 'An email message is required',
  [MESSAGE_TYPES.PLAIN_TEXT]: 'A message is required',
  [MESSAGE_TYPES.SMS]: 'A sms message is required',
  TEMPLATE_NAME: 'A template name is required',
  URL_INVALID_FORMAT: 'Invalid url',
  URL_INVALID_PROTOCOL: 'Url must begin with https://',
  URL_REQUIRED: 'A URL is required',
};

export const TEMPLATE_NOTIFICATIONS = {
  CREATED: 'Template created successfully',
  DELETED: 'Template deleted successfully',
  UPDATED: 'Template updated successfully',
};

export const PROPERTY_TABLE_VIEWS = {
  ADD_PROPERTY: 'add-property',
  ASSIGNABLE_PROPERTIES: 'assignable-properties',
  MANAGE_PROPERTY_ASSIGNMENT: 'manage-property-assignment',
};
