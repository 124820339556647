import React from 'react';
import { PropTypes } from 'prop-types';
import { Drawer, makeStyles } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles(() => ({
  featureDrawer: {
    '& .MuiDrawer-paper': {
      width: '800px',
      backgroundColor: '#E5E5E5',
    },
  },
}));

const FeatureDrawer = ({ 
  isOpen, 
  children, 
  ...props 
}) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      className={classes.featureDrawer}
      open={isOpen}
      variant="temporary"
      {...props}
    >
      {isOpen && children}
    </Drawer>
  );
};

FeatureDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default FeatureDrawer;
