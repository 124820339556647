import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@knockrentals/knock-react';
import { useFormik } from 'formik';
import { IntegrationVendorNames } from '../constants';
import { trimValue } from '../../../Utils';

import './_addIntegrationForm.scss';

export const VENDOR_NAME = IntegrationVendorNames.ONSITE;
export const MODAL_TITLE = `Add ${VENDOR_NAME} Integration`;
export const OSM_PROPERTY_ID_LABEL = 'Onsite Property ID:';

const handleValidate = (values) => {
  if (!values.osmPropertyId.trim()) {
    return { osmPropertyId: 'Required' };
  }
};

const AddOnsiteIntegration = ({ onAddIntegration, onCancelAddIntegration }) => {
  const handleOnSubmit = (values) => {
    onAddIntegration({
      osm_property_id: values.osmPropertyId,
      vendor: VENDOR_NAME.toLowerCase(),
    });
  };

  const formikConfig = {
    initialValues: {
      osmPropertyId: '',
    },
    onSubmit: handleOnSubmit,
    validate: handleValidate,
  };

  const { errors, handleChange, handleSubmit, isSubmitting, isValid, setFieldValue, values } =
    useFormik(formikConfig);

  return (
    <Modal className="add-integration-form">
      <ModalHeader>
        <h3>{MODAL_TITLE}</h3>
      </ModalHeader>

      <form className="add-onsite-integration-form" onSubmit={handleSubmit}>
        <ModalContent>
          <label>
            {OSM_PROPERTY_ID_LABEL}

            <input
              className="knock-react-input"
              name="osmPropertyId"
              onChange={handleChange}
              onBlur={trimValue(setFieldValue)}
              type="text"
              value={values.osmPropertyId || ''}
            />
          </label>

          {errors.osmPropertyId && (
            <span className="knock-react-form-hint knock-react-form-hint-error">
              {errors.osmPropertyId}
            </span>
          )}
        </ModalContent>

        <ModalFooter>
          <button
            className="knock-react-button"
            disabled={!isValid || isSubmitting}
            type="submit"
          >
            Save
          </button>

          <button
            className="knock-react-button"
            disabled={isSubmitting}
            onClick={onCancelAddIntegration}
            type="button"
          >
            Cancel
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

AddOnsiteIntegration.propTypes = {
  onAddIntegration: PropTypes.func,
  onCancelAddIntegration: PropTypes.func,
};

export default AddOnsiteIntegration;
