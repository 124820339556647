import React from 'react';
import PropTypes from 'prop-types';

import PreviewEmailTemplate from './PreviewEmailTemplate';
import PreviewSmsTemplate from './PreviewSmsTemplate';
import { TEMPLATE_TABLE_CONTENT_TYPES } from '../../constants';

const TemplatePreview = ({ contentType, templateName, subject, message }) =>
  contentType === TEMPLATE_TABLE_CONTENT_TYPES.SMS ? (
    <PreviewSmsTemplate message={message} />
  ) : (
    <PreviewEmailTemplate
      message={message}
      subject={subject}
      templateName={templateName}
    />
  );

TemplatePreview.propTypes = {
  contentType: PropTypes.string,
  message: PropTypes.string,
  subject: PropTypes.string,
  templateName: PropTypes.string,
};

export default TemplatePreview;
