import React from 'react';
import PropTypes from 'prop-types';

import DataTable from 'material-table';
import { Button, Paper } from '@knockrentals/knock-shared-web';

import { ErrorDialog } from '../../../common/ErrorDialog';
import { StatusChip } from '../../DripCampaignViews/StatusChip';
import { useTemplateList } from './useTemplateList';
import { getDefaultTableOptions } from '../../../../../Services/SharedService';
import { TemplateFormatIcon } from '../../DripCampaignViews/TemplateView/TemplateFormatIcon';

const defaultTableOptions = getDefaultTableOptions();

const tableOptions = {
  ...defaultTableOptions,
  headerStyle: {
    ...defaultTableOptions.headerStyle,
    fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
    lineHeight: '24px',
    padding: '16px 24px 16px 16px',
    whiteSpace: 'nowrap',
  },
  paging: false,
  rowStyle: {
    minWidth: '582px',
  },
};

const tableColumns = (handleSelectTemplate) => [
  {
    title: 'Template Name',
    render: (rowData) => {
      const { contentType, templateId, templateName, status  } = rowData || {};
      const template = { contentType, templateId, templateName, status };
      return (
        <Button
          color="primary"
          onClick={handleSelectTemplate(template)}
          variant="text"
        >
          {templateName}
        </Button>
      );
    },
    width: '486px',
  },
  {
    title: 'Format',
    width: '100px',
    render: (rowData) => {
      const { contentType } = rowData || {};
      return <TemplateFormatIcon contentType={contentType} />;
    },
  },
  {
    title: 'Status',
    render: (rowData) => <StatusChip status={rowData.status} />,
    width: '100px',
  },
];

const SelectTemplateDataTable = ({
  contentType,
  isActionTemplates,
  setSelectedTemplate,
}) => {
  const { errorMessage, isLoading, templates, setErrorMessage } =
    useTemplateList({
      contentType,
      isActionTemplates,
    });

  const handleSelectTemplate = (template) => () =>
    setSelectedTemplate(template);

  return (
    <React.Fragment>
      <ErrorDialog
        closeDialog={() => setErrorMessage('')}
        errorMessage={errorMessage}
        isOpen={Boolean(errorMessage)}
      />

      <DataTable
        columns={tableColumns(handleSelectTemplate)}
        components={{
          Container: (props) => <Paper {...props} elevation={1} />,
        }}
        data={templates}
        isLoading={isLoading}
        options={tableOptions}
        style={{ maxWidth: '744px', overflow: 'hidden' }}
      />
    </React.Fragment>
  );
};

SelectTemplateDataTable.propTypes = {
  contentType: PropTypes.string,
  isActionTemplates: PropTypes.bool,
  setSelectedTemplate: PropTypes.func,
};

export default SelectTemplateDataTable;
