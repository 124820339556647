import React from 'react';
import { PropTypes } from 'prop-types';
import { ArrowBack } from '@material-ui/icons';

import {
  Card,
  CardHeader,
  CloseIcon,
  IconButton,
  makeStyles,
} from '@knockrentals/knock-shared-web';

const useStyles = makeStyles(() => ({
  featureCard: {
    display: 'flex',
    flexFlow: 'column',
    borderRadius: 0,
    overflow: 'hidden',
    height: '100vh',
    '& .MuiCardHeader-action': {
      alignSelf: 'unset',
    },
  },
  cardHeader: {
    padding: '24px',
    zIndex: 1,
    wordBreak: 'break-word',
    boxShadow:
      '0px 2px 12px -4px rgba(24, 39, 75, 0.04), 0px 4px 16px -2px rgba(24, 39, 75, 0.02)',
  },
}));

const renderBackButton = (handleOnClick) => {
  if (handleOnClick) {
    return (
      <IconButton
        aria-label="back"
        color="inherit"
        onClick={handleOnClick}
        size="small"
      >
        <ArrowBack />
      </IconButton>
    );
  }
};

const FeatureCard = ({
  children,
  closeDrawer,
  handleBackButtonOnClick,
  subheader,
  title,
}) => {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.featureCard}>
      <CardHeader
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={closeDrawer}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        }
        avatar={renderBackButton(handleBackButtonOnClick)}
        className={classes.cardHeader}
        subheader={subheader}
        subheaderTypographyProps={{ color: 'textPrimary', variant: 'h6' }}
        title={title}
        titleTypographyProps={{ color: 'secondary', variant: 'caption' }}
      />

      {children}
    </Card>
  );
};

FeatureCard.propTypes = {
  children: PropTypes.node.isRequired,
  closeDrawer: PropTypes.func,
  handleBackButtonOnClick: PropTypes.func,
  subheader: PropTypes.string,
  title: PropTypes.string,
};

export default FeatureCard;
