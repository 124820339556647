import { mlRemote } from '../../../../../Services/RemoteService';

export const createAiCompanyTemplate = async (payload) => {
  try {
    const response = await mlRemote.post('templates', payload);

    if (!response.ok || response.status !== 201) {
      throw new Error();
    }
    const responseData = await response.json();

    return responseData.id;
  } catch (error) {
    throw new Error(
      'We are unable to save the ai email template at this time.'
    );
  }
};

export const getAiCompanyTemplate = async (id) => {
  try {
    const response = await mlRemote.get(
      `template-data-by-id?template_id=${id}`
    );

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }
    const { template_body, template_name, template_subject, is_default } =
      await response.json();

    return {
      templateName: template_name,
      message: template_body,
      subject: template_subject,
      isDefault: is_default,
    };
  } catch (error) {
    throw new Error(
      'We are unable to fetch the ai email template at this time.'
    );
  }
};

export const editAiCompanyTemplate = async (payload) => {
  try {
    const response = await mlRemote.put('templates', payload);

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }
    const responseData = await response.json();

    return responseData.id;
  } catch (error) {
    throw new Error(
      'We are unable to save the ai email template at this time.'
    );
  }
};

export const deleteAiCompanyTemplate = async ({
  intent_id,
  company_id,
  id,
}) => {
  try {
    const response = await mlRemote.delete(
      `templates?intent_id=${intent_id}&company_id=${company_id}&id=${id}`
    );

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    throw new Error('We are unable to delete the ai email template.');
  }
};
