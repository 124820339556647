import React from 'react';
import PropTypes from 'prop-types';
import { CardContent, makeStyles } from '@knockrentals/knock-shared-web';
import FeatureCard from '../../FeatureCard';
import { FeatureActions } from '../../FeatureActions';

const useStyles = makeStyles({
  tooltipCardContent: {
    flexGrow: 1,
    background: '#F7F7F8',
    overflowY: 'auto',
  },
});

const TooltipCard = ({ closeDrawer, children, subheader, title }) => {
  const classes = useStyles();

  return (
    <FeatureCard closeDrawer={closeDrawer} subheader={subheader} title={title}>
      <CardContent className={classes.tooltipCardContent}>
        {children}
      </CardContent>

      <FeatureActions
        isCancelButtonHidden
        handleSaveOnClick={closeDrawer}
        primaryText="Done"
      />
    </FeatureCard>
  );
};

TooltipCard.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  subheader: PropTypes.string,
  title: PropTypes.string,
};

export default TooltipCard;
