import React from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@material-ui/core/SvgIcon';

export const BRAND_COLOR_ARIA_LABEL = 'brand color icon';
export const DEFAULT_STROKE_COLOR = '#D3D3D3';
export const NONE = 'none';
export const WRAPPER_ARIA_LABEL = 'icon wrapper';

const BrandColorIcon = ({ brandColor, ...props }) => {
  const strokeColor = !brandColor ? DEFAULT_STROKE_COLOR : NONE;

  return (
    <SvgIcon aria-label={WRAPPER_ARIA_LABEL} {...props}>
      <svg
        aria-label={BRAND_COLOR_ARIA_LABEL}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="3.5"
          fill={brandColor || NONE}
          stroke={strokeColor}
        />
      </svg>
    </SvgIcon>
  );
};

BrandColorIcon.propTypes = {
  brandColor: PropTypes.string,
};

export default BrandColorIcon;
