import React from 'react';
import { Modal, ModalContent, ModalHeader, ModalFooter } from '@knockrentals/knock-react';
import PropTypes from 'prop-types';

import './_ModalView.scss';

const ModalView = (props) => {
  const {
    className,
    header,
    footer,
    onHide,
    show,
    children,
  } = props;

  if (!show) {
    return null;
  }

  const footerSection = (footer &&
    <ModalFooter>
      {footer}
    </ModalFooter>);

  const handleHide = async () => {
    await onHide();
  };

  return (
    <Modal
      className={className}
    >
      <ModalHeader>
        <div className='modal-view-header-wrapper'>
          <h3>
            {header}
          </h3>
          <button
            className='modal-view-header-hide-button'
            onClick={handleHide}
            aria-label='Close'
          >
            <span>
              <i className='fa fa-times' />
            </span>
          </button>
        </div>
      </ModalHeader>
      <ModalContent>
        {children}
      </ModalContent>
      {footerSection}
    </Modal>
  );
};

ModalView.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  header: PropTypes.node,
  footer: PropTypes.node,
  onHide: PropTypes.func,
  children: PropTypes.node,
};

export default ModalView;
