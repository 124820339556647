export const IS_TRANSCRIBED = 'is_transcribed';
export const IS_SCORED = 'is_scored';
export const IS_STANDALONE = 'is_standalone';

export const FEATURE_SWITCHES_TITLES = {
  [IS_TRANSCRIBED]: 'Transcribe Calls',
  [IS_SCORED]: 'Annotate Calls',
  [IS_STANDALONE]: 'Standalone'
};

export const FEATURE_SWITCHES_DESCRIPTION = {
  [IS_TRANSCRIBED]: 'Toggle this to turn Transcription service on or off. Transcriptions are direct text version of the spoken conversation.',
  [IS_SCORED]: 'Toggle this to turn Annotation service on or off. Annotations are feedback to help identify weak points in the model’s performance.',
  [IS_STANDALONE]: 'Toggle this to turn Standalone Call Intel on or off. This is only needed where the customer is not using Knock as their CRM.'
};

export const DO_NOT_SHOW_CONFIRMATION_DIALOG_LS_KEY = 'knock_call_intel_do_no_show_confirmation_dialog';
