import { useState, useRef } from 'react';
import { NotificationService } from '../../../../../Components/Notifications';
import * as schedulingApi from '../../schedulingApi';
import {
  ERROR_MESSAGES,
  IS_IN_PERSON_TOURS_ENABLED,
  IS_LIVE_VIDEO_TOURS_ENABLED,
} from '../../constants';
import { SAVED_NOTIFICATION } from '../../../../../constants';

export const DEFAULT_ROWS_PER_PAGE = 50;
// TODO - update PAGE_SIZES_OPTIONS with product/UX recommendations
const ROWS_PER_PAGE_OPTIONS = [25, 50, 100, 500];

export const getPropertiesPaginationMap = (propertiesByPage) =>
  Object.entries(propertiesByPage).reduce((propertyMap, [pageIndex, rows]) => {
    rows.forEach((rowData, rowIndex) => {
      const propertyId = rowData.propertyId;
      propertyMap[propertyId] = [pageIndex, rowIndex];
    });
    return propertyMap;
  }, {});

const useSchedulingGrid = ({ setErrorMessage }) => {
  const [propertiesByPage, setPropertiesByPage] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const tableRef = useRef();
  const reloadTableData = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const updateSchedulingGridData = () => {
    setPropertiesByPage({});
    reloadTableData();
  };

  const tableDetailsRef = useRef({
    pageIndex: 0,
    shouldLoadPageIndex: false,
    sortColumn: 'propertyName',
    sortDirection: 'asc',
    totalPropertyCount: 0,
  });

  const getPageIndex = () => tableDetailsRef.current.pageIndex;
  const setPageIndex = (index) => {
    tableDetailsRef.current.pageIndex = index;
  };

  const getShouldLoadPageIndex = () =>
    tableDetailsRef.current.shouldLoadPageIndex;
  const setShouldLoadPageIndex = (shouldLoad) => {
    tableDetailsRef.current.shouldLoadPageIndex = shouldLoad;
  };

  const getSortColumn = () => tableDetailsRef.current.sortColumn;

  const getSortDirection = () => tableDetailsRef.current.sortDirection;

  const getTotalPropertyCount = () =>
    tableDetailsRef.current.totalPropertyCount;
  const setTotalPropertyCount = (count) => {
    tableDetailsRef.current.totalPropertyCount = count;
  };

  const getPage = (query) => {
    if (getShouldLoadPageIndex()) {
      setShouldLoadPageIndex(false);
      return getPageIndex();
    }
    return query.page;
  };

  const shouldFetchRequestedPage = (page) => {
    const nextPage = propertiesByPage[page] || [];
    return !nextPage.length;
  };

  const fetchTableData = (query) => {
    const { page, pageSize } = query;

    const queryParams = {
      page,
      pageSize,
      sortColumn: getSortColumn(),
      sortDirection: getSortDirection(),
    };

    return schedulingApi
      .getSchedulingProperties(queryParams)
      .then((responseData) => {
        const { properties, totalPropertyCount } = responseData;

        setTotalPropertyCount(totalPropertyCount);

        setPropertiesByPage((prevState) => ({
          ...prevState,
          [page]: properties,
        }));

        return properties;
      })
      .catch(() => {
        setErrorMessage(ERROR_MESSAGES.FETCH);
        return [];
      });
  };

  const getTableData = (query) =>
    new Promise(async (resolve) => {
      const { pageSize } = query;
      const page = getPage(query);
      const hasRowsPerPageChanged = pageSize !== rowsPerPage;

      if (hasRowsPerPageChanged) {
        setRowsPerPage(pageSize);
        setPropertiesByPage({});
      }

      const properties =
        hasRowsPerPageChanged || shouldFetchRequestedPage(page)
          ? await fetchTableData(query)
          : propertiesByPage[page];

      resolve({
        data: properties.map((property) => ({ ...property })),
        page: page,
        totalCount: getTotalPropertyCount(),
      });
    });

  const updateSchedulingSwitch = (pageIndex, rowIndex, updatedSwitch) => {
    setPropertiesByPage((prevState) => {
      const updatedProperties = [...prevState[pageIndex]];
      updatedProperties[rowIndex] = {
        ...updatedProperties[rowIndex],
        ...updatedSwitch,
      };

      return {
        ...prevState,
        [pageIndex]: updatedProperties,
      };
    });
    reloadTableData();
  };

  const toggleSchedulingSwitch = (rowData) => async (name) => {
    const {
      [name]: currentCheckedValue,
      propertyId,
      tableData: { id: rowIndex },
    } = rowData;
    const isChecked = !currentCheckedValue;

    const revertSwitchUpdate = { [name]: currentCheckedValue };
    const switchUpdate = { [name]: isChecked };

    if (
      name === IS_IN_PERSON_TOURS_ENABLED ||
      name === IS_LIVE_VIDEO_TOURS_ENABLED
    ) {
      if (!isChecked) {
        const otherSwitchName =
          name === IS_IN_PERSON_TOURS_ENABLED
            ? IS_LIVE_VIDEO_TOURS_ENABLED
            : IS_IN_PERSON_TOURS_ENABLED;
        revertSwitchUpdate[otherSwitchName] = rowData[otherSwitchName];
        switchUpdate[otherSwitchName] = true;
      }
    }

    const pageIndex = getPageIndex();
    updateSchedulingSwitch(pageIndex, rowIndex, switchUpdate);

    const payload = {
      propertyIds: [propertyId],
      features: switchUpdate,
    };

    try {
      await schedulingApi.updateSchedulingPreferences(payload);
      updateSchedulingGridData();
      NotificationService.notify(SAVED_NOTIFICATION);
    } catch (_e) {
      updateSchedulingSwitch(pageIndex, rowIndex, revertSwitchUpdate);
      setErrorMessage(ERROR_MESSAGES.UPDATE);
    }
  };

  return {
    getTableData,
    getPageIndex,
    getCurrentPagePropertyCount: () =>
      (propertiesByPage[getPageIndex()] || []).length,
    getTotalPropertyCount,
    propertiesByPage,
    rowsPerPage,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    setPageIndex,
    setPropertiesByPage,
    setRowsPerPage,
    setShouldLoadPageIndex,
    tableRef,
    toggleSchedulingSwitch,
    updateSchedulingGridData,
  };
};

export default useSchedulingGrid;
