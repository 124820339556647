import React from 'react';
import { Text, ThemeProvider } from '@knockrentals/knock-shared-web';
import { ErrorDialog } from '../Features/common/ErrorDialog';
import { AccessDataTable } from './AccessDataTable';
import { useAccess } from './use-access';
import './_AccessSettingsPage.scss';

export const ACCESS_TITLE = 'Access';
export const DESCRIPTION =
  'Configure company level access settings for the Admin, Agent, and Analytics roles';

const AccessSettingsPage = () => {
  const {
    accessSettings,
    errorMessage,
    isFullAccessView,
    isLoading,
    setErrorMessage,
    updateAccessSetting,
  } = useAccess();

  return (
    <ThemeProvider>
      <section className="access-settings-page">
        <div className="page-header">
          <Text variant="h6">{ACCESS_TITLE}</Text>
        </div>

        <Text
          className="access-settings-page-description"
          color="textSecondary"
        >
          {DESCRIPTION}
        </Text>

        <ErrorDialog
          closeDialog={() => {
            setErrorMessage('');
          }}
          errorMessage={errorMessage}
          isOpen={Boolean(errorMessage)}
        />

        <AccessDataTable
          accessSettings={accessSettings}
          isFullAccessView={isFullAccessView}
          isLoading={isLoading}
          updateAccessSetting={updateAccessSetting}
        />
      </section>
    </ThemeProvider>
  );
};

export default AccessSettingsPage;
