import React, { Component } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalContent } from '@knockrentals/knock-react';

class SourceRelayPhoneRemover extends Component {
  render() {
    const relaySourcesToDelete = this.props.relaySourcesToDelete;

    const deleteList = [];
    for (const relaySourceId in relaySourcesToDelete) {
      for (const relayPhoneId in relaySourcesToDelete[relaySourceId]) {
        const sourceRelayPhonePayload = relaySourcesToDelete[relaySourceId][relayPhoneId];
        if (sourceRelayPhonePayload.delete) {
          deleteList.push(
            <li key={relayPhoneId}>
              <strong>{sourceRelayPhonePayload.details.source.title}: </strong> 
              {sourceRelayPhonePayload.details.relayPhone.voiceNumber}
            </li>
          );          
        } 
      }
    }

    return (
      <Modal className="phone-relay-remover" onBackdropClicked={this.props.onCancel}>
        <ModalHeader>
          <h3> Unregister tracking source numbers from <strong>{this.props.property.data.location.name}</strong></h3>
        </ModalHeader>

        <ModalContent>
          <div>
            <div>Are you sure? There is no going back from this!</div>
            <div>
              Unregister:
              <ul name="deleteTrackingNumbersList" style={{ listStyleType: "none" }}>
                {deleteList}
              </ul>
            </div>
          </div>
        </ModalContent>

        <ModalFooter>
          <button className="knock-react-button" onClick={this.props.onCancel}>
            Cancel
          </button>
          <button
            className="knock-react-button btn-danger"
            onClick={this.onSubmit.bind(this, false)}
            type="button"
          >
            Yes, but do not assign new numbers
          </button>
          <button
            className="knock-react-button btn-danger"
            onClick={this.onSubmit.bind(this, true)}
            type="button"
          >
            Yes, and assign new numbers
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  onSubmit(isReassign) {
    this.props.onSubmit(isReassign);
  }
}

export default SourceRelayPhoneRemover;
