import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  InputText,
  Text,
  makeStyles,
  Tooltip,
} from '@knockrentals/knock-shared-web';
import HelpOutline from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles(() => ({
  characterCount: {
    display: 'inline-block',
    alignSelf: 'flex-end',
  },
  intentContainer: {
    paddingBottom: 20,
  },
  inputField: {
    width: '100%',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '40px',
  },
  productNotActiveToolTip: {
    verticalAlign: 'middle',
    marginLeft: '3px',
  },
}));

export const SMS_RESPONSE_CHAR_LIMIT = 300;
export const SMS_RESPONSE_CHAR_LIMIT_ERROR = `SMS Response should not have more than ${SMS_RESPONSE_CHAR_LIMIT} characters`;

const PropertySmsResponseTemplate = ({ smsResponse, onSmsResponseChange, active, generatedByAi }) => {
  const classes = useStyles();
  const [smsResponseCharCount, setSmsResponseCharCount] = useState(smsResponse?.length || 0);

  useEffect(() => {
    setSmsResponseCharCount(smsResponse?.length || 0);
  }, [smsResponse]);

  const isSmsResponseCharExceeded =
    smsResponseCharCount > SMS_RESPONSE_CHAR_LIMIT;

  const handleSmsResponseChange = (response) => {
    setSmsResponseCharCount(response.length);
    onSmsResponseChange && onSmsResponseChange(response);
  };

  return (
    <Box className={classes.intentContainer}>
      <div className="knock-react-flex">
        <div className="input-label-text">
          <Text variant="subtitle2" className={classes.labelContainer}>
            SMS
          </Text>
          
        </div>
        {!active && (
          <Tooltip title="AI SMS is not active">
              <HelpOutline
                className={classes.productNotActiveToolTip}
                fontSize="small"
              />
          </Tooltip>
        )}
      </div>
      <InputText
        multiline
        className={classes.inputField}
        value={smsResponse}
        hideOptionalLabel={true}
        onChange={(event) => handleSmsResponseChange(event.target.value)}
        error={isSmsResponseCharExceeded}
        helperText={isSmsResponseCharExceeded && SMS_RESPONSE_CHAR_LIMIT_ERROR}
        InputProps={{
          endAdornment: (
            <Text variant="body2" className={classes.characterCount}>
              {smsResponseCharCount}/{SMS_RESPONSE_CHAR_LIMIT}
            </Text>
          ),
        }}
      />
    </Box>
  );
};

PropertySmsResponseTemplate.propTypes = {
  smsResponse: PropTypes.string,
  onSmsResponseChange: PropTypes.func,
  active: PropTypes.bool,
  generatedByAi: PropTypes.bool,
};

export default PropertySmsResponseTemplate;
