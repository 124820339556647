import React from 'react';

const LegalDisclaimer = () => {
  return (
    <span className="txt-disclaimer">
      Disclaimer: By completing this Facebook integration on the Knock platform, you are
      hereby acknowledging the{' '}
      <a href="https://app.knockcrm.com/legal/terms" target="_blank">
        Terms of Service
      </a>{' '}
      and{' '}
      <a href="https://app.knockcrm.com/legal/privacy" target="_blank">
        Privacy Policy
      </a>{' '}
      set forth by Knock, Inc.
    </span>
  );
};

export default LegalDisclaimer;
