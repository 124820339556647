import { AuthenticationService } from '@knockrentals/knock-react';
import CompanyService from '../../../Services/CompanyService';
import LoginAPI from '../../../Pages/Login/LoginAPI';
import AccessAPI from '../../../Pages/Access/AccessAPI';
import { normalizeAccessSettingsForRole } from '../../../Pages/Access/utils';
import { ADMIN_USER_ROLES } from '../../../constants';

export const THIRTY_MINUTES_MS = 1800000;

export const getAccessForRole = async (role) => {
  try {
    const { access = {} } = await AccessAPI.getCompanyAccessSettings();

    const accessSettings = normalizeAccessSettingsForRole(
      access.settings,
      role
    );
    return accessSettings;
  } catch {
    return {};
  }
};

export const getCompanyName = async () => {
  try {
    const { company = {} } = await CompanyService.getCompanyInfo();
    return company.name;
  } catch {
    return;
  }
};

export const resetApp = () => {
  localStorage.clear();
  window.location.reload();
};

export const setAccessToken = async (refreshToken) => {
  try {
    const { access_token } = await LoginAPI.refreshAuthToken(refreshToken);
    AuthenticationService.setToken(access_token);
  } catch {
    resetApp();
  }
};

export const resetAccessTokens = async () => {
  // previous bug in login method via access token caused a number of users to access the app
  // without the necessary refresh token, this patch gives users the necessary refresh token
  // without navigating them away from their workflow
  try {
    const currentAccessToken = AuthenticationService.getToken();
    const { accessToken, refreshToken } = await LoginAPI.getUserRefreshToken(
      currentAccessToken
    );
    AuthenticationService.setToken(accessToken);
    AuthenticationService.setRefreshToken(refreshToken);
  } catch {
    resetApp();
  }
};

export const setTokenRefreshInterval = () => {
  // this method fetches a fresh token every 30 minutes
  setInterval(() => {
    const refreshToken = AuthenticationService.getRefreshToken();
    if (refreshToken) {
      setAccessToken(refreshToken);
    }
  }, THIRTY_MINUTES_MS);
};

export const parseWindowLocation = () => {
  const { hostname } = window.location;
  const searchParams = new URLSearchParams(window.location.search);
  const hasAccessToken = Boolean(searchParams.get('accessToken'));
  const hasInternalParam = Boolean(searchParams.get('internal'));

  return { hasAccessToken, hasInternalParam, hostname };
};

export const getIsInternalMode = (hasInternalParam, hostname) => {
  return (
    (hostname === 'localhost' && hasInternalParam) ||
    (hostname === 'admin.knockrentals.test' && hasInternalParam) ||
    hostname.includes('internal-admin')
  );
};

export const parseToken = () => {
  const tokenPayload = AuthenticationService.getTokenPayload();
  const payloadSub = tokenPayload?.sub || {};

  return {
    companyId: payloadSub.company_id,
    userId: payloadSub.user_id,
    userRole: payloadSub.role,
    userType: payloadSub.type,
  };
};

export const initializeApp = async (updateAppData) => {
  const { hasAccessToken, hasInternalParam, hostname } = parseWindowLocation();

  const isInternalMode = getIsInternalMode(hasInternalParam, hostname);
  AuthenticationService._internalMode = isInternalMode;

  const isLoggedIn = AuthenticationService.isAuthenticated();

  if (hasAccessToken || !isLoggedIn) {
    updateAppData({ isInternalMode, isReady: true });
    return;
  }

  const refreshToken = AuthenticationService.getRefreshToken();
  refreshToken ? await setAccessToken(refreshToken) : await resetAccessTokens();

  const { companyId, userId, userRole, userType } = parseToken();

  if (companyId) {
    setTokenRefreshInterval();
    const companyName = await getCompanyName();

    const accessMap =
      userRole !== ADMIN_USER_ROLES.MASTER
        ? await getAccessForRole(userRole)
        : {};

    updateAppData({
      accessMap,
      companyId,
      companyName,
      isInternalMode,
      isLoggedIn,
      userId,
      userRole,
      userType,
    });
  }

  AuthenticationService.addAuthChangeHandler(() => {
    const isLoggedIn = AuthenticationService.isAuthenticated();
    updateAppData({ isLoggedIn });
  });
};
