import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@knockrentals/knock-shared-web';
import { AddCircleOutlineIcon } from '../../../../../Components/Icons';

const useStyles = makeStyles((theme) => ({
  AddIcon: {
    '&.MuiSvgIcon-root path': {
      stroke: theme.palette.secondary,
    },
  },
}));

const AddIcon = () => {
  const classes = useStyles();
  return (
    <SvgIcon className={classes.AddIcon}>
      <AddCircleOutlineIcon />
    </SvgIcon>
  );
};

export default AddIcon;
