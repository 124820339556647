import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import DataTable from 'material-table';
import { Paper } from '@knockrentals/knock-shared-web';

import { Pagination } from './Pagination';
import { EditButton } from './EditButton';
import { getColumns } from './table-columns';
import { getTableActions } from './table-actions';
import { getTableOptions } from './table-options';
import { LIST, PAGE } from '../../common/MultiSelectCheckbox';
import './_SchedulingGrid.scss';

//TODO get tooltip text
export const EDIT_ACTION_TOOLTIP = 'Edit property';
export const EDIT_BUTTON_TOOLTIP = 'edit-button-tooltip';

const SchedulingGrid = ({
  getCurrentPagePropertyCount,
  getPageIndex,
  getTableData,
  getTotalPropertyCount,
  handleEditProperties,
  isFetchingPropertiesList,
  propertiesByPage,
  propertySelectionMap,
  rowsPerPage,
  rowsPerPageOptions,
  selectedPropertyCount,
  setPageIndex,
  setPropertySelectionMap,
  setShouldLoadPageIndex,
  tableRef,
  toggleSchedulingSwitch,
}) => {
  useEffect(() => {
    setShouldLoadPageIndex(true);
  }, []);

  const getUpdatedSelectedProperties = (batchOption) => {
    const updatedSelectedPropertiesMap = { ...propertySelectionMap };

    if (batchOption === PAGE) {
      const propertiesList = propertiesByPage[getPageIndex()];
      propertiesList.forEach((property) => {
        updatedSelectedPropertiesMap[property.propertyId].isSelected = true;
      });

      return updatedSelectedPropertiesMap;
    }

    const isSelected = batchOption === LIST;
    Object.keys(updatedSelectedPropertiesMap).forEach((propertyId) => {
      updatedSelectedPropertiesMap[propertyId].isSelected = isSelected;
    });

    return updatedSelectedPropertiesMap;
  };

  const onChangeBatchOption = (batchSelectOption) => {
    const updatedSelectedProperties = getUpdatedSelectedProperties(
      batchSelectOption,
      true
    );

    setPropertySelectionMap(updatedSelectedProperties);
  };

  const getBatchCheckboxState = () => {
    const properties = Object.values(propertySelectionMap);
    const selectionStatus = properties.reduce(
      (statusMap, property) => {
        const status = property.isSelected ? 'selected' : 'unSelected';
        statusMap[status] += 1;
        return statusMap;
      },
      { selected: 0, unSelected: 0 }
    );

    const propertyCount = properties.length;
    const { selected, unSelected } = selectionStatus;
    const areAllSelected = propertyCount > 0 && selected === propertyCount;
    const areAllUnselected = unSelected === propertyCount;

    return {
      isBatchCheckBoxChecked: areAllSelected,
      isBatchCheckBoxIndeterminate: !areAllSelected && !areAllUnselected,
    };
  };

  const { isBatchCheckBoxChecked, isBatchCheckBoxIndeterminate } =
    getBatchCheckboxState();

  const updatePropertyIsChecked = (propertyId, isChecked) => {
    const updatedSelectedPropertiesMap = { ...propertySelectionMap };
    updatedSelectedPropertiesMap[propertyId].isSelected = isChecked;
    setPropertySelectionMap(updatedSelectedPropertiesMap);
  };

  const handleOnPageChange = (index) => {
    setPageIndex(index);
  };

  const handleEditButtonOnClick = () => {
    const selectedProperties = Object.values(propertySelectionMap).filter(
      (property) => property.isSelected
    );
    handleEditProperties(selectedProperties);
  };

  const handleEditActionOnClick = (propertyId) => {
    const property = propertySelectionMap[propertyId];
    handleEditProperties([property]);
  };

  const tableColumns = getColumns({
    getCurrentPagePropertyCount,
    getTotalPropertyCount,
    isBatchCheckBoxChecked,
    isBatchCheckBoxIndeterminate,
    onChangeBatchOption,
    propertySelectionMap,
    toggleSchedulingSwitch,
    updatePropertyIsChecked,
  });

  const tableActions = getTableActions(
    handleEditActionOnClick,
    EDIT_ACTION_TOOLTIP
  );

  return (
    <div className="scheduling-grid">
      <EditButton
        onClick={handleEditButtonOnClick}
        selectedCount={selectedPropertyCount}
        tooltip={EDIT_BUTTON_TOOLTIP}
      />
      <div className="scheduling-grid-data-table">
        <DataTable
          actions={tableActions}
          columns={tableColumns}
          components={{
            Container: (props) => (
              <Paper {...props} elevation={4} className="data-table-paper" />
            ),
            Pagination: Pagination,
          }}
          data={getTableData}
          isLoading={isFetchingPropertiesList}
          onChangePage={handleOnPageChange}
          options={getTableOptions(rowsPerPage, rowsPerPageOptions)}
          tableRef={tableRef}
        />
      </div>
    </div>
  );
};

SchedulingGrid.propTypes = {
  getCurrentPagePropertyCount: PropTypes.func,
  getPageIndex: PropTypes.func,
  getTableData: PropTypes.func,
  getTotalPropertyCount: PropTypes.func,
  handleEditProperties: PropTypes.func,
  isFetchingPropertiesList: PropTypes.bool,
  propertiesByPage: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        propertyId: PropTypes.number,
        propertyName: PropTypes.string,
        leasingTeamId: PropTypes.number,
        leasingTeamName: PropTypes.string,
        isSelfScheduleEnabled: PropTypes.bool,
        isInPersonToursEnabled: PropTypes.bool,
        isLiveVideoToursEnabled: PropTypes.bool,
      })
    )
  ),
  propertySelectionMap: PropTypes.shape({
    id: PropTypes.number,
    isSelected: PropTypes.bool,
    name: PropTypes.string,
  }),
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  selectedPropertyCount: PropTypes.number,
  setPageIndex: PropTypes.func,
  setPropertySelectionMap: PropTypes.func,
  setShouldLoadPageIndex: PropTypes.func,
  tableRef: PropTypes.shape({ current: PropTypes.object }),
  toggleSchedulingSwitch: PropTypes.func,
};

export default SchedulingGrid;
