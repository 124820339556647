import TemplatesAPI from './TemplatesAPI';
import { getEmailMergeTags } from '../../Services/message-service';

const fetchProperties = () =>
  TemplatesAPI.getTemplates()
    .then((response) => response.json())
    .then((json) => {
      const properties = json.templates.sort((propertyA, propertyB) => {
        return propertyA.property_name > propertyB.property_name ? 1 : -1;
      });
      return properties;
    })
    .catch(() => []);

export const fetchMergeTags = async () => {
  try {
    const response = await getEmailMergeTags();
    const data = await response.json();
    const { merge_tags: mergeTags = [] } = data || {};
    return mergeTags;
  } catch (_e) {
    return [];
  }
};

export const fetchTemplatesPageData = async () => {
  const properties = await fetchProperties();
  const mergeTags = await fetchMergeTags();
  return { mergeTags, properties };
};
