import { mlRemote } from '../../../../../Services/RemoteService';
import { AI_INTENT_NAME_TO_TYPE } from '../AiConstants';

export const getAiTemplates = async (company_id) => {
  try {
    const response = await mlRemote.get(`templates?company_id=${company_id}`);

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const intents = await response.json();
    const templates = normalizeIntentsByType(intents['intents']);
    return templates;
  } catch (error) {
    throw new Error('We are unable to manage ai email templates at this time.');
  }
};

const normalizeIntentsByType = (intents) => {
  return intents.reduce((intents, intent) => {
    const intentType = AI_INTENT_NAME_TO_TYPE[intent.intent_name];
    intents[intentType] = {
      intentId: intent.intent_id,
      templateCount: intent.template_count,
      templates: normalizeTemplates(intent.templates || []),
    };

    return intents;
  }, {});
};

const normalizeTemplates = (templates) => {
  return templates.map((template) => ({
    assignedPropertiesCount: template.property_count,
    templateId: template.template_id,
    templateName: template.template_name,
    properties: template.properties,
  }));
};
