import React, { useEffect, useState } from 'react';

import {
  ThemeProvider,
  makeStyles,
  Text
} from '@knockrentals/knock-shared-web';
import { NotificationService } from '../../Components/Notifications';
import { getUsers } from './SSDAGraphQLAPI';
import SSDAError from './SSDAError';
import SSDAList from './SSDAList';
import SSDALoading from './SSDALoading';
import SSDASetup from './SSDASetup';
import FeatureDrawer from '../../Pages/Features/common/FeatureDrawer';
import { SetupForm } from './SSDAForms/index';
import { SSDA_PAGE_VIEWS } from './constants';
import './_SSDAPage.scss';

const useStyles = makeStyles({
  pageTitle: {
    fontWeight: 600
  },
  pageDescription: {
    marginTop: '24px',
    width: '739px'
  }
});

const DESCRIPTION =
  'Set up a customer on Knock Data Cloud without having to include Product and Engineering in initial conversations or ticket creation. Snowflake credentials will be generated and sent securely to the appointed contact person/team who will then have access to the data.';

const SSDAPage = () => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [currentView, setCurrentView] = useState(SSDA_PAGE_VIEWS.LOADING);
  const [usersData, setUsersData] = useState(null);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const users = await getUsers({
          pageSize: 10,
          pageIndex: 0,
        });
        setUsersData(users);
        const view = users.totalUserCount === 0 ? SSDA_PAGE_VIEWS.SETUP : SSDA_PAGE_VIEWS.LIST
        setCurrentView(view);
      } catch (error) {
        NotificationService.notify('Error retrieving SSDA user data');
        setCurrentView(SSDA_PAGE_VIEWS.ERROR);
      }
    };
    getUserData();
  }, [currentView]);

  const renderView = () => {
    switch (currentView) {
      case SSDA_PAGE_VIEWS.ERROR:
        return <SSDAError />;

      case SSDA_PAGE_VIEWS.LIST:
        return (
          <SSDAList
            ipAddresses={usersData?.ipAddresses}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        );

      case SSDA_PAGE_VIEWS.LOADING:
        return <SSDALoading />;

      case SSDA_PAGE_VIEWS.SETUP:
        return (
          <SSDASetup
            setIsDrawerOpen={setIsDrawerOpen}
          />
        );

      default:
        return null;
    }
  };

  return (
    <ThemeProvider>
      <FeatureDrawer isOpen={isDrawerOpen}>
        <SetupForm
          closeDrawer={() => setIsDrawerOpen(false)}
          setCurrentView={setCurrentView}
        />
      </FeatureDrawer>

      <section>
        <Text className={classes.pageTitle} variant="h5">
          Knock Data Cloud
        </Text>

        <Text className={classes.pageDescription} color="textPrimary">
          {DESCRIPTION}
        </Text>

        {renderView()}
      </section>
    </ThemeProvider>
  );
};

export default SSDAPage;
