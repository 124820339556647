import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  makeStyles,
  CardActions,
} from '@knockrentals/knock-shared-web';
import { DeleteSvgIcon } from '../../../../../Components/Icons';
import { useFeatureFlagContext } from '../../../../../Context/FeatureFlagContext';

const useStyles = makeStyles((theme) => ({
  companyTemplateActions: {
    justifyContent: 'space-between',
    padding: '18px 22px',
    boxShadow: '0px -1px 0px rgba(24, 39, 76, 0.25)',
  },
  companyTemplateDeleteBtn: {
    color: theme.palette.error.dark,
  },
  actionButton: {
    marginRight: '10px',
  },
  CardActions: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    display: 'flex',
  },
}));

const CompanyTemplateActions = ({
  handleCancelOnClick,
  handleDeleteOnClick,
  handlePreviewOnClick,
  handleSaveOnClick,
  isCancelButtonHidden,
  isDeleteButtonVisible,
  isSaveButtonDisabled,
  primaryText = 'Save',
}) => {
  const classes = useStyles();

  const { isCompanyTemplatesPreviewEnabled } = useFeatureFlagContext();

  const renderSecondaryButton = () =>
    isCompanyTemplatesPreviewEnabled && handlePreviewOnClick ? (
      <Button
        className={classes.actionButton}
        color="primary"
        onClick={handlePreviewOnClick}
        variant="outlined"
      >
        Preview
      </Button>
    ) : (
      <Button
        className={classes.actionButton}
        color="primary"
        onClick={handleCancelOnClick}
        variant="outlined"
      >
        Cancel
      </Button>
    );

  return (
    <CardActions className={classes.companyTemplateActions}>
      {isDeleteButtonVisible && (
        <Button
          className={classes.companyTemplateDeleteBtn}
          color="primary"
          startIcon={<DeleteSvgIcon fontSize="small" />}
          variant="text"
          onClick={handleDeleteOnClick}
        >
          Delete Template
        </Button>
      )}

      <div className={classes.CardActions}>
        {!isCancelButtonHidden && renderSecondaryButton()}

        <Button
          className={classes.actionButton}
          color="primary"
          onClick={handleSaveOnClick}
          disabled={isSaveButtonDisabled}
        >
          {primaryText}
        </Button>
      </div>
    </CardActions>
  );
};

CompanyTemplateActions.propTypes = {
  handleCancelOnClick: PropTypes.func,
  handleDeleteOnClick: PropTypes.func,
  handlePreviewOnClick: PropTypes.func,
  handleSaveOnClick: PropTypes.func,
  isDeleteButtonVisible: PropTypes.bool,
  isCancelButtonHidden: PropTypes.bool,
  isSaveButtonDisabled: PropTypes.bool,
  primaryText: PropTypes.string,
};

export default CompanyTemplateActions;
