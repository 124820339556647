import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AccessSettingsInput extends Component {
  static propTypes = {
    service: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    accountRole: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
  };

  state = {
    isSelected: false,
  };

  componentDidMount() {
    const { settings, service, field, accountRole } = this.props;
    const isSelected = settings[service][field].includes(accountRole);

    this.setState({ isSelected });
  }

  onSelect() {
    const { service, field, accountRole } = this.props;
    const isSelected = !this.state.isSelected;

    this.props.onSelect(service, field, accountRole, isSelected);
    this.setState({ isSelected });
  }

  render() {
    return (
      <input type="checkbox" checked={this.state.isSelected} onChange={this.onSelect.bind(this)} />
    );
  }
}

export default AccessSettingsInput;