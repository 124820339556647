import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  CardActions,
  makeStyles,
} from '@knockrentals/knock-shared-web';

import ActionTemplatePreview from './ActionTemplatePreview';
import { DripCampaignCard } from '../../../DripCampaignCard';
import FeatureCardContent from '../../../../common/FeatureCardContent';

const SUBHEADER_TEXT = 'Select Template';

const useStyles = makeStyles({
  selectedTemplateCardActions: {
    minHeight: '32px',
    padding: '18px 22px',
    '&.MuiCardActions-root': {
      justifyContent: 'right',
      boxShadow: '0px -1px 0px rgba(24, 39, 76, 0.25)',
    },
  },
  templateCardContent: {
    background: '#FFFFFF',
  },
});

const SelectedTemplatePreview = ({
  closeDrawer,
  closeParentDrawer,
  handleSelectOnClick,
  template,
}) => {
  const classes = useStyles();

  const { templateId } = template;

  return (
    <DripCampaignCard
      closeDrawer={closeParentDrawer}
      handleBackButtonOnClick={closeDrawer}
      subheader={SUBHEADER_TEXT}
    >
      <FeatureCardContent className={classes.templateCardContent}>
        <ActionTemplatePreview
          templateId={templateId}
        />
      </FeatureCardContent>

      <CardActions className={classes.selectedTemplateCardActions}>
        <Button
          color="primary"
          onClick={handleSelectOnClick}
        >
          Select
        </Button>
      </CardActions>
    </DripCampaignCard>
  );
};

SelectedTemplatePreview.propTypes = {
  closeDrawer: PropTypes.func,
  closeParentDrawer: PropTypes.func,
  handleSelectOnClick: PropTypes.func,
  template: PropTypes.shape({
    contentType: PropTypes.string,
    templateId: PropTypes.number,
    templateName: PropTypes.string,
  }),
};

export default SelectedTemplatePreview;
