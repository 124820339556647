import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@knockrentals/knock-shared-web';

const ErrorDialog = ({ closeDialog, isOpen, message }) => (
  <Dialog open={isOpen} onClose={closeDialog}>
    <DialogContent>
      <Alert severity="error">{message}</Alert>
    </DialogContent>

    <DialogActions disableSpacing>
      <Button color="primary" onClick={closeDialog} variant="outlined">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

ErrorDialog.propTypes = {
  closeDialog: PropTypes.func,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
};

export default ErrorDialog;
