import { useEffect, useState } from 'react';
import { AuthenticationService } from '@knockrentals/knock-react';

import { getAiTemplates } from './AiTemplatesApi/aiTemplatesApi';
import { getIsAiEmailEnabled } from '../../../VirtualAgent/aiEmailAPI';
import PropertiesAPI from '../../../Properties/PropertiesAPI';
import { AI_VIEWS } from './AiConstants';

const useAiTemplates = () => {
  const [aiTemplates, setAiTemplates] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [aiView, setAiView] = useState();
  const [isLoadingAiPropertiesConfig, setIsLoadingAiPropertiesConfig] = useState(false);

  const fetchAiTemplates = async () => {
    setIsLoading(true);
    try {
      const tokenPayload = AuthenticationService.getTokenPayload() || {};
      const { company_id: companyId } = tokenPayload.sub || {};
      const templates = await getAiTemplates(companyId);
      setAiTemplates(templates);
    } catch (error) {
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  };

  const checkAiEmailEnabled = async () => {
    setIsLoadingAiPropertiesConfig(true);
    try {
      const propertiesResponse = await PropertiesAPI.getCommunityInfo();
      const propertyIds = propertiesResponse.properties.map((property) => property.id);

      const aiEmailEnabledPropertyList = await getIsAiEmailEnabled(propertyIds);

      const enabledProperties = aiEmailEnabledPropertyList.filter((property) => property.ai_email_enabled === 'ON' || property.ai_email_enabled === 'SETUP');
      const hasAtLeastOneOnProperty = enabledProperties.some(property => property.ai_email_enabled === 'ON');
      if (!enabledProperties.length) setAiView(AI_VIEWS.OFF);
      else if (hasAtLeastOneOnProperty) setAiView(AI_VIEWS.ON); 
      else setAiView(AI_VIEWS.SETUP);
    } catch (error) {
      setErrorMessage(error.message);
      setAiView(AI_VIEWS.OFF);
    }
    setIsLoadingAiPropertiesConfig(false);
  };

  useEffect(() => {
    checkAiEmailEnabled();
    if (aiView !== AI_VIEWS.OFF) {
      fetchAiTemplates();
    }
  }, [aiView]);

  const isLoadingState = isLoading || isLoadingAiPropertiesConfig;

  return {
    aiTemplates,
    errorMessage,
    fetchAiTemplates,
    isLoading: isLoadingState,
    setErrorMessage,
    aiView
  };
};

export default useAiTemplates;
