import React, { Component } from 'react';
import DoorwayAPI from './DoorwayAPI';
import CommunityService from '../../Services/CommunityService';
import PropertyDoorway from './PropertyDoorway';
import { PageContainer, Toaster, Alert } from '@knockrentals/knock-react';
import PropertiesAPI from '../Properties/PropertiesAPI';
import * as VirtualAgentAPI from '../VirtualAgent/VirtualAgentAPI';
import { v4 as uuidv4 } from 'uuid';

const LIVE_VIDEO_TOUR_TYPE = 'external';

class DoorwayPage extends Component {
  state = {
    isLoading: true,
    doorwaySettings: [],
    properties: [],
    aiChatByProperty: {},
    selectedPropertyId: 'all',
  };

  componentDidMount() {
    this.loadProperties();
  }

  async getVirtualAgent(properties) {
    const propertyIds = properties.map((property) => property.id);
    let virtualAgentResponse = null;

    try {
      virtualAgentResponse =
        await VirtualAgentAPI.getVirtualAgentsByPropertyIds(
          propertyIds.toString(),
          1,
          1000
        );
    } catch (e) {
      Toaster.showToast(
        'Error retrieving virtual agent data',
        2000,
        Toaster.ToastClasses.error
      );
      console.error(e);
    }

    const aiChatByProperty = {};

    if (virtualAgentResponse != null && virtualAgentResponse.results) {
      virtualAgentResponse.results.forEach((agent) => {
        aiChatByProperty[agent.property_id] = agent.is_chat_enabled;
      });
    }

    return aiChatByProperty;
  }

  async loadProperties() {
    let doorwaySettingsResponse = null;
    let propertiesResponse = null;
    let schedulingResponse = null;

    try {
      [doorwaySettingsResponse, propertiesResponse, schedulingResponse] =
        await Promise.all([
          DoorwayAPI.getDoorwaySettings(),
          PropertiesAPI.getCommunityInfo(),
          DoorwayAPI.getPropertiesScheduling(),
        ]);
    } catch (e) {
      Toaster.showToast(
        'Error retrieving properties',
        2000,
        Toaster.ToastClasses.error
      );
      console.error(e);
    }

    let doorwaySettings = [];
    let properties = [];
    let schedulingSettings = [];

    if (doorwaySettingsResponse != null) {
      doorwaySettings = doorwaySettingsResponse.company_doorway_settings.sort(
        (propertyA, propertyB) => {
          return propertyA.name > propertyB.name ? 1 : -1;
        }
      );
    }

    let aiChatByProperty = {};
    if (propertiesResponse != null) {
      properties = propertiesResponse.properties.map((property) => ({
        ...property,
        data: CommunityService.enforceCommunityIntegrity(property.data),
      }));
      aiChatByProperty = await this.getVirtualAgent(properties);
    }

    if (schedulingResponse !== null && schedulingResponse.property_hours_data) {
      schedulingSettings = [...schedulingResponse.property_hours_data];
      schedulingSettings.forEach((item) => {
        // We must generate *temporary* IDs for the Virtual Tour Mappings for use in the drag and drop component.
        // These IDs will NOT be stored on the backend, and will be stripped before being returned via API.
        item.preferences.virtual_tour_links_mapping =
          item.preferences.virtual_tour_links_mapping.map((tour) => ({
            ...tour,
            id: uuidv4(),
          }));
      });
    }

    this.setState({
      doorwaySettings,
      schedulingSettings,
      properties,
      aiChatByProperty,
      isLoading: false,
    });
  }

  render() {
    return (
      <PageContainer
        className="doorway-page-container"
        isLoading={this.state.isLoading}
      >
        <h1>Doorway</h1>

        <div className="knock-react-flex knock-react-flex-row">
          <div>
            <div>Property</div>
            <select
              value={this.state.selectedPropertyId}
              onChange={this.onSelectedPropertyChanged.bind(this)}
            >
              <option value={'all'}>All</option>
              {this.renderPropertyOptions()}
            </select>
          </div>
        </div>

        {this.renderProperties()}
      </PageContainer>
    );
  }

  onSelectedPropertyChanged(event) {
    this.setState({
      selectedPropertyId:
        event.target.value === 'all'
          ? event.target.value
          : parseInt(event.target.value, 10),
    });
  }

  renderPropertyOptions() {
    return this.state.doorwaySettings.map((doorwaySettings) => {
      return (
        <option
          key={'doorwaySettings-' + doorwaySettings.id}
          value={doorwaySettings.id}
        >
          {doorwaySettings.name}
        </option>
      );
    });
  }

  renderProperties() {
    if (this.state.doorwaySettings.length === 0) {
      return <Alert>No properties to display.</Alert>;
    }

    if (this.state.selectedPropertyId === 'all') {
      return this.state.doorwaySettings.map((doorwaySettings) => {
        const selectedProperty = this.state.properties.find(
          (property) => property.data.property_id === doorwaySettings.id
        );

        const selectedPropertySchedulingSettings =
          this.state.schedulingSettings.find(
            (property) => property.property_id === doorwaySettings.id
          );

        const selectedPropertyChatEnabledSettings =
          this.state.aiChatByProperty[doorwaySettings.id];

        return (
          <PropertyDoorway
            onDoorwayChanged={this.onDoorwayChanged.bind(this)}
            key={doorwaySettings.id}
            doorwaySettings={doorwaySettings}
            schedulingSettings={selectedPropertySchedulingSettings}
            isChatEnabled={selectedPropertyChatEnabledSettings}
            property={selectedProperty}
            onPropertyChanged={this.onPropertyChanged}
            onSchedulingChanged={this.onSchedulingChanged.bind(this)}
          />
        );
      });
    }
    const selectedDoorwaySettings = this.state.doorwaySettings.find(
      (property) => property.id === this.state.selectedPropertyId
    );

    const selectedProperty = this.state.properties.find(
      (property) => property.data.property_id === this.state.selectedPropertyId
    );

    const selectedPropertySchedulingSettings =
      this.state.schedulingSettings.find(
        (property) => property.property_id === this.state.selectedPropertyId
      );

    const selectedPropertyChatEnabledSettings =
      this.state.aiChatByProperty[this.state.selectedPropertyId];

    return (
      <PropertyDoorway
        onDoorwayChanged={this.onDoorwayChanged.bind(this)}
        key={selectedProperty.id}
        doorwaySettings={selectedDoorwaySettings}
        schedulingSettings={selectedPropertySchedulingSettings}
        chatEnabled={selectedPropertyChatEnabledSettings}
        property={selectedProperty}
        onPropertyChanged={this.onPropertyChanged}
        onSchedulingChanged={this.onSchedulingChanged.bind(this)}
      />
    );
  }

  _showSavingToaster = () => {
    const savingContent = (
      <span>
        <i className="fa fa-lg fa-spin fa-cog" /> Saving now...
      </span>
    );
    Toaster._fireShowToastHandlers(savingContent, Toaster.ToastClasses.success);
  };

  onPropertyChanged = (updatedProperty) => {
    this._showSavingToaster();

    const updatedPropertyIdx = this.state.properties.findIndex(
      (p) => p.id === updatedProperty.id
    );
    if (updatedPropertyIdx === -1) {
      Toaster.showToast(
        'Error saving property info.',
        2000,
        Toaster.ToastClasses.error
      );
      return;
    }

    this.setState((prevState) => {
      const newProperties = [...prevState.properties];
      newProperties[updatedPropertyIdx] = updatedProperty;
      return { properties: newProperties };
    });

    PropertiesAPI.updateProperty(
      updatedProperty.data.property_id,
      updatedProperty
    )
      .then(() => {
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      })
      .catch((e) => {
        Toaster.showToast(
          'Error saving property info.',
          2000,
          Toaster.ToastClasses.error
        );
        console.log(e);
      });
  };

  onSchedulingChanged(propertyId, settingsUpdates) {
    const updatedSchedulingSettings = [...this.state.schedulingSettings];

    const updatedPropertyIndex = updatedSchedulingSettings
      .map((settings) => settings.property_id)
      .indexOf(propertyId);

    if (updatedPropertyIndex === -1) {
      Toaster.showToast(
        'Error saving property info.',
        2000,
        Toaster.ToastClasses.error
      );

      return;
    }

    updatedSchedulingSettings[updatedPropertyIndex].preferences =
      settingsUpdates;

    // Before sending the updated virtual tour links to the backend, we must strip the temporary IDs,
    // -- we leave the IDs on the mappings placed in state, as they might be reordered further.
    const virtualTourLinksWithoutIds =
      settingsUpdates.virtual_tour_links_mapping.map((mapping) => ({
        name: mapping.name,
        url: mapping.url,
      }));

    // We must explicitly pass the liveVideo setting into the API, even though we're not editing the
    // value here -- otherwise, will default to 'false' could inadvertently change the setting.
    const schedulingSettingsPayload = {
      liveVideo: settingsUpdates.live_video_tour_type === LIVE_VIDEO_TOUR_TYPE,
      virtualTourLinks: settingsUpdates.virtual_tour_links,
      virtualTourLinksMapping: virtualTourLinksWithoutIds,
    };

    this.setState({ schedulingSettings: updatedSchedulingSettings }, () => {
      DoorwayAPI.updatePropertyOwnerSchedulingPreferences(
        propertyId,
        schedulingSettingsPayload
      )
        .then(() => {
          Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
        })
        .catch((e) => {
          Toaster.showToast(
            'Error saving property info.',
            2000,
            Toaster.ToastClasses.error
          );
          console.log(e);
        });
    });
  }

  onDoorwayChanged(propertyId, updatedSettings) {
    this._showSavingToaster();
    const updatedDoorwaySettings = this.state.doorwaySettings.slice();

    updatedDoorwaySettings.find(
      (property) => property.id === propertyId
    ).settings = updatedSettings;

    this.setState({ doorwaySettings: updatedDoorwaySettings }, () => {
      DoorwayAPI.updateDoorwaySettings(propertyId, updatedSettings).then(() => {
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      });
    });
  }
}

export default DoorwayPage;
