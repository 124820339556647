import React from 'react';
import {
  makeStyles,
  Select,
  Autocomplete,
  TextField,
  Box,
} from '@knockrentals/knock-shared-web';
import {
  getHousingTypes,
  getPropertyClasses,
  getValidYears,
} from './BenchmarkingAttributesLookupTables';

const useStyles = makeStyles(() => ({
  // These styles are needed to correct the icon sizes in the Autocomplete fields since they are getting reduced somehow within this project
  selectYearEndAdornment: {
    '& .MuiAutocomplete-endAdornment': {
      '& .MuiSvgIcon-fontSizeSmall': {
        fontSize: '20px',
      },

      '& .MuiAutocomplete-popupIndicator': {
        '& .MuiSvgIcon-root': {
          fontSize: '24px',
        },
      },
    },
  },

  // This style removes the bottom margin for the FormControl component that MUI uses inside the Autocomplete, which isn't ideal when it's used
  // in a table
  yearSelection: {
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
  },
}));

export const getColumns = ({ saveData }) => {
  const classes = useStyles();

  return [
    ['Property', 'propertyName', undefined, '350px'],
    [
      'Housing Type',
      'housingType',
      'string',
      '320px',
      (rowData) => {
        return (
          <Select
            aria-label="Select a housing type"
            defaultValue={rowData.data.benchmarking.housingType}
            fullWidth
            name="housing type"
            noForm
            onSelectChange={(value) =>
              saveData('housingType', value, rowData.propertyId)
            }
            options={getHousingTypes()}
          />
        );
      },
    ],
    [
      'Property Class',
      'propertyClass',
      'string',
      '180px',
      (rowData) => {
        return (
          <Select
            aria-label="Select a property class"
            defaultValue={rowData.data.benchmarking.propertyClass}
            fullWidth
            name="property class"
            noForm
            onSelectChange={(value) =>
              saveData('propertyClass', value, rowData.propertyId)
            }
            options={getPropertyClasses()}
          />
        );
      },
    ],
    [
      'Year Built',
      'yearBuilt',
      'string',
      '160px',
      (rowData) => {
        return (
          <Box className={classes.yearSelection}>
            <Autocomplete
              getOptionLabel={(option) =>
                option ? (option.label ? option.label.toString() : '') : ''
              }
              onChange={(_, option) => {
                if (option) {
                  return saveData(
                    'yearBuilt',
                    option.value,
                    rowData.propertyId
                  );
                }
              }}
              options={getValidYears()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.selectYearEndAdornment}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              value={{
                label: rowData.data.benchmarking.yearBuilt,
                value: rowData.data.benchmarking.yearBuilt,
              }}
            />
          </Box>
        );
      },
    ],
    [
      'Year Renovated',
      'yearRenovated',
      'string',
      '152px',
      (rowData) => {
        return (
          <Box className={classes.yearSelection}>
            <Autocomplete
              getOptionLabel={(option) =>
                option ? (option.label ? option.label.toString() : '') : ''
              }
              onChange={(_, option) => {
                if (option) {
                  return saveData(
                    'yearRenovated',
                    option.value,
                    rowData.propertyId
                  );
                }
              }}
              options={getValidYears()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.selectYearEndAdornment}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              value={{
                label: rowData.data.benchmarking.yearRenovated,
                value: rowData.data.benchmarking.yearRenovated,
              }}
            />
          </Box>
        );
      },
    ],
  ];
};

export const getColumnHeaderConfig = (column) => {
  const [title, field, type, width, render] = column;

  return {
    align: 'left',
    title,
    field,
    type,
    width,
    cellStyle: {
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    },
    render,
  };
};
