import React from 'react';
import { Paper } from '@material-ui/core';
import DataTable from 'material-table';
import { CompanySourceBatchEnable } from './CompanySourceBatchEnable';
import { CompanySourceEnable } from './CompanySourceEnable';

export const getColumnHeaders = (properties, updateSourceProperties) => [
  [
    <CompanySourceBatchEnable
      properties={properties}
      updateSourceProperties={updateSourceProperties}
    />,
    'enabled',
    '200px',
  ],
  ['LeasingTeam', 'leasingTeam', '200px'],
  ['Property', 'propertyName', '200px']
];

export const getColumnHeaderConfig = (column) => {
  const [title, field, width] = column;
  return {
    align: 'left',
    field,
    title,
    width,
  };
};

export const getSourcePropertiesTableData =
  (updateSourceProperties) => (property) => {
    const updateSourceProperty = (updatedPropertyData) => {
      updateSourceProperties({
        [property.leasingTeamId]: updatedPropertyData,
      });
    };

    return {
      enabled: (
        <CompanySourceEnable
          property={property}
          updateSourceProperty={updateSourceProperty}
        />
      ),
      propertyName: property.properties.map( function(val) { return val.name; }).join(",\n"),
      leasingTeam: property.leasingTeamName,
    };
  };

const options = {
  draggable: false,
  grouping: false,
  headerStyle: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '32px',
    position: 'static',
  },
  padding: 'dense',
  paging: false,
  rowStyle: {
    fontSize: '14px',
  },
  search: false,
  sorting: false,
  tableLayout: 'fixed',
  toolbar: false,
};

const SourcePropertiesDataTable = ({
  properties = [],
  updateSourceProperties,
}) => {
  const columnHeaders = getColumnHeaders(properties, updateSourceProperties);
  return (
    <div
      className="source-properties-data-table"
      data-testid="source-properties-data-table"
    >
      <DataTable
        columns={columnHeaders.map(getColumnHeaderConfig)}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        data={properties.map(
          getSourcePropertiesTableData(updateSourceProperties)
        )}
        options={options}
      />
    </div>
  );
};

export default SourcePropertiesDataTable;
