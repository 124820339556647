import React, { Component } from 'react';
import { Modal } from '@knockrentals/knock-react';


class ForgetGuestcardConfirmation extends Component {

  render() {
    return (
      <Modal className="forget-guestcard-confirmation-modal">

        <div className="forget-guestcard-confirmation-container">

          <i className="fa fa-exclamation-triangle" />

          <div className="forget-guestcard-confirmation-blurb">
            Forgetting a guestcard is an irreversible action.<br/>
            Are you sure you want to proceed?<br/>
            <strong>{ this.props.isConfirmingForgetGuestcard }</strong>
          </div>

          <div className="forget-guestcard-confirmation-actions">
            <button className="knock-react-button btn-success" onClick={this.cancel.bind(this)}>
              <i className="fa fa-arrow-left" /> Cancel this action
            </button>
            <button className="knock-react-button btn-warning" onClick={this.submit.bind(this)}>
              <i className="fa fa-user-times" /> Confirm
            </button>
          </div>
        </div>

      </Modal>
    );
  }

  submit(event) {
    event.preventDefault();
    this.props.onSubmit();
  }

  cancel(event) {
    event.preventDefault();
    this.props.onCancel();
  }

}

export default ForgetGuestcardConfirmation;
