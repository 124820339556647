import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Text,
  makeStyles,
} from '@knockrentals/knock-shared-web';

const useStyles = makeStyles(() => ({
  dialogActions: {
    padding: '16px',
  },
  mBottom20: {
    marginBottom: '20px',
  },
}));

const CancelBulkEditModal = ({ isOpen, handleClose }) => {
  const classes = useStyles();
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle disableTypography={true}>
        <Text variant="h6" className={classes.mBottom20}>
          Close Drawer
        </Text>
        <Text variant="body1">
          If you close this drawer now your changes will not be saved.
        </Text>
      </DialogTitle>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleClose(true);
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelBulkEditModal;