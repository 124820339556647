import { BASE_WEBAPP_URI } from '../config';

class CookieService {
   static setTokenCookie = (tokenValue, tokenField = 'token') => {
    const token = `${tokenField}=${tokenValue}`;
    // Grab the domain and tld from config so this can be tested in dev env
    // (because the config varies -- knockrentals.com vs knocktest.com)
    // eslint-disable-next-line no-unused-vars
    const [subdom, dom, tld] = BASE_WEBAPP_URI.split('.');
    const domain = `domain=${[dom, tld].join('.')}`;
    const path = `path=/`;
    // We're deliberately setting both path and domain and deliberately *not* setting expiration
    // * we need path and domain to be set this way so we can use this cookie across subdomains
    // * we need expiration unset so that the token is only a session cookie
    document.cookie = `${token}; ${domain}; ${path};`;
  };
}

export default CookieService;
