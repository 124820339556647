export const normalizePropertiesBenchmarkingAttributes = (properties) => {
  const propertyData = properties.properties.map((property) => {
    const benchmarking = {
      housingType: '0',
      propertyClass: '0',
      yearBuilt: null,
      yearRenovated: null,
    };

    if (property.data.benchmarking) {
      benchmarking.housingType = property.data.benchmarking.housingType
        ? property.data.benchmarking.housingType
        : '0';
      benchmarking.propertyClass = property.data.benchmarking.propertyClass
        ? property.data.benchmarking.propertyClass
        : '0';
      benchmarking.yearBuilt = property.data.benchmarking.yearBuilt;
      benchmarking.yearRenovated = property.data.benchmarking.yearRenovated;
    }

    return {
      propertyId: property.id,
      propertyName: property.data.location.name,
      preferences: property.preferences,
      data: { ...property.data, benchmarking },
      propertyCustomFees: property.custom_fees,
      hasBenchmarkData: false,
    };
  });
  return propertyData;
};
