import React, { Component } from 'react';
import SheetsAPI from './SheetsAPI';

import { PageContainer, Toaster, AuthenticationService } from '@knockrentals/knock-react';

import './_sheetsPage.scss';
import moment from 'moment';

class SheetsPage extends Component {
  state = {
    properties: [],
    isLoading: false,
    isRefreshingProperty: {},
  };

  UNSAFE_componentWillMount() {
    this.loadProperties();
  }

  loadProperties() {
    SheetsAPI.getPropertySpreadsheets()
      .then(response => {
        const properties = response.company_property_spreadsheets;

        properties.sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });

        this.setState({ properties });
      })
      .catch(e => {
        console.log(e);
      });
  }

  render() {
    return (
      <PageContainer className="sheets-info-page-container" isLoading={this.state.isLoading}>
        <h1>Analytics Sheets</h1>
        <div>
          <table className="wire-frame-table">
            <thead>
              <tr>
                <th></th>
                <th>Event Data</th>

                {AuthenticationService._internalMode ? <th>Last Refresh</th> : null}

                <th>
                  {AuthenticationService._internalMode ? (
                    <button
                      className="btn-sm btn-success cell-value actionable"
                      onClick={this.refreshAllSpreadsheets.bind(this)}
                    >
                      <i className="fa fa-refresh" /> Refresh All
                    </button>
                  ) : (
                    <span>
                      Settings <i className="fa fa-wrench" />
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>{this.renderPropertyRows()}</tbody>
          </table>
        </div>
      </PageContainer>
    );
  }

  renderPropertyRows() {
    return this.state.properties.map((property, i) => {
      return (
        <tr key={`property-${property.id}`}>
          <td>{property.name}</td>

          {property.spreadsheets && property.spreadsheets.raw_data_url ? (
            <td>
              <span
                className="label label-default actionable"
                title={`Last refreshed:\n${moment(
                  property.spreadsheets.raw_data_last_refresh_time
                ).format('MMM D, h:mm:ss a')}`}
              >
                <i className="fa fa-clock-o" />
              </span>
              <span className="label">
                <a href={property.spreadsheets.raw_data_url} target="_blank">
                  View
                </a>
              </span>
            </td>
          ) : (
            <td className="shaded" />
          )}

          {AuthenticationService._internalMode ? (
            property.spreadsheets && property.spreadsheets.raw_data_last_refresh_time ? (
              <td>
                <span className="label">
                  {moment(property.spreadsheets.raw_data_last_refresh_time).isBefore(
                    moment().subtract(16, 'hours')
                  ) ? (
                    <span className="pull-right" title="Last refresh was before 16 hours ago">
                      <i className="fa fa-exclamation-triangle" />
                    </span>
                  ) : null}
                  {moment(property.spreadsheets.raw_data_last_refresh_time).format(
                    'MMM D, h:mm:ss a'
                  )}
                </span>
              </td>
            ) : (
              <td className="shaded" />
            )
          ) : null}

          <td>
            {this.state.isRefreshingProperty[property.id] ? (
              <button className="btn-sm btn-success cell-value" disabled>
                <i className="fa fa-cog fa-spin" /> Loading
              </button>
            ) : property.spreadsheets ? (
              AuthenticationService._internalMode ? (
                <button
                  className="btn-sm btn-success cell-value actionable"
                  onClick={this.refreshSpreadsheetForProperty.bind(this, property.id)}
                >
                  <i className="fa fa-refresh" /> Refresh
                </button>
              ) : null
            ) : (
              AuthenticationService._internalMode ? (
                <button
                  className="btn-sm btn-warning cell-value actionable"
                  onClick={this.refreshSpreadsheetForProperty.bind(this, property.id)}
                >
                  <i className="fa fa-plus-circle" /> Activate
                </button>
              ) : null
            )}
          </td>
        </tr>
      );
    });
  }

  refreshSpreadsheetForProperty(propertyId) {
    const isRefreshingProperty = this.state.isRefreshingProperty;

    isRefreshingProperty[propertyId] = true;
    this.setState({ isRefreshingProperty });

    SheetsAPI.refreshPropertySpreadsheets(propertyId)
      .then(() => {
        Toaster.showToast('Refreshed!', 2000, Toaster.ToastClasses.success);
        this.loadProperties();
      })
      .catch(e => {
        Toaster.showToast(
          'Error refreshing spreadsheets for property',
          2000,
          Toaster.ToastClasses.error
        );
        console.log(e);
      })
      .finally(() => {
        isRefreshingProperty[propertyId] = false;
        this.setState({ isRefreshingProperty });
      });
  }

  refreshAllSpreadsheets() {
    SheetsAPI.refreshAllPropertySpreadsheetsOnCompany()
      .then(() => {
        Toaster.showToast(
          'Received request to refresh all listed spreadsheets',
          2000,
          Toaster.ToastClasses.success
        );
        this.loadProperties();
      })
      .catch(e => {
        Toaster.showToast(
          'Error with initiating request to refresh all spreadsheets',
          2000,
          Toaster.ToastClasses.error
        );
        console.log(e);
      });
  }
}

export default SheetsPage;
