import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, CardActions } from '@knockrentals/knock-shared-web';
import ActionIconButton from './ActionIconButton';

const useStyles = makeStyles({
  featureActions: {
    justifyContent: 'space-between',
    minHeight: '32px',
    padding: '18px 22px',
    boxShadow: '0px -1px 0px rgba(24, 39, 76, 0.25)',
  },
  CardActions: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    display: 'flex',
    '& .action-button': {
      marginRight: '10px',
    },
  },
});

const DrawerActions = ({
  children,
  handleIconButtonOnClick,
  iconButtonText,
  isIconButtonVisible,
  status,
}) => {
  const classes = useStyles();

  return (
    <CardActions className={classes.featureActions}>
      {isIconButtonVisible && (
        <ActionIconButton
          buttonText={iconButtonText}
          handleOnClick={handleIconButtonOnClick}
          status={status}
        />
      )}
      {children && <div className={classes.CardActions}>{children}</div>}
    </CardActions>
  );
};

DrawerActions.propTypes = {
  children: PropTypes.node,
  handleIconButtonOnClick: PropTypes.func,
  iconButtonText: PropTypes.string,
  isIconButtonVisible: PropTypes.bool,
  status: PropTypes.string,
};

export default DrawerActions;
