import { apiRemote } from '../../Services/RemoteService';
import queryString from 'query-string';

class HoursAPI {
  static Timezones = [
    { name: 'Hawaii', id: 'Pacific/Honolulu' },
    { name: 'Alaska', id: 'America/Anchorage' },
    { name: 'Pacific', id: 'America/Los_Angeles' },
    { name: 'Mountain', id: 'America/Denver' },
    { name: 'Central', id: 'America/Chicago' },
    { name: 'Eastern', id: 'America/New_York' },
    { name: 'Arizona', id: 'America/Phoenix' },
    { name: 'Regina / Saskatchewan / CST', id: 'America/Regina' },
  ];

  static getPropertiesHours(tourType=null) {
    const tourTypeParam = tourType
      ? `?${queryString.stringify({ 'tour_type': tourType })}`
      : '';
    return apiRemote
      .get(`admin/hours${tourTypeParam}`)
      .then(response => response.json())
      .catch(e => e);
  }

  static updatePropertyOwnerHours(propertyId, updatedHours, tour_type=null) {
    const payload = updatedHours.map(weekdayHours => {
      return {
        is_inactive: !weekdayHours.isActive,
        weekday: weekdayHours.weekday,
        start_time: weekdayHours.start_time,
        end_time: weekdayHours.end_time,
        max_visitors: weekdayHours.max_visitors,
      };
    });

    return apiRemote
      .put(`admin/hours/${propertyId}`, { property_hours_data: payload, tour_type })
      .then(response => response.json())
      .catch(e => e);
  }

  static updatePropertyOwnerTimezone(propertyId, updatedTimezone) {
    const payload = {
      property_owner_timezone: updatedTimezone,
    };

    return apiRemote
      .put(`admin/hours/${propertyId}`, payload)
      .then(response => response.json())
      .catch(e => e);
  }
}

export default HoursAPI;
