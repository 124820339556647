import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@knockrentals/knock-shared-web';
import { TemplateDataTable } from './TemplateDataTable';
import { DataTableToolBar } from '../DataTableToolBar';

export const DESCRIPTION =
  'Templates are the customized email messages that are sent through a Campaign. You can apply the same template to any Action of a given Campaign, and even to multiple Campaigns.';

const TemplateView = ({
  getTableData,
  isFetching,
  rowsPerPage,
  rowsPerPageOptions,
  setPageIndex,
  setShouldLoadPageIndex,
  setTemplate,
  tableRef,
  totalTemplateCount,
}) => {
  return (
    <section className="template-view">
      <div className="view-description">
        <Text variant="caption">{DESCRIPTION}</Text>
      </div>

      <DataTableToolBar
        count={totalTemplateCount}
      />

      <TemplateDataTable
        getTableData={getTableData}
        isFetching={isFetching}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        setPageIndex={setPageIndex}
        setShouldLoadPageIndex={setShouldLoadPageIndex}
        setTemplate={setTemplate}
        tableRef={tableRef}
      />
    </section>
  );
};

TemplateView.propTypes = {
  getTableData: PropTypes.func,
  isFetching: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  setPageIndex: PropTypes.func,
  setShouldLoadPageIndex: PropTypes.func,
  setTemplate: PropTypes.func,
  tableRef: PropTypes.shape({ current: PropTypes.object }),
  totalTemplateCount: PropTypes.number,
};

export default TemplateView;
