import { makeStyles } from '@knockrentals/knock-shared-web';

export const useStyles = makeStyles({
  formInput: {
    padding: '0 12px 8px 12px',
  },
  divider: {
    margin: '16px 0',
  },

  textFieldInput: {
    '& .MuiFilledInput-root:not(.Mui-disabled)': {
      backgroundColor: '#ffffff',
      border: 'none',
      padding: 0,
    },
    '& label.MuiInputLabel-formControl': {
      fontWeight: '400',
      transform: 'translate(0, 24px) scale(.85)',
      top: '5px',
      left: '12px',
    },
    '& .MuiFilledInput-underline:before': {
      border: 'none',
    },
    '& .MuiFormHelperText-contained': {
      backgroundColor: '#ffffff',
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      paddingTop: '3px',
      paddingLeft: '10px',
      paddingRight: '14px',
    },
  },
});
