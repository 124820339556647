import { apiRemote } from '../../Services/RemoteService';

class TemplatesAPI {
  static getTemplates() {
    return apiRemote.get(`admin/templates`);
  }

  static updateTemplates(propertyId, payload) {
    const {
      email_subject,
      email_template,
      sms_template,
      self_guided_email_template,
      self_guided_sms_template,
      self_guided_tour_email_instructions,
      self_guided_tour_sms_instructions,
      live_video_tour_instructions,
      live_video_tour_url,
      tours_mobile_quote_template,
      mytour_agenda,
      self_guided_detailed_instructions,
      suppressed_autoresponders,
      winback_email_template
    } = payload;

    return apiRemote.put(`admin/templates/${propertyId}`, {
      email_subject,
      email_template,
      sms_template,
      self_guided_email_template,
      self_guided_sms_template,
      self_guided_tour_email_instructions,
      self_guided_tour_sms_instructions,
      live_video_tour_instructions,
      live_video_tour_url,
      tours_mobile_quote_template,
      mytour_agenda,
      self_guided_detailed_instructions,
      suppressed_autoresponders,
      winback_email_template
    });
  }
}

export default TemplatesAPI;
