import { useEffect, useState } from 'react';
import {
  getEmailMergeTags,
  MERGE_TAG_TYPES,
} from '../../../../Services/message-service';

const useMergeTags = (mergeTagType = MERGE_TAG_TYPES.DEFAULT) => {
  const [mergeTags, setMergeTags] = useState({});
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const fetchMergeTags = async () => {
      try {
        const response = await getEmailMergeTags();
        const data = await response.json();
        const { merge_tags: mergeTags = {} } = data || {};
        setMergeTags(mergeTags);
      } catch (_e) {
        setMergeTags({});
      }
      setIsFetching(false);
    };

    fetchMergeTags();
  }, []);

  return {
    isFetchingMergeTags: isFetching,
    mergeTags: mergeTags[mergeTagType],
  };
};

export default useMergeTags;
