import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Avatar, makeStyles } from '@knockrentals/knock-shared-web';

const BORDER_COLOR = {
  DOTTED: '#D3D3D3',
  SOLID: '#548F85',
};
export const LOGO_ARIA_LABEL = 'logo thumbnail';
const PLACEHOLDER_SPACE = ' ';

const useStyles = makeStyles({
  logoAvatar: {
    width: '22px',
    height: '22px',
    border: `1px solid ${BORDER_COLOR.SOLID}`,
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    '& .MuiAvatar-img': {
      objectFit: 'contain',
    },
    '&.empty': {
      border: `1px dotted ${BORDER_COLOR.DOTTED}`,
    },
  },
});

const LogoAvatar = ({ logoUrl }) => {
  const classes = useStyles();

  return (
    <Avatar
      aria-label={LOGO_ARIA_LABEL}
      className={classnames(classes.logoAvatar, !logoUrl && 'empty')}
      src={logoUrl || ''}
      variant="rounded"
    >
      {!logoUrl && PLACEHOLDER_SPACE}
    </Avatar>
  );
};

LogoAvatar.propTypes = {
  logoUrl: PropTypes.string,
};

export default LogoAvatar;
