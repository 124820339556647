import { apiRemote } from './RemoteService';


class CompanyService {
    static getCompanyInfo() {
      return apiRemote
        .get(`admin/utilities/info/company`)
        .then(response => response.json())
        .catch(e => e);
    }
  }


export default CompanyService;
