import { apiRemote } from '../../Services/RemoteService';

class UnitsApi {
  static getUnits(propertyId) {
    return apiRemote
      .get(`property/${propertyId}/units`)
      .then(response => response.json())
      .catch(e => console.error(`Error fetching units for property id ${propertyId}: ${e}`));
  }

  static getLayouts(propertyId) {
    return apiRemote
      .get(`property/${propertyId}/layouts`)
      .then(response => response.json())
      .catch(e => console.error(`Error fetching layouts for property id ${propertyId}: ${e}`));
  }

  static getLayout(propertyId, layoutId) {
    return apiRemote
      .get(`property/${propertyId}/layouts/${layoutId}`)
      .then(response => response.json())
      .catch(e => console.error(`Error fetching layout ${layoutId} for property id ${propertyId}: ${e}`));

  }
}

export default UnitsApi;
