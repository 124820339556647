import React from 'react';
import PropTypes from 'prop-types';

import { InputCheckbox } from '@knockrentals/knock-shared-web';

const DataTableCheckbox = ({
  arialLabel = 'checkbox',
  isChecked = false,
  isIndeterminate = false,
  name = 'isSelected',
  onChangeHandler,
}) => {
  const handleOnChange = (e) => {
    const { name, checked } = e.target;
    onChangeHandler({ [name]: checked });
  };
  return (
    <InputCheckbox
      checked={isChecked}
      color="primary"
      onChange={handleOnChange}
      indeterminate={isIndeterminate}
      inputProps={{
        'aria-label': arialLabel,
      }}
      name={name}
    />
  );
};

DataTableCheckbox.propTypes = {
  arialLabel: PropTypes.string,
  isChecked: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  name: PropTypes.string,
  onChangeHandler: PropTypes.func,
};

export default DataTableCheckbox;
