import { getDefaultTableOptions } from '../../../../../Services/SharedService';

const defaultTableOptions = getDefaultTableOptions();

export const getTableOptions = () => ({
  ...defaultTableOptions,
  actionsCellStyle: {
    width: '40px',
  },
  actionsColumnIndex: -1,
  detailPanel: false,
  draggable: false,
  emptyRowsWhenPaging: false,
  headerStyle: {
    fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
    lineHeight: '24px',
    padding: '16px',
    fontSize: '14px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  paging: false,
  search: false,
  showFirstLastPageButtons: false,
  sorting: true,
  toolbar: false,
});
