import {
  AVAILABILITY_BUFFER,
  DAYS_OF_THE_WEEK,
  END_TIME,
  INCOME_MULTIPLIER,
  MAX_VISITORS,
  NUMBER_OF_SCHEDULING_DAYS,
  POST_APPOINTMENT_BUFFER,
  PRE_APPOINTMENT_BUFFER,
  START_TIME,
  TIMEZONE,
  TOUR_DURATION,
} from './constants';

export const toursHoursOptions = [
  { value: '07:00:00', label: '7:00 am' },
  { value: '07:15:00', label: '7:15 am' },
  { value: '07:30:00', label: '7:30 am' },
  { value: '07:45:00', label: '7:45 am' },
  { value: '08:00:00', label: '8:00 am' },
  { value: '08:15:00', label: '8:15 am' },
  { value: '08:30:00', label: '8:30 am' },
  { value: '08:45:00', label: '8:45 am' },
  { value: '09:00:00', label: '9:00 am' },
  { value: '09:15:00', label: '9:15 am' },
  { value: '09:30:00', label: '9:30 am' },
  { value: '09:45:00', label: '9:45 am' },
  { value: '10:00:00', label: '10:00 am' },
  { value: '10:15:00', label: '10:15 am' },
  { value: '10:30:00', label: '10:30 am' },
  { value: '10:45:00', label: '10:45 am' },
  { value: '11:00:00', label: '11:00 am' },
  { value: '11:15:00', label: '11:15 am' },
  { value: '11:30:00', label: '11:30 am' },
  { value: '11:45:00', label: '11:45 am' },
  { value: '12:00:00', label: '12:00 pm' },
  { value: '12:15:00', label: '12:15 pm' },
  { value: '12:30:00', label: '12:30 pm' },
  { value: '12:45:00', label: '12:45 pm' },
  { value: '13:00:00', label: '1:00 pm' },
  { value: '13:15:00', label: '1:15 pm' },
  { value: '13:30:00', label: '1:30 pm' },
  { value: '13:45:00', label: '1:45 pm' },
  { value: '14:00:00', label: '2:00 pm' },
  { value: '14:15:00', label: '2:15 pm' },
  { value: '14:30:00', label: '2:30 pm' },
  { value: '14:45:00', label: '2:45 pm' },
  { value: '15:00:00', label: '3:00 pm' },
  { value: '15:15:00', label: '3:15 pm' },
  { value: '15:30:00', label: '3:30 pm' },
  { value: '15:45:00', label: '3:45 pm' },
  { value: '16:00:00', label: '4:00 pm' },
  { value: '16:15:00', label: '4:15 pm' },
  { value: '16:30:00', label: '4:30 pm' },
  { value: '16:45:00', label: '4:45 pm' },
  { value: '17:00:00', label: '5:00 pm' },
  { value: '17:15:00', label: '5:15 pm' },
  { value: '17:30:00', label: '5:30 pm' },
  { value: '17:45:00', label: '5:45 pm' },
  { value: '18:00:00', label: '6:00 pm' },
  { value: '18:15:00', label: '6:15 pm' },
  { value: '18:30:00', label: '6:30 pm' },
  { value: '18:45:00', label: '6:45 pm' },
  { value: '19:00:00', label: '7:00 pm' },
  { value: '19:15:00', label: '7:15 pm' },
  { value: '19:30:00', label: '7:30 pm' },
  { value: '19:45:00', label: '7:45 pm' },
  { value: '20:00:00', label: '8:00 pm' },
  { value: '20:15:00', label: '8:15 pm' },
  { value: '20:30:00', label: '8:30 pm' },
  { value: '20:45:00', label: '8:45 pm' },
  { value: '21:00:00', label: '9:00 pm' },
  { value: '21:15:00', label: '9:15 pm' },
  { value: '21:30:00', label: '9:30 pm' },
  { value: '21:45:00', label: '9:45 pm' },
  { value: '22:00:00', label: '10:00 pm' },
];

export const getHourValue = (hour) => `${String(hour).padStart(2, '0')}:00:00`;
export const getHoursLabel = (hour) =>
  hour === 0 ? 'None' : `${hour} hour${hour === 1 ? '' : 's'}`;

export const getHoursOptions = () => {
  const hours = [...new Array(24)].map((_, hour) => {
    return {
      label: getHoursLabel(hour),
      value: getHourValue(hour),
    };
  });

  return [
    ...hours,
    { label: '24 hours', value: '1 day 00:00:00' }
  ];
};

export const getMaxVisitorOptions = () =>
  [...new Array(20)].map((_, index) => {
    const maxVisitors = index + 1;
    return {
      label: maxVisitors,
      value: maxVisitors,
    };
  });

export const getNumberOfDaysOutOptions = () => {
  return [...new Array(22)].map((_, index) => {
    const firstValue = 7;
    const numberOfDaysOut = firstValue + index;
    return { label: `${numberOfDaysOut} days`, value: numberOfDaysOut };
  });
};

export const getIncomeMultiplierOptions = () => {
  const notRequiredOption = { label: 'Not Required', value: 0 };
  const multipliers = [2, 2.5, 3, 3.5, 4];
  const multiplierOptions = multipliers.map((multiplier) => ({
    label: `${multiplier}x Rent`,
    value: multiplier,
  }));

  return [notRequiredOption, ...multiplierOptions];
};

export const tourDurationHours = [
  { label: '15 minutes', value: '0:15:00' },
  { label: '30 minutes', value: '0:30:00' },
  { label: '45 minutes', value: '0:45:00' },
  { label: '60 minutes', value: '1:00:00' },
];

export const appointmentBufferHours = [
  { label: 'None', value: '00:00:00' },
  { label: '15 minutes', value: '00:15:00' },
  { label: '30 minutes', value: '00:30:00' },
  { label: '45 minutes', value: '00:45:00' },
  { label: '60 minutes', value: '01:00:00' },
];

export const timeZoneOptions = [
  { label: 'Hawaii', value: 'Pacific/Honolulu' },
  { label: 'Alaska', value: 'America/Anchorage' },
  { label: 'Pacific', value: 'America/Los_Angeles' },
  { label: 'Mountain', value: 'America/Denver' },
  { label: 'Central', value: 'America/Chicago' },
  { label: 'Eastern', value: 'America/New_York' },
  { label: 'Arizona', value: 'America/Phoenix' },
  { label: 'Regina / Saskatchewan / CST', value: 'America/Regina' },
];

export const getOptions = (name) => {
  switch (name) {
    case AVAILABILITY_BUFFER:
      return getHoursOptions();

    case END_TIME:
    case START_TIME:
      return toursHoursOptions;

    case INCOME_MULTIPLIER:
      return getIncomeMultiplierOptions();

    case MAX_VISITORS:
      return getMaxVisitorOptions();

    case NUMBER_OF_SCHEDULING_DAYS:
      return getNumberOfDaysOutOptions();

    case POST_APPOINTMENT_BUFFER:
    case PRE_APPOINTMENT_BUFFER:
      return appointmentBufferHours;

    case TIMEZONE:
      return timeZoneOptions;

    case TOUR_DURATION:
      return tourDurationHours;

    default:
      return [];
  }
};

export const defaultTourHours = {
  endTime: '17:00:00',
  maxVisitors: 1,
  startTime: '09:00:00',
};

export const setInitialTourHours = (tourHours, isActive = null) => {
  const tourHoursMap = { ...tourHours };
  DAYS_OF_THE_WEEK.forEach((day, dayIndex) => {
    if (!tourHoursMap[day]) {
      tourHoursMap[day] = {
        ...defaultTourHours,
        isActive: isActive,
        weekday: dayIndex + 1,
      };
    }
  });
  return tourHoursMap;
};

export const getUpdateCount = (statusMap) =>
  Object.values(statusMap).reduce((count, preferences) => {
    count += Object.values(preferences).filter(
      (isModified) => isModified
    ).length;
    return count;
  }, 0);
