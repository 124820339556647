import { useEffect, useState } from 'react';
import * as schedulingApi from '../../schedulingApi';
import { ERROR_MESSAGES } from '../../constants';

export const getPropertySelectionMap = (propertiesList) => {
  const isSelected = false;
  return propertiesList.reduce((propertiesMap, property) => {
    propertiesMap[property.id] = {
      ...property,
      isSelected,
    };
    return propertiesMap;
  }, {});
};

export const getSelectedCount = (propertySelectionMap) =>
  Object.values(propertySelectionMap).filter((property) => property.isSelected)
    .length;

const usePropertyList = ({ setErrorMessage }) => {
  const [isFetchingPropertiesList, setIsFetchingPropertiesList] =
    useState(false);
  const [propertySelectionMap, setPropertySelectionMap] = useState({});

  useEffect(() => {
    const fetchPropertiesList = async () => {
      setIsFetchingPropertiesList(true);
      try {
        const propertiesList = await schedulingApi.getPropertyList();
        setPropertySelectionMap(getPropertySelectionMap(propertiesList));
        setIsFetchingPropertiesList(false);
      } catch (_e) {
        setIsFetchingPropertiesList(false);
        setErrorMessage(ERROR_MESSAGES.FETCH);
      }
    };

    fetchPropertiesList();
  }, []);

  return {
    isFetchingPropertiesList,
    propertySelectionMap,
    selectedPropertyCount: getSelectedCount(propertySelectionMap),
    setPropertySelectionMap,
  };
};

export default usePropertyList;
