import React, { Component } from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter } from '@knockrentals/knock-react';
import '../../_attributeInfo.scss';
import * as _ from 'lodash';
import { Formik } from 'formik';

class AddAttributeModal extends Component {
  state = {
    suggestions: _.keys(this.props.suggestions).sort(),
    isUseNullAttribute: false,
  };

  render() {
    return (
      <Modal className="attribute-modal">
        <Formik
          initialValues={{ attributeValue: this.props.isUseNullAttribute }}
          onSubmit={this.onConfirm.bind(this)}
        >
          {props => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <ModalHeader>
                <h2>Override Default Source Attribute</h2>
              </ModalHeader>
              <ModalContent>
                <div className="knock-react-flex">
                  <div className="field-label">Source</div>
                  <div className="field-input">
                    <i className="fa fa-building" /> {this.props.marketingSource.name}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div className="field-label">Default Attribute</div>
                  <div className="field-input">
                    {this.props.marketingSource.default_attribute ? (
                      <span>
                        <i className="fa fa-tags" /> {this.props.marketingSource.default_attribute}
                      </span>
                    ) : (
                      <span>
                        <i className="fa fa-tag" /> <em>None</em>
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div className="field-label">Override With</div>
                  <div className="field-input">
                    <input
                      name="attributeValue"
                      type="text"
                      onChange={props.handleChange}
                      value={props.values.attributeValue}
                      className="knock-react-input"
                      placeholder="Custom source attribute"
                      disabled={this.state.isUseNullAttribute}
                    />
                    <input
                      type="checkbox"
                      checked={this.state.isUseNullAttribute}
                      onChange={this.toggleIsUseNullAttribute.bind(this, props)}
                    />
                    No source attribute
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div className="field-label">Suggestions</div>
                  <div className="field-input">
                    {this.state.suggestions.length ? (
                      this.state.suggestions.map((suggestion, i) => {
                        return (
                          <a
                            key={`suggestion-${i}`}
                            className="mRight10"
                            onClick={this.loadSuggestion.bind(this, suggestion, props)}
                            href="#"
                          >
                            {suggestion}
                          </a>
                        );
                      })
                    ) : (
                      <em>None</em>
                    )}
                  </div>
                </div>
              </ModalContent>
              <ModalFooter>
                <button
                  className="btn-md btn-primary"
                  onClick={this.onCancel.bind(this)}
                  type="button"
                >
                  <i className="fa fa-lg fa-times-circle" /> Cancel
                </button>
                <button className="btn-md btn-success">
                  <i className="fa fa-lg fa-check-circle" /> Override Default Attribute
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  toggleIsUseNullAttribute(formProps) {
    const toggleToValue = !this.state.isUseNullAttribute;
    this.setState({ isUseNullAttribute: toggleToValue });
    formProps.setFieldValue('attributeValue', toggleToValue ? '_None_' : '');
  }

  loadSuggestion(suggestion, formProps) {
    if (!this.state.isUseNullAttribute) {
      formProps.setFieldValue('attributeValue', suggestion);
    }
  }

  onConfirm(values) {
    const attributeValue = this.state.isUseNullAttribute ? null : values.attributeValue;

    if (attributeValue !== '') {
      this.props.createAttribute(this.props.marketingSource.id, attributeValue);
    }
  }

  onCancel() {
    this.props.toggleModal(false);
  }
}

export default AddAttributeModal;
