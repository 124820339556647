import React from 'react';
import 'moment-timezone';
import 'moment-duration-format';
import { ToastContainer } from '@knockrentals/knock-react';
import { AdminRouter } from './Navigation/AdminRouter';
import { NavigationBar } from './Navigation/NavigationBar';
import { Notifications } from './Components/Notifications';
import { useAppContext } from './Context/AppContext';
import './App.scss';

const App = () => {
  const { isReady, isLoggedIn } = useAppContext();

  if (!isReady) {
    return null;
  }

  return (
    <div className="knock-admin-app">
      {isLoggedIn && (
        <div className="knock-admin-app-display-col knock-admin-app-navbar-col">
          <NavigationBar />
        </div>
      )}

      <div className="knock-admin-app-display-col knock-admin-app-content-col">
        <AdminRouter />
      </div>

      <ToastContainer />
      <Notifications />
    </div>
  );
};

export default App;
