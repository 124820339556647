import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tooltip } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  tooltip: {
    fontSize: '10px',
    lineHeight: '14px',
    margin: '0 auto',
    maxWidth: '122px',
    padding: '4px 8px',
    textAlign: 'center',
  },
});

const BrandContentTooltip = ({ children, title }) => {
  const classes = useStyles();
  return (
    <Tooltip arrow classes={classes} placement="right" title={title || ''}>
      <span>{children}</span>
    </Tooltip>
  );
};

BrandContentTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

export default BrandContentTooltip;
