import React, { Component } from 'react';
import {Modal, ModalHeader, ModalContent, ModalFooter, PageContainer, Toaster} from '@knockrentals/knock-react';

class UpchargeExplainerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  hideModal = () => {
    this.props.closeHandler();
  };

  render() {
    const { loading } = this.state.loading;
    if (this.state.loading) {
      return null;
    }

    return (
      <PageContainer isLoading={loading}>
        <Modal>
          <ModalHeader>
            <h2>
             What's This?
            </h2>
          </ModalHeader>
            <ModalContent>
                <div style={{ maxWidth: "475px", marginBottom: "15px" }}>
                    This setting determines how an upcharge will be calculated and displayed in a generated quote.
                    If selected, the upcharge amount will be <strong>added</strong> to the quote's base monthy rent amount,
                    and the <strong>Additional Fee Info</strong> section will display a message of "Lease term upcharge
                    (added to base monthly rent): $(upcharge amount)/mo".
                </div>
                <div>Example: Unit listed for $2000/mo, with $100 upcharge:</div>
                <div>Base Monthly Rent: $2000</div>
                <div style={{ marginBottom: "15px" }}>Total Monthly Rent: $2100</div>
                <div style={{ maxWidth: "475px", marginBottom: "15px" }}>
                    If this setting is deselected, the upcharge amount will <strong>not</strong> be added to the quote's total
                    monthly rent amount, but rather <strong>subtracted</strong> from the base monthly rent, and the <strong>Additional Fee Info</strong> section will display a message of
                    "Lease term upcharge (pre-included in total monthly rent): $(upcharge amount)/mo".
                </div>
                <div>Using the same example:</div>
                <div>Base Monthly Rent: $1900</div>
                <div>Total Monthly Rent: $2000</div>
            </ModalContent>
            <ModalFooter>
                <button
                    className="knock-react-button"
                    onClick={this.hideModal}
                >
                  OK!
                </button>
            </ModalFooter>
        </Modal>
      </PageContainer>
    );
  }
}

class EditUpchargeModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            upchargeAmount: this.props.selectedUpcharge,
        };
    }

    hideModal = () => {
        this.props.closeHandler();
      };

    onChange = (event) => {
        this.setState({upchargeAmount: event.target.value});
    };

    onSubmit = (event) => {
        event.preventDefault();
        if (!/^\d+$/.test(this.state.upchargeAmount)) {
            Toaster.showToast('Please enter a valid whole number for a dollar amount.', 2000, Toaster.ToastClasses.error);
        } else {
            this.props.submitHandler(parseInt(this.state.upchargeAmount, 10));
            this.hideModal();
        }
    }

    render() {
        const { loading } = this.state.loading;
        if (this.state.loading) {
          return null;
        }

        return (
          <PageContainer isLoading={loading}>
            <Modal>
              <ModalHeader>
                <h2>
                 Edit Upcharge Amount
                </h2>
              </ModalHeader>
                <form onSubmit={evt => this.onSubmit(evt)}>
                    <ModalContent>
                        <div>
                          <label htmlFor="upchargeAmount">
                            <strong>
                              Upcharge Amount
                            </strong>
                          </label>
                          <input className="knock-react-input"
                            style={{ marginLeft: "25px", marginRight: "15px" }}
                            defaultValue={this.props.selectedUpcharge}
                            type="text"
                            id="upchargeAmount"
                            maxLength="30"
                            required
                            onChange={evt => this.onChange(evt)}
                          />
                        <span className="input-append-text">USD / month</span>
                        </div>
                    </ModalContent>
                    <ModalFooter>
                        <button
                            id="cancelEditUpcharge"
                            className="knock-react-button"
                            onClick={this.hideModal}
                        >
                          Cancel
                        </button>
                        <button id="submitUpcharge" type="submit"
                            className="knock-react-button"
                        >
                          Save
                        </button>
                    </ModalFooter>
                </form>
            </Modal>
          </PageContainer>
    );
  }
}

export { UpchargeExplainerModal, EditUpchargeModal };