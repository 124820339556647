import { ACCESS_ROLES, ACCESS_SETTINGS } from '../../constants';

export const ACCESS_SETTING_CONFIG = {
  [ACCESS_SETTINGS.AD_SPEND_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN, ACCESS_ROLES.AGENT],
    label: 'Ad Spend Page',
  },
  [ACCESS_SETTINGS.ADMINS_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Admins Page',
  },
  [ACCESS_SETTINGS.AI_TEMPLATE_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Ai Template Page',
  },
  [ACCESS_SETTINGS.ATTRIBUTES_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Attributes Page',
  },
  [ACCESS_SETTINGS.BRAND_CONTENT_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Brand Content Page',
  },
  [ACCESS_SETTINGS.CROSS_SELL_TAB]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Cross Sell Tab',
  },
  [ACCESS_SETTINGS.DOORWAY_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Doorway Page',
  },
  [ACCESS_SETTINGS.DRIP_CAMPAIGN_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Drip Campaign Page',
  },
  [ACCESS_SETTINGS.INTEGRATIONS_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Integrations Page',
  },
  [ACCESS_SETTINGS.LMA_ACCESS]: {
    configurableRoles: [ACCESS_ROLES.ANALYTICS],
    label: 'LMA Access',
  },
  [ACCESS_SETTINGS.PHOTOS_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Photos Page',
  },
  [ACCESS_SETTINGS.PRICING_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Pricing Page',
  },
  [ACCESS_SETTINGS.PROPERTIES_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Properties Page',
  },
  [ACCESS_SETTINGS.QUICK_REPLIES_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Quick Replies Page',
  },
  [ACCESS_SETTINGS.REGISTRATION_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Registration Page',
  },
  [ACCESS_SETTINGS.REFRESH_SPREADSHEET_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Refresh Spread Sheet Page',
  },
  [ACCESS_SETTINGS.SCHEDULING_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Scheduling Page',
  },
  [ACCESS_SETTINGS.SETTINGS_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Settings Page',
  },
  [ACCESS_SETTINGS.SHEETS_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Sheets Page',
  },
  [ACCESS_SETTINGS.SOURCES_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Sources Page',
  },
  [ACCESS_SETTINGS.SOURCE_LIST_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Source List Page',
  },
  [ACCESS_SETTINGS.SSDA_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'SSDA Page',
  },
  [ACCESS_SETTINGS.SYNC_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Sync Page',
  },
  [ACCESS_SETTINGS.TEMPLATES_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Templates Page',
  },
  [ACCESS_SETTINGS.TOURS_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Knock Tours Page',
  },
  [ACCESS_SETTINGS.USERS_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Users Page',
  },
  [ACCESS_SETTINGS.VIRTUAL_AGENT_PAGE]: {
    configurableRoles: [ACCESS_ROLES.ADMIN],
    label: 'Virtual Agent Page',
  },
};

export const API_SETTINGS_MAP = {
  ad_spend_page: ACCESS_SETTINGS.AD_SPEND_PAGE,
  admins_page: ACCESS_SETTINGS.ADMINS_PAGE,
  ai_template_page: ACCESS_SETTINGS.AI_TEMPLATE_PAGE,
  attribute_page: ACCESS_SETTINGS.ATTRIBUTES_PAGE,
  brand_content_page: ACCESS_SETTINGS.BRAND_CONTENT_PAGE,
  cross_sell_tab: ACCESS_SETTINGS.CROSS_SELL_TAB,
  doorway_page: ACCESS_SETTINGS.DOORWAY_PAGE,
  drip_campaign_page: ACCESS_SETTINGS.DRIP_CAMPAIGN_PAGE,
  integrations_page: ACCESS_SETTINGS.INTEGRATIONS_PAGE,
  knock_tours_page: ACCESS_SETTINGS.TOURS_PAGE,
  lma_access: ACCESS_SETTINGS.LMA_ACCESS,
  photos_page: ACCESS_SETTINGS.PHOTOS_PAGE,
  pricing_page: ACCESS_SETTINGS.PRICING_PAGE,
  properties_page: ACCESS_SETTINGS.PROPERTIES_PAGE,
  quick_replies_page: ACCESS_SETTINGS.QUICK_REPLIES_PAGE,
  registration_page: ACCESS_SETTINGS.REGISTRATION_PAGE,
  refresh_spreadsheet_page: ACCESS_SETTINGS.REFRESH_SPREADSHEET_PAGE,
  scheduling_page: ACCESS_SETTINGS.SCHEDULING_PAGE,
  settings_page: ACCESS_SETTINGS.SETTINGS_PAGE,
  sheets_page: ACCESS_SETTINGS.SHEETS_PAGE,
  sources_page: ACCESS_SETTINGS.SOURCES_PAGE,
  source_list_page: ACCESS_SETTINGS.SOURCE_LIST_PAGE,
  ssda_page: ACCESS_SETTINGS.SSDA_PAGE,
  sync_page: ACCESS_SETTINGS.SYNC_PAGE,
  templates_page: ACCESS_SETTINGS.TEMPLATES_PAGE,
  users_page: ACCESS_SETTINGS.USERS_PAGE,
  virtual_agent_page: ACCESS_SETTINGS.VIRTUAL_AGENT_PAGE,
};

export const CONFIGURABLE_EXTERNAL_SETTINGS = {
  [ACCESS_SETTINGS.AD_SPEND_PAGE]: true,
  [ACCESS_SETTINGS.ATTRIBUTES_PAGE]: true,
};

export const SETTINGS_MAP = {
  [ACCESS_SETTINGS.AD_SPEND_PAGE]: 'ad_spend_page',
  [ACCESS_SETTINGS.ADMINS_PAGE]: 'admins_page',
  [ACCESS_SETTINGS.AI_TEMPLATE_PAGE]: 'ai_template_page',
  [ACCESS_SETTINGS.ATTRIBUTES_PAGE]: 'attribute_page',
  [ACCESS_SETTINGS.BRAND_CONTENT_PAGE]: 'brand_content_page',
  [ACCESS_SETTINGS.CROSS_SELL_TAB]: 'cross_sell_tab',
  [ACCESS_SETTINGS.DOORWAY_PAGE]: 'doorway_page',
  [ACCESS_SETTINGS.DRIP_CAMPAIGN_PAGE]: 'drip_campaign_page',
  [ACCESS_SETTINGS.INTEGRATIONS_PAGE]: 'integrations_page',
  [ACCESS_SETTINGS.TOURS_PAGE]: 'knock_tours_page',
  [ACCESS_SETTINGS.LMA_ACCESS]: 'lma_access',
  [ACCESS_SETTINGS.PHOTOS_PAGE]: 'photos_page',
  [ACCESS_SETTINGS.PRICING_PAGE]: 'pricing_page',
  [ACCESS_SETTINGS.PROPERTIES_PAGE]: 'properties_page',
  [ACCESS_SETTINGS.QUICK_REPLIES_PAGE]: 'quick_replies_page',
  [ACCESS_SETTINGS.REGISTRATION_PAGE]: 'registration_page',
  [ACCESS_SETTINGS.REFRESH_SPREADSHEET_PAGE]: 'refresh_spreadsheet_page',
  [ACCESS_SETTINGS.SCHEDULING_PAGE]: 'scheduling_page',
  [ACCESS_SETTINGS.SETTINGS_PAGE]: 'settings_page',
  [ACCESS_SETTINGS.SHEETS_PAGE]: 'sheets_page',
  [ACCESS_SETTINGS.SOURCES_PAGE]: 'sources_page',
  [ACCESS_SETTINGS.SOURCE_LIST_PAGE]: 'source_list_page',
  [ACCESS_SETTINGS.SSDA_PAGE]: 'ssda_page',
  [ACCESS_SETTINGS.SYNC_PAGE]: 'sync_page',
  [ACCESS_SETTINGS.TEMPLATES_PAGE]: 'templates_page',
  [ACCESS_SETTINGS.USERS_PAGE]: 'users_page',
  [ACCESS_SETTINGS.VIRTUAL_AGENT_PAGE]: 'virtual_agent_page',
};
