import React from 'react';
import PropTypes from 'prop-types';

import { LoadingOverlay } from '@knockrentals/knock-shared-web';

import { TEMPLATE_TABLE_CONTENT_TYPES } from '../../../constants';
import { ErrorDialog } from '../../../../common/ErrorDialog';
import TemplatePreview from '../../Template/TemplatePreview';
import { useActionTemplate } from './useActionTemplate';

const ActionTemplatePreview = ({ templateId }) => {
  const { errorMessage, isLoading, setErrorMessage, template } =
    useActionTemplate({
      templateId,
    });

  const { contentType = TEMPLATE_TABLE_CONTENT_TYPES.EMAIL, message = '', subject = '', templateName = '' } = template || {};

  return (
    <React.Fragment>
      <LoadingOverlay open={isLoading} />

      <ErrorDialog
        closeDialog={() => setErrorMessage('')}
        errorMessage={errorMessage}
        isOpen={Boolean(errorMessage)}
      />

      {!isLoading && (
        <TemplatePreview
          contentType={contentType}
          message={message}
          subject={subject}
          templateName={templateName}
        />
      )}
    </React.Fragment>
  );
};

ActionTemplatePreview.propTypes = {
  templateId: PropTypes.number,
};

export default ActionTemplatePreview;
