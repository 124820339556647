export const getTableOptions = (pageSize, pageSizeOptions) => ({
  actionsCellStyle: {
    width: '40px',
  },
  actionsColumnIndex: -1,
  detailPanel: true,
  draggable: false,
  emptyRowsWhenPaging: false,
  grouping: false,
  headerStyle: {
    fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
    lineHeight: '24px',
    padding: '16px',
    fontSize: '14px',
    fontWeight: 600,
  },
  padding: 'dense',
  paging: true,
  pageSize,
  pageSizeOptions,
  rowStyle: {
    minWidth: '1096px',
  },
  search: false,
  showFirstLastPageButtons: false,
  sorting: false,
  toolbar: false,
});
