import { useState, useRef } from 'react';
import { companyQuickRepliesApi } from '../../CompanyQuickRepliesApi';

export const DEFAULT_ROWS_PER_PAGE = 50;
// TODO - update PAGE_SIZES_OPTIONS with product/UX recommendations
export const ROWS_PER_PAGE_OPTIONS = [25, 50, 100, 500];

const useQuickReplyGrid = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [quickRepliesByPage, setQuickRepliesByPage] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const tableDetailsRef = useRef({
    pageIndex: 0,
    shouldLoadPageIndex: false,
    sortColumn: 'replyTitle',
    sortDirection: 'asc',
    totalQuickRepliesCount: 0,
  });

  const getPageIndex = () => tableDetailsRef.current.pageIndex;
  const setPageIndex = (index) => {
    tableDetailsRef.current.pageIndex = index;
  };

  const getShouldLoadPageIndex = () =>
    tableDetailsRef.current.shouldLoadPageIndex;
  const setShouldLoadPageIndex = (shouldLoad) => {
    tableDetailsRef.current.shouldLoadPageIndex = shouldLoad;
  };

  const getSortColumn = () => tableDetailsRef.current.sortColumn;
  const getSortDirection = () => tableDetailsRef.current.sortDirection;

  const getTotalQuickRepliesCount = () =>
    tableDetailsRef.current.totalQuickRepliesCount;
  const setTotalQuickRepliesCount = (count) => {
    tableDetailsRef.current.totalQuickRepliesCount = count;
  };

  const tableRef = useRef();
  const reloadTableData = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const resetTableData = () => {
    setQuickRepliesByPage({});
    reloadTableData();
  };

  const getPage = (query) => {
    if (getShouldLoadPageIndex()) {
      setShouldLoadPageIndex(false);
      return getPageIndex();
    }
    return query.page;
  };

  const shouldFetchRequestedPage = (page) => {
    const nextPage = quickRepliesByPage[page] || [];
    return !nextPage.length;
  };

  const fetchTableData = async (query) => {
    const { page, pageSize } = query;

    const queryParams = {
      page,
      pageSize,
      sortColumn: getSortColumn(),
      sortDirection: getSortDirection(),
    };

    try {
      const responseData = await companyQuickRepliesApi.getCompanyQuickReplies(
        queryParams
      );

      const { quickReplies, quickRepliesCount } = responseData;
      setTotalQuickRepliesCount(quickRepliesCount);
      setQuickRepliesByPage((prevState) => ({
        ...prevState,
        [page]: quickReplies,
      }));

      return quickReplies;
    } catch (error) {
      setErrorMessage(error.message);
      return [];
    }
  };

  const getTableData = (query) =>
    new Promise(async (resolve) => {
      const { pageSize } = query;
      const page = getPage(query);
      const hasRowsPerPageChanged = pageSize !== rowsPerPage;

      if (hasRowsPerPageChanged) {
        setRowsPerPage(pageSize);
        setQuickRepliesByPage({});
      }

      const quickReplies =
        hasRowsPerPageChanged || shouldFetchRequestedPage(page)
          ? await fetchTableData(query)
          : quickRepliesByPage[page];

      resolve({
        data: quickReplies.map((reply) => ({ ...reply })),
        page: page,
        totalCount: getTotalQuickRepliesCount(),
      });
    });

  return {
    errorMessage,
    getPageIndex,
    getTableData,
    quickRepliesByPage,
    resetTableData,
    rowsPerPage,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    setErrorMessage,
    setPageIndex,
    setQuickRepliesByPage,
    setRowsPerPage,
    tableRef,
  };
};

export default useQuickReplyGrid;
