import React, { useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  CloseIcon,
  Text,
  TextField,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import { ErrorAlert } from '../ErrorAlert';
import { ERROR_MESSAGES } from '../constants';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },

  dialogContent: {
    height: '300px',
  },

  dialogActions: {
    padding: '16px',
  },

  // These styles are needed to correct the icon sizes in the input field since they are getting reduced somehow within this project
  endAdornment: {
    '& .MuiAutocomplete-endAdornment': {
      '& .MuiSvgIcon-fontSizeSmall': {
        fontSize: '20px',
      },

      '& .MuiAutocomplete-popupIndicator': {
        '& .MuiSvgIcon-root': {
          fontSize: '24px',
        },
      },
    },
  },
}));

const AddCompanySourceView = ({
  buttonText,
  errorMessage,
  setErrorMessage,
  sources,
  sourceMap,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();
  const [currentSource, setCurrentSource] = useState(null);
  const [hasError, setHasError] = useState(false);

  const handleSubmit = () => {
    if (currentSource) {
      onSubmit(currentSource);
    } else {
      setHasError(true);
    }
  };

  const handleSourceChange = (event, value) => {
    setCurrentSource(value);

    if (value) {
      setHasError(false);
    }
  };

  const handleOnFocus = () => {
    if (errorMessage) {
      setErrorMessage();
    }
  };

  const hasSources = sources && sources.length > 0;

  return (
    <React.Fragment>
      <DialogTitle disableTypography={true}>
        <Text variant="h6">{buttonText}</Text>

        <IconButton
          aria-label="close the popup"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers={true} className={classes.dialogContent}>
        <Box>
          <ErrorAlert
            alertMessage={errorMessage}
            data-testid="add-source-error"
            isVisible={errorMessage === ERROR_MESSAGES.ADD_SOURCE}
          />

          {hasSources && (
            <Autocomplete
              options={sources || []}
              getOptionLabel={(option) => option.label}
              onChange={handleSourceChange}
              onFocus={handleOnFocus}
              getOptionDisabled={(option) => Boolean(sourceMap[option.id])}
              data-testid="SelectSource"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Source"
                  className={classes.endAdornment}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={hasError}
                  helperText={hasError ? 'Please select a company source' : ''}
                />
              )}
            />
          )}

          <ErrorAlert
            alertMessage={errorMessage}
            data-testid="all-sources-error"
            isVisible={errorMessage === ERROR_MESSAGES.ALL_SOURCES}
          />
        </Box>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button disabled={!hasSources} onClick={handleSubmit}>
          {buttonText}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default AddCompanySourceView;
