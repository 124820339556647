import { apiRemote } from '../../../../Services/RemoteService';
import { QUICK_REPLY } from '../constants';
import {
  normalizeQuickReplyData,
  getNormalizeAllQuickRepliesData,
  getTransformedPayload,
} from './utils';

export const ERROR_MESSAGES = {
  EDIT: 'We are unable to edit the requested company quick reply at this time.',
  MANAGE: 'We are unable to manage company quick replies at this time.',
  REMOVE:
    'We are unable to remove the requested company quick reply at this time.',
  SAVE: 'We are unable to save the company quick reply at this time.',
  UPDATE: 'We are unable to update the company quick reply at this time.',
};

export const createCompanyQuickReply = async (quickReply) => {
  const payload = {
    message: quickReply[QUICK_REPLY.EMAIL],
    message_sms: quickReply[QUICK_REPLY.SMS],
    title: quickReply[QUICK_REPLY.TITLE],
  };

  try {
    const response = await apiRemote.post(
      'admin/company/quick-replies',
      payload
    );

    if (!response.ok || response.status !== 201) {
      throw new Error();
    }
    const responseData = await response.json();
    return responseData.quick_reply_id;
  } catch (error) {
    throw new Error(ERROR_MESSAGES.SAVE);
  }
};

export const deleteCompanyQuickReply = async (quickReplyId) => {
  try {
    const response = await apiRemote.delete(
      `admin/company/quick-replies/${quickReplyId}`
    );

    if (!response.ok || response.status !== 204) {
      throw new Error();
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.REMOVE);
  }
};

export const getCompanyQuickReply = async (quickReplyId) => {
  try {
    const response = await apiRemote.get(
      `admin/company/quick-replies/${quickReplyId}`
    );

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const data = await response.json();
    return normalizeQuickReplyData(data);
  } catch (error) {
    throw new Error(ERROR_MESSAGES.EDIT);
  }
};

export const sortColumnMap = {
  replyTitle: 'title',
};

const getQueryString = (query) => {
  const { page, pageSize, sortColumn, sortDirection } = query;
  const offSet = page * pageSize;
  return `?offset=${offSet}&page_size=${pageSize}&sort_column=${sortColumnMap[sortColumn]}&sort_direction=${sortDirection}`;
};

export const getCompanyQuickReplies = async (queryParams) => {
  const queryString = getQueryString(queryParams);
  try {
    const response = await apiRemote.get(
      `admin/company/quick-replies${queryString}`
    );

    if (!response.ok || response.status !== 200) {
      throw new Error();
    }

    const responseData = await response.json();
    return getNormalizeAllQuickRepliesData(responseData);
  } catch (error) {
    throw new Error(ERROR_MESSAGES.MANAGE);
  }
};

export const updateCompanyQuickReply = async (id, payload) => {
  const { quickReply, properties } = getTransformedPayload(payload);

  try {
    if (quickReply) {
      const response = await apiRemote.patch(
        `admin/company/quick-replies/${id}`,
        quickReply
      );

      if (!response.ok || response.status !== 204) {
        throw new Error();
      }
    }
    if (properties) {
      const response = await apiRemote.put(
        `admin/company/quick-replies/${id}/properties`,
        { properties }
      );

      if (
        !response.ok ||
        (response.status !== 201 && response.status !== 204)
      ) {
        throw new Error();
      }
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.UPDATE);
  }
};
