import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  LoadingOverlay,
  Button,
  Text,
  CircularProgress,
} from '@knockrentals/knock-shared-web';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Toaster } from '@knockrentals/knock-react';

import * as VirtualAgentAPI from '../VirtualAgentAPI';
import VirtualAgentSetupChatResponsesModal from './VirtualAgentSetupChatResponsesModal';

const useStyles = makeStyles(() => ({
  boxTitle: {
    margin: '20px 0 5px',
  },
  boxMargin: {
    margin: '20px 0',
  },
  mBottom10: {
    marginBottom: '10px',
  },
}));

const PENDING = 'pending';

const VirtualAgentSetupChatResponses = ({ companyId }) => {
  const classes = useStyles();
  const [copyDestination, setCopyDestination] = useState('chat');
  const [isLoading, setIsLoading] = useState(false);
  const [voiceResponsesCopied, setVoiceResponsesCopied] = useState(null);
  const [voiceResponseCopiedSMS, setVoiceResponsesCopiedSMS] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [copyVoicesModalIsOpen, setCopyVoicesModalIsOpen] = useState(false);

  const handleCopyVoiceConfirmation = async (propertyIds, destOverride) => {
    setCopyVoicesModalIsOpen(false);
    destOverride ? setVoiceResponsesCopiedSMS(PENDING) : setVoiceResponsesCopied(PENDING);

    try {
      await VirtualAgentAPI.copyVoiceResponsesToChatV2(propertyIds, destOverride);
      Toaster.showToast(
        'Responses copied successfully',
        2000,
        Toaster.ToastClasses.success,
      );
      setVoiceResponsesCopied(null);
      setVoiceResponsesCopiedSMS(null);
    } catch (e) {
      Toaster.showToast(
        'An error occurred while copying the responses.',
        2000,
        Toaster.ToastClasses.error,
      );
      setVoiceResponsesCopied(null);
      setVoiceResponsesCopiedSMS(null);
    }
  };

  const isWaitingCopyVoiceConfirmation = voiceResponsesCopied === PENDING;
  const isWaitingCopyVoiceConfirmationSMS = voiceResponseCopiedSMS === PENDING;

  return (
    <section>
      <LoadingOverlay open={isLoading} />

      <Text variant="h6" className={classes.boxTitle}>
        Chat
      </Text>
      <Text variant="body1" className={classes.mBottom10}>
        Copy all voice responses to chat, this will overwrite all your existing
        responses
      </Text>

      <Button
        variant="outlined"
        size="large"
        onClick={() => {
          setCopyDestination('chat');
          setCopyVoicesModalIsOpen(true);
        }}        startIcon={<FileCopyOutlinedIcon />}
        endIcon={
          isWaitingCopyVoiceConfirmation ? <CircularProgress size="20px" /> : ''
        }
        disabled={isWaitingCopyVoiceConfirmation}
      >
        Copy
      </Button>

      <Text variant="h6" className={classes.boxTitle}>
        SMS
      </Text>
      <Text variant="body1" className={classes.mBottom10}>
        Copy all voice responses to SMS, this will overwrite all your existing
        responses
      </Text>

      <Button
        variant="outlined"
        size="large"
        onClick={() => {
          setCopyDestination('sms');
          setCopyVoicesModalIsOpen(true);
        }}
        startIcon={<FileCopyOutlinedIcon />}
        endIcon={
          isWaitingCopyVoiceConfirmationSMS ? <CircularProgress size="20px" /> : ''
        }
        disabled={isWaitingCopyVoiceConfirmationSMS}
      >
        Copy
      </Button>

      <VirtualAgentSetupChatResponsesModal
        companyId={companyId}
        copyDestination={copyDestination}
        isOpen={copyVoicesModalIsOpen}
        closeDialog={() => setCopyVoicesModalIsOpen(false)}
        handleConfirmation={handleCopyVoiceConfirmation}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
      />
    </section>
  );
};

VirtualAgentSetupChatResponses.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default VirtualAgentSetupChatResponses;
