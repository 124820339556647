import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Column } from '../Grid';

const DeveloperHeaderRow = ({ developers }) => {
  const columnStyles = {
    paddingBottom: 0,
  };
  return (
    <Row header rotated>
      <Column style={columnStyles}>Property</Column>
      {developers.map(({ id, name }, index) => (
        <Column
          heading
          rotated
          title={name}
          key={`DeveloperHeaderRow-Column-${id}-${index}`}
          style={columnStyles}
        >
          {name}
        </Column>
      ))}
    </Row>
  );
};

DeveloperHeaderRow.propTypes = {
  developers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

const FilterHeaderRow = props => {
  const {
    developers,
    onSubscribeAllChange,
    disableSubscribeAll,
    isSubscribedToAllCommunities,
    onCommunityChange,
    selectedCommunityId,
    communityOptions,
  } = props;
  const TOP_OFFSET = '95px';
  const columnStyles = {
    paddingBottom: '15px',
  };

  return (
    <Row header border top={TOP_OFFSET}>
      <Column style={columnStyles}>
        <select value={selectedCommunityId} onChange={onCommunityChange}>
          {communityOptions}
        </select>
      </Column>
      {developers.map(({ id, name }, index) => (
        <Column
          heading
          title={name}
          style={columnStyles}
          key={`FilterHeaderRow-Column-${id}-${index}`}
        >
          <input
            type="checkbox"
            disabled={disableSubscribeAll}
            checked={isSubscribedToAllCommunities({ id, name })}
            onChange={onSubscribeAllChange({ id, name })}
          />
        </Column>
      ))}
    </Row>
  );
};

FilterHeaderRow.propTypes = {
  developers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  onSubscribeAllChange: PropTypes.func,
  disableSubscribeAll: PropTypes.bool,
  isSubscribedToAllCommunities: PropTypes.func,
  onCommunityChange: PropTypes.func,
  selectedCommunityId: PropTypes.string,
  communityOptions: PropTypes.arrayOf(PropTypes.element),
};

export default class IlsSubscriptionTable extends Component {
  static propTypes = {
    communities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    developers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    subscriptions: PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string,
        communityIds: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    onSubscriptionChange: PropTypes.func,
    onSubscribeAllChange: PropTypes.func,
  };

  static SHOW_ALL_COMMUNITIES = 'SHOW_ALL_COMMUNITIES';

  state = {
    selectedCommunityId: IlsSubscriptionTable.SHOW_ALL_COMMUNITIES,
  };

  getFilteredCommunities = () => {
    const { selectedCommunityId } = this.state;
    if (selectedCommunityId === IlsSubscriptionTable.SHOW_ALL_COMMUNITIES) {
      return this.props.communities;
    }
    return this.props.communities.filter(({ id }) => id === selectedCommunityId);
  };

  isSubscribedToAllCommunities = developer => {
    const { communities, subscriptions } = this.props;
    const developerSubscriptions = subscriptions[developer.id];
    return communities.every(({ id }) => developerSubscriptions.communityIds.includes(id));
  };

  onCommunityChange = event => {
    this.setState({
      selectedCommunityId: event.target.value,
    });
  };

  getCommunityOptions = () => {
    return this.props.communities.reduce(
      (options, { id, name }) => {
        options.push(
          <option value={id} key={`IlsSubscriptionTable-getCommunityOptions-${id}`}>
            {name}
          </option>
        );
        return options;
      },
      [
        <option
          value={IlsSubscriptionTable.SHOW_ALL_COMMUNITIES}
          key={`IlsSubscriptionTable-getCommunityOptions-${IlsSubscriptionTable.SHOW_ALL_COMMUNITIES}`}
        >
          All
        </option>,
      ]
    );
  };

  disableSubscribeAll = () => {
    return this.state.selectedCommunityId !== IlsSubscriptionTable.SHOW_ALL_COMMUNITIES;
  };

  render() {
    const { developers, subscriptions, onSubscriptionChange, onSubscribeAllChange } = this.props;

    const filteredCommunities = this.getFilteredCommunities();

    return (
      <Grid>
        <DeveloperHeaderRow developers={developers} />
        <FilterHeaderRow
          developers={developers}
          onSubscribeAllChange={onSubscribeAllChange}
          disableSubscribeAll={this.disableSubscribeAll()}
          isSubscribedToAllCommunities={this.isSubscribedToAllCommunities}
          communityOptions={this.getCommunityOptions()}
          onCommunityChange={this.onCommunityChange}
        />
        {filteredCommunities.map((community, communityIndex, communityArray) => {
          const { id: communityId, name: communityName } = community;
          return (
            <Row
              key={`IlsSubscriptionTable-Row-${communityId}-${communityIndex}`}
              title={communityName}
              style={{
                marginBottom: communityIndex === communityArray.length - 1 ? '20px' : undefined,
              }}
            >
              <Column>{communityName}</Column>
              {developers.map((developer, developerIndex) => {
                const { id: developerId } = developer;
                const subscription = subscriptions[developerId];
                const { communityIds } = subscription;
                const checked = communityIds.includes(communityId);
                return (
                  <Column
                    key={`IlsSubscriptionTable-Column-subscription-${developerId}-${developerIndex}`}
                  >
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={onSubscriptionChange(community, developer)}
                    />
                  </Column>
                );
              })}
            </Row>
          );
        })}
      </Grid>
    );
  }
}
