class NotificationService {
  notificationHandlers = [];

  customNotify = (notificationConfig) => {
    this.handleNotification(notificationConfig);
  };

  handleNotification = (notificationConfig) => {
    this.notificationHandlers.forEach((notificationHandler) => {
      notificationHandler(notificationConfig);
    });
  };

  notify = (message) => {
    this.handleNotification({ message });
  };

  setNotificationHandler = (notificationHandler) => {
    this.notificationHandlers.push(notificationHandler);
  };
}

const NewNotificationService = new NotificationService();

export default NewNotificationService;
