export const DEFAULT_TEXT_COLORS = { BLACK: '#000000', WHITE: '#ffffff' };

export const ALIGNMENT = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
};

export const DEFAULT_HEADER_ALIGNMENT = ALIGNMENT.CENTER;

export const CONFIRMATION_MODES = {
  SAVE: 'save',
};

export const CONFIRMATION_BUTTON_TEXT = {
  [CONFIRMATION_MODES.SAVE]: 'Yes, Save',
};
export const CONFIRMATION_SECONDARY_BUTTON_TEXT = {
  [CONFIRMATION_MODES.SAVE]: 'No, Do not Save',
};
export const CONFIRMATION_TITLES = {
  [CONFIRMATION_MODES.SAVE]: 'Save Settings',
};
