import { DEFAULT_HEADER_ALIGNMENT } from '../constants';

const getPropertyMessage = (count) =>
  count > 1 ? `all ${count} selected properties` : `the selected property`;

const getBrandingMessage = (isHeaderUpdating, isFooterUpdating) => {
  const headerMessage = isHeaderUpdating ? 'Header' : '';
  const footerMessage = isFooterUpdating ? 'Footer' : '';
  return `${headerMessage}${headerMessage && ' and '}${footerMessage}`;
};

export const getConfirmationSaveMessage = (messageConfig = {}) => {
  const { isFooterUpdating, isHeaderUpdating, propertyCount } = messageConfig;

  return `Saving will update ${getPropertyMessage(
    propertyCount
  )} to display new settings in the ${getBrandingMessage(
    isHeaderUpdating,
    isFooterUpdating
  )}. Do you want to save?`;
};

export const getFeatureCardSubheader = (propertyList) =>
  propertyList.length > 1
    ? `${propertyList.length} Properties`
    : propertyList[0].propertyName;

export const setBrandContentDefaults = (brandContent = {}) => {
  const {
    headerAlignment,
    isFooterEnabled,
    isFooterEnabledIndeterminate,
    isHeaderAlignmentMixed,
    isHeaderEnabled,
    isHeaderEnabledIndeterminate,
  } = brandContent;
  const updatedBrandContent = { ...brandContent };

  if (!headerAlignment && !isHeaderAlignmentMixed) {
    updatedBrandContent.headerAlignment = DEFAULT_HEADER_ALIGNMENT;
  }

  if (!isHeaderEnabled && !isHeaderEnabledIndeterminate) {
    updatedBrandContent.isHeaderEnabled = false;
  }

  if (!isFooterEnabled && !isFooterEnabledIndeterminate) {
    updatedBrandContent.isFooterEnabled = false;
  }
  return updatedBrandContent;
};

export const getHasBrandContentChanged = (currentValues, originalValues) => {
  return [
    'brandColor',
    'footerContent',
    'headerAlignment',
    'isFooterEnabled',
    'isHeaderEnabled',
  ].some((field) => currentValues[field] !== originalValues[field]);
};

export const getBrandContentChanges = (currentValues, originalValues) =>
  [
    'brandColor',
    'footerContent',
    'headerAlignment',
    'isFooterEnabled',
    'isHeaderEnabled',
  ].reduce((changes, field) => {
    const currentValue = currentValues[field];
    if (currentValue !== originalValues[field]) {
      changes[field] = currentValue;
    }
    return changes;
  }, {});
