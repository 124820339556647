import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import DataTable from 'material-table';
import { useFeatureFlagContext } from '../../../Context/FeatureFlagContext';

import { Toaster } from '@knockrentals/knock-react';
import {
  LoadingOverlay,
  Select,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import HelpOutline from '@material-ui/icons/HelpOutline';
import PropertiesAPI from '../../Properties/PropertiesAPI';
import * as VirtualAgentAPI from '../VirtualAgentAPI';
import { getChatRollovers, getOfferTimes } from '../VirtualAgentLookupTables';
import {
  editAiEmailEnabled,
  editGenAiEmailEnabled,
  getIsAiEmailEnabled,
} from '../aiEmailAPI';

const useStyles = makeStyles(() => ({
  switchButton: {
    paddingBottom: '7px',
    paddingTop: '7px',
  },
  verificationToolTip: {
    verticalAlign: 'middle',
    marginLeft: '3px',
  },
}));

const VirtualAgentConfig = ({ companyId }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState([]);

  const { isAiEmailGenAiAdminEnabled, isCallIntelStandaloneEnabled } =
    useFeatureFlagContext();

  const tableOptions = {
    draggable: false,
    grouping: false,
    headerStyle: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '32px',
      position: 'sticky',
    },
    maxBodyHeight: '100vh',
    padding: 'dense',
    paging: false,
    rowStyle: {
      fontSize: '14px',
      fontWeight: 400,
    },
    search: false,
    sorting: false,
    toolbar: false,
  };

  useEffect(() => {
    const getVirtualAgents = async () => {
      const virtualAgentResponse = await VirtualAgentAPI.getVirtualAgents(
        companyId,
        1,
        1000
      );
      const virtualAgentData = {};

      virtualAgentResponse.results.forEach((virtualAgent) => {
        virtualAgentData[virtualAgent.property_id] = virtualAgent;
      });

      return virtualAgentData;
    };

    const getProperties = async () => {
      const propertiesResponse = await PropertiesAPI.getCommunityInfo();
      const propertyIds = [];

      const propertyData = propertiesResponse.properties.map((property) => {
        propertyIds.push(property.id);

        return {
          propertyId: property.id,
          propertyName: property.data.location.name,
          virtualAgentId: 0,
          isChatEnabled: false,
          ...(!isCallIntelStandaloneEnabled
            ? {
                isTranscribed: false,
                isScored: false,
              }
            : {}),
          callRouting: 'OFF',
          offerTime: 0,
          voiceId: 0,
          voiceRate: 0,
          hasVirtualAgent: false,
          isGenAiVoiceEnabled: false,
          isGenAiChatEnabled: false,
          isGenAiSmsEnabled: false,
          residentVirtualAgentVoice: false,
          residentVirtualAgentSms: false,
        };
      });

      const aiEmailEnabledFlags = {};
      const aiEmailEnabledPropertyList = await getIsAiEmailEnabled(propertyIds);
      aiEmailEnabledPropertyList.forEach((item) => {
        aiEmailEnabledFlags[item.property_id] = {
          ai_email_enabled: item.ai_email_enabled,
          gen_ai_email_enabled: item.gen_ai_enabled,
        };
      });

      const virtualAgentData = await getVirtualAgents();

      propertyData.forEach((property) => {
        const virtualAgent = virtualAgentData[property.propertyId];
        if (virtualAgent) {
          property.virtualAgentId = virtualAgent.id;
          property.isChatEnabled = virtualAgent.is_chat_enabled;
          property.callRouting = virtualAgent.call_routing;
          property.offerTime = virtualAgent.offer_time;
          property.voiceId =
            virtualAgent.voice_id === null ? 0 : virtualAgent.voice_id;
          property.chatRollover = virtualAgent.chat_rollover || 'NONE';
          property.voiceRate = virtualAgent.voice_rate;
          property.hasVirtualAgent = true;
          property.answerVerification = virtualAgent.is_answer_verification;
          property.isSmsEnabled = virtualAgent.is_sms_enabled;
          property.rpccAgentRollover = virtualAgent.rpcc_agent_rollover;
          property.isGenAiVoiceEnabled = virtualAgent.is_gen_ai_voice_enabled;
          property.isGenAiChatEnabled = virtualAgent.is_gen_ai_chat_enabled;
          property.isGenAiSmsEnabled = virtualAgent.is_gen_ai_sms_enabled;
          property.scheduleTourVaEnabled =
            virtualAgent.schedule_tour_va_enabled;
          property.pnaVaEnabled = virtualAgent.pna_va_enabled;

          if (!isCallIntelStandaloneEnabled) {
            property.isTranscribed = virtualAgent.is_transcribed;
            property.isScored = virtualAgent.is_scored;
          }

          property.residentVirtualAgentVoice =
            virtualAgent.resident_virtual_agent_voice;
          property.residentVirtualAgentSms =
            virtualAgent.resident_virtual_agent_sms;
        }
        property.aiEmailEnabled =
          aiEmailEnabledFlags[property.propertyId].ai_email_enabled;
        property.aiEmailGenAiAdminEnabled =
          aiEmailEnabledFlags[property.propertyId].gen_ai_email_enabled;
      });

      propertyData.sort((lValue, rValue) =>
        lValue.propertyName.localeCompare(rValue.propertyName)
      );

      setProperties(propertyData);
    };

    if (properties.length === 0) {
      setIsLoading(true);

      getProperties()
        .catch(() => {
          Toaster.showToast(
            'Error fetching virtual agent data',
            2000,
            Toaster.ToastClasses.error
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const handleAiEmailEnabledChange = async (value, propertyId, companyId) => {
    const newPropertyData = [...properties];

    const index = newPropertyData.findIndex((item) => {
      return item.propertyId === propertyId;
    });

    try {
      const { aiEmailEnabled, aiEmailGenAiAdminEnabled } =
        await editAiEmailEnabled([
          {
            property_id: propertyId,
            company_id: companyId,
            ai_email_enabled: value,
          },
        ]);

      newPropertyData[index].aiEmailEnabled = aiEmailEnabled;
      newPropertyData[index].aiEmailGenAiAdminEnabled =
        aiEmailGenAiAdminEnabled;

      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      setProperties(newPropertyData);
    } catch (error) {
      Toaster.showToast(
        'Error saving virtual agent settings',
        2000,
        Toaster.ToastClasses.error
      );
    }
  };

  const handleGenAiEmailEnabledChange = async (
    value,
    propertyId,
    companyId
  ) => {
    const newPropertyData = [...properties];

    const index = newPropertyData.findIndex((item) => {
      return item.propertyId === propertyId;
    });

    try {
      const aiEmailGenAiAdminEnabled = await editGenAiEmailEnabled([
        {
          property_id: propertyId,
          company_id: companyId,
          gen_ai_enabled: value,
        },
      ]);

      newPropertyData[index].aiEmailGenAiAdminEnabled =
        aiEmailGenAiAdminEnabled;

      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      setProperties(newPropertyData);
    } catch (error) {
      Toaster.showToast(
        'Error saving virtual agent settings',
        2000,
        Toaster.ToastClasses.error
      );
    }
  };

  const saveData = async (field, newValue, propertyId) => {
    // This prevents the user from un-selecting a call routing value, making them act more like a set of radio buttons
    if (field === 'callRouting' && newValue === null) {
      return;
    }

    const newPropertyData = [...properties];
    const index = newPropertyData.findIndex((item) => {
      return item.propertyId === propertyId;
    });

    newPropertyData[index][field] = newValue;

    try {
      if (!newPropertyData[index].hasVirtualAgent) {
        const voiceId =
          field === 'voiceId' ? newPropertyData[index].voiceId : null;

        const data = await VirtualAgentAPI.createVirtualAgent({
          companyId,
          propertyId,
          isChatEnabled: newPropertyData[index].isChatEnabled,
          ...(!isCallIntelStandaloneEnabled
            ? {
                isTranscribed: newPropertyData[index].isTranscribed,
                isScored: newPropertyData[index].isScored,
              }
            : {}),
          callRouting: newPropertyData[index].callRouting,
          offerTime: newPropertyData[index].offerTime,
          voiceId,
          voiceRate: newPropertyData[index].voiceRate,
          is_answer_verification: newPropertyData[index].is_answer_verification,
          isSmsEnabled: newPropertyData[index].isSmsEnabled,
          rpccAgentRollover: newPropertyData[index].rpccAgentRollover,
          isGenAiVoiceEnabled: newPropertyData[index].isGenAiVoiceEnabled,
          isGenAiChatEnabled: newPropertyData[index].isGenAiChatEnabled,
          isGenAiSmsEnabled: newPropertyData[index].isGenAiSmsEnabled,
          residentVirtualAgentVoice:
            newPropertyData[index].residentVirtualAgentVoice,
          residentVirtualAgentSms:
            newPropertyData[index].residentVirtualAgentSms,
        });

        newPropertyData[index].virtualAgentId = data.id;
        newPropertyData[index].hasVirtualAgent = true;
      } else {
        const payload = {
          virtualAgentId: newPropertyData[index].virtualAgentId,
          propertyId,
          field,
          value: newValue,
        };

        await VirtualAgentAPI.updateVirtualAgent(payload);
      }

      Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      setProperties(newPropertyData);
    } catch (error) {
      Toaster.showToast(
        'Error saving virtual agent settings',
        2000,
        Toaster.ToastClasses.error
      );
    }
  };

  const getAnswerVerificationHeader = () => {
    return (
      <React.Fragment>
        Answer Verification
        <Tooltip title="If enabled, site staff will hear a whisper when answering the phone to press 1 to accept the call.">
          <HelpOutline
            className={classes.verificationToolTip}
            fontSize="small"
          />
        </Tooltip>
      </React.Fragment>
    );
  };

  const getColumns = () => {
    const tableArray = [
      ['Property', 'propertyName', undefined, '300px', undefined],
      [
        'AI Knockbot (Chat)',
        'isChatEnabled',
        'boolean',
        '150px',
        (rowData) => {
          return (
            <Switch
              color="primary"
              checked={rowData.isChatEnabled}
              onChange={(event) =>
                saveData(
                  'isChatEnabled',
                  event.target.checked,
                  rowData.propertyId
                )
              }
            />
          );
        },
      ],
      [
        'AI Email',
        'aiEmailEnabled',
        'string',
        '200px',
        (rowData) => {
          return (
            <ToggleButtonGroup
              value={rowData.aiEmailEnabled}
              exclusive
              onChange={(event, value) => {
                handleAiEmailEnabledChange(
                  value,
                  rowData.propertyId,
                  companyId
                );
              }}
              aria-label="Options"
            >
              <ToggleButton value="OFF" className={classes.switchButton}>
                Off
              </ToggleButton>
              <ToggleButton value="SETUP" className={classes.switchButton}>
                Setup
              </ToggleButton>
              <ToggleButton
                value="ON"
                className={classes.switchButton}
                disabled={rowData.aiEmailEnabled === 'OFF'}
              >
                On
              </ToggleButton>
            </ToggleButtonGroup>
          );
        },
      ],
      [
        'GenAI Chat',
        'isGenAiChatEnabled',
        'boolean',
        '100px',
        (rowData) => {
          return (
            <Switch
              color="primary"
              name="is-gen-ai-chat-enabled"
              checked={rowData.isGenAiChatEnabled}
              disabled={!rowData.isChatEnabled}
              onChange={(event) =>
                saveData(
                  'isGenAiChatEnabled',
                  event.target.checked,
                  rowData.propertyId
                )
              }
            />
          );
        },
      ],
      [
        'Chat Rollover',
        'chatRollover',
        'numeric',
        '150px',
        (rowData) => {
          return (
            <Select
              options={getChatRollovers()}
              name="chat rollover"
              aria-label="Select an option"
              defaultValue={rowData.chatRollover || 'NONE'}
              disabled={!rowData.isChatEnabled}
              fullWidth={true}
              noForm={true}
              onSelectChange={(value) =>
                saveData('chatRollover', value, rowData.propertyId)
              }
            />
          );
        },
      ],
      [
        'AI SMS',
        'isSmsEnabled',
        'boolean',
        '120px',
        (rowData) => {
          return (
            <Switch
              color="primary"
              checked={rowData.isSmsEnabled}
              onChange={(event) =>
                saveData(
                  'isSmsEnabled',
                  event.target.checked,
                  rowData.propertyId
                )
              }
            />
          );
        },
      ],
      [
        'GenAI SMS',
        'isGenAiSmsEnabled',
        'boolean',
        '100px',
        (rowData) => {
          return (
            <Switch
              color="primary"
              name="is-gen-ai-sms-enabled"
              checked={rowData.isGenAiSmsEnabled}
              disabled={!rowData.isSmsEnabled}
              onChange={(event) =>
                saveData(
                  'isGenAiSmsEnabled',
                  event.target.checked,
                  rowData.propertyId
                )
              }
            />
          );
        },
      ],
      ...(!isCallIntelStandaloneEnabled
        ? [
            [
              'Transcribe',
              'isTranscribed',
              'boolean',
              '120px',
              (rowData) => {
                return (
                  <Switch
                    color="primary"
                    checked={rowData.isTranscribed}
                    onChange={(event) =>
                      saveData(
                        'isTranscribed',
                        event.target.checked,
                        rowData.propertyId
                      )
                    }
                  />
                );
              },
            ],
            [
              'Score',
              'isScored',
              'boolean',
              '120px',
              (rowData) => {
                return (
                  <Switch
                    color="primary"
                    checked={rowData.isScored}
                    onChange={(event) =>
                      saveData(
                        'isScored',
                        event.target.checked,
                        rowData.propertyId
                      )
                    }
                  />
                );
              },
            ],
          ]
        : []),
      [
        'Call Routing',
        'callRouting',
        'string',
        '230px',
        (rowData) => {
          return (
            <ToggleButtonGroup
              value={rowData.callRouting}
              exclusive
              onChange={(event, value) =>
                saveData('callRouting', value, rowData.propertyId)
              }
              aria-label="Options"
            >
              <ToggleButton value="OFF" className={classes.switchButton}>
                Off
              </ToggleButton>
              <ToggleButton
                value="MISSED_CALLS"
                className={classes.switchButton}
              >
                Missed calls
              </ToggleButton>
              <ToggleButton value="ALL" className={classes.switchButton}>
                All
              </ToggleButton>
            </ToggleButtonGroup>
          );
        },
      ],
      [
        'RPCC Voice Agent Rollover',
        'rpccAgentRollover',
        'boolean',
        '150px',
        (rowData) => {
          return (
            <Switch
              color="primary"
              checked={rowData.rpccAgentRollover}
              disabled={rowData.callRouting === 'OFF'}
              onChange={(event) =>
                saveData(
                  'rpccAgentRollover',
                  event.target.checked,
                  rowData.propertyId
                )
              }
            />
          );
        },
      ],
      [
        getAnswerVerificationHeader(),
        'answerVerification',
        'boolean',
        '200px',
        (rowData) => {
          return (
            <div>
              <Switch
                disabled={rowData.callRouting !== 'MISSED_CALLS'}
                color="primary"
                checked={rowData.answerVerification}
                onChange={(event) =>
                  saveData(
                    'answerVerification',
                    event.target.checked,
                    rowData.propertyId
                  )
                }
              />
            </div>
          );
        },
      ],
      [
        'Offer Time',
        'offerTime',
        'numeric',
        '170px',
        (rowData) => {
          return (
            <Select
              options={getOfferTimes()}
              name="offer time"
              aria-label="Select an offer time"
              defaultValue={rowData.offerTime}
              fullWidth={true}
              noForm={true}
              onSelectChange={(value) =>
                saveData('offerTime', value, rowData.propertyId)
              }
            />
          );
        },
      ],
      [
        'GenAI Voice',
        'isGenAiVoiceEnabled',
        'boolean',
        '100px',
        (rowData) => {
          return (
            <Switch
              color="primary"
              name="is-gen-ai-voice-enabled"
              checked={rowData.isGenAiVoiceEnabled}
              disabled={rowData.callRouting === 'OFF'}
              onChange={(event) =>
                saveData(
                  'isGenAiVoiceEnabled',
                  event.target.checked,
                  rowData.propertyId
                )
              }
            />
          );
        },
      ],
      [
        'AI Resident Voice',
        'residentVirtualAgentVoice',
        'boolean',
        '120px',
        (rowData) => {
          return (
            <Switch
              color="primary"
              name="resident-virtual-agent-voice"
              checked={rowData.residentVirtualAgentVoice}
              onChange={(event) =>
                saveData(
                  'residentVirtualAgentVoice',
                  event.target.checked,
                  rowData.propertyId
                )
              }
            />
          );
        },
      ],
      [
        'AI Resident SMS',
        'residentVirtualAgentSms',
        'boolean',
        '120px',
        (rowData) => {
          return (
            <Switch
              color="primary"
              name="resident-virtual-agent-sms"
              checked={rowData.residentVirtualAgentSms}
              onChange={(event) =>
                saveData(
                  'residentVirtualAgentSms',
                  event.target.checked,
                  rowData.propertyId
                )
              }
            />
          );
        },
      ],
      [
        'Tour Scheduling',
        'scheduleTourVaEnabled',
        'boolean',
        '120px',
        (rowData) => {
          return (
            <Switch
              color="primary"
              checked={rowData.scheduleTourVaEnabled}
              onChange={(event) =>
                saveData(
                  'scheduleTourVaEnabled',
                  event.target.checked,
                  rowData.propertyId
                )
              }
            />
          );
        },
      ],
      [
        'Price and Availability',
        'pnaVaEnabled',
        'boolean',
        '120px',
        (rowData) => {
          return (
            <Switch
              color="primary"
              checked={rowData.pnaVaEnabled}
              onChange={(event) =>
                saveData(
                  'pnaVaEnabled',
                  event.target.checked,
                  rowData.propertyId
                )
              }
            />
          );
        },
      ],
    ];

    if (isAiEmailGenAiAdminEnabled) {
      tableArray.splice(3, 0, [
        'GenAI Email',
        'isAiEmailGenAiAdminEnabled',
        'boolean',
        '100px',
        (rowData) => {
          return (
            <Switch
              color="primary"
              name="is-gen-ai-email-enabled"
              checked={rowData.aiEmailGenAiAdminEnabled === 'ON'}
              disabled={rowData.aiEmailEnabled !== 'ON'}
              onChange={(event, value) => {
                handleGenAiEmailEnabledChange(
                  value ? 'ON' : 'OFF',
                  rowData.propertyId,
                  companyId
                );
              }}
            />
          );
        },
      ]);
    }

    return tableArray;
  };

  const getColumnHeaderConfig = (column) => {
    const [title, field, type, width, render] = column;

    return {
      align: 'left',
      title,
      field,
      type,
      width,
      cellStyle: {
        fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      },
      render,
    };
  };

  return (
    <div className="sticky-table-first-column">
      <LoadingOverlay
        open={isLoading}
        style={{ position: 'absolute', opacity: '0.5' }}
      />
      <DataTable
        columns={getColumns().map(getColumnHeaderConfig)}
        data={properties}
        options={tableOptions}
      />
    </div>
  );
};

VirtualAgentConfig.propTypes = {
  companyId: PropTypes.number,
};

export default VirtualAgentConfig;
