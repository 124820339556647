import React from 'react';
import PropTypes from 'prop-types';
import { CreateCampaign } from './CreateCampaign';
import { CreateTemplate } from './CreateTemplate';
import { Campaign } from './Campaign';
import { Template } from './Template';
import { VIEWS } from '../constants';

const DrawerView = ({ closeDrawer, drawerData, reloadViewData, view }) => {
  const { campaignId, templateId } = drawerData;

  const reloadCampaignsViewData = () => reloadViewData(VIEWS.CAMPAIGN);
  const reloadTemplatesViewData = () => reloadViewData(VIEWS.TEMPLATE);

  const renderCampaign = () =>
    campaignId ? (
      <Campaign
        closeDrawer={closeDrawer}
        reloadCampaignsViewData={reloadCampaignsViewData}
        selectedCampaign={drawerData}
      />
    ) : (
      <CreateCampaign
        closeDrawer={closeDrawer}
        reloadCampaignsViewData={reloadCampaignsViewData}
      />
    );

  const renderTemplate = () =>
    templateId ? (
      <Template
        closeDrawer={closeDrawer}
        reloadTemplatesViewData={reloadTemplatesViewData}
        selectedTemplate={drawerData}
      />
    ) : (
      <CreateTemplate
        closeDrawer={closeDrawer}
        newTemplate={drawerData}
        reloadTemplatesViewData={reloadTemplatesViewData}
      />
    );

  return view === VIEWS.CAMPAIGN ? renderCampaign() : renderTemplate();
};

DrawerView.propTypes = {
  closeDrawer: PropTypes.func,
  dripCampaign: PropTypes.oneOfType([
    PropTypes.shape({ campaignId: PropTypes.number }),
    PropTypes.shape({ templateId: PropTypes.number }),
  ]),
  view: PropTypes.string,
};

export default DrawerView;
