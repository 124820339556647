import { MODES, STATUS, TEMPLATE_TABLE_CONTENT_TYPES } from '../../constants';
import { CONFIRMATION_MODES, TEMPLATE_SUBHEADER } from '../constants';

const TEMPLATE_SUBHEADER_VIEW_TEXT = {
  [STATUS.ARCHIVED]: TEMPLATE_SUBHEADER.VIEW_ARCHIVE,
  [STATUS.DRAFT]: TEMPLATE_SUBHEADER.VIEW_DRAFT,
  [STATUS.LIVE]: TEMPLATE_SUBHEADER.VIEW_LIVE,
};

const TEMPLATE_SUBHEADER_USE_TEXT = {
  [STATUS.ARCHIVED]: TEMPLATE_SUBHEADER.USE_ARCHIVE,
  [STATUS.DRAFT]: TEMPLATE_SUBHEADER.USE_DRAFT,
  [STATUS.LIVE]: TEMPLATE_SUBHEADER.USE_LIVE,
};

export const getCardConfig = ({
  closeDrawer,
  contentType,
  handleCancel,
  handleFormGoBack,
  hasTemplateChanged,
  isOriginCreateTemplate,
  mode,
  setConfirmationMode,
  status,
}) => {
  const getConfirmationModeHandler = (mode) => () => setConfirmationMode(mode);

  const templateSubheader =
    contentType === TEMPLATE_TABLE_CONTENT_TYPES.SMS
      ? TEMPLATE_SUBHEADER.NEW_SMS
      : TEMPLATE_SUBHEADER.NEW_EMAIL;

  const configs = {
    [MODES.DISPLAY]: {
      backButtonHandler: isOriginCreateTemplate && closeDrawer,
      closeDrawer: handleCancel,
      subheader: isOriginCreateTemplate
        ? TEMPLATE_SUBHEADER_USE_TEXT[status]
        : TEMPLATE_SUBHEADER_VIEW_TEXT[status],
    },
    [MODES.FORM]: {
      closeDrawer: hasTemplateChanged
        ? getConfirmationModeHandler(CONFIRMATION_MODES.CANCEL)
        : handleCancel,
      backButtonHandler: hasTemplateChanged
        ? getConfirmationModeHandler(CONFIRMATION_MODES.BACK)
        : handleFormGoBack,
      subheader:
        status === STATUS.DRAFT
          ? TEMPLATE_SUBHEADER.EDIT_DRAFT
          : templateSubheader,
    },
  };

  return configs[mode];
};
