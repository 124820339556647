import { COMPANY_TEMPLATE, TEMPLATE_TYPES } from '../constants';

const TEMPLATE_TYPE_MAP = {
  auto_response_email: TEMPLATE_TYPES.AUTORESPONSE_EMAIL,
  auto_response_sms: TEMPLATE_TYPES.AUTORESPONSE_SMS,
  live_video_tour_instructions: TEMPLATE_TYPES.LIVE_VIDEO_TOUR_INSTRUCTIONS,
  live_video_tour_url: TEMPLATE_TYPES.LIVE_VIDEO_TOUR_URL,
  my_tour_agenda: TEMPLATE_TYPES.MY_TOUR_AGENDA,
  self_guided_tour_detailed_instructions:
    TEMPLATE_TYPES.SELF_GUIDED_TOUR_DETAILED_INSTRUCTIONS,
  self_guided_tour_email: TEMPLATE_TYPES.SELF_GUIDED_TOUR_EMAIL,
  self_guided_tour_email_instructions:
    TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_EMAIL,
  self_guided_tour_sms: TEMPLATE_TYPES.SELF_GUIDED_TOUR_SMS,
  self_guided_tour_sms_instructions:
    TEMPLATE_TYPES.SELF_GUIDED_TOUR_INSTRUCTIONS_SMS,
  tour_mobile_quote: TEMPLATE_TYPES.TOUR_MOBILE_QUOTE,
  winback_email: TEMPLATE_TYPES.WIN_BACK_EMAIL,
};

const TRANSFORMED_FIELDS = {
  [COMPANY_TEMPLATE.ASSIGNABLE_PROPERTIES]: 'assignable_properties',
  [COMPANY_TEMPLATE.IS_EVERY_PROPERTY_ASSIGNABLE]: 'all_properties_assignable',
  [COMPANY_TEMPLATE.MESSAGE]: 'message',
  [COMPANY_TEMPLATE.SUBJECT]: 'subject',
  [COMPANY_TEMPLATE.TEMPLATE_NAME]: 'title',
  [COMPANY_TEMPLATE.IS_ENABLED]: 'is_suppressed',
  [COMPANY_TEMPLATE.IS_DEFAULT]: 'is_default',
};

const normalizeTemplates = (templates) => {
  const { defaultTemplate, customTemplates } = templates.reduce(
    (templateMap, template) => {
      const normalizedTemplate = {
        isDefault: template.is_default || false,
        isEnabled: !template.is_suppressed,
        assignedPropertiesCount: template.property_count,
        templateId: template.template_id,
        templateName: template.template_name,
      };

      if (normalizedTemplate.isDefault) {
        templateMap.defaultTemplate = normalizedTemplate;
      } else {
        templateMap.customTemplates.push(normalizedTemplate);
      }

      return templateMap;
    },
    { defaultTemplate: {}, customTemplates: [] }
  );

  return defaultTemplate.templateName
    ? [defaultTemplate, ...customTemplates]
    : customTemplates;
};

export const normalizeProperties = (properties) =>
  properties.map((property) => ({
    assignedTemplateName: property.assigned_template_name,
    propertyId: property.id,
    propertyName: property.property_name,
  }));

export const normalizeCompanyTemplatesByType = (companyTemplates) =>
  Object.entries(companyTemplates).reduce(
    (companyTemplates, [type, templateData]) => {
      const templateType = TEMPLATE_TYPE_MAP[type];
      const { property_count, template_count, templates } = templateData;

      companyTemplates[templateType] = {
        propertyCount: property_count,
        templateCount: template_count,
        templates: normalizeTemplates(templates || []),
      };

      return companyTemplates;
    },
    {}
  );

const normalizeTemplateProperties = (properties) =>
  properties.map((property) => ({
    isEnabled: !property.is_suppressed,
    propertyId: property.id,
    propertyName: property.property_name,
  }));

export const normalizeAssignableProperties = (properties) =>
  properties.map((property) => ({
    isSelected: property.is_assigned,
    propertyId: property.id,
    propertyName: property.property_name,
  }));

export const normalizeTemplateData = (template) => ({
  assignableProperties: normalizeAssignableProperties(
    template.assignable_properties || []
  ),
  assignedProperties: template.properties
    ? normalizeTemplateProperties(template.properties)
    : null,
  canSupportHtml: template.supports_html,
  createdByUserRole: template.created_by_user_role,
  isDefault: template.is_default || false,
  isEveryPropertyAssignable: template.all_properties_assignable ?? true,
  message: template.message || '',
  subject: template.subject || '',
  templateId: template.id,
  templateName: template.title,
});

const transformProperties = (properties) =>
  properties.map((property) => ({
    id: property.propertyId,
    is_suppressed: !property.isEnabled,
  }));

export const transformAssignableProperties = (properties) =>
  properties.map((property) => ({
    id: property.propertyId,
    is_assigned: property.isSelected,
  }));

export const getTransformedPayload = (payload) => {
  const transformedPayload = {};
  const { properties = [], ...companyTemplate } = payload;

  Object.entries(companyTemplate).forEach(([field, value]) => {
    transformedPayload[TRANSFORMED_FIELDS[field]] =
      field === COMPANY_TEMPLATE.IS_ENABLED ? !value : value;
  });

  if (properties.length) {
    transformedPayload.properties = transformProperties(properties);
  }

  if (companyTemplate?.assignableProperties?.length) {
    transformedPayload[
      TRANSFORMED_FIELDS[COMPANY_TEMPLATE.ASSIGNABLE_PROPERTIES]
    ] = transformAssignableProperties(companyTemplate.assignableProperties);
  }

  return transformedPayload;
};

export const getIsDefaultQueryString = (isDefaultTemplate) => {
  return isDefaultTemplate ? '?is_default=true' : '';
};
