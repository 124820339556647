import { useEffect, useState, useRef } from 'react';

import { dripCampaignsApi } from '../../../DripCampaignsApi';
import { SELECT_PROPERTY_MODES, TRIGGER_TYPES } from '../constants';

export const getAssociatedPropertiesMap = (associatedProperties) =>
  associatedProperties.reduce((propertiesMap, property) => {
    propertiesMap[property.propertyId] = true;
    return propertiesMap;
  }, {});

export const useProperties = ({
  associatedProperties,
  campaignId,
  selectPropertiesMode,
  setErrorMessage,
  triggerType,
}) => {
  const associatedPropertiesMapRef = useRef(
    getAssociatedPropertiesMap(associatedProperties)
  );
  const [properties, setProperties] = useState([]);
  const [selectedPropertiesMap, setSelectedPropertiesMap] = useState(
    associatedPropertiesMapRef.current
  );

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const properties =
        triggerType === TRIGGER_TYPES.AI_EMAIL
          ? await dripCampaignsApi.getAiEmailGatedPropertyList(campaignId)
          : await dripCampaignsApi.getDripCampaignPropertyList(campaignId, triggerType);
        setProperties(properties);
      } catch (e) {
        setErrorMessage(e.message);
      }
    };
    fetchProperties();
  }, [setErrorMessage, triggerType]);

  const getUpdatedAssociatedProperties = () =>
    properties
      .filter((property) => selectedPropertiesMap[property.propertyId])
      .sort((propertyA, propertyB) =>
        propertyA.propertyName > propertyB.propertyName ? 1 : -1
      );

  const filterAssociatedProperties = () =>
    properties.filter(
      (property) => !associatedPropertiesMapRef.current[property.propertyId]
    );

  const propertiesList =
    selectPropertiesMode === SELECT_PROPERTY_MODES.EDIT
      ? properties
      : filterAssociatedProperties();

  const getSelectedPropertyCount = () => {
    const selectedPropertiesLength = Object.values(
      selectedPropertiesMap
    ).filter((isSelected) => isSelected).length;

    if (selectPropertiesMode === SELECT_PROPERTY_MODES.EDIT) {
      return selectedPropertiesLength;
    }

    return selectedPropertiesLength - associatedProperties.length;
  };

  const getHasPropertySelectionChanged = () =>
    Object.entries(selectedPropertiesMap).some(([propertyId, isSelected]) => {
      if (associatedPropertiesMapRef.current.hasOwnProperty(propertyId)) {
        return associatedPropertiesMapRef.current[propertyId] !== isSelected;
      }
      return isSelected;
    });

  return {
    associatedPropertiesMap: associatedPropertiesMapRef.current,
    getUpdatedAssociatedProperties,
    hasPropertySelectionChanged: getHasPropertySelectionChanged(),
    propertiesList,
    selectedPropertiesCount: getSelectedPropertyCount(),
    selectedPropertiesMap,
    setSelectedPropertiesMap,
  };
};
