import React from 'react';
import PropTypes from 'prop-types';

import { InputCheckbox } from '@knockrentals/knock-shared-web';

const AllPropertyCheckbox = ({
  isChecked = false,
  isIndeterminate = false,
  updateAllPropertiesIsEnabled,
}) => {
  return (
    <InputCheckbox
      checked={isChecked}
      color="primary"
      onChange={(e) => updateAllPropertiesIsEnabled(e.target.checked)}
      indeterminate={isIndeterminate}
      inputProps={{ 'aria-label': 'enable all properties checkbox' }}
    />
  );
};

AllPropertyCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  updateAllPropertiesIsEnabled: PropTypes.func,
};

export default AllPropertyCheckbox;
