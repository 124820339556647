import React from 'react';
import PropTypes from 'prop-types';

const ResmanIntegrationInfo = ({ integration: { vendor, role, vendor_community_id, integration_partner_id, account_id } }) => (
  <div className="knock-admin-integration-info">
    <div>
      <strong>Integration:</strong> {vendor}
      {role === 'import' ? ' (unit availability, resident import)' : ''}
    </div>
    <div>
      <strong>Vendor Property Id:</strong> {vendor_community_id}
    </div>
    <div>
      <strong>Integration Partner Id:</strong> {integration_partner_id}
    </div>
    <div>
      <strong>Account Id:</strong> {account_id}
    </div>
  </div>
);

ResmanIntegrationInfo.propTypes = {
  integration: PropTypes.object
};

export default ResmanIntegrationInfo;
