import React from "react";
import PropTypes from 'prop-types';

const PropertySelfieScanResults = ({ title, defaultMessage }) =>
  <div className='tours-self-scan-results-container'>
    <h3>{title}</h3>
    <h4>ID Scan results page messaging</h4>
    <div className='tours-self-scan-results-message-container'>
      {defaultMessage}
    </div>
  </div>;

PropertySelfieScanResults.propTypes = {
  title: PropTypes.string,
  defaultMessage: PropTypes.string
};

export default PropertySelfieScanResults;
