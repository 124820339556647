import React from 'react';

import { PropertyCheckbox } from './PropertyCheckbox';
import { MultiSelectCheckbox } from '../../common/MultiSelectCheckbox';
import { SchedulingSwitch } from './SchedulingSwitch';
import {
  IS_IN_PERSON_TOURS_ENABLED,
  IS_LIVE_VIDEO_TOURS_ENABLED,
  IS_SELF_SCHEDULE_ENABLED,
} from '../constants';

export const getColumns = ({
  getCurrentPagePropertyCount,
  getTotalPropertyCount,
  isBatchCheckBoxChecked,
  isBatchCheckBoxIndeterminate,
  onChangeBatchOption,
  propertySelectionMap,
  toggleSchedulingSwitch,
  updatePropertyIsChecked,
}) => [
  {
    align: 'left',
    field: 'enabled',
    render: (rowData) => {
      const { isSelected } = propertySelectionMap[rowData.propertyId] || {};
      return (
        <PropertyCheckbox
          isPropertySelected={isSelected}
          propertyId={rowData.propertyId}
          propertyName={rowData.propertyName}
          updatePropertyIsChecked={updatePropertyIsChecked}
        />
      );
    },
    title: (
      <MultiSelectCheckbox
        deselectAll={onChangeBatchOption}
        getPageItemCount={getCurrentPagePropertyCount}
        handleSelect={onChangeBatchOption}
        isChecked={isBatchCheckBoxChecked}
        isIndeterminate={isBatchCheckBoxIndeterminate}
        label="Property"
        totalCount={getTotalPropertyCount()}
      />
    ),
    width: '59px',
  },
  { align: 'left', title: 'ID', field: 'propertyId', width: '72px' },
  {
    align: 'left',
    field: 'leasingTeamName',
    title: 'Leasing Team',
    width: '160px',
  },
  {
    align: 'left',
    field: IS_SELF_SCHEDULE_ENABLED,
    render: (rowData) => (
      <SchedulingSwitch
        isChecked={rowData[IS_SELF_SCHEDULE_ENABLED]}
        label="Self-Scheduling"
        name={IS_SELF_SCHEDULE_ENABLED}
        updateSchedulingSwitch={toggleSchedulingSwitch(rowData)}
      />
    ),
    title: 'Self-Scheduling',
    width: '160px',
  },
  {
    align: 'left',
    field: IS_IN_PERSON_TOURS_ENABLED,
    render: (rowData) => (
      <SchedulingSwitch
        isChecked={rowData[IS_IN_PERSON_TOURS_ENABLED]}
        label="In-Person Tours"
        name={IS_IN_PERSON_TOURS_ENABLED}
        updateSchedulingSwitch={toggleSchedulingSwitch(rowData)}
      />
    ),
    title: 'In-Person Tours',
    width: '160px',
  },
  {
    align: 'left',
    field: IS_LIVE_VIDEO_TOURS_ENABLED,
    render: (rowData) => (
      <SchedulingSwitch
        isChecked={rowData[IS_LIVE_VIDEO_TOURS_ENABLED]}
        label="Live Video Tours"
        name={IS_LIVE_VIDEO_TOURS_ENABLED}
        updateSchedulingSwitch={toggleSchedulingSwitch(rowData)}
      />
    ),
    title: 'Live Video Tours',
    width: '160px',
  },
];
