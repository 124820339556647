import React from 'react';
import PropTypes from 'prop-types';

import { Tab, Tabs, makeStyles } from '@knockrentals/knock-shared-web';
import { VIEWS } from './constants';

export const TITLES = {
  [VIEWS.CAMPAIGN]: 'Campaign',
  [VIEWS.TEMPLATE]: 'Template',
};

const useStyles = makeStyles({
  dripCampaignTabs: {
    borderBottom: '1px solid #E0E0E0',
    marginBottom: '10px',
    '&.MuiTabs-root': {
      width: '300px',
    },
  },
});

const DripCampaignTabs = ({ children, setView, view }) => {
  const classes = useStyles();
  return (
    <section>
      <Tabs
        className={classes.dripCampaignTabs}
        indicatorColor="primary"
        onChange={(_, newView) => setView(newView)}
        textColor="primary"
        value={view}
      >
        <Tab
          ariaLabel={TITLES[VIEWS.CAMPAIGN]}
          label={TITLES[VIEWS.CAMPAIGN]}
          value={VIEWS.CAMPAIGN}
        />

        <Tab
          ariaLabel={TITLES[VIEWS.TEMPLATE]}
          label={TITLES[VIEWS.TEMPLATE]}
          value={VIEWS.TEMPLATE}
        />
      </Tabs>

      {children}
    </section>
  );
};

DripCampaignTabs.propTypes = {
  children: PropTypes.node,
  setView: PropTypes.func,
  view: PropTypes.string,
};

export default DripCampaignTabs;
