import { apiRemote } from '../../Services/RemoteService';
import CookieService from '../../Services/CookieService';
import { BASE_WEBAPP_URI, UNIFIED_API_URL } from '../../config';

class LoginAPI {
  static async login(username, password) {
    try {
      const response = await apiRemote.post('auth/validate', {
        username,
        password,
        type: 'manager',
      });

      if (response.status !== 200) {
        throw new Error(response.status);
      }

      const { access_token, refresh_token } = await response.json();
      return { accessToken: access_token, refreshToken: refresh_token };
    } catch (error) {
      throw error;
    }
  }

  static getUserAuthToken(userId) {
    return apiRemote
      .post(`auth/team`, { manager_id: userId })
      .then((response) => response.json());
  }
  // this is used in an autologin flow where only the accessToken is passed in the
  // query parameter and the refreshToken is needed to refresh the accessToken
  static async getUserRefreshToken(accessToken) {
    try {
      const response = await apiRemote.post('auth/exchange', {
        token: accessToken,
      });
      if (response.status !== 200) {
        throw new Error('error logging in.');
      }

      const { access_token, refresh_token } = await response.json();
      return { accessToken: access_token, refreshToken: refresh_token };
    } catch (error) {
      throw error;
    }
  }

  static openWebappAsUser(userId) {
    LoginAPI.getUserAuthToken(userId).then((response) => {
      window.open(BASE_WEBAPP_URI + '/autologin?token=' + response.token);
    });
  }

  static openWebappAsUserFromCookie(userId) {
    LoginAPI.getUserAuthToken(userId).then((response) => {
      CookieService.setTokenCookie(response.token);
      window.open(BASE_WEBAPP_URI + '/autologin');
    });
  }

  static refreshAuthToken(refreshToken) {
    return apiRemote
      .post(`auth/refresh`, { refresh_token: refreshToken })
      .then((response) => response.json());
  }

  static async exchangeUnifiedToken(unifiedLoginAccessToken) {
    const apiUrl = `${UNIFIED_API_URL}/token`;

    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${unifiedLoginAccessToken}`,
          'Content-Type': 'application/json',
        },
      });

      const { accessToken } = await response.json();
      return accessToken;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default LoginAPI;
