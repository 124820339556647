const GET_USERS_QUERY = `
query GetSsdaUsers($input: SsdaUsersInput!) {
  getSsdaUsers(input: $input) {
    companyId
    ipAddresses
    userInfo {
      username
      fullName
      email
      status
    }
    totalUserCount
  }
}`;

const CREATE_USER_MUTATION = `
mutation CreateSsdaUser($input: CreateSsdaCredentialsInput!) {
  createSsdaUser(input: $input) {
    companyId
    userId
    ipAddresses
    userInfo {
      username
      email
      isServiceAccount
      firstName
      lastName
      status
    }
  }
}
`;

const DELETE_USER_MUTATION = `
mutation DeleteSsdaUsers($input: DeleteUserInput!) {
  deleteSsdaUsers(input: $input) {
    deletedUsers
    failedDeletedUsers
  }
}
`;

const DELETE_IP_ADDRESSES_MUTATION = `
mutation DeleteIpAddresses($input: DeleteSsdaIpInput!) {
  deleteIpAddresses(input: $input)
}
`;


export const ANALYTICS_QUERIES = {
  GET_USERS: {
    query: GET_USERS_QUERY,
    operationName: "GetSsdaUsers",
  },
  CREATE_USER: {
    query: CREATE_USER_MUTATION,
    operationName: "CreateSsdaUser",
  },
  DELETE_USER: {
    query: DELETE_USER_MUTATION,
    operationName: "DeleteSsdaUsers",
  },
  DELETE_IP_ADDRESSES: {
    query: DELETE_IP_ADDRESSES_MUTATION,
    operationName: "DeleteIpAddresses",
  },
};
