import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Text, TextField } from '@knockrentals/knock-shared-web';

import { EmailTemplateEditor } from '../../../../../EmailTemplateEditor';
import {
  PLACE_HOLDER_TEXT,
  TEMPLATE,
  TEMPLATE_COMPOSE_HEADER,
} from '../constants';

const useStyles = makeStyles(() => ({
  emailTitleInputText: {
    '& .MuiFilledInput-root': {
      background: '#F7F7F8',
      border: 'none',
    },
    '& label.MuiInputLabel-formControl': {
      fontWeight: '400',
      paddingLeft: '12px',
      transform: 'translate(0, 24px) scale(.85)',
      top: '5px',
      left: 0,
    },
    '& .MuiFilledInput-input': {
      padding: '15px 12px 15px 0px',
    },
  },
  emailErrorText: {
    marginTop: '3px',
    marginLeft: '5px',
  },
  emailTemplateSubheader: {
    marginTop: '15px',
  },
}));

const EmailTemplate = ({
  clearValidationErrorMessage,
  handleOnChange,
  handleOnChangeMessage,
  mergeTags,
  message,
  subject,
  validationErrorMessage,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Text variant="subtitle1" className={classes.emailTemplateSubheader}>
        {TEMPLATE_COMPOSE_HEADER.EMAIL}
      </Text>
      <Text variant="caption" paragraph gutterBottom>
        {TEMPLATE_COMPOSE_HEADER.EMAIL_COMPOSE_MESSAGE}
      </Text>
      <TextField
        className={classes.emailTitleInputText}
        error={Boolean(validationErrorMessage.subject)}
        fullWidth
        helperText={validationErrorMessage.subject}
        inputProps={{
          maxLength: 100,
          'aria-label': TEMPLATE.MESSAGE,
        }}
        name={TEMPLATE.SUBJECT}
        onChange={handleOnChange}
        onFocus={() => {
          clearValidationErrorMessage(TEMPLATE.SUBJECT);
        }}
        placeholder={PLACE_HOLDER_TEXT.SUBJECT}
        value={subject}
        variant="filled"
      />
      <div className={validationErrorMessage.message && 'error'}>
        <EmailTemplateEditor
          content={message}
          isSourceModeEnabled
          mergeTags={mergeTags}
          minResizeWidth={'100%'}
          name="drip-campaign-template-email"
          onChange={handleOnChangeMessage}
          placeHolderText={PLACE_HOLDER_TEXT.MESSAGE}
        />
        {validationErrorMessage.message && (
          <Text
            className={classes.emailErrorText}
            color="error"
            role="alert"
            variant="caption"
          >
            {validationErrorMessage.message}
          </Text>
        )}
      </div>
    </React.Fragment>
  );
};

EmailTemplate.propTypes = {
  clearValidationErrorMessage: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleOnChangeMessage: PropTypes.func,
  mergeTags: PropTypes.array,
  message: PropTypes.string,
  subject: PropTypes.string,
  validationErrorMessage: PropTypes.object,
};

export default EmailTemplate;
