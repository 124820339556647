import React from 'react';
import { PropTypes } from 'prop-types';
import DataTable from 'material-table';

import { tableIcons } from './tableIcons';
import { getColumns } from './table-columns';
import { getTableOptions } from './table-options';

const QuickReplyDataTable = ({
  isEnabledPropertyMap,
  properties,
  setIsEnabledPropertyMap,
}) => {
  const updateIsEnabledPropertyMap = (propertyId) => (isEnabled) => {
    setIsEnabledPropertyMap((prevState) => ({
      ...prevState,
      [propertyId]: isEnabled,
    }));
  };

  const getAllPropertyCheckboxState = () => {
    const selectionStatus = Object.values(isEnabledPropertyMap).reduce(
      (statusMap, isEnabled) => {
        const status = isEnabled ? 'selected' : 'unSelected';
        statusMap[status] += 1;
        return statusMap;
      },
      { selected: 0, unSelected: 0 }
    );

    const propertyCount = properties.length;
    const { selected, unSelected } = selectionStatus;
    const areAllSelected = propertyCount > 0 && selected === propertyCount;
    const areAllUnselected = unSelected === propertyCount;

    return {
      isAllChecked: areAllSelected,
      isIndeterminate: !areAllSelected && !areAllUnselected,
      selectedPropertyCount: selected,
    };
  };

  const { isAllChecked, isIndeterminate, selectedPropertyCount } =
    getAllPropertyCheckboxState();

  const updateAllPropertiesIsEnabled = (isEnabled) =>
    setIsEnabledPropertyMap((prevState) =>
      Object.keys(prevState).reduce((isEnabledMap, id) => {
        isEnabledMap[id] = isEnabled;
        return isEnabledMap;
      }, {})
    );

  const enabledCountDisplay = `(${selectedPropertyCount}/${properties.length})`;

  const tableColumns = getColumns({
    enabledCountDisplay,
    isAllChecked,
    isIndeterminate,
    isEnabledPropertyMap,
    updateIsEnabledPropertyMap,
    updateAllPropertiesIsEnabled,
  });

  return (
    <DataTable
      columns={tableColumns}
      data={properties}
      icons={tableIcons}
      options={getTableOptions()}
      style={{ overflow: 'hidden' }}
    />
  );
};

QuickReplyDataTable.propTypes = {
  isEnabledPropertyMap: PropTypes.objectOf(PropTypes.bool),
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      propertyId: PropTypes.number,
      isEnabled: PropTypes.bool,
      propertyName: PropTypes.string,
      leasingTeamName: PropTypes.string,
    })
  ),
  setIsEnabledPropertyMap: PropTypes.func,
};

export default QuickReplyDataTable;
