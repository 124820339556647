import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@knockrentals/knock-shared-web';

import DaysToDelay from './DaysToDelay';
import NoDelayCheckbox from './NoDelayCheckbox';
import { DISABLED_IMMEDIATE_ACTION_TOOLTIP_TEXT, TRIGGER_TYPES,  } from '../../Campaign/constants';

const ActionTiming = ({
  actionTrigger,
  clearMessage,
  daysToDelay,
  daysToDelayErrorMessage,
  handleDaysToDelayOnChange,
  handleNoDelayOnChange,
  hasNoDelay,
  isFormMode,
  isNoDelayCheckboxEnabled,
  triggerType,
}) => {
  const isImmediateActionDisabled = !isFormMode || triggerType === TRIGGER_TYPES.CONFIGURABLE_STALE;
  const noDelayTooltipText = isImmediateActionDisabled
    ? DISABLED_IMMEDIATE_ACTION_TOOLTIP_TEXT
    : '';
  const isDaysToDelayVisible = isFormMode || daysToDelay;
  const isNoDelayCheckboxVisible = (isNoDelayCheckboxEnabled && isFormMode) || hasNoDelay;

  return (
    <div>
      <Text gutterBottom variant="h6">
        Timing
      </Text>

      {isDaysToDelayVisible && (
        <DaysToDelay
          actionTrigger={actionTrigger}
          clearErrorMessage={clearMessage('daysToDelay')}
          daysToDelay={daysToDelay}
          handleDaysToDelayOnChange={handleDaysToDelayOnChange}
          hasError={Boolean(daysToDelayErrorMessage)}
          isFormMode={isFormMode}
          isDaysToDelayDisabled={hasNoDelay}
        />
      )}

      {isNoDelayCheckboxVisible && (
        <NoDelayCheckbox
          actionTrigger={actionTrigger}
          clearErrorMessage={clearMessage('daysToDelay')}
          disabled={isImmediateActionDisabled}
          handleNoDelayOnChange={handleNoDelayOnChange}
          hasError={Boolean(daysToDelayErrorMessage)}
          hasNoDelay={hasNoDelay}
          isFormMode={isFormMode}
          tooltipText={noDelayTooltipText}
        />
      )}

      {daysToDelayErrorMessage && isFormMode && (
        <Text color="error" component="div" variant="caption">
          {daysToDelayErrorMessage}
        </Text>
      )}
    </div>
  );
};

ActionTiming.propTypes = {
  actionTrigger: PropTypes.string,
  clearMessage: PropTypes.func,
  daysToDelay: PropTypes.number,
  daysToDelayErrorMessage: PropTypes.string,
  handleDaysToDelayOnChange: PropTypes.func,
  handleNoDelayOnChange: PropTypes.func,
  hasNoDelay: PropTypes.bool,
  isFormMode: PropTypes.bool,
  isNoDelayCheckboxEnabled: PropTypes.bool,
  triggerType: PropTypes.string,
};

export default ActionTiming;
