import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CardContent, Text, makeStyles } from '@knockrentals/knock-shared-web';
import CompanyTemplateCard from '../CompanyTemplateCard';
import { CompanyTemplateActions } from '../CompanyTemplateActions';
import ConfirmationDialog from '../../../common/ConfirmationDialog';
import { ErrorDialog } from '../../../common/ErrorDialog';
import { PropertiesDataTable } from '../PropertiesDataTable';
import useAddProperty from './useAddProperty';
import {
  CONFIRMATION_BUTTON_TEXT,
  CONFIRMATION_MESSAGES,
  CONFIRMATION_MODES,
  CONFIRMATION_TITLES,
  PROPERTY_TABLE_VIEWS,
} from '../../constants';

const useStyles = makeStyles(() => ({
  addPropertyCardContent: {
    flexGrow: 1,
    background: '#F7F7F8',
    overflowY: 'auto',
    padding: '26px 40px 40px',
  },
  textLabel: { marginLeft: '5px', marginBottom: '3px' },
  bold: { fontWeight: 'bold' },
}));

const AddProperty = ({
  addProperties,
  closeDrawer,
  templateName,
  templatePropertiesMap,
  templateType,
  isDefaultTemplate,
}) => {
  const classes = useStyles();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const {
    errorMessage,
    getSelectedProperties,
    properties,
    setErrorMessage,
    setTablePropertiesMap,
    tablePropertiesMap,
    isSaveButtonDisabled,
  } = useAddProperty({
    templatePropertiesMap,
    templateType,
    isDefaultTemplate,
  });

  const handleConfirmation = () => {
    const selectedProperties = getSelectedProperties();
    closeDrawer();
    addProperties(selectedProperties);
  };

  const handleCloseErrorDialog = () => {
    setErrorMessage('');
  };

  const composeConfirmationMessage = () => {
    const messageStart = CONFIRMATION_MESSAGES[ADD];
    return (
      <Text component="span" variant="body1">
        {messageStart}
        <Text component="span" variant="body1" className={classes.bold}>
          {templateName}
        </Text>
        .
      </Text>
    );
  };

  const { ADD } = CONFIRMATION_MODES;

  return (
    <div>
      <ConfirmationDialog
        closeDialog={() => setIsConfirmationOpen(false)}
        handleConfirmation={handleConfirmation}
        isOpen={isConfirmationOpen}
        message={composeConfirmationMessage()}
        primaryButtonText={CONFIRMATION_BUTTON_TEXT[ADD]}
        title={CONFIRMATION_TITLES[ADD]}
      />

      <ErrorDialog
        closeDialog={handleCloseErrorDialog}
        errorMessage={errorMessage}
        isOpen={Boolean(errorMessage)}
      />

      <CompanyTemplateCard
        closeDrawer={closeDrawer}
        subheader={templateName}
        title="ADD PROPERTY"
      >
        <CardContent className={classes.addPropertyCardContent}>
          <Text
            variant="subtitle2"
            color="secondary"
            className={classes.textLabel}
          >
            {`Select One or More Properties to Add to ${templateName}`}
          </Text>

          <PropertiesDataTable
            properties={properties}
            tablePropertiesMap={tablePropertiesMap}
            setTablePropertiesMap={setTablePropertiesMap}
            view={PROPERTY_TABLE_VIEWS.ADD_PROPERTY}
          />
        </CardContent>

        <CompanyTemplateActions
          isSaveButtonDisabled={isSaveButtonDisabled}
          handleCancelOnClick={closeDrawer}
          handleSaveOnClick={() => setIsConfirmationOpen(true)}
          primaryText="Include Selected Properties"
        />
      </CompanyTemplateCard>
    </div>
  );
};

AddProperty.propTypes = {
  addProperties: PropTypes.func,
  closeDrawer: PropTypes.func,
  templateName: PropTypes.string,
  templatePropertiesMap: PropTypes.objectOf(
    PropTypes.shape({
      isEnabled: PropTypes.bool,
      isSelected: PropTypes.bool,
      propertyId: PropTypes.number,
      propertyName: PropTypes.string,
    })
  ),
  templateType: PropTypes.string,
  isDefaultTemplate: PropTypes.bool,
};

export default AddProperty;
