export const normalizeCompanySources = (marketingSources) =>
  marketingSources.map((source) => ({
    isDefault: source.is_default,
    isEnabled: source.is_enabled,
    code: source.marketing_source_code,
    id: source.marketing_source_id,
    name: source.marketing_source_name,
  })).sort((sourceX, sourceY) => {
    return sourceX.name.localeCompare(sourceY.name);
  });

export const normalizePropertiesMap = (propertiesData) => {
  const { marketing_source_id, leasing_teams = [] } = propertiesData;
  return leasing_teams.reduce((propertyMap, leasing_team) => {
    const leasingTeamId = leasing_team.id;
    propertyMap[leasingTeamId] = {
      isEnabled: leasing_team.is_enabled,
      leasingTeamId: leasing_team.id,
      leasingTeamName: leasing_team.name,
      marketingSourceId: marketing_source_id,
      properties: leasing_team.properties,
    };
    return propertyMap;
  }, {});
};

export const getSortedProperties = (properties) =>
  Object.values(properties).sort((propertyA, propertyB) =>
    propertyA.name > propertyB.name ? 1 : -1
  );

export const getCompanySourcesMap = (companySourcesList = []) => {
  return companySourcesList.reduce((sourceMap, source) => {
    sourceMap[source.id] = source.name;
    return sourceMap;
  }, {});
};

