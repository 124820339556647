import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '@knockrentals/knock-react';
import { Formik } from 'formik';

import '../../_attributeInfo.scss';

class EditAttributeTypeModal extends Component {
  state = {
    attributeType: this.props.attributeType.attribute_type,
  };

  render() {
    return (
      <Modal className="attribute-modal">
        <Formik
          initialValues={{
            attributeType: this.props.attributeType.attribute_type,
          }}
          validate={this.validate}
          onSubmit={this.onConfirm.bind(this)}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <ModalHeader>
                <h2>Edit New Attribute Type</h2>
              </ModalHeader>
              <ModalContent>
                <div className="knock-react-flex">
                  <div className="field-label">Attribute Type</div>
                  <div className="field-input">
                    <input
                      name="attributeType"
                      type="text"
                      onChange={props.handleChange}
                      value={props.values.attributeType}
                      className="knock-react-input"
                      placeholder="e.g. Region, Type, Ownership, Stage, etc..."
                    />
                    {props.errors.attributeType ? (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.attributeType}
                      </span>
                    ) : null}
                  </div>
                </div>
              </ModalContent>
              <ModalFooter>
                <button
                  className="btn-md btn-primary"
                  onClick={this.onCancel.bind(this)}
                  type="button"
                >
                  <i className="fa fa-lg fa-times-circle" /> Cancel
                </button>
                <button
                  className="btn-md btn-danger"
                  onClick={this.onDelete.bind(this)}
                  type="button"
                >
                  <i className="fa fa-lg fa-trash" /> Delete
                </button>
                <button className="btn-md btn-success" type="submit">
                  <i className="fa fa-lg fa-check-circle" /> Update Attribute
                  Type
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  validate(values) {
    const errors = {};

    if (!values.attributeType.trim()) {
      errors.attributeType = 'Required';
    }

    return errors;
  }

  onConfirm(values) {
    this.props.updateAttributeType(
      this.props.attributeType.id,
      values.attributeType.trim()
    );
  }

  onDelete(event) {
    event.preventDefault();
    this.props.deleteAttributeType(this.props.attributeType.id);
  }

  onCancel() {
    this.props.toggleModal(false);
  }
}

export default EditAttributeTypeModal;
