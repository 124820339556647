import React from 'react';
import { Link, Text } from '@knockrentals/knock-shared-web';
import { TooltipDrawer } from '../../common/TooltipDrawer';
import './_BrandContentTooltip.scss';

export const BRAND_CONTENT_TOOLTIP_TITLE = 'Brand Content';
export const DRAWER_SUBHEADER = 'Brand Content Info';

export const BRAND_CONTENT_TEXT = {
  TITLE: 'Brand Content Instructions',
  DESCRIPTION:
    'To include your unique brand identity in outgoing email communications, follow these steps:',
};

export const HEADER_FOOTER = 'HeaderFooter';
export const ADDING_BRAND_COLOR = 'AddBrandColor';
export const ADDING_PROPERTY_COLOR = 'AddBrandPropertyColor';

export const TOOLTIP_TITLES = {
  [HEADER_FOOTER]: 'Header and Footer:',
  [ADDING_BRAND_COLOR]: 'Adding Brand Color:',
  [ADDING_PROPERTY_COLOR]: 'Adding Property Logo:',
};

export const TOOL_TIP_TYPES = [
  HEADER_FOOTER,
  ADDING_BRAND_COLOR,
  ADDING_PROPERTY_COLOR,
];

export const TOOLTIPS = {
  [HEADER_FOOTER]: (
    <React.Fragment>
      <Text variant="body2">
        Display your property logo and brand color in the header and footer of
        your emails.
      </Text>
      <Text paragraph variant="body2">
        If no options are selected, the email will have no additional
        header/footer content.
      </Text>
    </React.Fragment>
  ),

  [ADDING_BRAND_COLOR]: (
    <React.Fragment>
      <Text variant="body2">
        Navigate to{' '}
        <Link
          variant="body2"
          href="/properties"
          target="_blank"
          rel="noopener noreferrer"
        >
          Properties
        </Link>{' '}
        and select <strong>Basics</strong>.
      </Text>
      <Text variant="body2">Update your brand color.</Text>
      <Text paragraph variant="body2">
        Once updated, click <strong>Save Basic Information</strong>
      </Text>
    </React.Fragment>
  ),
  [ADDING_PROPERTY_COLOR]: (
    <React.Fragment>
      <Text variant="body2">Navigate to Knock CRM.</Text>
      <Text variant="body2">
        Navigate to <strong>Leasing Binder</strong> and select{' '}
        <strong>Edit Community</strong>.
      </Text>
      <Text paragraph variant="body2">
        Add your property logo there.
      </Text>
    </React.Fragment>
  ),
};

const BrandContentTooltip = () => (
  <TooltipDrawer
    title={BRAND_CONTENT_TOOLTIP_TITLE}
    subheader={DRAWER_SUBHEADER}
  >
    <div className="tooltip-section">
      <Text gutterBottom variant="h6">
        {BRAND_CONTENT_TEXT.TITLE}
      </Text>
      <Text variant="body2" paragraph>
        {BRAND_CONTENT_TEXT.DESCRIPTION}
      </Text>

      <div className="brand-content-tooltip">
        {TOOL_TIP_TYPES.map((tooltipType) => {
          return (
            <div key={tooltipType}>
              <Text gutterBottom variant="subtitle1">
                {TOOLTIP_TITLES[tooltipType]}
              </Text>

              {TOOLTIPS[tooltipType]}
            </div>
          );
        })}
      </div>
    </div>
  </TooltipDrawer>
);

export default BrandContentTooltip;
