export const ERROR_MESSAGES = {
    FETCH:
        'An unexpected error has occurred.  SSDA users are unable to be managed at this time',
    UPDATE:
        'An unexpected error has occurred.  SSDA users are unable to be updated at this time',
};

export const SSDA_PAGE_VIEWS = {
    ERROR: "ERROR",
    LIST: "LIST",
    LOADING: "LOADING",
    SETUP: "SETUP",
};
