import React, { useState } from 'react';
import Button from '../Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntegrationsAPI from '../../IntegrationsAPI';
import { Toaster } from '@knockrentals/knock-react';


export const UpdateIntegrationButton = props => {
  const {
    property_id,
    engrain_unitmap_id,
    reloadData,
    setUpdateLoading,
  } = props;

  // Enum of all view states the add/update button can have:
  const UpdateButtonViews = {
    DEFAULT: 'DEFAULT',
    REQUESTED: 'REQUESTED',
    SUCCESS: 'SUCCESS',
  };
  const [currentView, setCurrentView] = useState(UpdateButtonViews.DEFAULT);

  const onError = (toastMessage, logMessage) => {
    if (logMessage) {
      console.error(logMessage);
    }
    Toaster.showToast(toastMessage, 2000, Toaster.ToastClasses.error);
  };

  const onSuccess = (toastMessage, logMessage) => {
    if (logMessage) {
      console.info(logMessage);
    }
    Toaster.showToast(toastMessage, 2000, Toaster.ToastClasses.success);
  };

  const handleError = (toastMessage, logMessage) => {
    onError(toastMessage, logMessage);
    setCurrentView(UpdateButtonViews.DEFAULT);
  };

  const handleSuccess = (toastMessage, logMessage) => {
    onSuccess(toastMessage, logMessage);
    setCurrentView(UpdateButtonViews.SUCCESS);
    setTimeout(() => reloadData(), 2000);
  };

  const onSubmit = async (property_id, engrain_unitmap_id) => {
    try {
      await IntegrationsAPI.addEngrainIntegration({ property_id, engrain_unitmap_id });
      handleSuccess('Successfully added Engrain integration!');
    } catch (error) {
      handleError('Error adding Engrain integration', error);
    }
  };

  const handleRequestUpdate = async (property_id, engrain_unitmap_id) => {
    setUpdateLoading(true);
    setCurrentView(UpdateButtonViews.REQUESTED);
    await onSubmit(property_id, engrain_unitmap_id);
    setUpdateLoading(false);
  };

  const Wrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      { children}
    </div>
  );

  const DefaultView = (
    <Button
      className='btn-success update-button'
      iconType="pencil"
      onClick={() => handleRequestUpdate(property_id, engrain_unitmap_id)}
    >
      Update Integration
    </Button>
  );

  const RequestedView = (
    <Wrapper style={{ display: 'inline-block'}}>
      <CircularProgress size={15} />
      <span style={{ marginLeft: '5px' }}>
        Updating Integration
      </span>
    </Wrapper>
  );

  const SuccessView = (
    <Wrapper  style={{ display: 'inline-block'}}>
      <i className='fa fa-check' style={{ color: '#5cb85c' }} />
      <span style={{ marginLeft: '10px' }}>
        Success
      </span>
    </Wrapper>
  );

  switch (currentView) {
    case UpdateButtonViews.DEFAULT:
      return DefaultView;

    case UpdateButtonViews.REQUESTED:
      return RequestedView;

    case UpdateButtonViews.SUCCESS:
      return SuccessView;

    default:
      return DefaultView;
  }
};

export default UpdateIntegrationButton;