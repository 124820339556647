import React from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Text,
} from '@knockrentals/knock-shared-web';

const propertyMessage = (count) =>
  `${count} ${count === 1 ? 'property' : 'properties'}`;
const settingsMessage = (count) =>
  `${count} ${count === 1 ? 'setting' : 'settings'}`;

const ConfirmationDialog = ({
  closeDialog,
  handleSaveOnClick,
  isOpen,
  preferenceCount,
  propertyCount,
}) => (
  <Dialog open={isOpen} onClose={closeDialog}>
    <DialogContent>
      <Text variant="h6">
        {`You are changing ${settingsMessage(
          preferenceCount
        )} for ${propertyMessage(propertyCount)}.`}
        <br />
        Do you want to proceed?
      </Text>
    </DialogContent>

    <DialogActions>
      <Button
        color="primary"
        onClick={closeDialog}
        variant="text"
        startIcon={<ArrowBackIcon />}
      >
        Cancel
      </Button>

      <Button
        color="primary"
        onClick={handleSaveOnClick}
        variant="text"
        endIcon={<ArrowForwardIcon />}
      >
        Yes, proceed
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  closeDialog: PropTypes.func,
  handleSaveOnClick: PropTypes.func,
  isOpen: PropTypes.bool,
  preferenceCount: PropTypes.number,
  propertyCount: PropTypes.number,
};

export default ConfirmationDialog;
