import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Divider,
  LoadingOverlay,
  Text,
  TextField,
  makeStyles,
} from '@knockrentals/knock-shared-web';

import EmailTemplate from './EmailTemplate';
import SmsTemplate from './SmsTemplate';
import { DripCampaignCard } from '../../DripCampaignCard';
import FeatureCardContent from '../../../common/FeatureCardContent';
import FeatureDrawer from '../../../common/FeatureDrawer';
import { useMergeTags } from '../../../common/MergeTags';
import { MERGE_TAG_TYPES } from '../../../../../Services/message-service';
import Preview from './Preview';
import TemplatePreview from './TemplatePreview';
import { TemplateActionButtons } from '../../DrawerActionButtons';
import { Confirmation } from '../Confirmation';
import { ErrorDialog } from '../../../common/ErrorDialog';
import useTemplate from './useTemplate';
import { MODES, STATUS, TEMPLATE_TABLE_CONTENT_TYPES } from '../../constants';
import { getCardConfig } from './utils';
import {
  CONFIRMATION_MODES,
  CONFIRMATION_MODE_FOR_STATUS,
  PLACE_HOLDER_TEXT,
  TEMPLATE,
  TEMPLATE_FPO_MESSAGE,
  TEMPLATE_VALIDATION_MESSAGES,
} from '../constants';

const useStyles = makeStyles(() => ({
  templateCardContent: {
    '& .cke_chrome': {
      border: 'none',
      borderRadius: 0,
      boxShadow: 'none',
    },
    '& .error': {
      '& .cke_chrome': {
        borderColor: '#F599A8',
      },
    },
    background: '#FFFFFF',
  },
  titleInputText: {
    '& .MuiFilledInput-root': {
      background: '#F7F7F8',
      border: 'none',
    },
    '& label.MuiInputLabel-formControl': {
      fontWeight: '400',
      paddingLeft: '12px',
      transform: 'translate(0, 24px) scale(.85)',
      top: '5px',
      left: 0,
    },
    '& .MuiFilledInput-input': {
      padding: '15px 12px 15px 0px',
    },
  },
  textLabel: { margin: '12px 0px 3px 5px' },
}));

const Template = ({
  closeDrawer,
  closeParentDrawer,
  isOriginCreateTemplate,
  reloadTemplatesViewData,
  selectedTemplate,
}) => {
  const classes = useStyles();
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [confirmationMode, setConfirmationMode] = useState(null);
  const [validationErrorMessage, setValidationErrorMessage] = useState({});

  const closeAllDrawers = isOriginCreateTemplate
    ? closeParentDrawer
    : closeDrawer;

  const { isFetchingMergeTags, mergeTags } = useMergeTags(
    MERGE_TAG_TYPES.PROSPECT
  );

  const {
    archiveTemplate,
    clearErrorMessage,
    contentType,
    deleteTemplate,
    errorMessage,
    handleDuplicate,
    hasTemplateChanged,
    isEditing,
    isLoading,
    message,
    mode,
    onFormGoBack,
    saveTemplate,
    setIsEditing,
    status,
    subject,
    templateName,
    updateTemplate,
  } = useTemplate({
    closeAllDrawers,
    reloadTemplatesViewData,
    selectedTemplate,
    closeParentDrawer,
  });

  const clearValidationErrorMessage = (field) => {
    setValidationErrorMessage((prevState) => {
      const updatedMessages = { [field]: '' };

      return { ...prevState, ...updatedMessages };
    });
  };

  const handleCancel = closeParentDrawer || closeDrawer;

  const handleFormGoBack = () =>
    onFormGoBack(isOriginCreateTemplate, closeDrawer);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    updateTemplate({ [name]: value });
  };

  const handleOnChangeMessage = (value) => {
    if (validationErrorMessage.message) {
      clearValidationErrorMessage(TEMPLATE.MESSAGE);
    }
    updateTemplate({ [TEMPLATE.MESSAGE]: value });
  };

  const handleIconButtonOnClick = () => {
    setConfirmationMode(CONFIRMATION_MODE_FOR_STATUS[status]);
  };

  const handleSaveTemplate = (handledConfirmationMode) => {
    const status =
      handledConfirmationMode === CONFIRMATION_MODES.SAVE ||
      handledConfirmationMode === CONFIRMATION_MODES.ACTIVATE
        ? STATUS.LIVE
        : STATUS.DRAFT;

    saveTemplate(status);
  };

  const handleConfirmation = () => {
    const handledConfirmationMode = confirmationMode;
    setConfirmationMode(null);

    switch (handledConfirmationMode) {
      case CONFIRMATION_MODES.BACK:
        handleFormGoBack();
        break;

      case CONFIRMATION_MODES.CANCEL:
        handleCancel();
        break;

      case CONFIRMATION_MODES.DELETE_ARCHIVE:
      case CONFIRMATION_MODES.DELETE_DRAFT:
        deleteTemplate();
        break;

      case CONFIRMATION_MODES.ARCHIVE:
        archiveTemplate();
        break;

      case CONFIRMATION_MODES.DUPLICATE:
        handleDuplicate();
        break;

      case CONFIRMATION_MODES.ACTIVATE:
      case CONFIRMATION_MODES.SAVE:
      case CONFIRMATION_MODES.SAVE_DRAFT:
      case CONFIRMATION_MODES.SAVE_DRAFT_EDIT:
        handleSaveTemplate(handledConfirmationMode);
        break;

      default:
        break;
    }
  };

  const validateTemplate = () => {
    const messages = {};
    if (!templateName.trim()) {
      messages.templateName = TEMPLATE_VALIDATION_MESSAGES.TEMPLATE_NAME;
    }
    if (contentType === TEMPLATE_TABLE_CONTENT_TYPES.EMAIL && !subject.trim()) {
      messages.subject = TEMPLATE_VALIDATION_MESSAGES.SUBJECT;
    }
    if (!message.trim()) {
      messages.message = TEMPLATE_VALIDATION_MESSAGES.MESSAGE;
    }
    return messages;
  };

  const handlePreviewOnClick = () => {
    const messages = validateTemplate();
    Object.keys(messages).length > 0
      ? setValidationErrorMessage(messages)
      : setIsPreviewVisible(true);
  };

  const cardConfig = getCardConfig({
    closeDrawer,
    contentType,
    handleCancel,
    handleFormGoBack,
    hasTemplateChanged,
    isOriginCreateTemplate,
    mode,
    setConfirmationMode,
    status,
  });

  return (
    <DripCampaignCard
      closeDrawer={cardConfig.closeDrawer}
      handleBackButtonOnClick={cardConfig.backButtonHandler}
      subheader={cardConfig.subheader}
    >
      <FeatureDrawer isOpen={isPreviewVisible}>
        <Preview
          closeDrawer={() => setIsPreviewVisible(false)}
          contentType={contentType}
          isEditing={isEditing}
          message={message}
          setConfirmationMode={setConfirmationMode}
          subject={subject}
          templateName={templateName}
        />
      </FeatureDrawer>

      <LoadingOverlay open={isLoading} />

      <ErrorDialog
        closeDialog={clearErrorMessage}
        errorMessage={errorMessage}
        isOpen={Boolean(errorMessage)}
      />

      <Confirmation
        confirmationMode={confirmationMode}
        handleConfirmation={handleConfirmation}
        setConfirmationMode={setConfirmationMode}
      />
      {!isLoading && !isFetchingMergeTags && (
        <FeatureCardContent className={classes.templateCardContent}>
          {mode === MODES.FORM ? (
            <React.Fragment>
              <TextField
                fullWidth
                error={Boolean(validationErrorMessage.templateName)}
                helperText={validationErrorMessage.templateName}
                className={classes.titleInputText}
                inputProps={{
                  maxLength: 100,
                  'aria-label': TEMPLATE.TEMPLATE_NAME,
                }}
                onFocus={() => {
                  clearValidationErrorMessage(TEMPLATE.TEMPLATE_NAME);
                }}
                name={TEMPLATE.TEMPLATE_NAME}
                onChange={handleOnChange}
                placeholder={PLACE_HOLDER_TEXT.TEMPLATE_NAME}
                value={templateName}
                variant="filled"
              />

              <Text variant="caption" paragraph gutterBottom>
                {TEMPLATE_FPO_MESSAGE}
              </Text>

              <Divider />

              {contentType ===
              TEMPLATE_TABLE_CONTENT_TYPES.SMS ? (
                <SmsTemplate
                  clearValidationErrorMessage={clearValidationErrorMessage}
                  handleOnChange={handleOnChange}
                  message={message}
                  validationErrorMessage={validationErrorMessage}
                />
              ) : (
                <EmailTemplate
                  clearValidationErrorMessage={clearValidationErrorMessage}
                  handleOnChange={handleOnChange}
                  handleOnChangeMessage={handleOnChangeMessage}
                  message={message}
                  mergeTags={mergeTags}
                  subject={subject}
                  validationErrorMessage={validationErrorMessage}
                />
              )}
            </React.Fragment>
          ) : (
            <TemplatePreview
              contentType={contentType}
              message={message}
              subject={subject}
              templateName={templateName}
            />
          )}
        </FeatureCardContent>
      )}

      <TemplateActionButtons
        handleDuplicateOnClick={() => {
          setConfirmationMode(CONFIRMATION_MODES.DUPLICATE);
        }}
        handleEditOnClick={() => setIsEditing(true)}
        handleIconButtonOnClick={handleIconButtonOnClick}
        handlePreviewOnClick={handlePreviewOnClick}
        handleSaveOnClick={() => {
          setConfirmationMode(CONFIRMATION_MODES.ACTIVATE);
        }}
        isOriginCreateTemplate={isOriginCreateTemplate}
        isPreviewButtonDisabled={!hasTemplateChanged}
        mode={mode}
        status={status}
      />
    </DripCampaignCard>
  );
};

Template.propTypes = {
  closeDrawer: PropTypes.func,
  closeParentDrawer: PropTypes.func,
  isOriginCreateTemplate: PropTypes.bool,
  reloadTemplatesViewData: PropTypes.func,
  selectedTemplate: PropTypes.object,
};

export default Template;
