import { useState } from 'react';
import { dripCampaignsApi } from '../DripCampaignsApi';
import { useTablePagination } from '../../../../hooks/useTablePagination';
import PropTypes from 'prop-types';

const ROWS_PER_PAGE_OPTIONS = [25, 50, 100, 500];

const useCampaigns = ({ setErrorMessage }) => {
  const [campaignsByPage, setCampaignsByPage] = useState({});

  const {
    getPage,
    getTotalCount,
    reloadTableData,
    rowsPerPage,
    setPageIndex,
    setRowsPerPage,
    setShouldLoadPageIndex,
    setTotalCount,
    tableRef,
  } = useTablePagination();

  const reloadCampaigns = () => {
    setCampaignsByPage({});
    reloadTableData();
  };

  const shouldFetchRequestedPage = (page) => {
    const nextPage = campaignsByPage[page] || [];
    return !nextPage.length;
  };

  const fetchCampaigns = async (query) => {
    try {
      const { page, pageSize } = query;

      const queryParams = {
        page,
        pageSize,
        sortColumn: 'campaignName',
        sortDirection: 'asc',
      };

      const { campaigns = [], totalCampaignCount = 0 } =
        await dripCampaignsApi.getCampaigns(queryParams);

      setTotalCount(totalCampaignCount);
      setCampaignsByPage((prevState) => ({
        ...prevState,
        [page]: campaigns,
      }));

      return campaigns;
    } catch (error) {
      setErrorMessage(error.message);
      return [];
    }
  };
  const getTableData = async (query) => {
    const { pageSize } = query;
    const page = getPage(query);
    const hasRowsPerPageChanged = pageSize !== rowsPerPage;

    if (hasRowsPerPageChanged) {
      setRowsPerPage(pageSize);
      setCampaignsByPage({});
    }

    const shouldFetchNewData =
      hasRowsPerPageChanged || shouldFetchRequestedPage(page);

    const campaigns = shouldFetchNewData
      ? await fetchCampaigns(query)
      : campaignsByPage[page];

    return {
      data: campaigns.map((campaign) => ({ ...campaign })),
      page,
      totalCount: getTotalCount(),
    };
  };

  return {
    getTableData,
    reloadCampaigns,
    rowsPerPage,
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS,
    setPageIndex,
    setShouldLoadPageIndex,
    tableRef,
    totalCampaignCount: getTotalCount(),
  };
};

useCampaigns.propTypes = {
  setErrorMessage: PropTypes.func,
};

export default useCampaigns;
