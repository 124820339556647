import React, { useState } from 'react';
import { AddCompanySource } from './CompanySourceModal';
import CompanySourcesList from './CompanySourcesList';
import { getCompanySourcesMap } from './utils';

import './_CompanySourcesTab.scss';
import { useAppContext } from '../../../Context/AppContext';

const CompanySourcesTab = ({ companySources, setCompanySources }) => {
  const [companySourcesError, setCompanySourcesError] = useState();

  const { isInternalMode } = useAppContext();

  const resetCompanySources = () => setCompanySources();

  return (
    <div className="company-sources-tab" data-testid="company-sources-tab">
      {isInternalMode && (
        <div className="add-company-source">
          <AddCompanySource
            onSubmit={resetCompanySources}
            sourceMap={getCompanySourcesMap(companySources)}
          />
        </div>
      )}

      <CompanySourcesList
        companySources={companySources}
        errorMessage={companySourcesError}
        setCompanySources={setCompanySources}
        setErrorMessage={setCompanySourcesError}
      />
    </div>
  );
};

export default CompanySourcesTab;
