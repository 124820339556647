import React from 'react';
import { Text } from '@knockrentals/knock-shared-web';
import { QuickReplyMessage } from './QuickReplyMessage';

export const getColumns = () => [
  {
    align: 'left',
    cellStyle: {
      minWidth: '260px',
      maxWidth: '260px',
      whiteSpace: 'nowrap',
    },
    render: (rowData) => <Text noWrap>{rowData.title}</Text>,
    title: 'Reply Title',
    width: '260px',
  },
  {
    align: 'left',
    cellStyle: {
      minWidth: '500px',
      maxWidth: '500px',
    },
    title: 'Message',
    width: '500px',
    render: (rowData) => (
      <QuickReplyMessage email={rowData.email} sms={rowData.sms} />
    ),
  },
  {
    align: 'left',
    title: 'Properties',
    field: 'enabledPropertiesCount',
    width: '100px',
  },
];
