import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalContent } from '@knockrentals/knock-react';
import {
  FacebookProviderLogin,
  LegalDisclaimer,
  ErrorMessage,
} from '../components';
import IntegrationsAPI from '../IntegrationsAPI';
import * as _ from 'lodash';
import classnames from 'classnames';

import './_addIntegrationForm.scss';

class AddFacebookIntegration extends Component {
  state = {
    error: null,
    fbAccessToken: null,
    fbUserId: null,
    fbPages: null,
    fbPageMapping: null,
  };

  render() {
    const { fbPages } = this.state;
    const showLogin = !fbPages;
    const modalContentClassNames = classnames({
      'facebook-integration-content': true,
      'max-width-500': showLogin, // only use for Login view
    });

    return (
      <Modal className='add-integration-form'>
        <ModalHeader>
          <span className='actionable pull-right mTop5' onClick={this.cancel}>
            <i className='fa fa-times' />
          </span>
          <h3>Add Facebook Integration</h3>
        </ModalHeader>
        <ModalContent>
          <div className={modalContentClassNames}>
            { showLogin ? this.renderFacebookLogin() : this.renderFacebookPages() }
          </div>
        </ModalContent>
      </Modal>
    );
  }

  renderFacebookLogin() {
    const { error } = this.state;
    return (
      <form onSubmit={this.submit} autoComplete='off'>
        <div>
          <FacebookProviderLogin
            onResponse={this.handleFacebookLoginSuccess}
            onError={this.handleFacebookLoginError}
          />
        </div>

        { error && <ErrorMessage message={error} /> }

        <div className='legal-disclaimer-wrapper'>
          <LegalDisclaimer />
          <small>
            <a href="#" onClick={this.cancel}>
              Cancel
            </a>
          </small>
        </div>
      </form>
    );
  }

  renderFacebookPages() {
    const { fbPages } = this.state;
    const hasNoPages = !fbPages || !fbPages.length;
    const tasksIncludes = (tasks) => (
      _.includes(tasks, 'ANALYZE') &&
      _.includes(tasks, 'ADVERTISE') &&
      _.includes(tasks, 'MODERATE') &&
      _.includes(tasks, 'CREATE_CONTENT') &&
      _.includes(tasks, 'MANAGE')
    );

    const PageTableHead = ({ titleText }) => (
      <thead>
        <tr>
          <th>{ titleText }</th>
          <th />
          <th />
        </tr>
      </thead>);

    const PageTableButton = ({ text, buttonType='primary', iconType='download', onClick }) => (
      <button
        className={`btn-sm btn-${buttonType}`}
        onClick={onClick}
        type='button'
      >
        <i className={`fa fa-${iconType}`} /> { text }
      </button>);

    const LinkCell = ({ facebookPageId }) => (
      <td>
        <a
          href={`//facebook.com/${facebookPageId}`}
          target='_blank'
        >
          <PageTableButton
            text='Open page'
            buttonType='primary'
            iconType='external-link'
          />
        </a>
      </td>);

    const NameCell = ({ facebookPageName }) => (
      <td>
        <i className='fa fa-star' /> { facebookPageName }
      </td>);

    const SubscribeToPageCell = ({ tasks, facebookPageId, accessToken }) => (
      <td>
        {
          tasksIncludes(tasks) &&
            <PageTableButton
              text='Integrate'
              buttonType='success'
              iconType='download'
              onClick={this.subscribeToPage(facebookPageId, accessToken)}
            />
        }
      </td>);

    const PageTableBody = ({ facebookPages }) => (
      <tbody>
      {
        facebookPages && facebookPages.map((page) => {
          const { id, name, tasks, access_token } = page;
          return (
            <tr key={`renderFacebookPages-${id}-${access_token || 'access_token'}`}>
              <NameCell
                facebookPageName={name}
              />
              <LinkCell
                facebookPageId={id}
              />
              <SubscribeToPageCell
                tasks={tasks}
                facebookPageId={id}
                accessToken={access_token}
              />
            </tr>);
        })
      }
    </tbody>);

  const NoPagesMessage = ({ message }) => (
    <div className='row-null'>
      <i className='fa fa-frown-o' /> { message }
    </div>);

    return (
      <Fragment>
        <table className="wire-frame-table">
          <PageTableHead titleText='Facebook Pages' />
          <PageTableBody facebookPages={fbPages} />
        </table>
        { hasNoPages && <NoPagesMessage message='No pages to show for this Facebook user' /> }
      </Fragment>
    );
  }

  subscribeToPage = (pageId, pageAccessToken) => () => {
    this.props.onAddIntegration({
      facebook_page_id: pageId,
      page_access_token: pageAccessToken,
      user_access_token: this.state.fbAccessToken,
    });
  }

  handleFacebookLoginSuccess = (payload) => {
    const fbAccessToken = payload.tokenDetail.accessToken;
    const fbUserId = payload.tokenDetail.userID;

    this.setState({ error: null, fbAccessToken, fbUserId });

    IntegrationsAPI.getFacebookPages(fbAccessToken).then(response => {
      this.setState({ fbPages: response.facebook_payload.data });
    });
  }

  handleFacebookLoginError = (payload) => {
    this.setState({ error: payload.toString() });
  }

  submit = (event) => {
    event.preventDefault();
  }

  cancel = () => {
    this.props.onCancelAddIntegration();
  }
}

export default AddFacebookIntegration;
