import React from 'react';
import PropTypes from 'prop-types';
import { InputCheckbox, Text, Tooltip } from '@knockrentals/knock-shared-web';
import TimingLabel from './TimingLabel';

export const NO_DELAY_LABEL_TEXT = 'Immediately on';

const NoDelayCheckbox = ({
  actionTrigger,
  clearErrorMessage,
  disabled,
  handleNoDelayOnChange,
  hasError,
  hasNoDelay = false,
  isFormMode,
  tooltipText,
}) => (
  <React.Fragment>
    {isFormMode && (
      <Text variant="body2" color="textPrimary">
        or
      </Text>
    )}

    <Tooltip title={tooltipText} disableHoverListener={!disabled}>
      <span>
        <InputCheckbox
          color="primary"
          disabled={disabled}
          checked={hasNoDelay}
          label={
            <TimingLabel
              actionTrigger={actionTrigger}
              hasError={hasError}
              labelText={NO_DELAY_LABEL_TEXT}
            />
          }
          onChange={handleNoDelayOnChange}
          onFocus={clearErrorMessage}
        />
      </span>
    </Tooltip>
  </React.Fragment>
);

NoDelayCheckbox.propTypes = {
  actionTrigger: PropTypes.string,
  clearErrorMessage: PropTypes.func,
  disabled: PropTypes.bool,
  handleNoDelayOnChange: PropTypes.func,
  hasError: PropTypes.bool,
  hasNoDelay: PropTypes.bool,
  isFormMode: PropTypes.bool,
  tooltipText: PropTypes.string,
};

export default NoDelayCheckbox;
