import React from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Text,
} from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  companyAccordion: {
    maxWidth: '1110px',
    minWidth: '768px',
  },
  templateSummary: {
    '&.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '46px',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      marginBottom: 0,
    },
  },
});

export const propertyCountText = (count) =>
  `${count} ${count === 1 ? 'Property' : 'Properties'}`;

export const templateCountText = (count) =>
  `${count} ${count === 1 ? 'Template' : 'Templates'}`;

const CompanyTemplateTypeAccordion = ({
  children,
  title,
  propertyCount = 0,
  templateCount = 0,
}) => {
  const classes = useStyles();
  return (
    <Accordion
      className={classes.companyAccordion}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary className={classes.templateSummary}>
        <div className="company-template-accordion-summary">
          <Text variant="subtitle1">{title}</Text>

          <Text color="secondary" variant="body2">
            {`${templateCountText(templateCount)} - ${propertyCountText(
              propertyCount
            )}`}
          </Text>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <div className="accordion-div">{children}</div>
      </AccordionDetails>
    </Accordion>
  );
};

CompanyTemplateTypeAccordion.propTypes = {
  children: PropTypes.node,
  propertyCount: PropTypes.number,
  templateCount: PropTypes.number,
  title: PropTypes.string,
};

export default CompanyTemplateTypeAccordion;
