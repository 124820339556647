import React from 'react';

import { Text, ThemeProvider } from '@knockrentals/knock-shared-web';
import { CompanyTemplatesTooltip } from './CompanyTemplatesTooltip';
import { TemplateTabs } from './TemplateTabs';
import { useFeatureFlagContext } from '../../../Context/FeatureFlagContext';
import './_CompanyTemplatesPage.scss';

export const DESCRIPTION = 'Update your email response messaging';

const CompanyTemplatesPage = () => {
  const { isCompanyTemplatesEnabled } = useFeatureFlagContext();

  return (
    <ThemeProvider>
      <section className="company-templates-page">
        <Text variant="h6">
          Marketing Automation
          {isCompanyTemplatesEnabled && <CompanyTemplatesTooltip />}
        </Text>

        <div className="company-templates-page-description">
          <Text variant="body1">{DESCRIPTION}</Text>
        </div>

        <TemplateTabs />
      </section>
    </ThemeProvider>
  );
};

export default CompanyTemplatesPage;
