import React, { useEffect, useState } from 'react';
import { CompanySourcesAccordion } from './CompanySourcesAccordion';
import { ErrorAlert } from './ErrorAlert';
import { normalizeCompanySources } from './utils';
import { ERROR_MESSAGES } from './constants';
import SourcesAPI from '../SourcesAPI';

const CompanySourcesList = ({
  companySources = [],
  errorMessage,
  setCompanySources,
  setErrorMessage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorSeverity, setErrorSeverity] = useState('');

  const fetchCompanySources = async () => {
    try {
      const { marketing_sources = [] } =
        await SourcesAPI.getCompanyMarketingSources();
      if (!marketing_sources.length) {
        setErrorSeverity('info');
        setErrorMessage(ERROR_MESSAGES.COMPANY_SOURCES_NOT_FOUND);
      }
      return marketing_sources;
    } catch (_e) {
      setErrorSeverity('error');
      setErrorMessage(ERROR_MESSAGES.COMPANY_SOURCES);
      return [];
    }
  };

  const handleFetchCompanySources = async () => {
    setIsLoading(true);
    const companySources = await fetchCompanySources();
    setCompanySources(normalizeCompanySources(companySources));
    setIsLoading(false);
  };

  useEffect(() => {
    const shouldFetchCompanySources =
      !companySources.length > 0 && !isLoading && !errorMessage;

    if (shouldFetchCompanySources) {
      handleFetchCompanySources();
    }
  }, [companySources, handleFetchCompanySources, isLoading, errorMessage]);

  return (
    <div className="company-sources-list" data-testid="company-sources-list">
      {/* TODO - consult with UX for loading state */}
      {isLoading && <div data-testid="loading-sources-indicator" />}

      <ErrorAlert isVisible={!!errorMessage} alertMessage={errorMessage} severity={errorSeverity} />

      {companySources && companySources.length > 0 && (
        <CompanySourcesAccordion companySources={companySources} />
      )}
    </div>
  );
};

export default CompanySourcesList;
