import React from 'react';
import LoginForm from './LoginForm';
import LegalDisclaimer from '../LegalDisclaimer';
import Button from '../../Button';
import PropTypes from 'prop-types';

const RuledText = ({ children }) => {
  const wrapperStyles = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '15px',
    width: '100%',
  };
  const hrStyles = {
    flexGrow: 1,
    verticalAlign: 'middle',
    marginBottom: '15px',
    margin: '15px',
    opacity: 0.5,
  };
  const RuledLine = <hr style={hrStyles} />;

  return (
    <div style={wrapperStyles} >
      { RuledLine }
      <span>
        { children }
      </span>
      { RuledLine }
    </div>
  );
};

const InstructionText = ({ children }) => (
  <p
    style={{
      textAlign: 'center',
    }}
  >
    { children }
  </p>
);

const ChooseAction = (props) => {
  const {
    onSuccess,
    onError,
    error,
    showRemoveView,
  } = props;

  const styles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  };

  return (
    <div style={styles}>
      <InstructionText>
        Log in to Add or Modify Integrations:
      </InstructionText>

      <LoginForm
        onSuccess={onSuccess}
        onError={onError}
        error={error}
      />

      <RuledText>
        or
      </RuledText>

      <Button
        className='danger'
        iconType='trash'
        onClick={showRemoveView}
        style={{
          minWidth: '75%',
          fontSize: '12px',
        }}
      >
        Click here to choose existing integrations to remove
      </Button>

      <div className='legal-disclaimer-wrapper'>
        <LegalDisclaimer />
      </div>
    </div>
  );
};

ChooseAction.propTypes = {
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  error: PropTypes.string,
  showRemoveView: PropTypes.func,
};

export default ChooseAction;