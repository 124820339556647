import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  makeStyles,
  CardActions,
} from '@knockrentals/knock-shared-web';
import DeleteIcon from './DeleteIcon';

const useStyles = makeStyles((theme) => ({
  quickReplyActions: {
    padding: '18px 22px',
    boxShadow: '0px -1px 0px rgba(24, 39, 76, 0.25)',
    zIndex: 1,
  },
  quickReplyDeleteBtn: {
    color: theme.palette.error.dark,
    lineHeight: '18px',
  },
  actionButton: {
    width: '80px',
    marginRight: '10px',
  },
  CardActions: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    display: 'flex',
  },
}));

const QuickReplyActions = ({
  handleSaveOnClick,
  handleCancelOnClick,
  handleDeleteOnClick,
  isDeleteButtonVisible,
  isSaveButtonDisabled,
}) => {
  const classes = useStyles();

  return (
    <CardActions className={classes.quickReplyActions}>
      {isDeleteButtonVisible && (
        <Button
          className={classes.quickReplyDeleteBtn}
          startIcon={<DeleteIcon />}
          variant="text"
          onClick={handleDeleteOnClick}
        >
          Delete
        </Button>
      )}

      <div className={classes.CardActions}>
        <Button
          className={classes.actionButton}
          color="primary"
          onClick={handleCancelOnClick}
          variant="outlined"
        >
          Cancel
        </Button>

        <Button
          className={classes.actionButton}
          color="primary"
          onClick={handleSaveOnClick}
          disabled={isSaveButtonDisabled}
        >
          Save
        </Button>
      </div>
    </CardActions>
  );
};

QuickReplyActions.propTypes = {
  handleSaveOnClick: PropTypes.func,
  handleCancelOnClick: PropTypes.func,
  handleDeleteOnClick: PropTypes.func,
  isDeleteButtonVisible: PropTypes.bool,
  isSaveButtonDisabled: PropTypes.bool,
};

export default QuickReplyActions;
