import {
  ACCESS_SETTING_CONFIG,
  API_SETTINGS_MAP,
  CONFIGURABLE_EXTERNAL_SETTINGS,
  SETTINGS_MAP,
} from './constants';

export const getEnabledRoleNames = (updatedConfigurableRoles) =>
  Object.entries(updatedConfigurableRoles).reduce(
    (enabledList, [roleName, settings]) => {
      if (settings.isChecked) {
        enabledList.push(roleName);
      }
      return enabledList;
    },
    []
  );

const getSettingConfig = (settingName, enabledRoles) => {
  const { label, configurableRoles } = ACCESS_SETTING_CONFIG[settingName];
  const roleSettings = configurableRoles.reduce((roleMap, role) => {
    roleMap[role] = {
      isChecked: enabledRoles.includes(role),
    };
    return roleMap;
  }, {});

  return {
    label,
    settingName,
    configurableRoles: roleSettings,
  };
};

export const normalizeAccessSettings = ({
  featureFlagHiddenSettings = {},
  isFullAccessView,
  settings,
}) => {
  const accessSettings = Object.entries(settings).reduce(
    (settingsList, [settingName, enabledRoles]) => {
      const normalizedName = API_SETTINGS_MAP[settingName];
      const isHidden = featureFlagHiddenSettings[normalizedName];
      const shouldRenderSetting =
        normalizedName &&
        !isHidden &&
        (isFullAccessView || CONFIGURABLE_EXTERNAL_SETTINGS[normalizedName]);

      if (shouldRenderSetting) {
        const settingConfig = getSettingConfig(normalizedName, enabledRoles);
        settingsList.push(settingConfig);
      }
      return settingsList;
    },
    []
  );

  return accessSettings;
};

export const normalizeAccessSettingsForRole = (settings, role) => {
  const accessSettings = Object.entries(settings).reduce(
    (settingsMap, [settingName, roles]) => {
      const normalizedName = API_SETTINGS_MAP[settingName];
      if (normalizedName) {
        settingsMap[normalizedName] = roles.includes(role);
      }
      return settingsMap;
    },
    {}
  );

  return accessSettings;
};

export const transformAccessSettings = (updatedSettings) => {
  const transformedSettings = Object.entries(updatedSettings).reduce(
    (transformedMap, [settingName, roles]) => {
      const transformedName = SETTINGS_MAP[settingName];
      transformedMap[transformedName] = roles;
      return transformedMap;
    },
    {}
  );

  return { settings: transformedSettings };
};
