import React from 'react';
import PropTypes from 'prop-types';
import { ListItemText, Switch, Text } from '@knockrentals/knock-shared-web';

const TemplateDetails = ({
  assignedPropertiesCount,
  handleOnChangeIsEnabled,
  isDefault = false,
  isEnabled = false,
  propertyCount,
  templateName,
}) => (
  <ListItemText disableTypography>
    <div className={'template-row'}>
      <Text variant="subtitle1">{templateName}</Text>

      <div className={'template-row-data'}>
        {!isDefault && (
          <Switch
            aria-label="enable company template"
            color="primary"
            checked={isEnabled}
            onChange={(e) => {
              handleOnChangeIsEnabled(e.target.checked);
            }}
          />
        )}

        <div className="enabled-property-ratio">
          <Text align="center" color="secondary" variant="body2">
            {`${assignedPropertiesCount}/${propertyCount}`}
          </Text>
        </div>
      </div>
    </div>
  </ListItemText>
);

TemplateDetails.propTypes = {
  assignedPropertiesCount: PropTypes.number,
  handleOnChangeIsEnabled: PropTypes.func,
  isEnabled: PropTypes.bool,
  isDefault: PropTypes.bool,
  propertyCount: PropTypes.number,
  templateName: PropTypes.string,
};

export default TemplateDetails;
