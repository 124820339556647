import React from 'react';

import {
  makeStyles,
  Text,
} from '@knockrentals/knock-shared-web';

import { CloudLoadingImage } from './images';
import './_SSDAPage.scss';


const useStyles = makeStyles({
  kdcInfoDescription: {
    color: '#999999',
  },
});


const SSDAError = () => {
  const classes = useStyles();

  return (
    <div className="knock-data-cloud-setup">
      <div className="setup-image-container">
        <span>
          <CloudLoadingImage />
        </span>
                
        <Text align="center" color="textSecondary" variant="h3">
          Error
        </Text>
      </div>
    </div>
  );
};

export default SSDAError;
