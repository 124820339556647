import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@material-ui/core';
import {
  InputCheckbox,
  List,
  ListItem,
  makeStyles,
  Text,
} from '@knockrentals/knock-shared-web';

export const CHANNEL_LIST = ["Voice", "SMS", "Chat"];

const useStyles = makeStyles({
  propertyListSubheader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  messageItem: {
    justifyContent: 'center',
  },
});

const VirtualAgentGenAIChannelList = ({
  selectedChannels,
  setSelectedChannels
}) => {
  const classes = useStyles();
  const selectedCount = selectedChannels.length;
  const isSelectAllChecked =
    selectedCount === CHANNEL_LIST.length;
  const isSelectAllIndeterminate = selectedCount > 0 && !isSelectAllChecked;

  const handleSelectChannel = (channel) => (e) => {
    const { checked } = e.target;
    setSelectedChannels((prevSelectedChannels) => {
      if (checked) {
        return [...prevSelectedChannels, channel];
      } else {
        return prevSelectedChannels.filter((selectedChannel) => selectedChannel !== channel);
      }
    });
  };

  const handleSelectAllOnChange = (e) => {
    isSelectAllIndeterminate || selectedCount === 0 ? setSelectedChannels(CHANNEL_LIST) : setSelectedChannels([]);
  };

  return (
    <Paper elevation={2}>
      <List
        disablePadding
        subheader={
          <ListItem className={classes.propertyListSubheader} divider>
            <InputCheckbox
              checked={isSelectAllChecked}
              color="primary"
              disabled={!CHANNEL_LIST.length}
              indeterminate={isSelectAllIndeterminate}
              label="Channels"
              onChange={handleSelectAllOnChange}
            />
            <Text color="secondary" variant="body2">
              {`${selectedCount || 0} / ${CHANNEL_LIST.length}`}
            </Text>
          </ListItem>
        }
      >
        {CHANNEL_LIST.map((channel) => {
          return (
            <ListItem divider key={channel}>
              <InputCheckbox
                checked={selectedChannels.includes(channel) || false}
                color="primary"
                label={channel}
                onChange={handleSelectChannel(channel)}
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

VirtualAgentGenAIChannelList.propTypes = {
  selectedChannels: PropTypes.array,
  setSelectedChannels: PropTypes.func,
};

export default VirtualAgentGenAIChannelList;
