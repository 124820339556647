import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Checkbox, FormLabel, MenuItem } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { IconButton, makeStyles, Menu } from '@knockrentals/knock-shared-web';

export const ARIA_LABEL_SUFFIX = 'multi select checkbox';
export const LIST = 'list';
export const PAGE = 'page';

export const ALL_LIST = 'Select All in List';
export const ALL_PAGE = 'Select All on Page';

const useStyles = makeStyles((theme) => ({
  multiSelectCheckbox: {
    '&.MuiIconButton-root': { padding: 0 },
    '& .MuiSvgIcon-root': { zIndex: 'unset' },
  },
  checkboxLabel: {
    color: 'unset',
    verticalAlign: 'middle',
  },
  dropDownArrow: {
    '&.MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.secondary.dark,
    },
  },
}));

const getInListText = (count) => `${ALL_LIST} (${count})`;
const getOnPageText = (count) => `${ALL_PAGE} (${count})`;

const MultiSelectCheckbox = ({
  deselectAll,
  getPageItemCount,
  handleSelect,
  isChecked,
  isIndeterminate,
  label,
  stopPropagation,
  totalCount,
}) => {
  const [anchorElement, setAnchorElement] = useState(null);

  const classes = useStyles();

  const handleMenuItemOnClick = (selectionType) => () => {
    setAnchorElement(null);
    handleSelect(selectionType);
  };

  const handleOnCheckboxOnChange = (e) => {
    if (!e.target.checked || isIndeterminate) {
      deselectAll();
      return;
    }

    setAnchorElement(e.target);
  };

  const toggleMenu = (e) => {
    const { target } = e;
    if (!isChecked || isIndeterminate) {
      setAnchorElement((prevState) => (prevState ? null : target));
    }
  };

  const handlePropagation = () => {
    if (stopPropagation) {
      return (e) => {
        e.stopPropagation();
      };
    }
  };

  return (
    <span onClick={handlePropagation()}>
      <Checkbox
        aria-label={`${`${label} `}${ARIA_LABEL_SUFFIX}`}
        checked={isChecked}
        className={classes.multiSelectCheckbox}
        color="primary"
        indeterminate={isIndeterminate}
        onChange={handleOnCheckboxOnChange}
      />

      <IconButton
        className={classes.dropDownArrow}
        disableRipple
        onClick={toggleMenu}
      >
        <ArrowDropDown fontSize="large" />
      </IconButton>

      <FormLabel className={classes.checkboxLabel}>{label}</FormLabel>

      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{
          horizontal: 10,
          vertical: 'bottom',
        }}
        aria-label="multi select menu"
        getContentAnchorEl={null}
        onClose={() => setAnchorElement(null)}
        open={Boolean(anchorElement)}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
      >
        <MenuItem onClick={handleMenuItemOnClick(PAGE)} value={PAGE}>
          {getOnPageText(getPageItemCount())}
        </MenuItem>

        <MenuItem onClick={handleMenuItemOnClick(LIST)} value={LIST}>
          {getInListText(totalCount)}
        </MenuItem>
      </Menu>
    </span>
  );
};

MultiSelectCheckbox.propTypes = {
  deselectAll: PropTypes.func,
  getPageItemCount: PropTypes.func,
  handleSelect: PropTypes.func,
  isChecked: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  label: PropTypes.string,
  pageItemCount: PropTypes.number,
  stopPropagation: PropTypes.bool,
  totalCount: PropTypes.number,
};

export default MultiSelectCheckbox;
