import { getDefaultTableOptions } from '../../../../../../Services/SharedService';

const MAX_DEFAULT_PAGESIZE = 500;
const defaultTableOptions = getDefaultTableOptions();

export const getTableOptions = (
  pageSize,
  pageSizeOptions,
  isOriginCreateCampaign
) => ({
  ...defaultTableOptions,
  actionsColumnIndex: -1,
  headerStyle: {
    ...defaultTableOptions.headerStyle,
    fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
    lineHeight: '24px',
    padding: '16px 24px 16px 16px',
    whiteSpace: 'nowrap',
  },
  paging: !isOriginCreateCampaign,
  emptyRowsWhenPaging: false,
  pageSize: isOriginCreateCampaign ? MAX_DEFAULT_PAGESIZE : pageSize,
  pageSizeOptions,
  showFirstLastPageButtons: false,
  rowStyle: {
    minWidth: '1048px',
  },
});
