import { apiRemote } from '../../Services/RemoteService';

class AttributesAPI {
  static getPropertyAttributeTypes() {
    return apiRemote.get(`admin/property-attributes/types`).then(response => response.json());
  }

  static updatePropertyAttributeType(attributeTypeId, attributeType) {
    return AttributesAPI.postPropertyAttributeType(attributeType, attributeTypeId);
  }

  static postPropertyAttributeType(attributeType, attributeTypeId = null) {
    return apiRemote
      .post(`admin/property-attributes/types`, {
        id: attributeTypeId,
        attribute_type: attributeType,
      })
      .then(response => response.json());
  }

  static deletePropertyAttributeType(attributeTypeId) {
    return apiRemote.delete(`admin/property-attributes/types/${attributeTypeId}`);
  }

  static getPropertyAttributes() {
    return apiRemote.get(`admin/property-attributes`).then(response => response.json());
  }

  static updatePropertyAttribute(attributeId, attributeTypeValue) {
    return apiRemote
      .put(`admin/property-attributes/${attributeId}`, {
        id: attributeId,
        attribute_type_value: attributeTypeValue,
      })
      .then(response => response.json());
  }

  static createPropertyAttribute(propertyId, attributeTypeId, attributeTypeValue) {
    return apiRemote
      .post(`admin/property-attributes`, {
        property_id: propertyId,
        attribute_type_id: attributeTypeId,
        attribute_type_value: attributeTypeValue,
      })
      .then(response => response.json());
  }

  static deletePropertyAttribute(attributeTypeId) {
    return apiRemote.delete(`admin/property-attributes/${attributeTypeId}`);
  }

  static getSourceAttributes() {
    return apiRemote.get(`admin/source-attributes`).then(response => response.json());
  }

  static updateSourceAttribute(sourceAttributeId, marketingSourceId, attributeValue) {
    return AttributesAPI.postSourceAttribute(attributeValue, marketingSourceId, sourceAttributeId);
  }

  static postSourceAttribute(attributeValue, marketingSourceId, sourceAttributeId = null) {
    return apiRemote
      .post(`admin/source-attributes`, {
        id: sourceAttributeId,
        marketing_source_id: marketingSourceId,
        attribute: attributeValue,
      })
      .then(response => response.json());
  }

  static deleteSourceAttribute(sourceAttributeId) {
    return apiRemote.delete(`admin/source-attributes/${sourceAttributeId}`);
  }
}

export default AttributesAPI;
