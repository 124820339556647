export const parseAmount = (currencyString = '') => {
  const filteredString = currencyString.replace(/[^\d.]/g, '');

  if (!filteredString) {
    return '';
  }

  const values = filteredString.split('.');
  const [dollars, ...cents] = values;
  const decimal = values.length > 1 ? '.' : '';

  const formattedCents = cents.join('').substring(0, 2);
  const formattedDollars = Number(dollars).toString().substring(0, 10);

  return `${formattedDollars}${decimal}${formattedCents}`;
};

export const formatAmount = (value) => {
  const formattedValue = parseAmount(String(value ?? ''));
  return formattedValue ? `$${formattedValue}` : '';
};

export const formatUSDollar = (amount = 0) =>
  new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  }).format(amount);

export const padAmount = (value) => {
  const parsedValue = parseAmount(String(value ?? ''));

  if (!Number(parsedValue)) {
    return '';
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    useGrouping: false,
  }).format(parsedValue);
};
