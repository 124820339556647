import textareaCaret from 'textarea-caret';

export const getCaretCoordinates = (inputRef) => {

  const caretCoordinates = inputRef ? textareaCaret(inputRef, inputRef.selectionEnd) : { top: 0, left: 0, height: 0 };
  const rect = inputRef ? inputRef.getBoundingClientRect() : { top: 0, left: 0 };

  return {
    top: rect.top + caretCoordinates.top,
    left: rect.left + caretCoordinates.left
  };
};
