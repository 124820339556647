import React from 'react';
import PropTypes from 'prop-types';

import { InputCheckbox } from '@knockrentals/knock-shared-web';

const PropertyCheckbox = ({ isChecked = false, updateIsEnabled }) => {
  return (
    <InputCheckbox
      checked={isChecked}
      color="primary"
      onChange={(e) => updateIsEnabled(e.target.checked)}
      inputProps={{ 'aria-label': 'enabled property checkbox' }}
    />
  );
};

PropertyCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  updateIsEnabled: PropTypes.func,
};

export default PropertyCheckbox;
