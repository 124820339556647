import React, { Component } from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter } from '@knockrentals/knock-react';

class TeamImport extends Component {
  state = {
    importInProgress: false,
  };

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.importFileAdded = this.importFileAdded.bind(this);
  }

  importFileAdded(fileInput) {
    this.fileInput = fileInput;
  }

  submit(event) {
    event.preventDefault();

    this.setState({ importInProgress: true });

    this.props.onImportTeams(this.fileInput.files[0]).then(() => {
      this.setState({ importInProgress: false });
    });
  }

  cancel(event) {
    event.preventDefault();
    this.props.onCancel();
  }

  renderImportErrors() {
    if (!this.props.importErrors) {
      return null;
    }

    const invalidMasterUsernames = this.props.importErrors.invalidMasterUsernames.map(username => {
      return <div>{username}</div>;
    });

    return (
      <div>
        <div>
          <strong>Invalid master usernames</strong>
          {invalidMasterUsernames}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal className="user-editor-container">
        <form onSubmit={this.submit}>
          <ModalHeader>
            <h2>Import teams</h2>
          </ModalHeader>
          <ModalContent>
            <div className="knock-react-flex">
              <div>
                <strong>Team import CSV: </strong>
              </div>
              <div>
                <input
                  type="file"
                  className="knock-react-input"
                  name="teamName"
                  ref={this.importFileAdded}
                />
              </div>
            </div>
            {this.renderImportErrors()}
          </ModalContent>
          {this.state.importInProgress ? (
            <ModalFooter>
              <button className="knock-react-button" disabled>
                Saving...
              </button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <button className="knock-react-button">Save</button>
              <button className="knock-react-button" onClick={this.cancel.bind(this)}>
                Cancel
              </button>
            </ModalFooter>
          )}
        </form>
      </Modal>
    );
  }
}

export default TeamImport;
