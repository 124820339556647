import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { Modal, ModalHeader, ModalContent, ModalFooter , PageContainer} from '@knockrentals/knock-react';
import moment from 'moment';
import './_groupSettings.scss';

class CustomFiltersDialog extends Component {
  constructor(props) {
    super(props);

    const defaultFilter = {
      'start_date': moment().subtract(14, 'days').format('YYYY/MM/DD'),
      'end_date': moment().format('YYYY/MM/DD'),
      'is_default': false,
      'name': ''
    };

    this.state = {
      loading: false,
      filters: props.settings.custom_prospect_report_filter,
      selectedFilter: props.selectedIndex !== -1 ?
          props.settings.custom_prospect_report_filter[props.selectedIndex] : defaultFilter,
      validStartDate: true,
      validEndDate: true,
      validName: true
    };
  }

  hideModal = () => {
    this.props.closeHandler();
  };

  onChangeStartDate(newDate) {
    const updatedFilter = {...this.state.selectedFilter, start_date: newDate.format('YYYY/MM/DD')};
    this.setState({selectedFilter: updatedFilter, validStartDate: true});
  }

  onChangeEndDate(newDate) {
    const updatedFilter = {...this.state.selectedFilter, end_date: newDate.format('YYYY/MM/DD')};
    this.setState({selectedFilter: updatedFilter});
  }

  onChangeRawBlur(event, stateParam) {
    if (!moment(event.target.value).isValid()) {
      this.setState({[stateParam]: false});
    }
    else {
      this.setState({[stateParam]: true});
    }
  }

  onChangeName(event) {
    const newName = event.target.value;
    for (let i=0; i < this.props.settings.custom_prospect_report_filter.length; i++) {
      if (i === this.props.selectedIndex) {
        continue;
      } else {
        if (newName === this.props.settings.custom_prospect_report_filter[i]['name']) {
          const updatedFilter = {...this.state.selectedFilter, name: newName};
          this.setState({selectedFilter: updatedFilter, validName: false});
          return;
        }
      }
    }

    const updatedFilter = {...this.state.selectedFilter, name: newName};
    this.setState({selectedFilter: updatedFilter, validName: true});
  }

  onSubmit = () => {
    const numFilters = this.props.settings.custom_prospect_report_filter.length;
    const index = this.props.selectedIndex === -1 ? numFilters : this.props.selectedIndex;

    let updatedFilters = [...this.state.filters];

    updatedFilters[index] = this.state.selectedFilter;

    if (numFilters === 0) {
      updatedFilters[0]['is_default'] = true;
    }

    this.props.filterUpdated(updatedFilters);
    this.hideModal();
  }

  render() {
    const { loading } = this.state.loading;
    if (this.state.loading) {
      return null;
    }

    return (
      <PageContainer isLoading={loading}>
        <Modal className="custom-filters-modal">
          <ModalHeader>
            <h2>
             Add/Edit Custom Filter
            </h2>
          </ModalHeader>
          <form onSubmit={this.onSubmit}>
            <ModalContent>
              <span className="inline-block side-margin-lg">
                <div className={'modal-field-label'}>
                  <label htmlFor="filter_name">
                    <strong>
                      Display Name
                    </strong>
                  </label>
                </div>
                <input className={this.state.validName ? "knock-react-input" : "knock-react-input knock-react-input-error"}
                  value={this.state.selectedFilter.name}
                  type="text"
                  id="filter_name"
                  onChange={evt => this.onChangeName(evt)}
                  maxLength="30"
                  required
                />
                <div className={!this.state.validName ? "validation-error" : "validation-error-hidden"}>Name already in use</div>
                <div className={'modal-field-label'}>
                  <label htmlFor="start_date">
                    <strong>
                      Start Date
                    </strong>
                  </label>
                </div>
                <DatePicker
                  id="start_date"
                  className={this.state.validStartDate ? "knock-react-input" : "knock-react-input knock-react-input-error"}
                  dateFormat="YYYY/MM/DD"
                  onChange={evt => this.onChangeStartDate(evt)}
                  onBlur={evt => this.onChangeRawBlur(evt, 'validStartDate')}
                  selected={moment(this.state.selectedFilter.start_date)}
                />
                <div className={!this.state.validStartDate ? "validation-error" : "validation-error-hidden"}>Invalid start date</div>
                <div className={'modal-field-label'}>
                  <label htmlFor="start_date">
                    <strong>
                      End Date
                    </strong>
                  </label>
                </div>
                <DatePicker
                  id="end_date"
                  className={this.state.validEndDate ? "knock-react-input" : "knock-react-input knock-react-input-error"}
                  dateFormat="YYYY/MM/DD"
                  onChange={evt => this.onChangeEndDate(evt)}
                  onBlur={evt => this.onChangeRawBlur(evt, 'validEndDate')}
                  selected={moment(this.state.selectedFilter.end_date)}
                />
                <div className={!this.state.validEndDate ? "validation-error" : "validation-error-hidden"}>Invalid end date</div>
              </span>
            </ModalContent>

            <ModalFooter>
              <button className="knock-react-button" onClick={this.hideModal}>
                  Cancel
                </button>
                <button type="submit"
                    className="knock-react-button"
                    disabled={ !this.state.validStartDate || !this.state.validEndDate || !this.state.validName }
                >
                  Save
                </button>
            </ModalFooter>
          </form>
        </Modal>
      </PageContainer>
    );
  }
}

export default CustomFiltersDialog;