import React from 'react';
import PropTypes from 'prop-types';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import { TEMPLATE_TABLE_CONTENT_TYPES } from '../../../constants';

const TemplateFormatIcon = ({ contentType }) => {
  switch (contentType) {
    case TEMPLATE_TABLE_CONTENT_TYPES.EMAIL:
      return <EmailOutlinedIcon aria-label="email format icon" />;

    case TEMPLATE_TABLE_CONTENT_TYPES.SMS:
      return <SmsOutlinedIcon aria-label="sms format icon" />;

    default:
      return null;
  }
};

TemplateFormatIcon.propTypes = {
  contentType: PropTypes.string,
};

export default TemplateFormatIcon;
