import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@knockrentals/knock-shared-web';

const CompanySourceRow = ({ title, children }) => {
  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary>{title}</AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default CompanySourceRow;
