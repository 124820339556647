import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@knockrentals/knock-shared-web';
import { DeleteIcon as TrashCan } from '../../../../../Components/Icons';

const useStyles = makeStyles({
  deleteIcon: {
    height: '18px',
    width: '18px',
    '&.MuiSvgIcon-root path': {
      stroke: 'currentColor',
    },
  },
});

const DeleteIcon = () => {
  const classes = useStyles();

  return (
    <SvgIcon className={classes.deleteIcon}>
      <TrashCan />
    </SvgIcon>
  );
};

export default DeleteIcon;
