import React from 'react';

const WebsiteUrl = ({ url }) => {
    if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
        url = 'http://' + url;
    }

    return (
        <div>
            <h3>
                <a target="_blank" href={url}>
                    {url}
                </a>
            </h3>
        </div>
    );
};

export default WebsiteUrl;
