import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Collapse } from '@knockrentals/knock-react';
import PropertySelfieScanResults from './PropertySelfieScanResults';

export const selfieScanDisabledText =
  'This property does not have access to Knock Tours on the current plan. To learn more about Knock Tours and our ID Scanning solutions, please contact your account manager.';
export const defaultSuccessText =
  'Your ID has been verified. Please reach out to your leasing agents for next steps.';
export const defaultFailedText =
  'We were unable to authenticate your ID. Please try again.';

const PropertyToursSettings = ({ property }) => {
  const isSelfieScanEnabled = property.preferences.enable_selfie_scan;

  return (
    <Collapse title={property.data.location.name}>
      {isSelfieScanEnabled ? (
        <Fragment>
          <PropertySelfieScanResults
            title={'Selfie Scan Success Results Page'}
            defaultMessage={defaultSuccessText}
          />
          <PropertySelfieScanResults
            title={'Selfie Scan Failed Results Page'}
            defaultMessage={defaultFailedText}
          />
        </Fragment>
      ) : (
        <div className="tours-self-scan-disabled-container">
          {selfieScanDisabledText}
        </div>
      )}
    </Collapse>
  );
};

PropertyToursSettings.propTypes = {
  property: PropTypes.shape({
    preferences: PropTypes.shape({
      enable_selfie_scan: PropTypes.bool,
    }),
    data: PropTypes.shape({
      location: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default PropertyToursSettings;
