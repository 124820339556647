import {
  Box,
  Text,
  Tooltip,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import HelpOutline from '@material-ui/icons/HelpOutline';
import PropTypes from 'prop-types';
import React from 'react';

import TextEditor from './TextEditor';

const useStyles = makeStyles(() => ({
  intentContainer: {
    paddingBottom: 20,
  },
  inputField: {
    width: '100%',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '40px',
  },
  productNotActiveToolTip: {
    verticalAlign: 'middle',
    marginLeft: '3px',
  },
}));

const PropertyChatResponseTemplate = ({ chatResponse, onChatResponseChange, active, generatedByAi }) => {
  const classes = useStyles();

  return (
    <Box className={classes.intentContainer}>
      <div className="knock-react-flex">
        <div className="input-label-text">
        <Text variant="subtitle2" className={classes.labelContainer}>
          Chat
        </Text>
        </div>
        {!active && (
          <Tooltip title="AI Chat is not active">
              <HelpOutline
                className={classes.productNotActiveToolTip}
                fontSize="small"
              />
          </Tooltip>
        )}
      </div>
      <TextEditor content={chatResponse} onChange={onChatResponseChange} />
    </Box>
  );
};

PropertyChatResponseTemplate.propTypes = {
  chatResponse: PropTypes.string,
  onChatResponseChange: PropTypes.func,
  active: PropTypes.bool,
  generatedByAi: PropTypes.bool,
};

export default PropertyChatResponseTemplate;
