import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IconButton, makeStyles } from '@knockrentals/knock-shared-web';
import { PencilIcon } from '../../../../../Components/Icons';

const useStyles = makeStyles({
  editIcon: {
    '&.MuiSvgIcon-root path': {
      stroke: 'currentColor',
    },
  },
});

const EditButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <IconButton
      aria-label="edit company template"
      color="secondary"
      onClick={onClick}
    >
      <SvgIcon className={classes.editIcon}>
        <PencilIcon />
      </SvgIcon>
    </IconButton>
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func,
};

export default EditButton;
