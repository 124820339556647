import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Text } from '@knockrentals/knock-shared-web';

import { CreateNewButton } from '../CreateNewButton';
import { DrawerActions } from '../../DrawerActionButtons';
import { DripCampaignCard } from '../../DripCampaignCard';
import FeatureDrawer from '../../../common/FeatureDrawer';
import FeatureCardContent from '../../../common/FeatureCardContent';
import { SelectTemplateDataTable } from '../SelectTemplateDataTable';
import { Template } from '../Template';
import { TEMPLATE_SUBHEADER } from '../constants';
import { TEMPLATE_TABLE_CONTENT_TYPES } from '../../constants';

const useStyles = makeStyles({
  existingText: {
    marginTop: '24px',
  },
});

const CreateTemplate = ({
  closeDrawer,
  newTemplate,
  reloadTemplatesViewData,
}) => {
  const classes = useStyles();
  const [template, setTemplate] = useState(null);
  const createTemplateSubheader =
    newTemplate.contentType === TEMPLATE_TABLE_CONTENT_TYPES.SMS
      ? TEMPLATE_SUBHEADER.NEW_SMS
      : TEMPLATE_SUBHEADER.NEW_EMAIL;

  return (
    <DripCampaignCard
      closeDrawer={closeDrawer}
      subheader={createTemplateSubheader}
    >
      <FeatureDrawer
        disableEnforceFocus
        isOpen={Boolean(template)}
        ModalProps={{ hideBackdrop: true }}
      >
        <Template
          closeParentDrawer={closeDrawer}
          closeDrawer={() => setTemplate(null)}
          isOriginCreateTemplate
          reloadTemplatesViewData={reloadTemplatesViewData}
          selectedTemplate={template}
        />
      </FeatureDrawer>

      <FeatureCardContent>
        <CreateNewButton
          onClick={() => {
            setTemplate(newTemplate);
          }}
        />

        <Text className={classes.existingText} gutterBottom>
          Create based on existing template
        </Text>

        <SelectTemplateDataTable
          contentType={newTemplate.contentType}
          setSelectedTemplate={setTemplate}
        />
      </FeatureCardContent>

      <DrawerActions />
    </DripCampaignCard>
  );
};

CreateTemplate.propTypes = {
  closeDrawer: PropTypes.func,
  newTemplate: PropTypes.shape({
    contentType: PropTypes.oneOf([
      TEMPLATE_TABLE_CONTENT_TYPES.EMAIL,
      TEMPLATE_TABLE_CONTENT_TYPES.SMS,
    ]),
  }),
  reloadTemplatesViewData: PropTypes.func,
};

export default CreateTemplate;
