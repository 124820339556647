import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@knockrentals/knock-shared-web';

const DataTableToolBar = ({ count }) => (
  <Text variant="subtitle2">{`${count} Items`} </Text>
);

DataTableToolBar.propTypes = {
  count: PropTypes.number,
};

export default DataTableToolBar;
