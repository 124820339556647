import React from 'react';
import PropTypes from 'prop-types';
import DataTable from 'material-table';

import { getColumns } from './table-columns.jsx';
import { getTableOptions } from './table-options.js';

export const PropertiesCallIntelTable = ({
  isUpdatingFeatureSwitch,
  onUpdateTableValue,
  propertiesCallIntelData,
  selectedProperties, 
  setSelectedProperties, 
}) => {
  const handleOnChangeAll = ({ isSelected }) => {
    setSelectedProperties(Object.keys(selectedProperties).reduce((acc, propertyId) => {
      acc[propertyId] = { ...selectedProperties[propertyId], isSelected };
      return acc;
    }, {}));
  };

  const handleRowDataOnChange = (propertyId) => {
    if (!Object.keys(selectedProperties).length) return;
    setSelectedProperties({
      ...selectedProperties, 
      [propertyId]: {
        ...selectedProperties[propertyId],
        isSelected: !selectedProperties[propertyId].isSelected
      }
    });
  };

  const handleSwitchValueOnChange = (event, rowData) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.checked;
    onUpdateTableValue({ fieldName, fieldValue, rowData });
  };

  const tableColumns = getColumns({
    handleOnChangeAll,
    handleRowDataOnChange,
    handleSwitchValueOnChange,
    selectedProperties,
  });

  return (
    <div className={isUpdatingFeatureSwitch ? 'disable-switches' : ''}>
      <DataTable
        columns={tableColumns}
        data={propertiesCallIntelData.map((property) => ({ ...property }))}
        options={getTableOptions()}
        style={{ overflow: 'hidden' }}
      />
    </div>
  );
};

PropertiesCallIntelTable.propTypes = {
  isUpdatingFeatureSwitch: PropTypes.bool,
  onUpdateTableValue: PropTypes.func,
  propertiesCallIntelData: PropTypes.arrayOf(
    PropTypes.shape({
      is_scored: PropTypes.bool,
      is_standalone: PropTypes.bool,
      is_transcribed: PropTypes.bool,
      propertyName: PropTypes.string,
      propertyId: PropTypes.number,
    })
  ),
  selectedProperties: PropTypes.objectOf(
    PropTypes.shape({
      isSelected: PropTypes.bool,
      propertyName: PropTypes.string,
    })
  ),
  setSelectedProperties: PropTypes.func,
};