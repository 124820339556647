import React, { Component } from 'react';
import { WrappedPropertySources as PropertySources } from './WrappedPropertySources';
import SourcesAPI from './SourcesAPI';
import {
  PageContainer,
  Toaster,
  Alert,
  AuthenticationService,
} from '@knockrentals/knock-react';
import { CompanyPhoneRegistration } from './PhoneRegistration';

class PropertySourcesTab extends Component {
  state = {
    selectedPropertyId: 'all',
    properties: [],
    validSources: [],
    isLoading: true,
    spreadsheetUrl: null,
  };

  isInternalMode = AuthenticationService._internalMode;
  isRoleMaster = AuthenticationService.getRole() === 'master';

  isCompanyPhoneRegistrationVisible = () =>
    this.isInternalMode && this.isRoleMaster;

  componentDidMount() {
    this.loadData();
  }

  loadData(propertyIds) {
    const initPromises = [this.getPropertiesSources(propertyIds)];

    Promise.all(initPromises).then(() => {
      this.setState({ isLoading: false });
    });
  }

  getPropertiesSources(propertyIds) {
    const includeDisabled = AuthenticationService._internalMode;
    return SourcesAPI.getPropertiesSources(propertyIds, includeDisabled).then(
      (data) => {
        const mappedPropertiesData = data.property_sources_data
          .map((propertyData) => {
            return {
              id: propertyData.id,
              leasing_team_id: propertyData.leasing_team_id,
              data: propertyData.data,
            };
          })
          .sort((propertyA, propertyB) => {
            return propertyA.data.location.name > propertyB.data.location.name
              ? 1
              : -1;
          });

        this.setState({ properties: mappedPropertiesData });
      }
    );
  }

  render() {
    return (
      <PageContainer isLoading={this.state.isLoading}>
        <div className="knock-react-flex knock-react-flex-row property-sources-properties">
          <div>
            <div>Property</div>
            <select
              value={this.state.selectedPropertyId}
              onChange={this.onPropertyChanged.bind(this)}
            >
              <option value={'all'}>All</option>
              {this.renderPropertyOptions()}
            </select>
          </div>

          {this.isCompanyPhoneRegistrationVisible() && (
            <CompanyPhoneRegistration />
          )}
        </div>

        {this.renderProperties()}
      </PageContainer>
    );
  }

  renderPropertyOptions() {
    return this.state.properties.map((property) => {
      return (
        <option key={'property-' + property.id} value={property.id}>
          {property.data.location.name}
        </option>
      );
    });
  }

  onPropertyChanged(event) {
    this.setState({
      selectedPropertyId:
        event.target.value === 'all'
          ? event.target.value
          : parseInt(event.target.value, 10),
    });
  }

  renderProperties() {
    if (this.state.properties.length === 0) {
      return <Alert>No properties to display.</Alert>;
    }

    if (this.state.selectedPropertyId === 'all') {
      return this.state.properties.map((property) =>
        this.renderProperty(property)
      );
    }

    return this.renderProperty(
      this.state.properties.find(
        (property) => property.id === this.state.selectedPropertyId
      )
    );
  }

  renderProperty(property) {
    return (
      <PropertySources
        key={`${property.id}`}
        property={property}
        feeTypes={SourcesAPI.FEE_TYPES}
        onDataRetrieved={this.onDataRetrieved.bind(this)}
        onSourcesChanged={this.onSourcesChanged.bind(this)}
        onLeasingTeamSourceChanged={this.onLeasingTeamSourceChanged.bind(this)}
        defaultSourceChanged={this.defaultSourceChanged.bind(this)}
        updatePropertySourceMapping={this.updatePropertySourceMapping.bind(
          this
        )}
      />
    );
  }

  onDataRetrieved(propertyId, propertyData) {
    const updatedProperties = this.state.properties.slice();
    const updatedPropertyIndex = updatedProperties.findIndex(
      (property) => property.id === propertyId
    );

    const sources = propertyData.sources
      .sort((sourceA, sourceB) => {
        const sourceAHasRelay =
          sourceA.relay_forwarding_phone_number || sourceA.display_email;
        const sourceBHasRelay =
          sourceB.relay_forwarding_phone_number || sourceB.display_email;

        if (sourceAHasRelay) {
          if (!sourceBHasRelay) {
            return -1;
          }
        }

        if (sourceBHasRelay) {
          if (!sourceAHasRelay) {
            return 1;
          }
        }

        if (sourceA.is_enabled) {
          if (!sourceB.is_enabled) {
            return -1;
          }
        }

        if (sourceB.is_enabled) {
          if (!sourceA.is_enabled) {
            return 1;
          }
        }

        return sourceA.name.toLowerCase() > sourceB.name.toLowerCase() ? 1 : -1;
      })
      .map((sourceData) => {
        const relayPhones = sourceData.relay_phones
          ? sourceData.relay_phones
              .sort((relayPhoneA, relayPhoneB) => {
                return relayPhoneA.number > relayPhoneB.number ? 1 : -1;
              })
              .map((relayPhone) => {
                const relayNumber = relayPhone.number;

                const formattedRelayNumber = relayNumber
                  ? `(${relayNumber.substr(2, 3)}) ${relayNumber.substr(
                      5,
                      3
                    )}-${relayNumber.substr(8, 4)}`
                  : '';

                if (
                  relayPhone.forwarding_number &&
                  relayPhone.forwarding_number.startsWith('+')
                ) {
                  relayPhone.forwarding_number = `(${relayPhone.forwarding_number.substr(
                    2,
                    3
                  )}) ${relayPhone.forwarding_number.substr(
                    5,
                    3
                  )}-${relayPhone.forwarding_number.substr(8, 4)}`;
                }

                if (
                  relayPhone.resident_forwarding_number &&
                  relayPhone.resident_forwarding_number.startsWith('+')
                ) {
                  relayPhone.resident_forwarding_number = `(${relayPhone.resident_forwarding_number.substr(
                    2,
                    3
                  )}) ${relayPhone.resident_forwarding_number.substr(
                    5,
                    3
                  )}-${relayPhone.resident_forwarding_number.substr(8, 4)}`;
                }

                let rawForwardingNumber = (
                  relayPhone.forwarding_number || ''
                ).replace(/\D/g, '');

                let rawResidentForwardingNumber = (
                  relayPhone.resident_forwarding_number || ''
                ).replace(/\D/g, '');

                if (rawForwardingNumber.length > 10) {
                  rawForwardingNumber = rawForwardingNumber.substring(2, 12);
                }

                if (rawResidentForwardingNumber.length > 10) {
                  rawResidentForwardingNumber =
                    rawResidentForwardingNumber.substring(2, 12);
                }

                return {
                  voiceNumber: formattedRelayNumber,
                  voiceNumberForwardsTo: relayPhone.forwarding_number,
                  voiceNumberResidentForwardsTo:
                    relayPhone.resident_forwarding_number,
                  voiceNumberRaw: relayNumber,
                  voiceNumberForwardsToRaw: rawForwardingNumber,
                  voiceNumberResidentForwardsToRaw: rawResidentForwardingNumber,
                  voiceNumberLastIncomingActivityTime:
                    relayPhone.last_incoming_activity_time,
                  relayPhoneId: relayPhone.id,
                };
              })
          : null;

        return {
          id: sourceData.source_id,
          propertySourceId: sourceData.property_source_id,
          title: sourceData.name,
          isEnabled: sourceData.is_enabled,
          disallowDisable: false,
          displayEmail: sourceData.display_email,
          trackingEmail: sourceData.relay_email_address,
          relayPhones: relayPhones,
          currentFee:
            sourceData.current_pricing_model === null
              ? null
              : sourceData.current_cost,
          currentPricingModel: sourceData.current_pricing_model || 'None',
          parentSourceId: sourceData.parent_source_id,
        };
      });

    updatedProperties[updatedPropertyIndex] = {
      ...updatedProperties[updatedPropertyIndex],
      sources,
      integrations: propertyData.integrations,
    };

    this.setState({ ...this.state, properties: updatedProperties });
  }

  onSourcesChanged(
    propertyId,
    sourceIndex,
    updatedSource,
    updatedSourceMapping,
    integrationIndex = null
  ) {
    /**
     * Get and update property sources
     */
    const properties = [...this.state.properties];
    const propertyToUpdate = properties.find(
      (property) => property.id === propertyId
    );
    propertyToUpdate.sources[sourceIndex] = updatedSource;

    /**
     * If we have an integration index we update our mapped sources.
     */
    if (integrationIndex != null) {
      const newIntegrationSourceId = updatedSourceMapping.integration_source_id;
      const integration = propertyToUpdate.integrations[integrationIndex];
      const mappedSource = integration.integration_sources.find(
        (source) => source.id === newIntegrationSourceId
      );

      /**
       * Remove existing source if it exists.
       */
      const mappedSources = integration.mapped_sources.filter(
        (iSource) => iSource.knock_marketing_source_id !== updatedSource.id
      );

      /**
       * If our new integration is not "Default" then we'll add the integration to our mapped sources.
       */
      if (newIntegrationSourceId != null) {
        mappedSources.push({
          mapped_id: newIntegrationSourceId,
          mapped_name: mappedSource.name,
          knock_marketing_source_id: updatedSource.id,
        });
      }

      integration.mapped_sources = mappedSources;
    }

    this.setState(
      {
        properties,
      },
      () => {
        this.resortSources(propertyId);
      }
    );

    const updatedSourceMappings = updatedSourceMapping
      ? [updatedSourceMapping]
      : null;

    this.saveSource(
      propertyId,
      updatedSource.id,
      updatedSource.displayEmail,
      updatedSourceMappings
    );
  }

  saveSource(propertyId, sourceId, displayEmail, updatedSourceMappings) {
    SourcesAPI.updatePropertySource(
      propertyId,
      sourceId,
      displayEmail,
      updatedSourceMappings
    )
      .then(() => {
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      })
      .catch((e) => {
        console.error(`Error saving source: ${e}`);
        Toaster.showToast('Error saving!', 2000, Toaster.ToastClasses.error);
        this.reloadSources(propertyId);
      });
  }

  defaultSourceChanged(propertyId, integrationId, mappedSourceId) {
    const updatedProperties = this.state.properties.slice();

    const updatedProperty = updatedProperties.find(
      (property) => property.id === propertyId
    );

    const updatedPropertyIntegration = updatedProperty.integrations.find(
      (integration) => integration.id === integrationId
    );

    if (updatedPropertyIntegration.default_integration_source === null) {
      updatedPropertyIntegration.default_integration_source = {
        mapped_id: mappedSourceId,
      };
    } else {
      updatedPropertyIntegration.default_integration_source.mapped_id =
        mappedSourceId;
    }

    this.setState({
      ...this.state,
      properties: updatedProperties,
    });

    this.saveDefaultSource(propertyId, integrationId, mappedSourceId);
  }

  saveDefaultSource(propertyId, integrationId, mappedSourceId) {
    SourcesAPI.updateDefaultPropertySource(
      propertyId,
      integrationId,
      mappedSourceId
    )
      .then(() => {
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      })
      .catch((e) => {
        console.error(`Error saving default source: ${e}`);
        Toaster.showToast('Error saving!', 2000, Toaster.ToastClasses.error);
        this.reloadSources(propertyId);
      });
  }

  onLeasingTeamSourceChanged(leasingTeamId, propertyId, updatedSource) {
    const updatedProperties = this.state.properties.slice();

    updatedProperties
      .find((property) => property.id === propertyId)
      .sources.find((source) => source.id === updatedSource.id).isEnabled =
      updatedSource.isEnabled;

    this.setState({ properties: updatedProperties }, () => {
      this.resortSources(propertyId);
    });

    return SourcesAPI.updateAccountSource(
      leasingTeamId,
      updatedSource.id,
      updatedSource.isEnabled,
      updatedSource.parentSourceId
    )
      .then(() => {
        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      })
      .catch((e) => {
        console.error(`Error saving account source: ${e}`);
        Toaster.showToast('Error saving!', 2000, Toaster.ToastClasses.error);
        this.reloadSources(propertyId);
      });
  }

  reloadSources(propertyId) {
    const includeDisabled = AuthenticationService._internalMode;
    return SourcesAPI.getPropertiesSources([propertyId], includeDisabled)
      .then((response) => {
        const { property_sources_data } = response;
        if (property_sources_data) {
          const propertyData = property_sources_data.find(
            (property) => property.id === propertyId
          );
          this.onDataRetrieved(propertyId, propertyData);
        } else {
          throw Error(`Error reloading property sources: ${response}`);
        }
      })
      .catch((e) => {
        console.error(`Error reloading property sources: ${e}`);
        Toaster.showToast('Error reloading!', 2000, Toaster.ToastClasses.error);
      });
  }

  resortSources(propertyId) {
    const property = this.state.properties.find(
      (property) => property.id === propertyId
    );
    const sources = property.sources.sort((sourceA, sourceB) => {
      const sourceAHasRelay =
        sourceA.voiceNumberForwardsTo || sourceA.displayEmail;
      const sourceBHasRelay =
        sourceB.voiceNumberForwardsTo || sourceB.displayEmail;

      if (sourceAHasRelay) {
        if (!sourceBHasRelay) {
          return -1;
        }
      }

      if (sourceBHasRelay) {
        if (!sourceAHasRelay) {
          return 1;
        }
      }

      if (sourceA.isEnabled) {
        if (!sourceB.isEnabled) {
          return -1;
        }
      }

      if (sourceB.isEnabled) {
        if (!sourceA.isEnabled) {
          return 1;
        }
      }

      return sourceA.title.toLowerCase() > sourceB.title.toLowerCase() ? 1 : -1;
    });

    const updatedProperties = this.state.properties.slice();

    updatedProperties.find((property) => property.id === propertyId).sources =
      sources;

    this.setState({ properties: updatedProperties });
  }

  updatePropertySourceMapping(propertyId, updatedSourceMappings) {
    const properties = [...this.state.properties];
    const propertyToUpdate = properties.find(
      (property) => property.id === propertyId
    );

    if (updatedSourceMappings.length <= 0) {
      Toaster.showToast('No Matches Found', 2000, Toaster.ToastClasses.success);
      return;
    }

    const payload = updatedSourceMappings.map((defaultSourceMapping) => ({
      integration_id: defaultSourceMapping.integration_id,
      integration_source_id: defaultSourceMapping.integration_source_id,
      knock_source_id: defaultSourceMapping.knock_source_id,
    }));

    SourcesAPI.updatePropertySourceMapping(propertyId, payload)
      .then(() => {
        Toaster.showToast(
          'Automapping Saved!',
          2000,
          Toaster.ToastClasses.success
        );
      })
      .catch((e) => {
        console.error(`Error saving source: ${e}`);
        Toaster.showToast('Error saving!', 2000, Toaster.ToastClasses.error);
        this.reloadSources(propertyId);
      });

    if (
      propertyToUpdate &&
      propertyToUpdate.integrations &&
      propertyToUpdate.integrations[0]
    ) {
      updatedSourceMappings.forEach(function (updatedSourceMap) {
        propertyToUpdate.integrations[0].mapped_sources.push({
          knock_marketing_source_id: updatedSourceMap.knock_marketing_source_id,
          mapped_id: updatedSourceMap.mapped_id,
          mapped_name: updatedSourceMap.mapped_name,
        });
      });
    }
    this.setState({ properties });
  }
}

export default PropertySourcesTab;
