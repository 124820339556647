import React from 'react';
import PropTypes from 'prop-types';

import HelpIcon from '@material-ui/icons/Help';
import { Tooltip, makeStyles } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  tooltipHelpIcon: {
    marginLeft: '5px',
    verticalAlign: 'middle',
  },
});

const ItemTitle = ({ title, tooltip }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {title}
      {tooltip && (
        <Tooltip title={tooltip}>
          <HelpIcon
            className={classes.tooltipHelpIcon}
            fontSize="small"
            color="primary"
          />
        </Tooltip>
      )}
    </React.Fragment>
  );
};

ItemTitle.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string,
};

export default ItemTitle;
