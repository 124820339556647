import React, { Component } from 'react';
import { PageContainer, Toaster, Alert } from '@knockrentals/knock-react';
import { FeatureFlagContext } from '../../Context/FeatureFlagContext';
import TemplatesAPI from './TemplatesAPI';
import PropertyTemplates from './PropertyTemplates';
import { fetchTemplatesPageData } from './utils';

class TemplatesPage extends Component {
  state = {
    isLoading: true,
    mergeTags: {},
    properties: [],
    selectedPropertyId: 'all',
  };

  componentDidMount() {
    this.initTemplatesPage();
  }

  initTemplatesPage = async () => {
    const { mergeTags, properties } = await fetchTemplatesPageData();
    this.setState({ isLoading: false, mergeTags, properties });
  };

  render() {
    return (
      <PageContainer
        className="templates-page-container"
        isLoading={this.state.isLoading}
      >
        {!this.context.isCompanyTemplatesEnabled && <h1>Templates</h1>}
        <div className="knock-react-flex knock-react-flex-row">
          <div>
            <div>Property</div>
            <select
              value={this.state.selectedPropertyId}
              onChange={this.onPropertyChanged.bind(this)}
            >
              <option value={'all'}>All</option>
              {this.renderPropertyOptions()}
            </select>
          </div>
        </div>
        {this.renderProperties()}
      </PageContainer>
    );
  }

  onPropertyChanged(event) {
    this.setState({
      selectedPropertyId:
        event.target.value === 'all'
          ? event.target.value
          : parseInt(event.target.value, 10),
    });
  }

  renderPropertyOptions() {
    return this.state.properties.map((property) => {
      return (
        <option
          key={'property-' + property.property_id}
          value={property.property_id}
        >
          {property.property_name}
        </option>
      );
    });
  }

  renderProperties() {
    if (this.state.properties.length === 0) {
      return <Alert>No properties to display.</Alert>;
    }

    if (this.state.selectedPropertyId === 'all') {
      return this.state.properties.map((property) => {
        return (
          <PropertyTemplates
            isCompanyTemplatesEnabled={this.context.isCompanyTemplatesEnabled}
            isCompanyTemplatesWinbackEnabled={
              this.context.isCompanyTemplatesWinbackEnabled
            }
            isCompanyTemplatesSelfGuidedToursEnabled={
              this.context.isCompanyTemplatesSelfGuidedToursEnabled
            }
            key={property.property_id}
            mergeTags={this.state.mergeTags}
            onChange={this.onTemplatesUpdated.bind(this, property.property_id)}
            property={property}
          />
        );
      });
    }

    const selectedProperty = this.state.properties.find(
      (property) => property.property_id === this.state.selectedPropertyId
    );

    return [
      <PropertyTemplates
        isCompanyTemplatesEnabled={this.context.isCompanyTemplatesEnabled}
        isCompanyTemplatesWinbackEnabled={
          this.context.isCompanyTemplatesWinbackEnabled
        }
        isCompanyTemplatesSelfGuidedToursEnabled={
          this.context.isCompanyTemplatesSelfGuidedToursEnabled
        }
        key={selectedProperty.property_id}
        mergeTags={this.state.mergeTags}
        onChange={this.onTemplatesUpdated.bind(
          this,
          selectedProperty.property_id
        )}
        property={selectedProperty}
      />,
    ];
  }

  onTemplatesUpdated(propertyId, updatesTemplates) {
    return TemplatesAPI.updateTemplates(propertyId, updatesTemplates).then(
      () => {
        const updatedProperties = this.state.properties.slice();
        const updatedProperty = {
          ...updatedProperties.find(
            (property) => property.property_id === propertyId
          ),
          ...updatesTemplates,
        };

        const propertyIndex = updatedProperties.findIndex(
          (property) => property.property_id === propertyId
        );
        updatedProperties[propertyIndex] = updatedProperty;

        this.setState({ properties: updatedProperties });

        Toaster.showToast('Saved!', 2000, Toaster.ToastClasses.success);
      },
      () => {
        Toaster.showToast(
          'Error saving templates.',
          2000,
          Toaster.ToastClasses.error
        );
      }
    );
  }
}

export default TemplatesPage;

TemplatesPage.contextType = FeatureFlagContext;
