import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CardContent, Text, makeStyles } from '@knockrentals/knock-shared-web';

import FeatureCard from '../../../common/FeatureCard';
import { PropertiesDataTable } from '../PropertiesDataTable';
import { FeatureActions } from '../../../common/FeatureActions';
import {
  AI_CONFIRMATION_BUTTON_TEXT,
  AI_CONFIRMATION_MODES,
  AI_TEMPLATE_TYPE_TITLES,
  getAiConfirmationMessage,
  getAiConfirmationTitle,
} from '../AiConstants';
import usAddAiProperty from './useAddAiProperty';
import ConfirmationDialog from '../../../common/ConfirmationDialog';

const useStyles = makeStyles(() => ({
  addPropertyCardContent: {
    flexGrow: 1,
    background: '#F7F7F8',
    overflowY: 'auto',
    padding: '26px 40px 40px',
  },
  textLabel: { marginLeft: '5px', marginBottom: '3px' },
}));

const AddAiProperty = ({
  addProperties,
  closeDrawer,
  intentTemplates,
  templateId,
  templateName,
  templatePropertiesMap,
  templateType,
}) => {
  const classes = useStyles();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const { propertiesTemplatesList, setTablePropertiesMap, tablePropertiesMap } =
    usAddAiProperty({ intentTemplates, templateId, templatePropertiesMap });

  const getSelectedProperties = () =>
    Object.keys(tablePropertiesMap).reduce((acc, propertyId) => {
      if (tablePropertiesMap[propertyId].isSelected) {
        acc.push({
          propertyId: Number(propertyId),
          propertyName: tablePropertiesMap[propertyId].name,
        });
      }
      return acc;
    }, []);

  const handleConfirmation = () => {
    closeDrawer();
    addProperties(getSelectedProperties());
  };

  const handleSaveOnClick = () => {
    if (getSelectedProperties().length) setIsConfirmationOpen(true);
    else closeDrawer();
  };

  return (
    <div>
      <ConfirmationDialog
        closeDialog={() => setIsConfirmationOpen(false)}
        handleConfirmation={handleConfirmation}
        isOpen={isConfirmationOpen}
        message={getAiConfirmationMessage(
          templateName,
          AI_CONFIRMATION_MODES.ADD
        )}
        primaryButtonText={
          AI_CONFIRMATION_BUTTON_TEXT[AI_CONFIRMATION_MODES.ADD]
        }
        title={getAiConfirmationTitle(templateName, AI_CONFIRMATION_MODES.ADD)}
      />

      <FeatureCard
        closeDrawer={closeDrawer}
        subheader={`${AI_TEMPLATE_TYPE_TITLES[templateType]} / ${templateName}`}
        title="ADD PROPERTY"
      >
        <CardContent className={classes.addPropertyCardContent}>
          <Text
            variant="subtitle2"
            color="secondary"
            className={classes.textLabel}
          >
            {`Select One or More Properties to Add to ${templateName}`}
          </Text>

          <PropertiesDataTable
            properties={propertiesTemplatesList}
            tablePropertiesMap={tablePropertiesMap}
            setTablePropertiesMap={setTablePropertiesMap}
          />
        </CardContent>

        <FeatureActions
          handleCancelOnClick={closeDrawer}
          handleSaveOnClick={handleSaveOnClick}
          primaryText="Include Selected Properties"
        />
      </FeatureCard>
    </div>
  );
};

AddAiProperty.propTypes = {
  addProperties: PropTypes.func,
  closeDrawer: PropTypes.func,
  intentTemplates: PropTypes.shape({
    intentId: PropTypes.number,
    templateCount: PropTypes.number,
    templates: PropTypes.arrayOf(
      PropTypes.shape({
        assignedPropertiesCount: PropTypes.number,
        properties: PropTypes.arrayOf(
          PropTypes.shape({
            property_id: PropTypes.number,
            property_name: PropTypes.string,
          })
        ),
        templateId: PropTypes.number,
        templateName: PropTypes.string,
      })
    ),
  }),
  templateId: PropTypes.number,
  templateName: PropTypes.string,
  templatePropertiesMap: PropTypes.objectOf(
    PropTypes.shape({
      isSelected: PropTypes.bool,
      propertyId: PropTypes.number,
      name: PropTypes.string,
      template: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    })
  ),
  templateType: PropTypes.string,
};

export default AddAiProperty;
