import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputCheckbox,
  makeStyles,
  Text,
} from '@knockrentals/knock-shared-web';
import { PropertiesDataTable } from '../PropertiesDataTable';
import { PROPERTY_TABLE_VIEWS } from '../../constants';

const useStyles = makeStyles({
  tableTitle: {
    marginTop: '32px',
  },
});

export const getLabelText = (propertyCount) =>
  `All ${propertyCount} users/properties can View this template`;

const AssignableProperties = ({
  assignableProperties,
  assignablePropertiesMap,
  handleAllAssignableChange,
  isEditing,
  isEveryPropertyAssignable,
  propertyCount,
  setAssignablePropertiesMap,
}) => {
  const classes = useStyles();

  const [isAssignablePropertiesHidden, setIsAssignablePropertiesHidden] =
    useState(isEveryPropertyAssignable);

  const handleCheckboxOnChange = (e) => {
    const isChecked = e.target.checked;
    handleAllAssignableChange(isChecked);
    setIsAssignablePropertiesHidden(isChecked);
  };

  return (
    <div>
      <InputCheckbox
        checked={isAssignablePropertiesHidden}
        color="primary"
        label={getLabelText(propertyCount)}
        onChange={handleCheckboxOnChange}
      />

      {!isAssignablePropertiesHidden && (
        <React.Fragment>
          <Text className={classes.tableTitle} paragraph variant="subtitle1">
            Only selected properties will be able to VIEW the new Template
          </Text>

          <PropertiesDataTable
            isEditing={isEditing}
            properties={assignableProperties}
            propertyCount={propertyCount}
            setTablePropertiesMap={setAssignablePropertiesMap}
            tablePropertiesMap={assignablePropertiesMap}
            view={PROPERTY_TABLE_VIEWS.ASSIGNABLE_PROPERTIES}
          />
        </React.Fragment>
      )}
    </div>
  );
};

AssignableProperties.propTypes = {
  assignableProperties: PropTypes.arrayOf(
    PropTypes.shape({
      isSelected: PropTypes.bool,
      propertyId: PropTypes.number,
      propertyName: PropTypes.string,
    })
  ),
  assignablePropertiesMap: PropTypes.objectOf(
    PropTypes.shape({
      isSelected: PropTypes.bool,
      propertyId: PropTypes.number,
      propertyName: PropTypes.string,
    })
  ),
  handleAllAssignableChange: PropTypes.func,
  isEditing: PropTypes.bool,
  isEveryPropertyAssignable: PropTypes.bool,
  propertyCount: PropTypes.number,
  setAssignablePropertiesMap: PropTypes.func,
};

export default AssignableProperties;
