export const getDefaultTableOptions = () => ({
  draggable: false,
  grouping: false,
  headerStyle: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '32px',
    position: 'static',
  },
  padding: 'dense',
  paging: false,
  rowStyle: {
    fontSize: '14px',
    fontWeight: 400
  },
  search: false,
  sorting: false,
  toolbar: false,
});
