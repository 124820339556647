import { useEffect, useState } from 'react';
import { dripCampaignsApi } from '../../../DripCampaignsApi';

export const useActionTemplate = ({ templateId }) => {
  const [template, setTemplate] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const template = await dripCampaignsApi.getTemplateById(templateId);

        setTemplate(template);
      } catch (e) {
        setErrorMessage(e.message);
      }
      setIsLoading(false);
    };

    if (templateId) {
      fetchTemplate();
    }
  }, [templateId]);

  return {
    errorMessage,
    isLoading,
    setErrorMessage,
    template,
  };
};
