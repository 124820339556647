import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '@knockrentals/knock-react';
import { Formik } from 'formik';
import { trimValue } from '../../../Utils';

import './_addIntegrationForm.scss';

export const ARIA_LABELS = {
  PMC_ID: 'pmc_id',
  SITE_ID: 'site_id'
};

class AddRealPageIntegration extends Component {
  render() {
    return (
      <Modal className="add-integration-form">
        <ModalHeader>
          <h3>Add RealPage Integration</h3>
        </ModalHeader>
        <Formik
          initialValues={{
            pmc_id: '',
            site_id: '',
          }}
          onSubmit={this.submit.bind(this)}
          validate={this.validate}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <ModalContent>
                <div className="knock-react-flex">
                  <div>
                    <strong>PMC ID: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      aria-label={ARIA_LABELS.PMC_ID}
                      value={props.values.pmc_id}
                      name="pmc_id"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                      type="text"
                    />
                    {!!props.errors.vendor_property_id && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.vendor_property_id}
                      </span>
                    )}
                  </div>
                </div>
                <div className="knock-react-flex">
                  <div>
                    <strong>Site ID: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      aria-label={ARIA_LABELS.SITE_ID}
                      value={props.values.site_id}
                      name="site_id"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.site_id && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.site_id}
                      </span>
                    )}
                  </div>
                </div>
              </ModalContent>
              <ModalFooter>
                <button className="knock-react-button" type="submit">
                  Save
                </button>
                <button
                  className="knock-react-button"
                  onClick={this.cancel.bind(this)}
                  type="button"
                >
                  Cancel
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  submit(values) {
    this.props.onAddIntegration({ ...values, vendor: 'realpage' });
  }

  cancel() {
    this.props.onCancelAddIntegration();
  }
}

export default AddRealPageIntegration;
