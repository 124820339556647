import React from 'react';
import { makeStyles, Text } from '@knockrentals/knock-shared-web';
import { BrandContentTooltip } from './BrandContentTooltip';
import { useFeatureFlagContext } from '../../../Context/FeatureFlagContext/FeatureFlagContext';

export const TITLE = 'Brand Content';
export const DESCRIPTION =
  'Include your unique brand identity with property logo and brand color display on the Header and Footer of outgoing email communications. If no options are selected, the email will have no additional Header/Footer content.';

export const DETAILS =
  'Property Logo can be added in your LMA > Properties > Edit Community. To add a Brand Color go to Admin > Properties > Basics. Once updated, refresh this page to display.';

const useStyles = makeStyles({
  descriptionText: {
    marginTop: '24px',
  },
});

const PageDescription = () => {
  const classes = useStyles();
  const { isEnhancedBrandContentEnabled } = useFeatureFlagContext();

  return (
    <div className="brand-content-page-header">
      <Text variant="h6">
        {TITLE} <BrandContentTooltip />
      </Text>

      <Text className={classes.descriptionText}>{DESCRIPTION}</Text>

      {!isEnhancedBrandContentEnabled && (
        <Text className={classes.descriptionText}>{DETAILS}</Text>
      )}
    </div>
  );
};

export default PageDescription;
