import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Button
} from '@knockrentals/knock-shared-web';
import { AddCircleSvgIcon } from '../../Components/Icons';
import SSDATable from './SSDATable';
import IpAddressChips from './IpAddressChips';

const useStyles = makeStyles({
  addUserButton: {
    position: 'absolute',
    right: `25px`,
    top: '25px',
  },
  ipAddressChipContainer: {
    margin: '20px 0px 20px 0px'
  }
});

const SSDAList = ({ ipAddresses = [], setIsDrawerOpen }) => {
  const classes = useStyles();

  return (
    <div className="knock-data-cloud-list">
      <Button
        className={classes.addUserButton}
        color="primary"
        onClick={() => {
          setIsDrawerOpen(true);
        }}
        startIcon={<AddCircleSvgIcon />}
        variant="text"
      >
        Add User
      </Button>

      <div className={classes.ipAddressChipContainer}>
        <IpAddressChips ipAddresses={ipAddresses} />

        <Button color="primary" startIcon={<AddCircleSvgIcon />} variant="text">
          IP Address
        </Button>
      </div>

      <SSDATable />
    </div>
  );
};

SSDAList.propTypes = {
  ipAddresses: PropTypes.arrayOf(PropTypes.string),
  setIsDrawerOpen: PropTypes.func,
};

export default SSDAList;
