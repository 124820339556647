export const CHAR_LIMITS = {
  short: 140,
  medium: 1500,
  long: 10000,
};

export const DRIP_CAMPAIGN_ACTION_TYPES = {
  EMAIL: 'email',
  SMS: 'sms',
  STATE_TRANSITION: 'state_transition',
};

export const PROSPECT_STATES = {
  LOST: 'lost',
};
