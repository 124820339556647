import React from 'react';
import PropTypes from 'prop-types';
import DataTable from 'material-table';
import { getTableColumns } from './table-columns';
import { tableOptions } from './table-options';

const AccessDataTable = ({
  accessSettings,
  isFullAccessView,
  isLoading,
  updateAccessSetting,
}) => {
  const updateSetting = (rowData, role) => (e) => {
    updateAccessSetting(rowData, role, e.target.checked);
  };

  const columns = getTableColumns(isFullAccessView, updateSetting);
  return (
    <DataTable
      columns={columns}
      data={accessSettings}
      isLoading={isLoading}
      options={tableOptions}
      style={{ width: '800px' }}
    />
  );
};

AccessDataTable.propTypes = {
  accessSettings: PropTypes.array,
  isFullAccessView: PropTypes.bool,
  isLoading: PropTypes.bool,
  updateAccessSetting: PropTypes.func,
};

export default AccessDataTable;
