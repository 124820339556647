import { apiRemote, responseHandler } from '../../Services/RemoteService';
import { BASE_API_URI } from '../../config';

class SyndicationAPI {
  async getMyCommunities() {
    return apiRemote.get(`me/communities`).then(responseHandler);
  }

  async getIlsDeveloperSubscriptions() {
    // Content-Type is not part of the RFC spec for GET requests
    // but the apiRemote package in knock-react adds it automatically as of 9/12/19.
    // removing here because it causes CORS permissions errors in core.
    // eslint-disable-next-line no-unused-vars
    const { 'Content-Type': _, ...headers } = apiRemote.getHeaders();
    const subscriptions = fetch(`${BASE_API_URI}ils/developers/subscriptions`, {
      headers,
    }).then(responseHandler);
    return subscriptions;
  }

  async updateIlsDeveloperSubscriptions(subscribeActions) {
    const { subscriptions } = await apiRemote
      .put(`ils/developers/subscribe`, subscribeActions)
      .then(responseHandler);
    return subscriptions;
  }
}

export default new SyndicationAPI();
