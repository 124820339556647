import React from 'react';
import PropTypes from 'prop-types';
import FacebookProvider, { Login } from 'react-facebook';
import { FACEBOOK_APPLICATION_ID } from '../../../../config';
import classnames from 'classnames';

const FacebookProviderLogin = (props) => {
  const {
    appId,
    scope,
    buttonText,
    onResponse,
    onError,
  } = props;

  const facebookAppId = appId || FACEBOOK_APPLICATION_ID;
  const loginScope = scope || 'manage_pages,pages_messaging,leads_retrieval';

  const buttonClassNames = classnames('knock-react-button', 'btn-facebook');
  const iconClassNames = classnames('fa', 'fa-facebook-square', 'fa-lg');
  const btnText = buttonText || 'Login via Facebook';

  return (
    <FacebookProvider appId={facebookAppId}>
      <Login
        scope={loginScope}
        onResponse={onResponse}
        onError={onError}
      >
        <button
          className={buttonClassNames}
          type='button'
        >
          <i className={iconClassNames} /> { btnText }
        </button>
      </Login>
    </FacebookProvider>
  );
};

FacebookProviderLogin.propTypes = {
  appId: PropTypes.string,
  scope: PropTypes.string,
  buttonText: PropTypes.string,
  onResponse: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default FacebookProviderLogin;