import React, { Component } from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter } from '@knockrentals/knock-react';
import { Formik } from 'formik';
import { trimValue } from '../../../Utils';

import './_addIntegrationForm.scss';

class AddAmsiIntegration extends Component {
  render() {
    return (
      <Modal className="add-integration-form">
        <ModalHeader>
          <h3>Add AMSI Integration</h3>
        </ModalHeader>
        <Formik
          initialValues={{
            urlProtocol: 'http://',
            urlHost: '',
            urlPort: '80',
            username: '',
            password: '',
            portfolio_name: '',
            vendor_property_id: '',
          }}
          onSubmit={this.submit.bind(this)}
          validate={this.validate}
        >
          {props => (
            <form onSubmit={props.handleSubmit} autoComplete="off">
              <ModalContent>
                <div className="knock-react-flex">
                  <div>
                    <strong>Property ID: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.vendor_property_id}
                      name="vendor_property_id"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.vendor_property_id && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.vendor_property_id}
                      </span>
                    )}
                  </div>
                </div>

                <div className="knock-react-flex">
                  <div>
                    <strong>Username: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.username}
                      name="username"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.username && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.username}
                      </span>
                    )}
                  </div>
                </div>

                <div className="knock-react-flex">
                  <div>
                    <strong>Password: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.password}
                      name="password"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.password && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.password}
                      </span>
                    )}
                  </div>
                </div>

                <div className="knock-react-flex">
                  <div>
                    <strong>Portfolio Name: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.portfolio_name}
                      name="portfolio_name"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.portfolio_name && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.portfolio_name}
                      </span>
                    )}
                  </div>
                </div>

                <div className="knock-react-flex">
                  <div>
                    <strong>URL prefix: </strong>
                  </div>
                  <div>
                    <input
                      className="knock-react-input"
                      value={props.values.urlPrefix}
                      placeholder="https://example.com/AMSIWEB/eDexWeb"
                      name="urlPrefix"
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    />
                    {!!props.errors.urlPrefix && (
                      <span className="knock-react-form-hint knock-react-form-hint-error">
                        {props.errors.urlPrefix}
                      </span>
                    )}
                  </div>
                </div>

                <div className="knock-react-flex">
                  <div>
                    <strong>Port: </strong>
                  </div>
                  <div>
                    <input
                      name="urlPort"
                      value={props.values.urlPort || ''}
                      onBlur={trimValue(props.setFieldValue)}
                      onChange={props.handleChange}
                    >
                    </input>
                  </div>
                </div>

                <div className="knock-react-flex">
                  <div>
                    <strong>Full URL:</strong>
                  </div>
                  <div>
                    <span>
                      {props.values.urlPrefix}
                      {props.values.urlPort}
                    </span>
                  </div>
                </div>
              </ModalContent>

              <ModalFooter>
                <button className="knock-react-button" type="submit">
                  Save
                </button>
                <button className="knock-react-button" onClick={this.cancel.bind(this)}>
                  Cancel
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  validate(values) {
    const errors = {};
    const requiredString = 'Required';

    if (!values.urlPrefix) {
      errors.urlPrefix = requiredString;
    }

    if (!values.username) {
      errors.username = requiredString;
    }

    if (!values.password) {
      errors.password = requiredString;
    }

    if (!values.portfolio_name) {
      errors.portfolio_name = requiredString;
    }

    if (!values.vendor_property_id) {
      errors.vendor_property_id = requiredString;
    }

    return errors;
  }

  submit(values) {
    const {
      urlPrefix,
      urlPort,
      username,
      password,
      portfolio_name,
      vendor_property_id,
    } = values;

    const data = {
      url: {
        url_prefix: urlPrefix,
        port: urlPort,
      },
      username,
      password,
      portfolio_name,
      vendor_property_id,
      url_prefix: urlPrefix,
      host: urlPort === '' ? urlPrefix : `${urlPrefix}:${urlPort}`,
      vendor: 'amsi',
    };

    this.props.onAddIntegration({ ...data });
  }

  cancel() {
    this.props.onCancelAddIntegration();
  }
}

export default AddAmsiIntegration;
