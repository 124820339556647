import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { VerticalNavbarLink } from '@knockrentals/knock-react';
import { useAppContext } from '../../Context/AppContext';
import { useFeatureFlagContext } from '../../Context/FeatureFlagContext';
import { ACCESS_SETTINGS } from '../../constants';

const ProtectedNavBarLink = ({ accessSetting, children, path }) => {
  const { isAuthorized } = useAppContext();
  const { isEnhancedAccessEnabled } = useFeatureFlagContext();

  const isHidden =
    (isEnhancedAccessEnabled ||
      accessSetting === ACCESS_SETTINGS.ATTRIBUTES_PAGE) &&
    !isAuthorized(accessSetting);

  if (isHidden) {
    return null;
  }

  return (
    <NavLink to={path}>
      <VerticalNavbarLink>{children}</VerticalNavbarLink>
    </NavLink>
  );
};

ProtectedNavBarLink.propTypes = {
  accessSetting: PropTypes.string,
  children: PropTypes.node,
  path: PropTypes.string,
};

export default ProtectedNavBarLink;
