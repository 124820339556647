import React from 'react';
import ModalView from '../ModalView';
import classnames from 'classnames';
import { EngrainIntegrationsTable } from './EngrainIntegrationsTable';


export const EngrainIntegrationModal = props => {

  const { propertyMapping, reloadData, show, onHide } = props;

  const classNames = classnames({
    'engain-integration-content': true,
    'min-width-1000': true,
  });

  return (
    <ModalView
      header='Edit Engrain Integration'
      onHide={onHide}
      show={show}
    >
      <div className={classNames}>
        <EngrainIntegrationsTable
          propertyMapping={propertyMapping}
          reloadData={reloadData}
        />
      </div>
    </ModalView>
  );
};

export default EngrainIntegrationModal;
