import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { RichTextEditor } from '../RichTextEditor';
import { AuthenticationService } from '@knockrentals/knock-react';
import { BASE_API_URI } from '../config';
import './_EmailTemplateEditor.scss';

export const DEFAULT_MIN_HEIGHT = 271;
export const DEFAULT_MIN_WIDTH = 768;
export const IMAGE_UPLOAD_URL = `${BASE_API_URI}upload/attachment`;

export const getImageUploadConfig = (shouldDisableInlineImages) => {
  const config = {};
  if (!shouldDisableInlineImages) {
    config.authToken = AuthenticationService.getToken();
    config.imageUploadUrl = IMAGE_UPLOAD_URL;
  }
  return config;
};

export const setWidth = (width) => {
  if (width) {
    return {
      minWidth: `${width}px`,
      maxWidth: `${width}px`,
    };
  }
};

const EmailTemplateEditor = ({
  content,
  isSourceModeEnabled,
  mergeTags = [],
  minResizeHeight = DEFAULT_MIN_HEIGHT,
  minResizeWidth = DEFAULT_MIN_WIDTH,
  name,
  onChange,
  onDialogDefinition,
  onInstanceReady,
  placeHolderText,
  shouldDisableInlineImages,
}) => {
  const { authToken, imageUploadUrl } = getImageUploadConfig(
    shouldDisableInlineImages
  );

  return (
    <div
      className={classnames('email-template-editor', name)}
      style={setWidth(minResizeWidth)}
    >
      <RichTextEditor
        authToken={authToken}
        content={content}
        mentionsList={mergeTags}
        isSourceModeEnabled={isSourceModeEnabled}
        imageUploadUrl={imageUploadUrl}
        minResizeHeight={minResizeHeight}
        minResizeWidth={minResizeWidth}
        name={name}
        onChange={onChange}
        onDialogDefinition={onDialogDefinition}
        onInstanceReady={onInstanceReady}
        placeHolderText={placeHolderText}
      />
    </div>
  );
};

EmailTemplateEditor.propTypes = {
  content: PropTypes.string,
  isSourceModeEnabled: PropTypes.bool,
  mergeTags: PropTypes.array,
  minResizeHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minResizeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  onDialogDefinition: PropTypes.func,
  onInstanceReady: PropTypes.func,
  placeHolderText: PropTypes.string,
  shouldDisableInlineImages: PropTypes.bool,
};

export default EmailTemplateEditor;
