import React, { Component, Fragment } from 'react';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalContent,
  AuthenticationService,
} from '@knockrentals/knock-react';
import SourcesAPI from '../SourcesAPI';
import { Formik } from 'formik';

import './_phoneRelayEditor.scss';

class SourceRelayPhoneEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forwardsTo: '',
      residentForwardsTo: '',
      sourceId: '',
      greetingRepeat: 0,
      residentGreetingRepeat: 0,
      greetingDelay: 5,
      residentGreetingDelay: 1,
      isSpamDetectionEnabled: null,
      recordCalls: true,
      relayPhoneNumber: null,
      countryCode: 'US',
    };
  }

  componentDidMount() {
    const { source, relayPhone } = this.props;

    if (relayPhone.relayPhoneId) {
      this.setState({
        relayPhoneNumber: relayPhone.voiceNumberRaw,
        forwardsTo:
          relayPhone.voiceNumberForwardsToRaw.length > 10
            ? relayPhone.voiceNumberForwardsToRaw.substring(2, 12)
            : relayPhone.voiceNumberForwardsToRaw,
        residentForwardsTo:
          relayPhone.voiceNumberResidentForwardsToRaw.length > 10
            ? relayPhone.voiceNumberResidentForwardsToRaw.substring(2, 12)
            : relayPhone.voiceNumberResidentForwardsToRaw,
        sourceId: source.id,
      });

      SourcesAPI.getRelaySettings(this.props.propertyId, relayPhone.relayPhoneId)
        .then(response => {
          this.setState({
            recordCalls: response.relay_phone.should_record,
            greetingRepeat: response.relay_phone.greeting_repeat_count,
            residentGreetingRepeat: response.relay_phone.resident_greeting_repeat_count,
            greetingDelay: response.relay_phone.greeting_delay_seconds,
            residentGreetingDelay: response.relay_phone.resident_greeting_delay_seconds,
            isSpamDetectionEnabled: response.relay_phone.is_spam_detection_enabled,
          });
        })
        .catch(e => console.log(e));
    }
  }

  render() {
    const sourceOptions = this.props.sources
      .filter(source => !source.relayPhones || source.id === this.state.sourceId)
      .map(source => {
        return (
          <option key={source.id} value={source.id}>
            {source.title}
          </option>
        );
      });

    return (
      <Modal className="phone-relay-editor">
        <Formik
          initialValues={{
            forwardsTo: this.state.forwardsTo,
            greetingRepeat: this.state.greetingRepeat,
            greetingDelay: this.state.greetingDelay,
            recordCalls: this.state.recordCalls,
            residentForwardsTo: this.state.residentForwardsTo,
            residentGreetingRepeat: this.state.residentGreetingRepeat,
            residentGreetingDelay: this.state.residentGreetingDelay,
            sourceId: this.state.sourceId,
            isSpamDetectionEnabled: this.state.isSpamDetectionEnabled,
            relayPhoneNumber: this.state.relayPhoneNumber,
            countryCode: this.state.countryCode,
          }}
          validate={this.validate}
          onSubmit={this.onSubmit.bind(this)}
          enableReinitialize
        >
          {props => (
            <form
              onSubmit={props.handleSubmit}
              onKeyPress={this.pressEnterSelectTrackingNumber.bind(this)}
              autoComplete="off"
            >
              <ModalHeader>
                <h3>Edit Tracking Number</h3>
              </ModalHeader>
              <ModalContent>
                <div>
                  <div className="knock-react-flex">
                    <div>
                      <strong>Tracking number:</strong>
                    </div>
                    <div>{this.state.relayPhoneNumber}</div>
                  </div>
                  <div className="knock-react-flex">
                    <div>
                      <strong>Source: </strong>
                    </div>
                    <div>
                      <select
                        name="sourceId"
                        value={props.values.sourceId}
                        onChange={props.handleChange}
                      >
                        {sourceOptions}
                      </select>
                    </div>
                  </div>
                  <div className="knock-react-flex">
                    <div>
                      <strong>Record calls: </strong>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        checked={!!props.values.recordCalls}
                        onChange={e => props.setFieldValue('recordCalls', e.target.checked)}
                        name="recordCalls"
                      />
                    </div>
                  </div>
                  {AuthenticationService._internalMode && (
                    <div className="knock-react-flex">
                      <div>
                        <strong>Detect spam: </strong>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          checked={props.values.isSpamDetectionEnabled || false}
                          onChange={e =>
                            props.setFieldValue('isSpamDetectionEnabled', e.target.checked)
                          }
                          name="isSpamDetectionEnabled"
                        />
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="knock-react-flex">
                    <div>
                      <strong>Prospect: </strong>
                    </div>
                    <div>
                      <input
                        className="knock-react-input"
                        value={props.values.forwardsTo}
                        name="forwardsTo"
                        placeholder="Phone number"
                        onChange={props.handleChange}
                      />
                      {!!props.errors.forwardsTo && (
                        <span className="knock-react-form-hint knock-react-form-hint-error">
                          {props.errors.forwardsTo}
                        </span>
                      )}
                    </div>
                  </div>
                  {AuthenticationService._internalMode && (
                    <Fragment>
                      <div className="knock-react-flex">
                        <div>
                          <strong>Greeting: </strong>
                        </div>
                        <div>
                          <select
                            value={props.values.greetingRepeat}
                            name="greetingRepeat"
                            onChange={props.handleChange}
                          >
                            {this.props.greetingRepeatOptions}
                          </select>
                        </div>
                      </div>
                      <div className="knock-react-flex">
                        <div>
                          <strong>Greeting delay: </strong>
                        </div>
                        <div>
                          <select
                            name="greetingDelay"
                            value={props.values.greetingDelay}
                            onChange={props.handleChange}
                          >
                            {this.props.greetingDelayOptions}
                          </select>
                        </div>
                      </div>
                    </Fragment>
                  )}
                  <hr />
                  <div className="knock-react-flex">
                    <div>
                      <strong>Resident: </strong>
                    </div>
                    <div>
                      <input
                        className="knock-react-input"
                        value={props.values.residentForwardsTo}
                        name="residentForwardsTo"
                        placeholder="Phone number"
                        onChange={props.handleChange}
                      />
                      {!!props.errors.residentForwardsTo && (
                        <span className="knock-react-form-hint knock-react-form-hint-error">
                          {props.errors.residentForwardsTo}
                        </span>
                      )}
                    </div>
                  </div>
                  {AuthenticationService._internalMode && (
                    <Fragment>
                      <div className="knock-react-flex">
                        <div>
                          <strong>Greeting: </strong>
                        </div>
                        <div>
                          <select
                            name="residentGreetingRepeat"
                            value={props.values.residentGreetingRepeat}
                            onChange={props.handleChange}
                          >
                            {this.props.greetingRepeatOptions}
                          </select>
                        </div>
                      </div>
                      <div className="knock-react-flex">
                        <div>
                          <strong>Greeting delay: </strong>
                        </div>
                        <div>
                          <select
                            name="residentGreetingDelay"
                            value={props.values.residentGreetingDelay}
                            onChange={props.handleChange}
                          >
                            {this.props.greetingDelayOptions}
                          </select>
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </ModalContent>
              <ModalFooter>
                <button className="knock-react-button" onClick={this.props.onCancel}>
                  Cancel
                </button>
                <button className="knock-react-button" type="submit">
                  Save
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  //Prevent the enter key from bubbling up and closing the form or submitting invalid form data
  pressEnterSelectTrackingNumber(event, formProps) {
    if (event.charCode === 13) {
      event.stopPropagation();
      event.preventDefault();

      if (formProps.isValid) {
        formProps.handleSubmit();
      }
    }
  }

  onSubmit(values) {
    const replaceDigitsRegex = /\D/g;
    this.props.onSubmit({
      ...values,
      forwardsTo: values.forwardsTo.replace(replaceDigitsRegex, ''),
      residentForwardsTo: values.residentForwardsTo.replace(replaceDigitsRegex, ''),
    });
  }

  validate(values) {
    const errors = {};
    const cleanForwardsTo = values.forwardsTo.replace(/\D/g, '');
    const cleanResidentForwardsTo = values.residentForwardsTo.replace(/\D/g, '');

    if (!cleanForwardsTo) {
      errors.forwardsTo = 'Required';
    }

    if (cleanForwardsTo.length !== 10 || !/[0-9]{10}/.test(cleanForwardsTo)) {
      errors.forwardsTo = 'Invalid phone number';
    }

    if (!cleanResidentForwardsTo) {
      errors.forwardsTo = 'Required';
    }

    if (cleanResidentForwardsTo.length !== 10 || !/[0-9]{10}/.test(cleanResidentForwardsTo)) {
      errors.residentForwardsTo = 'Invalid phone number';
    }

    if (!values.sourceId) {
      errors.sourceId = 'Required';
    }

    return errors;
  }
}

export default SourceRelayPhoneEditor;
