import React, { Component } from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter, Toaster } from '@knockrentals/knock-react';
import AdminsAPI from './AdminsAPI';

class DeleteAdminUser extends Component {
  state = {
    loading: false,
  };

  render() {
    const { loading } = this.state;
    return (
      <Modal>
        <ModalHeader>
          <h2> Delete admin user </h2>
        </ModalHeader>

        <ModalContent>
          Are you sure you want to delete <br />
          {this.props.adminUser.username} ?
        </ModalContent>

        <ModalFooter>
          <button
            className="knock-react-button"
            id="cancelDeleteAdmin"
            onClick={this.props.onCancel}
          >
            Cancel
          </button>

          <button
            className="knock-react-button & danger"
            id="confirmDeleteAdmin"
            onClick={this.deleteAdminUser}
            disabled={loading}
          >
            {loading && <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>}
            {!loading && <span>Delete</span>}
          </button>
        </ModalFooter>
      </Modal>
    );
  }

  deleteAdminUser = () => {
    this.setState({ loading: true });
    return AdminsAPI.deleteAdminUser(this.props.adminUser.user_role, this.props.adminUser.admin_id)
      .then(response => {
        this.setState({ loading: false });

        if (response.message === 'User deleted') {
          Toaster.showToast(
            `Deleted Admin: ${this.props.adminUser.username}`,
            2000,
            Toaster.ToastClasses.success
          );
          this.props.onCancel();
          this.props.onDeleteSuccess(this.props.adminUser);
        } else {
          Toaster.showToast(
            `Error deleting Admin: ${this.props.adminUser.username}`,
            2000,
            Toaster.ToastClasses.error
          );
        }
      })
      .catch(() => {
        this.setState({ loading: false });

        Toaster.showToast(
          `Error deleting Admin: ${this.props.adminUser.username}`,
          2000,
          Toaster.ToastClasses.error
        );
      });
  };
}

export default DeleteAdminUser;
