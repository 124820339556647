import React from 'react';
import PropTypes from 'prop-types';
import { Text, Button } from '@knockrentals/knock-shared-web';
import { ArrowBack } from '@material-ui/icons';
import { PropertyChips } from './PropertyChips';
import { SchedulingPreferencesEdit } from './SchedulingPreferencesEdit';
import './_SchedulingPreferences.scss';

export const SCHEDULING_PREFERENCE_DESCRIPTION =
  'Update the settings you want to change for ALL selected properties and click save. The updated settings will replace any previously saved values - all other values on the page remain as previously set.';

const SchedulingPreferences = ({
  handleBackButtonOnClick,
  selectedProperties = [],
  setErrorMessage,
  updateSchedulingGridData,
}) => {
  const isBatchEdit = selectedProperties.length > 1;
  const [firstProperty = {}] = selectedProperties;
  const propertyName = isBatchEdit ? 'Edit Multiple' : firstProperty.name;

  return (
    <section className="scheduling-preferences">
      <Button
        color="primary"
        startIcon={<ArrowBack fontSize="small" />}
        variant="text"
        onClick={handleBackButtonOnClick}
      >
        Back
      </Button>

      <Text variant="h6">Scheduling: {propertyName}</Text>
      <Text className="scheduling-preferences-description" variant="body1">
        {SCHEDULING_PREFERENCE_DESCRIPTION}
      </Text>

      {isBatchEdit && <PropertyChips selectedProperties={selectedProperties} />}

      <SchedulingPreferencesEdit
        closeDrawer={handleBackButtonOnClick}
        isBatchEdit={isBatchEdit}
        selectedProperties={selectedProperties}
        setErrorMessage={setErrorMessage}
        updateSchedulingGridData={updateSchedulingGridData}
      />
    </section>
  );
};

SchedulingPreferences.propTypes = {
  handleBackButtonOnClick: PropTypes.func.isRequired,
  selectedProperties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      isSelected: PropTypes.bool,
    })
  ).isRequired,
  setErrorMessage: PropTypes.func,
  updateEditedSchedulingSwitches: PropTypes.func.isRequired,
};

export default SchedulingPreferences;
