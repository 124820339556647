import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '@knockrentals/knock-react';
import { Formik } from 'formik';
import { whiteSpaceRestrictValue } from '../../Utils';

const LABEL_IDS = {
  PASSWORD: 'password-editor-password',
  PASSWORD_CONFIRM: 'password-editor-password-confirm',
};

class PasswordEditor extends Component {
  render() {
    return (
      <Modal className="user-editor-container">
        <ModalHeader>
          <h2>Change password (Username: {this.props.adminUser.username})</h2>
        </ModalHeader>
        <Formik
          validateOnChange={false}
          initialValues={{
            password: '',
            passwordConfirm: '',
          }}
          validate={this.validate}
          onSubmit={this.props.submitPasswordUpdate.bind(this)}
        >
          {(props) => (
            <form
              onSubmit={props.handleSubmit}
              onKeyPress={this.enterSubmitForm.bind(this, props)}
              autoComplete="off"
            >
              <ModalContent>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <div>
                      <label
                        className="modal-field-label"
                        id={LABEL_IDS.PASSWORD}
                      >
                        <strong>Password: </strong>
                      </label>
                      <div className="modal-field-input">
                        <input
                          aria-labelledby={LABEL_IDS.PASSWORD}
                          autoFocus
                          className="knock-react-input"
                          type="password"
                          value={props.values.password}
                          name="password"
                          onChange={whiteSpaceRestrictValue(
                            props.setFieldValue
                          )}
                        />
                        {!!props.errors.password && (
                          <div className="knock-react-form-hint-error">
                            {props.errors.password}
                          </div>
                        )}
                      </div>
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                      <label
                        className="modal-field-label"
                        id={LABEL_IDS.PASSWORD_CONFIRM}
                      >
                        <strong>Confirm password: </strong>
                      </label>
                      <div className="modal-field-input">
                        <input
                          aria-labelledby={LABEL_IDS.PASSWORD_CONFIRM}
                          className="knock-react-input"
                          type="password"
                          value={props.values.passwordConfirm}
                          name="passwordConfirm"
                          onChange={whiteSpaceRestrictValue(
                            props.setFieldValue
                          )}
                        />
                        {!!props.errors.passwordConfirm && (
                          <div className="knock-react-form-hint-error">
                            {props.errors.passwordConfirm}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </ModalContent>
              <ModalFooter>
                <button
                  className="knock-react-button"
                  onClick={this.props.cancel}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="knock-react-button"
                  disabled={!props.dirty}
                  type="submit"
                >
                  Save
                </button>
              </ModalFooter>
            </form>
          )}
        </Formik>
      </Modal>
    );
  }

  //Prevent the enter key from bubbling up and closing the form or submitting invalid data
  enterSubmitForm(event, formProps) {
    if (event === 13) {
      // Enter key
      event.stopPropagation();
      event.preventDefault();

      if (formProps.isValid) {
        this.props.submitPasswordUpdate();
      }
    }
  }

  validate(values) {
    const errors = {};

    if (!values.password) {
      errors.password = 'Required';
    }

    if (values.password && values.password.length < 8) {
      errors.password = 'Password must be at least 8 characters.';
    }

    if (!values.passwordConfirm) {
      errors.passwordConfirm = 'Required';
    }

    if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = 'Passwords must match';
    }
    return errors;
  }
}

export default PasswordEditor;
