import React from 'react';
import PropTypes from 'prop-types';
import EditButton from './EditButton';
import { ListItemSecondaryAction } from '@material-ui/core';
import { ListItem, makeStyles } from '@knockrentals/knock-shared-web';
import TemplateDetails from './TemplateDetails';

const useStyles = makeStyles({
  templateListItem: {
    boxShadow:
      '0px 2px 12px -4px rgba(24, 39, 75, 0.04), 0px 4px 16px -2px rgba(24, 39, 75, 0.02)',
  },
});

const CompanyTemplates = ({
  propertyCount,
  setCompanyTemplate,
  templates,
  templateType,
  updateIsEnabled,
}) => {
  const classes = useStyles();
  return templates.map(
    ({
      assignedPropertiesCount,
      isDefault,
      isEnabled,
      templateId,
      templateName,
    }) => (
      <ListItem
        dense
        divider
        className={classes.templateListItem}
        key={templateName}
      >
        <TemplateDetails
          assignedPropertiesCount={assignedPropertiesCount}
          handleOnChangeIsEnabled={updateIsEnabled(templateType, templateId)}
          isDefault={isDefault}
          isEnabled={isEnabled}
          propertyCount={propertyCount}
          templateName={templateName}
        />

        <ListItemSecondaryAction>
          <EditButton
            onClick={() =>
              setCompanyTemplate({
                isDefault,
                templateId,
                templateType,
                propertyCount,
              })
            }
          />
        </ListItemSecondaryAction>
      </ListItem>
    )
  );
};

CompanyTemplates.propTypes = {
  propertyCount: PropTypes.number,
  setCompanyTemplate: PropTypes.func,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      isEnabled: PropTypes.bool,
      assignedPropertiesCount: PropTypes.number,
      templateId: PropTypes.number,
      templateName: PropTypes.string,
    })
  ),
  templateType: PropTypes.string,
  updateIsEnabled: PropTypes.func,
};

export default CompanyTemplates;
