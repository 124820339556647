import React from 'react';
import { FieldArray, Field } from 'formik';
import { Button, TextField } from '@knockrentals/knock-shared-web';
import { AddCircleSvgIcon, BinIcon } from '../../../Components/Icons';
import { useStyles } from './styles';
import './_SetupForm.scss';

const IndividualAccountsForm = () => {
  const classes = useStyles();

  return (
    <FieldArray name="userInfo">
      {({ form, push, remove }) => {
        const { errors, handleBlur, handleChange, touched, values } = form;
        const { userInfo } = values;
        const userInfoErrors = errors.userInfo || [];
        const userInfoTouched = touched.userInfo || [];

        const hasErrors = userInfoErrors.some((error) => 
          error.firstName || error.lastName || error.email
        );

        const allFieldsTouched = userInfoTouched.every((fieldTouched) => 
          fieldTouched.email && fieldTouched.firstName && fieldTouched.lastName
        );

        return (
          <>
            {userInfo.map((_, index) => {
              const userInfoErrorMessages = userInfoErrors[index] || {};
              const userInfoTouchedMessages = userInfoTouched[index] || {};

              return (
                <div className="individual-accounts" key={`individual-account-${index}`}>
                  {index > 0 && (
                    <div className="form-controls">
                      <Button
                        variant="text"
                        onClick={() => remove(index)}
                        startIcon={<BinIcon />}
                      >
                        Remove This User
                      </Button>
                    </div>
                  )}

                  <Field
                    as={TextField}
                    className={classes.textFieldInput}
                    error={Boolean(userInfoErrorMessages.firstName && userInfoTouchedMessages.firstName)}
                    helperText={userInfoErrorMessages.firstName}
                    label="First Name"
                    name={`userInfo.${index}.firstName`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Add First Name"
                    variant="filled"
                    fullWidth
                  />

                  <Field
                    as={TextField}
                    className={classes.textFieldInput}
                    error={Boolean(userInfoErrorMessages.lastName && userInfoTouchedMessages.lastName)}
                    helperText={userInfoErrorMessages.lastName}
                    label="Last Name"
                    name={`userInfo.${index}.lastName`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Add Last Name"
                    variant="filled"
                    fullWidth
                  />

                  <Field
                    as={TextField}
                    className={classes.textFieldInput}
                    error={Boolean(userInfoErrorMessages.email && userInfoTouchedMessages.email)}
                    helperText={userInfoErrorMessages.email}
                    label="Email"
                    name={`userInfo.${index}.email`}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Add Email"
                    variant="filled"
                    fullWidth
                  />
                </div>
              );
            })}

            <Button
              onClick={() =>
                push({
                  email: '',
                  firstName: '',
                  lastName: '',
                  isServiceAccount: false,
                })
              }
              disabled={hasErrors || !allFieldsTouched}
              variant="text"
              startIcon={<AddCircleSvgIcon />}
            >
              Add Additional Users
            </Button>
          </>
        );
      }}
    </FieldArray>
  );
};

export default IndividualAccountsForm;
